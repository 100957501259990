import React from "react";
/**
 * Version : 1.0
 * 파일명 : addParticipant.js
 * 작성일자 : 2021-10-05
 * 작성자 : 강연승
 * 설명 : 회의 예약 시 입력 input
 * 수정일자 :  2021-11-22
 * 수정자 : 강연승
 * 수정내역 : onClick 버블링 추가
 * props :
 * 전달받은값 - 설명
 */
function AddParticipant({
  className,
  PRTCP_EMAIL,
  onChange,
  onKeyPress,
  onFocus,
}) {
  return (
    <input
      className={className}
      name="PRTCP_EMAIL"
      placeholder="참석자 메일을 입력하세요."
      autoComplete="off"
      onChange={onChange}
      onKeyPress={onKeyPress}
      onFocus={onFocus}
      onClick={(e) => e.stopPropagation()}
      value={PRTCP_EMAIL}
    />
  );
}

export default AddParticipant;
