import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Cookies from "universal-cookie";
import "css/reponsive/landing/landing.css";
import SupportPopup from "component/tag/popup/support/supportPopup";
/**
 * Version : 1.0
 * 파일명 : Footer.js
 * 작성일자 : 2022-01-07
 * 작성자 : 권도훈
 * 설명 : footer
 * 수정일자 : 2022-06-13
 * 수정자 : 강연승
 * 수정내역 : sobridge 데모 - 계정 탈퇴 주석처리 및 사업자정보 수정
 */
function Footer() {
  /**
   * history
   */
  const history = useHistory();
  /**
   * request go delete page
   */
  const goDeleteAccount = () => {
    const cookies = new Cookies();

    //cookie
    const domain = process.env.REACT_APP_COOKIE_DOMAIN;
    const refreshNm = process.env.REACT_APP_COOKIE_REFRESH;
    const authNm = process.env.REACT_APP_COOKIE_AUTH;
    const loginTypeNm = process.env.REACT_APP_COOKIE_TYPE;

    cookies.remove(loginTypeNm, {
      path: "/",
      domain: domain,
    });

    cookies.remove(authNm, {
      path: "/",
      domain: domain,
    });

    cookies.remove(refreshNm, {
      path: "/",
      domain: domain,
    });

    history.push({
      pathname: "/login",
      state: {
        tag: "block",
        type: "delete",
      },
    });
  };

  const goSupportPage = () => {
    console.log("서포트페이지");
    history.push("/support");
  };
  /**
   * open KB Auth Popup
   */
  // const openKBAuth = () => {
  //     window.open("https://okbfex.kbstar.com/quics?page=C021590&cc=b034066:b035526&mHValue=ed54ba72d929bcd596083ab01603f6be201810041708952",
  //         "KB_AUTHMARK",
  //         "height=604, width=648, status=yes, toolbar=no, menubar=no, location = no"
  //     )
  // }
  /**
   * tag
   */
  return (
    <footer>
      <div className="ft_content">
        <img src="/img/logo_dark.png" alt="logo" />

        <div className="ft_btn_area">
          <div
            className="cursor ft_btn"
            onClick={() => {
              goSupportPage();
              window.scrollTo(0, 0);
            }}
          >
            고객센터
          </div>
          <div
            className="cursor ft_btn center1"
            onClick={() =>
              window.open(
                "https://junnhhh.notion.site/junnhhh/So-Bridge-2022-06-15-fba408b180a747338817fe3330a57579"
              )
            }
          >
            이용약관
          </div>
          <div
            className="cursor ft_btn center2"
            onClick={() =>
              window.open(
                "https://junnhhh.notion.site/junnhhh/So-Bridge-2022-06-15-ad6ad8d4b56844ba858bb7debd890d1b"
              )
            }
          >
            개인정보처리방침
          </div>
          {/* <div className="cursor ft_btn" onClick={() => goDeleteAccount()}>
            계정탈퇴
          </div> */}
        </div>

        <div className="ft_info">
          <div>
            삼양데이타시스템(주) | 대표: 김상욱 | 사업자등록번호: 208-81-19494
          </div>
          <div>
            서울특별시 종로구 종로33길 31 (삼양그룹 빌딩) | 고객센터:
            02-760-5057
          </div>
        </div>
      </div>

      {/* <img
                className='cursor'
                style={{
                    position: "absolute",
                    right: "0"
                }}
                src="http://img1.kbstar.com/img/escrow/escrowcmark.gif"
                alt="0"
                onClick={() => openKBAuth()}
            /> */}
    </footer>
  );
}

export default Footer;
