import axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import "css/profilesupmyquestion.css"
import { useDispatch, useSelector } from 'react-redux';
import { closeAlert, openAlert, openConfirm, updatePagingsupInfo } from 'actions';
import SupportPopupimage from 'component/tag/popup/support/supportPopupimage';
import Paging from "component/tag/div/common/supportPaging";
import { PreviewOutlined } from '@mui/icons-material';

/**
 * Version : 1.0
 * 파일명 : supmyquestion.js
 * 작성일자 : 2022-03-30
 * 작성자 : 정지윤
 * 설명 : 고객센터 내 문의사항 페이지
 * 수정일자 : 2022-04-12
 * 수정자 : 정지윤
 * 수정내역 : 답변 내용, 버튼 클릭방식 변경
*/

function Supmyquestion() {

  let server_url = "";
  let change = '';

  if (process.env.NODE_ENV === "development") {
    server_url =
      process.env.REACT_APP_SERVER_URL + process.env.REACT_APP_SERVER_PORT;
  } else {
    server_url = process.env.REACT_APP_SERVER_URL;
  }

  const PAGING_STATE = useSelector(state => state.pagingsupInfoReducer);

  const dispatch = useDispatch();

  const [myquestionlist, setmyquestionlist] = useState([]);

 const[replace, setreplace] = useState([]);

  const getmyquestionlist = useCallback(() => {
    let config={
        params:{
            skip : PAGING_STATE.PAGE_COUNT,
            amount :PAGING_STATE.ROW_COUNT,
        }
   }
axios.get("/support/myquestionList", config).then((res) => {
    if(res.status ===200){

        setmyquestionlist(res.data);
   
    }
   })

},[PAGING_STATE.PAGE_COUNT, PAGING_STATE.ROW_COUNT]);



const [opennumber, setopennumber] = useState('');

const [answer, setanswer] = useState('');
const [answerfiles, setanswerfiles] = useState('');



const onClickbutton = (value, supportnum) =>{
  setanswer('')
  setanswerfiles('')
  if(value===opennumber){
    setopennumber('');
  }else{
 

    if(supportnum ===undefined){
      setopennumber(value);
      return;
    }else{
    let config={
      params : {
        SUPPORT_NUM : supportnum
      }
    }
    axios.get("/support/getAnswer",config).then((res) =>{
      if(res.status ===200){
        setanswer(res.data.Answerdata)
        setanswerfiles(res.data.Answerfiledata)
        setopennumber(value);

        }
    })
  }

  }
   
}

useEffect(()=>{
  onClickbutton();
},[PAGING_STATE.PAGE_COUNT])


const [imageLink, setImageLink] = useState('');



// const openimage =(data) => {
//     setImageLink(data);
//     openModal();
// }

const [showModal, setShowModal] = useState(false);
const openModal=()=> {
    setShowModal(true);
}
const closeModal = ()=>{
    setShowModal(false);
}



const onClickdeletequestion = (SUPPORT_NUM) =>{
  let config={
    params:{
      SUPPORT_NUM : SUPPORT_NUM,
    }
}
  const obj = {
    TEXT: "정말 삭제하시겠습니까?",
    submitEventHandler: () => { 
      dispatch(closeAlert())
  axios.put("/support/deletedirectquestion",null, config).then((res) =>{
    if(res.status ===200){
      getmyquestionlist();
      setopennumber('')

      const obj = {
        TEXT: "삭제되었습니다.",
        submitEventHandler: () => dispatch(closeAlert()),
      };
      dispatch(openAlert(obj));
      return;
  }
  }
  )
},
  
  };
  dispatch(openConfirm(obj));
  return;
}

const getmyquestionTotal = useCallback(() =>{
  axios.get("/support/myquestiontotal").then((res)=>{
  
    if (res.status ===200){
    const paging_obj ={
      ROW_COUNT: PAGING_STATE.ROW_COUNT,
      PAGE_COUNT: PAGING_STATE.PAGE_COUNT,
      TOTAL: res.data
    }
    dispatch(updatePagingsupInfo(paging_obj));
  }
  })
}, [PAGING_STATE.PAGE_COUNT, PAGING_STATE.ROW_COUNT, dispatch]);

useEffect(() => {
  getmyquestionTotal();
  getmyquestionlist();
}, [getmyquestionTotal,getmyquestionlist]);




// const downloadfiledq =(filenm, orifilenm) =>{
//   const link = "'"+ server_url + "/file/downloadFile?filename=" + filenm + "&downfilename="  +orifilenm +"'"
//   window.open(link);
// }

const  convertHTML = (value)=>{

  return <div dangerouslySetInnerHTML={ {__html: value} }>
  </div>
}


  return (
    <div>
{myquestionlist.length >0 ?
<div>
{myquestionlist.map((data, index) =>(
                                <div key={index} className= 'myquestion_main'>
                                    <div className='myquestion_main_title' id={index}>
                                    {opennumber === (index) 
                                    

                                  ? <div className='myquestion_main_c openmyquestion'> 
                                  <div className='myquestion_open '>
                                         <div  className='cursor'>
                                          <div className='myquestion_closed_title' onClick={()=>onClickbutton(index)}>
                                          <div className='c_title_left'>{data.SUPPORT_TITLE}</div>
                                          <div className='c_title_right'>
                                          <div className='myquestion_writedate'>{data.CREATE_DTTM.substr(0,10)}</div>
                                          <div>{data.SUPPORT_PROGRESS === 'CD1201' 
                                          ? <div className='myquestion_answer_unfinished'>답변 대기중</div>
                                          : <div className='myquestion_answer_complete'>답변 완료</div>
                                          }
                                          </div>
                                          <div className='img_dttm_cursor'><img src="/img/cursorup.png" alt='cursor'></img></div>
                                          </div>
                                      </div>
                                         </div> 
                                         <div className='myquestion_line'>

                                           </div>
                                          <div className='myquestion_small_header'>문의내용</div>
                                        <div className='myquestion_content'>

                                          {{change} = data.SUPPORT_CONTENT.replaceAll("<br />", "\n" )}
                                         
                                        
                                        </div>
                                        
                                        {data.FILE_LIST.length === 0 ? <div></div>:
                                        <div className='myquestion_file'>
                                          <div className='myquestion_file_title'>첨부파일</div>
                                          <div className='myquestion_file_body'>
                                          {data.FILE_LIST.map((data) =>(
                                     
                                            // <div key={data.FILE_NUM} onClick={() =>openimage( <img src={data.FILE_URL} alt=''
                                            //                                                      style={{
                                            //                                                        verticalAlign:"middle",
                                            //                                                        maxHeight : "729px",
                                            //                                                        width : "auto"
                                            //                                                      }}/>)}>
                                            //   <img src={data.FILE_URL} alt=''  className='insertimage'/>
                                            // </div>
                              
                                            <div key={data.FILE_NUM}    className='myquestion_file_txt cursor' onClick={() =>
                                              window.open(
                                                server_url +
                                                  "/file/downloadFile?filename=" +
                                                  data.FILE_NM +
                                                  "&downfilename=" +
                                                  data.ORI_FILE_NM,
                                                "_blank"
                                              )
                                            }>
                                                {data.ORI_FILE_NM}
                                            </div>
                                          
                                          ))}

                                          </div>
                                        </div>
                                        }
                                        { data.SUPPORT_PROGRESS === 'CD1201' ?<div></div> :
                                        (answer===''|| answer=== null || answer===undefined) ? <div></div> :
                                        <div>
                                            <div className='myquestion_line2'></div>
                                            <div className='myquestion_small_header'>답변내용</div>
                                            <div className='myquestion_content'> {convertHTML(answer.ANSWER_CONTENT)}</div>
                                          

                                            {answerfiles.length ===0 ? <div></div> : 
                                            <div className='myquestion_file'>
                                            <div className='myquestion_file_title'>첨부파일</div>
                                            <div className='myquestion_file_body'>{answerfiles.map((data =>(
                                                <div key={data.FILE_NUM}    className='myquestion_file_txt cursor' onClick={() =>
                                                  window.open(
                                                    server_url +
                                                      "/file/downloadFile?filename=" +
                                                      data.FILE_NM +
                                                      "&downfilename=" +
                                                      data.ORI_FILE_NM,
                                                    "_blank"
                                                  )
                                                }>
                                                    {data.ORI_FILE_NM}
                                                </div>
                                            )))}</div>
                                            </div>
                                            }
                                      
                                        </div>
                                        }

                                        <div className='myquestion_line2'> 
                                        
                                        </div>

                                        <div className='myquestion_end'>
                                             <div className='myquestion_email_address'>
                                            {data.EMAIL_ADDRESS}
                                             </div>
                                               <div className='myquestion_end_button'>
                                              {/* <div className='myquestion_update'>수정</div> <div className='myquestion_line3'></div>  */}
                                              <div className='myquestion_delete cursor' onClick={()=>onClickdeletequestion(data.SUPPORT_NUM)}>삭제</div>
                                              </div>  
                                        </div>   
                                          
                                    </div>
                                    </div>  

                                  :<div className='myquestion_main_c'>
                                    <div className='cursor'>
                                    <div>{data.SUPPORT_PROGRESS === 'CD1201' ?
                                           <div className='myquestion_closed_title' onClick={()=>onClickbutton(index)}>
                                          <div className='c_title_left'>{data.SUPPORT_TITLE}</div>
                                          <div className='c_title_right'>
                                          <div className='myquestion_writedate'>{data.CREATE_DTTM.substr(0,10)}</div>
                                          
                                           <div className='myquestion_answer_unfinished'>답변 대기중</div>
                                          
                                          
                                          <div className='img_dttm_cursor'><img src="/img/cursordown.png" alt='cursor'></img></div>
                                          </div>
                                          </div>

                                          : 
                                          <div className='myquestion_closed_title' onClick={()=>onClickbutton(index,data.SUPPORT_NUM)}>
                                          <div className='c_title_left'>{data.SUPPORT_TITLE}</div>
                                          <div className='c_title_right'>
                                          <div className='myquestion_writedate'>{data.CREATE_DTTM.substr(0,10)}</div>
                                          
                                          <div className='myquestion_answer_complete'>답변 완료</div>
                                         
                                         
                                          <div className='img_dttm_cursor'><img src="/img/cursordown.png" alt='cursor'></img></div>
                                          </div>
                                          </div>
                                          }
                                          </div>
                                      </div>
                                      </div>
                                       }

                                    </div>
  
          
                                </div>
                                ))}


</div>



: <div><div style={{textAlign : 'center', marginTop : '60px'}}>문의하신 내용이 없습니다.</div><div  style={{textAlign : 'center', marginTop : '20px'}}>궁금한 사항이 있으시면 1:1 문의를 이용해주세요</div></div>}

                                <div  className='supportpaging'>
            

                               {myquestionlist.length>0 ? <Paging /> : <div></div>} 
                                </div>


                                            <SupportPopupimage
                open = {showModal}
                close = {closeModal}
                image = {imageLink}
            >
                </SupportPopupimage>
    </div>
  )
}

export default Supmyquestion