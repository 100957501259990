import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react'
import "css/profileannouncement.css";
import Paging from "component/tag/div/common/supportPaging";
import { useDispatch, useSelector } from 'react-redux';
import { updatePagingsupInfo } from 'actions';
/**
 * Version : 1.0
 * 파일명 : supannouncement.js
 * 작성일자 : 2022-03-30
 * 작성자 : 정지윤
 * 설명 : 고객센터 공지사항페이지
* 수정일자 : 2022-03-31
 * 수정자 : 정지윤
 * 수정내역 : 공지사항 강조 화면 제작
 * * 수정일자 : 2022-04-12
 * 수정자 : 정지윤
 * 수정내역 : 공지사항 파일부분 수정
*/

function Supannouncement(props) {

  let server_url = "";
  if (process.env.NODE_ENV === "development") {
    server_url =
      process.env.REACT_APP_SERVER_URL + process.env.REACT_APP_SERVER_PORT;
  } else {
    server_url = process.env.REACT_APP_SERVER_URL;
  }
  const [AnnouncementList, setAnnouncementList] = useState([]);

  const PAGING_STATE = useSelector(state => state.pagingsupInfoReducer);

  const dispatch = useDispatch();

  const getAnnouncementList = useCallback(() => {
    let config={
        params:{
            skip : PAGING_STATE.PAGE_COUNT,
            amount :PAGING_STATE.ROW_COUNT,
        }
   }
axios.get("/support/AnnouncementList", config).then((res) => {
    if(res.status ===200){
        setAnnouncementList(res.data);

    }
   })


},[PAGING_STATE.PAGE_COUNT, PAGING_STATE.ROW_COUNT])

useEffect(() =>{
   getAnnouncementList()
},[getAnnouncementList])

// const [opnegetnum, setopengetnum] = useState(props.kkk);
const [opennumber, setopennumber] = useState(props.kkk);

useEffect(()=>{
  if(props.kkk !== ""){
    setopennumber(props.kkk);
  } else{
    return
  }
},[props.kkk])



const getannouncementtotal = useCallback(() =>{
  axios.get("/support/countannouncement").then((res)=>{
    if (res.status ===200){
    const paging_obj ={
      ROW_COUNT: PAGING_STATE.ROW_COUNT,
      PAGE_COUNT: PAGING_STATE.PAGE_COUNT,
      TOTAL: res.data
    }
    dispatch(updatePagingsupInfo(paging_obj));

  }
  })
}, [PAGING_STATE.ROW_COUNT, PAGING_STATE.PAGE_COUNT, dispatch]);

useEffect(() =>{
  getAnnouncementList();
  getannouncementtotal();
},[getAnnouncementList,getannouncementtotal])



const onClickbutton = (value) =>{
  if(opennumber === value){
    setopennumber('');
  }
    else
    {
      setopennumber('');
      setopennumber(value);
      let config={
        params:{
          notice_num : value
        }
   }
    axios.put("/support/updateclickcount",null,config).then((res)=>{
      if(res.status===200){
      }
    });
    }


}

const  convertHTML = (value)=>{

  return <div dangerouslySetInnerHTML={ {__html: value} }>
  </div>
}





  return (
    <div>
            <div className='profile_announcement_main'>
            
                {AnnouncementList.length >0 ? 
                  <div>
                  {AnnouncementList.map((data, index) =>(
                                    <div key={index} className= 'announcement_main'>
                                
                                        {
                                           opennumber === (data.Notice_Num) 
                                           ?
                                            <div className='announcement_body_title_noclick  openannouncement'>
                                             <div className='announcement_body_title_onclick_header cursor' onClick={() =>onClickbutton(data.Notice_Num)}>
                                             <div>{data.Notice_Title}</div><div className='announcement_right'><div className='onclick_dttm'>{data.Create_Dttm.substr(0,10)}</div>  <div className='img_d_cursor'><img src="/img/cursorup.png" alt='cursor'></img></div></div>
                                            </div>
                                            <div className='announcement_body_line'>
                                            </div>
                                             <div className='announcement_body_content'>
                                             {convertHTML(data.Notice_Content)}
                                             </div>
    
                                            <div className='announcement_files'>
                                             {data.FILE_LIST.length === 0 ? <div></div>:
                                            <div className='announcement_file'>
                                              <div className='announcement_file_title'>첨부파일</div>
                                              <div className='announcement_file_body'>
                                              {data.FILE_LIST.map((data) =>(
                                         
                                                // <div key={data.FILE_NUM} onClick={() =>openimage( <img src={data.FILE_URL} alt=''
                                                //                                                      style={{
                                                //                                                        verticalAlign:"middle",
                                                //                                                        maxHeight : "729px",
                                                //                                                        width : "auto"
                                                //                                                      }}/>)}>
                                                //   <img src={data.FILE_URL} alt=''  className='insertimage'/>
                                                // </div>
                                  
                                                <div key={data.FILE_NUM}    className='announcement_file_txt cursor' onClick={() =>
                                                  window.open(
                                                    server_url +
                                                      "/file/downloadFile?filename=" +
                                                      data.FILE_NM +
                                                      "&downfilename=" +
                                                      data.ORI_FILE_NM,
                                                    "_blank"
                                                  )
                                                }>
                                                    {data.ORI_FILE_NM}
                                                </div>
                                              
                                              ))}
    
                                              </div>
                                            </div>
                                            }
                                            </div>
                                            
                                           </div>
    
                                          : <div className='announcement_body_title_noclick'>
                                          {
                                      
                                          data.Notice_Hold_YN === 'Y' ?
                                          <div className='announcement_body_title_noclick_header cursor'onClick={()=>onClickbutton(data.Notice_Num)}>
                                            <div className='announcement_hold'>
                                            <div className='announcement_hold_text'> 공지 </div>
                                           <div>
                                          {data.Notice_Title} 
                                          </div>
                                          </div>
                                          <div className='announcement_right'>
                                          <div className='noclick_dttm'>
                                          {data.Create_Dttm.substr(0,10)}
                                          </div>
                                          <div className='img_d_cursor'><img src="/img/cursordown.png" alt='cursor'></img></div>
                                          </div>
                                         </div>
                                           :
                                          
                                             <div className='announcement_body_title_noclick_header cursor'onClick={()=>onClickbutton(data.Notice_Num)}>
                                             <div className='announcement_body_title_noclick_headertxt'>
                                             {data.Notice_Title} 
                                             </div>
                                             <div className='announcement_right'>
                                             <div className='noclick_dttm'>
                                             {data.Create_Dttm.substr(0,10)}
                                             </div>
                                             <div className='img_d_cursor'><img src="/img/cursordown.png" alt='cursor'></img></div>
                                             </div>
                                            </div>
                                            }
                                           </div>
    
                                        }
    
                                       
      
              
                                    </div>
                                    ))}
                  </div>
                 :
                 <div><div style={{textAlign : 'center', marginTop : '60px'}}>공지사항이 아직 준비되지 않았습니다.</div><div  style={{textAlign : 'center', marginTop : '20px'}}>빠른 시일 내로 찾아뵙겠습니다.</div></div> }
               
              


            </div>

            <div  className='supportpaging'>

            {AnnouncementList.length>0 ? <Paging /> : <div></div>} 
      
                                </div>                   




    </div>
  )
}

export default Supannouncement