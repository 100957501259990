/**
 * Version : 1.0
 * 작성일자 : 2022-05-02
 * 작성자 : 강연승
 * 설명 : 캘린더 액티브 범위 정보
 */
const initalState = {
  ACTIVE_START: "",
  ACTIVE_END: "",
};

const calendarRangeInfo = (state = initalState, action) => {
  switch (action.type) {
    case "calendarRangeInfo/UPDATE":
      return {
        ACTIVE_START: action.data.ACTIVE_START,
        ACTIVE_END: action.data.ACTIVE_END,
      };
    default:
      return state;
  }
};

export default calendarRangeInfo;
