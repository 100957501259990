import React from "react";
import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
import { useSelector, useDispatch } from "react-redux";
import { closePayDonePopup } from "actions/index";
/**
 * Version : 1.0
 * 파일명 : PaySuccessPopup.js
 * 작성일자 : 2022-01-07
 * 작성자 : 권도훈
 * 설명 : 상품 결제 팝업
 * 수정일자 :  2022-04-13
 * 수정자 : 권도훈
 * 수정내역 : UI 및 데이터 수정
 */
function PaySuccessPopup() {
    /**
     * REDUX
     */
    const PROFILE_STATE = useSelector(state => state.profileInfoReducer);
    /**
     * DISPATCH
     */
    const dispatch = useDispatch();
    /**
     * TRANSLATION
     */
    const { t } = useTranslation();
    /**
     * body
     */
    ReactModal.setAppElement("body");
    /**
     * 팝업 닫기
     */
    const closePopup = () => {
        dispatch(closePayDonePopup());
    }
    return (
        <ReactModal
            isOpen={PROFILE_STATE.DONE_POPUP}
            className="py-popup-success"
            overlayClassName="NewAlertOverlay"
            animationType="fade"
            transparent={true}
            closeTimeoutMS={200}
        >
            <p className="py-popup-success-title">
                {t("my.profile.txt.successBuy")}
            </p>

            <img
                src="/img/icon/payment/buy_pass.png"
                alt="DONE"
            />

            <div className="py-popup-success-info top">
                <img
                    src="/img/icon/payment/pay2.png"
                    alt=""
                />

                <section className="py-popup-success-section">
                    <p className="py-popup-success-section-font blue">
                        {PROFILE_STATE.TODAY}
                    </p>

                    <p className="py-popup-success-section-font">
                        {PROFILE_STATE.COUNT}개의 회의이용권이 배정되었습니다.
                    </p>
                </section>
            </div>

            <div className="py-popup-success-info bot">
                <img
                    src="/img/icon/payment/pay3.png"
                    alt=""
                />

                <section className="py-popup-success-section">
                    <p className="py-popup-success-section-font">
                        고객님의 [{PROFILE_STATE.PRODUCT_NM}] 다음 결제일은
                    </p>

                    <p className="py-popup-success-section-font">
                        <font className="py-popup-success-section-font purple">{PROFILE_STATE.PAY_DT}</font> 입니다.
                    </p>
                </section>
            </div>

            <p className="py-popup-success-comment">
                *문의사항은 고객센터를 이용해주시기 바랍니다.
            </p>

            <button
                onClick={() => closePopup()}
                className="py-popup-success-btn cursor"
            >
                {t("common.btn.ok")}
            </button>
        </ReactModal >
    )
}

export default PaySuccessPopup
