import React, { useState } from "react";
import axios from "axios";
import ReactModal from "react-modal";
import AddressPopup from "component/tag/popup/common/addressPopup";
import { useTranslation } from "react-i18next";
// import Modal from "component/common/modal";

/**
 * Version : 1.0
 * 파일명 : makeGroup.js
 * 작성일자 : 2021-10-05
 * 작성자 : 강연승
 * 설명 : 그룹 생성 화면
 * 수정일자 : 2022-05-04
 * 수정자 : 강연승
 * 수정내역 : 그룹 컬러 수정
 */
function MakeGroup(props) {
  /**
   * body
   */
  ReactModal.setAppElement("body");
  /**
   * 언어변환
   */
  const { t } = useTranslation();
  const [members, setMembers] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [groupInput, setGroupInput] = useState({
    group_nm: "",
    group_expln: "",
    group_color: "",
    group_ctgry: "",
    group_member: members,
    group_license: "",
  });
  const [inputs, setInputs] = useState({
    USER_NM: "",
    EMAIL: "",
  });
  const { USER_NM, EMAIL } = inputs;
  const onChangeMember = e => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
    setGroupInput({
      ...groupInput,
      group_member: members,
    });
  };
  const onChangeEvent = e => {
    const { name, value } = e.target;
    setGroupInput({
      ...groupInput,
      [name]: value,
    });
  };

  const handleKeyPress = ({ key }) => {
    if (key === "Enter") {
      onCreate();
    }
  };
  const validationEmail = value => {
    var emailType =
      /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
    return emailType.test(value);
  };
  const onCreate = () => {
    if (EMAIL === "" || validationEmail(EMAIL) === false) {
      return;
    } else {
      for (let i = 0; i < members.length; i++) {
        if (EMAIL === members[i].EMAIL) {
          return;
        }
      }
    }
    if (Array.isArray(addressAll) && addressAll.length > 0) {
      for (let i = 0; i < addressAll.length; i++) {
        if (EMAIL === addressAll[i].ADR_EMAIL) {
          const member = {
            USER_NM: addressAll[i].ADR_NM,
            EMAIL: addressAll[i].ADR_EMAIL,
          };
          setMembers(members.concat(member));
          break;
        } else {
          const member = {
            USER_NM,
            EMAIL,
          };
          setMembers(members.concat(member));
        }
      }
    } else {
      const member = {
        USER_NM,
        EMAIL,
      };
      setMembers(members.concat(member));
    }
    setInputs({
      USER_NM: "",
      EMAIL: "",
    });
  };

  const onRemove = EMAIL => {
    setMembers(members.filter(member => member.EMAIL !== EMAIL));
  };

  const clickAddressItem = (nm, email) => {
    for (let i = 0; i < members.length; i++) {
      if (email === members[i].EMAIL) {
        // alertModal("이미 추가하셨습니다.", "ALREADY");
        alert("이미 추가하셨습니다.");
        return;
      }
    }
    setMembers(members.concat({ USER_NM: nm, EMAIL: email }));
    setGroupInput({
      ...groupInput,
      group_member: members,
    });
    setInputs({
      USER_NM: "",
      EMAIL: "",
    });
  };

  const createGroup = () => {
    if (
      groupInput.group_nm === "" ||
      groupInput.group_nm === null ||
      groupInput.group_nm === undefined ||
      groupInput.group_nm.length < 1
    ) {
      return;
    }
    if (
      groupInput.group_ctgry === "" ||
      groupInput.group_ctgry === null ||
      groupInput.group_ctgry === undefined
    ) {
      groupInput.group_ctgry = "CD0402";
    }
    const formData = new FormData();
    let groupInfo = {
      GROUP_NM: groupInput.group_nm,
      CTGRY_CD: groupInput.group_ctgry,
      GROUP_COLOR: getGroupColor(),
      GROUP_EXPLN: "그룹설명",
    };
    if (Array.isArray(members) && members.length > 0) {
      groupInfo = {
        ...groupInfo,
        participants: members,
      };
    }

    formData.append("groupInfo", JSON.stringify(groupInfo));
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    axios
      .post("/group/insertGroup", formData, config)
      .then(response => {
        if (response.status === 200) {
          setIsSuccess(true);
          // alert("그룹이 생성되었습니다.");
          // alertModal("그룹이 생성되었습니다.", "SUCCESS");
        } else {
          alert("그룹이 생성되지 않았습니다.");
          // alertModal("그룹이 생성되지 않았습니다.", "CANCEL");
        }
      })
      .catch(error => {
        alert("그룹 생성과정에서 오류가 발생하였습니다.");
        // alertModal("그룹 생성과정에서 오류가 발생하였습니다.", "CANCEL");
        console.log(error);
      });
  };

  //임시 랜덤 사용 해당유저의 마지막 그룹색상 데이터 받아서 사용할 예정
  const getRandom = (min, max) => Math.floor(Math.random() * (max - min) + min);
  const getGroupColor = () => {
    let random = getRandom(0, 10);
    switch (random) {
      case 0:
        return "red";
      case 1:
        return "orange";
      case 2:
        return "yellow";
      case 3:
        return "bean";
      case 4:
        return "green";
      case 5:
        return "mint";
      case 6:
        return "pine";
      case 7:
        return "pink";
      case 8:
        return "purple";
      case 9:
        return "black";
      // default:
      //   return "#2B4AC4";
    }
  };
  const dialogClose = () => {
    document.body.style.overflow = "unset";
    props.close();
    if (isSuccess) {
      setIsSuccess(false);
      props.reload();
    }
    if (isActive) {
      setIsActive(false);
    }
    if (addressPopup) {
      setAddressPopup(false);
    }
    if (showGroupThema) {
      setShowGroupThema(false);
    }
    setGroupInput({
      group_nm: "",
      group_expln: "",
      group_color: "",
      group_ctgry: "",
      group_member: [],
      group_license: "",
    });
    setInputs({
      USER_NM: "",
      EMAIL: "",
    });
    setMembers([]);
  };

  const [isActive, setIsActive] = useState(false);
  const activeBtn = e => {
    if (e.target.value.length > 0 && e.target.value.length < 10) {
      setIsActive(true);
    } else if (e.target.value.length < 1 || e.target.value.length > 9) {
      setIsActive(false);
    }
  };

  const [addressPopup, setAddressPopup] = useState(false);
  const handleAddress = type => {
    if (type === true) {
      setAddressPopup(true);
      getAddressAll();
    } else if (type === false) {
      setAddressPopup(false);
    }
  };
  const [addressAll, setAddressAll] = useState([]);
  const getAddressAll = () => {
    axios
      .get("/address/AddressCreator")
      .then(({ data }) => setAddressAll(data))
      .catch(error => {
        console.log(error);
      });
  };

  const clickBackgound = () => {
    if (addressPopup) {
      setAddressPopup(false);
    }
    if (showGroupThema) {
      setShowGroupThema(false);
    }
  };

  const [showGroupThema, setShowGroupThema] = useState(false);
  const themaList = [
    // { name: "default", code: "CD0401" },
    { name: "company", code: "CD0402" },
    { name: "school", code: "CD0403" },
    { name: "private_institute", code: "CD0404" },
    { name: "university", code: "CD0405" },
    { name: "social", code: "CD0406" },
  ];
  /* 그룹 테마 코드 변환 */
  const groupThemaConvert = ctgryCD => {
    switch (ctgryCD) {
      // case "CD0401":
      //   return "default";
      case "CD0402":
        return "company";
      case "CD0403":
        return "school";
      case "CD0404":
        return "private_institute";
      case "CD0405":
        return "university";
      case "CD0406":
        return "social";
      default:
        return "none";
    }
  };
  return (
    <ReactModal
      isOpen={props.open} //모달 Open(Boolean)
      className="makeGroupPopup" //모달 ClassName
      overlayClassName="makeGroupPopupOverlay" //모달오버레이 ClassName
      onRequestClose={dialogClose}
      shouldCloseOnEsc={true}
      animationType="fade"
      transparent={true}
      closeTimeoutMS={200}
    >
      <div className="makeGroupPopup_background" onClick={clickBackgound}>
        {!isSuccess ? (
          <>
            <div className="makeGroupPopup_header">
              <div style={{ display: "inline-block", paddingLeft: "48px" }}>
                그룹만들기
              </div>
              <img
                className="cursor"
                src="/img/icon/group/close.png"
                alt="close"
                onClick={dialogClose}
                style={{
                  display: "inline-block",
                  float: "right",
                  marginRight: "24px",
                }}
              />
            </div>
            <div
              style={{
                marginTop: "18px",
                marginBottom: "35px",
                color: "#3E3E4F",
                font: "normal normal bold 12px/18px Spoqa Han Sans Neo",
              }}
            >
              <a style={{ display: "block" }}>
                사람들을 초대하여 회의이용권을 공유하고
              </a>
              <a style={{ display: "block" }}>
                함께 사용할 수 있는 공간을 만드세요.
              </a>
            </div>
            <input
              className="groupInputValue"
              type="text"
              placeholder="그룹명을 입력하세요."
              name="group_nm"
              value={groupInput.group_nm}
              maxLength="9"
              autoComplete="off"
              autoFocus
              onChange={e => (onChangeEvent(e), activeBtn(e))}
            />
            <div
              style={{
                width: "296px",
                height: "40px",
              }}
              className="cursor groupInputValue groupSelectCtgry"
              onClick={() => {
                setShowGroupThema(!showGroupThema);
              }}
            >
              <div className="inline-block" style={{ paddingLeft: "35px" }}>
                {t(
                  "group.category." + groupThemaConvert(groupInput.group_ctgry)
                )}
              </div>
              <img
                className="inline-block"
                style={{
                  float: "right",
                  marginTop: "12px",
                  marginRight: "17px",
                }}
                src={
                  showGroupThema
                    ? "/img/icon/group/category_up.png"
                    : "/img/icon/group/category_down.png"
                }
                alt="DROP"
              />
            </div>
            {showGroupThema ? (
              <div
                className="groupSelectThemaPopup"
                style={{ display: showGroupThema ? "block" : "none" }}
              >
                {themaList.map(data => (
                  <div
                    className="groupSelectThemaPopupItem"
                    key={data.name}
                    onClick={() => (
                      setGroupInput({
                        ...groupInput,
                        group_ctgry: data.code,
                      }),
                      setShowGroupThema(!showGroupThema)
                    )}
                  >
                    <div>{t("group.category." + data.name)}</div>
                  </div>
                ))}
              </div>
            ) : null}
            <AddMember
              className="groupInputValue"
              name="EMAIL"
              onChange={onChangeMember}
              email={EMAIL}
              onFocus={() => handleAddress(true)}
              onKeyPress={handleKeyPress}
            />
            <AddressPopup
              className="makeGroupAddressPopup scrollbar"
              open={addressPopup}
              address={addressAll}
              clickAddressItem={clickAddressItem}
              search={inputs.EMAIL}
            />
            <div>
              {members.length > 0 ? (
                <div
                  style={{
                    marginTop: "8px",
                    marginBottom: "4px",
                    color: "#2B4AC4",
                    font: "normal normal bold 12px/18px Spoqa Han Sans Neo",
                  }}
                >
                  미팅호스트 초대
                </div>
              ) : (
                <div
                  style={{
                    marginTop: "8px",
                    color: "#6E85D7",
                    font: "normal normal bold 12px/18px Spoqa Han Sans Neo",
                  }}
                >
                  미팅호스트를 추가하지 않고도 그룹을 만들 수 있습니다.
                </div>
              )}
              <MemberList
                // className=""
                members={members}
                onRemove={onRemove}
              />
            </div>
            <button
              className={isActive ? "groupCreateBtnActive" : "groupCreateBtn"}
              onClick={createGroup}
              style={{ marginTop: "32px", marginBottom: "40px" }}
              disabled={!isActive}
            >
              그룹 만들기
            </button>
          </>
        ) : (
          <>
            <div className="makeGroupPopup_header">
              <div style={{ display: "inline-block", paddingLeft: "48px" }}>
                그룹 만들기 완료!
              </div>
              <img
                src="/img/icon/group/close.png"
                alt="close"
                onClick={dialogClose}
                style={{
                  display: "inline-block",
                  float: "right",
                  marginRight: "24px",
                }}
              />
            </div>

            <div style={{ marginTop: "24px", marginBottom: "32px" }}>
              <a
                style={{
                  color: "#3E3E4F",
                  font: "normal normal bold 12px/18px Spoqa Han Sans Neo",
                }}
              >
                그룹 [{groupInput.group_nm}]가 만들어졌습니다!
              </a>
            </div>
            <div>
              <img src="/img/made.png" alt="made" />
            </div>

            {members.length > 0 ? (
              <div>
                <div
                  style={{
                    marginTop: "32px",
                    marginBottom: "4px",
                    color: "#2B4AC4",
                    font: "normal normal bold 12px/18px Spoqa Han Sans Neo",
                  }}
                >
                  다음의 사람들에게 그룹 초대장을 보냈습니다!
                </div>
                <div
                  className="scrollbar"
                  style={{
                    font: "normal normal normal 14px/18px Spoqa Han Sans Neo",
                    color: "#3E3E4F",
                    maxHeight: "100px",
                    overflowY: "auto",
                  }}
                >
                  {members.map(data =>
                    data.USER_NM !== null && data.USER_NM !== undefined ? (
                      <div
                        key={data.EMAIL}
                        style={{ height: "18px", padding: "5px 0 9px 0" }}
                      >
                        <a style={{ marginRight: "17px" }}>{data.USER_NM}</a>
                        <a>{data.EMAIL}</a>
                      </div>
                    ) : (
                      <div
                        key={data.EMAIL}
                        style={{
                          height: "18px",
                          padding: "5px 0 9px 0",
                          textAlign: "center",
                        }}
                      >
                        <a>{data.PRTCP_EMAIL}</a>
                      </div>
                    )
                  )}
                </div>
              </div>
            ) : null}

            <button
              className="groupCreateBtnActive"
              onClick={dialogClose}
              style={{
                marginTop: members.length > 0 ? "32px" : "40px",
                marginBottom: "40px",
              }}
            >
              확인
            </button>
          </>
        )}
      </div>
    </ReactModal>
  );
}

function AddMember({ className, name, email, onChange, onKeyPress, onFocus }) {
  return (
    <input
      className={className}
      name={name}
      placeholder="초대할 미팅호스트의 메일을 입력하세요."
      onChange={onChange}
      onKeyPress={onKeyPress}
      onFocus={onFocus}
      value={email}
      onClick={e => e.stopPropagation()}
      autoComplete="off"
    />
  );
}

function Member({ member, onRemove }) {
  return (
    <li
      style={{
        height: "18px",
        clear: "both",
        font: "normal normal normal 14px/18px Spoqa Han Sans Neo",
        color: "#3E3E4F",
        padding: "5px 0 9px 0",
      }}
    >
      <div
        style={{
          float: "left",
          marginLeft: member.USER_NM !== "" ? "24px" : "42px",
          marginRight: member.USER_NM !== "" ? "17px" : "35px",
        }}
      >
        {member.USER_NM !== null &&
        member.USER_NM !== undefined &&
        member.USER_NM !== "" ? (
          <a>{member.USER_NM}</a>
        ) : (
          <a style={{ textAlign: "center" }}>-</a>
        )}
      </div>
      <div style={{ float: "left" }}>{member.EMAIL}</div>
      <div style={{ float: "right", marginRight: "16px" }}>
        <img
          style={{ cursor: "pointer" }}
          src="/img/icon/group/delete.png"
          alt="delete"
          onClick={() => onRemove(member.EMAIL)}
        />
      </div>
    </li>
  );
}

function MemberList({ members, onRemove }) {
  return (
    <div
      className="scrollbar"
      style={{
        width: "296px",
        margin: "0 auto",
        maxHeight: "100px",
        overflowY: "auto",
      }}
    >
      <ul>
        {members.map(member => (
          <Member member={member} key={member.EMAIL} onRemove={onRemove} />
        ))}
      </ul>
    </div>
  );
}

export default MakeGroup;
