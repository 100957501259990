import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loading from "component/tag/loading/loading";
import DataStatisticsDetailPopup from "component/tag/popup/group/data/dataStatisticsDetailPopup";
import Paging from "component/tag/div/common/statisticsPaging";
/**
 * Version : 1.0
 * 파일명 : DataStatisticsDetail.js
 * 작성일자 : 2022-03-04
 * 작성자 : 권도훈
 * 설명 : 데이터 통계 상세보기
 * 수정일자 : 2022-04-15
 * 수정자 : 강연승
 * 수정내역 : 테마별 텍스트 적용
 */
function DataStatisticsDetail(props) {
  /**
   * REDUCER
   */
  const STATISTICS_STATE = useSelector(state => state.statisticsInfoReducer);
  const GROUP_STATE = useSelector(state => state.groupInfoReducer);
  /**
   * 참여율
   */
  const calRateText = (total, invite_total) => {
    let rate = 0;

    if (total > 0) {
      // rate = Math.floor((total / STATISTICS_STATE.detail_data.TOTAL) * 100);
      if (invite_total > 0) {
        rate = Math.floor((total / invite_total) * 100);
      } else {
        rate = total * 100;
      }
    }

    return rate + " / 100";
  };
  const calRate = (count, total) => {
    let rate = 0;

    if (total > 0) {
      rate = Math.floor((count / total) * 100);
    }

    return rate + "%";
  };
  /**
   * 참석자 상세 팝업
   */
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [participantInfo, setParticipantInfo] = useState({});
  const openPopup = data => {
    setIsOpenPopup(true);
    setParticipantInfo(data);
  };
  const closePopup = () => {
    setIsOpenPopup(false);
  };

  return (
    <div className="data-statistics-container-detail">
      <DataStatisticsDetailPopup
        open={isOpenPopup}
        close={closePopup}
        data={participantInfo}
        date={props.dateDetail}
        time={props.timeDetail}
      />
      <article>
        <ul className="data-statistics-detail-ul">
          <li>
            <div
              className="data-statistics-list-profile"
              style={{
                background: STATISTICS_STATE.detail_data.BACKGROUND_COLOR,
              }}
            >
              {STATISTICS_STATE.isDetail &&
                STATISTICS_STATE.detail_data.NAME !== ""
                ? STATISTICS_STATE.detail_data.NAME.substr(0, 1)
                : ""}
            </div>
          </li>

          <li className="data-statistics-detail-li name">
            <div className="data-statistics-detail-li-nowrap name" title={STATISTICS_STATE.detail_data.NAME}>
              {STATISTICS_STATE.detail_data.NAME}
            </div>
          </li>

          <li className="data-statistics-detail-li">
            <div className="data-statistics-detail-li-nowrap email" title={STATISTICS_STATE.detail_data.EMAIL}>
              {STATISTICS_STATE.detail_data.EMAIL}
            </div>
          </li>

          <li className="data-statistics-detail-li div total">
            <div>
              <img src="/img/icon/data/icon_number.png" alt="total" />
              <font>
                {GROUP_STATE.GROUP_CATEGORY === "CD0401" ||
                  GROUP_STATE.GROUP_CATEGORY === "CD0402"
                  ? "총 개설 회의 수"
                  : GROUP_STATE.GROUP_CATEGORY === "CD0403"
                    ? "총 개설 수업 수"
                    : GROUP_STATE.GROUP_CATEGORY === "CD0404" ||
                      GROUP_STATE.GROUP_CATEGORY === "CD0405"
                      ? "총 개설 강의 수"
                      : "총 개설 모임 수"}
              </font>
            </div>
            <font>{STATISTICS_STATE.detail_data.TOTAL}</font>
          </li>

          <li className="data-statistics-detail-li div duration">
            <div>
              <img src="/img/icon/data/icon_time.png" alt="total" />
              <font>
                {GROUP_STATE.GROUP_CATEGORY === "CD0401" ||
                  GROUP_STATE.GROUP_CATEGORY === "CD0402"
                  ? "총 회의시간"
                  : GROUP_STATE.GROUP_CATEGORY === "CD0403"
                    ? "총 수업시간"
                    : GROUP_STATE.GROUP_CATEGORY === "CD0404" ||
                      GROUP_STATE.GROUP_CATEGORY === "CD0405"
                      ? "총 강의시간"
                      : "총 모임시간"}
              </font>
            </div>
            <font>{STATISTICS_STATE.detail_data.DURATION + "분"}</font>
          </li>

          <li className="data-statistics-detail-li div count">
            <div>
              <img src="/img/icon/data/icon_attendee.png" alt="total" />
              <font>참석인원/초대인원</font>
            </div>
            <font>
              {/* {STATISTICS_STATE.detail_data.PARTICIPANT_COUNT + "명"} */}
              {STATISTICS_STATE.detail_data.PARTICIPANT_COUNT +
                "/" +
                (STATISTICS_STATE.detail_data.INVITE_PARTICIPANTS_COUNT +
                  STATISTICS_STATE.detail_data.NON_INVITE_PARTICIPANTS_COUNT)}
            </font>
          </li>

          <li className="data-statistics-detail-li div rate">
            <div>
              <img src="/img/icon/data/icon_rate.png" alt="total" />
              <font>
                {GROUP_STATE.GROUP_CATEGORY === "CD0403" ||
                  GROUP_STATE.GROUP_CATEGORY === "CD0404" ||
                  GROUP_STATE.GROUP_CATEGORY === "CD0405"
                  ? "평균 출석률"
                  : "평균 참석률"}
              </font>
            </div>
            <font>
              {/* {STATISTICS_STATE.detail_data.ATTENDANCE_RATE + "%"} */}
              {calRate(
                STATISTICS_STATE.detail_data.PARTICIPANT_COUNT,
                STATISTICS_STATE.detail_data.INVITE_PARTICIPANTS_COUNT +
                STATISTICS_STATE.detail_data.NON_INVITE_PARTICIPANTS_COUNT
              )}
            </font>
          </li>
        </ul>
      </article>

      <div className="data-statistics-detail-div">
        {props.loading ? (
          <div className="data-statistics-container-loading">
            <Loading />
          </div>
        ) : props.data.length > 0 ? (
          <>
            <table className="data-statistics-detail-table">
              <colgroup>
                <col width="7px" />
                <col width="36px" />
                <col width="7px" />
                <col width="133px" />
                <col width="7px" />
                <col width="176px" />
                <col width="7px" />
                <col width="140px" />
                <col width="7px" />
                <col width="90px" />
                <col width="7px" />
                <col width="107px" />
                <col width="7px" />
                <col width="130px" />
                <col width="7px" />
              </colgroup>

              <thead className="data-statistics-detail-thead">
                <tr className="data-statistics-detail-tr">
                  <th />
                  <th />
                  <th />
                  <th className="data-statistics-detail-flex">
                    <font>
                      {GROUP_STATE.GROUP_CATEGORY === "CD0401" ||
                        GROUP_STATE.GROUP_CATEGORY === "CD0402"
                        ? "회의 참석자"
                        : GROUP_STATE.GROUP_CATEGORY === "CD0403"
                          ? "수업 참석자"
                          : GROUP_STATE.GROUP_CATEGORY === "CD0404" ||
                            GROUP_STATE.GROUP_CATEGORY === "CD0405"
                            ? "강의 참석자"
                            : "모임 참석자"}
                    </font>
                    <img
                      className="data-statistics-list-sort cursor"
                      src={
                        props.sortDetail.type === "PARTICIPANT_NAME" &&
                          props.sortDetail.direction === "DESC"
                          ? "/img/icon/data/sort_reverse_black.png"
                          : "/img/icon/data/sort_black.png"
                      }
                      alt="sort"
                      onClick={() => {
                        props.setSortDetail({
                          type: "PARTICIPANT_NAME",
                          direction:
                            props.sortDetail.direction === "DESC"
                              ? "ASC"
                              : "DESC",
                        });
                        props.setDetailSearch(true);
                      }}
                    />
                  </th>
                  <th />
                  <th>참석자 메일</th>
                  <th />
                  <th className="data-statistics-detail-flex">
                    <font>
                      {GROUP_STATE.GROUP_CATEGORY === "CD0401" ||
                        GROUP_STATE.GROUP_CATEGORY === "CD0402"
                        ? "참석회의/초대회의"
                        : GROUP_STATE.GROUP_CATEGORY === "CD0403"
                          ? "참석수업/초대수업"
                          : GROUP_STATE.GROUP_CATEGORY === "CD0404" ||
                            GROUP_STATE.GROUP_CATEGORY === "CD0405"
                            ? "참석강의/초대강의"
                            : "참석모임/초대모임"}
                    </font>
                    <img
                      className="data-statistics-list-sort cursor"
                      src={
                        props.sortDetail.type === "TOTAL" &&
                          props.sortDetail.direction === "DESC"
                          ? "/img/icon/data/sort_reverse_black.png"
                          : "/img/icon/data/sort_black.png"
                      }
                      alt="sort"
                      onClick={() => {
                        props.setSortDetail({
                          type: "TOTAL",
                          direction:
                            props.sortDetail.direction === "DESC"
                              ? "ASC"
                              : "DESC",
                        });
                        props.setDetailSearch(true);
                      }}
                    />
                  </th>
                  <th />
                  <th className="data-statistics-detail-flex">
                    <font>
                      {GROUP_STATE.GROUP_CATEGORY === "CD0403"
                        ? "수업시간"
                        : GROUP_STATE.GROUP_CATEGORY === "CD0404" ||
                          GROUP_STATE.GROUP_CATEGORY === "CD0405"
                          ? "강의시간"
                          : "참여시간"}
                    </font>
                    <img
                      className="data-statistics-list-sort cursor"
                      src={
                        props.sortDetail.type === "DURATION_TOTAL" &&
                          props.sortDetail.direction === "DESC"
                          ? "/img/icon/data/sort_reverse_black.png"
                          : "/img/icon/data/sort_black.png"
                      }
                      alt="sort"
                      onClick={() => {
                        props.setSortDetail({
                          type: "DURATION_TOTAL",
                          direction:
                            props.sortDetail.direction === "DESC"
                              ? "ASC"
                              : "DESC",
                        });
                        props.setDetailSearch(true);
                      }}
                    />
                  </th>
                  <th />
                  <th>
                    {GROUP_STATE.GROUP_CATEGORY === "CD0403" ||
                      GROUP_STATE.GROUP_CATEGORY === "CD0404" ||
                      GROUP_STATE.GROUP_CATEGORY === "CD0405"
                      ? "평균 출석률 (%)"
                      : "평균 참석률 (%)"}
                  </th>
                  <th />
                  <th>
                    {GROUP_STATE.GROUP_CATEGORY === "CD0401" ||
                      GROUP_STATE.GROUP_CATEGORY === "CD0402"
                      ? "회의 시간"
                      : GROUP_STATE.GROUP_CATEGORY === "CD0403" ||
                        GROUP_STATE.GROUP_CATEGORY === "CD0404" ||
                        GROUP_STATE.GROUP_CATEGORY === "CD0405"
                        ? "출석부"
                        : "친밀도"}
                  </th>
                  <th />
                </tr>
              </thead>

              <tbody className="data-statistics-detail-tbody">
                {props.data.map((data, idx) => (
                  <tr
                    className="data-statistics-detail-tr tbody cursor"
                    key={"detail_" + idx}
                    onClick={() => openPopup(data)}
                  >
                    <td />
                    <td>
                      <div
                        className="data-statistics-list-profile"
                        style={{
                          background: data.BACKGROUND_COLOR,
                        }}
                      >
                        {data.NAME !== "" && data.NAME !== undefined
                          ? data.NAME.substr(0, 1)
                          : data.EMAIL.substr(0, 1)}
                      </div>
                    </td>
                    <td />
                    {/* <td className="data-statistics-detail-flex td"> */}
                    <td>
                      <div
                        className="data-statistics-detail-name"
                        title={
                          data.NAME !== "" &&
                            data.NAME !== null &&
                            data.NAME !== undefined
                            ? data.NAME
                            : "-"
                        }
                      >
                        {
                          data.NAME !== "" &&
                            data.NAME !== null &&
                            data.NAME !== undefined
                            ? data.NAME
                            : "-"
                          // : data.EMAIL.split("@")[0]
                        }
                      </div>
                    </td>
                    <td />
                    <td>
                      <div className="data-statistics-detail-email" title={data.EMAIL}>
                        {data.EMAIL}
                      </div>
                    </td>
                    <td />
                    <td>{data.TOTAL + "/" + data.INVITE_TOTAL}</td>
                    <td />
                    <td>{data.DURATION_TOTAL}분</td>
                    <td />
                    <td>{data.RATE.toFixed(0) + "%"}</td>
                    {/* <td>{calRateText(data.TOTAL, data.INVITE_COUNT)}</td> */}
                    <td />
                    {GROUP_STATE.GROUP_CATEGORY === "CD0401" ||
                      GROUP_STATE.GROUP_CATEGORY === "CD0402" ? (
                      <td className="data-statistics-detail-time">
                        <div className="data-statistics-detail-time-work">
                          {data.WORK_TIME_TOTAL}
                        </div>
                        <div className="data-statistics-detail-time-overwork">
                          {data.WEEK_WORK_TIME_TOTAL}
                        </div>
                        <div className="data-statistics-detail-time-week">
                          {data.WORK_OVERTIME_TOTAL}
                        </div>
                      </td>
                    ) : GROUP_STATE.GROUP_CATEGORY === "CD0406" ? (
                      <td className="data-statistics-detail-community">
                        <img
                          src={
                            data.RATE < 40
                              ? "/img/icon/data/bad.png"
                              : "/img/icon/data/bad_Inactive.png"
                          }
                          alt="BAD"
                        />
                        <img
                          src={
                            data.RATE >= 40 && data.RATE <= 70
                              ? "/img/icon/data/soso.png"
                              : "/img/icon/data/soso_Inactive.png"
                          }
                          alt="SOSO"
                        />
                        <img
                          src={
                            data.RATE > 70
                              ? "/img/icon/data/good.png"
                              : "/img/icon/data/good_Inactive.png"
                          }
                          alt="GOOD"
                        />
                      </td>
                    ) : (
                      <td className="data-statistics-detail-time">
                        <div className="data-statistics-detail-attendance">
                          {data.ATTENDANCE_COUNT}
                        </div>
                        <div className="data-statistics-detail-tardy">
                          {data.TARDY_COUNT}
                        </div>
                        <div className="data-statistics-detail-absebce">
                          {data.ABSEBCE_COUNT}
                        </div>
                      </td>
                    )}
                    <td />
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="data-statistics-detail-paging">
              <Paging
                page={props.page}
                setPage={props.setPage}
                total={props.total}
                setSearch={props.setDetailSearch}
              />
            </div>
          </>
        ) : (
          <div className="data-statistics-container-empty">
            <img src="/img/icon/data/no_status_new.png" alt="NO DATA" />
            <section>
              <font>아직 데이터가 없습니다!</font>
              <font>미팅 데이터가 누적되면 통계가 생성됩니다.</font>
            </section>
          </div>
        )}
      </div>
    </div>
  );
}

export default DataStatisticsDetail;
