/**
 * Import List
 */
import React, { useEffect, useMemo, useRef, useState } from "react";
import axios from "axios";
import Naver from "component/api/naver";
import Kakao from "component/api/kakao";
import { useHistory, useLocation } from "react-router";
import Google from "component/api/google";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";
import LoginFailPopup from "component/tag/popup/account/loginFailPopup";
import { updateFreeLicenseInfo } from "actions/index";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import DormantLoginPopUp from "component/tag/popup/my/home/dormantLoginPopUp";
/**
 * Version : 1.0
 * 파일명 : login.js
 * 작성일자 : 2021-09-30
 * 작성자 : 권도훈
 * 설명 : 사용자 로그인 페이지
 * 수정일자 : 2022-07-14
 * 수정자 : 강연승
 * 수정내역 : 로그인시 그룹 리스트로 이동
 */
function Login() {
  //cookie
  const domain = process.env.REACT_APP_COOKIE_DOMAIN;
  const typeNm = process.env.REACT_APP_COOKIE_REQUEST_TYPE;
  const refreshNm = process.env.REACT_APP_COOKIE_REFRESH;
  const authNm = process.env.REACT_APP_COOKIE_AUTH;
  const loginTypeNm = process.env.REACT_APP_COOKIE_TYPE;
  const autoLoginNm = process.env.REACT_APP_COOKIE_AUTO_LOGIN;

  const dispatch = useDispatch();
  /**
   * 언어변환
   */
  const { t } = useTranslation();
  /**
   * useLocation
   */
  const location = useLocation();
  /**
   * code
   */
  const [code, setCode] = useState();
  const [inputs, setInputs] = useState({ ID: "", PW: "" });
  const [autoCheck, setAutoCheck] = useState(false);
  const [errorMsg, setErrorMsg] = useState(" ");
  const [isFail, setIsFail] = useState(false);
  const { ID, PW } = inputs;
  const idInput = useRef();
  const pwInput = useRef();
  const [dormantID, setdormantID] = useState("");
  /**
   * 로딩
   */
  const [loading, setLoading] = useState(false);
  /**
   * 로그인 요청 타입
   */
  const [loginType, setLoginType] = useState("");
  /**
   * history
   */
  const history = useHistory();

  var cookies = useMemo(() => {
    return new Cookies();
  }, []);

  useEffect(() => {
    // if (location.search !== "") {
    if (location.state !== undefined) {
      // setMakeAccountBtn(location.state.tag);
      setLoginType(location.state.type);
    } else {
      if (location.search !== "") {
        setLoginType("inviteGroup");
        setCode(new URL(window.location.href).searchParams.get("code"));
      } else {
        setLoginType("default");
      }
    }
  }, [location]);

  /**
   * 사용자 로그인 여부 체크
   */
  useEffect(() => {
    const isAutoLogin = cookies.get(autoLoginNm);
    const type = cookies.get(loginTypeNm);
    const refreshToken = cookies.get(refreshNm);
    const authToken = cookies.get(authNm);

    const config = {
      params: {
        Request_Token: refreshToken,
      },
    };

    if (
      type !== undefined &&
      refreshToken !== undefined &&
      authToken !== undefined
    ) {
      setLoading(true);
      //재발급 만들어야함
      axios
        .put("/user/token", null, config)
        .then(res => {
          cookies.remove(authNm, {
            path: "/",
            domain: domain,
          });

          if (res.status === 200) {
            cookies.set(authNm, res.data.AUTH, {
              path: "/",
              maxAge: 60 * 60,
              domain: domain,
            });

            cookies.set(loginTypeNm, res.data.TYPE, {
              path: "/",
              maxAge: 60 * 60,
              domain: domain,
            });

            if (res.data.REFRESH !== undefined) {
              cookies.set(refreshNm, res.data.REFRESH, {
                path: "/",
                maxAge: 24 * 60 * 60 * 30,
                domain: domain,
              });
            }

            cookies.remove(typeNm, {
              path: "/",
              domain: domain,
            });
            // checkFreeAccount();
            const config = {
              DISPLAY: true,
            };
            dispatch(updateFreeLicenseInfo(config));
            history.push("/app/group");
            // history.push("/app/home");
            // window.location.href = "/app/home";
          } else {
            setLoading(false);
            return;
          }
        })
        .catch(() => {
          setLoading(false);
          return;
        });
    } else if (isAutoLogin !== undefined) {
      if (authToken !== undefined) {
        setLoading(true);
        //재발급 만들어야함.
        axios
          .put("/user/token", null, config)
          .then(res => {
            cookies.remove(authNm, {
              path: "/",
              domain: domain,
            });

            if (res.status === 200) {
              cookies.set(authNm, res.data.AUTH, {
                path: "/",
                maxAge: 60 * 60,
                domain: domain,
              });

              cookies.set(loginTypeNm, res.data.TYPE, {
                path: "/",
                maxAge: 60 * 60,
                domain: domain,
              });

              if (res.data.REFRESH !== undefined) {
                cookies.set(refreshNm, res.data.REFRESH, {
                  path: "/",
                  maxAge: 24 * 60 * 60 * 30,
                  domain: domain,
                });
              }

              cookies.remove(typeNm, {
                path: "/",
                domain: domain,
              });

              const config = {
                DISPLAY: true,
              };
              dispatch(updateFreeLicenseInfo(config));
              // history.push("/app/home");
              history.push("/app/group");
            } else {
              setLoading(false);
              return;
            }
          })
          .catch(() => {
            setLoading(false);
            return;
          });
      } else {
        cookies.remove(autoLoginNm, {
          path: "/",
          domain: domain,
        });
        return;
      }
    }
  }, [
    authNm,
    autoLoginNm,
    cookies,
    dispatch,
    domain,
    history,
    loginType,
    loginTypeNm,
    refreshNm,
    typeNm,
  ]);

  const checkOnChangeEvent = () => {
    if (cookies.get(autoLoginNm) !== undefined) {
      if (autoCheck) {
        setAutoCheck(false);
        cookies.remove(autoLoginNm, {
          path: "/",
          domain: domain,
        });
      } else {
        setAutoCheck(true);
      }
    } else {
      if (autoCheck) {
        setAutoCheck(false);
      } else {
        setAutoCheck(true);
        cookies.set(autoLoginNm, "checked", {
          path: "/",
          maxAge: 24 * 60 * 60 * 30,
          domain: domain,
        });
      }
    }
  };

  const createToken = () => {
    const config = {
      params: {
        Request_ID: ID,
      },
    };

    axios
      .post("/user/token", null, config)
      .then(res => {
        if (res.status === 200) {
          cookies.set(authNm, res.data.AUTH, {
            path: "/",
            maxAge: 60 * 60,
            domain: domain,
          });

          cookies.set(refreshNm, res.data.REFRESH, {
            path: "/",
            maxAge: 24 * 60 * 60 * 30,
            domain: domain,
          });

          axios
            .get("/user/member", { params: { request_id: ID } })
            .then(res => {
              if (res.status === 200) {
                cookies.remove(typeNm, {
                  path: "/",
                  domain: domain,
                });

                if (loginType === "default") {
                  // checkFreeAccount();
                  const config = {
                    DISPLAY: true,
                  };
                  dispatch(updateFreeLicenseInfo(config));
                  // history.push("/app/home");
                  history.push("/app/group");
                  // window.location.href = "/app/home";
                } else if (loginType === "price") {
                  history.push({
                    pathname: "/app/home/profile",
                    state: {
                      showPopup: true,
                    },
                  });
                } else if (loginType === "delete") {
                  history.push({
                    pathname: "/delete",
                    state: {
                      NAME: res.data[0].User_Nm,
                      EMAIL: res.data[0].User_Mail,
                      TYPE: res.data[0].User_Type,
                    },
                  });
                } else if (loginType === "inviteGroup") {
                  const config = {
                    params: {
                      code: code,
                    },
                  };
                  axios
                    .get("/group/inviteGroup", config)
                    .then(res => {
                      if (res.status === 200) {
                        history.push({
                          pathname: "/app/group",
                        });
                      } else {
                        alert("잘못된 요청입니다.");
                        window.location.href = "/login?code=" + code;
                      }
                    })
                    .catch(err => {
                      console.log(err);
                    });
                }
              } else {
                setLoading(false);
              }
            })
            .catch(() => {
              setLoading(false);
            });
        }
      })
      .catch(error => {
        setLoading(false);
        axios.post("/user/error", null, { headers: { error_info: error } });
      });
  };

  const onChange = e => {
    const { value, name } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const goLogin = () => {
    setLoading(true);

    if (inputs.ID === "") {
      setErrorMsg("아이디가 비었습니다.");
      setLoading(false);
      return false;
    } else if (inputs.PW === "") {
      setErrorMsg("비밀번호가 비었습니다.");
      setLoading(false);
      return false;
    }

    const params = {
      Request_ID: ID,
      Request_PW: PW,
    };

    axios
      .post("/user/login", params)
      .then(res => {
        if (res.status === 200) {
          if (res.data[0].Use_YN === "N") {
            setdormantID(res.data[0].USER_MAIL);
            setLoading(false);
            opendormant();
          } else {
            cookies.set(loginTypeNm, res.data[0].USER_TYPE, {
              path: "/",
              maxAge: 60 * 60,
              domain: domain,
            });

            createToken();
          }
        } else {
          setIsFail(true);
          setLoading(false);
          return false;
        }
      })
      .catch(error => {
        axios.post("/user/error", null, { headers: { error_info: error } });
        setIsFail(true);
        setInputs({
          ...inputs,
          PW: "",
        });
        setLoading(false);
        idInput.current.focus();
      });
  };

  const [showModal, setShowModal] = useState(false);

  const openDModal = () => {
    setShowModal(true);
  };
  const closeDModal = () => {
    setShowModal(false);
  };

  const opendormant = () => {
    openDModal();
  };

  const logoRender = () => {
    const url = new URL(window.location.href).host.toLowerCase();
    const list = ["yonsei", "kentech", "sy"];
    let logo = "logo";

    list.forEach(el => {
      if (url.includes(el)) {
        logo = "/" + el + "/" + logo;
        return logo;
      }
    });

    return logo;
  }

  return (
    <div
      style={{
        height: "100%",
        position: "absolute",
        width: "100%",
        backgroundImage: "url(/img/login_background.png)",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <DormantLoginPopUp open={showModal} close={closeDModal} ID={dormantID} />
      <LoginFailPopup isFail={isFail} setIsFail={setIsFail} />

      <div
        style={{
          padding: "0 15%",
          height: "100%",
          width: "70%",
          position: "fixed",
          overflowY: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            paddingTop: "40px",
          }}
        >
          <img
            className="cursor"
            src={"/img/" + logoRender() + ".png"}
            alt="logo_title"
            onClick={() => (window.location.href = "/")}
          />
        </div>

        <section
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            // paddingTop: "56px", 기본
            paddingTop: "180px",
          }}
        >
          <div
            style={{
              color: "#2B4AC4",
              fontSize: "32px",
              fontWeight: "bold",
            }}
          >
            LOGIN
          </div>

          <div
            style={{
              height: "58px",
              display: "flex",
              alignItems: "center",
              width: "384px",
              justifyContent: "center",
              color: "#C63353",
              fontSize: "14px",
            }}
          >
            {errorMsg}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <font
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                color: "#3E3E4F",
              }}
            >
              이메일
            </font>

            <input
              type="text"
              placeholder="메일을 입력해주세요."
              name="ID"
              value={ID}
              ref={idInput}
              onChange={onChange}
              autoComplete="off"
              onKeyDown={e => {
                if (e.key === "Enter") {
                  pwInput.current.focus();
                }
              }}
              style={{
                background: "#FFFFFF",
                border: "1px solid #DBE0F5",
                borderRadius: "12px",
                width: "384px",
                height: "48px",
                outline: "none",
                marginTop: "8px",
              }}
            // className="Login_Input w-full border-none"
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingTop: "16px",
            }}
          >
            <font
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                color: "#3E3E4F",
              }}
            >
              비밀번호
            </font>

            <input
              type="password"
              placeholder="비밀번호를 입력해주세요."
              name="PW"
              value={PW}
              ref={pwInput}
              onChange={onChange}
              onKeyDown={e => {
                if (e.key === "Enter") {
                  goLogin();
                }
              }}
              style={{
                background: "#FFFFFF",
                border: "1px solid #DBE0F5",
                borderRadius: "12px",
                width: "384px",
                height: "48px",
                outline: "none",
                marginTop: "8px",
              }}
            />
          </div>

          <button
            className="cursor"
            style={{
              width: "384px",
              height: "48px",
              background: loading ? "#9B9FAF" : "#2B4AC4",
              borderRadius: "12px",
              border: "none",
              outline: "none",
              color: "#FFFFFF",
              fontWeight: "bold",
              fontSize: "14px",
              marginTop: "16px",
            }}
            onClick={() => goLogin()}
          >
            {loading ? (
              <CircularProgress
                style={{
                  width: "20px",
                  height: "20px",
                }}
              />
            ) : (
              "로그인"
            )}
          </button>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "384px",
              marginTop: "16px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                className="cursor"
                onChange={checkOnChangeEvent}
                checked={cookies.get(autoLoginNm) !== undefined ? true : false}
                style={{}}
              />

              <font
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#3E3E4F",
                }}
              >
                로그인 상태를 유지합니다.
              </font>
            </div>

            <font
              className="cursor"
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                color: "#8B5FBF",
              }}
              onClick={() =>
                history.push({
                  pathname: "/password",
                  state: {
                    auth: false,
                  },
                })
              }
            >
              비밀번호 찾기
            </font>
          </div>

          {/* <div
            style={{
              marginTop: "48px",
              marginBottom: "40px",
            }}
          >
            <img src="/img/or.png" alt="or" />
          </div>

          <font
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              color: "#9B9FAF",
              width: "384px",
              textAlign: "center",
            }}
          >
            다음으로 간편 로그인
          </font>

          <div
            style={{
              marginTop: "32px",
              width: "190px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Kakao
              type={loginType === "delete" ? "DELETE" : "LOGIN"}
              groupCode={loginType === "inviteGroup" ? code : ""}
            />
            <Naver
              type={loginType === "delete" ? "DELETE" : "LOGIN"}
              groupCode={loginType === "inviteGroup" ? code : ""}
            />
            <Google
              type={loginType === "delete" ? "DELETE" : "LOGIN"}
              groupCode={loginType === "inviteGroup" ? code : ""}
            />
          </div>

          <div
            style={{
              marginTop: "56px",
              width: "384px",
              textAlign: "center",
            }}
          >
            <font
              style={{
                color: "#3E3E4F",
                fontSize: "16px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                marginBottom: "8px",
              }}
            >
              SoBridge는 처음이신가요?
            </font>

            <font
              style={{
                color: "#3E3E4F",
                fontSize: "16px",
              }}
              className="text-decoration-underline cursor"
              onClick={() => {
                if (loginType === "inviteGroup") {
                  history.push({
                    pathname: "/authMail",
                    state: {
                      code: code,
                      type: loginType,
                    },
                  });
                } else {
                  history.push({
                    pathname: "/authMail",
                  });
                }
              }}
            >
              {t("btn.SIGN_UP")}
            </font>
          </div> */}
        </section>
      </div>
    </div>
  );
}

export default Login;
