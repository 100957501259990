/**
 * Import List
 */
import React from "react";
import { NavLink, useHistory, useRouteMatch } from "react-router-dom";
import MenuToggleBtn from "component/tag/btn/common/menuToggleBtn";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  updatePagingInfo,
  updateRecordInfo,
  updateGroupInfo,
} from "actions/index";
/**
 * Version : 1.0
 * 파일명 : menu.js
 * 작성일자 : 2021-10-05
 * 작성자 : 권도훈
 * 설명 : 좌측 메뉴 리스트
 * 수정일자 : 2022-07-12
 * 수정자 : 강연승
 * 수정내역 : 미팅 호스트 대시보드 미출력
 */
function Menu() {
  /**
   * 언어변환
   */
  const { t } = useTranslation();
  /* 그룹 카테고리 코드 변환 */
  const groupCategoryConvert = ctgryCD => {
    switch (ctgryCD) {
      // case "CD0401":
      //   return "default";
      case "CD0402":
        return "company";
      case "CD0403":
        return "school";
      case "CD0404":
        return "private_institute";
      case "CD0405":
        return "university";
      case "CD0406":
        return "social";
      default:
        return "company";
    }
  };
  /**
   * dispatch
   */
  const dispatch = useDispatch();
  /**
   * history
   */
  const history = useHistory();
  /**
   * 글로벌 스테이트
   */
  const groupInfoState = useSelector(state => state.groupInfoReducer);
  const type = useRouteMatch("/app/:type");
  let tag = useRouteMatch("/app/" + type.params.type + "/:tag");
  let currentPage = useRouteMatch("/app/" + type.params.type + "/:tag");

  if (type.params.type === "home") {
    if (tag === null) {
      tag = "home";
      currentPage = "";
    } else {
      tag = "home";
      currentPage = currentPage.params.tag;
    }
  } else if (type.params.type === "group") {
    if (tag === null) {
      tag = "group";
      currentPage = "";
    } else if (tag.params.tag === "makeGroup") {
      tag = "makeGroup";
      currentPage = "";
    } else {
      tag = "groupMain";
      currentPage = currentPage.params.tag;
    }
  } else {
    tag = tag.params.tag;
  }

  const goHome = () => {
    const config = {
      GROUP_NUM: "",
      GROUP_NM: "",
      GROUP_COLOR: "",
      AUTH_CD: "",
      GROUP_CATEGORY: "",
      GROUP_IMG: "",
      GROUP_YN: "N",
      REC_YN: "",
      DOWNLOAD_YN: "",
      PLAY_YN: "",
      HOST_CNT: "0",
      LICENSE_CNT: "0",
      D_DAY: "",
      CREATE_DT: "",
    };
    dispatch(updateGroupInfo(config));
    history.push({
      pathname: "/app/home",
    });
  };

  const goGroup = () => {
    const config = {
      GROUP_YN: "Y",
      GROUP_NUM: "",
      AUTH_CD: "",
      GROUP_NM: "",
      GROUP_COLOR: "",
      GROUP_CATEGORY: "",
      GROUP_IMG: "",
      REC_YN: "",
      DOWNLOAD_YN: "",
      PLAY_YN: "",
      HOST_CNT: "0",
      LICENSE_CNT: "0",
      D_DAY: "",
      CREATE_DT: "",
    };
    dispatch(updateGroupInfo(config));
    history.push({
      pathname: "/app/group",
    });
  };

  const renderEvent = action => {
    switch (action) {
      case "home":
        return (
          <>
            <div className="Menu_BtnGroup">
              <NavLink
                exact
                to="/app/home"
                activeClassName="Menu_ActiveBtn"
                className="Menu_Btn text-center text-decoration-none font-bold inherit cursor"
                onClick={() => {
                  if (currentPage === "") {
                    window.location.reload();
                  }
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={"/img/icon/schedule/menu_meeting.png"}
                    style={{ marginLeft: "18px" }}
                  />
                  <font style={{ marginLeft: "15px" }}>
                    {t("btn.menu.meeting")}
                  </font>
                </div>
              </NavLink>
              <NavLink
                exact
                to="/app/home/address"
                activeClassName="Menu_ActiveBtn"
                className="Menu_Btn text-center text-decoration-none font-bold inherit cursor"
                onClick={() => {
                  if (currentPage === "address") {
                    window.location.reload();
                  }
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={"/img/icon/address/address.png"}
                    style={{ marginLeft: "18px" }}
                  />
                  <font style={{ marginLeft: "15px" }}>
                    {t("btn.menu.address")}
                  </font>
                </div>
              </NavLink>

              <NavLink
                exact
                to="/app/home/record"
                activeClassName="Menu_ActiveBtn"
                className="Menu_Btn text-center text-decoration-none font-bold inherit cursor"
                onClick={() => {
                  const obj = {
                    VIEW_TYPE: "CARD",
                    IS_CHECKED: false,
                    CHECKED_ARR: [],
                    SEARCH_TEXT: "",
                  };
                  dispatch(updateRecordInfo(obj));
                  const paging_obj = {
                    ROW_COUNT: 16,
                    PAGE_COUNT: 0,
                    TOTAL: 0,
                  };
                  dispatch(updatePagingInfo(paging_obj));
                  if (currentPage === "record") {
                    window.location.reload();
                  }
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={"/img/icon/record/record.png"}
                    style={{ marginLeft: "18px" }}
                  />
                  <font style={{ marginLeft: "15px" }}>
                    {t("btn.menu.record")}
                  </font>
                </div>
              </NavLink>

              <NavLink
                exact
                to="/app/home/dashboard"
                activeClassName="Menu_ActiveBtn"
                className="Menu_Btn text-center text-decoration-none font-bold inherit cursor"
                onClick={() => {
                  if (currentPage === "dashboard") {
                    window.location.reload();
                  }
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={"/img/icon/dashboard/dash.png"}
                    style={{ marginLeft: "18px" }}
                  />
                  <font style={{ marginLeft: "15px" }}>
                    {t("btn.menu.dashboard")}
                  </font>
                </div>
              </NavLink>

              <NavLink
                exact
                to="/app/home/data"
                activeClassName="Menu_ActiveBtn"
                className="Menu_Btn text-center text-decoration-none font-bold inherit cursor"
                onClick={() => {
                  if (currentPage === "data") {
                    window.location.reload();
                  }
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={"/img/icon/data/data.png"}
                    style={{ marginLeft: "18px" }}
                  />
                  <font style={{ marginLeft: "15px" }}>
                    {t("btn.menu.data")}
                  </font>
                </div>
              </NavLink>
            </div>

            <MenuToggleBtn type="home" onClick={goGroup} groupYN="N" />
          </>
        );
      case "group":
        return (
          <>
            <MenuToggleBtn type="group" onClick={goHome} groupYN="Y" />
          </>
        );
      case "groupMain":
        return (
          <>
            <div className="Menu_BtnGroup">
              <NavLink
                exact
                to="/app/group"
                className={
                  "groupList_Btn groupList_Btn_" +
                  groupInfoState.GROUP_COLOR +
                  " text-center text-decoration-none font-bold inherit cursor"
                }
                onClick={() => {
                  const config = {
                    GROUP_NUM: "",
                    GROUP_NM: "",
                    GROUP_COLOR: "",
                    AUTH_CD: "",
                    GROUP_CATEGORY: "",
                    GROUP_IMG: "",
                    GROUP_YN: "Y",
                    REC_YN: "",
                    DOWNLOAD_YN: "",
                    PLAY_YN: "",
                    HOST_CNT: "0",
                    LICENSE_CNT: "0",
                    D_DAY: "",
                    CREATE_DT: "",
                  };
                  dispatch(updateGroupInfo(config));
                }}
              >
                그룹 리스트
              </NavLink>

              <div
                style={{
                  backgroundColor: "rgba(255,255,255,0.2)",
                  borderRadius: "24px",
                  margin: "16px",
                  padding: "16px 0",
                  font: "normal normal bold 16px/21px Spoqa Han Sans Neo",
                }}
              >
                <NavLink
                  exact
                  to={"/app/group/groupMain/" + groupInfoState.GROUP_NUM}
                  activeClassName={
                    "groupMenu_ActiveBtn_" + groupInfoState.GROUP_COLOR
                  }
                  className={
                    "groupMenu_Btn groupMenu_Btn_" +
                    groupInfoState.GROUP_COLOR +
                    " text-center text-decoration-none font-bold inherit cursor"
                  }
                  onClick={() => {
                    if (currentPage === "") {
                      window.location.reload();
                    }
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={
                        "/img/icon/schedule/menu_meeting_" +
                        groupInfoState.GROUP_COLOR +
                        ".png"
                      }
                      style={{ marginLeft: "18px" }}
                    />
                    <font style={{ marginLeft: "15px" }}>
                      {t(
                        "group.thema." +
                          groupCategoryConvert(groupInfoState.GROUP_CATEGORY) +
                          ".meeting"
                      )}
                    </font>
                  </div>
                </NavLink>
                <NavLink
                  exact
                  to={"/app/group/address/" + groupInfoState.GROUP_NUM}
                  activeClassName={
                    "groupMenu_ActiveBtn_" + groupInfoState.GROUP_COLOR
                  }
                  className={
                    "groupMenu_Btn groupMenu_Btn_" +
                    groupInfoState.GROUP_COLOR +
                    " text-center text-decoration-none font-bold inherit cursor"
                  }
                  onClick={() => {
                    if (currentPage === "address") {
                      window.location.reload();
                    }
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={
                        "/img/icon/address/menu_address_" +
                        groupInfoState.GROUP_COLOR +
                        ".png"
                      }
                      style={{ marginLeft: "18px" }}
                    />
                    <font style={{ marginLeft: "15px" }}>
                      {t(
                        "group.thema." +
                          groupCategoryConvert(groupInfoState.GROUP_CATEGORY) +
                          ".address"
                      )}
                    </font>
                  </div>
                </NavLink>
                <NavLink
                  exact
                  to={"/app/group/record/" + groupInfoState.GROUP_NUM}
                  activeClassName={
                    "groupMenu_ActiveBtn_" + groupInfoState.GROUP_COLOR
                  }
                  className={
                    "groupMenu_Btn groupMenu_Btn_" +
                    groupInfoState.GROUP_COLOR +
                    " text-center text-decoration-none font-bold inherit cursor"
                  }
                  onClick={() => {
                    if (currentPage === "record") {
                      window.location.reload();
                    }
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={
                        "/img/icon/record/menu_record_" +
                        groupInfoState.GROUP_COLOR +
                        ".png"
                      }
                      style={{ marginLeft: "18px" }}
                    />
                    <font style={{ marginLeft: "15px" }}>
                      {t(
                        "group.thema." +
                          groupCategoryConvert(groupInfoState.GROUP_CATEGORY) +
                          ".recording"
                      )}
                    </font>
                  </div>
                </NavLink>

                {groupInfoState.AUTH_CD === "CD0301" ? (
                  <NavLink
                    exact
                    to={"/app/group/dashboard/" + groupInfoState.GROUP_NUM}
                    activeClassName={
                      "groupMenu_ActiveBtn_" + groupInfoState.GROUP_COLOR
                    }
                    className={
                      "groupMenu_Btn groupMenu_Btn_" +
                      groupInfoState.GROUP_COLOR +
                      " text-center text-decoration-none font-bold inherit cursor"
                    }
                    onClick={() => {
                      if (currentPage === "dashboard") {
                        window.location.reload();
                      }
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={
                          "/img/icon/dashboard/menu_dash_" +
                          groupInfoState.GROUP_COLOR +
                          ".png"
                        }
                        style={{ marginLeft: "18px" }}
                      />
                      <font style={{ marginLeft: "15px" }}>
                        {t(
                          "group.thema." +
                            groupCategoryConvert(
                              groupInfoState.GROUP_CATEGORY
                            ) +
                            ".dashboard"
                        )}
                      </font>
                    </div>
                  </NavLink>
                ) : null}

                <NavLink
                  exact
                  to={"/app/group/data/" + groupInfoState.GROUP_NUM}
                  activeClassName={
                    "groupMenu_ActiveBtn_" + groupInfoState.GROUP_COLOR
                  }
                  className={
                    "groupMenu_Btn groupMenu_Btn_" +
                    groupInfoState.GROUP_COLOR +
                    " text-center text-decoration-none font-bold inherit cursor"
                  }
                  onClick={() => {
                    if (currentPage === "data") {
                      window.location.reload();
                    }
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={
                        "/img/icon/data/menu_data_" +
                        groupInfoState.GROUP_COLOR +
                        ".png"
                      }
                      style={{ marginLeft: "18px" }}
                    />
                    <font style={{ marginLeft: "15px" }}>
                      {t(
                        "group.thema." +
                          groupCategoryConvert(groupInfoState.GROUP_CATEGORY) +
                          ".data"
                      )}
                    </font>
                  </div>
                </NavLink>
              </div>
            </div>

            <MenuToggleBtn type="groupMain" onClick={goHome} groupYN="Y" />
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <div className="Menu_Div">
      <div className="Menu_Logo drag-none">
        <img
          src="/img/logo.png"
          alt="logo"
          style={{ width: "152px", height: "48px", cursor: "pointer" }}
          onClick={goHome}
        />
      </div>
      {tag ? renderEvent(tag) : (window.location.href = "/empty")}
    </div>
  );
}

export default Menu;
