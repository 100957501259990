/**
 * Version : 1.0
 * 작성일자 : 2022-01-04
 * 작성자 : 권도훈
 * 설명 : 무료 회의 이용권 팝업
*/
const initalState = {
    DISPLAY: false
}

const freeLicenseInfo = (state = initalState, action) => {
    switch (action.type) {
        case "freeLicenseInfo/UPDATE":
            return {
                DISPLAY: action.DATA.DISPLAY
            };
        default:
            return state;
    }
}

export default freeLicenseInfo;