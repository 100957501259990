import axios from 'axios';
import React, { useState } from 'react'
import ReactModal from "react-modal";
import { openAlert, closeAlert } from "actions/index";
import { useDispatch } from 'react-redux';
import Loading from 'component/tag/loading/newLoading';
/**
 * Version : 1.0
 * 파일명 : LicenseSubscribePopup.js
 * 작성일자 : 2022-04-25
 * 작성자 : 권도훈
 * 설명 : 구독해지 팝업
 */
function LicenseSubscribePopup(props) {
    const [loading, setLoading] = useState(false);
    /**
     * dispatch
     */
    const dispatch = useDispatch();
    //회의이용권 포맷
    const licenseFormat = license => {
        if (license === undefined || license === "") {
            return "";
        } else if (license !== undefined && license !== "") {
            const licenseArr = license.split("-");
            return licenseArr[0].replace(/(.{6})/g, "$1-");
        }
    };
    //이용권 해지
    const unsubscribeLicense = () => {
        setLoading(true);

        const config = {
            params: {
                USER_LICENSE_NUM: props.license.USER_LICENSE_NUM,
                LICENSE_CODE: props.license.LICENSE_CODE,
                LICENSE_CODE_FORMAT: licenseFormat(props.license.LICENSE_CODE)
            }
        }

        axios.post("/payment/recurring/cancle", null, config)
            .then(res => {
                setLoading(false);
                if (res.status === 200) {
                    props.setIsShow(false);
                    props.setExpireDT(props.license.EXPIRED_DT);
                    props.setUnsubscribeResult(true);
                }
            })
            .catch(() => {
                setLoading(false);
                const obj = {
                    TEXT: "구독해지를 실패했습니다.",
                    submitEventHandler: () => {
                        dispatch(closeAlert());
                    }
                };
                dispatch(openAlert(obj));
            })
    }

    ReactModal.setAppElement("body");
    return (
        <ReactModal
            isOpen={props.isShow}
            className="py-unsubscribe-popup"
            overlayClassName="NewAlertOverlay"
            animationType="fade"
            transparent={true}
            closeTimeoutMS={200}
            style={{
                overlay: {
                    zIndex: "999"
                }
            }}
        >
            <div className='py-unsubscribe-popup-inner'>
                {
                    loading
                        ?
                        <div className='py-unsubscribe-popup-loading'>
                            <Loading />
                        </div>
                        :
                        <>
                            <img
                                src="/img/icon/payment/unsubscribe1.png"
                                alt=''
                            />
                            <div className='py-unsubscribe-title'>
                                이용권 구독 해지
                            </div>
                            <div className='py-unsubscribe-comment-1'>
                                해당 이용권의 구독을 해지 하시겠습니까?
                            </div>

                            <div className='py-unsubscribe-comment-2'>
                                <div>
                                    *해지 신청 후 취소가 불가능 하오니
                                </div>
                                <div>
                                    신중히 결정해주시기 바랍니다.
                                </div>
                            </div>

                            <div className='py-unsubscribe-btn-area cursor'>
                                <div
                                    className='py-unsubscribe-btn-ok'
                                    onClick={() => unsubscribeLicense()}
                                >
                                    구독 해지
                                </div>

                                <div
                                    className='py-unsubscribe-btn-cancle cursor'
                                    onClick={() => props.setIsShow(false)}>
                                    취소
                                </div>
                            </div>
                        </>
                }
            </div>
        </ReactModal>
    )
}

export default LicenseSubscribePopup
