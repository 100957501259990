import React from "react";
/**
 * Version : 1.0
 * 파일명 : groupListPopup.js
 * 작성일자 : 2021-12-16
 * 작성자 : 강연승
 * 설명 : 그룹리스트 팝업
 * 수정일자 : 2021-12-16
 * 수정자 : 강연승
 * 수정내역 : 주소록 팝업 클래스명 props로 전달받게끔 수정
 * props :
 * className - 클래스명
 * open - popup 오픈 상태값
 * groupList - 검색되는 전체 그룹 리스트
 * clickGroupItem - 검색한 주소록 선택 이벤트
 * search - 검색 input 값
 */
function GroupListPopup(props) {
  let row = [];
  props.groupList.forEach((group) => {
    if (group.GROUP_NM.indexOf(props.search) === -1) {
      return;
    }
    row.push(group);
  });
  return (
    <div
      className={props.className}
      style={{
        display: props.open ? "block" : "none",
      }}
    >
      {/* {props.search !== ""
        ? row.map((data) => (
            <div
              className="groupListPopupItem"
              key={data.GROUP_NUM}
              onClick={() =>
                props.clickGroupItem(data.GROUP_NUM, data.GROUP_NM)
              }
            >
              {data.GROUP_NM}
            </div>
          ))
        : null} */}

      {row.map((data) => (
        <div
          className="groupListPopupItem"
          key={data.GROUP_NUM}
          onClick={() => props.clickGroupItem(data.GROUP_NUM, data.GROUP_NM)}
        >
          {data.GROUP_NM}
        </div>
      ))}
    </div>
  );
}

export default GroupListPopup;
