import React, { useEffect } from "react";
import AddressList from "component/tag/table/address/addressList";
import { useDispatch } from "react-redux";
import { updateGroupInfo } from "actions/index";
import { useTranslation } from "react-i18next";
/**
 * Version : 1.0
 * 파일명 : address.js
 * 작성일자 : 2021-09-30
 * 작성자 : 권도훈
 * 설명 : 주소록
 * 수정일자 : 2022-02-09
 * 수정자 : 강연승
 * 수정내역 : 연락처 그룹 테마별 텍스트 적용
 */
function Address() {
  /**
   * DISPATCH
   */
  const dispatch = useDispatch();
  /**
   * 언어변환
   */
  const { t } = useTranslation();
  /**
   * SET DEFAULT STATE
   */
  useEffect(() => {
    const config = {
      GROUP_YN: "N",
      GROUP_NUM: "",
      AUTH_CD: "",
    };
    dispatch(updateGroupInfo(config));
  }, [dispatch]);
  return (
    <div
      style={{
        height: "902px",
        width: "1484px",
      }}
    >
      <div
        style={{
          margin: "26px 40px",
          height: "845px",
          width: "1404px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img src="/img/icon/address/address.png" alt="ICON" />
          <div
            style={{
              fontSize: "28px",
              fontWeight: "bold",
              color: "#2B4AC4",
              marginLeft: "16px",
            }}
          >
            {t("my.address.txt.title.CD0401")}
          </div>
        </div>

        <div
          style={{
            backgroundColor: "rgba(255,255,255,0.4)",
            height: "845px",
            marginTop: "23px",
            borderRadius: "12px",
          }}
        >
          <AddressList />
        </div>
      </div>
    </div>
  );
}

export default Address;
