import { Avatar, Badge } from "@material-ui/core";
import React, { useState, useRef, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ImageUploadPopup from "component/tag/popup/common/imageUploadPopup";
import GroupCategoryEditPopup from "component/tag/popup/group/profile/groupCategoryEditPopup";
import GroupColorEditPopup from "component/tag/popup/group/profile/groupColorEditPopup";
import GroupDeletePopup from "component/tag/popup/group/profile/groupDeletePopup";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { updateGroupInfo } from "actions/index";
/**
 * Version : 1.0
 * 파일명 : profileGroupInfo.js
 * 작성일자 : 2021-11-29
 * 작성자 : 강연승
 * 설명 : 그룹 프로필 - 그룹 정보
 * 수정일자 : 2022-05-04
 * 수정자 : 강연승
 * 수정내역 : 그룹 컬러 수정
 */
function ProfileGroupInfo(props) {
  /**
   * 언어변환
   */
  const { t } = useTranslation();
  /**
   * 글로벌 스테이트
   */
  const groupInfoState = useSelector(state => state.groupInfoReducer);
  const dispatch = useDispatch();
  /* 그룹 카테고리 코드 변환 */
  const groupCategoryConvert = ctgryCD => {
    switch (ctgryCD) {
      case "CD0401":
        return "company";
      case "CD0402":
        return "company";
      case "CD0403":
        return "school";
      case "CD0404":
        return "private_institute";
      case "CD0405":
        return "university";
      case "CD0406":
        return "social";
      default:
        return "company";
    }
  };

  /*
   * 색상 컨버터
   */
  const colorConverter = color => {
    switch (color) {
      case "red":
        return "#de8799";
      case "orange":
        return "#d78e71";
      case "yellow":
        return "#cdb14c";
      case "bean":
        return "#97C12A";
      case "green":
        return "#64d483";
      case "mint":
        return "#6cd6bf";
      case "pine":
        return "#75BDD9";
      case "pink":
        return "#cb86dd";
      case "purple":
        return "#a994e1";
      case "black":
        return "#3b3f4c";
      default:
        return "#2B4AC4";
    }
  };

  /*
   * 그룹 카테고리, 색상 변경 팝업
   */
  const [openCategory, setOpenCategory] = useState(false);
  const [openColor, setOpenColor] = useState(false);
  const [openGroupDelete, setOpenGroupDelete] = useState(false);

  const openPopup = type => {
    if (type === "category") {
      setOpenCategory(true);
    } else if (type === "color") {
      setOpenColor(true);
    } else if (type === "delete") {
      setOpenGroupDelete(true);
    }
  };

  const closePopup = type => {
    if (type === "category") {
      setOpenCategory(false);
    } else if (type === "color") {
      setOpenColor(false);
    } else if (type === "delete") {
      setOpenGroupDelete(false);
    }
  };

  const editGroupInfo = (type, data) => {
    if (type === "color" && props.groupInfo.GROUP_COLOR === data) {
      return closePopup("color");
    }
    if (type === "category" && props.groupInfo.CTGRY_CD === data) {
      return closePopup("category");
    }
    const formData = new FormData();
    let groupInfo = {
      GROUP_NM: props.groupInfo.GROUP_NM,
      GROUP_NUM: props.groupInfo.GROUP_NUM,
    };
    if (type === "color") {
      groupInfo = {
        ...groupInfo,
        GROUP_COLOR: data,
      };
    }
    if (type === "category") {
      groupInfo = {
        ...groupInfo,
        CTGRY_CD: data,
      };
    }

    formData.append("groupInfo", JSON.stringify(groupInfo));
    axios
      .put("/group/updateGroup", formData)
      .then(response => {
        if (response.status === 200) {
          closePopup(type);
          props.reloadGroupInfo();
          if (type === "color") {
          } else if (type === "category") {
            const config = {
              ...groupInfoState,
              GROUP_NM: props.groupInfo.GROUP_NM,
              GROUP_NUM: props.groupInfo.GROUP_NUM,
              GROUP_CATEGORY: data,
            };
            dispatch(updateGroupInfo(config));
          }
        } else {
          if (type === "color") {
            alert("그룹 색상이 변경되지 않았습니다.");
          } else if (type === "category") {
            alert("그룹 카테고리가 변경되지 않았습니다.");
          }
        }
      })
      .catch(error => {
        if (type === "color") {
          alert("그룹 색상 변경과정에서 오류가 발생하였습니다.");
        } else if (type === "category") {
          alert("그룹 카테고리 변경과정에서 오류가 발생하였습니다.");
        }
        console.log(error);
      });
  };

  /**
   * 이름 변경 설정
   */
  const [isEditGroupNm, setIsEditGroupNm] = useState(false);
  const nameRef = useRef();
  /**
   * 이름 수정 취소
   */
  const cancelEditGroupNm = useCallback(() => {
    if (isEditGroupNm) {
      // && nameRef.current.style.display === "block"
      setIsEditGroupNm(false);
    }
  }, [isEditGroupNm]);
  /**
   * 이름 수정 취소 이벤트 추가
   */
  useEffect(() => {
    if (isEditGroupNm) {
      window.addEventListener("click", cancelEditGroupNm);
      return () => window.removeEventListener("click", cancelEditGroupNm);
    }
  }, [cancelEditGroupNm, isEditGroupNm]);
  /**
   * 이름저장
   */
  const saveNameEvent = e => {
    if (e.key === "Enter") {
      if (e.target.value.length === 0) {
        alert("그룹명이 입력되지 않았습니다. 1~9자리 사이로 입력해주세요.");
        return;
      }
      if (e.target.value.length > 9) {
        alert("그룹명이 9자리이상입니다. 1~9자리 사이로 입력해주세요.");
        return;
      }
      if (props.groupInfo.GROUP_NM !== nameRef.current.value) {
        const params = {
          GROUP_NM: nameRef.current.value,
          GROUP_NUM: props.groupInfo.GROUP_NUM,
        };
        props.saveGroupName(params);
      }
      setIsEditGroupNm(false);
    }
  };
  /**
   * 이미지 변경 설정
   */
  const [groupProfileImage, setGroupProfileImage] = useState([]);
  const [isImageUpload, setIsImageUpload] = useState(false);

  const openImageUploadPopup = () => {
    setIsImageUpload(true);
  };

  const clickBackgound = () => {
    if (isImageUpload) {
      setIsImageUpload(false);
      setGroupProfileImage([]);
    }
  };

  const uploadProfileImage = () => {
    const formData = new FormData();
    let groupInfo = {
      GROUP_NUM: props.groupInfo.GROUP_NUM,
      GROUP_NM: props.groupInfo.GROUP_NM,
      file_info: groupProfileImage,
    };

    if (
      props.groupInfo.GROUP_IMG_NUM !== undefined &&
      props.groupInfo.GROUP_IMG_NUM !== null &&
      props.groupInfo.GROUP_IMG_NUM !== ""
    ) {
      groupInfo = {
        ...groupInfo,
        FILE_NUM: props.groupInfo.GROUP_IMG_NUM,
      };
    }

    formData.append("groupInfo", JSON.stringify(groupInfo));

    axios
      .put("/group/updateGroup", formData)
      .then(response => {
        if (response.status === 200) {
          alert("그룹 프로필 이미지가 등록되었습니다.");
          props.reloadGroupInfo();
          setIsImageUpload(false);
          setGroupProfileImage([]);
        } else {
          alert("그룹 프로필 이미지가 등록되지 않았습니다.");
        }
      })
      .catch(error => {
        alert("그룹 프로필 이미지 등록 과정에서 오류가 발생하였습니다.");
        console.log(error);
      });
  };

  const editColor = color => {
    let url = "";
    if (groupInfoState.AUTH_CD === "CD0301") {
      url = "/group/updateGroupColor";
    } else if (groupInfoState.AUTH_CD === "CD0302") {
      url = "/group/updateGroupOrderColor";
    }
    if (props.groupInfo.GROUP_COLOR === color) {
      return closePopup("color");
    }
    let groupInfo = {
      GROUP_NM: props.groupInfo.GROUP_NM,
      GROUP_NUM: props.groupInfo.GROUP_NUM,
      GROUP_COLOR: color,
    };

    axios
      .put(url, groupInfo)
      .then(response => {
        if (response.status === 200) {
          closePopup("color");
          props.reloadGroupInfo();
          const config = {
            ...groupInfoState,
            GROUP_NM: props.groupInfo.GROUP_NM,
            GROUP_NUM: props.groupInfo.GROUP_NUM,
            GROUP_COLOR: color,
          };
          dispatch(updateGroupInfo(config));
        } else {
          alert("그룹 색상이 변경되지 않았습니다.");
        }
      })
      .catch(error => {
        alert("그룹 색상 변경과정에서 오류가 발생하였습니다.");
        console.log(error);
      });
  };

  return (
    <>
      <GroupCategoryEditPopup
        open={openCategory}
        close={closePopup}
        editGroupInfo={editGroupInfo}
        groupNum={props.groupInfo.GROUP_NUM}
        category={props.groupInfo.CTGRY_CD}
      />
      <GroupColorEditPopup
        open={openColor}
        close={closePopup}
        editColor={editColor}
        groupNum={props.groupInfo.GROUP_NUM}
        color={props.groupInfo.GROUP_INFO_COLOR}
      />
      <GroupDeletePopup
        open={openGroupDelete}
        close={closePopup}
        deleteGroup={props.deleteGroup}
        leaveGroup={props.leaveGroup}
      />

      <div className="profile_sidebar_body" onClick={clickBackgound}>
        {groupInfoState.AUTH_CD === "CD0301" ? (
          props.groupInfo.GROUP_IMG === undefined ||
          props.groupInfo.GROUP_IMG === "" ? (
            <Badge
              onClick={openImageUploadPopup}
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={
                <Avatar
                  className="cursor"
                  style={{
                    width: "28px",
                    height: "28px",
                    marginTop: "10px",
                  }}
                  src="/img/icon/profile/img_change.png"
                />
              }
            >
              <Avatar
                style={{
                  width: "104px",
                  height: "104px",
                  marginTop: "32px",
                }}
              >
                {props.groupInfo.GROUP_NM.substr(0, 1)}
              </Avatar>
            </Badge>
          ) : (
            <Badge
              onClick={openImageUploadPopup}
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={
                <Avatar
                  className="cursor"
                  style={{
                    width: "28px",
                    height: "28px",
                    marginTop: "10px",
                  }}
                  src="/img/icon/profile/img_change.png"
                />
              }
            >
              <Avatar
                style={{
                  width: "104px",
                  height: "104px",
                  marginTop: "32px",
                }}
                src={props.groupInfo.GROUP_IMG}
              />
            </Badge>
          )
        ) : props.groupInfo.GROUP_IMG === undefined ||
          props.groupInfo.GROUP_IMG === "" ? (
          <Avatar
            style={{
              width: "104px",
              height: "104px",
              marginTop: "32px",
            }}
          >
            {props.groupInfo.GROUP_NM.substr(0, 1)}
          </Avatar>
        ) : (
          <Avatar
            style={{
              width: "104px",
              height: "104px",
              marginTop: "32px",
            }}
            src={props.groupInfo.GROUP_IMG}
          />
        )}

        <ImageUploadPopup
          isOpen={isImageUpload}
          upload={uploadProfileImage}
          image={groupProfileImage}
          setImage={setGroupProfileImage}
        />

        <div className="profile_sidebar_info">
          {isEditGroupNm ? (
            <input
              ref={nameRef}
              defaultValue={nameRef.current}
              className="profile_sidebar_nameEditor"
              type="text"
              maxLength="9"
              onKeyDown={e => saveNameEvent(e)}
              onClick={e => e.stopPropagation()}
            />
          ) : (
            <div className="group_profile_sidebar_groupname">
              <div
                style={{
                  display: "inline",
                }}
              >
                {props.groupInfo.GROUP_NM}
              </div>
              <img
                className="cursor"
                src="/img/icon/profile/name_edit.png"
                alt="EDIT"
                onClick={() => {
                  nameRef.current = props.groupInfo.GROUP_NM;
                  setIsEditGroupNm(true);
                }}
                style={{
                  display:
                    groupInfoState.AUTH_CD === "CD0301" ? "inline" : "none",
                  position: "absolute",
                  marginTop: "8px",
                  marginLeft: "8px",
                }}
              />
            </div>
          )}
          <div className="group_profile_sidebar_createDate">
            {t("group.profile.groupSetting.createDate", {
              DATE: props.groupInfo.CREATE_DT.replace(/\T.*/, ""),
            })}
          </div>

          <div className="group_profile_sidebar_leader">
            <img
              className="group_profile_sidebar_leader_icon"
              src="/img/icon/profile/cheif.png"
              alt="leader"
            />
            {t("group.profile.groupSetting.groupOwner", {
              OWNER: props.groupInfo.OWNER_NM,
            })}
          </div>
        </div>

        <div
          className={
            groupInfoState.AUTH_CD === "CD0301"
              ? "cursor profile_sidebar_btn_side"
              : "profile_sidebar_btn_side"
          }
          onClick={() =>
            groupInfoState.AUTH_CD === "CD0301" ? openPopup("category") : null
          }
          style={{
            outline: "none",
          }}
        >
          <div
            className="profile_sidebar_btn_left"
            style={{ color: "#3B3F4C" }}
          >
            {t("group.profile.groupSetting.groupThema")}
          </div>
          <div
            className="profile_sidebar_btn_right"
            style={{ color: "#3B3F4C" }}
          >
            {t(
              "group.category." + groupCategoryConvert(props.groupInfo.CTGRY_CD)
            )}
          </div>
        </div>

        <div
          className="cursor profile_sidebar_btn_side"
          onClick={() => openPopup("color")}
          style={{
            outline: "none",
          }}
        >
          <div
            className="profile_sidebar_btn_left"
            style={{ color: "#3B3F4C" }}
          >
            {t("group.profile.groupSetting.groupColor")}
          </div>

          <div className="profile_sidebar_btn_right">
            <div
              className="group_profile_sidebar_colorName"
              style={{
                color: colorConverter(props.groupInfo.GROUP_INFO_COLOR),
              }}
            >
              {props.groupInfo.GROUP_INFO_COLOR}
            </div>
            <div
              className="group_profile_sidebar_groupcolor"
              style={{
                background: colorConverter(props.groupInfo.GROUP_INFO_COLOR),
              }}
            />
          </div>
        </div>

        <div
          className="cursor profile_sidebar_btn_side"
          onClick={() => props.setViewType("LICENSE")}
          style={{
            outline:
              props.viewType === "LICENSE" ? "1px solid #8B5FBF" : "none",
            background:
              props.viewType === "LICENSE"
                ? "rgba(255, 255, 255, 0.5)"
                : "rgba(222, 223, 242, 0.5)",
          }}
        >
          <div
            className="profile_sidebar_btn_left"
            style={{ color: "#3B3F4C" }}
          >
            {t("group.profile.groupSetting.groupMeetingPass")}
          </div>

          <div
            className="profile_sidebar_btn_right"
            style={{ color: "#3B3F4C" }}
          >
            {t("group.profile.groupSetting.meetingPassCnt", {
              CNT: groupInfoState.LICENSE_CNT,
            })}
          </div>
        </div>

        <div
          className="cursor profile_sidebar_btn_side"
          onClick={() => props.setViewType("MEMBER")}
          style={{
            outline: props.viewType === "MEMBER" ? "1px solid #8B5FBF" : "none",
            background:
              props.viewType === "MEMBER"
                ? "rgba(255, 255, 255, 0.5)"
                : "rgba(222, 223, 242, 0.5)",
          }}
        >
          <div
            className="profile_sidebar_btn_left"
            style={{ color: "#3B3F4C" }}
          >
            {groupInfoState.AUTH_CD === "CD0301"
              ? t("group.profile.groupSetting.meetingHost_owner", {
                  MEETING_HOST: t(
                    "group.thema." +
                      groupCategoryConvert(props.groupInfo.CTGRY_CD) +
                      ".meetingHost"
                  ),
                })
              : t("group.profile.groupSetting.meetingHost_host", {
                  MEETING_HOST: t(
                    "group.thema." +
                      groupCategoryConvert(props.groupInfo.CTGRY_CD) +
                      ".meetingHost"
                  ),
                })}
          </div>

          <div
            className="profile_sidebar_btn_right"
            style={{ color: "#3B3F4C" }}
          >
            {t("group.profile.groupSetting.meetingHostCnt", {
              CNT: groupInfoState.HOST_CNT,
            })}
          </div>
        </div>

        {groupInfoState.AUTH_CD === "CD0301" ? (
          <div
            className="cursor profile_sidebar_btn_center"
            onClick={() => openPopup("delete")}
          >
            {t("group.profile.groupSetting.groupDelete")}
          </div>
        ) : (
          <div
            className="cursor profile_sidebar_btn_center"
            onClick={() => openPopup("delete")}
          >
            {t("group.profile.groupSetting.groupExit")}
          </div>
        )}
      </div>
    </>
  );
}

export default ProfileGroupInfo;
