import axios from 'axios';
import React from 'react'
import Cookies from 'universal-cookie';
/**
 * Version : 1.0
 * 파일명 : deleteAgree.js
 * 작성일자 : 2021-11-29
 * 작성자 : 권도훈
 * 설명 : 탈퇴 동의 팝업
 * 수정일자 :  2022-01-10
 * 수정자 : 권도훈
 * 수정내역 : 오류 처리
*/
function DeleteAgree(props) {
    const deleteAccount = () => {
        const config = {
            params: {
                EMAIL: props.INFO.EMAIL,
                SURVEY: props.surveyResult
            }
        }

        axios.post("/user/delete", null, config)
            .then(res => {
                if (res.status === 200) {
                    const cookies = new Cookies();
                    //cookie
                    const domain = process.env.REACT_APP_COOKIE_DOMAIN;
                    const refreshNm = process.env.REACT_APP_COOKIE_REFRESH;
                    const authNm = process.env.REACT_APP_COOKIE_AUTH;
                    const loginTypeNm = process.env.REACT_APP_COOKIE_TYPE;

                    cookies.remove(
                        loginTypeNm,
                        {
                            path: "/",
                            domain: domain
                        }
                    );

                    cookies.remove(
                        authNm,
                        {
                            path: "/",
                            domain: domain
                        }
                    );

                    cookies.remove(
                        refreshNm,
                        {
                            path: "/",
                            domain: domain
                        }
                    );

                    props.setPage("RESULT");
                } else {
                    alert("오류가 발생했습니다.");
                }
            })
            .catch(() => {
                alert("오류가 발생했습니다.");
            })
    }
    return (
        <div
            style={{
                height: "208px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between"
            }}
        >
            <div
                style={{
                    textAlign: "center",
                    fontSize: "18px",
                    fontWeight: "bold"
                }}
            >
                <div>사용하고 계신 계정</div>
                <div>(<font style={{ color: "#2B4AC4" }}>{props.INFO.EMAIL}</font>)을</div>
                <div>정말 탈퇴 하시겠습니까?</div>
            </div>

            <div
                style={{
                    textAlign: "center",
                    fontSize: "14px",
                    color: "#C63353"
                }}
            >
                <div>탈퇴할 경우 해당 계정의</div>
                <div>재사용 및 복구가 불가능합니다.</div>
            </div>

            <div>
                <button
                    className='cursor'
                    style={{
                        border: "none",
                        outline: "none",
                        backgroundColor: "#FFFFFF",
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#C63353",
                        marginRight: "20px"
                    }}
                    onClick={() => props.cancleBtnEvent()}
                >
                    취 소
                </button>

                <button
                    className='cursor common_ok_textBtn'
                    style={{
                        border: "none",
                        outline: "none",
                        backgroundColor: "#FFFFFF",
                        fontSize: "14px",
                        fontWeight: "bold"
                    }}
                    onClick={() => deleteAccount()}
                >
                    확 인
                </button>
            </div>
        </div>
    )
}

export default DeleteAgree
