import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import GroupItem from "component/page/group/groupItem";
import MakeGroup from "./makeGroup";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import InviteGroupAgreePopup from "component/tag/popup/group/invite/inviteGroupAgreePopup";
import DenyInviteGroupAlert from "component/tag/popup/group/invite/denyInviteGroupAlert";
import { useDispatch, useSelector } from "react-redux";
import { updateGroupInfo } from "actions/index";
import Loading from "component/tag/loading/newLoading";
/**
 * Version : 1.0
 * 파일명 : groupList.js
 * 작성일자 : 2021-10-05
 * 작성자 : 강연승
 * 설명 : 그룹 리스트(그룹 초기화면)
 * 수정일자 : 2022-04-22
 * 수정자 : 강연승
 * 수정내역 : 타임라인 오류수정
 */
function GroupList() {
  /**
   * 글로벌 스테이트
   */
  const dispatch = useDispatch();
  const groupInfoState = useSelector(state => state.groupInfoReducer);

  const [createGroupListCnt, setCreateGroupListCnt] = useState("");
  const [createGroupList, setCreateGroupList] = useState([]);
  const [joinGroupListCnt, setJoinGroupListCnt] = useState([]);
  const [joinGroupList, setJoinGroupList] = useState([]);
  const [listLoading, setListLoading] = useState(false);

  const [makeGroupOpen, setMakeGroupOpen] = useState(false);

  const handleDialogOpen = () => {
    document.body.style.overflow = "hidden";
    setMakeGroupOpen(true);
  };

  const handleDialogClose = () => {
    setMakeGroupOpen(false);
  };

  const [inviteListOpen, setInviteListOpen] = useState(false);
  const [inviteGroupInfo, setInviteGroupInfo] = useState({});
  const handleInviteDialogOpen = info => {
    setInviteGroupInfo(info);
    setInviteListOpen(true);
  };

  const handleInviteDialogClose = () => {
    setInviteListOpen(false);
  };

  useEffect(() => {
    if (groupInfoState.GROUP_YN === "N" || groupInfoState.GROUP_NUM !== "") {
      const config = {
        GROUP_NUM: "",
        GROUP_NM: "",
        GROUP_COLOR: "",
        AUTH_CD: "",
        GROUP_CATEGORY: "",
        GROUP_IMG: "",
        GROUP_YN: "Y",
        REC_YN: "",
        DOWNLOAD_YN: "",
        PLAY_YN: "",
        HOST_CNT: "0",
        LICENSE_CNT: "0",
        D_DAY: "",
        CREATE_DT: "",
      };
      dispatch(updateGroupInfo(config));
    }
    reloadGroupList();
  }, []);

  const reloadGroupList = () => {
    getCreateGroupList();
    getJoinGroupList();
  };

  const getCreateGroupList = () => {
    setListLoading(true);
    axios
      .get("/group/selectCreateGroupList", {
        params: {
          // CURRENTPAGE: "0",
          // RECORDPERPAGE: "3",
        },
      })
      .then(
        ({ data }) => (
          setListLoading(false),
          setCreateGroupList(data[0].CREATEGROUPLIST),
          setCreateGroupListCnt(data[0].TOTAL)
        )
      )
      .catch(error => {
        setListLoading(false);
        console.log(error);
      });
  };
  const getJoinGroupList = () => {
    setListLoading(true);
    axios
      .get("/group/selectJoinGroupList", {
        params: {
          // USE_YN: "Y",
          // CURRENTPAGE: "0",
          // RECORDPERPAGE: "3",
        },
      })
      .then(
        ({ data }) => (
          setListLoading(false),
          setJoinGroupList(data[0].JOINGROUPLIST),
          setJoinGroupListCnt(data[0].TOTAL)
        )
      )
      .catch(error => {
        setListLoading(false);
        console.log(error);
      });
  };

  const clickBackgound = () => {
    if (makeGroupOpen) {
      setMakeGroupOpen(false);
    }
    if (inviteListOpen) {
      setInviteListOpen(false);
    }
  };

  /* 그룹 리스트 정렬 */
  const sortItems = (a, b) => {
    if (a.USE_YN < b.USE_YN) return -1;
    if (a.USE_YN > b.USE_YN) return 1;
    if (a.FAVORITE_YN < b.FAVORITE_YN) return 1;
    if (a.FAVORITE_YN > b.FAVORITE_YN) return -1;
    if (a.ORDER_NUM < b.ORDER_NUM) return -1;
    if (a.ORDER_NUM > b.ORDER_NUM) return 1;
    return 0;
  };

  /* 그룹 아이템 드랍이벤트 */
  const onDrop = (ownerYN, dragItem, otherItem) => {
    if (dragItem.GROUP_NUM === otherItem.GROUP_NUM) {
      return;
    }
    let newList = [];
    if (ownerYN === "Y") {
      newList = [...createGroupList];
    } else if (ownerYN === "N") {
      newList = [...joinGroupList];
    }
    let firstItem = newList.find(i => i.GROUP_NUM === dragItem.GROUP_NUM);
    let secondItem = newList.find(i => i.GROUP_NUM === otherItem.GROUP_NUM);
    const firstIndex = firstItem.ORDER_NUM;
    firstItem.ORDER_NUM = secondItem.ORDER_NUM;
    secondItem.ORDER_NUM = firstIndex;
    const groupInfo = {
      newOrder: {
        GROUP_INFO_ORDER_NUM: dragItem.GROUP_INFO_ORDER_NUM,
        ORDER_NUM: firstItem.ORDER_NUM,
      },
      oldOrder: {
        GROUP_INFO_ORDER_NUM: otherItem.GROUP_INFO_ORDER_NUM,
        ORDER_NUM: secondItem.ORDER_NUM,
      },
    };
    updateOrder(groupInfo);
    if (ownerYN === "Y") {
      setCreateGroupList(newList);
    } else if (ownerYN === "N") {
      setJoinGroupList(newList);
    }
  };

  /* 그룹 순서 및 즐겨찾기 수정 axios */
  const updateOrder = useCallback(async body => {
    await axios.put("/group/updateGroupOrder", body).catch(error => {
      console.log(error);
    });
  });

  /* 그룹 즐겨찾기 */
  const onFavorite = info => {
    let newList = [];
    if (info.ownerYN === "Y") {
      newList = [...createGroupList];
    } else if (info.ownerYN === "N") {
      newList = [...joinGroupList];
    }
    let favoriteYN = "";
    if (info.FAVORITE_YN === "Y") {
      favoriteYN = "N";
    } else if (info.FAVORITE_YN === "N") {
      favoriteYN = "Y";
    }

    let selectItem = newList.find(i => i.GROUP_NUM === info.GROUP_NUM);

    selectItem.FAVORITE_YN = favoriteYN;

    const groupInfo = {
      newOrder: {
        GROUP_INFO_ORDER_NUM: info.GROUP_INFO_ORDER_NUM,
        FAVORITE_YN: favoriteYN,
      },
    };
    updateOrder(groupInfo);
    if (info.ownerYN === "Y") {
      setCreateGroupList(newList);
    } else if (info.ownerYN === "N") {
      setJoinGroupList(newList);
    }
  };

  const agreeInviteGroup = (num, nm, color) => {
    axios
      .put("/group/updateGroupMemberAgree", {
        GROUP_NUM: num,
        USE_YN: "Y",
        GROUP_COLOR: color,
      })
      .then(response => {
        if (response.status === 200) {
          alert("[" + nm + "] 그룹 호스트가 되신 것을 축하합니다!");
          handleInviteDialogClose();
          getJoinGroupList();
        } else {
          alert("[" + nm + "] 그룹에 가입되지않았습니다.");
        }
      })
      .catch(error => {
        alert("그룹 가입과정에서 오류가 발생하였습니다.");
        console.log(error);
      });
  };

  const denyInviteGroup = groupNum => {
    axios
      .put("/group/denyGroupMember", {
        GROUP_NUM: groupNum,
      })
      .then(response => {
        if (response.status === 200) {
          handleInviteDialogClose();
          alertDenyInviteGroup();
          getJoinGroupList();
        } else {
          alert("그룹 거절 실패");
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
  const [alertPopup, setAlertPopup] = useState(false);
  const [alertRequest, setAlertRequest] = useState({});

  const alertDenyInviteGroup = () => {
    setAlertPopup(true);
    const alertParams = {
      className: "denyAlert",
      contents: (
        <div>
          <div
            style={{
              font: "normal normal normal 20px/27px Spoqa Han Sans Neo",
              color: "#2B4AC4",
            }}
          >
            그룹 초대 거절
          </div>
          <div
            style={{
              font: "normal normal normal 16px/21px Spoqa Han Sans Neo",
              color: "#3E3E4F",
              marginTop: "8px",
            }}
          >
            그룹 초대장이 삭제되었습니다.
          </div>
        </div>
      ),
      // EventHandler: function () {},
    };
    setAlertRequest(alertParams);
  };
  const closeAlertPopup = () => {
    setAlertPopup(false);
  };

  /**
   * 이름 변경 저장
   */
  const saveGroupNameEvent = params => {
    axios
      .put("/group/updateGroupName", params)
      .then(res => {
        if (res.status === 200) {
          alert("그룹정보가 수정되었습니다.");
          getCreateGroupList();
        } else {
          alert("저장에 실패했습니다.");
        }
      })
      .catch(() => {
        alert("저장에 실패했습니다.");
      });
  };
  return (
    <div className="absolute inline-block zi-0" style={{ width: "1010px" }}>
      <div
        style={{
          display: makeGroupOpen || inviteListOpen ? "block" : "none",
          width: "1680px",
          height: "954px",
          background: "rgba(255,255,255,0)",
          zIndex: "80",
          position: "absolute",
          left: "-197px",
        }}
        onClick={clickBackgound}
      ></div>
      <div
        style={{
          height: "36px",
          color: "#2B4AC4",
          font: "normal normal bold 32px/28px Spoqa Han Sans Neo",
          padding: "32px 0 20px 32px",
          clear: "both",
        }}
      >
        그룹 리스트
      </div>
      <InviteGroupAgreePopup
        open={inviteListOpen}
        close={handleInviteDialogClose}
        groupInfo={inviteGroupInfo}
        reload={reloadGroupList}
        agreeInviteGroup={agreeInviteGroup}
        denyInviteGroup={denyInviteGroup}
      />
      <MakeGroup
        open={makeGroupOpen}
        close={handleDialogClose}
        reload={reloadGroupList}
      />
      <DenyInviteGroupAlert
        open={alertPopup}
        close={closeAlertPopup}
        request={alertRequest}
      />

      {listLoading ? (
        <div
          style={{
            display: listLoading ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "997",
            height: "867px",
          }}
        >
          <Loading />
        </div>
      ) : createGroupList.length === 0 && joinGroupList.length === 0 ? (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "79px",
              cursor: "pointer",
            }}
            onClick={handleDialogOpen}
          >
            <img src="/img/icon/group/initial_add.png" alt="addGroup" />
          </div>
          <div
            style={{
              textAlign: "center",
              font: "normal normal normal 18px/27px Spoqa Han Sans Neo",
              color: "#84889B",
              marginTop: "40px",
            }}
          >
            <a style={{ display: "block" }}>아직 그룹이 없습니다.</a>
            <a style={{ display: "block" }}>
              사람들과 함께 하는 SoBridge를 만들 수 있습니다.
            </a>
          </div>
        </div>
      ) : (
        <div
          className="groupListScroll"
          style={{ overflowY: "auto", maxHeight: "866px" }}
        >
          <div>
            <div
              style={{
                padding: "20px 0 0 40px",
              }}
            >
              <img
                src="/img/icon/group/list_leader.png"
                alt="createGroup"
                style={{ verticalAlign: "text-bottom" }}
              />
              <a
                style={{
                  margin: "0 8px",
                  color: "#2B4AC4",
                  font: "normal normal bold 20px/27px Spoqa Han Sans Neo",
                }}
              >
                내가 만든 그룹
              </a>
              <a
                style={{
                  width: "28px",
                  height: "24px",
                  padding: "2px 10px 4px",
                  backgroundColor: "#2B4AC4",
                  borderRadius: "12px",
                  color: "#F9F9FA",
                  font: "normal normal bold 14px/18px Spoqa Han Sans Neo",
                }}
              >
                {createGroupListCnt}
              </a>
            </div>
            {/* padding: "32px 54px 40px 68px" */}
            <DndProvider backend={HTML5Backend}>
              <div
                style={{
                  paddingLeft: "72px",
                  paddingTop: "32px",
                  paddingBottom: "40px",
                  display: "grid",
                  gridColumnGap: "40px",
                  gridRowGap: "32px",
                  gridTemplateColumns: "repeat(auto-fill, minmax(265px,0fr))",
                }}
              >
                <div
                  onClick={handleDialogOpen}
                  style={{
                    width: "264px",
                    height: "144px",
                    borderRadius: "16px",
                    backgroundColor: "rgba(255,255,255,0.5)",
                    cursor: "pointer",
                    boxShadow: "0px 5px 16px #00000029",
                    textDecoration: "none",
                  }}
                >
                  <img
                    src="/img/icon/group/add_group.png"
                    alt="addGroup"
                    style={{ margin: "36px 118px 24px" }}
                  />
                  <div
                    style={{
                      marginBottom: "32px",
                      textAlign: "center",
                      color: "#4865CC",
                      font: "normal normal normal 18px/24px Spoqa Han Sans Neo",
                    }}
                  >
                    새로운 그룹 만들기
                  </div>
                </div>

                {createGroupList
                  .sort(sortItems)
                  .map(
                    data => (
                      (data = { ...data, ownerYN: "Y" }),
                      (
                        <GroupItem
                          key={data.GROUP_NUM}
                          groupInfo={data}
                          onDrop={onDrop}
                          onFavorite={onFavorite}
                          saveGroupNameEvent={saveGroupNameEvent}
                        />
                      )
                    )
                  )}
              </div>
            </DndProvider>
          </div>

          {joinGroupList.length > 0 ? (
            <div>
              <div
                style={{
                  padding: "20px 0 0 40px",
                }}
              >
                <img
                  src="/img/icon/group/list_member.png"
                  alt="joinGroup"
                  style={{ verticalAlign: "text-bottom" }}
                />
                <a
                  style={{
                    margin: "0 8px",
                    color: "#2B4AC4",
                    font: "normal normal bold 20px/27px Spoqa Han Sans Neo",
                  }}
                >
                  초대받은 그룹
                </a>
                <a
                  style={{
                    width: "28px",
                    height: "24px",
                    padding: "2px 10px 4px",
                    backgroundColor: "#2B4AC4",
                    borderRadius: "12px",
                    color: "#F9F9FA",
                    font: "normal normal bold 14px/18px Spoqa Han Sans Neo",
                  }}
                >
                  {joinGroupListCnt}
                </a>
              </div>

              <DndProvider backend={HTML5Backend}>
                <div
                  style={{
                    paddingLeft: "72px",
                    paddingTop: "32px",
                    paddingBottom: "40px",
                    display: "grid",
                    gridColumnGap: "40px",
                    gridRowGap: "32px",
                    gridTemplateColumns: "repeat(auto-fill, minmax(265px,0fr))",
                  }}
                >
                  {joinGroupList
                    .sort(sortItems)
                    .map(
                      data => (
                        (data = { ...data, ownerYN: "N" }),
                        (
                          <GroupItem
                            key={data.GROUP_NUM}
                            groupInfo={data}
                            onDrop={onDrop}
                            onFavorite={onFavorite}
                            inviteOpen={handleInviteDialogOpen}
                          />
                        )
                      )
                    )}
                </div>
              </DndProvider>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
}

export default GroupList;
