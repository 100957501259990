import React, { useState, useRef, useEffect } from "react";
import { useDragAndDrop } from "component/tag/dnd/useDragAndDrop";
import { Avatar } from "@material-ui/core";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import axios from "axios";
/**
 * Version : 1.0
 * 파일명 : bookmarkItem.js
 * 작성일자 : 2021-11-22
 * 작성자 : 강연승
 * 설명 :
 * 수정일자 : 2022-05-04
 * 수정자 : 강연승
 * 수정내역 : 그룹 컬러 수정
 */
function BookmarkItem(props) {
  /**
   * history
   */
  const history = useHistory();
  const groupInfoState = useSelector(state => state.groupInfoReducer);

  const createDragHoverCallback = (ref, currentItem, onDrop) => {
    return (otherItem, monitor) => {
      const dragIndex = otherItem.ORDER_NUM;
      const hoverIndex = currentItem.ORDER_NUM;
      if (
        props.resSidebarType === "createBookmark" ||
        props.resSidebarType === "editBookmark"
      ) {
        return;
      }

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      const hoverClientX = clientOffset.x - hoverBoundingRect.right;

      if (
        dragIndex < hoverIndex &&
        hoverClientY < hoverMiddleY &&
        hoverClientX < hoverMiddleX
      ) {
        return;
      }

      if (
        dragIndex > hoverIndex &&
        hoverClientY > hoverMiddleY &&
        hoverClientX > hoverMiddleX
      ) {
        return;
      }

      onDrop(otherItem, currentItem);

      otherItem.ORDER_NUM = currentItem.ORDER_NUM;
    };
  };

  const ref = useRef(null);
  const { isDragging } = useDragAndDrop(ref, {
    ...props.bookmarkInfo,
    hover: createDragHoverCallback(ref, props.bookmarkInfo, props.onDrop),
  });

  const opacity = isDragging ? 0 : 1;
  // const opacity = () => {
  //   if (isDragging) {
  //     return 0;
  //   } else {
  //     if (props.isEdit && props.isEditNum !== props.bookmarkInfo.FAVORITE_NUM) {
  //       return 0.5;
  //     } else {
  //       return 1;
  //     }
  //   }
  // };
  const [groupNM, setGroupNM] = useState();
  const [groupColor, setGroupColor] = useState();
  const getGroupInfo = num => {
    if (props.bookmarkInfo.GROUP_YN === "N") {
      return;
    } else if (props.bookmarkInfo.GROUP_YN === "Y") {
      axios
        .get("/group/selectGroupInfo", {
          params: {
            GROUP_NUM: num,
          },
        })
        .then(
          ({ data }) => (
            setGroupNM(data[0].GROUP_NM), setGroupColor(data[0].GROUP_COLOR)
          )
        )
        .catch(error => {
          console.log(error);
        });
    }
  };
  useEffect(() => {
    getGroupInfo(props.bookmarkInfo.GROUP_NUM);
  }, []);

  const getRandom = (min, max) => Math.floor(Math.random() * (max - min) + min);
  const getGroupColor = () => {
    let random = getRandom(0, 10);
    console.log("랜덤값" + random);
    switch (random) {
      case 0:
        return "#ff8787";
      case 1:
        return "#f783ac";
      case 2:
        return "#da77f2";
      case 3:
        return "#9775fa";
      case 4:
        return "#748ffc";
      case 5:
        return "#69db7c";
      case 6:
        return "#ffd43b";
      case 7:
        return "#ffa94d";
      case 8:
        return "#a9e34b";
      case 9:
        return "#38d9a9";
      case 10:
        return "#3bc9db";
    }
  };

  /*
   * 색상 컨버터
   */
  const colorConverter = color => {
    switch (color) {
      case "red":
        return "#D0778A";
      case "orange":
        return "#D78E71";
      case "yellow":
        return "#CDB14C";
      case "bean":
        return "#AED057";
      case "green":
        return "#28B750";
      case "mint":
        return "#27B395";
      case "pine":
        return "#4FAACE";
      case "pink":
        return "#BD66D4";
      case "purple":
        return "#7855D0";
      case "black":
        return "#84889B";
      default:
        return "#2B4AC4";
    }
  };

  return (
    <div
      ref={ref}
      className={
        groupInfoState.GROUP_YN === "N" || groupInfoState.GROUP_YN === ""
          ? "cursor bookmark_item"
          : "cursor group_bookmark_item_" + groupInfoState.GROUP_COLOR
      }
      style={{
        opacity,
      }}
      onClick={() =>
        props.bookmarkInfo.GROUP_YN === "Y" &&
        history.location.pathname === "/app/home"
          ? history.push({
              pathname: "/app/group/groupMain/" + props.bookmarkInfo.GROUP_NUM,
              state: {
                detailType: "history",
                title: props.bookmarkInfo.TITLE,
                content: props.bookmarkInfo.CONTENTS,
                participants: props.bookmarkInfo.participants,
              },
            })
          : props.resBookmark(props.bookmarkInfo)
      }
    >
      {props.bookmarkInfo.GROUP_YN === "Y" ? (
        <>
          <div
            className={
              groupInfoState.GROUP_YN === "N" || groupInfoState.GROUP_YN === ""
                ? "bookmark_item_title"
                : "group_bookmark_item_title_" + groupInfoState.GROUP_COLOR
            }
            style={{
              font: "normal normal normal 20px/27px Spoqa Han Sans Neo",
              marginBottom: "8px",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              maxWidth: "200px",
            }}
          >
            {props.bookmarkInfo.TITLE}
          </div>
          <div
            className={
              groupInfoState.GROUP_YN === "N" || groupInfoState.GROUP_YN === ""
                ? "bookmark_item_content"
                : "group_bookmark_item_content_" + groupInfoState.GROUP_COLOR
            }
            style={{
              font: "normal normal bold 12px/16px Spoqa Han Sans Neo",
              textOverflow: "ellipsis",
              color:
                groupInfoState.GROUP_YN === "N" ||
                groupInfoState.GROUP_YN === ""
                  ? colorConverter(groupColor)
                  : null,
              whiteSpace: "nowrap",
              overflow: "hidden",
              maxWidth: "200px",
              marginBottom:
                // props.bookmarkInfo.GROUP_YN === "Y" ? "33px" : "57px",
                "33px",
            }}
          >
            {/* {props.bookmarkInfo.GROUP_YN === "Y"
              ? props.bookmarkInfo.GROUP_NUM
              : null} */}
            {history.location.pathname === "/app/home"
              ? groupNM
              : props.bookmarkInfo.CONTENTS}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {Array.isArray(props.bookmarkInfo.participants) &&
            props.bookmarkInfo.participants.length > 0 ? (
              <div>
                {props.bookmarkInfo.participants.slice(0, 5).map(data =>
                  data.PRTCP_NM === "" ? (
                    <Avatar
                      key={data.PRTCP_NUM}
                      style={{
                        display: "inline-block",
                        width: "24px",
                        height: "20px",
                        textAlign: "center",
                        marginRight: "-8px",
                        font: "normal normal normal 14px/18px Spoqa Han Sans Neo",
                        color: "#fafafa",
                        paddingTop: "4px",
                      }}
                    />
                  ) : (
                    <Avatar
                      key={data.PRTCP_NUM}
                      style={{
                        display: "inline-block",
                        width: "24px",
                        height: "20px",
                        textAlign: "center",
                        marginRight: "-8px",
                        font: "normal normal normal 14px/18px Spoqa Han Sans Neo",
                        color: "#fafafa",
                        paddingTop: "4px",
                        // backgroundColor: getGroupColor(),
                      }}
                    >
                      {data.PRTCP_NM.substr(0, 1)}
                    </Avatar>
                  )
                )}
                {props.bookmarkInfo.participants.length > 5 ? (
                  <Avatar
                    style={{
                      display: "inline-block",
                      width: "24px",
                      height: "20px",
                      textAlign: "center",
                      marginRight: "-8px",
                      font: "normal normal normal 14px/18px Spoqa Han Sans Neo",
                      color: "#ffffff",
                      paddingTop: "4px",
                      backgroundColor: "#2B4AC4",
                    }}
                  >
                    +{props.bookmarkInfo.participants.length - 5}
                  </Avatar>
                ) : null}
              </div>
            ) : (
              <div></div>
            )}
            <div>
              <img
                src={
                  groupInfoState.GROUP_YN === "N" ||
                  groupInfoState.GROUP_YN === ""
                    ? "/img/icon/bookmark/bookmark_edit.png"
                    : "/img/icon/bookmark/groupBookmark_edit.png"
                }
                alt="edit"
                onClick={e =>
                  props.resSidebarType === "createBookmark" ||
                  props.resSidebarType === "editBookmark"
                    ? e.stopPropagation()
                    : (e.stopPropagation(),
                      props.editBookmark(props.bookmarkInfo),
                      props.onEdit(props.bookmarkInfo.FAVORITE_NUM))
                }
              />
              <img
                src={
                  groupInfoState.GROUP_YN === "N" ||
                  groupInfoState.GROUP_YN === ""
                    ? "/img/icon/bookmark/bookmark_delete.png"
                    : "/img/icon/bookmark/groupBookmark_delete.png"
                }
                alt="delete"
                style={{ marginLeft: "8px" }}
                onClick={e =>
                  props.resSidebarType === "createBookmark" ||
                  props.resSidebarType === "editBookmark"
                    ? e.stopPropagation()
                    : (e.stopPropagation(),
                      props.onDeletePopup(props.bookmarkInfo))
                }
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              font: "normal normal normal 20px/27px Spoqa Han Sans Neo",
              color: "#2B4AC4",
              marginBottom: "8px",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              maxWidth: "200px",
            }}
          >
            {props.bookmarkInfo.TITLE}
          </div>
          <div
            style={{
              font: "normal normal bold 12px/16px Spoqa Han Sans Neo",
              color: "#2B4AC4",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              maxWidth: "200px",
              marginBottom:
                props.bookmarkInfo.GROUP_YN === "Y" ? "33px" : "48px",
            }}
          >
            {props.bookmarkInfo.GROUP_YN === "Y"
              ? props.bookmarkInfo.GROUP_NUM
              : null}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {Array.isArray(props.bookmarkInfo.participants) &&
            props.bookmarkInfo.participants.length > 0 ? (
              <div>
                {props.bookmarkInfo.participants.slice(0, 5).map(data =>
                  data.PRTCP_NM === "" ? (
                    <Avatar
                      key={data.PRTCP_NUM}
                      style={{
                        display: "inline-block",
                        width: "24px",
                        height: "20px",
                        textAlign: "center",
                        marginRight: "-8px",
                        font: "normal normal normal 14px/18px Spoqa Han Sans Neo",
                        color: "#fafafa",
                        paddingTop: "4px",
                      }}
                    />
                  ) : (
                    <Avatar
                      key={data.PRTCP_NUM}
                      style={{
                        display: "inline-block",
                        width: "24px",
                        height: "20px",
                        textAlign: "center",
                        marginRight: "-8px",
                        font: "normal normal normal 14px/18px Spoqa Han Sans Neo",
                        color: "#fafafa",
                        paddingTop: "4px",
                        // backgroundColor: getGroupColor(),
                      }}
                    >
                      {data.PRTCP_NM.substr(0, 1)}
                    </Avatar>
                  )
                )}
                {props.bookmarkInfo.participants.length > 5 ? (
                  <Avatar
                    style={{
                      display: "inline-block",
                      width: "24px",
                      height: "20px",
                      textAlign: "center",
                      marginRight: "-8px",
                      font: "normal normal normal 14px/18px Spoqa Han Sans Neo",
                      color: "#ffffff",
                      paddingTop: "4px",
                      backgroundColor: "#2B4AC4",
                    }}
                  >
                    +{props.bookmarkInfo.participants.length - 5}
                  </Avatar>
                ) : null}
              </div>
            ) : (
              <div></div>
            )}
            <div>
              <img
                src={
                  groupInfoState.GROUP_YN === "N" ||
                  groupInfoState.GROUP_YN === ""
                    ? "/img/icon/bookmark/bookmark_edit.png"
                    : "/img/icon/bookmark/groupBookmark_edit.png"
                }
                alt="edit"
                onClick={e =>
                  props.resSidebarType === "createBookmark" ||
                  props.resSidebarType === "editBookmark"
                    ? e.stopPropagation()
                    : (e.stopPropagation(),
                      props.editBookmark(props.bookmarkInfo),
                      props.onEdit(props.bookmarkInfo.FAVORITE_NUM))
                }
              />
              <img
                src={
                  groupInfoState.GROUP_YN === "N" ||
                  groupInfoState.GROUP_YN === ""
                    ? "/img/icon/bookmark/bookmark_delete.png"
                    : "/img/icon/bookmark/groupBookmark_delete.png"
                }
                alt="delete"
                style={{ marginLeft: "8px" }}
                onClick={e =>
                  props.resSidebarType === "createBookmark" ||
                  props.resSidebarType === "editBookmark"
                    ? e.stopPropagation()
                    : (e.stopPropagation(),
                      props.onDeletePopup(props.bookmarkInfo))
                }
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default BookmarkItem;
