import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import AddParticipant from "component/tag/input/schedule/addParticipant";
import ParticipantList from "component/tag/list/schedule/participantList";
import AddressPopup from "component/tag/popup/common/addressPopup";
import ParticipantSidebar from "./participantSidebar";
import BookmarkResultPopup from "component/tag/popup/bookmark/bookmarkResultPopup";
import axios from "axios";
import { useSelector } from "react-redux";
/**
 * Version : 1.0
 * 파일명 : createBookmarkSidebar.js
 * 작성일자 : 2021-11-22
 * 작성자 : 강연승
 * 설명 :
 * 수정일자 : 2022-02-15
 * 수정자 : 강연승
 * 수정내역 : 참석자 추가 버튼 생성
 * props :
 * 전달받은값 - 설명
 */
function CreateBookmarkSidebar(props) {
  const groupInfoState = useSelector(state => state.groupInfoReducer);
  const inputRef = useRef(null);
  useEffect(() => {
    inputRef.current.focus();
  }, []);

  /**
   * 언어변환
   */
  const { t } = useTranslation();

  const [bookmarkInfo, setBookmarkInfo] = useState({
    TITLE: "",
    CONTENTS: "",
    participants: [],
    GROUP_YN: groupInfoState.GROUP_YN,
  });
  const [inputs, setInputs] = useState({
    PRTCP_NM: "",
    PRTCP_EMAIL: "",
  });
  const { PRTCP_NM, PRTCP_EMAIL } = inputs;
  const [participants, setParticipants] = useState([]);
  useEffect(() => {
    setBookmarkInfo({
      ...bookmarkInfo,
      participants: participants,
    });
  }, [participants]);
  const onChangeParticipant = e => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
    setBookmarkInfo({
      ...bookmarkInfo,
      participants: participants,
    });
  };
  const cancelEvent = () => {
    setBookmarkInfo({
      TITLE: "",
      CONTENTS: "",
      participants: [],
    });
    // setParticipants([]);
    if (isActive) {
      setIsActive(false);
    }
    props.setIsInput(false);
    props.setResSidebarType("res");
  };

  const onChangeEvent = e => {
    const { value, name } = e.target;
    setBookmarkInfo({
      ...bookmarkInfo,
      [name]: value,
    });
  };

  const handleKeyPress = ({ key }) => {
    if (key === "Enter") {
      onCreate();
    }
  };

  const validationEmail = value => {
    var emailType =
      /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
    return emailType.test(value);
  };

  const onCreate = () => {
    if (PRTCP_EMAIL === "" || validationEmail(PRTCP_EMAIL) === false) {
      alert("올바른 이메일 형식이 아닙니다.");
      return;
    } else {
      for (let i = 0; i < participants.length; i++) {
        if (PRTCP_EMAIL === participants[i].PRTCP_EMAIL) {
          alert("이미 참여자로 추가되어 있습니다.");
          return;
        }
      }
    }
    if (Array.isArray(addressAll) && addressAll.length > 0) {
      for (let i = 0; i < addressAll.length; i++) {
        if (PRTCP_EMAIL === addressAll[i].ADR_EMAIL) {
          const participant = {
            PRTCP_NM: addressAll[i].ADR_NM,
            PRTCP_EMAIL: addressAll[i].ADR_EMAIL,
          };
          setParticipants(participants.concat(participant));
          break;
        } else {
          const participant = {
            PRTCP_NM,
            PRTCP_EMAIL,
          };
          setParticipants(participants.concat(participant));
        }
      }
    } else {
      const participant = {
        PRTCP_NM,
        PRTCP_EMAIL,
      };
      setParticipants(participants.concat(participant));
    }
    setInputs({
      PRTCP_NM: "",
      PRTCP_EMAIL: "",
    });
  };
  const onRemove = PRTCP_EMAIL => {
    setParticipants(
      participants.filter(
        participant => participant.PRTCP_EMAIL !== PRTCP_EMAIL
      )
    );
  };
  const [partiSidebar, setPartiSidebar] = useState(false);
  const handlePartiSidebar = type => {
    if (type === true) {
      setPartiSidebar(true);
    } else if (type === false) {
      setPartiSidebar(false);
    }
  };

  const clickAddressItem = (nm, email) => {
    for (let i = 0; i < participants.length; i++) {
      if (email === participants[i].PRTCP_EMAIL) {
        alert("이미 참여자로 추가되어 있습니다.");
        return;
      }
    }
    setParticipants(participants.concat({ PRTCP_NM: nm, PRTCP_EMAIL: email }));
    setBookmarkInfo({
      ...bookmarkInfo,
      participants: participants,
    });

    setInputs({
      PRTCP_NM: "",
      PRTCP_EMAIL: "",
    });
  };
  const [addressPopup, setAddressPopup] = useState(false);
  const handleAddress = type => {
    if (type === true) {
      setAddressPopup(true);
      getAddressAll();
    } else if (type === false) {
      setAddressPopup(false);
    }
  };
  const [addressAll, setAddressAll] = useState([]);
  const getAddressAll = () => {
    axios
      .get("/address/AddressCreator")
      .then(({ data }) => setAddressAll(data))
      .catch(error => {
        console.log(error);
      });
  };

  const clickBackgound = () => {
    if (addressPopup) {
      setAddressPopup(false);
    }
    if (partiSidebar) {
      setPartiSidebar(false);
    }
  };

  const addCheckedAddressList = addressList => {
    let tmpAddress = [];
    for (let i = 0; i < addressList.length; i++) {
      for (let j = 0; j < participants.length; j++) {
        if (addressList[i].ADR_EMAIL === participants[j].PRTCP_EMAIL) {
          alert("이미 참여자로 추가되어 있습니다.");
          return;
        }
      }
      tmpAddress.push({
        PRTCP_NM: addressList[i].ADR_NM,
        PRTCP_EMAIL: addressList[i].ADR_EMAIL,
      });
    }
    setParticipants(participants.concat(tmpAddress));
  };

  const handleSaveBookmark = () => {
    if (bookmarkInfo.TITLE === "" || bookmarkInfo.participants.length === 0) {
      return;
    }
    props.setLoading(true);
    let paramInfo = {
      ...bookmarkInfo,
    };
    if (paramInfo.GROUP_YN === "Y") {
      paramInfo = {
        ...paramInfo,
        GROUP_NUM: groupInfoState.GROUP_NUM,
      };
    }

    axios
      .post("/schedule/insertFavorite", paramInfo)
      .then(response => {
        if (response.status === 200) {
          props.setLoading(false);
          handleDialogOpen(
            response.data[0].FAVORITE,
            response.data[0].PARTICIPANTS
          );
        } else {
          props.setLoading(false);
          alert("즐겨찾는 회의가 저장되지 않았습니다.");
        }
      })
      .catch(error => {
        props.setLoading(false);
        alert("즐겨찾는 회의 저장과정에서 오류가 발생하였습니다.");
        console.log(error);
      });
  };

  const [dialogOpen, setDialogOpen] = useState(false);
  const [resultBookmark, setResultBookmark] = useState({});
  const [resultParticipants, setResultParticipants] = useState([]);
  const handleDialogOpen = (favorite, participants) => {
    setResultBookmark(favorite);
    setResultParticipants(participants);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    props.handleBookmarkReload();
    setBookmarkInfo({
      TITLE: "",
      CONTENTS: "",
      participants: [],
    });
    if (isActive) {
      setIsActive(false);
    }
    props.setIsInput(false);
    props.setResSidebarType("res");
  };

  /* 저장 버튼 활성화 */
  const [isActive, setIsActive] = useState(false);
  const activeBtn = () => {
    if (
      bookmarkInfo.TITLE !== "" &&
      bookmarkInfo.TITLE !== null &&
      bookmarkInfo.TITLE !== undefined &&
      Array.isArray(bookmarkInfo.participants) &&
      bookmarkInfo.participants.length > 0
    ) {
      setIsActive(true);
    } else if (
      bookmarkInfo.TITLE === "" ||
      bookmarkInfo.TITLE === null ||
      bookmarkInfo.TITLE === undefined ||
      bookmarkInfo.participants.length <= 0
    ) {
      setIsActive(false);
    }
  };
  useEffect(() => {
    activeBtn();
  }, [bookmarkInfo.TITLE, bookmarkInfo.participants]);

  return (
    <>
      <ParticipantSidebar
        width={456}
        height={"954px"}
        defaultX={750}
        activeX={1011}
        partiSidebar={partiSidebar}
        groupYN={groupInfoState.GROUP_YN}
        groupNum={groupInfoState.GROUP_NUM}
        clickAddressItem={clickAddressItem}
        addCheckedAddressList={addCheckedAddressList}
        handlePartiSidebar={handlePartiSidebar}
      />
      <div className="bookmarkSidebar" onClick={clickBackgound}>
        <div className="bookmarkSidebar_margin">
          <div className="bookmarkSidebar_top">
            <div className="bookmarkSidebar_title">
              {t("bookmark.sidebar.header.CREATE")}
            </div>
            <img
              className="bookmarkSidebar_closeBtn cursor"
              src="/img/icon/schedule/close.png"
              alt="close"
              onClick={cancelEvent}
            />
          </div>
          <div className="bookmarkSidebar_expln">
            {t("bookmark.sidebar.EXPLN1")}
            <br />
            {t("bookmark.sidebar.EXPLN2")}
          </div>
          <div style={{ marginTop: "40px" }}>
            <div>
              <img
                className="resIcon"
                src="/img/icon/schedule/title_essential.png"
                alt="title"
              />
              <input
                ref={inputRef}
                className="bookmarkSidebar_input"
                type="text"
                placeholder={t("bookmark.placeholder.TITLE")}
                value={bookmarkInfo.TITLE}
                name="TITLE"
                maxLength="50"
                onChange={onChangeEvent}
                onFocus={() => handlePartiSidebar(false)}
              />
            </div>

            <div style={{ marginTop: "16px" }}>
              <img
                className="resIcon_content"
                src="/img/icon/schedule/contents.png"
                alt="contents"
              />
              <textarea
                className="bookmarkSidebar_textArea"
                type="text"
                placeholder={t("bookmark.placeholder.CONTENTS")}
                value={bookmarkInfo.CONTENTS}
                name="CONTENTS"
                maxLength="255"
                onChange={onChangeEvent}
                onFocus={() => handlePartiSidebar(false)}
              />
            </div>
            <div style={{ marginTop: "16px" }}>
              <img
                className="resIcon"
                src="/img/icon/schedule/participant.png"
                alt="participant"
              />
              <AddParticipant
                className="bookmarkSidebar_input_participant"
                PRTCP_EMAIL={PRTCP_EMAIL}
                placeholder={t("bookmark.placeholder.PARTICIPANTS")}
                onChange={onChangeParticipant}
                onKeyPress={handleKeyPress}
                onFocus={() => (handlePartiSidebar(true), handleAddress(true))}
              />
              <button
                className={
                  PRTCP_EMAIL.length > 0
                    ? "bookmarkSidebar_btn_addParticipant_active"
                    : "bookmarkSidebar_btn_addParticipant"
                }
                onClick={() => {
                  if (PRTCP_EMAIL.length > 0) {
                    onCreate();
                  }
                }}
              >
                참석자 추가
              </button>
              <AddressPopup
                className="favoriteAddressPopup scrollbar"
                open={addressPopup}
                address={addressAll}
                clickAddressItem={clickAddressItem}
                search={inputs.PRTCP_EMAIL}
              />
              <ParticipantList
                className="participantList scrollbar"
                participants={participants}
                length={participants.length}
                onRemove={onRemove}
              />
            </div>
          </div>
        </div>
        <button
          className={
            isActive
              ? "bookmarkSidebar_saveBtnActive"
              : "bookmarkSidebar_saveBtn"
          }
          onClick={() => (handleSaveBookmark(), handlePartiSidebar(false))}
          disabled={!isActive}
        >
          저장하기
        </button>
      </div>
      <BookmarkResultPopup
        type="CREATE"
        bookmark={resultBookmark}
        participants={resultParticipants}
        open={dialogOpen}
        close={handleDialogClose}
      />
    </>
  );
}

export default CreateBookmarkSidebar;
