import React from "react";
import axios from "axios";
import ReactModal from "react-modal";
import { useTranslation } from "react-i18next";
/**
 * Version : 1.0
 * 파일명 : bookmarkDeletePopup.js
 * 작성일자 : 2021-11-22
 * 작성자 : 강연승
 * 설명 :
 * 수정일자 : 2022-02-10
 * 수정자 : 강연승
 * 수정내역 : 텍스트 수정 영상회의 -> 화상회의, 웹폰트 변경
 * props :
 * 전달받은값 - 설명
 */

function BookmarkDeletePopup(props) {
  const closePopup = () => {
    props.close();
  };
  const deleteBookmark = () => {
    axios
      .put("/schedule/deleteFavorite", {
        FAVORITE_NUM: props.bookmarkInfo.FAVORITE_NUM,
      })
      .then(response => {
        if (response.status === 200) {
          closePopup();
          alert("선택한 즐겨찾기가 삭제되었습니다.");
          props.reload();
        } else {
          alert("회의가 삭제되지 않았습니다.");
        }
      })
      .catch(error => {
        alert("회의 삭제과정에서 오류가 발생하였습니다.");
        console.log(error);
      });
  };
  return (
    <ReactModal
      isOpen={props.open} //모달 Open(Boolean)
      className="bookmarkDeletePopup" //모달 ClassName
      overlayClassName="bookmarkDeletePopup_Overlay" //모달오버레이 ClassName
      onRequestClose={closePopup}
      shouldCloseOnEsc={true}
      animationType="fade"
      transparent={true}
      closeTimeoutMS={200}
    >
      <div>
        <div
          style={{
            font: "normal normal normal 24px/32px Spoqa Han Sans Neo",
            margin: "32px 0 0 40px",
          }}
        >
          즐겨찾기 회의를 삭제 할까요?
          <img
            src="/img/icon/group/close.png"
            alt="close"
            onClick={closePopup}
            style={{
              cursor: "pointer",
              float: "right",
              marginRight: "32px",
              // position: "absolute",
              // top: "32px",
              // right: "32px",
            }}
          />
        </div>
      </div>
      <div style={{ margin: "40px 0 0 48px" }}>
        <div
          style={{
            color: "#2B4AC4",
            font: "normal normal bold 18px/24px Spoqa Han Sans Neo",
          }}
        >
          <div>{props.bookmarkInfo.TITLE}</div>
        </div>
        {Array.isArray(props.bookmarkInfo.participants) &&
        props.bookmarkInfo.participants.length > 0 ? (
          <div style={{ marginTop: "16px", marginBottom: "32px" }}>
            <div
              style={{
                display: "inline-block",
                verticalAlign: "top",
                marginRight: "18px",
                font: "normal normal bold 16px/21px Spoqa Han Sans Neo",
              }}
            >
              참석자
            </div>
            <div
              style={{
                display: "inline-block",
                font: "normal normal normal 16px/24px Spoqa Han Sans Neo",
              }}
            >
              {props.bookmarkInfo.participants.map(data => (
                <div key={data.PRTCP_NUM}>
                  <div style={{ display: "inline-block" }}>{data.PRTCP_NM}</div>
                  <div style={{ display: "inline-block", marginLeft: "8px" }}>
                    {data.PRTCP_EMAIL}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
      <div
        style={{
          borderTop: "1px solid #D1D3DA",
          padding: "24px",
          textAlign: "right",
        }}
      >
        <img
          src="/img/icon/schedule/delete.png"
          alt="delete"
          style={{ display: "inline-block" }}
        />
        <div
          style={{
            display: "inline-block",
            color: "red",
            marginLeft: "8px",
          }}
        >
          이 일정을 삭제할까요?
        </div>
        <button
          style={{
            display: "inline-block",
            width: "64px",
            height: "36px",
            backgroundColor: "white",
            border: "none",
            color: "#C63353",
            font: "normal normal bold 14px/18px Spoqa Han Sans Neo",
            marginLeft: "16px",
            marginRight: "8px",
            cursor: "pointer",
          }}
          onClick={deleteBookmark}
        >
          예
        </button>
        <button
          style={{
            display: "inline-block",
            width: "58px",
            height: "36px",
            backgroundColor: "white",
            border: "none",
            color: "#2B4AC4",
            font: "normal normal bold 14px/18px Spoqa Han Sans Neo",
            cursor: "pointer",
          }}
          onClick={closePopup}
        >
          아니오
        </button>
      </div>
    </ReactModal>
  );
}

export default BookmarkDeletePopup;
