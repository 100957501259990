import React, { useState, useEffect, useCallback } from "react";
import { Chart } from "chart.js";
import "chart.js/auto";
import { Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Loading from "component/tag/loading/loading";
/**
 * Version : 1.0
 * 파일명 : ReserveTotal.js
 * 작성일자 : 2022-02-23
 * 작성자 : 강연승
 * 설명 : 대시보드 아이템 - 요일별 회의 진행 빈도 차트
 * 수정일자 : 2022-06-08
 * 수정자 : 강연승
 * 수정내역 : 차트 폰트패밀리 적용
 */
function MeetingCntBar() {
  /**
   * REDUCER
   */
  const GROUP_STATE = useSelector(state => state.groupInfoReducer);
  /**
   * 언어변환
   */
  const { t } = useTranslation();
  /**
   * chart.js
   */
  Chart.defaults.font.family = "'Spoqa Han Sans Neo', 'sans-serif'";
  /**
   * STATE
   */
  const [loading, setLoading] = useState(false);
  const [resCntList, setResCntList] = useState([
    { day: "SUN", cnt: 0 },
    { day: "MON", cnt: 0 },
    { day: "TUE", cnt: 0 },
    { day: "WED", cnt: 0 },
    { day: "THU", cnt: 0 },
    { day: "FRI", cnt: 0 },
    { day: "SAT", cnt: 0 },
  ]);
  const [partiCntList, setPartiCntList] = useState([
    { day: "SUN", cnt: 0 },
    { day: "MON", cnt: 0 },
    { day: "TUE", cnt: 0 },
    { day: "WED", cnt: 0 },
    { day: "THU", cnt: 0 },
    { day: "FRI", cnt: 0 },
    { day: "SAT", cnt: 0 },
  ]);
  /**
   * 요일별 월간 예약 및 참석 횟수 조회
   */
  const getMeetingCntList = useCallback(() => {
    setLoading(true);
    let param = {};
    if (GROUP_STATE.GROUP_YN === "Y") {
      param = {
        GROUP_YN: GROUP_STATE.GROUP_YN,
        GROUP_NUM: GROUP_STATE.GROUP_NUM,
      };
    } else if (GROUP_STATE.GROUP_YN === "N") {
      param = {
        GROUP_YN: GROUP_STATE.GROUP_YN,
      };
    }
    axios
      .get("/schedule/dashboard/weekDayMeetingCnt", {
        params: param,
      })
      .then(res => {
        if (res.status === 200) {
          console.log(res.data);
          setResCntList([
            { day: "SUN", cnt: res.data.RES.SUN },
            { day: "MON", cnt: res.data.RES.MON },
            { day: "TUE", cnt: res.data.RES.TUE },
            { day: "WED", cnt: res.data.RES.WED },
            { day: "THU", cnt: res.data.RES.THU },
            { day: "FRI", cnt: res.data.RES.FRI },
            { day: "SAT", cnt: res.data.RES.SAT },
          ]);
          if (res.data.hasOwnProperty("PARTI")) {
            setPartiCntList([
              { day: "SUN", cnt: res.data.PARTI.SUN },
              { day: "MON", cnt: res.data.PARTI.MON },
              { day: "TUE", cnt: res.data.PARTI.TUE },
              { day: "WED", cnt: res.data.PARTI.WED },
              { day: "THU", cnt: res.data.PARTI.THU },
              { day: "FRI", cnt: res.data.PARTI.FRI },
              { day: "SAT", cnt: res.data.PARTI.SAT },
            ]);
          }
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [GROUP_STATE.GROUP_NUM, GROUP_STATE.GROUP_YN]);
  /**
   * useEffect
   */
  useEffect(() => {
    getMeetingCntList();
  }, [getMeetingCntList]);
  return (
    <div className="dashboardMeetingCntBarArea">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <img src="/img/icon/dashboard/bar.png" className="dashboardIcon" />
          <font className="title">
            {t(
              "group.dashboard.txt.meetingCntBar." + GROUP_STATE.GROUP_CATEGORY
            )}
          </font>
        </div>
        {GROUP_STATE.GROUP_YN === "N" ? (
          <div
            style={{
              display: "flex",
              height: "19px",
              justifyContent: "center",
            }}
          >
            <div className="resMeetingDiv">
              <div className="resMeeting">개설 회의</div>
              <div className="resMeetingBox" />
            </div>
            <div className="endMeetingDiv">
              <div className="endMeeting">참석 회의</div>
              <div className="endMeetingBox" />
            </div>
          </div>
        ) : null}
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div style={{ marginTop: "9px", marginLeft: "32px" }}>
          <Bar
            data={{
              labels: resCntList.map(data => data.day),
              datasets:
                GROUP_STATE.GROUP_YN === "Y"
                  ? [
                      {
                        label: "개설 회의",
                        data: resCntList.map(data => data.cnt),
                        backgroundColor: "#ffb423",
                        stack: "Stack 0",
                      },
                    ]
                  : [
                      {
                        label: "개설 회의",
                        data: resCntList.map(data => data.cnt),
                        backgroundColor: "#ffb423",
                        stack: "Stack 0",
                      },
                      {
                        label: "참석 회의",
                        data: partiCntList.map(data => data.cnt),
                        backgroundColor: "#7f84fb",
                        stack: "Stack 0",
                      },
                    ],
            }}
            options={{
              plugins: {
                legend: {
                  display: false,
                },
              },
              maintainAspectRatio: false,
              barThickness: "38",
              scales: {
                x: {
                  grid: {
                    display: false,
                  },
                },
                y: {
                  stacked: true,
                  // ticks: {
                  //   stepSize: 5,
                  // },
                },
              },
            }}
            height="213"
          />
        </div>
      )}
    </div>
  );
}

export default MeetingCntBar;
