import React, { useState } from "react";
import ReactModal from "react-modal";
import "css/coachmark.css";
/**
 * Version : 1.0
 * 파일명 : CoachMark.js
 * 작성일자 : 2022-06-08
 * 작성자 : 권도훈
 * 설명 : 코치마크
*/
function CoachMark() {
    const [isShow, setIsShow] = useState(true);
    const [page, setPage] = useState(1);
    const [isHover, setIsHover] = useState(false);
    const pageTotal = 6;

    return (
        <ReactModal
            isOpen={isShow}
            className="coachmark"
            overlayClassName="coachmark-overlay"
            animationType="fade"
            transparent={true}
        >
            <div className="cm-slide-btn-area">
                <img
                    src="/img/icon/coachmark/arrow.png"
                    alt=""
                    className={
                        page < 2
                            ? "cm-slide-btn none"
                            : "cm-slide-btn cursor"
                    }
                    onClick={() => {
                        setPage(page - 1);
                    }}
                />
                <img
                    src="/img/icon/coachmark/arrow.png"
                    alt=""
                    className={
                        page === pageTotal
                            ? "cm-slide-btn none"
                            : "cm-slide-btn cursor"
                    }
                    onClick={() => {
                        setPage(page + 1);
                    }}
                />
            </div>

            <div className="cm-bot-area">
                <div className="cm-pagecount-area">
                    <span className="cm-pagecount-current">{page}</span>
                    <span className="cm-pagecount-line" />
                    <span className="cm-pagecount-total">{pageTotal}</span>
                </div>

                <div
                    className="cm-close-btn center cursor"
                    onClick={() => setIsShow(false)}
                    onMouseOver={() => setIsHover(true)}
                    onMouseOut={() => setIsHover(false)}
                >
                    <img
                        className={
                            isHover
                                ? "cm-close-btn-img hover"
                                : "cm-close-btn-img"
                        }
                        src="/img/icon/coachmark/close.png"
                        alt=""
                    />
                </div>
            </div>

            <img
                className="drag-none"
                src={"/img/coachmark/" + page + ".png"}
                alt=""
            />
        </ReactModal>
    )
}

export default CoachMark
