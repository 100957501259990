import React, { useState } from 'react'
import { CircularProgress } from '@material-ui/core'
import { useHistory } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { openAlert, closeAlert } from "actions/index";

/**
 * Version : 1.0
 * 파일명 : changePw.js
 * 작성일자 : 2021-12-06
 * 작성자 : 권도훈
 * 설명 : 비밀번호 변경 페이지
 * 수정일자 : 2022-02-09
 * 수정자 : 권도훈
 * 수정내역 : UI 수정
*/
function ChangePw(props) {
    /**
     * history
     */
    const history = useHistory();
    /**
     * dispatch
     */
    const dispatch = useDispatch();
    /**
     * 비밀번호
     */
    const [password, setPassword] = useState({
        newPW: "",
        reEnterPW: ""
    });
    /**
     * 비밀번호 검사
     */
    const isValidPassword = (pw) => {
        var pattern1 = /[0-9]/;
        var pattern2 = /[a-zA-Z]/;
        var pattern3 = /[~!@\\#$%<>^&*]/;     // 원하는 특수문자 추가 제거 \ 확인

        if (!pattern1.test(pw) || !pattern2.test(pw) || !pattern3.test(pw) || pw.length < 8 || pw.length > 50) {
            return false;
        }

        return true;
    }
    /**
     * 비밀번호 변경
     */
    const changeBtnEvent = () => {
        if (password.newPW === "" || password.reEnterPW === "") {
            const obj = {
                TEXT: "비밀번호가 비어있습니다.",
                submitEventHandler: () => {
                    dispatch(closeAlert());
                }
            };
            dispatch(openAlert(obj));
        } else if (password.newPW !== password.reEnterPW) {
            const obj = {
                TEXT: "비밀번호가 일치하지 않습니다.",
                submitEventHandler: () => {
                    dispatch(closeAlert());
                }
            };
            dispatch(openAlert(obj));
        } else if (!isValidPassword(password.newPW) || !isValidPassword(password.reEnterPW)) {
            const obj = {
                TEXT: "비밀번호는 영문&숫자&특수기호 8자리 이상으로 구성하여야 합니다.",
                submitEventHandler: () => {
                    dispatch(closeAlert());
                }
            };
            dispatch(openAlert(obj));
        } else {
            props.updatePassword(password);
        }
    }

    return (
        <div
            style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <img
                className="cursor"
                src="/img/logo.png"
                alt="logo_title"
                onClick={() => window.location.href = "/"}
            />

            <div
                style={{
                    fontSize: "28px",
                    fontWeight: "bold",
                    color: "#3E3E4F",
                    marginTop: "16px",
                    marginBottom: "24px"
                }}
            >
                비밀번호를 재설정합니다.
            </div>

            <div
                style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#3E3E4F"
                }}
            >
                비밀번호는 영문&숫자&특수기호 8자리 이상으로 구성하여야 합니다.
            </div>

            <section
                style={{
                    width: "560px",
                    height: "312px",
                    background: "#F8F9FD",
                    borderRadius: "16px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "56px"
                }}
            >
                <input
                    type="password"
                    className='searchPwInput'
                    placeholder="비밀번호 입력 (영문&숫자&특수기호 8자리 이상 입력)"
                    onChange={
                        e => {
                            setPassword({
                                ...password,
                                newPW: e.target.value
                            })
                        }
                    }
                    onKeyDown={
                        e => {
                            if (e.key === "Enter") {
                                changeBtnEvent();
                            }
                        }
                    }
                    style={{
                        marginBottom: "16px"
                    }}
                />
                <input
                    type="password"
                    className='searchPwInput'
                    placeholder="비밀번호 재입력"
                    onChange={
                        e => {
                            setPassword({
                                ...password,
                                reEnterPW: e.target.value
                            })
                        }
                    }
                    onKeyDown={
                        e => {
                            if (e.key === "Enter") {
                                changeBtnEvent();
                            }
                        }
                    }
                />

                <button
                    style={{
                        marginTop: "16px"
                    }}
                    className='searchPwBtn cursor'
                    disabled={
                        props.loading
                            ?
                            true
                            :
                            false
                    }
                    onClick={() => changeBtnEvent()}
                >
                    {
                        props.loading
                            ?
                            <CircularProgress
                                style={{
                                    width: "24px",
                                    height: "24px"
                                }}
                            />
                            :
                            "비밀번호 변경하기"
                    }
                </button>
            </section>
        </div >
    )
}

export default ChangePw
