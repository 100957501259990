import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Loading from "component/tag/loading/loading";
/**
 * Version : 1.0
 * 파일명 : ReserveTotal.js
 * 작성일자 : 2022-02-23
 * 작성자 : 강연승
 * 설명 : 대시보드 아이템 - 총 예약 회의 건 수
 * 수정일자 : 2022-03-07
 * 수정자 : 강연승
 * 수정내역 : 툴팁 추가
 */
function ReserveTotal() {
  /**
   * REDUCER
   */
  const REDUCER_STATE = useSelector(state => state.groupInfoReducer);
  /**
   * 언어변환
   */
  const { t } = useTranslation();
  /**
   * STATE
   */
  const [loading, setLoading] = useState(false);
  const [resTotal, setResTotal] = useState(0);
  const [todayCnt, setTodayCnt] = useState(0);
  const [isShowTooltip, setIsShowTooltip] = useState(false);
  const [tooltipType, setTooltipType] = useState("");
  /**
   * 총 예약 회의 수 조회
   */
  const getMeetingResCnt = useCallback(() => {
    setLoading(true);
    let param = {};
    if (REDUCER_STATE.GROUP_YN === "Y") {
      param = {
        GROUP_YN: REDUCER_STATE.GROUP_YN,
        GROUP_NUM: REDUCER_STATE.GROUP_NUM,
      };
    } else if (REDUCER_STATE.GROUP_YN === "N") {
      param = {
        GROUP_YN: REDUCER_STATE.GROUP_YN,
      };
    }
    axios
      .get("/schedule/dashboard/meetingResCnt", {
        params: param,
      })
      .then(res => {
        if (res.status === 200) {
          setResTotal(res.data.TOTAL);
          setTodayCnt(res.data.TODAY_CNT);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [REDUCER_STATE.GROUP_NUM, REDUCER_STATE.GROUP_YN]);
  /**
   * useEffect
   */
  useEffect(() => {
    getMeetingResCnt();
  }, [getMeetingResCnt]);
  /**
   * 툴팁 설정
   */
  const onMouseTooltip = (overYN, type) => {
    setTooltipType(type);
    if (overYN === "over") {
      setIsShowTooltip(true);
    } else if (overYN === "out") {
      setIsShowTooltip(false);
    }
  };
  return (
    <div className="dashboardMeetingItem">
      <div style={{ height: "19px" }}>
        <img
          src="/img/icon/dashboard/reservation.png"
          className="dashboardIcon"
        />
        <font className="title">
          {t("group.dashboard.txt.resTotal." + REDUCER_STATE.GROUP_CATEGORY)}
        </font>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div
          style={{
            display: "flex",
            marginTop: "20px",
            justifyContent: "center",
          }}
        >
          <div
            className="reserveTotal"
            onMouseOver={() => onMouseTooltip("over", "TOTAL")}
            onMouseOut={() => onMouseTooltip("out", "TOTAL")}
          >
            {resTotal}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                className="tooltip"
                style={{
                  display:
                    isShowTooltip && tooltipType === "TOTAL" ? "block" : "none",
                }}
              >
                <div
                  style={{
                    font: "normal normal bold 12px/18px Spoqa Han Sans Neo",
                    color: "#FFFFFF",
                  }}
                >
                  <div>
                    {t(
                      "group.dashboard.txt.resTotal.tooltip.total." +
                        REDUCER_STATE.GROUP_CATEGORY
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="reserveToday"
            onMouseOver={() => onMouseTooltip("over", "TODAY")}
            onMouseOut={() => onMouseTooltip("out", "TODAY")}
          >
            +{todayCnt}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                className="tooltip"
                style={{
                  display:
                    isShowTooltip && tooltipType === "TODAY" ? "block" : "none",
                }}
              >
                <div
                  style={{
                    font: "normal normal bold 12px/18px Spoqa Han Sans Neo",
                    color: "#FFFFFF",
                  }}
                >
                  <div>
                    {t(
                      "group.dashboard.txt.resTotal.tooltip.today." +
                        REDUCER_STATE.GROUP_CATEGORY
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ReserveTotal;
