import React from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openAlert, closeAlert } from "actions/index";
import 'css/paging.css';
/**
 * Version : 1.0
 * 파일명 : CommonPaging.js
 * 작성일자 : 2022-04-22
 * 작성자 : 권도훈
 * 설명 : 공통 페이징 신규
 */
function CommonPaging(props) {
    const total = props.res.data.read();
    /**
     * dispatch
     */
    const dispatch = useDispatch();
    /**
     * TRANSLATION
     */
    const { t } = useTranslation();
    /**
     * function
     */
    //기본 페이징 이벤트
    const changePage = (type) => {
        if (type === "prev") {
            if (props.page.PAGE_COUNT - 10 < 0 || total <= 10) {
                const obj = {
                    TEXT: t("paging.first"),
                    submitEventHandler: () => dispatch(closeAlert())
                };
                dispatch(openAlert(obj));
                return;
            } else if (props.page.PAGE_COUNT >= 10) {
                let diff = props.page.PAGE_COUNT - 10;

                if (diff < 10) {
                    props.setPage({
                        ...props.page,
                        PAGE_COUNT: 0,
                    });
                } else {
                    props.setPage({
                        ...props.page,
                        PAGE_COUNT: props.page.PAGE_COUNT - 10,
                    });
                }
                props.setSearch(true);
            }
        } else if (type === "next") {
            if (props.page.PAGE_COUNT + 10 >= total || props.page.ROW_COUNT === total) {
                const obj = {
                    TEXT: t("paging.last"),
                    submitEventHandler: () => dispatch(closeAlert())
                };
                dispatch(openAlert(obj));
                return;
            } else if (props.page.PAGE_COUNT < total) {
                let diff = props.page.PAGE_COUNT + 10;

                if (diff > total) {
                    props.setPage({
                        ...props.page,
                        PAGE_COUNT: total - (total - props.page.PAGE_COUNT),
                    });
                } else {
                    props.setPage({
                        ...props.page,
                        PAGE_COUNT: props.page.PAGE_COUNT + 10,
                    });
                }
                props.setSearch(true);
            }
        }
    };
    //첫 페이지 이동
    const goFirstPage = () => {
        if (props.page.PAGE_COUNT - 10 < 0 || total <= 10) {
            const obj = {
                TEXT: t("paging.first"),
                submitEventHandler: () => dispatch(closeAlert())
            };
            dispatch(openAlert(obj));
            return;
        } else if (props.page.PAGE_COUNT >= 10) {
            props.setPage({
                ...props.page,
                PAGE_COUNT: 0,
            });
            props.setSearch(true);
        }
    };
    //마지막 페이지 이동
    const goLastPage = () => {
        if (props.page.PAGE_COUNT + 10 >= total || props.page.ROW_COUNT === total) {
            const obj = {
                TEXT: t("paging.last"),
                submitEventHandler: () => dispatch(closeAlert())
            };
            dispatch(openAlert(obj));
            return;
        } else if (props.page.PAGE_COUNT < total) {

            const diff = total % 10;

            if (diff === 0) {
                props.setPage({
                    ...props.page,
                    PAGE_COUNT: total - 10,
                });
            } else {
                props.setPage({
                    ...props.page,
                    PAGE_COUNT: total - diff,
                });
            }
            props.setSearch(true);
        }
    };

    return (
        <div className='pg-common-div'>
            <font className="pg-common-font">
                {
                    total > 0
                        ?
                        total + "개 중 " + (props.page.PAGE_COUNT + 1) + "-" +
                        (
                            props.page.PAGE_COUNT + 10 >= total
                                ? total
                                : props.page.PAGE_COUNT + 10
                        )
                        : "0개"
                }
            </font>

            <div>
                <img
                    className="pg-common-img cursor"
                    src="/img/icon/address/first_page_btn.png"
                    alt=""
                    onClick={() => goFirstPage()}
                />
                <img
                    className="pg-common-img cursor"
                    src="/img/icon/address/prev_page_btn.png"
                    alt=""
                    onClick={() => changePage("prev")}
                />
                <img
                    className="pg-common-img cursor"
                    src="/img/icon/address/next_page_btn.png"
                    alt=""
                    onClick={() => changePage("next")}
                />
                <img
                    className="pg-common-img cursor"
                    src="/img/icon/address/last_page_btn.png"
                    alt=""
                    onClick={() => goLastPage()}
                />
            </div>
        </div>
    )
}

export default CommonPaging
