/**
 * Import List
 */
import React from "react";
import Cookies from "universal-cookie";
/**
 * url 설정
 */
let front_url = "";

if (process.env.NODE_ENV === "development") {
  front_url =
    process.env.REACT_APP_FRONT_URL + process.env.REACT_APP_FRONT_PORT;
} else {
  front_url = process.env.REACT_APP_FRONT_URL;
}
/**
 * 구글 클라이언트 아이디
 */
const key = process.env.REACT_APP_GOOGLE_KEY;
/**
 * Version : 1.0
 * 파일명 : google.js
 * 작성일자 : 2021-11-09
 * 작성자 : 권도훈
 * 설명 : GOOGLE 로그인
 * 수정일자 : 2022-02-18
 * 수정자 : 강연승
 * 수정내역 : 그룹 초대 가입 추가
 */
function Google(props) {
  /**
   * 요청 URL 설정
   */
  const url = "https://accounts.google.com/o/oauth2/v2/auth";
  const redirect_uri = "redirect_uri=" + front_url + "/callback/google";
  const state = props.groupCode === "" ? "" : "&state=" + props.groupCode;
  const target =
    url +
    "?scope=profile email&response_type=code&access_type=offline&include_granted_scopes=true&client_id=" +
    key +
    "&" +
    redirect_uri +
    state;
  /**
   * 쿠키
   */
  const cookies = new Cookies();
  /**
   * 요청
   */
  const googleHandler = () => {
    //cookie
    const domain = process.env.REACT_APP_COOKIE_DOMAIN;
    const typeNm = process.env.REACT_APP_COOKIE_REQUEST_TYPE;

    cookies.set(
      typeNm,
      props.type,
      {
        path: "/",
        domain: domain
      }
    );

    window.location.href = target;
  };
  /**
   * 태그
   */
  return (
    <>
      {props.type === "LOGIN" || props.type === "DELETE" ? (
        <img
          className="cursor"
          src="/img/icon_google.png"
          alt="icon_google"
          onClick={googleHandler}
          style={{
            boxShadow: "0px 3px 6px #00000029",
            borderRadius: "999px",
          }}
        />
      ) : (
        <div>
          <img
            className="m-center block mt-4 cursor"
            src="/img/join_google.png"
            alt="join_google"
            onClick={googleHandler}
          />
        </div>
      )}
    </>
  );
}

export default Google;
