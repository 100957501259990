import React from "react";
import ReactModal from "react-modal";
/**
 * Version : 1.0
 * 파일명 : Alert.js
 * 작성일자 : 2021-10-28
 * 작성자 : 권도훈
 * 설명 : 공통 알림창
 */
function Alert(props) {
    /**
     * body
     */
    ReactModal.setAppElement("body");
    /**
     * 태그
     */
    return (
        <ReactModal
            isOpen={props.alertProps.isShow} //모달 Open(Boolean)
            className="Alert" //모달 ClassName
            overlayClassName="defaultAlertOverlay" //모달오버레이 ClassName
            animationType="fade"
            transparent={true}
        >
            <div
                className="drag-none"
                style={{
                    paddingTop: "30px",
                    paddingBottom: "20px",
                    marginLeft: "24px",
                    marginRight: "24px",
                    textAlign: "center",
                    fontSize: "16px",
                    color: "#000000",
                }}
            >
                {props.alertProps.text}
            </div>
            <div
                style={{
                    height: "100%",
                    textAlign: "right",
                }}
            >
                <button
                    className="cursor inline"
                    onClick={() => props.alertProps.cancleBtnEventHandler()}
                    style={{
                        display: props.alertProps.cancelBtnDisplay ? "inline" : "none",
                        width: "64px",
                        height: "36px",
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#D7263D",
                        background: "none",
                        outline: "none",
                        border: "none",
                        marginRight: "8px",
                        marginBottom: "6px",
                    }}
                >
                    취소
                </button>

                <button
                    className="cursor inline"
                    onClick={() => props.alertProps.okBtnEventHandler()}
                    style={{
                        display: props.alertProps.okBtnDisplay ? "inline" : "none",
                        width: "64px",
                        height: "36px",
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#2B4AC4",
                        background: "none",
                        outline: "none",
                        border: "none",
                        marginRight: "8px",
                        marginBottom: "6px",
                    }}
                >
                    확인
                </button>
            </div>
        </ReactModal>
    )
}

export default Alert