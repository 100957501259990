import axios from 'axios';
import React, { useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { openAlert, closeAlert, openPayDonePopup, openConfirm } from "actions/index";
import Loading from "component/tag/loading/loading";
import { CircularProgress } from "@material-ui/core";
/**
 * Version : 1.0
 * 파일명 : PaymenyList.js
 * 작성일자 : 2022-04-05
 * 작성자 : 권도훈
 * 설명 : 결제수단 정보
 * 수정일자 : 2022-04-22
 * 수정자 : 권도훈
 * 수정내역 : ui 및 기능 수정
 */
function PaymenyList(props) {
    /**
     * reducer
     */
    const GROUP_STATE = useSelector(state => state.groupInfoReducer);
    /**
     * dispatch
     */
    const dispatch = useDispatch();
    /**
     * state
     */
    const [loading, setLoading] = useState(false);
    const [payLoading, setPayLoading] = useState(false);
    const [init, setInit] = useState(true);
    const [disabled, setDisable] = useState(true);
    const [availPayment, setAvailPayment] = useState(false);
    const [paymentInfo, setPaymentInfo] = useState({
        COMPANY: "",
        METHOD_KEY: "",
        PAYMENT_NUM: "",
        PAYMENT_TYPE: "",
        PAYMENT_NAME: "",
        SUCCESS_YN: ""
    });
    /**
     * 정기결제 수단 조회
     */
    const getPaymentInfo = useCallback(() => {
        setLoading(true);

        axios.get("/payment/recurring", null)
            .then(res => {
                if (res.status === 200) {
                    setPaymentInfo(res.data);
                    setAvailPayment(true);
                    setDisable(false);
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            })
    }, []);

    useEffect(() => {
        if (init) {
            getPaymentInfo();
            setInit(false);
        }
    }, [getPaymentInfo, init]);

    /**
     * 결제 토큰 조회
     */
    const getPayAuthToken = () => {
        axios.get("/payment/auth")
            .then(res => {
                if (res.status === 200) {
                    if (res.data.IS_AUTH === true) {
                        props.setType("ADD_PAYMENT");
                        props.setIsRender(true);
                    } else {
                        props.setType("AUTH");
                        props.setIsRender(true);
                    }
                }
            })
            .catch(() => {
                const obj = {
                    TEXT: "에러가 발생했습니다.",
                    submitEventHandler: () => dispatch(closeAlert())
                };
                dispatch(openAlert(obj));
            })
    }
    /**
     * 가격 포맷
     */
    const amountFormat = (amount) => {
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + "원";
    }
    /**
     * vat 가격
     */
    const calVat = (amount, count) => {
        const vat = (amount * count) / 10;
        return vat;
    }
    /**
     * 날짜 포맷
     */
    const dateFormat = (date) => {
        const arr = date.split("-");
        return arr[0] + "년 " + arr[1] + "월 " + arr[2] + "일"
    }
    /**
     * 정기결제 등록 및 추가
     */
    const setRecurringPayment = () => {
        setPayLoading(true);

        const config = {
            params: {
                PRODUCT_NAME: props.selectedProduct.NAME,
                PRODUCT_AMOUNT: props.selectedProduct.AMOUNT,
                PRODUCT_VAT: props.selectedProduct.VAT,
                PRODUCT_COUNT: props.selectedProduct.COUNT,
                PRODUCT_CODE: props.selectedProduct.CODE,
                GROUP_NUM: GROUP_STATE.GROUP_NUM
            }
        }

        axios.post("/payment/recurring", null, config)
            .then(res => {
                setPayLoading(false);

                if (res.status === 200 && res.data.isSuccess) {
                    props.setIsShow(false);
                    props.setInit(true);
                    props.isRefresh(true);

                    const obj = {
                        TODAY: dateFormat(res.data.TODAY_DT),
                        PAY_DT: dateFormat(res.data.PAY_DT),
                        COUNT: props.selectedProduct.COUNT,
                        PRODUCT_NM: props.selectedProduct.NAME
                    };

                    dispatch(openPayDonePopup(obj));
                } else {
                    const obj = {
                        TEXT: res.data.MSG,
                        submitEventHandler: () => dispatch(closeAlert())
                    };
                    dispatch(openAlert(obj));
                }
            })
            .catch(() => {
                setPayLoading(false);
                const obj = {
                    TEXT: "오류가 발생했습니다.",
                    submitEventHandler: () => dispatch(closeAlert())
                };
                dispatch(openAlert(obj));
            })
    }

    return (
        <div className='py-product-payment'>
            <div className='py-product-selected-info'>
                <div className='py-product-selected-info-container'>
                    <div className='py-product-selected-info-title'>
                        <font>
                            선택 상품 내역
                        </font>
                    </div>

                    <div className='py-product-selected-info-content'>
                        <div className='py-product-selected-info-content-top'>
                            <font>
                                {props.selectedProduct.NAME}
                            </font>

                            <font>
                                이용권 {props.selectedProduct.COUNT}개
                            </font>
                        </div>

                        <div className='py-product-selected-info-content-bot'>
                            <font>
                                총 결제 금액
                            </font>

                            <font>
                                {amountFormat(props.selectedProduct.AMOUNT * props.selectedProduct.COUNT)}
                            </font>

                            {/* <font>
                                (*VAT별도)
                            </font> */}
                        </div>
                    </div>
                </div>

                <div
                    className='py-product-selected-info-btn cursor'
                    onClick={() => {
                        props.setType("PRODUCT");
                        props.setIsRender(true);
                    }}
                >
                    <img
                        src="/img/icon/payment/back.png"
                        alt='back'
                    />
                    다시 선택하기
                </div>
            </div>

            <div className='py-product-payment-info'>
                <div className='py-product-payment-info-container'>
                    <div className='py-product-payment-info-title'>
                        <font>
                            결제 수단 등록 및 선택
                        </font>
                    </div>

                    <div className='py-product-payment-info-content'>
                        {
                            loading
                                ?
                                <div className='py-product-payment-info-loading'>
                                    <Loading />
                                </div>
                                :
                                <div
                                    className={
                                        availPayment
                                            ? 'py-product-payment-info-card'
                                            : 'py-product-payment-info-card none cursor'
                                    }
                                    onClick={() => {
                                        if (!availPayment) {
                                            getPayAuthToken();
                                        }
                                    }}
                                >
                                    {
                                        availPayment
                                            ?
                                            <>
                                                <div className='py-product-payment-info-card-item'>
                                                    <div className='py-product-payment-info-card-item-inner'>
                                                        <div className='py-product-payment-info-card-item-top'>
                                                            {
                                                                paymentInfo.PAYMENT_TYPE === "CARD"
                                                                    ?
                                                                    <img
                                                                        src="/img/icon/payment/logo.png"
                                                                        alt=''
                                                                    />
                                                                    :
                                                                    <img
                                                                        src={"/img/icon/payment/bank/" + paymentInfo.PAYMENT_ICON + ".png"}
                                                                        alt=''
                                                                    />
                                                            }


                                                            <section>
                                                                {
                                                                    paymentInfo.PAYMENT_TYPE === "CARD"
                                                                        ? <p>{paymentInfo.COMPANY}</p>
                                                                        : <></>
                                                                }
                                                                <p>{paymentInfo.PAYMENT_NAME}</p>
                                                            </section>
                                                        </div>

                                                        <div
                                                            className={
                                                                paymentInfo.PAYMENT_TYPE === "CARD"
                                                                    ? 'py-product-payment-info-card-item-bot'
                                                                    : 'py-product-payment-info-card-item-bot transfer'
                                                            }
                                                        >
                                                            <section>
                                                                <p>
                                                                    {
                                                                        paymentInfo.PAYMENT_TYPE === "CARD"
                                                                            ? "카드번호"
                                                                            : "계좌번호"
                                                                    }
                                                                </p>

                                                                <p>
                                                                    {paymentInfo.PAYMENT_NUM}
                                                                </p>
                                                            </section>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <img
                                                    src="/img/icon/payment/registration.png"
                                                    alt='+'
                                                />
                                                <font>
                                                    카드 또는 계좌 등록하기
                                                </font>
                                            </>
                                    }
                                </div>
                        }

                        {
                            availPayment
                                ?
                                <button
                                    className='py-product-payment-info-del-btn cursor'
                                    onClick={() => {
                                        const obj = {
                                            TEXT:
                                                <>
                                                    <div>결제수단을 재등록하실 경우</div>
                                                    <div>현재 사용하던 결제수단은 삭제됩니다.</div>
                                                    <div>새로 등록하시겠습니까?</div>
                                                </>,
                                            submitEventHandler: () => {
                                                // deletePayment();
                                                props.setType("ADD_PAYMENT");
                                                props.setIsRender(true);
                                                dispatch(closeAlert());
                                            }
                                        };
                                        dispatch(openConfirm(obj));
                                        // deletePayment()
                                    }}
                                >
                                    다른 결제 수단 등록하기
                                </button>
                                :
                                <></>
                        }

                        <section className='py-product-payment-info-comment'>
                            <div>카드 or 계좌를 등록하여 정기결제를 진행하세요.</div>
                            <div>결제 수단은 1개만 등록 가능합니다.</div>
                        </section>

                        {/* <font className='py-product-payment-info-terms cursor'>
                                        요금약관보기
                                    </font> */}
                    </div>
                </div>

                <button
                    className={
                        disabled
                            ? 'py-product-payment-info-btn'
                            : 'py-product-payment-info-btn cursor'
                    }
                    disabled={disabled || payLoading}
                    onClick={() => {
                        if (!payLoading) {
                            setRecurringPayment();
                        }
                    }}
                >
                    {
                        payLoading
                            ?
                            <CircularProgress size={16} />
                            :
                            "총" + amountFormat(props.selectedProduct.AMOUNT * props.selectedProduct.COUNT) + "결제하기"
                    }

                </button>
            </div>
        </div>
    )
}

export default PaymenyList
