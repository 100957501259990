import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Loading from "component/tag/loading/loading";
/**
 * Version : 1.0
 * 파일명 : ReserveTotal.js
 * 작성일자 : 2022-02-23
 * 작성자 : 강연승
 * 설명 : 대시보드 아이템 - 총 완료 회의 건 수
 * 수정일자 : 2022-03-07
 * 수정자 : 강연승
 * 수정내역 : 툴팁 추가
 */
function MeetingEnd() {
  /**
   * REDUCER
   */
  const REDUCER_STATE = useSelector(state => state.groupInfoReducer);
  /**
   * 언어변환
   */
  const { t } = useTranslation();
  /**
   * STATE
   */
  const [loading, setLoading] = useState(false);
  const [completeCnt, setCompelteCnt] = useState(0);
  const [incompleteCnt, setIncompleteCnt] = useState(0);
  const [isShowTooltip, setIsShowTooltip] = useState(false);
  const [tooltipType, setTooltipType] = useState("");
  /**
   * 총 예약 시간 조회
   */
  const getDurationTotal = useCallback(() => {
    setLoading(true);
    let param = {};
    if (REDUCER_STATE.GROUP_YN === "Y") {
      param = {
        GROUP_YN: REDUCER_STATE.GROUP_YN,
        GROUP_NUM: REDUCER_STATE.GROUP_NUM,
      };
    } else if (REDUCER_STATE.GROUP_YN === "N") {
      param = {
        GROUP_YN: REDUCER_STATE.GROUP_YN,
      };
    }
    axios
      .get("/schedule/dashboard/meetingCompleteCnt", {
        params: param,
      })
      .then(res => {
        if (res.status === 200) {
          setCompelteCnt(res.data.COMPLETE_CNT);
          setIncompleteCnt(res.data.INCOMPLETE_CNT);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [REDUCER_STATE.GROUP_NUM, REDUCER_STATE.GROUP_YN]);
  /**
   * useEffect
   */
  useEffect(() => {
    getDurationTotal();
  }, [getDurationTotal]);
  /**
   * 툴팁 설정
   */
  const onMouseTooltip = (overYN, type) => {
    setTooltipType(type);
    if (overYN === "over") {
      setIsShowTooltip(true);
    } else if (overYN === "out") {
      setIsShowTooltip(false);
    }
  };
  return (
    <div className="dashboardMeetingItem">
      <div style={{ height: "19px" }}>
        <img src="/img/icon/dashboard/complete.png" className="dashboardIcon" />
        <font className="title">
          {t("group.dashboard.txt.endTotal." + REDUCER_STATE.GROUP_CATEGORY)}
        </font>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div
          style={{
            display: "flex",
            marginTop: "21px",
            height: "22px",
            justifyContent: "center",
          }}
        >
          <div>
            <div
              onMouseOver={() => onMouseTooltip("over", "COMPLETE")}
              onMouseOut={() => onMouseTooltip("out", "COMPLETE")}
            >
              <div className="meetingEndBox" />
              <div className="meetingEnd">{completeCnt}</div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                className="tooltip"
                style={{
                  display:
                    isShowTooltip && tooltipType === "COMPLETE"
                      ? "block"
                      : "none",
                }}
              >
                <div
                  style={{
                    font: "normal normal bold 12px/18px Spoqa Han Sans Neo",
                    color: "#FFFFFF",
                  }}
                >
                  <div>
                    {t(
                      "group.dashboard.txt.endTotal.tooltip.complete." +
                        REDUCER_STATE.GROUP_CATEGORY
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="meetingEndBorder">
            <div
              onMouseOver={() => onMouseTooltip("over", "INCOMPLETE")}
              onMouseOut={() => onMouseTooltip("out", "INCOMPLETE")}
            >
              <div className="meetingNotOverBox" />
              <div className="meetingNotOver">{incompleteCnt}</div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                className="tooltip"
                style={{
                  display:
                    isShowTooltip && tooltipType === "INCOMPLETE"
                      ? "block"
                      : "none",
                }}
              >
                <div
                  style={{
                    font: "normal normal bold 12px/18px Spoqa Han Sans Neo",
                    color: "#FFFFFF",
                  }}
                >
                  <div>
                    {t(
                      "group.dashboard.txt.endTotal.tooltip.incomplete." +
                        REDUCER_STATE.GROUP_CATEGORY
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MeetingEnd;
