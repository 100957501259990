import React, { useState, useEffect, useRef, useCallback } from "react";
import { Avatar } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { openAlert, closeAlert, openConfirm } from "actions/index";
import { useDispatch, useSelector } from "react-redux";
/**
 * Version : 1.0
 * 파일명 : groupMemberList.js
 * 작성일자 : 2022-01-21
 * 작성자 : 강연승
 * 설명 : 그룹 멤버 리스트 아이템
 * 수정일자 : 2022-05-10
 * 수정자 : 권도훈
 * 수정내역 : 그룹 초대 취소 수정
 */
function MeetingHostListItem(props) {
  /**
   * REDUX
   */
  const GROUP_STATE = useSelector(state => state.groupInfoReducer);
  /**
   * 언어변환
   */
  const { t } = useTranslation();
  /**
   * dispatch
   */
  const dispatch = useDispatch();
  /**
   * 닉네임 변경 설정
   */
  const [isEditNikckNM, setIsEditNikckNM] = useState(false);
  const nameRef = useRef();

  /**
   * 이름 수정 취소
   */
  const cancelEditNickNm = useCallback(() => {
    if (isEditNikckNM) {
      setIsEditNikckNM(false);
    }
  }, [isEditNikckNM]);

  /**
   * 닉네임 저장
   */
  const saveNickNameEvent = (e, member) => {
    if (e.key === "Enter") {
      if (e.target.value.length === 0) {
        alert(t("group.profile.meetingHostSetting.emptyNickName"));
        return;
      }
      if (member.NICK_NM !== nameRef.current.value) {
        const params = {
          NICK_NM: nameRef.current.value,
          MEMBER_NUM: member.MEMBER_NUM,
          USE_YN: "Y",
        };
        props.saveNickNameEvent(params);
      }
      setIsEditNikckNM(false);
    }
  };

  /**
   * 이름 수정 취소 이벤트 추가
   */
  useEffect(() => {
    if (isEditNikckNM) {
      nameRef.current.focus();
      window.addEventListener("click", cancelEditNickNm);
      return () => window.removeEventListener("click", cancelEditNickNm);
    }
  }, [cancelEditNickNm, isEditNikckNM]);

  const deleteMemberList = (memberNum, memberID, requestType) => {
    const config = {
      params: {
        MEMBER_ID: memberID,
        MEMBER_NUM: memberNum,
        REQUEST_TYPE: requestType,
        GROUP_NM: GROUP_STATE.GROUP_NM
      },
    };

    axios
      .delete("/group/member/invite", config)
      .then(res => {
        if (res.status === 200) {
          props.getMeetingHostTotal();
          props.getMeetingHostList();
        }
      })
      .catch(() => {
        const obj = {
          TEXT: "삭제를 실패했습니다.",
          submitEventHandler: () => dispatch(closeAlert()),
        };
        dispatch(openAlert(obj));
      });
  };

  return (
    <tr className="group_profile_tbody text-center">
      <td />
      <td>
        <Avatar style={{ margin: "auto" }}>
          {props.data.USER_NM.substr(0, 1)}
          {/* {props.data.NICK_NM === ""
            ? props.data.USER_NM.substr(0, 1)
            : props.data.NICK_NM.substr(0, 1)} */}
        </Avatar>
      </td>
      <td />
      <td className="text-left">
        {props.data.USER_NM}
        {/* {isEditNikckNM ? (
          <input
            ref={nameRef}
            defaultValue={nameRef.current}
            type="text"
            onKeyDown={e => saveNickNameEvent(e, props.data)}
            onClick={e => e.stopPropagation()}
          />
        ) : (
          <div>
            <div style={{ display: "inline" }}>
              {props.data.NICK_NM === ""
                ? props.data.USER_NM
                : props.data.NICK_NM}
            </div>
            <img
              className="cursor"
              style={{
                verticalAlign: "text-top",
                marginLeft: "4px",
              }}
              src="/img/icon/profile/name_edit.png"
              alt="editName"
              onClick={() => {
                if (props.data.NICK_NM === "") {
                  nameRef.current = props.data.USER_NM;
                } else if (props.data.NICK_NM !== "") {
                  nameRef.current = props.data.NICK_NM;
                }
                setIsEditNikckNM(true);
              }}
            />
          </div>
        )} */}
      </td>
      <td />
      <td className="text-left">{props.data.USER_ID}</td>
      <td />
      <td>
        <img
          className={props.data.STATUS === "MEMBER" ? "cursor" : ""}
          src={
            props.data.PLAY_YN === "Y"
              ? "/img/icon/profile/streaming_on_" +
              GROUP_STATE.GROUP_COLOR +
              ".png"
              : "/img/icon/profile/streaming_off_" +
              GROUP_STATE.GROUP_COLOR +
              ".png"
          }
          alt="streaming"
          // style={{ marginRight: "32px" }}
          onClick={() => {
            if (props.data.STATUS === "MEMBER") {
              props.editAuth("play", props.data.AUTH_NUM, props.data.PLAY_YN);
            }
          }}
        />
      </td>
      <td />
      <td>
        <img
          className={props.data.STATUS === "MEMBER" ? "cursor" : ""}
          src={
            props.data.DOWNLOAD_YN === "Y"
              ? "/img/icon/profile/download_on_" +
              GROUP_STATE.GROUP_COLOR +
              ".png"
              : "/img/icon/profile/download_off_" +
              GROUP_STATE.GROUP_COLOR +
              ".png"
          }
          alt="download"
          onClick={() => {
            if (props.data.STATUS === "MEMBER") {
              props.editAuth(
                "download",
                props.data.AUTH_NUM,
                props.data.DOWNLOAD_YN
              );
            }
          }}
        />
      </td>
      <td />
      <td>
        <img
          className={props.data.STATUS === "MEMBER" ? "cursor" : ""}
          src={
            props.data.REC_YN === "Y"
              ? "/img/icon/profile/record_on_" +
              GROUP_STATE.GROUP_COLOR +
              ".png"
              : "/img/icon/profile/record_off_" +
              GROUP_STATE.GROUP_COLOR +
              ".png"
          }
          alt="record"
          // style={{ marginRight: "32px" }}
          onClick={() => {
            if (props.data.STATUS === "MEMBER") {
              props.editAuth("rec", props.data.AUTH_NUM, props.data.REC_YN);
            }
          }}
        />
      </td>
      <td />
      <td
        className={
          props.data.STATUS === "MEMBER"
            ? "group_profile_delete_member cursor"
            : "group_profile_delete_member"
        }
        onClick={() => {
          if (props.data.STATUS === "MEMBER") {
            props.openPopup(props.data);
            props.getMeetingHostTotal();
            props.getMeetingHostList();
          }
        }}
      >
        {props.data.STATUS === "MEMBER" ? (
          t("common.btn.delete")
        ) : props.data.STATUS === "INVITE" ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <div>초대됨</div>

            <div
              onClick={() => {
                const obj = {
                  TEXT:
                    <>
                      <div>해당 사용자의</div>
                      <div>초대를 취소하시겠습니까?</div>
                    </>,
                  submitEventHandler: () => {
                    deleteMemberList(props.data.MEMBER_NUM, props.data.USER_ID, "CANCLE");
                    dispatch(closeAlert());
                  },
                };
                dispatch(openConfirm(obj));
              }}
              className="cursor"
              style={{
                marginLeft: "8px",
              }}
            >
              X
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <div>거절됨</div>

            <div
              onClick={() => {
                const obj = {
                  TEXT:
                    <>
                      <div>해당 사용자를</div>
                      <div>목록에서 삭제하시겠습니까?</div>
                    </>,
                  submitEventHandler: () => {
                    deleteMemberList(props.data.MEMBER_NUM, props.data.USER_ID, "DELETE");
                    dispatch(closeAlert());
                  },
                };
                dispatch(openConfirm(obj));
              }}
              className="cursor"
              style={{
                marginLeft: "8px",
              }}
            >
              X
            </div>
          </div>
        )}
      </td>
      <td />
    </tr>
  );
}

export default MeetingHostListItem;
