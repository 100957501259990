import React from "react";
import Avatar from "@material-ui/core/Avatar";
import { useSelector } from "react-redux";
/**
 * Version : 1.0
 * 파일명 :
 * 작성일자 : 2021-10-28
 * 작성자 : 강연승
 * 설명 :
 * 수정일자 : 2022-05-04
 * 수정자 : 강연승
 * 수정내역 : 그룹 컬러 수정
 * props :
 * 전달받은값 - 설명
 */
function GroupProfileBtn() {
  /**
   * 글로벌 스테이트
   */
  const groupInfoState = useSelector(state => state.groupInfoReducer);
  const groupColor = color => {
    switch (color) {
      case "red":
        return "#791A2F";
      case "orange":
        return "#732C0E";
      case "yellow":
        return "#725314";
      case "bean":
        return "#35450F";
      case "green":
        return "#104920";
      case "mint":
        return "#10483B";
      case "pine":
        return "#134457";
      case "pink":
        return "#661B7A";
      case "purple":
        return "#46239F";
      case "black":
        return "#000000";
      default:
        return "#2B4AC4";
    }
  };
  return (
    <>
      {groupInfoState.GROUP_IMG !== undefined &&
      groupInfoState.GROUP_IMG !== null &&
      groupInfoState.GROUP_IMG !== "" ? (
        <Avatar
          alt="G"
          style={{ float: "left" }}
          src={groupInfoState.GROUP_IMG}
        ></Avatar>
      ) : (
        <Avatar
          alt="G"
          style={{ float: "left", fontFamily: "Spoqa Han Sans Neo" }}
        >
          {groupInfoState.GROUP_NM.substr(0, 1)}
        </Avatar>
      )}
      <div
        style={{
          font: "normal normal bold 16px/21px Spoqa Han Sans Neo",
          float: "left",
          color: groupColor(groupInfoState.GROUP_COLOR),
          margin: "8px 15px",
        }}
      >
        {groupInfoState.GROUP_NM}
      </div>
    </>
  );
}

export default GroupProfileBtn;
