import React, { useState } from "react";
import ReactModal from "react-modal";
/**
 * Version : 1.0
 * 파일명 : firstLoginPopup.js
 * 작성일자 : 2021-11-30
 * 작성자 : 권도훈
 * 설명 : 첫 로그인 팝업
 * 수정일자 : 2022-06-08
 * 수정자 : 권도훈
 * 수정내역 : 첫 로그인 처리 수정
*/
function FirstLoginPopup(props) {
    const [isPopup, setIsPopup] = useState(true);
    /**
     * body
     */
    ReactModal.setAppElement("body");
    /**
     * 팝업 닫기
     */
    const closePopup = () => {
        setIsPopup(false);
        props.openCoachMark();
    }
    /**
     * 태그
     */
    return (
        <ReactModal
            isOpen={isPopup} //모달 Open(Boolean)
            className="firstLoginAlert" //모달 ClassName
            overlayClassName="AlertOverlay" //모달오버레이 ClassName
            animationType="fade"
            transparent={true}
        >
            <div>
                <div className="firstLogin_closeBtn">
                    <img
                        className="cursor"
                        src="/img/icon/landing/close.png"
                        alt="X"
                        onClick={() => closePopup()}
                    />
                </div>

                <div className="firstLogin_content">
                    <div className="firstLogin_title">
                        무료 체험이 시작되었습니다!
                    </div>

                    <img className="firstLogin_mainIMG" src="/img/icon/landing/notice_coupon.png" alt="ERROR" />

                    <div className="firstLogin_coment_main">
                        1개의 예약권이 배정되었습니다.
                    </div>

                    <div className="firstLogin_coment_sub">
                        <div>
                            예약권으로 회의 개설 및 예약을 할 수 있습니다.
                        </div>
                        <div>
                            그룹에도 할당하여 사용해보세요!
                        </div>
                        <div>
                            기한 확인은 마이 프로필에서 확인할 수 있습니다.
                        </div>
                    </div>

                    <div className="firstLogin_coment_sub_red">
                        <div>
                            *최대 30일 이용가능
                        </div>
                        <div>
                            *일일 최대 예약 가능 시간 3시간
                        </div>
                        <div>
                            *모든 기능 사용 가능 (녹화 제외)
                        </div>
                    </div>

                    <div className="firstLogin_btn_area">
                        <button
                            className="cursor"
                            onClick={() => closePopup()}
                        >
                            OK
                        </button>
                    </div>
                </div>
            </div>
        </ReactModal>
    )
}

export default FirstLoginPopup