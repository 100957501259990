/**
 * Version : 1.0
 * 작성일자 : 2022-03-04
 * 작성자 : 권도훈
 * 설명 : 통계 정보
*/
const initalState = {
    type: "",
    loading: false,
    data: [],
    isDetail: false,
    detail_data: {}
}

const statisticsInfo = (state = initalState, action) => {
    switch (action.type) {
        case "statisticsInfo/LOADING":
            return {
                ...state,
                loading: action.loading
            };
        case "statisticsInfo/TOGGLE":
            return {
                ...state,
                type: action.viewType
            };
        case "statisticsInfo/DATA":
            return {
                ...state,
                data: action.data
            };
        case "statisticsInfo/DETAIL":
            return {
                ...state,
                isDetail: true,
                detail_data: action.detail_data
            };
        case "statisticsInfo/DETAIL_CLOSE":
            return {
                ...state,
                isDetail: false,
                detail_data: {}
            }
        default:
            return state;
    }
}

export default statisticsInfo;