import React, { useRef, useState, useEffect, useCallback } from "react";
import { useDragAndDrop } from "component/tag/dnd/useDragAndDrop";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { updateGroupInfo } from "actions/index";
import axios from "axios";
import Loading from "component/tag/loading/newLoading";
/**
 * Version : 1.0
 * 파일명 : groupItem.js
 * 작성일자 : 2021-10-05
 * 작성자 : 강연승
 * 설명 : 그룹 리스트 항목
 * 수정일자 : 2022-04-29
 * 수정자 : 권도훈
 * 수정내역 : 미팅호스트 목록 수정
 */
function GroupItem(props) {
  /**
   * 글로벌 스테이트
   */
  const dispatch = useDispatch();
  const history = useHistory();
  /**
   * STATE
   */
  const [infoLoading, setInfoLoading] = useState(false);
  /* 그룹 순서 드래그&드롭 정렬 */
  const createDragHoverCallback = (ref, currentItem, onDrop) => {
    return (otherItem, monitor) => {
      const dragIndex = otherItem.ORDER_NUM;
      const hoverIndex = currentItem.ORDER_NUM;

      if (dragIndex === hoverIndex) {
        return;
      }
      if (currentItem.ownerYN !== otherItem.ownerYN) {
        return;
      }
      if (currentItem.FAVORITE_YN !== otherItem.FAVORITE_YN) {
        return;
      }
      if (currentItem.USE_YN !== otherItem.USE_YN) {
        return;
      }

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      const hoverClientX = clientOffset.x - hoverBoundingRect.right;

      if (
        dragIndex < hoverIndex &&
        hoverClientY < hoverMiddleY &&
        hoverClientX < hoverMiddleX
      ) {
        return;
      }

      if (
        dragIndex > hoverIndex &&
        hoverClientY > hoverMiddleY &&
        hoverClientX > hoverMiddleX
      ) {
        return;
      }

      onDrop(currentItem.ownerYN, otherItem, currentItem);

      otherItem.ORDER_NUM = currentItem.ORDER_NUM;
    };
  };

  const ref = useRef(null);
  const { isDragging } = useDragAndDrop(ref, {
    ...props.groupInfo,
    hover: createDragHoverCallback(ref, props.groupInfo, props.onDrop),
  });
  const opacity = isDragging ? 0 : 1;

  /**
   * 닉네임 변경 설정
   */
  const [isEditGroupNM, setIsEditGroupNM] = useState(false);
  const nameRef = useRef();
  const explnRef = useRef();

  /**
   * 이름 수정 취소
   */
  const cancelEditGroupNm = useCallback(() => {
    if (isEditGroupNM) {
      setIsEditGroupNM(false);
    }
  }, [isEditGroupNM]);

  /**
   * 닉네임 저장
   */
  const saveGroupNameEvent = (e, group) => {
    if (e.key === "Enter") {
      if (nameRef.current.value.length === 0) {
        alert("그룹명이 입력되지 않았습니다. 1~9자리 사이로 입력해주세요.");
        return;
      }
      if (nameRef.current.value.length > 9) {
        alert("그룹명이 9자리 이상입니다. 1~9자리 사이로 입력해주세요.");
        return;
      }
      if (
        group.GROUP_NM !== nameRef.current.value ||
        group.GROUP_EXPLN !== explnRef.current.value
      ) {
        const params = {
          GROUP_NM: nameRef.current.value,
          GROUP_EXPLN: explnRef.current.value,
          GROUP_NUM: group.GROUP_NUM,
        };
        props.saveGroupNameEvent(params);
      }
      setIsEditGroupNM(false);
    }
  };
  /**
   * 이름 수정 취소 이벤트 추가
   */
  useEffect(() => {
    if (isEditGroupNM) {
      nameRef.current.focus();
      window.addEventListener("click", cancelEditGroupNm);
      return () => window.removeEventListener("click", cancelEditGroupNm);
    }
  }, [cancelEditGroupNm, isEditGroupNM]);

  return (
    <>
      <div
        style={{
          display: infoLoading ? "block" : "none",
          zIndex: "997",
          position: "absolute",
          width: "1010px",
          height: "867px",
        }}
      >
        <Loading />
      </div>
      <div
        ref={ref}
        className="cursor"
        style={{
          width: "264px",
          height: "144px",
          borderRadius: "16px",
          opacity,
        }}
      >
        {/* props.groupInfo.ownerYN === "N" && props.groupInfo.USE_YN === "N" */}
        {props.groupInfo.ownerYN === "N" && props.groupInfo.JOIN_DT === "" ? (
          <div onClick={() => props.inviteOpen(props.groupInfo)}>
            <div
              className={`groupBgColor_${props.groupInfo.ORIGIN_GROUP_COLOR} groupBorderColor_${props.groupInfo.ORIGIN_GROUP_COLOR}`}
              style={{
                height: "100px",
                padding: "24px 24px 18px 20px",
                color: "#ffffff",
                borderRadius: "16px",
              }}
            >
              <div
                style={{
                  font: "normal normal bold 20px/27px Spoqa Han Sans Neo",
                }}
              >
                {props.groupInfo.GROUP_NM}
              </div>
              <div
                className={`groupExplnColor_${props.groupInfo.ORIGIN_GROUP_COLOR}`}
              >
                {props.groupInfo.GROUP_EXPLN}
              </div>
              <div
                style={{
                  marginTop: "33px",
                  font: "normal normal bold 14px/18px Spoqa Han Sans Neo",
                  display: "inline-block",
                  float: "left",
                  color: "#ffffff",
                }}
              >
                <img
                  src="/img/icon/group/owner_icon.png"
                  alt="leader"
                  style={{ marginRight: "8px" }}
                />
                그룹장 {props.groupInfo.GTYPE}
              </div>
              <img
                src={
                  "/img/icon/group/new_" +
                  props.groupInfo.ORIGIN_GROUP_COLOR +
                  ".png"
                }
                alt="new"
                style={{ marginTop: "25px", float: "right" }}
              />
            </div>
            {/* <div
            className={`groupBgColor_${props.groupInfo.ORIGIN_GROUP_COLOR} groupBorderColor_${props.groupInfo.ORIGIN_GROUP_COLOR}_top_none`}
            style={{
              height: "18px",
              padding: "12px 20px 16px",
              borderRadius: "0 0 16px 16px",
              font: "normal normal bold 14px/18px Spoqa Han Sans Neo",
            }}
          >
          </div> */}
          </div>
        ) : (
          <div
            onClick={() => {
              if (!isEditGroupNM) {
                setInfoLoading(true);
                axios
                  .get("/group/selectGroupInfo", {
                    params: {
                      GROUP_NUM: props.groupInfo.GROUP_NUM,
                    },
                  })
                  .then(res => {
                    if (res.status === 200) {
                      const config = {
                        GROUP_NUM: res.data[0].GROUP_NUM,
                        GROUP_NM: res.data[0].GROUP_NM,
                        GROUP_COLOR: res.data[0].GROUP_INFO_COLOR,
                        AUTH_CD: res.data[0].AUTH_CD,
                        GROUP_CATEGORY: res.data[0].CTGRY_CD,
                        GROUP_IMG: res.data[0].GROUP_IMG,
                        GROUP_YN: "Y",
                        REC_YN: res.data[0].REC_YN,
                        DOWNLOAD_YN: res.data[0].DOWNLOAD_YN,
                        PLAY_YN: res.data[0].PLAY_YN,
                        HOST_CNT: res.data[0].HOST_CNT,
                        LICENSE_CNT: res.data[0].LICENSE_CNT,
                        D_DAY: res.data[0].D_DAY,
                        CREATE_DT: res.data[0].CREATE_DT,
                      };
                      dispatch(updateGroupInfo(config));
                      setInfoLoading(false);
                      history.push({
                        pathname:
                          "/app/group/groupMain/" + props.groupInfo.GROUP_NUM,
                        state: {
                          propsType: "group",
                          groupNum: props.groupInfo.GROUP_NUM,
                          groupColor: props.groupInfo.GROUP_COLOR,
                        },
                      });
                    }
                  })
                  .catch(error => {
                    setInfoLoading(false);
                    console.log(error);
                  });
              }
            }}
          >
            <div
              className={`groupBorderColor_${props.groupInfo.GROUP_COLOR}_bottom_none groupBgColor_${props.groupInfo.GROUP_COLOR}`}
              style={{
                height: "59px",
                padding: "16px 16px 21px 20px",
                color: "white",
                borderRadius: "16px 16px 0 0",
              }}
            >
              {/* <div style={{ float: "right" }}> */}
              <img
                src={
                  props.groupInfo.FAVORITE_YN === "Y"
                    ? "/img/icon/group/bookmark.png"
                    : `/img/icon/group/bookmark_${props.groupInfo.GROUP_COLOR}.png`
                }
                alt="bookmark"
                style={{ float: "right" }}
                onClick={e => (
                  e.stopPropagation(), props.onFavorite(props.groupInfo)
                )}
              />
              {/* </div> */}
              <div
                style={{
                  font: "normal normal bold 20px/27px Spoqa Han Sans Neo",
                  clear: "both",
                }}
              >
                {!isEditGroupNM ? (
                  <div
                    style={{
                      display: "inline-block",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      maxWidth: "180px",
                    }}
                  >
                    {props.groupInfo.GROUP_NM}
                  </div>
                ) : (
                  <input
                    type="text"
                    ref={nameRef}
                    defaultValue={nameRef.current}
                    maxLength="9"
                    style={{
                      height: "20px",
                      width: "180px",
                      font: "normal normal normal 20px/27px Spoqa Han Sans Neo",
                      outline: "none",
                      border: "none",
                      borderRadius: "4px",
                      background: "#e9ecef",
                      color: "#000000",
                      boxShadow: "0px 0px 1px white",
                    }}
                    onKeyDown={e => saveGroupNameEvent(e, props.groupInfo)}
                    onClick={e => e.stopPropagation()}
                  />
                )}
                {props.groupInfo.ownerYN === "Y" ? (
                  !isEditGroupNM ? (
                    <img
                      src={`/img/icon/group/edit_${props.groupInfo.GROUP_COLOR}.png`}
                      alt="edit"
                      style={{ marginLeft: "8px" }}
                      onClick={e => {
                        e.stopPropagation();
                        nameRef.current = props.groupInfo.GROUP_NM;
                        explnRef.current = props.groupInfo.GROUP_EXPLN;
                        setIsEditGroupNM(true);
                      }}
                    />
                  ) : null
                ) : null}
              </div>
              <div
                className={`groupExplnColor_${props.groupInfo.GROUP_COLOR}`}
                style={{
                  // marginTop: "8px",
                  font: "normal normal bold 12px/16px Spoqa Han Sans Neo",
                }}
              >
                {!isEditGroupNM ? (
                  <div
                    style={{
                      display: "inline-block",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      maxWidth: "180px",
                    }}
                  >
                    {props.groupInfo.GROUP_EXPLN}
                  </div>
                ) : (
                  <input
                    type="text"
                    ref={explnRef}
                    defaultValue={explnRef.current}
                    maxLength="255"
                    style={{
                      // width: "180px",
                      font: "normal normal normal 12px/16px Spoqa Han Sans Neo",
                      outline: "none",
                      border: "none",
                      borderRadius: "4px",
                      background: "#e9ecef",
                      color: "#000000",
                      boxShadow: "0px 0px 1px white",
                    }}
                    onKeyDown={e => saveGroupNameEvent(e, props.groupInfo)}
                    onClick={e => e.stopPropagation()}
                  />
                )}
              </div>
            </div>
            {props.groupInfo.ownerYN === "Y" ? (
              <div
                className={`groupBorderColor_${props.groupInfo.GROUP_COLOR}_top_none`}
                style={{
                  height: "18px",
                  padding: "12px 20px 16px",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "0 0 16px 16px",
                  font: "normal normal bold 14px/18px Spoqa Han Sans Neo",
                }}
              >
                <div
                  className={`groupMemberColor_${props.groupInfo.GROUP_COLOR}`}
                  style={{ display: "inline-block", float: "left" }}
                >
                  멤버 {props.groupInfo.MEMBER_CNT}
                </div>
                <div
                  className={`groupMeetingColor_${props.groupInfo.GROUP_COLOR}`}
                  style={{ display: "inline-block", float: "right" }}
                >
                  {props.groupInfo.GROUP_DT.replace(/T.*$/, "")}
                </div>
              </div>
            ) : (
              <div
                className={`groupBorderColor_${props.groupInfo.GROUP_COLOR}_top_none`}
                style={{
                  height: "18px",
                  padding: "12px 20px 16px",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "0 0 16px 16px",
                  font: "normal normal bold 14px/18px Spoqa Han Sans Neo",
                }}
              >
                <div
                  className={`groupLeaderColor_${props.groupInfo.GROUP_COLOR}`}
                  style={{ display: "inline-block", float: "left" }}
                >
                  <img
                    src={`/img/icon/group/leader_${props.groupInfo.GROUP_COLOR}.png`}
                    alt="leader"
                    style={{ marginRight: "8px" }}
                  />
                  {props.groupInfo.GTYPE}
                </div>
                <div
                  className={`groupMemberColor_${props.groupInfo.GROUP_COLOR}`}
                  style={{ display: "inline-block", float: "right" }}
                >
                  멤버 {props.groupInfo.MEMBER_CNT}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default GroupItem;
