import DataUseLogList from "component/tag/table/data/dataUseLogList";
import React, { useEffect, useState } from "react";
import DataStatistics from "component/tag/table/data/dataStatistics";
import { useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateGroupInfo } from "actions/index";
import axios from "axios";
import Loading from "component/tag/loading/loading";
/**
 * Version : 1.0
 * 파일명 : data.js
 * 작성일자 : 2021-12-28
 * 작성자 : 권도훈
 * 설명 : 그룹 데이터 페이지
 * 수정일자 : 2022-06-08
 * 수정자 : 강연승
 * 수정내역 : 초기 화면 로그 -> 통계 변경
 */
function Data() {
  /**
   * DISPATCH
   */
  const dispatch = useDispatch();
  /**
   * REDUCER
   */
  const REDUCER_STATE = useSelector(state => state.groupInfoReducer);
  /**
   * HISTORY
   */
  const history = useHistory();
  /**
   * VIEW TYPE
   */
  const [viewType, setViewType] = useState("STATISTICS");
  /**
   * Loading
   */
  const [loading, setLoading] = useState(false);
  /**
   * GROUP NUM
   */
  const tag = useRouteMatch("/app/group/data/:num");
  /**
   * 잘못된 요청 검사
   */
  useEffect(() => {
    if (REDUCER_STATE.GROUP_YN === "") {
      setLoading(true);

      const config = {
        params: {
          GROUP_NUM: tag.params.num,
        },
      };

      let obj = {
        GROUP_YN: "Y",
        GROUP_NUM: tag.params.num,
      };
      axios
        .get("/group/selectGroupInfo", config)
        .then(res => {
          if (res.status === 200) {
            obj = {
              ...obj,
              AUTH_CD: res.data[0].AUTH_CD,
              GROUP_NM: res.data[0].GROUP_NM,
              GROUP_COLOR: res.data[0].GROUP_INFO_COLOR,
              GROUP_CATEGORY: res.data[0].CTGRY_CD,
              GROUP_IMG: res.data[0].GROUP_IMG,
              REC_YN: res.data[0].REC_YN,
              DOWNLOAD_YN: res.data[0].DOWNLOAD_YN,
              PLAY_YN: res.data[0].PLAY_YN,
              HOST_CNT: res.data[0].HOST_CNT,
              LICENSE_CNT: res.data[0].LICENSE_CNT,
              D_DAY: res.data[0].D_DAY,
              CREATE_DT: res.data[0].CREATE_DT,
            };
            dispatch(updateGroupInfo(obj));
          } else {
            history.goBack();
          }
          setLoading(false);
        })
        .catch(() => {
          history.goBack();
        });
    }
  }, [REDUCER_STATE.GROUP_YN, dispatch, history, tag.params.num]);
  /**
   * 태그
   */
  return (
    <div
      style={{
        height: "902px",
        width: "1484px",
      }}
    >
      <div
        style={{
          margin: "26px 40px",
          height: "845px",
          width: "1404px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={
              REDUCER_STATE.GROUP_YN === "N" || REDUCER_STATE.GROUP_YN === ""
                ? "/img/icon/log/data.png"
                : "/img/icon/data/title_data_" +
                  REDUCER_STATE.GROUP_COLOR +
                  ".png"
            }
            alt="ICON"
          />
          <div
            className={"groupPebble_title_" + REDUCER_STATE.GROUP_COLOR}
            style={{
              font: "normal normal bold 28px/37px Spoqa Han Sans Neo",
              marginLeft: "16px",
            }}
          >
            데이터
          </div>
          <div
            style={{
              width: "80px",
              height: "36px",
              background: "#3B3F4C",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "10px",
              marginLeft: "16px",
            }}
            className="cursor"
            onClick={() =>
              setViewType(viewType === "LOG" ? "STATISTICS" : "LOG")
            }
          >
            <img src="/img/icon/log/change.png" alt="CHANGE" />
            <div
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                color: "#FFFFFF",
                marginLeft: "8px",
              }}
            >
              {viewType === "LOG" ? "통계" : "로그"}
            </div>
          </div>
        </div>
        {loading ? (
          <div
            style={{
              backgroundColor: "rgba(255,255,255,0.4)",
              height: "845px",
              marginTop: "23px",
              borderRadius: "12px",
            }}
          >
            {/* <div
                style={{
                  height: "100%"
                }}
              > */}
            <Loading />
            {/* </div> */}
          </div>
        ) : viewType === "LOG" ? (
          <DataUseLogList />
        ) : (
          <DataStatistics viewType={viewType} />
        )}
      </div>
    </div>
  );
}

export default Data;
