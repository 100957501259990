import Alert from 'component/tag/modal/alert';
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next';
/**
 * Version : 1.0
 * 파일명 : paging_8.js
 * 작성일자 : 2022-01-06
 * 작성자 : 권도훈
 * 설명 : 8row paging
 * 수정일자 : 2022-02-07
 * 수정자 : 권도훈
 * 수정내역 : 다국어 적용
*/

function Paging_8(props) {
    /**
     * TRANSLATION
     */
    const { t } = useTranslation();
    /**
    * 알림창 설정
    */
    const [alertProps, setAlertProps] = useState({
        text: "",
        isShow: false,
        cancelBtnDisplay: false,
        okBtnDisplay: true,
        okBtnEventHandler: function () { },
        cancleBtnEventHandler: function () {
            closeAlertEvent();
        },
    });
    /**
     * 알림창 닫기
     */
    const closeAlertEvent = useCallback(() => {
        setAlertProps({
            ...alertProps,
            isShow: false,
        });
    }, [alertProps]);
    /**
   * 페이징
   */
    const changePage = (type) => {
        if (type === "prev") {
            if (props.page.PAGE_COUNT - 8 < 0 || props.total <= 8) {
                setAlertProps({
                    ...alertProps,
                    isShow: true,
                    text: t("paging.first"),
                    cancelBtnDisplay: false,
                    okBtnEventHandler: function () {
                        closeAlertEvent();
                    },
                });
                return;
            } else if (props.page.PAGE_COUNT >= 8) {
                let diff = props.page.PAGE_COUNT - 8;

                if (diff < 8) {
                    props.setPage({
                        ...props.page,
                        PAGE_COUNT: 0,
                    });
                } else {
                    props.setPage({
                        ...props.page,
                        PAGE_COUNT: props.page.PAGE_COUNT - 8,
                    });
                }
            }
        } else if (type === "next") {
            if (props.page.PAGE_COUNT + 8 >= props.total || props.page.ROW_COUNT === props.total) {
                setAlertProps({
                    ...alertProps,
                    isShow: true,
                    text: t("paging.last"),
                    cancelBtnDisplay: false,
                    okBtnEventHandler: function () {
                        closeAlertEvent();
                    },
                });
                return;
            } else if (props.page.PAGE_COUNT < props.total) {
                let diff = props.page.PAGE_COUNT + 8;

                if (diff > props.total) {
                    props.setPage({
                        ...props.page,
                        PAGE_COUNT: props.total - (props.total - props.page.PAGE_COUNT),
                    });
                } else {
                    props.setPage({
                        ...props.page,
                        PAGE_COUNT: props.page.PAGE_COUNT + 8,
                    });
                }
            }
        }
    };
    /**
     * 첫 페이지로 이동
     */
    const goFirstPage = () => {
        if (props.page.PAGE_COUNT - 8 < 0 || props.total <= 8) {
            setAlertProps({
                ...alertProps,
                isShow: true,
                text: t("paging.first"),
                cancelBtnDisplay: false,
                okBtnEventHandler: function () {
                    closeAlertEvent();
                },
            });
            return;
        } else if (props.page.PAGE_COUNT >= 8) {
            props.setPage({
                ...props.page,
                PAGE_COUNT: 0,
            });
        }
    };
    /**
     * 마지막 페이지로 이동
     */
    const goLastPage = () => {
        if (props.page.PAGE_COUNT + 8 >= props.total || props.page.ROW_COUNT === props.total) {
            setAlertProps({
                ...alertProps,
                isShow: true,
                text: t("paging.last"),
                cancelBtnDisplay: false,
                okBtnEventHandler: function () {
                    closeAlertEvent();
                },
            });
            return;
        } else if (props.page.PAGE_COUNT < props.total) {

            const diff = props.total % 8;

            if (diff === 0) {
                props.setPage({
                    ...props.page,
                    PAGE_COUNT: props.total - 8,
                });
            } else {
                props.setPage({
                    ...props.page,
                    PAGE_COUNT: props.total - diff,
                });
            }
        }
    };
    return (
        <div className='data_content_paging'>
            <Alert alertProps={alertProps} />

            <div>
                {
                    props.total > 0
                        ?
                        props.total + "개 중 " + (props.page.PAGE_COUNT + 1) + "-" +
                        (
                            props.page.PAGE_COUNT + 8 >= props.total
                                ? props.total
                                : props.page.PAGE_COUNT + 8
                        )
                        : "0개"
                }
            </div>

            <img
                style={{
                    width: "24px",
                    height: "24px",
                    margin: '0 8px'
                }}
                className="cursor"
                src="/img/icon/address/first_page_btn.png"
                alt="<<"
                onClick={() => goFirstPage()}
            />
            <img
                style={{
                    width: "24px",
                    height: "24px",
                    margin: '0 8px'
                }}
                className="cursor"
                src="/img/icon/address/prev_page_btn.png"
                alt="<"
                onClick={() => changePage("prev")}
            />
            <img
                style={{
                    width: "24px",
                    height: "24px",
                    margin: '0 8px'
                }}
                className="cursor"
                src="/img/icon/address/next_page_btn.png"
                alt=">"
                onClick={() => changePage("next")}
            />
            <img
                style={{
                    width: "24px",
                    height: "24px",
                    margin: '0 8px'
                }}
                className="cursor"
                src="/img/icon/address/last_page_btn.png"
                alt=">>"
                onClick={() => goLastPage()}
            />
        </div>
    )
}

export default Paging_8