import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import NoLicensePopup from "component/tag/popup/license/noLicensePopup";
import { useSelector } from "react-redux";
/**
 * Version : 1.0
 * 파일명 : meetingNowBtn.js
 * 작성일자 : 2021-10-06
 * 작성자 : 강연승
 * 설명 : 즉시 미팅 버튼
 * 수정일자 : 2022-02-14
 * 수정자 : 강연승
 * 수정내역 : 그룹 색상별 이미지 변경
 */
function MeetingNowBtn() {
  /**
   * 글로벌 스테이트
   */
  const groupInfoState = useSelector(state => state.groupInfoReducer);
  const licenseCntState = useSelector(state => state.licenseCntInfoReducer);
  const [time, setTime] = useState("");
  const [ampm, setAmPm] = useState("");

  /**
   * 인터벌 설정
   */
  function useInterval(callback, delay) {
    const savedCallback = useRef();

    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }
  /**
   * 인터벌 사용 (현재 시간)
   */
  useInterval(() => {
    getTime();
  }, 1000);

  const getTime = () => {
    let date = new Date();
    let hour = date.getHours();
    let min =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    if (hour > 12) {
      hour = hour - 12;
      setAmPm("PM");
    } else if (hour === "12") {
      setAmPm("PM");
    } else if (hour < 12) {
      setAmPm("AM");
    }
    setTime(hour + " : " + min);
  };

  const history = useHistory();
  const [noLicensePopup, setNoLicensePopup] = useState(false);
  const openNoLicensePopup = () => {
    setNoLicensePopup(true);
  };
  const closeNoLicensePopup = () => {
    setNoLicensePopup(false);
  };

  const licenseCheck = () => {
    console.log(licenseCntState);
    console.log(
      "무료 회의이용권 : " + licenseCntState.FREE_CNT,
      "유료 회의이용권 : " + licenseCntState.PAY_CNT
    );
    if (licenseCntState.FREE_CNT > 0 || licenseCntState.PAY_CNT > 0) {
      return true;
    } else {
      return false;
    }
  };
  const clickMeetingNow = () => {
    if (!licenseCheck()) {
      openNoLicensePopup();
      return;
    } else if (licenseCheck()) {
      if (
        groupInfoState.GROUP_NUM !== undefined &&
        groupInfoState.GROUP_NUM !== "" &&
        groupInfoState.GROUP_NUM !== null
      ) {
        history.push({
          pathname: "/app/group/meetingNow",
        });
      } else if (
        groupInfoState.GROUP_NUM === undefined ||
        groupInfoState.GROUP_NUM === "" ||
        groupInfoState.GROUP_NUM === null
      ) {
        history.push({
          pathname: "/app/home/meetingNow",
        });
      }
    }
  };

  return (
    <>
      <NoLicensePopup
        open={noLicensePopup}
        close={closeNoLicensePopup}
        groupNum={groupInfoState.GROUP_NUM}
      />
      <button
        className={
          groupInfoState.GROUP_COLOR !== null &&
          groupInfoState.GROUP_COLOR !== undefined &&
          groupInfoState.GROUP_COLOR !== ""
            ? `meetingNowBtn meetingNowBtn_${groupInfoState.GROUP_COLOR}`
            : "meetingNowBtn"
        }
        onClick={clickMeetingNow}
      >
        <div
          style={{
            textAlign: "left",
            font: "normal normal normal 28px/37px Spoqa Han Sans Neo",
            color: "#ffffff",
            float: "left",
          }}
        >
          {time}
        </div>
        <div
          style={{
            textAlign: "left",
            font: "normal normal bold 14px/18px Spoqa Han Sans Neo",
            color: "#ffffff",
            float: "left",
            lineHeight: 2,
            marginLeft: "8px",
          }}
        >
          {ampm}
        </div>
        <img
          src={
            groupInfoState.GROUP_YN === "N"
              ? "/img/icon/schedule/meeting.png"
              : "/img/icon/schedule/meeting_" +
                groupInfoState.GROUP_COLOR +
                ".png"
          }
          alt="meetingNow"
          style={{
            width: "36px",
            float: "right",
          }}
        ></img>
        <div
          style={{
            clear: "both",
            textAlign: "left",
            font: "normal normal normal 28px/37px Spoqa Han Sans Neo",
            color: "#ffffff",
            float: "left",
          }}
        >
          Meeting Now
        </div>
      </button>
    </>
  );
}

export default MeetingNowBtn;
