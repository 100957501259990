import React, { useCallback, useEffect, useState } from 'react'
import { updateFreeLicenseInfo } from "actions/index";
import { useDispatch } from "react-redux";
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
/**
 * Version : 1.0
 * 파일명 : accountInfo.js
 * 작성일자 : 2021-11-09
 * 작성자 : 권도훈
 * 설명 : API 로그인 요청 계정 정보 출력 컴포넌트
 * 수정일자 : 2022-02-07
 * 수정자 : 권도훈
 * 수정내역 : 다국어 적용
 * 수정일자 : 2022-06-08
 * 수정자 : 권도훈
 * 수정내역 : 가입 시 처리 수정
*/
function AccountInfo(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    /**
     * translation
     */
    const { t } = useTranslation();
    /**
     * 출력 정보
     */
    const [info, setInfo] = useState({
        type: "",
        name: "",
        email: "",
    });
    const [init, setInit] = useState(true);
    /**
     * 메인페이지 이동 처리
     */
    const goHome = useCallback(() => {
        const config = {
            DISPLAY: true,
        };
        dispatch(updateFreeLicenseInfo(config));
        history.push("/app/home");
    }, [dispatch, history]);
    /**
     * 정보 설정
     */
    useEffect(() => {
        if (init) {
            setInfo({
                type: props.type,
                name: props.name,
                email: props.email,
            });

            if (props.type === "NEW") {
                props.insertEvent();
            }
            setInit(false);
        }
    }, [init, props, props.email, props.name, props.type]);
    /**
     * 태그
     */
    return (
        <div
            style={{
                textAlign: "center",
                width: "550px",
                margin: "150px auto 0px",
            }}
        >
            <img src="/img/login_black.png" alt="img" />

            <div
                style={{
                    fontSize: "32px",
                    fontWeight: "bold",
                    color: "#3E3E4F",
                }}
            >
                {
                    info.type === "NEW"
                        ?
                        <p>
                            {t("api.txt.signup.success")}
                        </p>
                        :
                        <p>
                            {t("api.txt.signup.fail")}
                        </p>
                }
            </div>

            <img src="/img/my_pebble.png" alt="img" />

            <div>
                <table
                    style={{
                        width: "550px",
                        fontSize: "16px",
                        fontWeight: "bold",
                        marginTop: "30px",
                    }}
                >
                    <colgroup>
                        <col width="25%" />
                        <col width="75%" />
                    </colgroup>

                    <tbody>
                        <tr>
                            <td
                                style={{
                                    float: "right"
                                }}
                            >
                                이름
                            </td>
                            <td>{info.name}</td>
                        </tr>

                        <tr>
                            <td
                                style={{
                                    float: "right"
                                }}
                            >
                                이메일
                            </td>
                            <td>{info.email}</td>
                        </tr>
                    </tbody>
                </table>

                <button
                    className={
                        props.loading
                            ? "account-info-success-btn drag-none"
                            : "account-info-success-btn cursor"
                    }
                    onClick={() => {
                        if (info.type === "NEW") {
                            goHome();
                        } else {
                            props.btnEvent();
                        }
                    }}
                    disabled={props.loading}
                >
                    {
                        props.loading
                            ? <CircularProgress size={16} />
                            : t("common.btn.ok")
                    }
                </button>
            </div>
        </div>
    )
}

export default AccountInfo
