import React from 'react'
/**
 * Version : 1.0
 * 파일명 : PaymentType.js
 * 작성일자 : 2022-04-06
 * 작성자 : 권도훈
 * 설명 : 결제수단 종류 선택
 */
function PaymentType(props) {
    /**
     * 종류 선택
     */
    const selectType = (type) => {
        props.setType(type);
        props.setIsRender(true);
    }

    return (
        <div className='py-product-add'>
            <div className='py-product-add-header'>
                <font>
                    결제 수단 등록
                </font>
            </div>

            <div className='py-product-add-container'>
                <div
                    className='py-product-add-item cursor'
                    onClick={() => selectType("PAYMENT_CARD")}
                >
                    <div className='py-product-add-item-inner'>
                        <img
                            src="/img/icon/payment/registration_card.png"
                            alt='CARD'
                        />

                        <section className='py-product-add-item-section'>
                            <div className='py-product-add-item-section-title'>
                                카드 등록
                            </div>
                            <div className='py-product-add-item-section-comment'>
                                신용, 체크, 법인 모두 가능
                            </div>
                        </section>
                    </div>
                </div>

                <div
                    className='py-product-add-item cursor'
                    onClick={() => selectType("PAYMENT_TRANSFER")}
                >
                    <div className='py-product-add-item-inner'>
                        <img
                            src="/img/icon/payment/registration_account.png"
                            alt='CARD'
                        />

                        <section className='py-product-add-item-section'>
                            <div className='py-product-add-item-section-title'>
                                계좌 등록
                            </div>
                            <div className='py-product-add-item-section-comment'>
                                계좌를 통해 자동이체합니다
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default PaymentType
