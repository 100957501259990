/**
 * Version : 1.0
 * 작성일자 : 2022-03-29
 * 작성자 : 정지윤
 * 설명 : 관리자 페이징
 */
const initalState = {
    ROW_COUNT: 7,
    PAGE_COUNT: 0,
    TOTAL: 0
};

const pagingsupInfo = (state = initalState, action) => {
    switch (action.type) {
        case "pagingsupInfo/UPDATE":
            return {
                ROW_COUNT: action.DATA.ROW_COUNT,
                PAGE_COUNT: action.DATA.PAGE_COUNT,
                TOTAL: action.DATA.TOTAL
            };
        default:
            return state;
    }
};

export default pagingsupInfo;
