import { useDrag, useDrop } from "react-dnd";
/**
 * Version : 1.0
 * 파일명 : useDragAndDrop.js
 * 작성일자 : 2021-11-10
 * 작성자 : 강연승
 * 설명 : 드래그&드롭 API
 * 수정일자 :  2021-11-10
 * 수정자 : 강연승
 * 수정내역 :
 * props :
 * ref - 드래그 중인 레퍼런스
 * groupinfo - 그룹 정보
 */
export function useDragAndDrop(ref, groupinfo) {
  const [{ isDragging }, drag] = useDrag({
    type: "GRID_ITEM",
    item: { ...groupinfo },
    // item: { type: "GRID_ITEM", ...groupinfo },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: "GRID_ITEM",
    hover: groupinfo.hover,
  });

  drag(drop(ref));

  return {
    isDragging,
  };
}

export default { useDragAndDrop };
