/**
 * Import List
 */
import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { Cookies } from "react-cookie";
import AccountInfo from "./accountInfo";
import { useHistory } from "react-router";
import { updateFreeLicenseInfo } from "actions/index";
import { useDispatch } from "react-redux";
import { openAlert, closeAlert } from "actions/index";
import { useTranslation } from "react-i18next";
import DormantLoginPopUp from "component/tag/popup/my/home/dormantLoginPopUp";
/**
 * url 설정
 */
let front_url = "";

if (process.env.NODE_ENV === "development") {
  front_url =
    process.env.REACT_APP_FRONT_URL + process.env.REACT_APP_FRONT_PORT;
} else {
  front_url = process.env.REACT_APP_FRONT_URL;
}
/**
 * Version : 1.0
 * 파일명 : callBack_google.js
 * 작성일자 : 2021-09-30
 * 작성자 : 권도훈
 * 설명 : google 로그인 요청 콜백 페이지
 * 수정일자 : 2022-02-18
 * 수정자 : 강연승
 * 수정내역 : 그룹 초대 가입 추가
  * 수정일자 : 2022-05-25
 * 수정자 : 정지윤
 * 수정내역 : 휴면계정 로그인 추가
 * 수정일자 : 2022-06-08
 * 수정자 : 권도훈
 * 수정내역 : 가입 시 처리 수정
 */
function CallBack_google() {
  //cookie
  const domain = process.env.REACT_APP_COOKIE_DOMAIN;
  const typeNm = process.env.REACT_APP_COOKIE_REQUEST_TYPE;
  const refreshNm = process.env.REACT_APP_COOKIE_REFRESH;
  const authNm = process.env.REACT_APP_COOKIE_AUTH;
  const loginTypeNm = process.env.REACT_APP_COOKIE_TYPE;

  const dispatch = useDispatch();
  const history = useHistory();

  /**
   * 휴면 아이디
   */
  const [dormantID, setdormantID] = useState('');

  /**
   * translation
   */
  const { t } = useTranslation();
  /**
   * REQUEST TYPE
   */
  const [showInfo, setShowInfo] = useState(true);
  const [loading, setLoading] = useState(true);
  /**
   * 토큰 정보
   */
  const [tokenInfo, setTokenInfo] = useState({
    EM: "",
    NM: "",
    AT: "",
    EI: "",
    RT: "",
    REI: "",
    TY: "",
    GRANT: "",
  });
  /**
   * 메인페이지 이동 처리
   */
  const goHome = useCallback(() => {
    const config = {
      DISPLAY: true,
    };
    dispatch(updateFreeLicenseInfo(config));
    history.push("/app/home");
  }, [dispatch, history]);
  /**
   * 그룹 초대 코드
   */
  const [groupCode, setGroupCode] = useState("");
  /**
   * 요청 분류
   */
  useEffect(() => {
    const cookies = new Cookies();
    const requestCode = new URL(window.location.href).searchParams.get("code");
    const requestType = cookies.get(typeNm);
    if (new URL(window.location.href).searchParams.get("state")) {
      setGroupCode(new URL(window.location.href).searchParams.get("state"));
    }
    const config = {
      params: {
        Request_Code: requestCode,
        Request_Type: requestType,
        Request_RT:
          cookies.get(refreshNm) === undefined || cookies.get(loginTypeNm) !== "GOOGLE"
            ? ""
            : cookies.get(refreshNm),
        RedirectUrl: front_url,
      },
    };

    axios
      .get("/user/google", config)
      .then(res => {
        if (res.status === 200) {
          const result = res.data;

          if (result.Use_YN === "N") {

            setdormantID(result.User_ID);

            // opendormant();
            setShowModal(true);
          } else {

            setTokenInfo({
              EM: result.EM,
              NM: result.NM,
              AT: result.AT,
              EI: result.EI,
              RT: result.RT,
              REI: result.REI,
              TY: result.TY,
              GRANT: result.GRANT,
            });

            if (result.TY === "EXIST") {
              setShowInfo(false);

              const config = {
                params: {
                  EM: result.EM,
                  NM: result.NM,
                  AT: result.AT,
                  RT: result.GRANT === "NEW" ? result.RT : "",
                },
              };

              axios
                .put("/user/google", null, config)
                .then(res => {
                  if (res.status === 200) {
                    if (result.GRANT === "NEW") {
                      cookies.set(
                        refreshNm,
                        result.RT, {
                        path: "/",
                        secure: true,
                        sameSite: "none",
                        domain: domain
                      });
                    }

                    cookies.set(
                      authNm,
                      result.AT, {
                      path: "/",
                      secure: true,
                      sameSite: "none",
                      maxAge: result.EI,
                      domain: domain
                    });

                    cookies.set(
                      loginTypeNm,
                      "GOOGLE", {
                      path: "/",
                      secure: true,
                      sameSite: "none",
                      domain: domain
                    });

                    cookies.remove(
                      typeNm,
                      {
                        path: "/",
                        domain: domain
                      }
                    );

                    if (requestType === "DELETE") {
                      history.push({
                        pathname: "/delete",
                        state: {
                          NAME: result.NM,
                          EMAIL: result.EM,
                          TYPE: "GOOGLE",
                        },
                      });
                    } else {
                      goHome();
                    }
                  } else {
                    const obj = {
                      TEXT: t("api.msg.signin.fail.error"),
                      submitEventHandler: () => {
                        dispatch(closeAlert());
                        window.location.href = "/";
                      },
                    };
                    dispatch(openAlert(obj));
                  }
                })
                .catch(err => {
                  const obj = {
                    TEXT: t("api.msg.signin.fail.error"),
                    submitEventHandler: () => {
                      dispatch(closeAlert());
                      axios.post("/user/error", null, {
                        headers: { error_info: err },
                      });
                      window.location.href = "/";
                    },
                  };
                  dispatch(openAlert(obj));
                });
            }

            cookies.remove(
              typeNm,
              {
                path: "/",
                domain: domain
              }
            );
          }
        }
      })
      .catch(err => {
        if (err.response.status === 403) {
          const obj = {
            TEXT: t("api.msg.signin.fail.pebble"),
            submitEventHandler: () => {
              dispatch(closeAlert());
              if (requestType === "DELETE") {
                history.push({
                  pathname: "/login",
                  state: {
                    tag: "block",
                    type: "delete",
                  },
                });
              } else {
                history.push({
                  pathname: "/login",
                  state: {
                    tag: "block",
                    type: "default",
                  },
                });
              }
            },
          };
          dispatch(openAlert(obj));
        } else if (err.response.status === 502) {
          const obj = {
            TEXT: t("api.msg.signin.fail.reject"),
            submitEventHandler: () => {
              dispatch(closeAlert());
              history.push({
                pathname: "/login",
                state: {
                  tag: "block",
                  type: "default",
                },
              });
            },
          };
          dispatch(openAlert(obj));
        } else if (err.response.status === 417) {
          const obj = {
            TEXT: t("api.msg.error"),
            submitEventHandler: () => {
              dispatch(closeAlert());
              history.push({
                pathname: "/login",
                state: {
                  tag: "block",
                  type: "default",
                },
              });
            },
          };
          dispatch(openAlert(obj));
        } else if (err.response.status === 400) {
          const obj = {
            TEXT: t("api.msg.signin.fail.omission"),
            submitEventHandler: () => {
              dispatch(closeAlert());
              history.push({
                pathname: "/login",
                state: {
                  tag: "block",
                  type: "default",
                },
              });
            },
          };
          dispatch(openAlert(obj));
        } else {
          history.push({
            pathname: "/login",
            state: {
              tag: "block",
              type: "default",
            },
          });
        }
      });
  }, [authNm, dispatch, domain, goHome, history, loginTypeNm, refreshNm, t, typeNm]);
  /**
   * 신규회원 등록 처리
   */
  const insertGoogleAccount = () => {
    const cookies = new Cookies();

    let config = {
      params: {
        EM: tokenInfo.EM,
        NM: tokenInfo.NM,
        AT: tokenInfo.AT,
        RT: tokenInfo.GRANT === "NEW" ? tokenInfo.RT : "",
      },
    };

    if (groupCode !== "") {
      config = {
        params: {
          ...config.params,
          groupCode: groupCode,
        },
      };
    }

    axios
      .post("/user/google", null, config)
      .then(res => {
        if (res.status === 200) {
          cookies.set(
            authNm,
            tokenInfo.AT, {
            path: "/",
            secure: true,
            sameSite: "none",
            maxAge: tokenInfo.EI,
            domain: domain
          });

          cookies.set(
            refreshNm,
            tokenInfo.RT, {
            path: "/",
            secure: true,
            sameSite: "none",
            domain: domain
          });

          cookies.set(
            loginTypeNm,
            "GOOGLE",
            {
              path: "/",
              secure: true,
              sameSite: "none",
              domain: domain
            }
          );

          cookies.remove(
            typeNm,
            {
              path: "/",
              domain: domain
            }
          );
          setLoading(false);

        } else {
          const obj = {
            TEXT: t("api.msg.signup.fail"),
            submitEventHandler: () => {
              dispatch(closeAlert());
              window.location.href = "/";
            },
          };
          dispatch(openAlert(obj));
        }
      })
      .catch(err => {
        const obj = {
          TEXT: t("api.msg.signup.fail"),
          submitEventHandler: () => {
            dispatch(closeAlert());
            axios.post("/user/error", null, { headers: { error_info: err } });
            window.location.href = "/";
          },
        };
        dispatch(openAlert(obj));
      });
  };
  /**
   * 기존 회원 로그인 처리
   */
  const updateGoogleAccount = () => {
    const cookies = new Cookies();

    const config = {
      params: {
        EM: tokenInfo.EM,
        NM: tokenInfo.NM,
        AT: tokenInfo.AT,
        RT: tokenInfo.GRANT === "NEW" ? tokenInfo.RT : "",
      },
    };

    axios
      .put("/user/google", null, config)
      .then(res => {
        if (res.status === 200) {
          if (tokenInfo.GRANT === "NEW") {
            cookies.set(
              refreshNm,
              tokenInfo.RT,
              {
                path: "/",
                secure: true,
                sameSite: "none",
                domain: domain
              }
            );
          }

          cookies.set(
            authNm,
            tokenInfo.AT,
            {
              path: "/",
              secure: true,
              sameSite: "none",
              maxAge: tokenInfo.EI,
              domain: domain
            }
          );

          cookies.set(
            loginTypeNm,
            "GOOGLE",
            {
              path: "/",
              secure: true,
              sameSite: "none",
              domain: domain
            }
          );

          cookies.remove(
            typeNm,
            {
              path: "/",
              domain: domain
            }
          );
        } else {
          const obj = {
            TEXT: t("api.msg.signin.fail.error"),
            submitEventHandler: () => {
              dispatch(closeAlert());
              window.location.href = "/";
            },
          };
          dispatch(openAlert(obj));
        }
      })
      .catch(err => {
        const obj = {
          TEXT: t("api.msg.signin.fail.error"),
          submitEventHandler: () => {
            dispatch(closeAlert());
            axios.post("/user/error", null, { headers: { error_info: err } });
            window.location.href = "/";
          },
        };
        dispatch(openAlert(obj));
      });
  };
  /**
 * 휴면창 열기
 */
  const [showModal, setShowModal] = useState(false);

  const openDModal = () => {
    setShowModal(true);
  }
  const closeDModal = () => {
    setShowModal(false);
  }

  const opendormant = () => {
    openDModal();
  }
  /**
   * 태그
   */
  return (


    <div>
      <DormantLoginPopUp
        open={showModal}
        close={closeDModal}
        ID={dormantID}
      />
      <div>
        <img
          src="/img/logo_main.png"
          alt="logo_main"
          style={{
            marginLeft: "116px",
            marginTop: "34px",
          }}
        />
      </div>

      {tokenInfo.NM === "" || !showInfo ? (
        <img
          className="block mx-auto mt-50"
          src="/img/pebble_loading.gif"
          alt="loading"
        />
      ) : (
        <AccountInfo
          type={tokenInfo.TY}
          name={tokenInfo.NM}
          email={tokenInfo.EM}
          insertEvent={insertGoogleAccount}
          btnEvent={updateGoogleAccount}
          loading={loading}
        />
      )}
    </div>
  );
}

export default CallBack_google;
