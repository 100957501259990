import axios from "axios";
/**
 * Version : 1.0
 * 파일명 : axiosApi.js
 * 작성일자 : 2022-04-22
 * 작성자 : 권도훈
 * 설명 : 공통 axios 처리
 */
function wrapPromise(promise) {
    let status = "pending";
    let result;
    let suspender = promise.then(
        r => {
            status = "success";
            result = r;
        },
        e => {
            status = "error";
            result = e;
        }
    );

    return {
        read() {
            if (status === "pending") {
                throw suspender;
            } else if (status === "error") {
                throw result;
            } else if (status === "success") {
                return result;
            }
        }
    };
}
/**
 * fetch
 */
export function fetchAxiosEvent(type, url, obj) {
    let result;

    switch (type) {
        case "GET":
            result = getEventHandler(url, obj);
            break;
        case "POST":
            result = postEventHandler(url, obj);
            break;
        case "PUT":
            result = putEventHandler(url, obj);
            break;
        case "DELETE":
            result = deleteEventHandler(url, obj);
            break;
        default:
            return;
    }

    return {
        data: wrapPromise(result)
    }
}
//GET
export function getEventHandler(url, obj) {
    return new Promise(resolve => {
        axios.get(url, obj.config)
            .then(res => {
                if (res.status === 200) {
                    resolve(res.data);
                }
            })
            .catch(err => {
                resolve(err.response);
            })
    })
}
//POST
export function postEventHandler(url, obj) {
    return new Promise(resolve => {
        axios.post(url, obj.data, obj.config)
            .then(res => {
                if (res.status === 200) {
                    resolve(res.data);
                }
            })
            .catch(err => {
                resolve(err.response);
            })
    })
}
//PUT
export function putEventHandler(url, obj) {
    return new Promise(resolve => {
        axios.put(url, obj.data, obj.config)
            .then(res => {
                if (res.status === 200) {
                    resolve(res.data);
                }
            })
            .catch(err => {
                resolve(err.response);
            })
    })
}
//DELETE
export function deleteEventHandler(url, obj) {
    return new Promise(resolve => {
        axios.delete(url, obj.config)
            .then(res => {
                if (res.status === 200) {
                    resolve(res.data);
                }
            })
            .catch(err => {
                resolve(err.response);
            })
    })
}