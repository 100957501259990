import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateGroupInfo } from "actions/index";
import RecordCardList from "component/tag/table/record/recordCardList";
import RecordList from "component/tag/table/record/recordList";
import { useHistory, useRouteMatch } from "react-router-dom";
import axios from "axios";
import Loading from "component/tag/loading/loading";
/**
 * Version : 1.0
 * 파일명 : Record.js
 * 작성일자 : 2021-10-05
 * 작성자 : 권도훈
 * 설명 : 개인 녹화파일
 * 수정일자 : 2022-04-22
 * 수정자 : 강연승
 * 수정내역 : 리덕스 수정
 */
function Record() {
  /**
   * DISPATCH
   */
  const dispatch = useDispatch();
  /**
   * REDUCER
   */
  const REDUCER_STATE = useSelector(state => state.groupInfoReducer);
  const RECORD_STATE = useSelector(state => state.recordInfoReducer);
  /**
   * HISTORY
   */
  const history = useHistory();
  /**
   * Loading
   */
  const [loading, setLoading] = useState(false);
  /**
   * GROUP NUM
   */
  const tag = useRouteMatch("/app/group/record/:num");
  /**
   * 잘못된 요청 검사
   */
  useEffect(() => {
    if (REDUCER_STATE.GROUP_YN === "") {
      setLoading(true);

      const config = {
        params: {
          GROUP_NUM: tag.params.num,
        },
      };

      let obj = {
        GROUP_YN: "Y",
        GROUP_NUM: tag.params.num,
      };

      axios
        .get("/group/selectGroupInfo", config)
        .then(res => {
          if (res.status === 200) {
            obj = {
              ...obj,
              AUTH_CD: res.data[0].AUTH_CD,
              GROUP_NM: res.data[0].GROUP_NM,
              GROUP_COLOR: res.data[0].GROUP_INFO_COLOR,
              GROUP_CATEGORY: res.data[0].CTGRY_CD,
              GROUP_IMG: res.data[0].GROUP_IMG,
              REC_YN: res.data[0].REC_YN,
              DOWNLOAD_YN: res.data[0].DOWNLOAD_YN,
              PLAY_YN: res.data[0].PLAY_YN,
              HOST_CNT: res.data[0].HOST_CNT,
              LICENSE_CNT: res.data[0].LICENSE_CNT,
              D_DAY: res.data[0].D_DAY,
              CREATE_DT: res.data[0].CREATE_DT,
            };
            dispatch(updateGroupInfo(obj));
          } else {
            history.goBack();
          }
          setLoading(false);
        })
        .catch(() => {
          history.goBack();
        });
    }
  }, [REDUCER_STATE.GROUP_YN, dispatch, history, tag.params.num]);
  /**
   * TAG
   */
  return (
    <div
      style={{
        height: "902px",
        width: "1484px",
      }}
    >
      {loading ? (
        <div
          style={{
            height: "100%",
          }}
        >
          <Loading />
        </div>
      ) : RECORD_STATE.VIEW_TYPE === "CARD" ? (
        <RecordCardList />
      ) : (
        <RecordList />
      )}
    </div>
  );
}

export default Record;
