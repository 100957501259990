import React, { useCallback, useEffect, useState } from "react";
import ReactModal from "react-modal";
import Loading from "component/tag/loading/loading";
import ProductList from "component/tag/div/common/payment/productList";
import PaymenList from "component/tag/div/common/payment/paymenyList";
import PaymentAuth from "component/tag/div/common/payment/paymentAuth";
import PaymentType from "component/tag/div/common/payment/paymentType";
import PaymentTransfer from "component/tag/div/common/payment/paymentTransfer";
import PaymentCard from "component/tag/div/common/payment/paymentCard";
/**
 * Version : 1.0
 * 파일명 : productPopup.js
 * 작성일자 : 2021-12-01
 * 작성자 : 권도훈
 * 설명 : 상품 결제 팝업
 * 수정일자 : 2022-04-19
 * 수정자 : 권도훈
 * 수정내역 : 인증 팝업 닫기 이벤트 수정
 */
function ProductPopup(props) {
    /**
     * default setting
     */
    const [init, setInit] = useState(true);
    const [type, setType] = useState("PRODUCT");
    const [isRender, setIsRender] = useState(false);
    const [render, setRender] = useState(<></>);
    const [loading, setLoading] = useState(false);
    // const [isClose, setIsClose] = useState(false);
    /**
     * product setting
     */
    const [selectedProduct, setSelectedProduct] = useState({});

    const popupClose = useCallback(() => {
        props.setIsShow(false);
        setInit(true);
    }, [props]);

    /**
     * 페이지 세팅
     */
    const setPage = useCallback(() => {
        switch (type) {
            case "PRODUCT":
                setRender(
                    <ProductList
                        setType={setType}
                        setIsRender={setIsRender}
                        selectedProduct={selectedProduct}
                        setSelectedProduct={setSelectedProduct}
                        setLoading={setLoading}
                    />
                );
                break;
            case "PAYMENT":
                setRender(
                    <PaymenList
                        setType={setType}
                        setIsRender={setIsRender}
                        selectedProduct={selectedProduct}
                        setIsShow={props.setIsShow}
                        setInit={setInit}
                        isRefresh={props.isRefresh}
                    />
                );
                break;
            case "AUTH":
                setRender(
                    <PaymentAuth
                        setType={setType}
                        setIsRender={setIsRender}
                        initType={props.type}
                        popupClose={popupClose}
                    />
                );
                break;
            case "ADD_PAYMENT":
                setRender(
                    <PaymentType
                        setType={setType}
                        setIsRender={setIsRender}
                    />
                );
                break;
            case "PAYMENT_CARD":
                setRender(
                    <PaymentCard
                        initType={props.type}
                        setType={setType}
                        setIsRender={setIsRender}
                        isRefresh={props.isRefresh}
                        popupClose={popupClose}
                    />
                );
                break;
            case "PAYMENT_TRANSFER":
                setRender(
                    <PaymentTransfer
                        initType={props.type}
                        setType={setType}
                        setIsRender={setIsRender}
                        isRefresh={props.isRefresh}
                        popupClose={popupClose}
                    />
                );
                break;
            default:
                setRender(<></>);
                break;
        }
    }, [popupClose, props.isRefresh, props.setIsShow, props.type, selectedProduct, type]);
    /**
     * 초기화
     */
    useEffect(() => {
        if (init && props.isShow) {
            setInit(false);
            setType(props.type);
            setSelectedProduct({});
            if (!isRender) {
                setIsRender(true);
            }
        }
    }, [init, isRender, props.isShow, props.type]);
    /**
     * 렌더링
     */
    useEffect(() => {
        if (isRender && props.isShow) {
            setPage();
            setIsRender(false);
        }
    }, [isRender, props.isShow, setPage]);

    ReactModal.setAppElement("body");

    return (
        <ReactModal
            isOpen={props.isShow}
            className="py-product-popup"
            overlayClassName="NewAlertOverlay"
            animationType="fade"
            transparent={true}
            closeTimeoutMS={200}
        >
            <div className='py-product-popup-inner'>
                <nav className='py-product-popup-header'>
                    <font>
                        회의이용권 구독하기
                    </font>
                    <img
                        className='cursor'
                        src="/img/icon/payment/close.png"
                        alt='X'
                        onClick={() => popupClose()}
                    />
                </nav>

                <div
                    className={
                        loading
                            ? 'py-product-popup-container-loading'
                            : 'py-product-popup-container'
                    }
                >
                    <div
                        style={{
                            display:
                                loading
                                    ? "flex"
                                    : "none"
                        }}
                    >
                        <Loading />
                    </div>
                    <div
                        style={{
                            display:
                                loading
                                    ? "none"
                                    : "block"
                        }}
                    >
                        {render}
                    </div>
                </div>
            </div>
        </ReactModal>
    )
}

export default ProductPopup
