import React from 'react'
import ReactModal from 'react-modal';
/**
 * Version : 1.0
 * 파일명 : TosPopup.js
 * 작성일자 : 2022-01-26
 * 작성자 : 권도훈
 * 설명 : 이용약관 팝업
 * 수정일자 : 2022-02-09
 * 수정자 : 권도훈
 * 수정내역 : UI 수정
*/
function TosPopup(props) {
    /**
     * body
     */
    ReactModal.setAppElement("body");
    /**
     * 팝업 닫기
     */
    const closePopup = () => {
        props.setIsShow(false);
    }

    return (
        <ReactModal
            isOpen={props.isShow} //모달 Open(Boolean)
            className="agreeListAlert" //모달 ClassName
            overlayClassName="AlertOverlay" //모달오버레이 ClassName
            animationType="fade"
            transparent={true}
            closeTimeoutMS={200}
        >
            <div
                style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "0 24px"
                }}
            >
                <div
                    style={{
                        color: "#2B4AC4",
                        fontSize: "20px",
                        fontWeight: "bold",
                        marginBottom: "24px",
                        width: "100%"
                    }}
                >
                    [필수] 이용약관
                </div>

                <section
                    className='scrollbar'
                    style={{
                        overflowY: "auto",
                        height: "370px"
                    }}
                >
                    <h3 id="-1-">제1조 (목 적)</h3>
                    <p>이 약관은 (주)리브테크놀로지가 운영하는 https://www.pebbling.co.kr(이하 “회사”라 한다)에서 제공하는 인터넷 관련 서비스(이하 "서비스"라 한다)를 이용함에 있어 회사와 사용자의 권리/의무 및 책임사항을 규정함을 목적으로 합니다.</p>
                    <div>
                        <h3 id="-2-">제2조 (용어정의)</h3>
                        <p>① “서비스”는 누구나 쉽고, 간단하게 사용할 수 있는 클라우드 화상회의 서비스 플랫폼  입니다.
                            ② “사용자”란 &quot;회사&quot;에 접속하여 이 약관에 따라 &quot;회사&quot;가 제공하는 서비스를 받는 정회원, 일반회원 및 비회원을 말합니다.
                            ③ “정회원” : 유료서비스 계약의 당사로 “회사”에 서비스 가입신청서를 제출하고, “회사”가 승인한 자로서 이용요금의 납입의무를 가진 법인이나 개인
                            ④ “일반회원” : “회사”가 운영하는 사이트의 이용약관에 동의하고, ‘이용신청 및 승낙’ 절차에 의하여 “회원” 등록된 자
                            ⑤ &quot;비회원&quot;이라 함은 회원에 가입하지 않고 “회사”가 제공하는 서비스를 이용하는 자를 말합니다.
                            ⑥ “비밀번호(패스워드)” : 회원이 부여 받은 아이디에 대하여 회원이 통신상에서 자신의 비밀 보호를 위하여 선정한 문자와 숫자, 특수문자의 조합
                            ⑦ “계약” : 서비스 이용과 관련하여 “회사”와 회원 간에 체결하는 계약
                            ⑧ “서비스 철회” : “회사” 또는 회원(회원 신청자 포함)이 서비스 개통 후 장래에 향해 서비스 계약을 해약하는 것
                            ⑨ “게시물” : 회원이 서비스를 이용함에 있어 단말기(PC, 휴대형 단말기, TV 등의 각종 유무선 장치를 포함) 또는 “회사” 홈페이지 등에 게시한 부호ㆍ문자ㆍ음성ㆍ음향ㆍ화상ㆍ동영상 등의 정보 형태의 글, 사진, 동영상 및 각종 파일과 링크 등
                            ⑩ “이용요금” : 사용자가 서비스를 이용하기 위해 회사에 지불하는 사용료</p>
                    </div>
                    <h3 id="-3-">제3조 (약관 등의 명시와 설명 및 개정)</h3>
                    <p>① “회사”는 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호, 전자우편주소, 사업자등록번호, 통신판매업신고번호, 개인정보관리책임자 등을 사용자가 쉽게 알 수 있도록 회사의 초기 서비스화면(전면)에 게시합니다. 다만, 약관의 내용은 사용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.
                        ② “회사”는 사용자가 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 환불조건 등과 같은 중요한 내용을 사용자가 이해할 수 있도록 별도의 연결 화면 또는 팝업화면 등을 제공하여 사용자의 확인을 구하여야 합니다.
                        ③ “회사”는 「온라인 디지털콘텐츠산업발전법」, 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의규제에 관한 법률」, 「소비자기본법 등 관련법」을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
                        ④ “회사”가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 회사의 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, 사용자에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우 “회사”는 개정 전 내용과 개정 후 내용을 명확하게 비교하여 사용자가 알기 쉽도록 표시합니다.
                        ⑤ “회사”가 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는 개정 전의 약관조항이 그대로 적용됩니다. 다만 이미 계약을 체결한 사용자가 개정약관 조항의 적용을 받기를 원하는 뜻을 제4항에 의한 개정약관의 공지기간 내에 &quot;회사&quot;에 송신하여 &quot;회사&quot;의 동의를 받은 경우 에는 개정약관 조항이 적용됩니다.
                        ⑥ 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래등에서의 소비자보호에관한법률, 약관의규제등에관한법률, 공정거래위원회가 정하는 전자상거래 등에서의 소비자보호지침 및 관계법령 또는 상관례에 따릅니다.
                        ⑦ 본 약관의 변경에 대해 동의하지 않는 회원은 서비스 이용을 중단하고 이용계약을 해지(회원탈퇴) 할 수 있습니다. 약관 시행일까지 회원탈퇴를 하지 않은 회원은 개정약관에 동의한 것으로 간주합니다.</p>
                    <div>
                        <h3 id="-4-">제4조 (서비스의 제공 및 변경)</h3>
                        <p>① “회사”는 다음과 같은 업무를 수행합니다. (컨텐츠 또는 서비스에 대한 정보 제공 / 기타 “회사”가 정하는 업무)
                            ② “회사”는 재화 또는 서비스의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 재화 또는 서비스의 내용을 변경할 수 있습니다. 이 경우에는 변경된 재화 또는 서비스의 내용 및 제공일자를 명시하여 현재의 재화 또는 서비스의 내용을 게시한 곳에 즉시 공지합니다.
                            ③ “회사”가 제공하기로 사용자와 계약을 체결한 서비스의 내용을 재화 등의 품절 또는 기술적 사양의 변경 등의 사유로 변경할 경우에는 그 사유를 사용자에게 통지 가능한 방법으로 즉시 통지합니다.
                            ④ 전항의 경우 “회사”는 이로 인하여 사용자가 입은 손해를 배상합니다. 다만, “회사”가 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.</p>
                    </div>
                    <h3 id="-5-">제5조 (서비스의 중단)</h3>
                    <p>① “회사”는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.
                        ② “회사”는 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 사용자 또는 제3자가 입은 손해에 대하여 배상합니다. 단, “회사”가 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.
                        ③ 사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 “회사”는 제8조에 정한 방법으로 사용자에게 통지하고 당초 &quot;회사&quot;에서 제시한 조건에 따라 소비자에게 보상합니다.</p>
                    <div>
                        <h3 id="-6-">제6조 (회원가입)</h3>
                        <p>① 사용자는 “회사”가 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.
                            ② “회사”는 제1항과 같이 회원으로 가입할 것을 신청한 사용자 중 다음 각호에 해당하지 않는 한 회원으로 등록합니다.</p>
                        <ul>
                            <li>타인 명의의 신청</li>
                            <li>등록 내용에 허위, 기재누락, 오기가 있는 경우</li>
                            <li>기타 회원으로 등록하는 것이 &quot;회사&quot;의 기술상 현저히 지장이 있다고 판단되는 경우</li>
                            <li>이용 신청 고객의 귀책사유로 이용승낙이 곤란한 경우</li>
                        </ul>
                        <p>③ 회원가입계약의 성립시기는 &quot;회사&quot;의 승낙이 회원에게 도달한 시점으로 합니다.
                            ④ 회원은 등록사항에 변경이 있는 경우, 즉시 전자우편 및 기타 방법으로 &quot;회사&quot;에 대하여 그 변경사항을 알려야 합니다.</p>
                    </div>
                    <h3 id="-7-">제7조 (회원 탈퇴 및 자격 상실 등)</h3>
                    <p>① 회원은 &quot;회사&quot;에 언제든지 탈퇴를 요청할 수 있으며 “회사”는 즉시 회원탈퇴를 처리합니다.
                        ② 회원이 다음 각호의 사유에 해당하는 경우, “회사”는 회원자격을 제한 및 정지시킬 수 있습니다.</p>
                    <ul>
                        <li>가입 신청 시에 허위 내용을 등록한 경우</li>
                        <li>&quot;회사&quot;를 통하여 구입한 재화 등의 대금, 기타 “회사”가용에 관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우</li>
                        <li>다른 사람의 &quot;회사&quot; 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우</li>
                        <li>&quot;회사&quot;를 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우</li>
                    </ul>
                    <p>③ “회사”가 회원자격을 상실시키는 경우에는 7일간의 소명 기회를 드립니다. 본인의 의도와 상관없이 회원 정보가 삭제되는 것을 방지하기 위한 조치 이므로 해당 기간 안에 관련 사항에 대한 의견을 회사에 개진하고 소명의지가 없거나 소명 기회를 무시하였을 경우에는 회원등록을 말소합니다.</p>
                    <div>
                        <h3 id="-8-">제8조 (회원에 대한 통지)</h3>
                        <p>① “회사”가 회원에 대한 통지를 하는 경우, 회원이 “회사”와 미리 약정하여 지정한 전자우편 주소로 할 수 있습니다.
                            ② “회사”는 불특정다수 회원에 대한 통지의 경우 1주일이상 &quot;회사&quot; 홈페이지에 게시함으로써 개별 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별통지를 합니다.</p>
                    </div>
                    <h3 id="-9-">제9조 (서비스 신청)</h3>
                    <p>사용자는 “서비스&quot; 상에서 다음 또는 이와 유사한 방법에 의하여 서비스를 신청하며, “회사”는 사용자가 서비스 신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다.</p>
                    <ol>
                        <li>문서/서식의 검색 및 선택</li>
                        <li>성명, 전자우편주소(또는 이동전화번호) 등의 입력</li>
                        <li>약관내용, 환불권이 제한되는 서비스 등에 대한 확인</li>
                        <li>재화 등의 구매신청 및 이에 관한 확인 또는 &quot;회사&quot;의 확인에 대한 동의</li>
                        <li>결제방법의 선택</li>
                    </ol>
                    <div>
                        <h3 id="-10-">제10조 (서비스 이용의 성립)</h3>
                        <p>① “회사”는 제9조와 같은 서비스 신청에 대하여 다음 각호에 해당하면 승낙하지 않을 수 있습니다.</p>
                        <ol>
                            <li>신청 내용에 허위, 기재누락, 오기가 있는 경우</li>
                            <li>기타 서비스 신청에 승낙하는 것이 &quot;회사&quot; 기술상 현저히 지장이 있다고 판단하는 경우
                                ② &quot;회사&quot;의 승낙이 제12조 제 1항의 수신확인 통지형태로 사용자에게 도달한 시점에 계약이 성립한 것으로 봅니다.
                                ③ &quot;회사&quot;의 승낙 의사표시에는 사용자의 서비스 신청에 대한 확인 및 서비스 신청의 정정/취소 등에 관한 정보 등을 포함하여야 합니다.</li>
                        </ol>
                    </div>
                    <h3 id="-11-">제11조 (지급방법)</h3>
                    <p>&quot;회사&quot;에서 구매한 컨텐츠 또는 서비스에 대한 대금지급방법은 다음 각호의 방법 중 가용한 방법으로 할 수 있습니다. 단, “회사”는 사용자의 지급방법에 대하여 컨텐츠 등의 대금에 어떠한 명목의 수수료도 추가하여 징수할 수 없습니다.</p>
                    <ol>
                        <li>폰 뱅킹, 인터넷뱅킹, 오픈 뱅킹 등의 각종 계좌이체</li>
                        <li>신용카드 등의 각종 카드 결제</li>
                        <li>온라인 무통장 입금</li>
                        <li>기타 전자적 지급 방법에 의한 대금 지급 등</li>
                    </ol>
                    <div>
                        <h3 id="-12-">제12조 (수신확인통지/구매신청 변경 및 취소)</h3>
                        <p>① “회사”는 사용자의 구매신청이 있는 경우 사용자에게 수신확인통지를 합니다.
                            ② 수신확인통지를 받은 사용자는 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후 즉시 구매신청 변경 및 취소를 요청할 수 있습니다. 다만 이미 대금을 지불한 경우에는 제15조의 서비스 철회 등에 관한 규정에 따릅니다.</p>
                    </div>
                    <h3 id="-13-">제13조 (서비스 공급)</h3>
                    <p>① “회사”는 사용자와 서비스 공급시기에 관하여 별도의 약정이 없는 결제 승인이 있는 날부터 서비스 등을 이용할 수 있도록 필요한 조치를 취합니다.
                        ② “회사”는 사용자가 구매한 컨텐츠에 대해 이용방법, 관련 프로그램, 이용 안내 등을 명시합니다.</p>
                    <div>
                        <h3 id="-14-">제14조 (서비스 요금)</h3>
                        <p>① 유료 서비스 요금의 종류는 약관과는 별도로 개별 홈페이지에서 정한 규정을 따릅니다.
                            ② 회원은 회사가 제공하는 서비스 중 유료서비스를 이용하는 경우 이용대금을 납부한 후 서비스를 이용하는 것을 원칙으로 합니다.
                            ③ 회사가 제공하는 유료서비스에 대한 이용요금의 결제 방법은 신용카드결제, 실시간 계좌이체, 가상계좌이체 등이 있으며 각 유료서비스마다 결제 방법의 차이가 있을 수 있습니다.
                            ④ “회사”는 결제의 이행을 위하여 반드시 필요한 회원의 개인정보를 추가적으로 요구할 수 있으며, 회원은 회사가 요구하는 개인정보를 정확하게 제공하여야 합니다.
                            ⑤ “회사”는 회원이 요금을 과납 하거나 오납한 경우 그 요금을 반환하거나 차기 요금에서 정산합니다. 단 회원의 고의 또는 귀책 사유로 과오금이 발생한 경우 환불에 소요되는 비용은 합리적인 범위 내에서 회원이 부담하도록 합니다.</p>
                    </div>
                    <h3 id="-15-">제15조 (서비스 철회)</h3>
                    <p>① “회사”와 컨텐츠 사용에 관한 계약을 체결한 사용자는 서비스 신청 승인을 받은 날부터 7일 이내에는 서비스 철회 신청을 할 수 있습니다.
                        ② 사용자가 제공받은 컨텐츠 및 서비스가 사용자가 원하지 않는 정보일 경우에도 위1항과 같이 7일 이내에 서비스 철회 신청을 할 수 있습니다.
                        ③ 어떤 이유로든 사용자의 정당한 사유에 의해 서비스 철회 요구 시 사용자가 이용한 신용카드 또는 전자화폐 결제수단으로 컨텐츠 등의 대금을 지급한 때에는 지체 없이 당해 결제수단을 제공한 사업자로 하여금 대금의 청구를 정시 또는 취소하도록 요청할 수 있습니다.
                        ④ 이용계약이 해지된 경우 회원의 저작물 중 본인 계정에 등록된 저작물과 개인정보 일체는 삭제됩니다. 단, 다른 회원에 의해 스크랩되어 게시되거나 공용 게시판에 등록된 저작물은 삭제되지 않습니다.
                        ⑤ 회원이 본 이용약관의 규정을 위반한 경우 “회사”는 일방적으로 서비스 계약을 해지할 수 있고, 이로 인하여 서비스 운영에 손해가 발생한 경우 이에 대한 민, 형사상 책임도 물을 수 있습니다.</p>
                    <div>
                        <h3 id="-16-">제16조 (서비스 철회 환급 기준)</h3>
                        <p>① 서비스 해지를 요청하는 고객이 지불 금액의 환불을 원할 경우에는 회사에서 인정하는 방식으로 서비스 해지 및 환불신청을 하여야 합니다.
                            ② 1개월권의 경우 서비스 이용 중 철회에 대한 잔여 금액을 별도 환불하지 않으며, 12개월권 의 경우 회원의 요청이 있을 경우에 한해 환불이 진행되며 이 때 회사는 잔여 계약 기간에 준하는 요금을 환불합니다.
                            ③ 회원이 환불을 요구하는 경우 회사는 구매 내역을 확인한 후 회원에게 본인 확인을 위하여 다음 각호에서 정한 서류의 제출을 요청할 수 있으며, 회원은 요청하는 서류를 이메일, FAX 전송 등의 방법으로 회사에 제출해야 합니다.</p>
                        <ul>
                            <li>환불 요청자 또는 기업 명의의 통장 사본 1부</li>
                            <li>환불 요청자의 신분을 확인할 수 있는 서류 사본 1부 (상황에 따라 받지 않을 수 있음)</li>
                            <li>비용을 납부한 납부 영수증 1부 (상황에 따라 받지 않을 수 있음)</li>
                        </ul>
                        <p>④ 12개월권을 구매한 이용자가 계약만료 전에 해지할 경우 사용한 기간에 할인 받은 차액을 위약금으로 납부하여야 합니다. 위약금의 산출 기준은 다음과 같습니다.</p>
                        <ul>
                            <li>위약금 = 표준가(무약정)금액 대비 월 할인금액 × 이용월 수</li>
                        </ul>
                        <p>⑤ 법정 대리인의 동의 없이 미성년자와 계약에 대한 서비스 철회 요청 시
                            “회사”는 계약 철회에 응하며 기 납부한 요금은 전액 환급조치하고 미납요금 및 위약금 청구행위를 하지 않습니다.
                            ⑥ “회사”는 사용자가 제시한 사유가 서비스 사용규정에 의거 합당하다고 판단될 경우 지체 없이 서비스 철회 요구에 응하며 이용료 전액을 환급하기로 합니다.
                            ⑦ 소비자의 귀책사유로 인한 계약해지 시 “회사”는 해지일까지의 이용일수에 해당하는 금액과 잔여일수의 10% 공제 후 환급해주기로 합니다.
                            단 계약체결일 또는 서비스 이용가능일로부터 7일 이내에 해지를 요구하는 서비스일 경우에는 위약금 없이 이용일수에 해당하는 금액만 공제하고 환급합니다.
                            ⑧ 3일 이상 서비스가 중지되거나 장애가 발생한 경우 “회사”는 계약해지 및 잔여기간에 대한 이용료를 환급해 드리기로 합니다.
                            단 서비스 중지, 장애시간은 소비자가 회사에 통지한 후부터 계산하되, 서비스가 불가항력(천재지변 등)이나 업체의 사전고지 또는 과실로 인하여 중지되거나 장애가
                            발생한 경우에는, 서비스 중지, 장애시간 계산에서 제외됨을 원칙으로 합니다.</p>
                    </div>
                    <h3 id="-17-">제17조 (개인정보보호)</h3>
                    <p>① “회사”는 사용자의 정보수집 시 구매계약 이행에 필요한 최소한의 정보를 수집합니다. 다음 사항을 필수사항으로 하며 그 외 사항은 선택사항으로 합니다.</p>
                    <ol>
                        <li>성명(회원의 경우)</li>
                        <li>이메일</li>
                        <li>비밀번호(회원의 경우)</li>
                    </ol>
                    <p>② “회사”가 사용자의 개인식별이 가능한 개인정보를 수집하는 때에는 반드시 당해 사용자의 동의를 받습니다.
                        ③ 제공된 개인정보는 당해 사용자의 동의 없이 목적 외의 이용이나 제3자에게 제공할 수 없으며, 이에 대한 모든 책임은 회사가 집니다.
                        다만, 다음의 경우에는 예외로 합니다.</p>
                    <ol>
                        <li>배송업무상 배송업체에게 배송에 필요한 최소한의 사용자의 정보(성명, 주소, 전화번호)를 알려주는 경우</li>
                        <li>통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우</li>
                        <li>컨텐츠 등의 거래에 따른 대금정산을 위하여 필요한 경우</li>
                        <li>도용방지를 위하여 본인확인에 필요한 경우</li>
                        <li>법률의 규정 또는 법률에 의하여 필요한 불가피한 사유가 있는 경우
                            ④ “회사”가 제2항과 제3항에 의해 사용자의 동의를 받아야 하는 경우에는 개인정보관리 책임자의 신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받은 자, 제공목적 및 제공할 정보의 내용) 등 정보통신망이용촉진등에관한법률 제22조제2항이 규정한 사항을 미리 명시하거나 고지 해야 하며 사용자는 언제든지 이 동의를 철회할 수 있습니다.
                            ⑤ 사용자는 언제든지 “회사”가 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 “회사”는 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. 사용자가 오류의 정정을 요구한 경우에는 “회사”는 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.
                            ⑥ “회사”는 개인정보 보호를 위하여 관리자를 한정하여 그 수를 최소화하며 신용카드, 은행계좌 등을 포함한 사용자의 개인정보의 분실, 도난, 유출, 변조 등으로 인한 사용자의 손해에 대하여 모든 책임을 집니다.
                            ⑦ &quot;회사&quot; 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다.</li>
                    </ol>
                    <div>
                        <h3 id="-18-">제18조 (&quot;회사&quot;의 의무)</h3>
                        <p>① “회사”는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 재화, 서비스를 제공하는데 최선을 다하여야 합니다.
                            ② “회사”는 사용자가 안전하게 인터넷 서비스를 이용할 수 있도록 사용자의 개인정보(신용정보 포함)보호를 위한 보안 시스템을 갖추어야 합니다.
                            ③ “회사”는 사용자가 원하지 않는 영리목적의 광고성 전자우편을 발송하지 않습니다.</p>
                    </div>
                    <h3 id="-19-id-">제19조 (회원의 ID 및 비밀번호에 대한 의무)</h3>
                    <p>① 제17조의 경우를 제외한 ID와 비밀번호에 관한 관리책임은 회원에게 있습니다.
                        ② 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안 됩니다.
                        ③ 회원이 자신의 ID 및 비밀번호를 도난 당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 &quot;회사&quot;에 통보하고 &quot;회사&quot;의 안내가 있는 경우에는 그에 따라야 합니다.</p>
                    <div>
                        <h3 id="-20-">제20조 (사용자의 의무)</h3>
                        <p>사용자는 다음 행위를 하여서는 안되며, “회사”는 서비스 이용 제한 조치를 취할 수 있습니다.</p>
                        <ol>
                            <li>신청 또는 변경 시 허위 내용의 등록</li>
                            <li>타인의 정보 도용</li>
                            <li>&quot;회사&quot;에 게시된 정보의 변경</li>
                            <li>“회사”가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시</li>
                            <li>&quot;회사&quot; 기타 제3자의 저작권 등 지적재산권에 대한 침해</li>
                            <li>&quot;회사&quot; 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위</li>
                            <li>외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 회사에 공개 또는 게시하는 행위</li>
                            <li>&quot;회사&quot; 사이트 운영을 방해하는 트래픽 증가 행위</li>
                        </ol>
                    </div>
                    <h3 id="-21-">제21조 (저작권의 귀속 및 이용제한)</h3>
                    <p>① “회사”가 작성한 저작물에 대한 저작권 기타 지적재산권은 &quot;회사&quot;에 귀속합니다.</p>
                    <ul>
                        <li>회사의 사전 허가 없이 회사의 서비스 저작물을 직, 간접적으로 변조, 복사, 배포, 출판, 전시, 판매 하거나 상품제작, 인터넷, 모바일 및 데이터베이스를 비롯한 각종 정보서비스 등에 사용하는 것을 금합니다.</li>
                    </ul>
                    <p>② “회사”의 서비스에 대하여 회원이 직접 게시물을 작성할 때는 다음과 같은 내용을 준수하여서 제3자의 지적재산권을 침해하는 일이 없도록 하여야 합니다.</p>
                    <ul>
                        <li>게시물의 내용에 인용문이 포함되어 있을 경우, 그 인용문이 회원이나 사용자의 의견에 일부 참고된 것이 아니라 내용의 중심이라면 출처를 밝히더라도 저작권 침해에 해당됩니다. 따라서 원 저작권자의 명시적 동의 없이 창작물을 게시해서는 안 됩니다.</li>
                        <li>원 저작권자의 명시적 동의 없이 원 저작권자가 만든 콘텐츠의 전부 혹은 일부를 게시, 전재, 복사, 재 배포, 변조, 판매, 게시하는 것은 출처를 밝히더라도 저작권 침해에 해당합니다. 따라서 원 저작권자의 명시적 동의 없이 이런 게시물을 게시하거나 판매해서는 안됩니다.</li>
                        <li>회원 또는 제휴회사에서 등록한 게시자료에 대하여 제3자로부터 저작권 및 기타 권리의 침해 또는 명예훼손, 음란성 등의 이유로 이의가 제기된 경우, “회사”는 당해 게시물을 임시 삭제할 수 있으며, 이의를 제기한 자와 게시물 등록자 간에 소송, 합의 등을 통해 당해 게시물에 관한 법적 문제가 종결된 후 이를 근거로 회사에 신청이 있는 경우에만 상기 임시 삭제된 게시물은 다시 등록될 수 있습니다. “회사”는 어떠한 경우도 제3자와 회원간, 또는 제3자와 제휴 회사간의 법적인 문제에 대한 책임을 지지 않습니다.</li>
                    </ul>
                    <div>
                        <h3 id="-22-">제22조 (서비스 면책사항)</h3>
                        <p>① 천재지변, 전쟁 및 기타 이에 준하는 불가항력으로 인하여 회사 서비스를 제공할 수 없는 경우에는 서비스 제공에 대한 책임이 면제됩니다.
                            ② “회사”는 회원의 귀책사유로 인한 서비스 이용의 장애 또는 손해에 대하여 책임을 지지 않습니다.
                            ③ “회사”는 기간통신사업자가 전기통신역무의 제공을 중지하거나 정상적으로 제공하지 아니하여 서비스를 제공하지 못한 경우에는 그에 대한 책임을 부담하지 않습니다.
                            ④ “회사”는 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 발생한 손해에 대한 책임이 면제됩니다.
                            ⑤ 회원은 “회사” 서비스와 관련하여 자신이 회사에 등록한 필수등록 항목(이동전화번호, 전자우편주소 등) 및 비밀번호의 보안에 대하여 책임을 지며, “회사”는 회원의 고의나 과실로 인하여 아이디, 비밀번호 등의 회원정보가 유출되어 발생하는 손해에 대하여는 책임을 지지 않습니다.
                            ⑥ “회사”는 회원의 컴퓨터 오류에 의해 손해가 발생한 경우, 회원이 신상정보 및 전자우편주소를 부실하게 기재하여 손해가 발생한 경우에는 책임을 지지 않습니다.
                            ⑦ “회사”는 회원 상호간 및 회원과 제3자간에 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없으며, 이로 인한 손해를 배상할 책임이 없습니다.
                            ⑧ 회사 사이트에 연결되거나 제휴한 업체(타 온라인사이트)에 포함되어 있는 내용의 유효성, 적합성, 법적 합리성, 저작권 준수 여부 등에 책임을 지지 않으며, 이로 인한 어떠한 손해에 대하여도 책임을 지지 않습니다.
                            ⑨ 회사 서비스(제휴관계 포함)를 통하여 제공되는 각종 문서서식 샘플은 문서작성을 위한 참고자료 용도로 제공되는 것이므로 자료활용에 따라 발생할 수 있는 손실 또는 손해는 이용회원의 책임이며 회사 이에 대해 어떠한 책임도 지지 않습니다.</p>
                        <div>
                            <h3 id="-23-">제23조 (분쟁해결)</h3>
                            <p>① “회사”는 사용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해보상 처리 기구를 설치, 운영합니다.
                                ② “회사”는 사용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 사용자에게 그 사유와 처리일정을 즉시 통보해 드립니다.
                                ③ “회사”와 사용자 간에 발생한 전자상거래 분쟁과 관련하여 사용자의 피해구제신청이 있는 경우에는 공정거래위원회 또는 시·도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.
                                ④ &quot;회사&quot;의 컨텐츠를 상업적으로 재이용하거나 제3자에게 납품하여 이익을 취하는 경우 법정 대리인을 통한 저작권리를 사용자에게 청구할 수 있고, 소를 통하여 제기될 경우 저작권법에 따라 피해액을 청구합니다.</p>
                        </div>
                        <h3 id="-24-">제24조 (기타조항)</h3>
                        <p>약관을 위반하거나 서비스 이용 시, 불편사항이 있을 경우, 고객지원센터로 문의 바랍니다.</p>
                        <div>
                            <p>☏ 문의전화: [대표] 070-7798-1311 (평일 09:30~18:30, 점심시간 11:50~13:00)
                                ☏ FAX: 02-6925-4651</p>
                        </div>
                    </div>
                </section>

                <div
                    style={{
                        width: "42%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: "30px"
                    }}
                >
                    <button
                        className="cursor"
                        style={{
                            fontSize: "14px",
                            fontWeight: "bold",
                            color: "#FFFFFF",
                            border: "none",
                            outline: "none",
                            background: "#2B4AC4",
                            width: "64px",
                            height: "36px",
                            borderRadius: "8px"
                        }}
                        onClick={() => {
                            props.setAgree({
                                ...props.agree,
                                read_1: true
                            });
                            closePopup();
                        }}
                    >
                        동 의
                    </button>

                    <button
                        className="cursor"
                        style={{
                            fontSize: "14px",
                            fontWeight: "bold",
                            color: "#FFFFFF",
                            border: "none",
                            outline: "none",
                            background: "#C63353",
                            width: "64px",
                            height: "36px",
                            borderRadius: "8px"
                        }}
                        onClick={() => {
                            props.setAgree({
                                ...props.agree,
                                read_1: false
                            });
                            closePopup()
                        }}
                    >
                        취 소
                    </button>
                </div>

            </div>
        </ReactModal>
    )
}

export default TosPopup
