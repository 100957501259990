import { Avatar, Badge } from "@material-ui/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ImageUploadPopup from "component/tag/popup/common/imageUploadPopup";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { updateUserInfo } from "actions/index";
import ProfileTimeZonePopup from "component/tag/popup/my/profile/profileTimeZonePopup";
import ProfileLanguagePopup from "component/tag/popup/my/profile/profileLanguagePopup";
import FileuploaderSingle from "component/tag/file/common/fileuploaderSingle";
import {
  updatePagingInfo,
  resetProfileInfo,
  openAlert,
  openConfirm,
  closeAlert,
} from "actions/index";
import { useTranslation } from "react-i18next";

/**
 * Version : 1.0
 * 파일명 : profileUserInfo.js
 * 작성일자 : 2021-11-25
 * 작성자 : 권도훈
 * 설명 : 프로필 사용자 정보
 * 수정일자 : 2022-07-12
 * 수정자 : 강연승
 * 수정내역 : 미팅호스트 엑셀 업로드 일괄 등록
 */
function ProfileUserInfo(props) {
  /**
   * TRANSLATION
   */
  const { t } = useTranslation();
  /**
   * REDUX
   */
  const USER_STATE = useSelector(state => state.userInfoReducer);
  const PROFILE_STATE = useSelector(state => state.profileInfoReducer);
  /**
   * DISPATCH
   */
  const dispatch = useDispatch();
  /**
   * STATE
   */
  const [licenseTotal, setLicenseTotal] = useState(0);
  const [paymentTotal, setPaymentTotal] = useState(0);
  /**
   * USER IMG
   */
  const [userProfileImage, setUserProfileImage] = useState([]);
  const [isImageUpload, setIsImageUpload] = useState(false);
  /**
   * POPUP STATE
   */
  const [languagePopup, setLanguagePopup] = useState(false);
  const [timeZonePopup, setTimeZonePopup] = useState(false);
  const [timeZoneComment, setTimeZoneComment] = useState(false);
  const [languageComment, setLanguageComment] = useState(false);
  const [excelComment, setExcelComment] = useState(false);
  /**
   * 이름 변경 설정
   */
  const [isEditName, setIsEditName] = useState(false);
  const nameRef = useRef();
  /**
   * 이름 수정 취소
   */
  const cancleEditName = useCallback(() => {
    if (isEditName) {
      setIsEditName(false);
    }
  }, [isEditName]);
  /**
   * get License Total
   */
  const getLicenseTotal = useCallback(() => {
    axios.get("/license/total").then(res => {
      if (res.status === 200) {
        setLicenseTotal(res.data);
      }
    });
  }, []);
  /**
   * get Payment Total
   */
  const getPaymentTotal = useCallback(() => {
    const config = {
      params: {
        TOTAL_TYPE: "CARD",
      },
    };
    axios.get("/profile/total", config).then(res => {
      if (res.status === 200) {
        setPaymentTotal(res.data);
      }
    });
  }, []);
  /**
   * set License&Payment Total
   */
  useEffect(() => {
    if (PROFILE_STATE.REFRESH) {
      getLicenseTotal();
      getPaymentTotal();
      dispatch(resetProfileInfo());
    }
  }, [
    PROFILE_STATE.REFRESH,
    dispatch,
    getLicenseTotal,
    getPaymentTotal,
    licenseTotal,
    paymentTotal,
  ]);

  /**
   * 이름 수정 취소 이벤트 추가
   */
  useEffect(() => {
    if (isEditName) {
      window.addEventListener("click", cancleEditName);
      return () => window.removeEventListener("click", cancleEditName);
    }
  }, [cancleEditName, isEditName]);
  /**
   * Add Close Event Listener
   */
  useEffect(() => {
    if (languagePopup) {
      window.addEventListener("click", closeLanguagePopup);
      return () => {
        window.removeEventListener("click", closeLanguagePopup);
      };
    } else if (timeZonePopup) {
      window.addEventListener("click", closeTimeZonePopup);
      return () => {
        window.removeEventListener("click", closeTimeZonePopup);
      };
    }
  }, [languagePopup, timeZonePopup]);
  /**
   * Language Popup CLose Event
   */
  const closeLanguagePopup = () => {
    setLanguagePopup(false);
  };
  /**
   * TimeZone Popup CLose Event
   */
  const closeTimeZonePopup = () => {
    setTimeZonePopup(false);
  };

  const openImageUploadPopup = () => {
    setIsImageUpload(true);
  };

  const clickBackgound = () => {
    if (isImageUpload) {
      setIsImageUpload(false);
      setUserProfileImage([]);
    }
    if (isExcel) {
      setIsExcel(false);
      deleteExcelFileInfo();
    }
  };
  /**
   * 사용자 정보 조회
   */
  const getUserInfo = () => {
    axios.get("/user/profile", null).then(res => {
      if (res.status === 200) {
        dispatch(updateUserInfo(res.data[0]));
      }
    });
  };
  const uploadProfileImage = () => {
    const formData = new FormData();
    let userInfo = {
      file_info: userProfileImage,
    };

    formData.append("userInfo", JSON.stringify(userInfo));

    axios
      .put("/user/updateProfileImg", formData)
      .then(response => {
        if (response.status === 200) {
          alert(t("my.profile.msg.successImg"));
          getUserInfo();
          setIsImageUpload(false);
          setUserProfileImage([]);
        } else {
          alert(t("my.profile.msg.failImg"));
        }
      })
      .catch(() => {
        alert(t("my.profile.msg.errorImg"));
      });
  };
  /**
   * 이름저장
   */
  const saveNameEvent = () => {
    if (nameRef.current.value === "") {
      return;
    }

    const config = {
      params: {
        NAME: nameRef.current.value,
      },
    };
    saveName(config);

    const obj = {
      ...USER_STATE,
      NAME: nameRef.current.value,
    };
    dispatch(updateUserInfo(obj));
    setIsEditName(false);
  };
  /**
   * 이름 변경 저장
   */
  const saveName = config => {
    axios
      .put("/user/name", null, config)
      .then(res => {
        if (res.status === 200) {
          getUserInfo();
          const obj = {
            TEXT: t("my.profile.msg.successName"),
            submitEventHandler: () => dispatch(closeAlert()),
          };
          dispatch(openAlert(obj));
        } else {
          const obj = {
            TEXT: t("my.profile.msg.failName"),
            submitEventHandler: () => dispatch(closeAlert()),
          };
          dispatch(openAlert(obj));
        }
      })
      .catch(() => {
        const obj = {
          TEXT: t("my.profile.msg.errorName"),
          submitEventHandler: () => dispatch(closeAlert()),
        };
        dispatch(openAlert(obj));
      });
  };

  /**
   * 연락처 파일명 컨버터
   */
  const fileNameConverter = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month =
      today.getMonth() + 1 < 10
        ? "0" + (today.getMonth() + 1)
        : today.getMonth() + 1;
    const day = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();
    const result = year + "." + month + "." + day;

    return "그룹 목록 (" + result + ").xlsx";
  };

  /**
   * 엑셀 파일 정보
   */
  const [isExcel, setIsExcel] = useState(false);
  const [excelInfo, setExcelInfo] = useState({
    name: "",
    base64: "",
    size: "",
  });

  /**
   * 업로드한 파일 정보 제거
   */
  const deleteExcelFileInfo = () => {
    setExcelInfo({
      name: "",
      base64: "",
      size: "",
    });
  };

  /**
   * 연락처 양식 다운로드
   */
  const downloadExcelForm = () => {
    const link = document.createElement("a");
    link.href = "/file/meetingHost_template.xlsx";
    link.setAttribute("download", "SoBridge_호스트_일괄_등록_양식.xlsx");
    document.body.appendChild(link);
    link.click();
  };

  /**
   * 엑셀 파일 다운로드
   */
  // const downloadExcel = () => {
  //   const obj = {
  //     TEXT: t("my.address.msg.confirm.download"),
  //     submitEventHandler: () => {
  //       const config = {
  //         params: {},
  //         responseType: "blob",
  //       };

  //       axios.get("/group/ExcelDownload", config).then(res => {
  //         if (res.status === 200) {
  //           const url = window.URL.createObjectURL(
  //             new Blob([res.data], { type: res.headers["content-type"] })
  //           );
  //           const link = document.createElement("a");
  //           link.href = url;
  //           link.setAttribute("download", fileNameConverter());
  //           document.body.appendChild(link);
  //           link.click();
  //         }
  //       });
  //       dispatch(closeAlert());
  //     },
  //   };
  //   dispatch(openConfirm(obj));
  // };

  /**
   * 엑셀 파일 업로드
   */
  const uploadEcxcel = () => {
    const obj = {
      TEXT: t("my.address.msg.confirm.upload"),
      submitEventHandler: () => {
        const formData = new FormData();
        formData.append("files", excelInfo);

        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        axios
          .post("/group/HostExcelUploload", formData, config)
          .then(res => {
            if (res.status === 200) {
              const tmp = {
                TEXT: t("my.address.msg.result.upload"),
                submitEventHandler: () => {
                  dispatch(closeAlert());
                  setIsExcel(false);
                  deleteExcelFileInfo();
                },
              };
              dispatch(openAlert(tmp));
            }
          })
          .catch(err => {
            if (err.response.status === 400) {
              const tmp = {
                TEXT: t("my.address.msg.err.file.type"),
                submitEventHandler: () => {
                  dispatch(closeAlert());
                  setIsExcel(false);
                  deleteExcelFileInfo();
                },
              };
              dispatch(openAlert(tmp));
            } else if (err.response.status === 417) {
              const tmp = {
                TEXT: t("my.address.msg.err.file.upload"),
                submitEventHandler: () => {
                  dispatch(closeAlert());
                  setIsExcel(false);
                  deleteExcelFileInfo();
                },
              };
              dispatch(openAlert(tmp));
            }
          });
        dispatch(closeAlert());
      },
    };
    dispatch(openConfirm(obj));
  };

  /**
   * TAG
   */
  return (
    <div
      className="profile_sidebar_body"
      onClick={clickBackgound}
      style={{
        justifyContent: "space-between",
        height: "847px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {USER_STATE.IMG === undefined || USER_STATE.IMG === "" ? (
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <Avatar
                onClick={() => openImageUploadPopup}
                className="cursor"
                style={{
                  width: "28px",
                  height: "28px",
                  marginTop: "10px",
                }}
                src="/img/icon/profile/img_change.png"
              />
            }
          >
            <Avatar
              style={{
                width: "104px",
                height: "104px",
                marginTop: "32px",
              }}
            >
              {USER_STATE.NAME.substr(0, 1)}
            </Avatar>
          </Badge>
        ) : (
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <Avatar
                className="cursor"
                onClick={() => openImageUploadPopup}
                style={{
                  width: "28px",
                  height: "28px",
                  marginTop: "10px",
                }}
                src="/img/icon/profile/img_change.png"
              />
            }
          >
            <Avatar
              style={{
                width: "104px",
                height: "104px",
                marginTop: "32px",
              }}
              src={USER_STATE.IMG}
            />
          </Badge>
        )}

        {isImageUpload ? (
          <ImageUploadPopup
            isOpen={isImageUpload}
            upload={uploadProfileImage}
            image={userProfileImage}
            setImage={setUserProfileImage}
          />
        ) : null}

        <div
          className="profile_sidebar_info"
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {isEditName ? (
            <input
              ref={nameRef}
              defaultValue={nameRef.current}
              className="profile_sidebar_nameEditor"
              type="text"
              maxLength="10"
              onKeyDown={e => {
                if (e.key === "Enter") {
                  if (e.target.value === "") {
                    const obj = {
                      TEXT: "변경할 이름을 입력해주세요.",
                      submitEventHandler: () => dispatch(closeAlert()),
                    };
                    dispatch(openAlert(obj));
                  } else if (USER_STATE.NAME !== e.target.value) {
                    const config = {
                      TEXT: t("my.profile.msg.saveName"),
                      submitEventHandler: () => saveNameEvent(),
                    };
                    dispatch(openConfirm(config));
                  } else {
                    setIsEditName(false);
                  }
                }
              }}
              onClick={e => e.stopPropagation()}
            />
          ) : (
            <div
              className="profile_sidebar_name"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  marginLeft: "13px",
                  width: "250px",
                }}
              >
                {USER_STATE.NAME}
              </div>
              <img
                className="cursor"
                src="/img/icon/profile/name_edit.png"
                alt="EDIT"
                onClick={() => {
                  nameRef.current = USER_STATE.NAME;
                  setIsEditName(true);
                }}
              />
            </div>
          )}

          <div className="profile_sidebar_email">{USER_STATE.EMAIL}</div>
        </div>

        {/* <div className="cursor profile_sidebar_btn_side profile_sidebar_point">
          <div className="profile_sidebar_leftdiv">
            보유 포인트
          </div>

          <div className="profile_sidebar_rightdiv">
            {USER_STATE.POINT}
          </div>
        </div> */}

        <div
          className="cursor profile_sidebar_btn_side"
          onClick={() => {
            if (props.viewType !== "LICENSE") {
              props.setViewType("LICENSE");
              const paging_obj = {
                ROW_COUNT: 10,
                PAGE_COUNT: 0,
                TOTAL: 0,
              };
              dispatch(updatePagingInfo(paging_obj));
            }
          }}
          style={{
            outline:
              props.viewType === "LICENSE" ? "1px solid #8B5FBF" : "none",
            background:
              props.viewType === "LICENSE"
                ? "rgba(255, 255, 255, 0.5)"
                : "rgba(222, 223, 242, 0.5)",
          }}
        >
          <div className="profile_sidebar_btn_left">
            {t("my.profile.txt.licenseCount")}
          </div>

          <div className="profile_sidebar_btn_right">{licenseTotal}</div>
        </div>

        {/* <div
          className="cursor profile_sidebar_btn_side"
          onClick={() => {
            if (props.viewType !== "CARD") {
              props.setViewType("CARD");
              const paging_obj = {
                ROW_COUNT: 11,
                PAGE_COUNT: 0,
                TOTAL: 0,
              };
              dispatch(updatePagingInfo(paging_obj));
            }
          }}
          style={{
            outline: props.viewType === "CARD" ? "1px solid #8B5FBF" : "none",
            background:
              props.viewType === "CARD"
                ? "rgba(255, 255, 255, 0.5)"
                : "rgba(222, 223, 242, 0.5)",
          }}
        >
          <div className="profile_sidebar_btn_left">
            결제 내역
          </div>
        </div> */}

        {/* <div
          className="cursor profile_sidebar_btn_side_support"
          onClick={() => {
            if (props.viewType !== "SUPPORT") {
              props.setViewType("SUPPORT");
            } else if (props.viewType === "SUPPORT") {
              props.setgodetail("5");
            }
          }}
          style={{
            outline:
              props.viewType === "SUPPORT" ? "1px solid #8B5FBF" : "none",
            background:
              props.viewType === "SUPPORT"
                ? "rgba(255, 255, 255, 0.5)"
                : "rgba(222, 223, 242, 0.5)",
          }}
        >
          <div className="profile_sidebar_btn_support">고객센터</div>
          <div className="profile_sidebar_btn_support_sub">
            FAQ·1:1문의·고객의소리·공지사항
          </div>
        </div> */}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            className="cursor"
            style={{
              width: "228px",
              height: "48px",
              background: "#9b9faf",
              borderRadius: "12px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "15px",
              fontWeight: "500",
              color: "#fff",
            }}
            onClick={() => {
              if (props.viewType !== "SUPPORT") {
                props.setViewType("SUPPORT");
              } else if (props.viewType === "SUPPORT") {
                props.setgodetail("5");
              }
            }}
          >
            <img
              src="/img/icon/profile/service.png"
              alt=""
              style={{ marginRight: "8px" }}
            />
            고객센터
          </div>
          <div
            className="cursor"
            style={{
              width: "48px",
              height: "48px",
              background: "#DE8799",
              borderRadius: "12px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "0 8px",
            }}
            onClick={() => setTimeZonePopup(!timeZonePopup)}
            onMouseOver={() => setTimeZoneComment(true)}
            onMouseOut={() => setTimeZoneComment(false)}
          >
            <img
              src="/img/icon/profile/timezone.png"
              alt="TZ"
              style={{
                width: "24px",
                height: "24px",
              }}
            />
          </div>

          <div
            className="cursor"
            style={{
              width: "48px",
              height: "48px",
              background: "#CB86DD",
              borderRadius: "12px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => setLanguagePopup(!languagePopup)}
            onMouseOver={() => setLanguageComment(true)}
            onMouseOut={() => setLanguageComment(false)}
          >
            <img
              src="/img/icon/profile/language.png"
              alt="LG"
              style={{
                width: "24px",
                height: "24px",
              }}
            />
          </div>

          {/* <div
            className="cursor"
            style={{
              width: "232px",
              height: "48px",
              background: "#8B9DDF",
              borderRadius: "12px",
              fontSize: "16px",
              fontWeight: "bold",
              color: "#FFFFFF",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => {
              window.open(
                "https://rivtech.notion.site/rivtech/Pebble-Tip-58df683839e8450a8d9bed3b9c592a91"
              );
            }}
          >
            {t("my.profile.btn.guide")}
          </div> */}

          <div
            style={{
              display: timeZonePopup ? "block" : "none",
              width: "344px",
              height: "145px",
              position: "absolute",
              inset: "0px auto auto 0px",
              transform: "translate(1075px, 586px)",
              background: "#FFFFFF",
              boxShadow: "0px 5px 10px #00000029",
              border: "1px solid #DE8799",
              borderRadius: "16px",
            }}
            onClick={e => e.stopPropagation()}
          >
            <ProfileTimeZonePopup
              setTimeZonePopup={setTimeZonePopup}
              timeZonePopup={timeZonePopup}
            />
          </div>

          <div
            style={{
              display: languagePopup ? "block" : "none",
              width: "288px",
              height: "145px",
              position: "absolute",
              inset: "0px auto auto 0px",
              transform: "translate(1130px, 586px)",
              background: "#FFFFFF",
              boxShadow: "0px 5px 10px #00000029",
              border: "1px solid #DE8799",
              borderRadius: "16px",
            }}
            onClick={e => e.stopPropagation()}
          >
            <ProfileLanguagePopup
              setLanguagePopup={setLanguagePopup}
              languagePopup={languagePopup}
            />
          </div>

          <div
            className="comment_timezone_balloon"
            style={{
              display: timeZoneComment && !timeZonePopup ? "flex" : "none",
            }}
          >
            <div>{t("my.profile.txt.timeZoneComment")}</div>
          </div>

          <div
            className="comment_language_balloon"
            style={{
              display: languageComment && !languagePopup ? "flex" : "none",
            }}
          >
            <div>{t("my.profile.txt.languageComment")}</div>
          </div>
        </div>

        {USER_STATE.AUTH_YN === "Y" ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "16px",
            }}
          >
            <div
              className="cursor"
              style={{
                width: "48px",
                height: "48px",
                background: "#4865cc",
                borderRadius: "12px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "10px",
              }}
              onClick={() => downloadExcelForm()}
              onMouseOver={() => setExcelComment(true)}
              onMouseOut={() => setExcelComment(false)}
            >
              <img src="/img/icon/profile/excel_down.png" alt="" />
            </div>

            <div
              className="cursor"
              style={{
                width: "286px",
                height: "48px",
                background: "#8b9ddf",
                borderRadius: "12px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "15px",
                fontWeight: "500",
                color: "#fff",
              }}
              onClick={() => {
                deleteExcelFileInfo();
                setIsExcel(!isExcel);
              }}
              // onClick={() => {
              //   if (props.viewType !== "SUPPORT") {
              //     props.setViewType("SUPPORT");
              //   } else if (props.viewType === "SUPPORT") {
              //     props.setgodetail("5");
              //   }
              // }}
            >
              <img
                src="/img/icon/profile/all_up.png"
                alt=""
                style={{ marginRight: "8px" }}
              />
              호스트 일괄 업로드
            </div>

            {isExcel ? (
              <div
                id="excelUploadPopup"
                className="host-excel-upload"
                onClick={e => e.stopPropagation()}
              >
                <img
                  className="host-excel-upload-close cursor"
                  src="/img/icon/address/close.png"
                  alt=""
                  onClick={() => {
                    if (excelInfo.name !== "") {
                      deleteExcelFileInfo();
                    }
                    setIsExcel(false);
                  }}
                />

                <div className="host-excel-upload-inner">
                  <section className="host-excel-upload-section">
                    <p>엑셀 업로드</p>
                    <p>엑셀 파일을 업로드하여 호스트를 한 번에 등록하세요.</p>
                  </section>

                  <div className="host-excel-upload-content">
                    {excelInfo.name === "" ? (
                      <>
                        {/* <button
                          className="host-excel-download-btn cursor"
                          onClick={() => downloadExcelForm()}
                        >
                          {REDUCER_STATE.GROUP_YN === "N"
                            ? t("my.address.txt.download.form.CD0401")
                            : t(
                                "my.address.txt.download.form." +
                                  REDUCER_STATE.GROUP_CATEGORY
                              )}
                        </button> */}

                        <label
                          htmlFor="fileuploaderSingle"
                          className="host-excel-select-btn cursor"
                        >
                          {t("my.address.txt.file")}
                        </label>
                      </>
                    ) : (
                      <>
                        <div className="host-excel-fileinfo">
                          <div className="host-excel-fileinfo-inner">
                            <div>
                              <img
                                src="/img/icon/address/file_icon.png"
                                alt=""
                              />
                              <div>{excelInfo.name}</div>
                            </div>

                            <img
                              className="cursor"
                              src="/img/icon/address/delete_36.png"
                              alt=""
                              onClick={() => deleteExcelFileInfo()}
                            />
                          </div>
                        </div>

                        <button
                          className="host-excel-upload-btn cursor"
                          onClick={() => uploadEcxcel()}
                        >
                          업로드
                        </button>
                      </>
                    )}
                  </div>

                  <FileuploaderSingle
                    type="excel"
                    setFileInfo={setExcelInfo}
                    fileType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    fileSize="10485760"
                  />
                </div>
              </div>
            ) : (
              <></>
            )}

            <div
              className="comment_excel_balloon"
              style={{
                display: excelComment ? "flex" : "none",
              }}
            >
              <div>그룹 양식 다운로드</div>
            </div>
          </div>
        ) : null}
      </div>

      {/* <img
        className="profile_sidebar_img"
        src="/img/icon/profile/profile_illust.png"
        alt="I"
      /> */}
    </div>
  );
}

export default ProfileUserInfo;
