import DataStatisticsHeader from "component/tag/div/group/data/dataStatisticsHeader";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataStatisticsCardList from "./dataStatisticsCardList";
import DataStatisticsList from "./dataStatisticsList";
import {
  updateStatisticsType,
  closedateStatisticsDetail,
  updateStatisticsDetail,
} from "actions/index";
import { useLocation } from "react-router";
import DataStatisticsDetail from "./dataStatisticsDetail";
import DataStatisticsSideBar from "component/tag/div/group/data/dataStatisticsSideBar";
import axios from "axios";
import Loading from "component/tag/loading/loading";
/**
 * Version : 1.0
 * 파일명 : dataStatistics.js
 * 작성일자 : 2021-12-28
 * 작성자 : 권도훈
 * 설명 : 데이터 통계
 * 수정일자 : 2022-06-08
 * 수정자 : 강연승
 * 수정내역 : 대시보드에서 이동한 경우 및 미팅호스트 케이스 추가
 */
function DataStatistics() {
  /**
   * REDUCER
   */
  const STATISTICS_STATE = useSelector(state => state.statisticsInfoReducer);
  const GROUP_STATE = useSelector(state => state.groupInfoReducer);
  const USER_INFO = useSelector(state => state.userInfoReducer);
  /**
   * DISPATCH
   */
  const dispatch = useDispatch();
  /**
   *  Location
   */
  const location = useLocation();
  /**
   * 초기화
   */
  const [init, setInit] = useState(false);
  /**
   * 데이터
   */
  const [statisticsData, setStatisticsData] = useState([]);
  const [statisticsTotal, setStatisticsTotal] = useState();
  const [statisticsPage, setStatisticsPage] = useState({
    ROW_COUNT: 10,
    PAGE_COUNT: 0,
  });
  const [detailData, setDetailData] = useState([]);
  const [detailTotal, setDetailTotal] = useState([]);
  const [detailPage, setDetailPage] = useState({
    ROW_COUNT: 10,
    PAGE_COUNT: 0,
  });
  /**
   * 색상테이블
   */
  const [colorTable, setColorTable] = useState([]);
  /**
   * 로딩
   */
  const [loading, setLoading] = useState(false);
  /**
   * 검색
   */
  const [dataSearch, setDataSearch] = useState(true);
  const [datailTargetRefresh, setDetailTargetRefresh] = useState(false);
  const [detailSearch, setDetailSearch] = useState(false);
  /**
   * 기본 목록
   */
  const [sort, setSort] = useState({
    type: "HOST_NAME",
    direction: "ASC",
  });
  const [dateInit, setDateInit] = useState(false);
  const [date, setDate] = useState({
    START_DATE: new Date(new Date().setMonth(new Date().getMonth() - 1)),
    END_DATE: new Date(),
  });
  const [hostName, setHostName] = useState("");
  /**
   * 상세보기
   */
  const [sortDetail, setSortDetail] = useState({
    type: "PARTICIPANT_NAME",
    direction: "ASC",
  });
  const [dateDetail, setDateDetail] = useState({
    START_DATE: new Date(new Date().setMonth(new Date().getMonth() - 1)),
    END_DATE: new Date(),
  });
  const [hostNameDetail, setHostNameDetail] = useState("");
  const [timeDetail, setTimeDetail] = useState({
    start_time_text: "",
    start_time: "",
    end_time_text: "",
    end_time: "",
  });
  const [defaultTime, setDefaultTime] = useState({
    start_time_text: "",
    start_time: "",
    end_time_text: "",
    end_time: "",
  });

  const [worktime, setWorkTime] = useState({
    min: 0,
    max: 0,
  });
  const [overtime, setOverTime] = useState({
    min: 0,
    max: 0,
  });
  const [weekendtime, setWeekendTime] = useState({
    min: 0,
    max: 0,
  });

  const [attendance, setAttendance] = useState({
    min: 0,
    max: 0,
  });

  const [attendanceCount, setAttendanceCount] = useState({
    min: 0,
    max: 0,
  });
  const [tardyCount, setTardyCount] = useState({
    min: 0,
    max: 0,
  });
  const [absebceCount, setAbsebceCount] = useState({
    min: 0,
    max: 0,
  });

  const [timeInit, setTimeInit] = useState(false);

  // const [pageInit, setPageInit] = useState(false);
  /**
   * 기본 목록 조회
   */
  const getData = useCallback(() => {
    setLoading(true);

    const config = {
      params: {
        GROUP_NUM: GROUP_STATE.GROUP_NUM,
        SORT_TYPE: sort.type,
        SORT_DIRECTION: sort.direction,
        START_DATE: dateConvert(date.START_DATE),
        END_DATE: dateConvert(date.END_DATE),
        HOST_NAME: hostName,
        TARGET_NUM: "",
        ROW_COUNT: statisticsPage.ROW_COUNT,
        PAGE_COUNT: statisticsPage.PAGE_COUNT,
      },
    };

    axios
      .get("/schedule/statistics", config)
      .then(res => {
        if (res.status === 200) {
          let statistics = [];
          let color = colorTable;

          for (let i = 0; i < res.data.length; i++) {
            let rate = 0;

            if (res.data[i].INVITE_PARTICIPANTS_COUNT > 0) {
              rate =
                (res.data[i].PARTICIPANT_COUNT /
                  res.data[i].INVITE_PARTICIPANTS_COUNT) *
                100;
            }

            res.data[i].ATTENDANCE_RATE = Math.floor(rate);
            res.data[i].BACKGROUND_COLOR =
              color[Math.floor(Math.random() * color.length) + 1];

            color = color.filter(data => data !== res.data[i].BACKGROUND_COLOR);

            statistics.push(res.data[i]);
          }
          setStatisticsData(statistics);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [
    GROUP_STATE.GROUP_NUM,
    colorTable,
    date.END_DATE,
    date.START_DATE,
    hostName,
    sort.direction,
    sort.type,
    statisticsPage.PAGE_COUNT,
    statisticsPage.ROW_COUNT,
  ]);

  const getDetailTargetData = useCallback(() => {
    setLoading(true);

    const config = {
      params: {
        GROUP_NUM: GROUP_STATE.GROUP_NUM,
        SORT_TYPE: sort.type,
        SORT_DIRECTION: sort.direction,
        START_DATE: dateConvert(dateDetail.START_DATE),
        END_DATE: dateConvert(dateDetail.END_DATE),
        HOST_NAME: hostName,
        TARGET_NUM: STATISTICS_STATE.detail_data.USER_NUM,
      },
    };

    axios
      .get("/schedule/statistics", config)
      .then(res => {
        if (res.status === 200) {
          let data = res.data[0];
          let color = colorTable;
          let rate = 0;

          if (data.INVITE_PARTICIPANTS_COUNT > 0) {
            rate =
              (data.PARTICIPANT_COUNT /
                (data.INVITE_PARTICIPANTS_COUNT +
                  data.NON_INVITE_PARTICIPANTS_COUNT)) *
              100;
          }

          data.ATTENDANCE_RATE = Math.floor(rate);
          data.BACKGROUND_COLOR =
            color[Math.floor(Math.random() * color.length) + 1];
          dispatch(updateStatisticsDetail(data));
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [
    GROUP_STATE.GROUP_NUM,
    STATISTICS_STATE.detail_data.USER_NUM,
    colorTable,
    dateDetail.END_DATE,
    dateDetail.START_DATE,
    dispatch,
    hostName,
    sort.direction,
    sort.type,
  ]);
  /**
   * 상세보기 목록 조회
   */
  const getDetailData = useCallback(() => {
    setLoading(true);

    let config = {};

    if (GROUP_STATE.AUTH_CD === "CD0301") {
      config = {
        params: {
          USER_NUM: STATISTICS_STATE.detail_data.USER_NUM,
        },
      };
    } else if (GROUP_STATE.AUTH_CD === "CD0302") {
      config = {
        params: {
          USER_NUM: USER_INFO.NUM,
        },
      };
    }

    if (
      GROUP_STATE.GROUP_CATEGORY === "CD0403" ||
      GROUP_STATE.GROUP_CATEGORY === "CD0404" ||
      GROUP_STATE.GROUP_CATEGORY === "CD0405"
    ) {
      config = {
        params: {
          ...config.params,
          // USER_NUM: STATISTICS_STATE.detail_data.USER_NUM,
          GROUP_NUM: GROUP_STATE.GROUP_NUM,
          START_DATE: dateConvert(dateDetail.START_DATE),
          END_DATE: dateConvert(dateDetail.END_DATE),
          SORT_TYPE: sortDetail.type,
          SORT_DIRECTION: sortDetail.direction,
          GROUP_CATEGORY: GROUP_STATE.GROUP_CATEGORY,
          ATTENDANCE_MAX_COUNT: attendanceCount.max,
          ATTENDANCE_MIN_COUNT: attendanceCount.min,
          TARDY_MAX_COUNT: tardyCount.max,
          TARDY_MIN_COUNT: tardyCount.min,
          ABSEBCE_MAX_COUNT: absebceCount.max,
          ABSEBCE_MIN_COUNT: absebceCount.min,
          ATTENDANCE_MIN_RATE: attendance.min,
          ATTENDANCE_MAX_RATE: attendance.max,
          HOST_NAME: hostNameDetail,
          ROW_COUNT: detailPage.ROW_COUNT,
          PAGE_COUNT: detailPage.PAGE_COUNT,
        },
      };
    } else if (
      GROUP_STATE.GROUP_CATEGORY === "CD0401" ||
      GROUP_STATE.GROUP_CATEGORY === "CD0402"
    ) {
      config = {
        params: {
          ...config.params,
          // USER_NUM: STATISTICS_STATE.detail_data.USER_NUM,
          GROUP_NUM: GROUP_STATE.GROUP_NUM,
          START_TIME: timeDetail.start_time,
          END_TIME: timeDetail.end_time,
          START_DATE: dateConvert(dateDetail.START_DATE),
          END_DATE: dateConvert(dateDetail.END_DATE),
          SORT_TYPE: sortDetail.type,
          SORT_DIRECTION: sortDetail.direction,
          GROUP_CATEGORY: GROUP_STATE.GROUP_CATEGORY,
          WORKTIME_MIN_TIME: worktime.min * 60,
          WORKTIME_MAX_TIME: worktime.max * 60,
          OVERTIME_MIN_TIME: overtime.min * 60,
          OVERTIME_MAX_TIME: overtime.max * 60,
          WEEKEND_MIN_TIME: weekendtime.min * 60,
          WEEKEND_MAX_TIME: weekendtime.max * 60,
          ATTENDANCE_MIN_RATE: attendance.min,
          ATTENDANCE_MAX_RATE: attendance.max,
          HOST_NAME: hostNameDetail,
          ROW_COUNT: detailPage.ROW_COUNT,
          PAGE_COUNT: detailPage.PAGE_COUNT,
        },
      };
    } else {
      config = {
        params: {
          ...config.params,
          // USER_NUM: STATISTICS_STATE.detail_data.USER_NUM,
          GROUP_NUM: GROUP_STATE.GROUP_NUM,
          START_TIME: "00:00",
          END_TIME: "23:59",
          START_DATE: dateConvert(dateDetail.START_DATE),
          END_DATE: dateConvert(dateDetail.END_DATE),
          SORT_TYPE: sortDetail.type,
          SORT_DIRECTION: sortDetail.direction,
          GROUP_CATEGORY: GROUP_STATE.GROUP_CATEGORY,
          ATTENDANCE_MIN_RATE: attendance.min,
          ATTENDANCE_MAX_RATE: attendance.max,
          HOST_NAME: hostNameDetail,
          ROW_COUNT: detailPage.ROW_COUNT,
          PAGE_COUNT: detailPage.PAGE_COUNT,
        },
      };
    }

    axios
      .get("/schedule/statistics/detail", config)
      .then(res => {
        setLoading(false);

        if (res.status === 200) {
          let color = colorTable;
          let statistics = [];

          if (
            GROUP_STATE.GROUP_CATEGORY === "CD0403" ||
            GROUP_STATE.GROUP_CATEGORY === "CD0404" ||
            GROUP_STATE.GROUP_CATEGORY === "CD0405"
          ) {
            for (let i = 0; i < res.data.length; i++) {
              res.data[i].BACKGROUND_COLOR =
                color[Math.floor(Math.random() * color.length) + 1];
              color = color.filter(
                data => data !== res.data[i].BACKGROUND_COLOR
              );

              statistics.push(res.data[i]);
            }
          } else if (
            GROUP_STATE.GROUP_CATEGORY === "CD0401" ||
            GROUP_STATE.GROUP_CATEGORY === "CD0402"
          ) {
            for (let i = 0; i < res.data.length; i++) {
              //set color
              res.data[i].BACKGROUND_COLOR =
                color[Math.floor(Math.random() * color.length) + 1];
              color = color.filter(
                data => data !== res.data[i].BACKGROUND_COLOR
              );

              res.data[i].WORK_TIME_TOTAL = Math.ceil(
                res.data[i].WORK_TIME_TOTAL / 60
              );
              res.data[i].WORK_OVERTIME_TOTAL = Math.ceil(
                res.data[i].WORK_OVERTIME_TOTAL / 60
              );
              res.data[i].WEEK_WORK_TIME_TOTAL = Math.ceil(
                res.data[i].WEEK_WORK_TIME_TOTAL / 60
              );

              statistics.push(res.data[i]);
            }
          } else {
            for (let i = 0; i < res.data.length; i++) {
              //set color
              res.data[i].BACKGROUND_COLOR =
                color[Math.floor(Math.random() * color.length) + 1];
              color = color.filter(
                data => data !== res.data[i].BACKGROUND_COLOR
              );

              statistics.push(res.data[i]);
            }
          }

          setDetailData(statistics);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }, [
    GROUP_STATE.GROUP_CATEGORY,
    GROUP_STATE.GROUP_NUM,
    STATISTICS_STATE.detail_data.USER_NUM,
    absebceCount.max,
    absebceCount.min,
    attendance.max,
    attendance.min,
    attendanceCount.max,
    attendanceCount.min,
    colorTable,
    dateDetail.END_DATE,
    dateDetail.START_DATE,
    detailPage.PAGE_COUNT,
    detailPage.ROW_COUNT,
    hostNameDetail,
    overtime.max,
    overtime.min,
    sortDetail.direction,
    sortDetail.type,
    tardyCount.max,
    tardyCount.min,
    timeDetail.end_time,
    timeDetail.start_time,
    weekendtime.max,
    weekendtime.min,
    worktime.max,
    worktime.min,
  ]);
  /**
   * 근무시간
   */
  const getWorkTime = useCallback(() => {
    const config = {
      params: {
        GROUP_NUM: GROUP_STATE.GROUP_NUM,
      },
    };

    axios.get("/group/workTime", config).then(res => {
      if (res.status === 200) {
        let workStart = res.data.WORK_START;
        let workEnd = res.data.WORK_END;

        let startHour = workStart.split(":")[0];
        let startMin = workStart.split(":")[1];
        let endHour = workEnd.split(":")[0];
        let endMin = workEnd.split(":")[1];

        setTimeDetail({
          start_time_text:
            startHour < 12
              ? "오전 " + startHour + ":" + startMin
              : "오후 " + startHour + ":" + startMin,
          start_time: startHour + ":" + startMin,
          end_time_text:
            endHour < 12
              ? "오전 " + endHour + ":" + endMin
              : "오후 " + endHour + ":" + endMin,
          end_time: endHour + ":" + endMin,
        });
        setDefaultTime({
          start_time_text:
            startHour < 12
              ? "오전 " + startHour + ":" + startMin
              : "오후 " + startHour + ":" + startMin,
          start_time: startHour + ":" + startMin,
          end_time_text:
            endHour < 12
              ? "오전 " + endHour + ":" + endMin
              : "오후 " + endHour + ":" + endMin,
          end_time: endHour + ":" + endMin,
        });
      }
    });
  }, [GROUP_STATE.GROUP_NUM]);

  const getDataTotal = useCallback(() => {
    setLoading(true);

    const config = {
      params: {
        GROUP_NUM: GROUP_STATE.GROUP_NUM,
        SORT_TYPE: sort.type,
        SORT_DIRECTION: sort.direction,
        START_DATE: dateConvert(date.START_DATE),
        END_DATE: dateConvert(date.END_DATE),
        HOST_NAME: hostName,
        ROW_COUNT: statisticsPage.ROW_COUNT,
        PAGE_COUNT: statisticsPage.PAGE_COUNT,
      },
    };

    axios
      .get("/schedule/statistics/total", config)
      .then(res => {
        if (res.status === 200) {
          setStatisticsTotal(res.data.TOTAL);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [
    GROUP_STATE.GROUP_NUM,
    date.END_DATE,
    date.START_DATE,
    hostName,
    sort.direction,
    sort.type,
    statisticsPage.PAGE_COUNT,
    statisticsPage.ROW_COUNT,
  ]);

  const getDetailTotal = useCallback(() => {
    setLoading(true);

    let config = {};

    if (GROUP_STATE.AUTH_CD === "CD0301") {
      config = {
        params: {
          USER_NUM: STATISTICS_STATE.detail_data.USER_NUM,
        },
      };
    } else if (GROUP_STATE.AUTH_CD === "CD0302") {
      config = {
        params: {
          USER_NUM: USER_INFO.NUM,
        },
      };
    }

    if (
      GROUP_STATE.GROUP_CATEGORY === "CD0403" ||
      GROUP_STATE.GROUP_CATEGORY === "CD0404" ||
      GROUP_STATE.GROUP_CATEGORY === "CD0405"
    ) {
      config = {
        params: {
          ...config.params,
          // USER_NUM: STATISTICS_STATE.detail_data.USER_NUM,
          GROUP_NUM: GROUP_STATE.GROUP_NUM,
          START_DATE: dateConvert(dateDetail.START_DATE),
          END_DATE: dateConvert(dateDetail.END_DATE),
          SORT_TYPE: sortDetail.type,
          SORT_DIRECTION: sortDetail.direction,
          GROUP_CATEGORY: GROUP_STATE.GROUP_CATEGORY,
          ATTENDANCE_MAX_COUNT: attendanceCount.max,
          ATTENDANCE_MIN_COUNT: attendanceCount.min,
          TARDY_MAX_COUNT: tardyCount.max,
          TARDY_MIN_COUNT: tardyCount.min,
          ABSEBCE_MAX_COUNT: absebceCount.max,
          ABSEBCE_MIN_COUNT: absebceCount.min,
          ATTENDANCE_MIN_RATE: attendance.min,
          ATTENDANCE_MAX_RATE: attendance.max,
          HOST_NAME: hostNameDetail,
          ROW_COUNT: detailPage.ROW_COUNT,
          PAGE_COUNT: detailPage.PAGE_COUNT,
        },
      };
    } else if (
      GROUP_STATE.GROUP_CATEGORY === "CD0401" ||
      GROUP_STATE.GROUP_CATEGORY === "CD0402"
    ) {
      config = {
        params: {
          ...config.params,
          // USER_NUM: STATISTICS_STATE.detail_data.USER_NUM,
          GROUP_NUM: GROUP_STATE.GROUP_NUM,
          START_TIME: timeDetail.start_time,
          END_TIME: timeDetail.end_time,
          START_DATE: dateConvert(dateDetail.START_DATE),
          END_DATE: dateConvert(dateDetail.END_DATE),
          SORT_TYPE: sortDetail.type,
          SORT_DIRECTION: sortDetail.direction,
          GROUP_CATEGORY: GROUP_STATE.GROUP_CATEGORY,
          WORKTIME_MIN_TIME: worktime.min * 60,
          WORKTIME_MAX_TIME: worktime.max * 60,
          OVERTIME_MIN_TIME: overtime.min * 60,
          OVERTIME_MAX_TIME: overtime.max * 60,
          WEEKEND_MIN_TIME: weekendtime.min * 60,
          WEEKEND_MAX_TIME: weekendtime.max * 60,
          ATTENDANCE_MIN_RATE: attendance.min,
          ATTENDANCE_MAX_RATE: attendance.max,
          HOST_NAME: hostNameDetail,
          ROW_COUNT: detailPage.ROW_COUNT,
          PAGE_COUNT: detailPage.PAGE_COUNT,
        },
      };
    } else {
      config = {
        params: {
          ...config.params,
          // USER_NUM: STATISTICS_STATE.detail_data.USER_NUM,
          GROUP_NUM: GROUP_STATE.GROUP_NUM,
          START_TIME: "00:00",
          END_TIME: "23:59",
          START_DATE: dateConvert(dateDetail.START_DATE),
          END_DATE: dateConvert(dateDetail.END_DATE),
          SORT_TYPE: sortDetail.type,
          SORT_DIRECTION: sortDetail.direction,
          GROUP_CATEGORY: GROUP_STATE.GROUP_CATEGORY,
          ATTENDANCE_MIN_RATE: attendance.min,
          ATTENDANCE_MAX_RATE: attendance.max,
          HOST_NAME: hostNameDetail,
          ROW_COUNT: detailPage.ROW_COUNT,
          PAGE_COUNT: detailPage.PAGE_COUNT,
        },
      };
    }

    axios
      .get("/schedule/statistics/detail/total", config)
      .then(res => {
        if (res.status === 200) {
          setDetailTotal(res.data.TOTAL);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [
    GROUP_STATE.GROUP_CATEGORY,
    GROUP_STATE.GROUP_NUM,
    STATISTICS_STATE.detail_data.USER_NUM,
    absebceCount.max,
    absebceCount.min,
    attendance.max,
    attendance.min,
    attendanceCount.max,
    attendanceCount.min,
    dateDetail.END_DATE,
    dateDetail.START_DATE,
    detailPage.PAGE_COUNT,
    detailPage.ROW_COUNT,
    hostNameDetail,
    overtime.max,
    overtime.min,
    sortDetail.direction,
    sortDetail.type,
    tardyCount.max,
    tardyCount.min,
    timeDetail.end_time,
    timeDetail.start_time,
    weekendtime.max,
    weekendtime.min,
    worktime.max,
    worktime.min,
  ]);

  const setCalendarDate = useCallback(() => {
    const now = new Date();

    setDate({
      START_DATE: new Date(now.setMonth(now.getMonth() - 1)),
      END_DATE: new Date(),
    });
  }, []);
  /**
   * 검색
   */
  useEffect(() => {
    if (dataSearch) {
      getData();
      getDataTotal();
      setDataSearch(false);
    }
  }, [dataSearch, getData, getDataTotal]);

  useEffect(() => {
    if (STATISTICS_STATE.isDetail && detailSearch) {
      getDetailData();
      getDetailTotal();
      setDetailSearch(false);
    }
  }, [STATISTICS_STATE.isDetail, detailSearch, getDetailData, getDetailTotal]);

  useEffect(() => {
    if (STATISTICS_STATE.isDetail && datailTargetRefresh) {
      getDetailTargetData();
      setDetailTargetRefresh(false);
    }
  }, [STATISTICS_STATE.isDetail, datailTargetRefresh, getDetailTargetData]);

  /**
   * 대시보드에서 이동한 경우
   */
  useEffect(() => {
    if (location.state !== null && location.state !== undefined) {
      setLoading(true);

      const config = {
        params: {
          GROUP_NUM: GROUP_STATE.GROUP_NUM,
          SORT_TYPE: sort.type,
          SORT_DIRECTION: sort.direction,
          START_DATE: dateConvert(date.START_DATE),
          END_DATE: dateConvert(date.END_DATE),
          HOST_NAME: hostName,
          TARGET_NUM: location.state.USER_NUM,
        },
      };

      axios
        .get("/schedule/statistics", config)
        .then(res => {
          if (res.status === 200) {
            let data = res.data[0];
            let color = colorTable;
            let rate = 0;

            if (data.INVITE_PARTICIPANTS_COUNT > 0) {
              rate =
                (data.PARTICIPANT_COUNT /
                  (data.INVITE_PARTICIPANTS_COUNT +
                    data.NON_INVITE_PARTICIPANTS_COUNT)) *
                100;
            }

            data.ATTENDANCE_RATE = Math.floor(rate);
            data.BACKGROUND_COLOR =
              color[Math.floor(Math.random() * color.length) + 1];
            dispatch(updateStatisticsDetail(data));
          }
          setLoading(false);
        })
        .then(() => {
          setDetailSearch(true);
          setDateDetail({
            START_DATE: new Date(date.START_DATE),
            END_DATE: new Date(date.END_DATE),
          });
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [location.state]);

  /**
   * init
   */
  useEffect(() => {
    if (!init) {
      setCalendarDate();
      dispatch(closedateStatisticsDetail());
      dispatch(updateStatisticsType("CARD"));
      // getData();
      setColorTable([
        "#D4667E",
        "#DC82C3",
        "#CD704B",
        "#BD9D29",
        "#97C12A",
        "#28B750",
        "#27B395",
        "#6E85D7",
        "#9479DA",
        "#2B4AC4",
      ]);
      // setDate({
      //     START_DATE: new Date(),
      //     END_DATE: new Date(),
      // });
      setInit(true);
      setStatisticsTotal(0);
      setDetailTotal(0);
      setStatisticsPage({
        ROW_COUNT: 10,
        PAGE_COUNT: 0,
      });
      setDetailPage({
        ROW_COUNT: 10,
        PAGE_COUNT: 0,
      });
      setDataSearch(true);
      if (GROUP_STATE.AUTH_CD === "CD0302") {
        setLoading(true);

        const config = {
          params: {
            GROUP_NUM: GROUP_STATE.GROUP_NUM,
            SORT_TYPE: sort.type,
            SORT_DIRECTION: sort.direction,
            START_DATE: dateConvert(date.START_DATE),
            END_DATE: dateConvert(date.END_DATE),
            HOST_NAME: hostName,
            TARGET_NUM: USER_INFO.NUM,
          },
        };

        axios
          .get("/schedule/statistics", config)
          .then(res => {
            console.log(res);
            if (res.status === 200) {
              let data = res.data[0];
              let color = colorTable;
              let rate = 0;

              if (data.INVITE_PARTICIPANTS_COUNT > 0) {
                rate =
                  (data.PARTICIPANT_COUNT /
                    (data.INVITE_PARTICIPANTS_COUNT +
                      data.NON_INVITE_PARTICIPANTS_COUNT)) *
                  100;
              }

              data.ATTENDANCE_RATE = Math.floor(rate);
              data.BACKGROUND_COLOR =
                color[Math.floor(Math.random() * color.length) + 1];
              dispatch(updateStatisticsDetail(data));
            }
            setLoading(false);
            setDetailSearch(true);
            setDateDetail({
              START_DATE: new Date(date.START_DATE),
              END_DATE: new Date(date.END_DATE),
            });
          })
          // .then(() => {
          //   setDetailSearch(true);
          //   setDateDetail({
          //     START_DATE: new Date(date.START_DATE),
          //     END_DATE: new Date(date.END_DATE),
          //   });
          // })
          .then(() => {
            getDetailTotal();
            getDetailData();
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  }, [dispatch, init, setCalendarDate]);
  /**
   * 근무시간 설정
   */
  useEffect(() => {
    if (!timeInit) {
      getWorkTime();
      setTimeInit(true);
    }
  }, [getWorkTime, timeInit]);
  /**
   * 날짜 포맷
   */
  const dateConvert = value => {
    let year = value.getFullYear();
    let month = value.getMonth() + 1;
    let days = value.getDate();

    if (month < 10) {
      month = "0" + month;
    }

    if (days < 10) {
      days = "0" + days;
    }

    const result = year + "-" + month + "-" + days;

    return result;
  };

  const closeDetail = () => {
    setDetailSearch(false);
    setHostNameDetail("");
    setDateDetail({
      START_DATE: "",
      END_DATE: "",
    });
    setDetailData([]);
    setTimeDetail({
      start_time_text: defaultTime.start_time_text,
      start_time: defaultTime.start_time,
      end_time_text: defaultTime.end_time_text,
      end_time: defaultTime.end_time,
    });
    setAttendance({
      min: 0,
      max: 100,
    });
    setWorkTime({
      min: 0,
      max: 0,
    });
    setOverTime({
      min: 0,
      max: 0,
    });
    setWeekendTime({
      min: 0,
      max: 0,
    });
    setAttendanceCount({
      min: 0,
      max: 0,
    });
    setTardyCount({
      min: 0,
      max: 0,
    });
    setAbsebceCount({
      min: 0,
      max: 0,
    });
    setDetailTotal(0);
    setDetailPage({
      ROW_COUNT: 10,
      PAGE_COUNT: 0,
    });
    setSortDetail({
      type: "PARTICIPANT_NAME",
      direction: "ASC",
    });
  };
  /**
   * 엑셀 다운로드
   */
  const downloadExcel = () => {
    setLoading(true);

    const config = {
      responseType: "blob",
      params: {
        GROUP_NUM: GROUP_STATE.GROUP_NUM,
        GROUP_CATEGORY: GROUP_STATE.GROUP_CATEGORY,
        // SORT_TYPE: sort.type,
        // SORT_DIRECTION: sort.direction,
        START_DATE: dateConvert(date.START_DATE),
        END_DATE: dateConvert(date.END_DATE),
        HOST_NAME: hostName,
        TARGET_NUM: "",
      },
    };

    axios
      .get("/schedule/statistics/excel", config)
      .then(res => {
        if (res.status === 200) {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: res.headers["content-type"] })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "SoBridge_" + GROUP_STATE.GROUP_NM + "_통계.xlsx"
          );
          document.body.appendChild(link);
          link.click();
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  /**
   * 엑셀 상세 다운로드
   */
  const downloadDetailExcel = () => {
    setLoading(true);
    let config = {};

    if (
      GROUP_STATE.GROUP_CATEGORY === "CD0403" ||
      GROUP_STATE.GROUP_CATEGORY === "CD0404" ||
      GROUP_STATE.GROUP_CATEGORY === "CD0405"
    ) {
      config = {
        responseType: "blob",
        params: {
          USER_NUM: STATISTICS_STATE.detail_data.USER_NUM,
          GROUP_NUM: GROUP_STATE.GROUP_NUM,
          START_DATE: dateConvert(dateDetail.START_DATE),
          END_DATE: dateConvert(dateDetail.END_DATE),
          SORT_TYPE: sortDetail.type,
          SORT_DIRECTION: sortDetail.direction,
          GROUP_CATEGORY: GROUP_STATE.GROUP_CATEGORY,
          ATTENDANCE_MAX_COUNT: attendanceCount.max,
          ATTENDANCE_MIN_COUNT: attendanceCount.min,
          TARDY_MAX_COUNT: tardyCount.max,
          TARDY_MIN_COUNT: tardyCount.min,
          ABSEBCE_MAX_COUNT: absebceCount.max,
          ABSEBCE_MIN_COUNT: absebceCount.min,
          ATTENDANCE_MIN_RATE: attendance.min,
          ATTENDANCE_MAX_RATE: attendance.max,
          HOST_NAME: hostNameDetail,

          HOST_INFO_NAME: STATISTICS_STATE.detail_data.NAME,
          HOST_INFO_EMAIL: STATISTICS_STATE.detail_data.EMAIL,
          HOST_INFO_TOTAL: STATISTICS_STATE.detail_data.TOTAL,
          HOST_INFO_DURATION: STATISTICS_STATE.detail_data.DURATION,
          HOST_INFO_PARTICIPANT_COUNT:
            STATISTICS_STATE.detail_data.PARTICIPANT_COUNT,
          HOST_INFO_INVITE_PARTICIPANT_COUNT:
            STATISTICS_STATE.detail_data.INVITE_PARTICIPANTS_COUNT,
          HOST_INFO_ATTENDANCE_RATE: calRate(
            STATISTICS_STATE.detail_data.PARTICIPANT_COUNT,
            STATISTICS_STATE.detail_data.INVITE_PARTICIPANTS_COUNT +
              STATISTICS_STATE.detail_data.NON_INVITE_PARTICIPANTS_COUNT
          ),
          GROUP_NM: GROUP_STATE.GROUP_NM,
        },
      };
    } else if (
      GROUP_STATE.GROUP_CATEGORY === "CD0401" ||
      GROUP_STATE.GROUP_CATEGORY === "CD0402"
    ) {
      config = {
        responseType: "blob",
        params: {
          USER_NUM: STATISTICS_STATE.detail_data.USER_NUM,
          GROUP_NUM: GROUP_STATE.GROUP_NUM,
          START_TIME: timeDetail.start_time,
          END_TIME: timeDetail.end_time,
          START_DATE: dateConvert(dateDetail.START_DATE),
          END_DATE: dateConvert(dateDetail.END_DATE),
          SORT_TYPE: sortDetail.type,
          SORT_DIRECTION: sortDetail.direction,
          GROUP_CATEGORY: GROUP_STATE.GROUP_CATEGORY,
          WORKTIME_MIN_TIME: worktime.min * 60,
          WORKTIME_MAX_TIME: worktime.max * 60,
          OVERTIME_MIN_TIME: overtime.min * 60,
          OVERTIME_MAX_TIME: overtime.max * 60,
          WEEKEND_MIN_TIME: weekendtime.min * 60,
          WEEKEND_MAX_TIME: weekendtime.max * 60,
          ATTENDANCE_MIN_RATE: attendance.min,
          ATTENDANCE_MAX_RATE: attendance.max,
          HOST_NAME: hostNameDetail,

          HOST_INFO_NAME: STATISTICS_STATE.detail_data.NAME,
          HOST_INFO_EMAIL: STATISTICS_STATE.detail_data.EMAIL,
          HOST_INFO_TOTAL: STATISTICS_STATE.detail_data.TOTAL,
          HOST_INFO_DURATION: STATISTICS_STATE.detail_data.DURATION,
          HOST_INFO_PARTICIPANT_COUNT:
            STATISTICS_STATE.detail_data.PARTICIPANT_COUNT,
          HOST_INFO_INVITE_PARTICIPANT_COUNT:
            STATISTICS_STATE.detail_data.INVITE_PARTICIPANTS_COUNT,
          HOST_INFO_ATTENDANCE_RATE: calRate(
            STATISTICS_STATE.detail_data.PARTICIPANT_COUNT,
            STATISTICS_STATE.detail_data.INVITE_PARTICIPANTS_COUNT +
              STATISTICS_STATE.detail_data.NON_INVITE_PARTICIPANTS_COUNT
          ),
          GROUP_NM: GROUP_STATE.GROUP_NM,
        },
      };
    } else {
      config = {
        responseType: "blob",
        params: {
          USER_NUM: STATISTICS_STATE.detail_data.USER_NUM,
          GROUP_NUM: GROUP_STATE.GROUP_NUM,
          START_TIME: "00:00",
          END_TIME: "23:59",
          START_DATE: dateConvert(dateDetail.START_DATE),
          END_DATE: dateConvert(dateDetail.END_DATE),
          SORT_TYPE: sortDetail.type,
          SORT_DIRECTION: sortDetail.direction,
          GROUP_CATEGORY: GROUP_STATE.GROUP_CATEGORY,
          ATTENDANCE_MIN_RATE: attendance.min,
          ATTENDANCE_MAX_RATE: attendance.max,
          HOST_NAME: hostNameDetail,

          HOST_INFO_NAME: STATISTICS_STATE.detail_data.NAME,
          HOST_INFO_EMAIL: STATISTICS_STATE.detail_data.EMAIL,
          HOST_INFO_TOTAL: STATISTICS_STATE.detail_data.TOTAL,
          HOST_INFO_DURATION: STATISTICS_STATE.detail_data.DURATION,
          HOST_INFO_PARTICIPANT_COUNT:
            STATISTICS_STATE.detail_data.PARTICIPANT_COUNT,
          HOST_INFO_INVITE_PARTICIPANT_COUNT:
            STATISTICS_STATE.detail_data.INVITE_PARTICIPANTS_COUNT,
          HOST_INFO_ATTENDANCE_RATE: calRate(
            STATISTICS_STATE.detail_data.PARTICIPANT_COUNT,
            STATISTICS_STATE.detail_data.INVITE_PARTICIPANTS_COUNT +
              STATISTICS_STATE.detail_data.NON_INVITE_PARTICIPANTS_COUNT
          ),
          GROUP_NM: GROUP_STATE.GROUP_NM,
        },
      };
    }
    console.log(config.params);
    axios
      .get("/schedule/statistics/detail/excel", config)
      .then(res => {
        if (res.status === 200) {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: res.headers["content-type"] })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "SoBridge_" + GROUP_STATE.GROUP_NM + "_상세통계.xlsx"
          );
          document.body.appendChild(link);
          link.click();
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  /**
   * PDF 상세 다운로드
   */
  const downloadDetailPdf = () => {
    const url = process.env.REACT_APP_REPORT_URL;
    let downloadFileNm =
      GROUP_STATE.GROUP_NM +
      "_" +
      STATISTICS_STATE.detail_data.NAME +
      "_상세통계_(" +
      dateConvert(dateDetail.START_DATE) +
      "%7E" +
      dateConvert(dateDetail.END_DATE) +
      ")";
    let file = "";
    let arg =
      "USER_NUM%23" +
      STATISTICS_STATE.detail_data.USER_NUM +
      "%23GROUP_NUM%23" +
      GROUP_STATE.GROUP_NUM +
      "%23START_DATE%23" +
      dateConvert(dateDetail.START_DATE) +
      "%23END_DATE%23" +
      dateConvert(dateDetail.END_DATE) +
      "%23UNLOCODE%23" +
      USER_INFO.TIMEZONE;
    if (
      GROUP_STATE.GROUP_CATEGORY === "CD0401" ||
      GROUP_STATE.GROUP_CATEGORY === "CD0402"
    ) {
      file = "partiList_company.jrf";
      arg +=
        "%23START_TIME%23" +
        timeDetail.start_time +
        "%23END_TIME%23" +
        timeDetail.end_time +
        "%23WORK_START%23" +
        timeDetail.start_time +
        ":00" +
        "%23WORK_END%23" +
        timeDetail.end_time +
        ":00" +
        "%23SORT_TYPE%23" +
        sortDetail.type +
        "%23SORT_DIRECTION%23" +
        sortDetail.direction +
        "%23WORKTIME_MIN_TIME%23" +
        worktime.min * 60 +
        "%23WORKTIME_MAX_TIME%23" +
        worktime.max * 60 +
        "%23OVERTIME_MIN_TIME%23" +
        overtime.min * 60 +
        "%23OVERTIME_MAX_TIME%23" +
        worktime.max * 60 +
        "%23WEEKEND_MIN_TIME%23" +
        weekendtime.min * 60 +
        "%23WEEKEND_MAX_TIME%23" +
        worktime.max * 60 +
        "%23ATTENDANCE_MIN_RATE%23" +
        attendance.min +
        "%23ATTENDANCE_MAX_RATE%23" +
        attendance.max +
        "%23HOST_NAME%23" +
        hostNameDetail +
        "%23";
    } else if (
      GROUP_STATE.GROUP_CATEGORY === "CD0403" ||
      GROUP_STATE.GROUP_CATEGORY === "CD0404" ||
      GROUP_STATE.GROUP_CATEGORY === "CD0405"
    ) {
      file = "partiList_education.jrf";
      arg +=
        "%23SORT_TYPE%23" +
        sortDetail.type +
        "%23SORT_DIRECTION%23" +
        sortDetail.direction +
        "%23ATTENDANCE_MAX_COUNT%23" +
        attendanceCount.max +
        "%23ATTENDANCE_MIN_COUNT%23" +
        attendanceCount.min +
        "%23TARDY_MAX_COUNT%23" +
        tardyCount.max +
        "%23TARDY_MIN_COUNT%23" +
        tardyCount.min +
        "%23ABSEBCE_MAX_COUNT%23" +
        absebceCount.max +
        "%23ABSEBCE_MIN_COUNT%23" +
        absebceCount.min +
        "%23ATTENDANCE_MIN_RATE%23" +
        attendance.min +
        "%23ATTENDANCE_MAX_RATE%23" +
        attendance.max +
        "%23HOST_NAME%23" +
        hostNameDetail +
        "%23";
    } else {
      file = "partiList_social.jrf";
      arg +=
        "%23START_TIME%23" +
        "00:00" +
        "%23END_TIME%23" +
        "23:59" +
        "%23SORT_TYPE%23" +
        sortDetail.type +
        "%23SORT_DIRECTION%23" +
        sortDetail.direction +
        "%23ATTENDANCE_MIN_RATE%23" +
        attendance.min +
        "%23ATTENDANCE_MAX_RATE%23" +
        attendance.max +
        "%23HOST_NAME%23" +
        hostNameDetail +
        "%23";
    }
    window.open(
      url + "?file=" + file + "&arg=" + arg + "&downloadName=" + downloadFileNm,
      "_blank",
      "width=840,height=700,status=no,titlebar=no,toolbar=no,menubar=no,location=no"
    );
  };

  /**
   * PDF 호스트리스트 다운로드
   */
  const downloadHostListPdf = () => {
    const url = process.env.REACT_APP_REPORT_URL;
    let downloadFileNm =
      GROUP_STATE.GROUP_NM +
      "_호스트통계_(" +
      dateConvert(date.START_DATE) +
      "%7E" +
      dateConvert(date.END_DATE) +
      ")";
    let file = "";
    let arg =
      "GROUP_NUM%23" +
      GROUP_STATE.GROUP_NUM +
      "%23START_DATE%23" +
      dateConvert(date.START_DATE) +
      "%2000:00:00" +
      "%23END_DATE%23" +
      dateConvert(date.END_DATE) +
      "%2023:59:59" +
      "%23UNLOCODE%23" +
      USER_INFO.TIMEZONE +
      "%23SORT_TYPE%23" +
      sort.type +
      "%23SORT_DIRECTION%23" +
      sort.direction +
      "%23HOST_NAME%23" +
      hostName +
      "%23BACK_COLOR%23" +
      GROUP_STATE.GROUP_COLOR +
      "%23GROUP_NM%23" +
      GROUP_STATE.GROUP_NM +
      "%23GROUP_CATEGORY%23" +
      GROUP_STATE.GROUP_CATEGORY +
      "%23";
    if (
      GROUP_STATE.GROUP_CATEGORY === "CD0401" ||
      GROUP_STATE.GROUP_CATEGORY === "CD0402"
    ) {
      file = "HostList_All.jrf";
      //groupnum,  unlcode, startdate, enddate, hostname, targetnum, sorttype, sortdirecti on
    } else if (
      GROUP_STATE.GROUP_CATEGORY === "CD0403" ||
      GROUP_STATE.GROUP_CATEGORY === "CD0404" ||
      GROUP_STATE.GROUP_CATEGORY === "CD0405"
    ) {
      file = "HostList_All.jrf";
    } else {
      file = "HostList_All.jrf";
    }
    window.open(
      url + "?file=" + file + "&arg=" + arg + "&downloadName=" + downloadFileNm,
      "_blank",
      "width=840,height=700,status=no,titlebar=no,toolbar=no,menubar=no,location=no"
    );
  };

  /**
   *  참여율
   */
  const calRate = (count, total) => {
    let rate = 0;

    if (total > 0) {
      rate = Math.floor((count / total) * 100);
    }

    return rate + "%";
  };

  return (
    <article className="data-statistics-article">
      <div
        className={
          STATISTICS_STATE.isDetail
            ? "data-statistics detail"
            : "data-statistics"
        }
      >
        {GROUP_STATE.AUTH_CD === "CD0301" ? (
          <>
            <div className="data-statistics-inner">
              <DataStatisticsHeader
                loading={loading}
                setDataSearch={setDataSearch}
                getData={getData}
                setHostName={setHostName}
                setSort={setSort}
                setDate={setDate}
                hostName={hostName}
                sort={sort}
                date={date}
                closeDetail={closeDetail}
                setStatisticsPage={setStatisticsPage}
                downloadExcel={downloadExcel}
                downloadDetailExcel={downloadDetailExcel}
                dateDetail={dateDetail}
                downloadDetailPdf={downloadDetailPdf}
                downloadHostListPdf={downloadHostListPdf}
                total={detailTotal}
              />
              <div
                className={
                  STATISTICS_STATE.isDetail
                    ? "data-statistics-container detail"
                    : "data-statistics-container"
                }
              >
                {loading ? (
                  <div className="data-statistics-container-loading">
                    <Loading />
                  </div>
                ) : STATISTICS_STATE.isDetail ? (
                  <DataStatisticsDetail
                    data={detailData}
                    setDetailData={setDetailData}
                    dateDetail={dateDetail}
                    timeDetail={timeDetail}
                    loading={loading}
                    setDetailSearch={setDetailSearch}
                    setSortDetail={setSortDetail}
                    sortDetail={sortDetail}
                    page={detailPage}
                    setPage={setDetailPage}
                    total={detailTotal}
                  />
                ) : STATISTICS_STATE.type === "CARD" ? (
                  <DataStatisticsCardList
                    data={statisticsData.filter(data => data.TOTAL > 0)}
                    setDateDetail={setDateDetail}
                    date={date}
                    setDetailSearch={setDetailSearch}
                    setLoading={setLoading}
                    loading={loading}
                  />
                ) : (
                  <DataStatisticsList
                    setDataSearch={setDataSearch}
                    setDetailSearch={setDetailSearch}
                    data={statisticsData.filter(data => data.TOTAL > 0)}
                    setDateDetail={setDateDetail}
                    setSort={setSort}
                    sort={sort}
                    date={date}
                    page={statisticsPage}
                    setPage={setStatisticsPage}
                    total={statisticsTotal}
                    loading={loading}
                  />
                )}
              </div>
            </div>

            <div
              className={
                STATISTICS_STATE.isDetail
                  ? "data-statistics-sidebar show"
                  : "data-statistics-sidebar"
              }
            >
              <div
                className="data-statistics-sidebar-inner"
                style={{
                  display: STATISTICS_STATE.isDetail ? "flex" : "none",
                }}
              >
                {STATISTICS_STATE.isDetail ? (
                  <DataStatisticsSideBar
                    data={detailData}
                    setDetailData={setDetailData}
                    setDetailSearch={setDetailSearch}
                    hostNameDetail={hostNameDetail}
                    setHostNameDetail={setHostNameDetail}
                    setDateDetail={setDateDetail}
                    dateDetail={dateDetail}
                    date={date}
                    setDefaultTime={setDefaultTime}
                    setTimeDetail={setTimeDetail}
                    timeDetail={timeDetail}
                    setAttendance={setAttendance}
                    attendance={attendance}
                    setWorkTime={setWorkTime}
                    setOverTime={setOverTime}
                    setWeekendTime={setWeekendTime}
                    worktime={worktime}
                    overtime={overtime}
                    weekendtime={weekendtime}
                    setAttendanceCount={setAttendanceCount}
                    setTardyCount={setTardyCount}
                    setAbsebceCount={setAbsebceCount}
                    setDetailTargetRefresh={setDetailTargetRefresh}
                    setDetailPage={setDetailPage}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="data-statistics-inner">
              <DataStatisticsHeader
                loading={loading}
                setDataSearch={setDataSearch}
                getData={getData}
                setHostName={setHostName}
                setSort={setSort}
                setDate={setDate}
                hostName={hostName}
                sort={sort}
                date={date}
                closeDetail={closeDetail}
                setStatisticsPage={setStatisticsPage}
                downloadExcel={downloadExcel}
                downloadDetailExcel={downloadDetailExcel}
                dateDetail={dateDetail}
                downloadDetailPdf={downloadDetailPdf}
                downloadHostListPdf={downloadHostListPdf}
                total={detailTotal}
              />
              <div
                className={
                  STATISTICS_STATE.isDetail
                    ? "data-statistics-container detail"
                    : "data-statistics-container"
                }
              >
                {loading ? (
                  <div className="data-statistics-container-loading">
                    <Loading />
                  </div>
                ) : STATISTICS_STATE.isDetail ? (
                  <DataStatisticsDetail
                    data={detailData}
                    setDetailData={setDetailData}
                    dateDetail={dateDetail}
                    timeDetail={timeDetail}
                    loading={loading}
                    setDetailSearch={setDetailSearch}
                    setSortDetail={setSortDetail}
                    sortDetail={sortDetail}
                    page={detailPage}
                    setPage={setDetailPage}
                    total={detailTotal}
                  />
                ) : null}
              </div>
            </div>

            <div
              className={
                STATISTICS_STATE.isDetail
                  ? "data-statistics-sidebar show"
                  : "data-statistics-sidebar"
              }
            >
              <div
                className="data-statistics-sidebar-inner"
                style={{
                  display: STATISTICS_STATE.isDetail ? "flex" : "none",
                }}
              >
                <DataStatisticsSideBar
                  data={detailData}
                  setDetailData={setDetailData}
                  setDetailSearch={setDetailSearch}
                  hostNameDetail={hostNameDetail}
                  setHostNameDetail={setHostNameDetail}
                  setDateDetail={setDateDetail}
                  dateDetail={dateDetail}
                  date={date}
                  setDefaultTime={setDefaultTime}
                  setTimeDetail={setTimeDetail}
                  timeDetail={timeDetail}
                  setAttendance={setAttendance}
                  attendance={attendance}
                  setWorkTime={setWorkTime}
                  setOverTime={setOverTime}
                  setWeekendTime={setWeekendTime}
                  worktime={worktime}
                  overtime={overtime}
                  weekendtime={weekendtime}
                  setAttendanceCount={setAttendanceCount}
                  setTardyCount={setTardyCount}
                  setAbsebceCount={setAbsebceCount}
                  setDetailTargetRefresh={setDetailTargetRefresh}
                  setDetailPage={setDetailPage}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </article>
  );
}

export default DataStatistics;
