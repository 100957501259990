import React, { useState } from 'react'
import "css/profiledirectquestion.css"
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import {  openAlert,
  closeAlert,} from"actions";
import SupportPopup from 'component/tag/popup/support/supportPopup';
import SupportPopupmain from 'component/tag/popup/support/supportPopupmain';

/**
 * Version : 1.0
 * 파일명 : supdirectquestion.js
 * 작성일자 : 2022-03-30
 * 작성자 : 정지윤
 * 설명 : 고객센터 1:1 문의 페이지
 * 수정일자 : 2022-03-31
 * 수정자 : 정지윤
 * 수정내역 : 버튼 영역 재조정
*/

function Supdirectquestion() {
  const [phonenumber, setphonenumber] = useState('');
  const [emailaddress, setemailaddress] = useState('');
  const [content, setcontent] = useState('');
  const [name, setname] = useState('');
  const [title, settitle] = useState('');
  const [Selected, setSelected] = useState("");
  
  const Emailreducer = useSelector(state => state.userInfoReducer);

  const regEmail = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;

  var changev = content;
  let contentcv = changev.replaceAll(/(?:\r\n|\r|\n)/g, "<br />");

  const regex = /^[0-9\b -]{0,13}$/;
  const regextest = /^[0-9\b -]{9,13}$/;


  const handlephonenumber = (e) =>{
    if(regex.test(e.target.value)){
      setphonenumber(e.target.value);
    }
  }
  const handleemailaddress = (e) =>{

    setemailaddress(e.target.value);
  
  }
  const handlecontent = (e) =>{
    setcontent(e.target.value);
  }
  const handlename = (e) =>{
    setname(e.target.value);
  }
  const handletitle = (e) =>{
    settitle(e.target.value);
  }
  const handleChangeSelect = (e) =>{
    setSelected(e.target.value);
  };

  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const openModal=()=> {
      setShowModal(true);
  }
  const closeModal = ()=>{
      setShowModal(false);
  }

  const setdataclear = () =>{
    setcontent('');
    setname('');
    settitle('');
    setSelected('');
    setphonenumber('');
    setemailaddress('');
    setdirectquestionFile([]);
  }

  const [directquestionFile, setdirectquestionFile] = useState([]);
 
  const onChangeFile = (e) => {

    let tmpArr = [];
    let suggestFileTotalSize = 0;
    for (let i = 0; i < directquestionFile.length; i++) {
      suggestFileTotalSize += directquestionFile[i].size;
    }
    for (let i = 0; i < e.target.files.length; i++) {
      let sizeTotal = suggestFileTotalSize;
      if (directquestionFile.find(file => file.name === e.target.files[i].name)) {
        const obj = {
          TEXT: "이미 선택된 파일입니다.",
          submitEventHandler: () => dispatch(closeAlert()),
        };
        dispatch(openAlert(obj));
        return;
      }
      if (e.target.files[i].size > 10485760) {
        const obj = {
          TEXT: "10MB 이하의 파일을 첨부해주세요.",
          submitEventHandler: () => dispatch(closeAlert()),
        };
        dispatch(openAlert(obj));
        return;
      }
      if (sizeTotal + e.target.files[i].size > 10485760) {
        const obj = {
          TEXT: "파일 크기 합계가 10MB 이상입니다. 사이즈를 줄여주세요",
          submitEventHandler: () => dispatch(closeAlert()),
        };
        dispatch(openAlert(obj));
        return;
      }  
      // if (e.target.files[i].type.replace(/\/.*/, "") !== "image") {
      //   const obj = {
      //     TEXT: "이미지 파일이 아닙니다. png, jpg, bmg, gif파일을 업로드 해주세요.",
      //     submitEventHandler: () => dispatch(closeAlert()),
      //   };
      //   dispatch(openAlert(obj));
      //   return;
      // } else {
      //   if(e.target.files[i].type.split("/")[1] !== "png" &&
      //   e.target.files[i].type.split("/")[1] !== "jpg" &&
      //   e.target.files[i].type.split("/")[1] !== "gif"&&
      //   e.target.files[i].type.split("/")[1] !== "bmp"){
      //     const obj = {
      //       TEXT: "이미지 파일이 아닙니다. png, jpg, bmp, gif파일을 업로드 해주세요.",
      //       submitEventHandler: () => dispatch(closeAlert()),
      //     };
      //     dispatch(openAlert(obj));
      //     return;
      //   }
      // }
      suggestFileTotalSize += e.target.files[i].size;
      tmpArr.push(e.target.files[i]);
    }
    setdirectquestionFile(directquestionFile.concat(tmpArr));
    e.target.value = "";
  };
  
  const onRemoveFile = file => {
    setdirectquestionFile(
      directquestionFile.filter(directquestionFile => directquestionFile.name !== file.name)
    );
  };



  const onClicksubmit = (phonenumber,emailaddress,content,name,title,Selected) =>{
          if(Selected !== '' ){
            if(phonenumber !== ''&& regextest.test(phonenumber)===true){
              if(title!==''){
                if(content !==''){
                  if(emailaddress !== ''){
                    if(regEmail.test(emailaddress) ===true){

                      const formData = new FormData();

                      if (Array.isArray(directquestionFile) && directquestionFile.length > 0) {
                        for (let i = 0; i < directquestionFile.length; i++) {
                          formData.append("files", directquestionFile[i]);
                        }

                      }
          
                      let parameter = {
                        PHONE_NUM : phonenumber,
                        EMAIL_ADDRESS : emailaddress,
                        SUPPORT_CONTENT : contentcv,
                        SUPPORT_NAME : name,
                        SUPPORT_TITLE : title,
                        SUPPORT_TYPE : Selected
                      }
          
                      formData.append("params", JSON.stringify(parameter))
                      const config = {
                        headers: {
                          "Content-Type": "multipart/form-data",
                        }
                      };
          
                      axios
                      .post("/support/directquestion", formData, config)
                      .then(response =>{
                        if (response.status===200){
   
                        }
                      })
                      setdataclear();  

                      openModal();

                    }else{
                      const obj = {
                        TEXT: "이메일 양식을 확인해 주세요",
                        submitEventHandler: () => dispatch(closeAlert()),
                      };
                      dispatch(openAlert(obj));
                      return;
                    }
                  }else{

                    /*  입력 안했을 시 eamil에 회원 이메일을 직접 주입 */
                    const formData = new FormData();
                     

                    if (Array.isArray(directquestionFile) && directquestionFile.length > 0) {
                      for (let i = 0; i < directquestionFile.length; i++) {
                        formData.append("files", directquestionFile[i]);
                      }

                    }
        
                    let parameter = {
                      PHONE_NUM : phonenumber,
                      EMAIL_ADDRESS : Emailreducer.EMAIL,
                      SUPPORT_CONTENT : contentcv,
                      SUPPORT_NAME : name,
                      SUPPORT_TITLE : title,
                      SUPPORT_TYPE : Selected
                    }
        
                    formData.append("params", JSON.stringify(parameter))
                    const config = {
                      headers: {
                        "Content-Type": "multipart/form-data",
                      }
                    };
        
                    axios
                    .post("/support/directquestion", formData, config)
                    .then(response =>{
                      if (response.status===200){    
                      }
                    })
                    setdataclear();  

                    openModal();
                  }
                }else{
                  const obj = {
                    TEXT: "문의 내용을 입력해 주세요",
                    submitEventHandler: () => dispatch(closeAlert()),
                  };
                  dispatch(openAlert(obj));
                  return;
                }
              }
              else{
                const obj = {
                  TEXT: "문의 제목을 입력해주세요",
                  submitEventHandler: () => dispatch(closeAlert()),
                };
                dispatch(openAlert(obj));
                return;
              }
            }else{
              const obj = {
                TEXT: "연락처를 입력해주세요",
                submitEventHandler: () => dispatch(closeAlert()),
              };
              dispatch(openAlert(obj));
              return;
            }
           
          }else{
              /* 전화번호 확인 모달창 */
              const obj = {
                TEXT: " 문의 유형을 선택해주세요",
                submitEventHandler: () => dispatch(closeAlert()),
              };
              dispatch(openAlert(obj));
              return;
          } 
  }



  return (
    <div>
          
          <div className='directquestion_headline'>
                <div className='directquestion_header'>궁금한 사항이 있으시면 문의주세요</div>
                <div className='directquestion_header1'></div>
                <div className='directquestion_header'>최대한 빠르게 답변드릴 수 있도록 노력하겠습니다.</div>
          </div>

          <div className='directquestion_category'>
            <div className='directquestion_category_head'> <div className='dq_fontblack'>문의유형</div></div>

            <div> 
              <select className='directquestion_category_body'  onChange={handleChangeSelect} value={Selected}>
                <option value="">문의 유형을 선택해주세요</option>
                <option value='CD1103'>회원가입/탈퇴, 정보변경</option>
                <option value='CD1104'>불편/개선</option>
                <option value='CD1105'>오류 문의</option>
                <option value='CD1106'>기타 문의</option>
                {/* <option value='CD1107'>항목5</option> */}
            </select> 
            </div>
          </div>

          <div className='directquestion_name'>
          <div className='directquestion_name_head'>
            <div className='dq_fontblack'>성함(회사명)</div></div>
            <div className='dq_placeholder'>
              <input type='text' value={name} placeholder='성함 또는 회사명' className='dq_input_name' onChange={handlename}></input>
            </div>
          </div>

          <div className='directquestion_phonenumber'>
          <div className='directquestion_phonenumber_head'>
            <div className='dq_fontblack'>연락처&nbsp;</div><div className='dq_fontorange'>(필수)</div>
          </div>
            <div className='dq_placeholder'>
            <input type='text' value={phonenumber} placeholder='010-1234-1234' className='dq_input_phonenumber'onChange={handlephonenumber}></input>
            </div>
          </div>

          <div className='directquestion_email'>
          <div className='directquestion_email_head'>
            <div className='dq_fontblack'>이메일&nbsp;</div><div className='dq_fontorange'>(필수)</div>
          </div>
            <div className='dq_placeholder'>
            <input type='text' value={emailaddress} placeholder='abc@pebble.com' className='dq_input_email'onChange={handleemailaddress}></input>
            </div>
          </div>

          <div className='directquestion_title'>
          <div className='directquestion_title_head'>
            <div className='dq_fontblack'>제&nbsp;&nbsp;&nbsp;&nbsp;목&nbsp;</div><div className='dq_fontorange'>(필수)</div>
          </div>
            <div className='dq_placeholder'>
            <input type='text' value={title} placeholder='글 제목' className='dq_input_title'onChange={handletitle}></input>
            </div>
          </div>

          <div className='directquestion_content'>
          <div className='directquestion_content_head'>
          <div className='dq_fontblack'>문의 내용&nbsp;</div><div className='dq_fontorange'>(필수)</div>
          </div>
            <div>
            <textarea type='text' value={content} placeholder='오류, 개선사항 등' className='dq_input_content' onChange={handlecontent}/>
            </div>
          </div>

          <div className='directquestion_file'>
          <div className='directquestion_file_head'>
            <div className='dq_fontblack'>파일 첨부</div> 
            </div>
            <div className='dq_input_file'>
            <div className='dq_input_fileinfo'>
            {Array.isArray(directquestionFile) && directquestionFile.length > 0 ? (
              <div className="dq_fileListscrollbar">
                  {directquestionFile.map(data => (
                    <div key={data.name} className="dq_img_test">
                      <font
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          marginRight: "5px",
                          marginLeft:"20px",
                          font: "normal normal normal 14px/18px Spoqa Han Sans Neo",
                          color: "#3E3E4F",
                        }}
                      >
                        {data.name}
                      </font>
                      <img
                        className="cursor"
                        src="/img/icon/schedule/remove.png"
                        alt="remove"
                        style={{
                          verticalAlign: "top",
                          float: "right",
                          marginRight: "8px",
                        }}
                        onClick={() => onRemoveFile(data)}
                      />
                    </div>
                  ))}
              
              </div>
            ) :(
              <div className="dq_inputFileplaceholder">
              10MB이내 파일만 가능합니다.
              </div>
            )
            }

              </div> 
            <div className='dq_inputfilebtn'>
            <label htmlFor="inputFile" className='suggestionimagebtn cursor'>등록</label>
            <input type='file' 
              id="inputFile" 
              className='suggestioninputfilemain'
              placeholder='등록'
              // accept="image/png, image/jpg, image/bmp, image/gif" 
              style={{display:"none"}}
              onChange={onChangeFile}
              name="file"
              multiple />
            </div>



            </div>
          </div>

        <div className='dq_underline'>
          
        </div>
        <div className='directquestion_footer'>
          <li>내용 본문과 이미지에 고객님의 개인정보(주민번호 등)가 포함되지 않도록 주의해주세요.</li>
        </div>
        <div className='directquestion_button_body'>
        <div className='directquestion_button cursor' onClick={()=>onClicksubmit(phonenumber,emailaddress,content,name,title,Selected)}>
              보내기
        </div>
  
        </div>
        <SupportPopupmain
                open = {showModal}
                close = {closeModal}
            >
                </SupportPopupmain>

    </div>
  )
}

export default Supdirectquestion