import React from "react";
import LicenseEmpty from "./licenseEmpty";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
/**
 * Version : 1.0
 * 파일명 : licenseList.js
 * 작성일자 : 2021-11-15
 * 작성자 : 권도훈
 * 설명 : 사용자 회의이용권 리스트
 * 수정일자 : 2022-05-04
 * 수정자 : 강연승
 * 수정내역 : 그룹 컬러 수정
 */
function LicenseList(props) {
  const list = props.res.data.read();

  if (list !== undefined) {
    props.setLicenseList(list);
  }
  /**
   * TRANSLATION
   */
  const { t } = useTranslation();
  /**
   * REDUX
   */
  const GROUP_STATE = useSelector(state => state.groupInfoReducer);
  /**
   * history
   */
  const history = useHistory();
  //회의이용권 코드 포맷
  const licenseFormat = license => {
    if (license !== undefined) {
      const licenseArr = license.split("-");
      return licenseArr[0].replace(/(.{6})/g, "$1-");
    } else {
      return;
    }
  };
  //날짜 포맷
  const dateFormat = date => {
    const dateArr = date.split("-");
    return dateArr[1] + "/" + dateArr[2];
  };
  //그룹색상
  const groupColor = color => {
    switch (color) {
      case "red":
        return "#de8799";
      case "orange":
        return "#d78e71";
      case "yellow":
        return "#cdb14c";
      case "bean":
        return "#97C12A";
      case "green":
        return "#64d483";
      case "mint":
        return "#6cd6bf";
      case "pine":
        return "#75BDD9";
      case "pink":
        return "#cb86dd";
      case "purple":
        return "#a994e1";
      case "black":
        return "#3b3f4c";
      default:
        return "#2B4AC4";
    }
  };

  return (
    <>
      {list.length > 0 ? (
        list.map((data, index) => (
          <tr
            className={
              data.NEW_LICENSE === "Y"
                ? "pf-license-table-tr tbody new"
                : "pf-license-table-tr tbody"
            }
            key={"license_" + index}
          >
            <td />

            <td className="pf-license-table-font center">
              <font
                className={
                  data.SUBSCRIBE_YN === "N" ? "pf-license-table-font red" : ""
                }
              >
                D-{data.ENDDATE_COUNT}
              </font>
            </td>

            <td>
              {data.LICENSE_TYPE === "PD0103" ? (
                <font className="pf-license-table-font free center">Free</font>
              ) : (
                <></>
              )}
              {data.NEW_LICENSE === "Y" ? (
                <font className="pf-license-table-font new center">New!</font>
              ) : (
                <></>
              )}
              {data.SUBSCRIBE_YN === "N" ? (
                <font className="pf-license-table-font unsubscribe center">
                  구독 취소
                </font>
              ) : (
                <></>
              )}
            </td>

            <td className="pf-license-table-font center bold">
              {licenseFormat(data.LICENSE_CODE)}
            </td>

            <td />

            <td className="pf-license-table-font center bold">
              {data.SCHEDULE_COUNT}
            </td>

            <td />

            <td
              className="pf-license-table-font center"
              style={{
                color:
                  data.GROUP_NUM === ""
                    ? "#9B9FAF"
                    : groupColor(data.GROUP_COLOR),
                cursor:
                  data.GROUP_NUM === "" || GROUP_STATE.AUTH_CD === "CD0302"
                    ? "default"
                    : "pointer",
                textAlign: "left",
              }}
              onClick={() => {
                if (GROUP_STATE.AUTH_CD !== "CD0302" && data.GROUP_NUM !== "") {
                  history.push({
                    pathname: "/app/group/groupMain/" + data.GROUP_NUM,
                  });
                }
              }}
            >
              {data.GROUP_NUM === ""
                ? t("my.profile.txt.noAllocate")
                : data.GROUP_NM}
            </td>

            <td className="pf-license-table-font center">
              {data.SUBSCRIBE_YN === "N" ? (
                <div className="pf-license-table-unsubscribe">
                  <img src="/img/icon/payment/unsubscribe.png" alt="" />
                  <font className="pf-license-table-font unsubscribe center">
                    {dateFormat(data.EXPIRED_DT)}까지 이용 가능
                  </font>
                </div>
              ) : (
                <></>
              )}
            </td>

            <td>
              {GROUP_STATE.AUTH_CD !== "CD0302" ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src="/img/icon/payment/management.png"
                    alt=""
                    style={{
                      marginRight: "8px",
                      marginTop: "2px",
                    }}
                  />

                  <font
                    className="cursor"
                    style={{
                      color: "#84889B",
                      fontWeight: "bold",
                    }}
                    onClick={() => {
                      props.setSelectLicense(data);
                      props.setShowLicenseSettingPopup(true);
                    }}
                  >
                    이용권 관리
                  </font>
                </div>
              ) : (
                <></>
              )}
            </td>
            <td />
          </tr>
        ))
      ) : (
        <LicenseEmpty />
      )}
    </>
  );
}

export default LicenseList;
