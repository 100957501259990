import React from "react";
import { requestFocusCalendar } from "actions/index";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
/**
 * Version : 1.0
 * 파일명 : InviteScheduleAlram.js
 * 작성일자 : 2021-12-16
 * 작성자 : 권도훈
 * 설명 : 회의초대 알람
 * 수정일자 : 2022-06-09
 * 수정자 : 강연승
 * 수정내역 : offset 적용
 */
function InviteScheduleAlram(props) {
  /**
   * HISTORY
   */
  const history = useHistory();
  /**
   * DISPATCH
   */
  const dispatch = useDispatch();
  const userInfo = useSelector(state => state.userInfoReducer);
  /**
   * 내용 포맷
   */
  const contentConvert = data => {
    const split = data.split("%SPLIT%");
    console.log("[ " + split[0] + " ] " + split[1] + " ~ " + split[2]);
    return (
      <div>
        <div>
          [ {split[0]} ]{" "}
          {new Date(
            new Date(split[1]) * 1 + 3600000 * parseFloat(userInfo.OFFSET)
          )
            .toLocaleString()
            .slice(0, -3)}{" "}
          ~{" "}
          {new Date(
            new Date(split[2]) * 1 + 3600000 * parseFloat(userInfo.OFFSET)
          )
            .toLocaleString()
            .slice(0, -3)}
        </div>
      </div>
    );
  };
  return (
    <div
      className="alarm_Item cursor"
      key={props.data.ALRAM_NUM}
      onClick={() => {
        const split = props.data.ALRAM_CONTENT.split("%SPLIT%")[1].split(" ");
        const obj = {
          START_DATE: split[0],
        };
        dispatch(requestFocusCalendar(obj));
        history.push("/app/home");
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "24px",
          color: props.data.READ_YN === "Y" ? "grey" : "blue",
        }}
      >
        <div className="font-bold">초대장 : 회의</div>

        <div
          style={{
            fontSize: "14px",
          }}
        >
          {props.date}
        </div>
      </div>

      <div
        style={{
          marginTop: "24px",
          marginBottom: "24px",
          color: props.data.READ_YN === "Y" ? "grey" : "black",
        }}
        className="m-0"
      >
        {contentConvert(props.data.ALRAM_CONTENT)}
      </div>
    </div>
  );
}

export default InviteScheduleAlram;
