/**
 * Version : 1.0
 * 작성일자 : 2022-01-26
 * 작성자 : 강연승
 * 설명 : 라이센스 갯수 정보
 */
const initalState = {
  GROUP_YN: "",
  FREE_CNT: "",
  PAY_CNT: "",
};

const licenseCntInfo = (state = initalState, action) => {
  switch (action.type) {
    case "licenseCntInfo/UPDATE":
      return {
        GROUP_YN: action.DATA.GROUP_YN,
        FREE_CNT: action.DATA.FREE_CNT,
        PAY_CNT: action.DATA.PAY_CNT,
      };
    default:
      return state;
  }
};

export default licenseCntInfo;
