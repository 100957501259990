import React, { useCallback, useState } from "react";
import ProfileBtn from "component/tag/btn/common/profileBtn";
import GroupProfileBtn from "component/tag/btn/common/groupProfileBtn";
import MsgBtn from "component/tag/btn/common/msgBtn";
import { useHistory } from "react-router";
import axios from "axios";
import Alert from "component/tag/modal/alert";
import { Cookies } from "react-cookie";
import Alarmlistpopup from "component/tag/popup/common/alarmlistpopup";
import { useDispatch, useSelector } from "react-redux";
import { updatePagingInfo } from "actions/index";
import { useTranslation } from "react-i18next";
/**
 * Version : 1.0
 * 파일명 :
 * 작성일자 : 2021-10-05
 * 작성자 : 권도훈
 * 설명 :
 * 수정일자 : 2022-05-10
 * 수정자 : 강연승
 * 수정내역 : 그룹 버튼 마진 추가
 */
function Header() {
  //cookie
  const domain = process.env.REACT_APP_COOKIE_DOMAIN;
  const refreshNm = process.env.REACT_APP_COOKIE_REFRESH;
  const authNm = process.env.REACT_APP_COOKIE_AUTH;
  const loginTypeNm = process.env.REACT_APP_COOKIE_TYPE;

  const cookies = new Cookies();
  /**
   * 언어변환
   */
  const { t } = useTranslation();
  /* 그룹 카테고리 코드 변환 */
  const groupCategoryConvert = ctgryCD => {
    switch (ctgryCD) {
      // case "CD0401":
      //   return "company";
      case "CD0402":
        return "company";
      case "CD0403":
        return "school";
      case "CD0404":
        return "private_institute";
      case "CD0405":
        return "university";
      case "CD0406":
        return "social";
      default:
        return "company";
    }
  };
  /**
   * history
   */
  const history = useHistory();
  /**
   * dispatch
   */
  const dispatch = useDispatch();
  /**
   * 글로벌 스테이트
   */
  const groupInfoState = useSelector(state => state.groupInfoReducer);
  const PAGINIG_STATE = useSelector(state => state.pagingInfoReducer);

  /**
   * 알림창 설정
   */
  const [alertProps, setAlertProps] = useState({
    text: "",
    isShow: false,
    cancelBtnDisplay: false,
    okBtnDisplay: true,
    okBtnEventHandler: function () { },
    cancleBtnEventHandler: function () {
      closeAlertEvent();
    },
  });
  /**
   * 알림창 닫기
   */
  const closeAlertEvent = useCallback(() => {
    setAlertProps({
      ...alertProps,
      isShow: false,
    });
  }, [alertProps]);
  /**
   * 로그아웃
   */
  const goLogout = () => {
    setAlertProps({
      ...alertProps,
      text: "SoBridge에서 로그아웃하시겠습니까?",
      isShow: true,
      cancelBtnDisplay: true,
      okBtnEventHandler: function () {
        closeAlertEvent();
        axios.post("/user/logout", null).then(res => {
          if (res.status === 200) {
            cookies.remove(refreshNm, {
              path: "/",
              domain: domain,
            });

            cookies.remove(authNm, {
              path: "/",
              domain: domain,
            });

            cookies.remove(loginTypeNm, {
              path: "/",
              domain: domain,
            });

            window.location.href = "/";
          }
        });
      },
      cancleBtnEventHandler: function () {
        closeAlertEvent();
      },
    });
  };
  const licenseIcon = color => {
    switch (color) {
      case "red":
        return "/img/icon/profile/license_icon_red.png";
      case "orange":
        return "/img/icon/profile/license_icon_orange.png";
      case "yellow":
        return "/img/icon/profile/license_icon_yellow.png";
      case "bean":
        return "/img/icon/profile/license_icon_bean.png";
      case "green":
        return "/img/icon/profile/license_icon_green.png";
      case "mint":
        return "/img/icon/profile/license_icon_mint.png";
      case "pine":
        return "/img/icon/profile/license_icon_pine.png";
      case "pink":
        return "/img/icon/profile/license_icon_pink.png";
      case "purple":
        return "/img/icon/profile/license_icon_purple.png";
      case "black":
        return "/img/icon/profile/license_icon_black.png";
      default:
        return "/img/icon/profile/license_icon.png";
    }
  };
  const hostIcon = color => {
    switch (color) {
      case "red":
        return "/img/icon/profile/host_icon_red.png";
      case "orange":
        return "/img/icon/profile/host_icon_orange.png";
      case "yellow":
        return "/img/icon/profile/host_icon_yellow.png";
      case "bean":
        return "/img/icon/profile/host_icon_bean.png";
      case "green":
        return "/img/icon/profile/host_icon_green.png";
      case "mint":
        return "/img/icon/profile/host_icon_mint.png";
      case "pine":
        return "/img/icon/profile/host_icon_pine.png";
      case "pink":
        return "/img/icon/profile/host_icon_pink.png";
      case "purple":
        return "/img/icon/profile/host_icon_purple.png";
      case "black":
        return "/img/icon/profile/host_icon_black.png";
      default:
        return "/img/icon/profile/host_icon.png";
    }
  };
  return (
    // <div className="h-23 relative border-b-navy">
    <div
      style={{
        height: "95px",
        borderBottom: "1px solid #2b4ac433",
      }}
    >
      <Alert alertProps={alertProps} />

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "0 24px",
          height: "100%",
        }}
      >
        {groupInfoState.GROUP_NUM !== null &&
          groupInfoState.GROUP_NUM !== undefined &&
          groupInfoState.GROUP_NUM !== "" ? (
          <div className="Header_groupProfileBtnArea">
            <button
              className="Header_UserBtn bg-white font-blue border-none border-radius-25 cursor font-bold"
              onClick={() =>
                history.push({
                  pathname:
                    "/app/group/groupProfile/" + groupInfoState.GROUP_NUM,
                  state: {
                    viewType: "LICENSE",
                  },
                })
              }
            >
              <GroupProfileBtn />
            </button>
            <button
              className={
                "cursor Header_groupLicenseBtn_" + groupInfoState.GROUP_COLOR
              }
              onClick={() =>
                history.push({
                  pathname:
                    "/app/group/groupProfile/" + groupInfoState.GROUP_NUM,
                  state: {
                    viewType: "LICENSE",
                  },
                })
              }
            >
              <img
                src={licenseIcon(groupInfoState.GROUP_COLOR)}
                alt="license"
                style={{ float: "left" }}
              />
              <div style={{ float: "left", marginLeft: "8px" }}>
                {t(
                  "group.thema." +
                  groupCategoryConvert(groupInfoState.GROUP_CATEGORY) +
                  ".meetingPass"
                )}
              </div>
              <div
                style={{
                  float: "right",
                  marginLeft: "20px",
                  color:
                    groupInfoState.LICENSE_CNT === 0 ? "#D7263D" : "#3D9C22",
                }}
              >
                {groupInfoState.LICENSE_CNT}
              </div>
              {groupInfoState.D_DAY < 11 ? (
                <div className="licenseDday">D-{groupInfoState.D_DAY}</div>
              ) : null}
            </button>
            <button
              className={
                "cursor Header_groupMeetingHostBtn_" +
                groupInfoState.GROUP_COLOR
              }
              onClick={() =>
                history.push({
                  pathname:
                    "/app/group/groupProfile/" + groupInfoState.GROUP_NUM,
                  state: {
                    viewType: "MEMBER",
                  },
                })
              }
            >
              <img
                src={hostIcon(groupInfoState.GROUP_COLOR)}
                alt="host"
                style={{ float: "left" }}
              />
              <div style={{ float: "left", marginLeft: "8px" }}>
                {t(
                  "group.thema." +
                  groupCategoryConvert(groupInfoState.GROUP_CATEGORY) +
                  ".meetingHost"
                )}
              </div>
              <div
                style={{
                  float: "right",
                  marginLeft: "20px",
                  color: groupInfoState.HOST_CNT === 0 ? "#D7263D" : "#3D9C22",
                }}
              >
                {groupInfoState.HOST_CNT}
              </div>
            </button>
          </div>
        ) : (
          <div></div>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              marginRight: "24px",
            }}
          >
            <MsgBtn invisible={true} />
            <Alarmlistpopup />
          </div>

          <div
            style={{
              marginRight: "24px",
            }}
          >
            <button
              className="Header_UserBtn bg-white font-blue border-none border-radius-25 cursor"
              onClick={() => {
                const currentURL = new URL(window.location.href).pathname;

                if (currentURL === "/app/home/profile") {
                  const paging_obj = {
                    ROW_COUNT: PAGINIG_STATE.ROW_COUNT,
                    PAGE_COUNT: 0,
                    TOTAL: PAGINIG_STATE.TOTAL,
                  };
                  dispatch(updatePagingInfo(paging_obj));
                } else {
                  const paging_obj = {
                    ROW_COUNT: 10,
                    PAGE_COUNT: 0,
                    TOTAL: 0,
                  };
                  dispatch(updatePagingInfo(paging_obj));
                }

                history.push({
                  pathname: "/app/home/profile",
                });
              }}
            >
              <ProfileBtn />
            </button>
          </div>

          <div
            className="cursor"
            style={{
              background: "#EE6C4D",
              boxShadow: "0px 6px 15px #96969629",
              width: "48px",
              height: "48px",
              borderRadius: "999px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => goLogout()}
          >
            <img src="/img/icon/common/logout.png" alt="LO" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
