import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateGroupInfo } from "actions/index";
import RecordCardList from 'component/tag/table/record/recordCardList';
import RecordList from 'component/tag/table/record/recordList';
/**
 * Version : 1.0
 * 파일명 : Record.js
 * 작성일자 : 2021-10-05
 * 작성자 : 권도훈
 * 설명 : 개인 녹화파일
 * 수정일자 : 2022-01-17
 * 수정자 : 권도훈
 * 수정내역 : 회의 영상 페이징 설정
 */
function Record() {
    /**
     * DISPATCH
     */
    const dispatch = useDispatch();
    /**
     * REDUCER
     */
    const RECORD_STATE = useSelector(state => state.recordInfoReducer);
    /**
     * SET DEFAULT STATE
     */
    useEffect(() => {
        const config = {
            GROUP_YN: "N",
            GROUP_NUM: "",
            AUTH_CD: ""
        }
        dispatch(updateGroupInfo(config));
    }, [dispatch]);
    /**
     * TAG
     */
    return (
        <div
            style={{
                height: "902px",
                width: "1484px",
            }}
        >
            {
                RECORD_STATE.VIEW_TYPE === "CARD"
                    ? <RecordCardList />
                    : <RecordList />
            }
        </div>
    )
}

export default Record
