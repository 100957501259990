import React from 'react'
/**
 * Version : 1.0
 * 파일명 : EmptyAlram.js
 * 작성일자 : 2021-12-16
 * 작성자 : 권도훈
 * 설명 : 알람 없음
 * 수정일자 : 
 * 수정자 : 
 * 수정내역 :
*/
function EmptyAlram() {
    return (
        <div
            style={{
                textAlign: "center",
                fontSize: "16px",
                fontWeight: "bold",
                marginTop: "23px",
                color: "#84889B"
            }}
        >
            알림 내용이 없습니다.
        </div>
    )
}

export default EmptyAlram
