import React from "react";
import ReserveTotal from "component/tag/div/group/dashboard/reserveTotal";
import MeetingEnd from "component/tag/div/group/dashboard/meetingEnd";
import ReserveTime from "component/tag/div/group/dashboard/reserveTime";
import RealMeetingTime from "component/tag/div/group/dashboard/realMeetingTime";
import AvgMeetingTime from "component/tag/div/group/dashboard/avgMeetingTime";
import AccCntArea from "component/tag/div/group/dashboard/accCntArea";
import DoughnutChart from "component/tag/div/group/dashboard/doughnutChart";
import MeetingCntBar from "component/tag/div/group/dashboard/meetingCntBar";
import MeetingHostResChart from "component/tag/div/group/dashboard/meetingHostResChart";
import ResMeetingList from "component/tag/div/group/dashboard/resMeetingList";
import EndMeetingList from "component/tag/div/group/dashboard/endMeetingList";
import { useSelector } from "react-redux";
/**
 * Version : 1.0
 * 파일명 : DashboardContents.js
 * 작성일자 : 2022-02-16
 * 작성자 : 강연승
 * 설명 : 그룹 대시보드 컨텐츠 div
 * 수정일자 : 2022-03-04
 * 수정자 : 강연승
 * 수정내역 :
 */
function DashboardContents() {
  /**
   * REDUCER
   */
  const REDUCER_STATE = useSelector(state => state.groupInfoReducer);
  return (
    <div className="dashboardBackground scrollbar">
      <div
        className="dashboardMeetingItemGrid"
        style={{
          display: "grid",
          justifyContent: "space-between",
          gridTemplateColumns: "repeat(auto-fill, minmax(259px,0fr))",
        }}
      >
        <ReserveTotal />
        <MeetingEnd />
        <ReserveTime />
        <RealMeetingTime />
        <AvgMeetingTime avg="45" max="182" min="4" />
      </div>

      {REDUCER_STATE.GROUP_YN === "Y" ? (
        <>
          <div className="dashboardDoughnutArea">
            <AccCntArea />
            <DoughnutChart />
          </div>

          <div className="dashbaordBarArea">
            <MeetingCntBar />
            <MeetingHostResChart />
          </div>
        </>
      ) : (
        <>
          <div className="dashbaordBarArea">
            <AccCntArea />
            <MeetingCntBar />
          </div>
        </>
      )}

      <div className="dashbaordListArea">
        <ResMeetingList />
        <EndMeetingList />
      </div>
    </div>
  );
}

export default DashboardContents;
