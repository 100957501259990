import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Loading from "component/tag/loading/loading";
/**
 * Version : 1.0
 * 파일명 : ReserveTotal.js
 * 작성일자 : 2022-02-23
 * 작성자 : 강연승
 * 설명 : 대시보드 아이템 - 평균 회의 진행 시간
 * 수정일자 : 2022-03-04
 * 수정자 : 강연승
 * 수정내역 : 대시보드 추가
 */
function AvgMeetingTime() {
  /**
   * REDUCER
   */
  const REDUCER_STATE = useSelector(state => state.groupInfoReducer);
  /**
   * 언어변환
   */
  const { t } = useTranslation();
  /**
   * STATE
   */
  const [loading, setLoading] = useState(false);
  const [durationAVG, setDurationAVG] = useState(0);
  const [durationMAX, setDurationMAX] = useState(0);
  const [durationMIN, setDurationMIN] = useState(0);
  /**
   * 평균 회의 진행시간 조회
   */
  const getMeetingDurationAVG = useCallback(() => {
    setLoading(true);
    let param = {};
    if (REDUCER_STATE.GROUP_YN === "Y") {
      param = {
        GROUP_YN: REDUCER_STATE.GROUP_YN,
        GROUP_NUM: REDUCER_STATE.GROUP_NUM,
      };
    } else if (REDUCER_STATE.GROUP_YN === "N") {
      param = {
        GROUP_YN: REDUCER_STATE.GROUP_YN,
      };
    }
    axios
      .get("/schedule/dashboard/durationAVG", {
        params: param,
      })
      .then(res => {
        if (res.status === 200) {
          setDurationAVG(res.data.DURATION_AVG);
          setDurationMAX(res.data.DURATION_MAX);
          setDurationMIN(res.data.DURATION_MIN);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [REDUCER_STATE.GROUP_NUM, REDUCER_STATE.GROUP_YN]);
  /**
   * useEffect
   */
  useEffect(() => {
    getMeetingDurationAVG();
  }, [getMeetingDurationAVG]);
  return (
    <div className="dashboardMeetingItem">
      <div style={{ height: "19px" }}>
        <img src="/img/icon/dashboard/average.png" className="dashboardIcon" />
        <font className="title">
          {t("group.dashboard.txt.avgTime." + REDUCER_STATE.GROUP_CATEGORY)}
        </font>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div
          style={{
            display: "flex",
            marginTop: "17px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
              paddingLeft: "15px",
              paddingRight: "19px",
              borderRight: "1px solid #c0c0c0",
            }}
          >
            <div className="avgMeetingDuration">{durationAVG}</div>
            <div className="hourText">{t("time.min")}</div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingLeft: "16px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div className="durationMax">{durationMAX}</div>
              <div className="durationMaxText">{t("common.txt.max")}</div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginLeft: "20px",
              }}
            >
              <div className="durationMin">{durationMIN}</div>
              <div className="durationMinText">{t("common.txt.min")}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AvgMeetingTime;
