import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactModal from "react-modal";
import AddressPopup from "component/tag/popup/common/addressPopup";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

/**
 * Version : 1.0
 * 파일명 : groupMemberInvitePopup.js
 * 작성일자 : 2021-11-22
 * 작성자 : 강연승
 * 설명 : 그룹 개설자 초대 팝업
 * 수정일자 : 2022-04-29
 * 수정자 : 권도훈
 * 수정내역 : 미팅호스트 목록 수정
 */

function GroupMemberInvitePopup(props) {
  /**
   * 언어변환
   */
  const { t } = useTranslation();
  /* 그룹 카테고리 코드 변환 */
  const groupCategoryConvert = ctgryCD => {
    switch (ctgryCD) {
      // case "CD0401":
      //   return "default";
      case "CD0402":
        return "company";
      case "CD0403":
        return "school";
      case "CD0404":
        return "private_institute";
      case "CD0405":
        return "university";
      case "CD0406":
        return "social";
      default:
        return "company";
    }
  };
  /**
   * REDUX
   */
  const groupInfoState = useSelector(state => state.groupInfoReducer);
  const userState = useSelector(state => state.userInfoReducer);
  const [members, setMembers] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);

  const [inputs, setInputs] = useState({
    USER_NM: "",
    EMAIL: "",
  });
  const { USER_NM, EMAIL } = inputs;
  const onChangeMember = e => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const handleKeyPress = ({ key }) => {
    if (key === "Enter") {
      onCreate();
    }
  };
  const validationEmail = value => {
    var emailType =
      /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
    return emailType.test(value);
  };
  const onCreate = () => {
    if (EMAIL === "" || validationEmail(EMAIL) === false) {
      return;
    } else {
      for (let i = 0; i < members.length; i++) {
        if (EMAIL === members[i].EMAIL) {
          return;
        }
      }
    }
    if (Array.isArray(addressAll) && addressAll.length > 0) {
      for (let i = 0; i < addressAll.length; i++) {
        if (EMAIL === addressAll[i].ADR_EMAIL) {
          const member = {
            USER_NM: addressAll[i].ADR_NM,
            EMAIL: addressAll[i].ADR_EMAIL,
          };
          setMembers(members.concat(member));
          break;
        } else {
          const member = {
            USER_NM,
            EMAIL,
          };
          setMembers(members.concat(member));
        }
      }
    } else {
      const member = {
        USER_NM,
        EMAIL,
      };
      setMembers(members.concat(member));
    }

    setInputs({
      USER_NM: "",
      EMAIL: "",
    });
  };

  const onRemove = EMAIL => {
    setMembers(members.filter(member => member.EMAIL !== EMAIL));
  };

  const clickAddressItem = (nm, email) => {
    for (let i = 0; i < members.length; i++) {
      if (email === members[i].EMAIL) {
        alert(t("schedule.msg.alreadyParticipant"));
        return;
      }
    }
    setMembers(members.concat({ USER_NM: nm, EMAIL: email }));
    setInputs({
      USER_NM: "",
      EMAIL: "",
    });
  };
  const dialogClose = () => {
    props.close();
    if (isSuccess) {
      setIsSuccess(false);
    }
    if (isActive) {
      setIsActive(false);
    }
    if (addressPopup) {
      setAddressPopup(false);
    }
    setInputs({
      USER_NM: "",
      EMAIL: "",
    });
    setMembers([]);
  };

  const [isActive, setIsActive] = useState(false);
  const activeBtn = e => {
    if (members.length > 0) {
      setIsActive(true);
    } else if (members.length <= 0) {
      setIsActive(false);
    }
  };
  useEffect(() => {
    activeBtn();
  }, [members]);

  const [addressPopup, setAddressPopup] = useState(false);
  const handleAddress = type => {
    if (type === true) {
      setAddressPopup(true);
      getAddressAll();
    } else if (type === false) {
      setAddressPopup(false);
    }
  };
  const [addressAll, setAddressAll] = useState([]);
  const getAddressAll = () => {
    const url = "/address/GroupInviteAddress";
    const params = {
      params: {
        GROUP_NUM: groupInfoState.GROUP_NUM,
      },
    };
    axios
      .get(url, params)
      .then(res => {
        if (res.status === 200) {
          setAddressAll(res.data);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const clickBackgound = () => {
    if (addressPopup) {
      setAddressPopup(false);
    }
  };

  const inviteMember = () => {
    if (members.length <= 0) {
      return;
    }

    axios
      .post("/group/requestInviteGroup", {
        USER_NM: userState.NAME,
        GROUP_NM: groupInfoState.GROUP_NM,
        GROUP_NUM: groupInfoState.GROUP_NUM,
        participants: members,
      })
      .then(response => {
        if (response.status === 200) {
          setIsSuccess(true);
          props.setRefresh(true);
        } else {
          alert(
            t("group.profile.meetingHostSetting.failSendInvite", {
              MEETING_HOST: t(
                "group.thema." +
                  groupCategoryConvert(groupInfoState.GROUP_CATEGORY) +
                  ".meetingHost"
              ),
            })
          );
        }
      })
      .catch(error => {
        alert(
          t("group.profile.meetingHostSetting.errorSendInvite", {
            MEETING_HOST: t(
              "group.thema." +
                groupCategoryConvert(groupInfoState.GROUP_CATEGORY) +
                ".meetingHost"
            ),
          })
        );
        console.log(error);
      });
  };

  return (
    <>
      <ReactModal
        isOpen={props.open} //모달 Open(Boolean)
        className="groupMeetingHostInvitePopup" //모달 ClassName
        overlayClassName="groupMeetingHostInvitePopup_Overlay" //모달오버레이 ClassName
        onRequestClose={dialogClose}
        shouldCloseOnEsc={true}
        animationType="fade"
        transparent={true}
        closeTimeoutMS={200}
      >
        <div
          className="groupMeetingHostInvitePopup_background"
          onClick={clickBackgound}
        >
          {!isSuccess ? (
            <>
              <div className="groupMeetingHostInvitePopup_header">
                <div style={{ display: "inline-block", paddingLeft: "48px" }}>
                  {t("group.profile.meetingHostSetting.hostInviteTitle", {
                    MEETING_HOST: t(
                      "group.thema." +
                        groupCategoryConvert(groupInfoState.GROUP_CATEGORY) +
                        ".meetingHost"
                    ),
                  })}
                </div>
                <img
                  className="cursor"
                  src="/img/icon/group/close.png"
                  alt="close"
                  onClick={dialogClose}
                  style={{
                    display: "inline-block",
                    float: "right",
                    marginRight: "24px",
                  }}
                />
              </div>

              <div
                style={{
                  marginTop: "24px",
                  marginBottom: "24px",
                  color: "#3E3E4F",
                  font: "normal normal bold 12px/18px Spoqa Han Sans Neo",
                }}
              >
                <div>
                  {t("group.profile.meetingHostSetting.hostInviteText1", {
                    GROUP_NM: groupInfoState.GROUP_NM,
                  })}
                </div>
                <div>
                  {t("group.profile.meetingHostSetting.hostInviteText2", {
                    MEETING_HOST: t(
                      "group.thema." +
                        groupCategoryConvert(groupInfoState.GROUP_CATEGORY) +
                        ".meetingHost"
                    ),
                  })}
                </div>
              </div>

              <input
                className="groupInputValue"
                name="EMAIL"
                placeholder={t(
                  "group.profile.meetingHostSetting.hostInvitePlaceholder",
                  {
                    MEETING_HOST: t(
                      "group.thema." +
                        groupCategoryConvert(groupInfoState.GROUP_CATEGORY) +
                        ".meetingHost"
                    ),
                  }
                )}
                onChange={onChangeMember}
                onKeyPress={handleKeyPress}
                onFocus={() => handleAddress(true)}
                value={EMAIL}
                onClick={e => e.stopPropagation()}
                autoComplete="off"
              />

              <AddressPopup
                className="inviteMeetingHost scrollbar"
                open={addressPopup}
                address={addressAll}
                clickAddressItem={clickAddressItem}
                search={inputs.EMAIL}
              />
              <div>
                {members.length > 0 ? (
                  <div
                    style={{
                      marginTop: "8px",
                      marginBottom: "9px",
                      color: "#2B4AC4",
                      font: "normal normal bold 12px/18px Spoqa Han Sans Neo",
                    }}
                  >
                    {t("group.profile.meetingHostSetting.hostListText")}
                  </div>
                ) : null}
                <MemberList
                  // className=""
                  members={members}
                  onRemove={onRemove}
                />
              </div>
              <button
                className={isActive ? "groupCreateBtnActive" : "groupCreateBtn"}
                onClick={inviteMember}
                style={{ marginTop: "32px", marginBottom: "40px" }}
                disabled={!isActive}
              >
                {t("group.profile.meetingHostSetting.sendInvitation")}
              </button>
            </>
          ) : (
            <>
              <div
                style={{
                  color: "#2B4AC4",
                  font: "normal normal bold 24px/32px Spoqa Han Sans Neo",
                  marginTop: "32px",
                }}
              >
                {t("group.profile.meetingHostSetting.inviteResultTitle")}
              </div>
              <img
                src="/img/icon/group/close.png"
                alt="close"
                onClick={dialogClose}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: "24px",
                  right: "24px",
                  marginBottom: "32px",
                }}
              />
              <div style={{ marginTop: "24px", marginBottom: "36px" }}>
                <a
                  style={{
                    color: "#3E3E4F",
                    font: "normal normal bold 12px/18px Spoqa Han Sans Neo",
                  }}
                >
                  {t("group.profile.meetingHostSetting.inviteResultText1", {
                    MEETING_HOST: t(
                      "group.thema." +
                        groupCategoryConvert(groupInfoState.GROUP_CATEGORY) +
                        ".meetingHost"
                    ),
                  })}
                </a>
              </div>
              <img src="/img/icon/profile/invite_newillust.png" alt="invite" />

              <div>
                <div
                  style={{
                    marginTop: "28px",
                    marginBottom: "16px",
                    color: "#2B4AC4",
                    font: "normal normal bold 12px/18px Spoqa Han Sans Neo",
                  }}
                >
                  {t("group.profile.meetingHostSetting.inviteResultText2")}
                </div>
                <div
                  className="scrollbar"
                  style={{
                    font: "normal normal normal 14px/18px Spoqa Han Sans Neo",
                    color: "#3E3E4F",
                    maxHeight: "100px",
                    overflowY: "auto",
                    margin: "0 80px",
                  }}
                >
                  {members.map(data =>
                    data.USER_NM !== null && data.USER_NM !== undefined ? (
                      <div
                        key={data.EMAIL}
                        style={{ height: "18px", padding: "5px 0 9px 0" }}
                      >
                        <a style={{ marginRight: "17px" }}>{data.USER_NM}</a>
                        <a>{data.EMAIL}</a>
                      </div>
                    ) : (
                      <div
                        key={data.EMAIL}
                        style={{
                          height: "18px",
                          padding: "5px 0 9px 0",
                          textAlign: "center",
                        }}
                      >
                        <a>{data.PRTCP_EMAIL}</a>
                      </div>
                    )
                  )}
                </div>
              </div>

              <button
                className="groupCreateBtnActive"
                onClick={dialogClose}
                style={{ marginTop: "32px", marginBottom: "40px" }}
              >
                {t("common.btn.ok")}
              </button>
            </>
          )}
        </div>
      </ReactModal>
    </>
  );
}

function Member({ member, onRemove }) {
  return (
    <li
      style={{
        height: "18px",
        clear: "both",
        font: "normal normal normal 14px/18px Spoqa Han Sans Neo",
        color: "#3E3E4F",
        padding: "5px 0 9px 0",
      }}
    >
      <div
        style={{
          float: "left",
          marginLeft: member.USER_NM !== "" ? "24px" : "42px",
          marginRight: member.USER_NM !== "" ? "17px" : "35px",
        }}
      >
        {member.USER_NM !== null &&
        member.USER_NM !== undefined &&
        member.USER_NM !== "" ? (
          <a>{member.USER_NM}</a>
        ) : (
          <a style={{ textAlign: "center" }}>-</a>
        )}
      </div>
      <div style={{ float: "left" }}>{member.EMAIL}</div>
      <div style={{ float: "right", marginRight: "16px" }}>
        <img
          style={{ cursor: "pointer" }}
          src="/img/icon/group/delete.png"
          alt="delete"
          onClick={() => onRemove(member.EMAIL)}
        />
      </div>
    </li>
  );
}

function MemberList({ members, onRemove }) {
  return (
    <div
      className="scrollbar"
      style={{
        width: "296px",
        margin: "0 auto",
        maxHeight: "100px",
        overflowY: "auto",
      }}
    >
      <ul>
        {members.map(member => (
          <Member member={member} key={member.EMAIL} onRemove={onRemove} />
        ))}
      </ul>
    </div>
  );
}

export default GroupMemberInvitePopup;
