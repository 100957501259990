/**
 * Version : 1.0
 * 작성일자 : 2022-01-18
 * 작성자 : 권도훈
 * 설명 : 알람 스케줄 포커싱 기능
 */
const initalState = {
    IS_FOCUS: false,
    START_DATE: ""
};

const focusCalendarInfo = (state = initalState, action) => {
    switch (action.type) {
        case "focusCalendarInfo/REQUEST_FOCUS":
            return {
                IS_FOCUS: true,
                START_DATE: action.DATA.START_DATE
            };
        case "focusCalendarInfo/RESPONSE_FOCUS":
            return {
                IS_FOCUS: false,
                START_DATE: ""
            };
        default:
            return state;
    }
};

export default focusCalendarInfo;
