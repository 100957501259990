import React from 'react'
/**
 * Version : 1.0
 * 파일명 : Header_noBtn.js
 * 작성일자 : 2022-01-07
 * 작성자 : 권도훈
 * 설명 : 랜딩페이지 Header 버튼 X
*/
function Header_noBtn() {
    return (
        <div
            style={{
                height: "120px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <div
                style={{
                    width: "80%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                }}
            >
                <div
                    className="cursor"
                    onClick={() => window.location.href = "/"}
                >
                    <img src="/img/logo.png" alt="logo_title" />
                </div>
            </div>

        </div>

    )
}

export default Header_noBtn
