import React, { useCallback, useState } from "react";
import ReactModal from "react-modal";
import { useHistory } from "react-router-dom";
import "css/dormantLoginPopUp.css";
import { closeAlert, openAlert } from "actions";
import { useDispatch } from "react-redux";
import DormantsuccessPopUp from "./dormantsuccessPopUp";
import axios from "axios";

function DormantLoginPopUp(props) {
  const [inputmail, setinputmail] = useState("");
  const handleinputmail = e => {
    setinputmail(e.target.value);
  };
  const [start, setstart] = useState("");

  const DormantAuth = useCallback(id => {
    const params = {
      USER_ID: id,
    };
    axios.post("/user/DormantAuth", params).then(res => {
      if (res.status === 200) {
      }
    });
  }, []);

  const history = useHistory();
  const dispatch = useDispatch();

  const closePopup = () => {
    setinputmail("");
    props.close();
    // history.push("")
  };

  console.log(props.ID);

  const sendmail = () => {
    closePopup();
    DormantAuth(props.ID);
    setstart("start");
    openModal();
  };
  const mailfalse = () => {
    const obj = {
      TEXT: "메일 주소가 올바르지 않습니다.",
      submitEventHandler: () => {
        dispatch(closeAlert());
      },
    };
    dispatch(openAlert(obj));
  };

  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <DormantsuccessPopUp open={showModal} state={start} close={closeModal} />

      <ReactModal
        isOpen={props.open} //모달 Open(Boolean)
        className="DormantPopup" //모달 ClassName
        overlayClassName="DormantPopup_Overlay" //모달오버레이 ClassName
        // //  onRequestClose={closePopup}
        //  shouldCloseOnEsc={closePopup}
        animationType="fade"
        transparent={true}
        closeTimeoutMS={200}
      >
        <div
          onClick={() => closePopup()}
          className="cursor"
          style={{ textAlign: "right", marginRight: "10px", marginTop: "10px" }}
        >
          <img
            src="/img/icon/common/close.png"
            alt="닫기"
            style={{ width: "20px", height: "20px" }}
          />
        </div>
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
          <img src="/img/icon/landing/dormant_people.png" alt="이미지" />
        </div>

        <div className="dormant_head_text">
          회원님의 계정은 휴면 상태입니다.
        </div>

        <div className="dormant_middle_text">
          <div>회원님의 계정은 장기간 SoBridge를 이용하지 않아</div>
          <div>휴면상태로 전환되었습니다.</div>
          <div>본인 확인 후 다시 SoBridge를 이용하실 수 있습니다.</div>
        </div>

        {inputmail === props.ID ? (
          <div className="dormant_activeline">
            <input
              type="text"
              placeholder="가입 시 사용했던 이메일을 입력해주세요."
              className="dormant_correct_input"
              onChange={handleinputmail}
              value={inputmail}
            ></input>
            <div
              className="dormant_correct_ID cursor"
              onClick={() => sendmail()}
            >
              본인 인증하기
            </div>
          </div>
        ) : (
          <div className="dormant_activeline">
            <input
              type="text"
              placeholder="가입 시 사용했던 이메일을 입력해주세요."
              className="dormant_input_mail"
              onChange={handleinputmail}
              value={inputmail}
            ></input>
            <div
              className="dormant_Check_ID cursor"
              onClick={() => mailfalse()}
            >
              본인 인증하기
            </div>
          </div>
        )}

        <div className="dormant_end">
          <div className="dormant_end_text">
            SoBridge는 회원님의 개인정보 보호를 위하여 장기간 SoBridge에
          </div>
          <div className="dormant_end_text">
            로그인하지 않은 계정을 휴면계정으로 전환하여 개인정보를 안전하게
          </div>
          <div className="dormant_end_text">
            분리 보관하고 있습니다. 위 수단으로 본인 확인이 되지 않으면 휴면계정
          </div>
          <div className="dormant_end_text">
            해제에 도움을 드리기 어렵습니다.
          </div>
        </div>
      </ReactModal>
    </div>
  );
}

export default DormantLoginPopUp;
