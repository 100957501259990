import React, { useState } from 'react'
/**
 * Version : 1.0
 * 파일명 : fileuploadMultiple.js
 * 작성일자 : 2021-10-25
 * 작성자 : 권도훈
 * 설명 : 멀티 파일 컴포넌트
 * 수정일자 :  2021-10-25
 * 수정자 : 권도훈
 * 수정내역 :
 * props :
*/
function Fileuploader(props) {
    const [base64_File, setbase64_File] = useState([]); // 파일 base64

    const handleChangeFile = (event) => {
        setbase64_File([]);

        for (var i = 0; i < event.target.files.length; i++) {
            if (event.target.files[i].size > 5242880) {
                alert("5MB 이하의 파일을 첨부해주세요.")
                return;
            }

            if (event.target.files[i]) {
                const FileNM = event.target.files[i].name;
                let reader = new FileReader();

                reader.readAsDataURL(event.target.files[i]);

                reader.onloadend = () => {
                    const base64 = reader.result;
                    if (base64) {
                        var base64Sub = base64.toString()
                        var strBase64 = base64Sub.split("base64,");

                        setbase64_File(base64_File =>
                            [
                                ...base64_File,
                                {
                                    FileNM: FileNM,
                                    FileBase64: strBase64[1],
                                }
                            ]
                        );
                    }
                }
            }
        }
    }
    return (
        <>
            <input style={props.inputStyle} type="file" id="file" onChange={handleChangeFile} multiple="multiple" />
            <button className="cursor" style={props.btnStyle} onClick={() => props.onClickEvent(base64_File)}>{props.btnText}</button>
        </>
    )
}

export default Fileuploader
