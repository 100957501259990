import React, { useState, useEffect, useCallback } from "react";
import Button from "@material-ui/core/Button";
import {
  withStyles,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";
import { useSelector } from "react-redux";
/**
 * Version : 1.0
 * 파일명 :
 * 작성일자 : 2021-10-05
 * 작성자 : 권도훈
 * 설명 : 메뉴 토글 버튼
 * 수정일자 : 2022-03-04
 * 수정자 : 강연승
 * 수정내역 : 대시보드 추가후 버튼 위치 수정
 */
function MenuToggleBtn(props) {
  const userinfo = useSelector(state => state.userInfoReducer);
  /**
   * 상태
   */
  const [type, setType] = useState();
  /**
   * bottom 높이 상태
   */
  const [topValue, setTopValue] = useState();

  useEffect(() => {
    setType(props.type);

    if (props.type === "home") {
      setTopValue(
        document.getElementById("root").clientHeight -
          document.getElementById("root").getBoundingClientRect().bottom +
          211
      );
    } else if (props.type === "group") {
      setTopValue(
        document.getElementById("root").clientHeight -
          document.getElementById("root").getBoundingClientRect().bottom +
          535
      );
    } else {
      setTopValue(
        document.getElementById("root").clientHeight -
          document.getElementById("root").getBoundingClientRect().bottom +
          140
      );
    }
  }, [props.type]);

  /**
   * 스크롤 메뉴 이동
   */
  const scrollEvent = useCallback(() => {
    const clientHeight = document.getElementById("root").clientHeight;
    const bottom = document
      .getElementById("root")
      .getBoundingClientRect().bottom;
    const diff = clientHeight - bottom;

    if (diff < 114) {
      if (type === "home") {
        setTopValue(diff + 211);
      } else if (type === "group") {
        setTopValue(diff + 535);
      } else {
        setTopValue(diff + 140);
      }
    } else {
      if (type === "home") {
        setTopValue(313);
      } else if (type === "group") {
        setTopValue(640);
      } else {
        setTopValue(260);
      }
    }
  }, [type]);

  useEffect(() => {
    window.addEventListener("scroll", scrollEvent);
    return () => window.removeEventListener("scroll", scrollEvent);
  }, [scrollEvent]);

  const theme = createTheme({
    palette: {
      primary: blue,
    },
  });

  const LoginButton = withStyles({
    root: {
      textTransform: "none",
      backgroundColor: "#2B4AC4",
      boxShadow: "0px 6px 15px #00000029",
      color: "#FFFFFF",
      font: "normal normal bold 14px/18px Spoqa Han Sans Neo",
      width: "124px",
      height: "40px",
      borderRadius: "10px",
      margin: "16px 20px 20px 20px",
      "&:hover": {
        backgroundColor: "#1c7ed6",
        borderColor: "#1971c2",
        boxShadow: "box-shadow: 0px 6px 15px #00000029",
      },
      "&:active": {
        boxShadow: "box-shadow: 0px 6px 15px #00000029",
        backgroundColor: "#1971c2",
        borderColor: "#1864ab",
      },
    },
  })(Button);

  return (
    <>
      <div
        id="menu_toggleDiv_ID"
        style={{
          top: topValue,
          position: "relative",
          textAlign: "center",
          margin: "32px 16px",
          backgroundColor: "rgba(255, 255, 255, 0.4)",
          borderRadius: "24px",
          width: "164px",
        }}
      >
        <img
          src={
            props.groupYN === "N" ? "/img/group_img.png" : "/img/my_pebble.png"
          }
          // src="/img/group_img.png"
          alt="myImg"
          style={{ marginTop: "20px" }}
        />
        <div
          style={{
            font: "normal normal bold 12px/16px Spoqa Han Sans Neo",
            color: "#2B4AC4",
            marginTop: "16px",
          }}
        >
          {props.groupYN === "N" ? (
            <div>
              사람들과 함께
              <br />
              소통할 수 있습니다.
            </div>
          ) : (
            <div>
              {userinfo.NAME}님의 공간으로
              <br />
              넘어가기
            </div>
          )}
        </div>
        <ThemeProvider theme={theme}>
          <LoginButton onClick={props.onClick}>
            {props.groupYN === "N" ? "Group SoBridge" : "My SoBridge"}
          </LoginButton>
        </ThemeProvider>
      </div>
    </>
  );
}

export default MenuToggleBtn;
// const mapStateToProps = state => {
//   console.log(state);
//   return { USER_NAME: state.USER_NAME }
// }

// export default connect(mapStateToProps)(MenuToggleBtn);
