import React from 'react';
import ReactDOM from 'react-dom';
import Pebble from './component/page/pebble';
import reportWebVitals from './reportWebVitals';
import reducers from './reducers';
import { Provider } from 'react-redux'
import { createStore } from 'redux'
// import { createStore, applyMiddleware } from 'redux'
// import { createLogger } from 'redux-logger';
// import { composeWithDevTools } from 'redux-devtools-extension';
/**
 * Version : 1.0
 * 파일명 : 
 * 작성일자 : 2021-10-05
 * 작성자 : 권도훈
 * 설명 : 메인 index
 * 수정일자 :  2021-12-23
 * 수정자 : 권도훈
 * 수정내역 : redux 적용
*/
// const logger = createLogger();
// const store = createStore(reducers, composeWithDevTools(applyMiddleware(logger)));
const store = createStore(reducers);

ReactDOM.render(
  <Provider store={store}>
    <Pebble />
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
