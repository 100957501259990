import React from "react";
import FileuploaderSingle from "component/tag/file/common/fileuploaderSingle";
import { useTranslation } from "react-i18next";
/**
 * Version : 1.0
 * 파일명 : ImageUploadPopup.js
 * 작성일자 : 2021-12-07
 * 작성자 : 강연승
 * 설명 : 프로필 이미지 업로드 팝업
 * 수정일자 : 2022-05-04
 * 수정자 : 강연승
 * 수정내역 : 그룹 컬러 수정
 */
function ImageUploadPopup(props) {
  /**
   * TRANSLATION
   */
  const { t } = useTranslation();
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div
        style={{
          display: props.isOpen ? "block" : "none",
          zIndex: "80",
          backgroundColor: "#FFFFFF",
          boxShadow: "0px 5px 16px #00000029",
          width: "320px",
          borderRadius: "12px",
          padding: "16px 40px",
          position: "absolute",
          textAlign: "center",
        }}
        onClick={e => e.stopPropagation()}
      >
        <div
          style={{
            font: "normal normal bold 16px/21px Spoqa Han Sans Neo",
            color: "#3E3E4F",
            marginBottom: "8px",
          }}
        >
          {t("my.profile.txt.imgPopupTitle")}
        </div>
        <div
          style={{
            font: "normal normal bold 14px/18px Spoqa Han Sans Neo",
            color: "#3E3E4F",
            marginBottom: "16px",
          }}
        >
          {/* 이미지는 512px 이상 되어야 합니다. */}
        </div>

        {props.image.length === 0 ? (
          <div style={{ marginBottom: "16px" }}>
            <label
              htmlFor="fileuploaderSingle"
              className="profile_sidebar_btn_imgUpload"
            >
              {t("my.profile.btn.selectImg")}
            </label>

            <FileuploaderSingle
              type="image"
              setFileInfo={props.setImage}
              fileType="image/*"
              fileSize="10485760"
            />
          </div>
        ) : (
          <div>
            <div style={{ marginBottom: "8px" }}>
              {props.image[0].name}
              <img
                style={{ verticalAlign: "middle", marginLeft: "8px" }}
                src="/img/icon/common/delete_blue.png"
                alt="delete"
                onClick={() => props.setImage([])}
              />
            </div>
            <button
              className="profile_sidebar_btn_imgUpload"
              onClick={props.upload}
            >
              {t("my.profile.btn.uploadImg")}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default ImageUploadPopup;
