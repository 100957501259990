import React, { useState, useEffect, useCallback, useRef } from "react";
import "chart.js/auto";
import { Chart } from "chart.js";
import { Bar, getElementAtEvent } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Loading from "component/tag/loading/loading";
/**
 * Version : 1.0
 * 파일명 : ReserveTotal.js
 * 작성일자 : 2022-02-23
 * 작성자 : 강연승
 * 설명 : 대시보드 아이템 - 호스트별 개설 회의 수 차트
 * 수정일자 : 2022-06-08
 * 수정자 : 강연승
 * 수정내역 : 차트 항목 클릭시 해당 호스트 상세통계로 이동
 */
function MeetingHostResChart() {
  /**
   * REDUCER
   */
  const REDUCER_STATE = useSelector(state => state.groupInfoReducer);
  const USER_INFO = useSelector(state => state.userInfoReducer);
  /**
   * 언어변환
   */
  const { t } = useTranslation();
  /**
   * HISTORY
   */
  const history = useHistory();
  /**
   * chart.js
   */
  Chart.defaults.font.family = "'Spoqa Han Sans Neo', 'sans-serif'";
  /**
   * STATE
   */
  const [loading, setLoading] = useState(false);
  const [hostResCntList, setHostResCntList] = useState([]);
  /**
   * useRef
   */
  const chartRef = useRef(null);
  /**
   * 호스트별 월간 예약 횟수 조회
   */
  const getHostResCntList = useCallback(() => {
    setLoading(true);
    axios
      .get("/schedule/dashboard/hostMonthResCnt", {
        params: {
          GROUP_NUM: REDUCER_STATE.GROUP_NUM,
        },
      })
      .then(res => {
        if (res.status === 200) {
          console.log(res.data);
          setHostResCntList(res.data);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [REDUCER_STATE.GROUP_NUM, REDUCER_STATE.GROUP_YN]);
  /**
   * useEffect
   */
  useEffect(() => {
    getHostResCntList();
  }, [getHostResCntList]);
  /**
   * 데이터 정렬
   */
  const [sortType, setSortType] = useState("lower");
  const sortdata = (a, b) => {
    if (sortType === "lower") {
      if (a.RES_CNT > b.RES_CNT) return -1;
      if (a.RES_CNT < b.RES_CNT) return 1;
      return 0;
    } else {
      if (a.RES_CNT < b.RES_CNT) return -1;
      if (a.RES_CNT > b.RES_CNT) return 1;
      return 0;
    }
  };
  const changeSort = () => {
    if (sortType === "lower") {
      setSortType("higher");
    } else if (sortType === "higher") {
      setSortType("lower");
    }
  };
  const colorConvert = size => {
    switch (size) {
      case "MAX":
        return "#419bf9";
      case "MIN":
        return "#ffa189";
      case "NORMAL":
        return "#5eebd8";
      default:
        return "#5eebd8";
    }
  };

  const clickBarItem = event => {
    const points = getElementAtEvent(chartRef.current, event);
    if (points.length) {
      const firstPoint = points[0];
      const value =
        chartRef.current.data.datasets[firstPoint.datasetIndex].data[
          firstPoint.index
        ];
      if (
        REDUCER_STATE.AUTH_CD === "CD0301" ||
        (REDUCER_STATE.AUTH_CD === "CD0302" && value.USER_NUM === USER_INFO.NUM)
      ) {
        history.push({
          pathname: "/app/group/data/" + REDUCER_STATE.GROUP_NUM,
          state: {
            USER_NUM: value.USER_NUM,
          },
        });
      }
    }
  };

  return (
    <div className="dashboardHostResMeetingCnt">
      <div className="dashboard_item_header">
        <div>
          <img src="/img/icon/dashboard/host.png" className="dashboardIcon" />
          <font className="title">
            {t(
              "group.dashboard.txt.hostResCntBar.title." +
                REDUCER_STATE.GROUP_CATEGORY
            )}
          </font>
        </div>
        <div className="sortArea">
          <font className="sortText">
            {sortType === "lower"
              ? t("group.dashboard.txt.hostResCntBar.sort.lower")
              : t("group.dashboard.txt.hostResCntBar.sort.higher")}
          </font>
          <img
            onClick={changeSort}
            className="sortIcon"
            src="/img/icon/dashboard/sort.png"
          />
        </div>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div style={{ marginTop: "31px", marginLeft: "31px" }}>
          <Bar
            ref={chartRef}
            data={{
              labels: hostResCntList
                .sort(sortdata)
                .slice(0, 5)
                .map(data => data.HOST_NM),
              datasets: [
                {
                  label: "개설 회의 수",
                  data: hostResCntList
                    .sort(sortdata)
                    .slice(0, 5)
                    .map(data => ({
                      HOST_NM: data.HOST_NM,
                      RES_CNT: data.RES_CNT,
                      MEMBER_NUM: data.MEMBER_NUM,
                      USER_NUM: data.USER_NUM,
                    })),
                  backgroundColor: hostResCntList
                    .sort(sortdata)
                    .slice(0, 5)
                    .map(data => colorConvert(data.SIZE)),
                },
              ],
            }}
            onClick={clickBarItem}
            options={{
              indexAxis: "y",
              parsing: {
                xAxisKey: "RES_CNT",
                yAxisKey: "HOST_NM",
              },
              plugins: {
                legend: {
                  display: false,
                },
              },
              maintainAspectRatio: false,
              barThickness: "24",
              scales: {
                x: {
                  // ticks: {
                  //   stepSize: 10,
                  // },
                },
                y: {
                  grid: {
                    display: false,
                  },
                },
              },
            }}
            height="194"
          />
        </div>
      )}
    </div>
  );
}

export default MeetingHostResChart;
