import React, { useEffect, useState, useCallback, useRef } from "react";
import { Avatar } from "@material-ui/core";
import GroupMeetingHostDeletePopup from "component/tag/popup/group/profile/groupMeetingHostDeletePopup";
import axios from "axios";
import Alert from "component/tag/modal/alert";
import MeetingHostEmpty from "./meetingHostEmpty";
import Paging from "component/tag/div/common/newPaging";
import { useDispatch, useSelector } from "react-redux";
import MeetingHostListItem from "component/tag/table/profile/meetingHostListItem";
import { useTranslation } from "react-i18next";
import { updatePagingInfo } from "actions/index";
/**
 * Version : 1.0
 * 파일명 : groupMemberList.js
 * 작성일자 : 2021-11-17
 * 작성자 : 강연승
 * 설명 : 그룹 멤버 리스트
 * 수정일자 : 2022-05-04
 * 수정자 : 강연승
 * 수정내역 : 그룹 컬러 수정
 */

function GroupMemberList(props) {
  /**
   * 언어변환
   */
  const { t } = useTranslation();
  /* 그룹 카테고리 코드 변환 */
  const groupCategoryConvert = ctgryCD => {
    switch (ctgryCD) {
      // case "CD0401":
      //   return "company";
      case "CD0402":
        return "company";
      case "CD0403":
        return "school";
      case "CD0404":
        return "private_institute";
      case "CD0405":
        return "university";
      case "CD0406":
        return "social";
      default:
        return "company";
    }
  };
  /**
   * REDUX
   */
  const groupInfoState = useSelector(state => state.groupInfoReducer);
  const PAGING_STATE = useSelector(state => state.pagingInfoReducer);
  /**
   * DISPATCH
   */
  const dispatch = useDispatch();
  /**
   * 알림창 설정
   */
  const [alertProps, setAlertProps] = useState({
    text: "",
    isShow: false,
    cancelBtnDisplay: false,
    okBtnDisplay: true,
    okBtnEventHandler: function () {},
    cancleBtnEventHandler: function () {
      closeAlertEvent();
    },
  });
  /**
   * 알림창 닫기
   */
  const closeAlertEvent = useCallback(() => {
    setAlertProps({
      ...alertProps,
      isShow: false,
    });
  }, [alertProps]);
  /**
   * alert 팝업 이벤트
   */
  const defaultAlert = useCallback(
    params => {
      setAlertProps({
        ...alertProps,
        isShow: true,
        text: params.Request_Text,
        cancelBtnDisplay: false,
        okBtnEventHandler: function () {
          if (params.EventHandler !== null) {
            params.EventHandler();
          }
          closeAlertEvent();
        },
      });
    },
    [alertProps, closeAlertEvent]
  );

  const [openDeleteHost, setOpenDeleteHost] = useState(false);
  const [selectMeetingHost, setSelectMeetingHost] = useState("");

  const openPopup = meetingHost => {
    setSelectMeetingHost(meetingHost);
    setOpenDeleteHost(true);
  };

  const closePopup = () => {
    setOpenDeleteHost(false);
  };

  const editAuth = (type, authNum, authYN) => {
    let authInfo = {
      AUTH_NUM: authNum,
    };

    if (type === "play" && authYN === "Y") {
      authInfo = {
        ...authInfo,
        PLAY_YN: "N",
      };
    } else if (type === "play" && authYN === "N") {
      authInfo = {
        ...authInfo,
        PLAY_YN: "Y",
      };
    } else if (type === "download" && authYN === "Y") {
      authInfo = {
        ...authInfo,
        DOWNLOAD_YN: "N",
      };
    } else if (type === "download" && authYN === "N") {
      authInfo = {
        ...authInfo,
        DOWNLOAD_YN: "Y",
      };
    } else if (type === "rec" && authYN === "Y") {
      authInfo = {
        ...authInfo,
        REC_YN: "N",
      };
    } else if (type === "rec" && authYN === "N") {
      authInfo = {
        ...authInfo,
        REC_YN: "Y",
      };
    }

    axios
      .put("/group/updateUserAuth", authInfo)
      .then(res => {
        if (res.status === 200) {
          getMeetingHostList();
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const [loading, setLoading] = useState(false);
  /**
   * 미팅 호스트 리스트
   */
  const [meetingHostList, setMeetingHostList] = useState([]);
  /**
   * Set LicenseList Total
   */
  const getMeetingHostTotal = useCallback(() => {
    setLoading(true);

    axios
      .get("/group/meetingHostCnt", {
        params: {
          AUTH_CD: groupInfoState.AUTH_CD,
          GROUP_NUM: groupInfoState.GROUP_NUM,
        },
      })
      .then(res => {
        if (res.status === 200) {
          const paging_obj = {
            ROW_COUNT: PAGING_STATE.ROW_COUNT,
            PAGE_COUNT: PAGING_STATE.PAGE_COUNT,
            TOTAL: res.data,
          };
          dispatch(updatePagingInfo(paging_obj));
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [
    PAGING_STATE.PAGE_COUNT,
    PAGING_STATE.ROW_COUNT,
    dispatch,
    groupInfoState.AUTH_CD,
    groupInfoState.GROUP_NUM,
  ]);
  const getMeetingHostList = useCallback(() => {
    axios
      .get("/group/selectGroupMember", {
        params: {
          AUTH_CD: groupInfoState.AUTH_CD,
          GROUP_NUM: groupInfoState.GROUP_NUM,
          ROW_COUNT: PAGING_STATE.ROW_COUNT,
          PAGE_COUNT: PAGING_STATE.PAGE_COUNT,
        },
      })
      .then(res => {
        if (res.status === 200) {
          console.log(res.data);
          setMeetingHostList(res.data);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }, [
    PAGING_STATE.PAGE_COUNT,
    PAGING_STATE.ROW_COUNT,
    groupInfoState.AUTH_CD,
    groupInfoState.GROUP_NUM,
  ]);

  /**
   * useEffect
   */
  useEffect(() => {
    getMeetingHostTotal();
    getMeetingHostList();
  }, [getMeetingHostList, getMeetingHostTotal]);

  useEffect(() => {
    if (props.refresh) {
      getMeetingHostTotal();
      getMeetingHostList();
      props.getGroupInfo();
      props.setRefresh(false);
    }
  }, [getMeetingHostList, getMeetingHostTotal, props]);

  const deleteMeetingHost = (memberNum, userNM) => {
    axios
      .put("/group/deleteGroupMember", { MEMBER_NUM: memberNum })
      .then(res => {
        if (res.status === 200) {
          const defaultParams = {
            Request_Text: (
              <div>
                <div
                  style={{
                    font: "normal normal normal 20px/27px Spoqa Han Sans Neo",
                    color: "#2B4AC4",
                    textAlign: "left",
                  }}
                >
                  {t("group.profile.meetingHostSetting.deleteHostTitle", {
                    MEETING_HOST: t(
                      "group.thema." +
                        groupCategoryConvert(groupInfoState.GROUP_CATEGORY) +
                        ".meetingHost"
                    ),
                  })}
                </div>
                <div
                  style={{
                    font: "normal normal normal 15px/21px Spoqa Han Sans Neo",
                    color: "#3E3E4F",
                    textAlign: "left",
                    marginTop: "11px",
                  }}
                >
                  {t("group.profile.meetingHostSetting.deleteHostResult", {
                    USER_NM: userNM,
                  })}
                </div>
              </div>
            ),
            EventHandler: function () {
              getMeetingHostList();
            },
          };
          closePopup();
          defaultAlert(defaultParams);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const [isMouseOverTooltip, setIsMouseOverTooltip] = useState(false);
  const onMouseTooltip = overYN => {
    if (overYN === "over") {
      setIsMouseOverTooltip(true);
    } else if (overYN === "out") {
      setIsMouseOverTooltip(false);
    }
  };

  /**
   * 닉네임 변경 설정
   */
  const [isEditNikckNM, setIsEditNikckNM] = useState(false);
  const nameRef = useRef();
  /**
   * 이름 수정 취소
   */
  const cancelEditNickNm = useCallback(() => {
    if (isEditNikckNM) {
      // && nameRef.current.style.display === "block"
      setIsEditNikckNM(false);
    }
  }, [isEditNikckNM]);
  /**
   * 이름 수정 취소 이벤트 추가
   */
  useEffect(() => {
    if (isEditNikckNM) {
      nameRef.current.focus();
      window.addEventListener("click", cancelEditNickNm);
      return () => window.removeEventListener("click", cancelEditNickNm);
    }
  }, [cancelEditNickNm, isEditNikckNM]);
  return (
    <>
      <Alert alertProps={alertProps} />

      <GroupMeetingHostDeletePopup
        open={openDeleteHost}
        close={closePopup}
        meetingHost={selectMeetingHost}
        deleteMeetingHost={deleteMeetingHost}
      />

      <div className="profile_content_license">
        {groupInfoState.AUTH_CD === "CD0301" ? (
          <table className="profile_content_licenseTable">
            <colgroup>
              <col width="16px" />
              <col width="80px" />
              <col width="16px" />
              <col width="116px" />
              <col width="16px" />
              <col width="188px" />
              <col width="16px" />
              <col width="80px" />
              <col width="8px" />
              <col width="80px" />
              <col width="8px" />
              <col width="104px" />
              <col width="6px" />
              <col width="102px" />
              <col width="32px" />
            </colgroup>

            <thead>
              {/* <tr className="profile_table_tr profile_table_header"> */}
              <tr className="group_profile_thead text-center">
                <th />
                <th>{t("group.profile.meetingHostSetting.table.profile")}</th>
                <th />
                <th className="text-left">
                  {t("group.profile.meetingHostSetting.table.name")}
                </th>
                <th />
                <th className="text-left">
                  {t("group.profile.meetingHostSetting.table.email")}
                </th>
                <th />
                <th>{t("group.profile.meetingHostSetting.table.play")}</th>
                <th />
                <th>{t("group.profile.meetingHostSetting.table.download")}</th>
                <th />
                <th>
                  <div className="inline-block" style={{ marginRight: "8px" }}>
                    {t("group.profile.meetingHostSetting.table.record")}
                  </div>
                  <img
                    src="/img/icon/profile/more_gray.png"
                    alt="more"
                    style={{ position: "absolute", marginTop: "-2px" }}
                    onMouseOver={() => onMouseTooltip("over")}
                    onMouseOut={() => onMouseTooltip("out")}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      className="meetingHostAuthTooltip"
                      style={{
                        display: isMouseOverTooltip ? "block" : "none",
                      }}
                    >
                      <div
                        style={{
                          font: "normal normal bold 12px/18px Spoqa Han Sans Neo",
                          color: "#FFFFFF",
                        }}
                      >
                        {t(
                          "group.profile.meetingHostSetting.table.tooltipText",
                          {
                            MEETING_HOST: t(
                              "group.thema." +
                                groupCategoryConvert(
                                  groupInfoState.GROUP_CATEGORY
                                ) +
                                ".meetingHost"
                            ),
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </th>
                <th />
                <th />
                <th />
              </tr>
            </thead>

            <tbody>
              {Array.isArray(meetingHostList) && meetingHostList.length > 0 ? (
                meetingHostList.map(data => (
                  <MeetingHostListItem
                    key={data.MEMBER_NUM}
                    data={data}
                    openPopup={openPopup}
                    editAuth={editAuth}
                    getMeetingHostTotal={getMeetingHostTotal}
                    getMeetingHostList={getMeetingHostList}
                    // saveNickNameEvent={props.saveNickNameEvent}
                  />
                ))
              ) : (
                <MeetingHostEmpty />
              )}
            </tbody>
          </table>
        ) : (
          <table className="profile_content_licenseTable">
            <colgroup>
              <col width="16px" />
              <col width="80px" />
              <col width="16px" />
              <col width="124px" />
              <col width="16px" />
              <col width="216px" />
              <col width="400px" />
            </colgroup>

            <thead>
              {/* <tr className="profile_table_tr profile_table_header"> */}
              <tr className="group_profile_thead text-center">
                <th />
                <th>{t("group.profile.meetingHostSetting.table.profile")}</th>
                <th />
                <th>{t("group.profile.meetingHostSetting.table.name")}</th>
                <th />
                <th>{t("group.profile.meetingHostSetting.table.email")}</th>
                <th />
              </tr>
            </thead>

            <tbody>
              {meetingHostList.length > 0 ? (
                meetingHostList.map(data => (
                  <tr
                    // className="profile_table_tr profile_table_body"
                    className="group_profile_tbody text-center"
                    key={data.MEMBER_NUM}
                  >
                    <td />
                    <td>
                      <Avatar style={{ margin: "auto" }}>
                        {data.USER_NM.substr(0, 1)}
                      </Avatar>
                    </td>
                    <td />
                    {/* <td>{data.NICK_NM === "" ? data.USER_NM : data.NICK_NM}</td> */}
                    <td>{data.USER_NM}</td>
                    <td />
                    <td>{data.USER_ID}</td>
                    <td />
                  </tr>
                ))
              ) : (
                <MeetingHostEmpty />
              )}
            </tbody>
          </table>
        )}

        <div
          style={{
            height: "60px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Paging />
        </div>
      </div>
    </>
  );
}

export default GroupMemberList;
