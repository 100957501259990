import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
/**
 * Version : 1.0
 * 파일명 : groupColorEditPopup.js
 * 작성일자 : 2021-11-29
 * 작성자 : 강연승
 * 설명 : 그룹프로필 - 그룹 색상 변경 팝업
 * 수정일자 : 2022-05-04
 * 수정자 : 강연승
 * 수정내역 : 그룹 컬러 수정
 */
function GroupColorEditPopup(props) {
  const closePopup = () => {
    props.close("color");
  };

  /* 카테고리 선택 */
  const [selectColor, setSelectColor] = useState("");
  useEffect(() => {
    setSelectColor(props.color);
  }, [props.color]);

  const clickColor = select => {
    setSelectColor(select);
  };

  const colorList = [
    { name: "red", code: "#de8799" },
    { name: "orange", code: "#d78e71" },
    { name: "yellow", code: "#cdb14c" },
    { name: "bean", code: "#97C12A" },
    { name: "green", code: "#64d483" },
    { name: "mint", code: "#6cd6bf" },
    { name: "pine", code: "#75BDD9" },
    { name: "pink", code: "#cb86dd" },
    { name: "purple", code: "#a994e1" },
    { name: "black", code: "#3b3f4c" },
  ];

  const colorConverter = color => {
    switch (color) {
      case "red":
        return "#c63353";
      case "orange":
        return "#d78e71";
      case "yellow":
        return "#cdb14c";
      case "bean":
        return "#97C12A";
      case "green":
        return "#64d483";
      case "mint":
        return "#6cd6bf";
      case "pine":
        return "#75BDD9";
      case "pink":
        return "#cb86dd";
      case "purple":
        return "#a994e1";
      case "black":
        return "#3b3f4c";
      default:
        return "#2B4AC4";
    }
  };
  return (
    <ReactModal
      isOpen={props.open} //모달 Open(Boolean)
      className="groupColorEditPopup" //모달 ClassName
      overlayClassName="groupColorEditPopup_Overlay" //모달오버레이 ClassName
      onRequestClose={closePopup}
      shouldCloseOnEsc={true}
      animationType="fade"
      transparent={true}
      closeTimeoutMS={200}
    >
      <div>
        <img
          src="/img/icon/group/close.png"
          alt="close"
          onClick={closePopup}
          style={{
            cursor: "pointer",
            float: "right",
            marginTop: "16px",
            marginRight: "16px",
          }}
        />
      </div>
      <div className="text-center" style={{ marginTop: "8px" }}>
        <div
          style={{
            color: "#2B4AC4",
            font: "normal normal bold 16px/21px Spoqa Han Sans Neo",
          }}
        >
          그룹 테마 변경
        </div>
        <div
          style={{
            color: "#3E3E4F",
            marginTop: "14px",
            font: "normal normal bold 14px/18px Spoqa Han Sans Neo",
          }}
        >
          그룹 테마 색상을 변경하실 수 있습니다.
        </div>
      </div>

      <div
        style={{
          margin: "34px 31px 32px 31px",
          display: "grid",
          gridColumnGap: "16px",
          gridRowGap: "12px",
          gridTemplateColumns: "repeat(auto-fill, minmax(32px,0fr))",
          textAlign: "center",
        }}
      >
        {colorList.map(color => (
          <div
            key={color.name}
            style={{
              width: "32px",
              height: "32px",
              backgroundColor: color.code,
              borderRadius: "8px",
              cursor: "pointer",
              boxShadow:
                color.name === selectColor ? "0px 5px 16px #00000029" : "none",
            }}
            onClick={() => clickColor(color.name)}
          >
            {color.name === selectColor ? (
              <img
                src="/img/icon/profile/check.png"
                alt="check"
                style={{ margin: "8px 0" }}
              />
            ) : null}
          </div>
        ))}
      </div>
      <div className="text-center">
        <button
          style={{
            width: "64px",
            height: "34px",
            backgroundColor: "white",
            border: "none",
            color: "#2B4AC4",
            font: "normal normal bold 14px/18px Spoqa Han Sans Neo",
            cursor: "pointer",
            marginBottom: "24px",
          }}
          onClick={() => props.editColor(selectColor)}
        >
          변 경
        </button>
      </div>
    </ReactModal>
  );
}

export default GroupColorEditPopup;
