/**
 * Version : 1.0
 * 작성일자 : 2021-12-23
 * 작성자 : 권도훈
 * 설명 : action 정보
 * 수정일자 :  2022-05-02
 * 수정자 : 강연승
 * 수정내역 : 캘린더 액티브 범위 추가
 */
/**
 * 사용자 정보 업데이트
 */
export const updateUserInfo = data => {
  return {
    type: "userInfo/UPDATE",
    info: data,
  };
};

/**
 * 알람 수 업데이트
 */
export const updateAlramCount = count => {
  return {
    type: "alramInfo/COUNT_UPDATE",
    count: count,
  };
};
/**
 * 알람 팝업 OPEN
 */
export const openAlramList = () => {
  return {
    type: "alramInfo/POPUP_OPEN",
  };
};
/**
 * 알람 팝업 CLOSE
 */
export const closeAlramList = () => {
  return {
    type: "alramInfo/POPUP_CLOSE",
  };
};

/**
 * 데이터 페이지 검색
 */
export const updateGroupInfo = data => {
  return {
    type: "groupInfo/UPDATE",
    DATA: data,
  };
};

/**
 * 무료 회의 이용권 정보
 */
export const updateFreeLicenseInfo = data => {
  return {
    type: "freeLicenseInfo/UPDATE",
    DATA: data,
  };
};

/**
 *  예약 날짜, 시간 저장
 */
export const updateDateTimeInfo = data => {
  return {
    type: "dateTimeInfo/UPDATE",
    data: data,
  };
};

/**
 * 회의 영상 페이지 정보 업데이트
 */
export const updateRecordInfo = data => {
  return {
    type: "recordInfo/UPDATE",
    DATA: data,
  };
};

/**
 * 페이징 정보 업데이트
 */
export const updatePagingInfo = data => {
  return {
    type: "pagingInfo/UPDATE",
    DATA: data,
  };
};

/**
 * 페이징 (고객센터) 정보 업데이트
 */
export const updatePagingsupInfo = data => {
  return {
    type: "pagingsupInfo/UPDATE",
    DATA: data,
  };
};

/**
 * 페이징 정보 업데이트
 */
export const updateLoadingInfo = data => {
  return {
    type: "loadingInfo/UPDATE",
    DATA: data,
  };
};

/**
 * 사용자 카드 정보 업데이트
 */
export const updateUserCardInfo = data => {
  return {
    type: "userCardInfo/UPDATE",
    DATA: data,
  };
};

/**
 * Open Alert
 */
export const openAlert = data => {
  return {
    type: "alertInfo/OPEN_ALERT",
    DATA: data,
  };
};
/**
 * Open Confirm
 */
export const openConfirm = data => {
  return {
    type: "alertInfo/OPEN_CONFIRM",
    DATA: data,
  };
};
/**
 * Close Alert
 */
export const closeAlert = () => {
  return {
    type: "alertInfo/CLOSE",
  };
};

/**
 * updateProfileInfo
 */
export const updateProfileInfo = () => {
  return {
    type: "profileInfo/UPDATE",
  };
};

export const resetProfileInfo = () => {
  return {
    type: "profileInfo/RESET",
  };
};

export const openPayDonePopup = data => {
  return {
    type: "profileInfo/OPEN_POPUP",
    DATA: data,
  };
};

export const closePayDonePopup = () => {
  return {
    type: "profileInfo/CLOSE_POPUP",
  };
};

export const requestFocusCalendar = data => {
  return {
    type: "focusCalendarInfo/REQUEST_FOCUS",
    DATA: data,
  };
};

export const responseFocusCalendar = () => {
  return {
    type: "focusCalendarInfo/RESPONSE_FOCUS",
  };
};

/**
 * 라이센스 무/유료 갯수 정보 업데이트
 */
export const updateLicenseCntInfo = data => {
  return {
    type: "licenseCntInfo/UPDATE",
    DATA: data,
  };
};

/**
 * 통계 페이지 로딩
 */
export const updateStatisticsLoading = loading => {
  return {
    type: "statisticsInfo/LOADING",
    loading: loading,
  };
};

/**
 * 통계 페이지 로딩
 */
export const updateStatisticsType = viewType => {
  return {
    type: "statisticsInfo/TOGGLE",
    viewType: viewType,
  };
};

/**
 * 통계 페이지 정보
 */
export const updateStatisticsData = data => {
  return {
    type: "statisticsInfo/DATA",
    data: data,
  };
};

/**
 * 통계 페이지 상세 정보
 */
export const updateStatisticsDetail = data => {
  return {
    type: "statisticsInfo/DETAIL",
    detail_data: data,
  };
};

export const closedateStatisticsDetail = () => {
  return {
    type: "statisticsInfo/DETAIL_CLOSE",
  };
};

//즉시 결제 성공
export const outstandingSuccess = () => {
  return {
    type: "outstandingInfo/OPEN_SUCCESS_POPUP",
  };
};
//즉시 결제 성공, 실패
export const outstandingStop = data => {
  return {
    type: "outstandingInfo/OPEN_STOP_POPUP",
    DATA: data,
  };
};
//즉시 결제 성공
export const outstandingFail = data => {
  return {
    type: "outstandingInfo/OPEN_FAIL_POPUP",
    DATA: data,
  };
};
//즉시 결제 결과 팝업 닫기
export const outstandingClose = () => {
  return {
    type: "outstandingInfo/CLOSE_POPUP",
  };
};
/**
 * 캘린더 액티브 범위 업데이트
 */
export const updateCalendarRangeInfo = data => {
  return {
    type: "calendarRangeInfo/UPDATE",
    data: data,
  };
};
