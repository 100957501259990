import React, { useState, useEffect, useCallback } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import BookmarkItem from "component/tag/div/bookmark/bookmarkItem";
import BookmarkDeletePopup from "component/tag/popup/bookmark/bookmarkDeletePopup";
import FavoriteGroupItem from "component/tag/div/bookmark/favoriteGroupItem";
import axios from "axios";
import { useSelector } from "react-redux";
/**
 * Version : 1.0
 * 파일명 : bookmark.js
 * 작성일자 : 2021-10-05
 * 작성자 : 권도훈
 * 설명 :
 * 수정일자 : 2022-05-04
 * 수정자 : 강연승
 * 수정내역 : 그룹 컬러 수정
 * props :
 * calendarApi - 풀캘린더 API
 * changeViewType - 캘린더, 북마크 간의 뷰타입 변경 이벤트
 * setIsInput - 사이드바 뷰타입 설정
 * setMeetingInfo - 스케줄 예약 정보
 */
function Bookmark(props) {
  const groupInfoState = useSelector(state => state.groupInfoReducer);
  const [bookmarkListCnt, setBookmarkListCnt] = useState("");
  const [bookmarkList, setBookmarkList] = useState([]);
  const [favoriteGroupListCnt, setFavoriteGroupListCnt] = useState("");
  const [favoriteGroupList, setFavoriteGroupList] = useState([]);
  const [bookmarkViewType, setBookmarkViewType] = useState("Meeting");

  useEffect(() => {
    getBookmarkList();
    if (groupInfoState.GROUP_YN === "N") {
      getFavoriteGroupList();
    }
  }, [props.bookmarkState]);

  const reloadBookmarkList = () => {
    getBookmarkList();
    if (groupInfoState.GROUP_YN === "N") {
      getFavoriteGroupList();
    }
  };

  const getBookmarkList = () => {
    // props.setLoading(true);
    let params = {
      CURRENTPAGE: "0",
      RECORDPERPAGE: "15",
    };
    if (
      groupInfoState.GROUP_NUM !== undefined &&
      groupInfoState.GROUP_NUM !== null &&
      groupInfoState.GROUP_NUM !== ""
    ) {
      params = {
        ...params,
        GROUP_NUM: groupInfoState.GROUP_NUM,
      };
    }
    axios
      .get("/schedule/selectMyFavorite", {
        params: params,
      })
      .then(
        ({ data }) => (
          // props.setLoading(false),
          setBookmarkList(data[0].FAVORITE), setBookmarkListCnt(data[0].TOTAL)
        )
      )
      .catch(error => {
        // props.setLoading(false);
        console.log(error);
      });
  };

  const getFavoriteGroupList = () => {
    let params = {
      CURRENTPAGE: "0",
      RECORDPERPAGE: "15",
    };
    // if (
    //   props.groupNum !== undefined &&
    //   props.groupNum !== null &&
    //   props.groupNum !== ""
    // ) {
    //   params = {
    //     ...params,
    //     GROUP_NUM: props.groupNum,
    //   };
    // }
    axios
      .get("/group/selectFavoriteGroupList", {
        params: params,
      })
      .then(
        ({ data }) => (
          // props.setLoading(false),
          setFavoriteGroupList(data[0].FAVORITEGROUPLIST),
          setFavoriteGroupListCnt(data[0].TOTAL)
        )
      )
      .catch(error => {
        // props.setLoading(false);
        console.log(error);
      });
  };

  const changeBookmarkViewType = type => {
    if (type === bookmarkViewType) {
      return;
    }
    setBookmarkViewType(type);
  };

  /* 그룹 리스트 정렬 */
  const sortItems = (a, b) => {
    if (a.ORDER_NUM < b.ORDER_NUM) return 1;
    if (a.ORDER_NUM > b.ORDER_NUM) return -1;
    return 0;
  };

  /* 그룹 아이템 드랍이벤트 */
  const onDrop = (dragItem, otherItem) => {
    if (dragItem.FAVORITE_NUM === otherItem.FAVORITE_NUM) {
      return;
    }
    let newList = [];
    newList = [...bookmarkList];
    let firstItem = newList.find(i => i.FAVORITE_NUM === dragItem.FAVORITE_NUM);
    let secondItem = newList.find(
      i => i.FAVORITE_NUM === otherItem.FAVORITE_NUM
    );
    const firstIndex = firstItem.ORDER_NUM;
    firstItem.ORDER_NUM = secondItem.ORDER_NUM;
    secondItem.ORDER_NUM = firstIndex;
    const bookmarkInfo = {
      newOrder: {
        FAVORITE_NUM: dragItem.FAVORITE_NUM,
        ORDER_NUM: firstItem.ORDER_NUM,
      },
      oldOrder: {
        FAVORITE_NUM: otherItem.FAVORITE_NUM,
        ORDER_NUM: secondItem.ORDER_NUM,
      },
    };
    updateOrder(bookmarkInfo);
    setBookmarkList(newList);
  };

  // /* 그룹 순서 및 즐겨찾기 수정 axios */
  const updateOrder = useCallback(async body => {
    await axios.put("/schedule/updateFavoriteOrder", body).catch(error => {
      console.log(error);
    });
  });

  /* 즐겨찾기 삭제 팝업 */
  const [isDelete, setIsDelete] = useState(false);
  const [bookmarkInfo, setBookmarkInfo] = useState({});
  const onDeletePopup = info => {
    setIsDelete(true);
    setBookmarkInfo(info);
  };

  const offDeletePopup = () => {
    setIsDelete(false);
    setBookmarkInfo({});
  };

  const createBookmark = () => {
    props.setIsInput(true);
    props.setResSidebarType("createBookmark");
  };

  const [isEdit, setIsEdit] = useState(false);
  const [isEditNum, setIsEditNum] = useState("");
  const onEdit = favoriteNum => {
    setIsEditNum(favoriteNum);
    setIsEdit(true);
  };

  const editBookmark = info => {
    props.setIsInput(true);
    props.setResSidebarType("editBookmark");
    props.setBookmarkInputInfo(info);
  };

  const resBookmark = info => {
    props.changeViewType();
    props.setIsInput(true);
    props.setResSidebarType("res");
    props.setMeetingInfo({
      title: info.TITLE,
      content: info.CONTENTS,
      participant: info.participants,
    });
  };

  return (
    <div>
      <div
        style={{
          marginTop: "24px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          className={
            groupInfoState.GROUP_YN === "N"
              ? "bookmark_header"
              : "group_bookmark_header"
          }
        >
          {groupInfoState.GROUP_YN === "N" ? (
            bookmarkViewType === "Meeting" ? (
              <>즐겨찾는 회의 {bookmarkListCnt}개</>
            ) : (
              <>즐겨찾는 그룹 {favoriteGroupListCnt}</>
            )
          ) : (
            <>그룹 내 즐겨찾는 회의 {bookmarkListCnt}개</>
          )}
        </div>
        <div>
          {groupInfoState.GROUP_YN === "N" ? (
            <>
              <button
                type="button"
                title="Meeting"
                className={
                  bookmarkViewType === "Meeting"
                    ? "bookmark_btn_meeting bookmark_btn_active"
                    : "bookmark_btn_meeting"
                }
                onClick={() => changeBookmarkViewType("Meeting")}
              >
                미팅
              </button>
              <button
                type="button"
                title="Group"
                className={
                  bookmarkViewType === "Group"
                    ? "bookmark_btn_group bookmark_btn_active"
                    : "bookmark_btn_group"
                }
                onClick={() => changeBookmarkViewType("Group")}
              >
                그룹
              </button>
            </>
          ) : null}
          <button
            type="button"
            title="캘린더"
            className={
              groupInfoState.GROUP_YN === "N"
                ? props.isInput
                  ? "mybookmark exchange_btn"
                  : "cursor mybookmark exchange_btn"
                : props.isInput
                ? "groupBookmark exchange_btn"
                : "cursor groupBookmark exchange_btn"
            }
            disabled={props.isInput}
            onClick={props.changeViewType}
          >
            <img
              src="/img/icon/bookmark/change_calendar.png"
              alt="exchange"
              style={{ verticalAlign: "bottom" }}
            />
          </button>
        </div>
      </div>
      <BookmarkDeletePopup
        open={isDelete}
        close={offDeletePopup}
        reload={reloadBookmarkList}
        bookmarkInfo={bookmarkInfo}
      />
      {bookmarkViewType === "Meeting" ? (
        <div
          className="scrollbar"
          style={{
            height: "790px",
            overflowY: "auto",
            backgroundColor: "rgba(255,255,255,0.5)",
            marginTop: "16px",
            borderRadius: "32px",
          }}
        >
          <DndProvider backend={HTML5Backend}>
            <div
              style={{
                padding: "40px 32px 32px",
                display: "grid",
                gridColumnGap: "32px",
                gridRowGap: "32px",
                gridTemplateColumns: "repeat(auto-fill, minmax(265px,0fr))",
              }}
            >
              <div
                onClick={
                  props.resSidebarType === "createBookmark" ||
                  props.resSidebarType === "editBookmark"
                    ? null
                    : createBookmark
                }
                style={{
                  width: "264px",
                  height: "144px",
                  borderRadius: "16px",
                  backgroundColor: "rgba(255,255,255,0.5)",
                  cursor: "pointer",
                  boxShadow: "0px 5px 16px #00000029",
                  textDecoration: "none",
                }}
              >
                <img
                  src="/img/icon/group/add_group.png"
                  alt="addGroup"
                  style={{ margin: "36px 118px 24px" }}
                />
                <div
                  style={{
                    marginBottom: "32px",
                    textAlign: "center",
                    color:
                      groupInfoState.GROUP_YN === "N" ||
                      groupInfoState.GROUP_YN === ""
                        ? "#4865CC"
                        : "#84889B",
                    font: "normal normal normal 18px/24px Spoqa Han Sans Neo",
                  }}
                >
                  새로운 즐겨찾기 만들기
                </div>
              </div>
              {bookmarkList.sort(sortItems).map(data => (
                <BookmarkItem
                  key={data.FAVORITE_NUM}
                  bookmarkInfo={data}
                  onDrop={onDrop}
                  resSidebarType={props.resSidebarType}
                  onDeletePopup={onDeletePopup}
                  editBookmark={editBookmark}
                  resBookmark={resBookmark}
                  isEdit={isEdit}
                  setIsEdit={setIsEdit}
                  isEditNum={isEditNum}
                  onEdit={onEdit}
                />
              ))}
            </div>
          </DndProvider>
        </div>
      ) : (
        /* 즐겨찾는 그룹 */
        <div
          className="scrollbar"
          style={{
            height: "790px",
            overflowY: "auto",
            backgroundColor: "rgba(255,255,255,0.5)",
            marginTop: "16px",
            borderRadius: "32px",
          }}
        >
          <div
            style={{
              padding: "40px 32px 32px",
              display: "grid",
              gridColumnGap: "32px",
              gridRowGap: "32px",
              gridTemplateColumns: "repeat(auto-fill, minmax(265px,0fr))",
            }}
          >
            {favoriteGroupList.sort(sortItems).map(data => (
              <FavoriteGroupItem key={data.GROUP_NUM} groupInfo={data} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default Bookmark;
