import React, { useState, useEffect } from "react";
import { Avatar } from "@material-ui/core";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { updatePagingInfo } from "actions/index";
/**
 * Version : 1.0
 * 파일명 : participantSidebar.js
 * 작성일자 : 2021-10-06
 * 작성자 : 강연승
 * 설명 : 회의 예약 시 좌측에 뜨는 참석자 사이드바
 * 수정일자 :  2022-04-11
 * 수정자 : 강연승
 * 수정내역 : 참석자 사이드바 중복 오류 수정
 * props :
 * 전달받은값 - 설명
 */
function AdressTypeItem({
  addressInfo,
  typeMemberList,
  groupYN,
  groupNum,
  checkedInputs,
  clickCheckbox,
  clickCheckboxAll,
  clickAddressItem,
}) {
  const [clickAdrType, setClickAdrType] = useState(false);

  const validCheckBoxAll = adrTypeNum => {
    let cnt = 0;
    if (checkedInputs.length === 0) {
      return false;
    } else {
      for (let i = 0; i < checkedInputs.length; i++) {
        if (adrTypeNum === checkedInputs[i].ADR_TYPE_NUM) {
          cnt += 1;
        }
      }
      if (cnt > 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  /*
   * 색상 컨버터
   */
  const colorConverter = color => {
    switch (color) {
      case "red":
        return "#D4667E";
      case "pink":
        return "#DC82C3";
      case "orange":
        return "#CD704B";
      case "yellow":
        return "#BD9D29";
      case "lightgreen":
        return "#97C12A";
      case "green":
        return "#28B750";
      case "mint":
        return "#27B395";
      case "blue":
        return "#6E85D7";
      case "purple":
        return "#9479DA";
      default:
        return "#2B4AC4";
    }
  };

  return (
    <div>
      <table style={{ width: "456px" }}>
        <thead>
          <tr
            style={{
              // width: "456px",
              height: "64px",
              cursor: "pointer",
            }}
            onClick={() => {
              if (typeMemberList.length > 0) {
                setClickAdrType(!clickAdrType);
              }
            }}
          >
            <th
              onClick={e => e.stopPropagation()}
              style={{
                width: "56px",
              }}
            >
              <label htmlFor={addressInfo.ADR_TYPE_NUM}>
                <img
                  src={
                    validCheckBoxAll(addressInfo.ADR_TYPE_NUM)
                      ? "/img/icon/common/release.png"
                      : "/img/icon/common/non_check.png"
                  }
                  alt="checkAll"
                />
              </label>
              <input
                type="checkbox"
                className="cursor address_checkbox"
                name="Adr_Chk"
                id={addressInfo.ADR_TYPE_NUM}
                style={{ display: "none" }}
                disabled={typeMemberList.length === 0}
                onChange={e => {
                  clickCheckboxAll(
                    e.currentTarget.checked,
                    typeMemberList,
                    addressInfo.ADR_TYPE_NUM
                  );
                  if (
                    !clickAdrType &&
                    !validCheckBoxAll(addressInfo.ADR_TYPE_NUM)
                  ) {
                    setClickAdrType(true);
                  } else if (
                    clickAdrType &&
                    validCheckBoxAll(addressInfo.ADR_TYPE_NUM)
                  ) {
                    setClickAdrType(false);
                  }
                }}
                checked={validCheckBoxAll(addressInfo.ADR_TYPE_NUM)}
              />
            </th>
            <th colSpan="2" style={{ textAlign: "left" }}>
              <div
                style={{
                  display: "inline",
                  marginRight: "8px",
                  borderRadius: "6px",
                  padding: "8px",
                  color: "white",
                  font: "normal normal bold 14px/18px Spoqa Han Sans Neo",
                  backgroundColor: colorConverter(addressInfo.ADR_TYPE_BGC),
                }}
              >
                {addressInfo.ADR_TYPE_NM}
              </div>
              <div style={{ display: "inline" }}>({typeMemberList.length})</div>
            </th>
            <th
              style={{
                textAlign: "right",
                paddingRight: "24px",
                display: clickAdrType ? "table-cell" : "none",
              }}
            >
              <img
                src="/img/icon/slide/folding.png"
                alt="add"
                style={{ marginLeft: "8px", verticalAlign: "bottom" }}
              />
            </th>
          </tr>
        </thead>
        {clickAdrType ? (
          <tbody className="listTbody">
            {typeMemberList.map((data, index) => (
              <AddressItem
                key={"type_" + index}
                type="type"
                addressInfo={data}
                groupYN={groupYN}
                groupNum={groupNum}
                checkedInputs={checkedInputs}
                clickCheckbox={clickCheckbox}
                clickAddressItem={clickAddressItem}
              />
            ))}
          </tbody>
        ) : null}
      </table>
    </div>
  );
}

function AddressItem({
  type,
  addressInfo,
  groupYN,
  groupNum,
  checkedInputs,
  clickCheckbox,
  clickAddressItem,
}) {
  return (
    <tr
      className="table-border-none"
      style={{ background: "none", width: "456px", height: "64px" }}
      onClick={() => {
        clickAddressItem(addressInfo.ADR_NM, addressInfo.ADR_EMAIL);
      }}
    >
      <td
        style={{
          paddingLeft: type !== null && type !== undefined ? "16px" : "none",
          width: "56px",
          textAlign: "center",
        }}
        onClick={e => e.stopPropagation()}
      >
        <label
          htmlFor={
            addressInfo.ADR_TYPE_NUM !== null &&
            addressInfo.ADR_TYPE_NUM !== undefined
              ? addressInfo.ADR_TYPE_NUM + "_" + addressInfo.ADR_NUM
              : addressInfo.ADR_EMAIL
          }
        >
          <img
            src={
              checkedInputs.includes(addressInfo)
                ? "/img/icon/common/check.png"
                : "/img/icon/common/non_check.png"
            }
            alt="check"
          />
        </label>
        <input
          className="cursor address_checkbox"
          type="checkbox"
          style={{ display: "none" }}
          id={
            addressInfo.ADR_TYPE_NUM !== null &&
            addressInfo.ADR_TYPE_NUM !== undefined
              ? addressInfo.ADR_TYPE_NUM + "_" + addressInfo.ADR_NUM
              : addressInfo.ADR_EMAIL
          }
          name="Adr_Chk"
          onChange={e => clickCheckbox(e.currentTarget.checked, addressInfo)}
          checked={checkedInputs.includes(addressInfo) ? true : false}
        />
      </td>
      <td>
        {addressInfo.ADR_IMG === undefined ? (
          <Avatar />
        ) : (
          <Avatar alt="user_img" src={addressInfo.ADR_IMG} />
        )}
      </td>
      <td
        style={{
          paddingLeft: "16px",
          font: "normal normal bold 16px/21px Spoqa Han Sans Neo",
        }}
      >
        {addressInfo.ADR_NM}
      </td>
      <td
        style={{
          paddingLeft: "16px",
          font: "normal normal normal 16px/21px Spoqa Han Sans Neo",
        }}
      >
        {addressInfo.ADR_EMAIL}
      </td>
    </tr>
  );
}

function ParticipantSidebar({
  width,
  height,
  defaultX,
  activeX,
  partiSidebar,
  groupYN,
  groupNum,
  clickAddressItem,
  addCheckedAddressList,
  handlePartiSidebar,
}) {
  /**
   * REDUCER
   */
  const PAGING_STATE = useSelector(state => state.pagingInfoReducer);
  const [xPosition, setX] = useState(-width - defaultX);
  const [yPosition, setY] = useState(-10);
  const [addressAll, setAddressAll] = useState([]);
  const [addressRecent, setAddressRecent] = useState([]);
  const [addressFavorite, setAddressFavorite] = useState([]);
  const [addressType, setAddressType] = useState([]);
  const [addressTypeMember, setAddressTypeMember] = useState([]);

  useEffect(() => {
    if (partiSidebar === true) {
      setX(-activeX);
      setY(0);
      getAddressAll();
      getAddressRecent();
      getAddressFavorite();
      getAddressType();
    } else if (partiSidebar === false) {
      setY(-10);
      setX(-width - defaultX);
      setActiveIndex(0);
      if (checkedInputs.length > 0) {
        setCheckedInputs([]);
      }
    }
  }, [partiSidebar]);

  const getAddressAll = () => {
    if (groupYN === "N") {
      axios
        .get("/address/Address", {
          params: {
            Group_Num: "",
            Group_YN: groupYN,
            // Search_Text: "",
            Order: "Y",
            page_Row: 100,
            page_Now: 0,
          },
        })
        .then(({ data }) => setAddressAll(data))
        .catch(error => {
          console.log(error);
        });
    } else if (
      groupYN === "Y" &&
      groupNum !== null &&
      groupNum !== undefined &&
      groupNum !== ""
    ) {
      axios
        .get("/address/AddressGroup", {
          params: {
            Group_Num: groupNum,
            Group_YN: groupYN,
            page_Row: 100,
            page_Now: 0,
          },
        })
        .then(({ data }) => setAddressAll(data))
        .catch(error => {
          console.log(error);
        });
    }
  };
  const getAddressRecent = () => {
    if (groupYN === "N") {
      axios
        .get("/address/Recent", {
          params: {
            Group_Num: "",
            Group_YN: groupYN,
          },
        })
        .then(({ data }) => setAddressRecent(data))
        .catch(error => {
          console.log(error);
        });
    } else if (
      groupYN === "Y" &&
      groupNum !== null &&
      groupNum !== undefined &&
      groupNum !== ""
    ) {
      axios
        .get("/address/Recent", {
          params: {
            Group_Num: groupNum,
            Group_YN: groupYN,
          },
        })
        .then(({ data }) => setAddressRecent(data))
        .catch(error => {
          console.log(error);
        });
    }
  };
  const getAddressFavorite = () => {
    if (groupYN === "N") {
      axios
        .get("/address/Favorite", {
          params: {
            Group_Num: "",
            Group_YN: groupYN,
            page_Row: 100,
            page_Now: 0,
          },
        })
        .then(({ data }) => setAddressFavorite(data))
        .catch(error => {
          console.log(error);
        });
    } else if (
      groupYN === "Y" &&
      groupNum !== null &&
      groupNum !== undefined &&
      groupNum !== ""
    ) {
      axios
        .get("/address/Favorite", {
          params: {
            Group_Num: groupNum,
            Group_YN: groupYN,
            page_Row: 100,
            page_Now: 0,
          },
        })
        .then(({ data }) => setAddressFavorite(data))
        .catch(error => {
          console.log(error);
        });
    }
  };
  const getAddressType = () => {
    if (groupYN === "N") {
      axios
        .get("/address/AdrType", {
          params: {
            Group_Num: "",
            Group_YN: groupYN,
            Adr_Type_Nm: "",
          },
        })
        .then(({ data }) => {
          setAddressType(data);
          getAddressTypeMember(data);
        })
        .catch(error => {
          console.log(error);
        });
    } else if (
      groupYN === "Y" &&
      groupNum !== null &&
      groupNum !== undefined &&
      groupNum !== ""
    ) {
      axios
        .get("/address/AdrType", {
          params: {
            Group_Num: groupNum,
            Group_YN: groupYN,
            Adr_Type_Nm: "",
          },
        })
        .then(({ data }) => {
          setAddressType(data);
          getAddressTypeMember(data);
        })
        .catch(error => {
          console.log(error);
        });
    }
  };
  const getAddressTypeMember = adrType => {
    console.log("adrType");
    console.log(adrType);
    let tmpAdrTypeMember = [];
    for (let i = 0; i < adrType.length; i++) {
      if (groupYN === "N") {
        axios
          .get("/address/Filter", {
            params: {
              Group_Num: "",
              Group_YN: groupYN,
              page_Row: 100,
              page_Now: 0,
              Adr_Type_Num: adrType[i].ADR_TYPE_NUM,
            },
          })
          .then(({ data }) =>
            data.map(
              data => (
                tmpAdrTypeMember.push({
                  ADR_NUM: data.ADR_NUM,
                  ADR_NM: data.ADR_NM,
                  ADR_EMAIL: data.ADR_EMAIL,
                  ADR_TYPE_NUM: replaceAdrTypeNum(
                    data.ADR_TYPE_NM,
                    adrType[i].ADR_TYPE_NUM
                  ),
                }),
                console.log(data),
                console.log(tmpAdrTypeMember)
              )
            )
          )
          .catch(error => {
            console.log(error);
          });
      } else if (
        groupYN === "Y" &&
        groupNum !== null &&
        groupNum !== undefined &&
        groupNum !== ""
      ) {
        axios
          .get("/address/Filter", {
            params: {
              Group_Num: groupNum,
              Group_YN: groupYN,
              page_Row: 100,
              page_Now: 0,
              Adr_Type_Num: adrType[i].ADR_TYPE_NUM,
            },
          })
          .then(({ data }) =>
            data.map(
              data => (
                tmpAdrTypeMember.push({
                  ADR_NUM: data.ADR_NUM,
                  ADR_NM: data.ADR_NM,
                  ADR_EMAIL: data.ADR_EMAIL,
                  ADR_TYPE_NUM: replaceAdrTypeNum(
                    data.ADR_TYPE_NM,
                    adrType[i].ADR_TYPE_NUM
                  ),
                }),
                console.log(data),
                console.log(tmpAdrTypeMember)
              )
            )
          )
          .catch(error => {
            console.log(error);
          });
      }
    }
    setAddressTypeMember(tmpAdrTypeMember);
  };

  const replaceAdrTypeNum = (address, num) => {
    const adrTypeList = address.split("/");
    let adrTypeNum = "";

    for (let i = 0; i < adrTypeList.length; i++) {
      let typeNum = adrTypeList[i].replace(/\@.*/, "");
      if (typeNum === num) {
        adrTypeNum = typeNum;
      }
    }
    return adrTypeNum;
  };

  const [checkedInputs, setCheckedInputs] = useState([]);

  const clickCheckbox = (checked, id) => {
    if (checked) {
      setCheckedInputs([...checkedInputs, id]);
    } else {
      setCheckedInputs(checkedInputs.filter(el => el !== id));
    }
  };
  const clickCheckboxAll = (checked, addressList, adrTypeNum) => {
    if (checked) {
      if (adrTypeNum === "") {
        let tmp = [];
        for (let i = 0; i < addressList.length; i++) {
          tmp.push(addressList[i]);
        }
        setCheckedInputs(tmp);
      } else if (adrTypeNum !== "") {
        let tmp = [];
        for (let i = 0; i < addressList.length; i++) {
          if (addressList.length > 0) {
            tmp.push(addressList[i]);
          }
          setCheckedInputs(checkedInputs.concat(tmp));
        }
      }
    } else {
      if (adrTypeNum === "") {
        setCheckedInputs([]);
      } else if (adrTypeNum !== "") {
        setCheckedInputs(
          checkedInputs.filter(address => address.ADR_TYPE_NUM !== adrTypeNum)
        );
      }
    }
  };

  const [sortOrder, setSortOrder] = useState("ASC");
  const clickSortBtn = addressList => {
    if (sortOrder === "ASC") {
      setSortOrder("DESC");
      addressList.reverse(function (a, b) {
        return b.ADR_NM - a.ADR_NM;
      });
    } else {
      setSortOrder("ASC");
      addressList.reverse(function (a, b) {
        return a.ADR_NM - b.ADR_NM;
      });
    }
  };

  const typeMember = adrTypeNum => {
    let tmpArr = [];
    for (let i = 0; i < addressTypeMember.length; i++) {
      if (adrTypeNum === addressTypeMember[i].ADR_TYPE_NUM) {
        tmpArr.push(addressTypeMember[i]);
      }
    }
    return tmpArr;
  };

  const typeSort = (a, b) => {
    let a_Cnt = typeMember(a.ADR_TYPE_NUM).length;
    let b_Cnt = typeMember(b.ADR_TYPE_NUM).length;
    if (a_Cnt > b_Cnt) return -1;
    if (a_Cnt < b_Cnt) return 1;
    return 0;
  };

  const [activeIndex, setActiveIndex] = useState(0);

  const handleTabClick = index => {
    setCheckedInputs([]);
    setActiveIndex(index);
  };

  const tabContArr = [
    {
      tabTitle: (
        <li
          key="address1"
          className={
            activeIndex === 0
              ? "activeParticipantTab participantTab inline-block text-center"
              : "participantTab inline-block text-center"
          }
          onClick={() => handleTabClick(0)}
        >
          기본
        </li>
      ),
      tabCont: (
        <div>
          {addressAll.length > 0 ? (
            <>
              <div
                style={{
                  height: "54px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div style={{ padding: "0 22px" }}>
                  <label htmlFor="Adr_Chk_ALL">
                    <img
                      src={
                        checkedInputs.length === 0
                          ? "/img/icon/common/non_check.png"
                          : "/img/icon/common/release.png"
                      }
                      alt="checkAll"
                    />
                  </label>
                  <input
                    type="checkbox"
                    className="cursor address_checkbox"
                    name="Adr_Chk"
                    id="Adr_Chk_ALL"
                    style={{ display: "none" }}
                    onChange={e => {
                      clickCheckboxAll(e.currentTarget.checked, addressAll, "");
                    }}
                    checked={checkedInputs.length === 0 ? false : true}
                  />
                </div>
                <div
                  style={{
                    font: "normal normal bold 14px/18px Spoqa Han Sans Neo",
                    color: "#2B4AC4",
                  }}
                >
                  전체
                  <div
                    style={{
                      marginLeft: "8px",
                      padding: "0 8px",
                      background: "#2B4AC4",
                      borderRadius: "8px",
                      color: "white",
                      font: "normal normal bold 12px/16px Spoqa Han Sans Neo",
                      display: "inline",
                    }}
                  >
                    {addressAll.length}
                  </div>
                </div>
                <div style={{ paddingLeft: "16px" }}>
                  <button
                    style={{
                      width: "82px",
                      height: "32px",
                      background: "#EDEDF0",
                      borderRadius: "18px",
                      font: "normal normal bold 14px/18px Spoqa Han Sans Neo",
                      border: "none",
                      color: "#3E3E4F",
                      cursor: "pointer",
                    }}
                    onClick={() => clickSortBtn(addressAll)}
                  >
                    <img
                      src="/img/icon/slide/sort.png"
                      alt="sort"
                      style={{ marginRight: "8px", verticalAlign: "bottom" }}
                    />
                    정렬
                  </button>
                </div>
                <div style={{ textAlign: "right", marginLeft: "100px" }}>
                  <button
                    onClick={() => (
                      addCheckedAddressList(checkedInputs), setCheckedInputs([])
                    )}
                    style={{
                      background: "#2B4AC4",
                      borderRadius: "18px",
                      color: "white",
                      width: "113px",
                      height: "36px",
                      border: "none",
                      font: "normal normal bold 14px/18px Spoqa Han Sans Neo",
                      cursor: "pointer",
                      display: checkedInputs.length === 0 ? "none" : "inline",
                    }}
                  >
                    선택추가
                    <img
                      src="/img/icon/slide/add.png"
                      alt="add"
                      style={{ marginLeft: "8px", verticalAlign: "bottom" }}
                    />
                  </button>
                </div>
              </div>
              <div
                className="scrollbar"
                style={{
                  maxHeight: "765px",
                  overflowY: "auto",
                }}
              >
                <table>
                  <tbody className="listTbody">
                    {addressAll.map((data, index) => (
                      <AddressItem
                        key={"all_" + index}
                        addressInfo={data}
                        groupYN={groupYN}
                        groupNum={groupNum}
                        checkedInputs={checkedInputs}
                        clickCheckbox={clickCheckbox}
                        clickAddressItem={clickAddressItem}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <div
              className="text-center"
              style={{
                marginTop: "30px",
              }}
            >
              <img src="/img/icon/profile/host_non.png" alt="hostNon" />
              <div
                style={{
                  marginTop: "16px",
                  font: "normal normal bold 14px/21px Spoqa Han Sans Neo",
                  color: "#3e3e4f",
                }}
              >
                등록된 연락처가 없습니다.
              </div>
            </div>
          )}
        </div>
      ),
    },
    {
      tabTitle: (
        <li
          key="address2"
          className={
            activeIndex === 1
              ? "activeParticipantTab participantTab inline-block text-center"
              : "participantTab inline-block text-center"
          }
          onClick={() => handleTabClick(1)}
        >
          최근
        </li>
      ),
      tabCont: (
        <div>
          {addressRecent.length > 0 ? (
            <>
              <div
                style={{
                  height: "54px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div style={{ padding: "0 22px" }}>
                  <label htmlFor="Adr_Chk_ALL">
                    <img
                      src={
                        checkedInputs.length === 0
                          ? "/img/icon/common/non_check.png"
                          : "/img/icon/common/release.png"
                      }
                      alt="checkAll"
                    />
                  </label>
                  <input
                    type="checkbox"
                    className="cursor address_checkbox"
                    name="Adr_Chk"
                    id="Adr_Chk_ALL"
                    style={{ display: "none" }}
                    onChange={e => {
                      clickCheckboxAll(
                        e.currentTarget.checked,
                        addressRecent,
                        ""
                      );
                    }}
                    checked={checkedInputs.length === 0 ? false : true}
                  />
                </div>
                <div
                  style={{
                    font: "normal normal bold 14px/18px Spoqa Han Sans Neo",
                    color: "#2B4AC4",
                  }}
                >
                  최근
                  <div
                    style={{
                      marginLeft: "8px",
                      padding: "0 8px",
                      background: "#2B4AC4",
                      borderRadius: "8px",
                      color: "white",
                      font: "normal normal bold 12px/16px Spoqa Han Sans Neo",
                      display: "inline",
                    }}
                  >
                    {addressRecent.length}
                  </div>
                </div>
                <div style={{ paddingLeft: "16px" }}>
                  <button
                    style={{
                      width: "82px",
                      height: "32px",
                      background: "#EDEDF0",
                      borderRadius: "18px",
                      font: "normal normal bold 14px/18px Spoqa Han Sans Neo",
                      border: "none",
                      color: "#3E3E4F",
                      cursor: "pointer",
                    }}
                    onClick={() => clickSortBtn(addressRecent)}
                  >
                    <img
                      src="/img/icon/slide/sort.png"
                      alt="sort"
                      style={{ marginRight: "8px", verticalAlign: "bottom" }}
                    />
                    정렬
                  </button>
                </div>
                <div style={{ textAlign: "right", marginLeft: "100px" }}>
                  <button
                    onClick={() => (
                      addCheckedAddressList(checkedInputs), setCheckedInputs([])
                    )}
                    style={{
                      background: "#2B4AC4",
                      borderRadius: "18px",
                      color: "white",
                      width: "113px",
                      height: "36px",
                      border: "none",
                      font: "normal normal bold 14px/18px Spoqa Han Sans Neo",
                      cursor: "pointer",
                      display: checkedInputs.length === 0 ? "none" : "inline",
                    }}
                  >
                    선택추가
                    <img
                      src="/img/icon/slide/add.png"
                      alt="add"
                      style={{ marginLeft: "8px", verticalAlign: "bottom" }}
                    />
                  </button>
                </div>
              </div>
              <div
                className="scrollbar"
                style={{
                  maxHeight: "765px",
                  overflowY: "auto",
                }}
              >
                <table>
                  <tbody
                    className="listTbody"
                    style={{ maxHeight: "784px", overflowY: "auto" }}
                  >
                    {addressRecent.map((data, index) => (
                      <AddressItem
                        key={"recent_" + index}
                        addressInfo={data}
                        groupYN={groupYN}
                        groupNum={groupNum}
                        checkedInputs={checkedInputs}
                        clickCheckbox={clickCheckbox}
                        clickAddressItem={clickAddressItem}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <div
              className="text-center"
              style={{
                marginTop: "30px",
              }}
            >
              <img src="/img/icon/profile/host_non.png" alt="hostNon" />
              <div
                style={{
                  marginTop: "16px",
                  font: "normal normal bold 14px/21px Spoqa Han Sans Neo",
                  color: "#3e3e4f",
                }}
              >
                최근 회의 3건 중 연락처에 등록된 참석자가 없습니다.
              </div>
            </div>
          )}
        </div>
      ),
    },
    {
      tabTitle: (
        <li
          key="address3"
          className={
            activeIndex === 2
              ? "activeParticipantTab participantTab inline-block text-center"
              : "participantTab inline-block text-center"
          }
          onClick={() => handleTabClick(2)}
        >
          즐겨찾기
        </li>
      ),
      tabCont: (
        <div>
          {addressFavorite.length > 0 ? (
            <>
              <div
                style={{
                  height: "54px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div style={{ padding: "0 22px" }}>
                  <label htmlFor="Adr_Chk_ALL">
                    <img
                      src={
                        checkedInputs.length === 0
                          ? "/img/icon/common/non_check.png"
                          : "/img/icon/common/release.png"
                      }
                      alt="checkAll"
                    />
                  </label>
                  <input
                    type="checkbox"
                    className="cursor address_checkbox"
                    name="Adr_Chk"
                    id="Adr_Chk_ALL"
                    style={{ display: "none" }}
                    onChange={e => {
                      clickCheckboxAll(
                        e.currentTarget.checked,
                        addressFavorite,
                        ""
                      );
                    }}
                    checked={checkedInputs.length === 0 ? false : true}
                  />
                </div>
                <div
                  style={{
                    font: "normal normal bold 14px/18px Spoqa Han Sans Neo",
                    color: "#2B4AC4",
                  }}
                >
                  즐겨찾기
                  <div
                    style={{
                      marginLeft: "8px",
                      padding: "0 8px",
                      background: "#2B4AC4",
                      borderRadius: "8px",
                      color: "white",
                      font: "normal normal bold 12px/16px Spoqa Han Sans Neo",
                      display: "inline",
                    }}
                  >
                    {addressFavorite.length}
                  </div>
                </div>
                <div style={{ paddingLeft: "16px" }}>
                  <button
                    style={{
                      width: "82px",
                      height: "32px",
                      background: "#EDEDF0",
                      borderRadius: "18px",
                      font: "normal normal bold 14px/18px Spoqa Han Sans Neo",
                      border: "none",
                      color: "#3E3E4F",
                      cursor: "pointer",
                    }}
                    onClick={() => clickSortBtn(addressFavorite)}
                  >
                    <img
                      src="/img/icon/slide/sort.png"
                      alt="sort"
                      style={{ marginRight: "8px", verticalAlign: "bottom" }}
                    />
                    정렬
                  </button>
                </div>
                <div style={{ textAlign: "right", marginLeft: "100px" }}>
                  <button
                    onClick={() => (
                      addCheckedAddressList(checkedInputs), setCheckedInputs([])
                    )}
                    style={{
                      background: "#2B4AC4",
                      borderRadius: "18px",
                      color: "white",
                      width: "113px",
                      height: "36px",
                      border: "none",
                      font: "normal normal bold 14px/18px Spoqa Han Sans Neo",
                      cursor: "pointer",
                      display: checkedInputs.length === 0 ? "none" : "inline",
                    }}
                  >
                    선택추가
                    <img
                      src="/img/icon/slide/add.png"
                      alt="add"
                      style={{ marginLeft: "8px", verticalAlign: "bottom" }}
                    />
                  </button>
                </div>
              </div>
              <div
                className="scrollbar"
                style={{
                  maxHeight: "765px",
                  overflowY: "auto",
                }}
              >
                <table>
                  <tbody className="listTbody">
                    {addressFavorite.map((data, index) => (
                      <AddressItem
                        key={"favorite_" + index}
                        addressInfo={data}
                        groupYN={groupYN}
                        groupNum={groupNum}
                        checkedInputs={checkedInputs}
                        clickCheckbox={clickCheckbox}
                        clickAddressItem={clickAddressItem}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <div
              className="text-center"
              style={{
                marginTop: "30px",
              }}
            >
              <img src="/img/icon/profile/host_non.png" alt="hostNon" />
              <div
                style={{
                  marginTop: "16px",
                  font: "normal normal bold 14px/21px Spoqa Han Sans Neo",
                  color: "#3e3e4f",
                }}
              >
                즐겨찾기한 연락처가 없습니다.
              </div>
            </div>
          )}
        </div>
      ),
    },
    {
      tabTitle: (
        <li
          key="address4"
          className={
            activeIndex === 3
              ? "activeParticipantTab participantTab inline-block text-center"
              : "participantTab inline-block text-center"
          }
          onClick={() => handleTabClick(3)}
        >
          구분
        </li>
      ),
      tabCont: (
        <div style={{ maxHeight: "784px" }}>
          {addressType.length > 0 ? (
            <div>
              <div
                style={{
                  height: "54px",
                  lineHeight: "54px",
                  paddingLeft: "32px",
                  clear: "both",
                }}
              >
                <div
                  style={{
                    float: "left",
                    font: "normal normal bold 14px/54px Spoqa Han Sans Neo",
                    color: "#2B4AC4",
                  }}
                >
                  전체 구분 태그
                  <div
                    style={{
                      marginLeft: "8px",
                      padding: "0 8px",
                      background: "#2B4AC4",
                      borderRadius: "8px",
                      color: "white",
                      font: "normal normal bold 12px/16px Spoqa Han Sans Neo",
                      display: "inline",
                    }}
                  >
                    {addressType.length}
                  </div>
                </div>
                <div style={{ float: "right", marginRight: "24px" }}>
                  <button
                    onClick={() => (
                      addCheckedAddressList(checkedInputs), setCheckedInputs([])
                    )}
                    style={{
                      background: "#2B4AC4",
                      borderRadius: "18px",
                      color: "white",
                      width: "113px",
                      height: "36px",
                      border: "none",
                      font: "normal normal bold 14px/18px Spoqa Han Sans Neo",
                      cursor: "pointer",
                      display: checkedInputs.length === 0 ? "none" : "inline",
                    }}
                  >
                    선택추가
                    <img
                      src="/img/icon/slide/add.png"
                      alt="add"
                      style={{ marginLeft: "8px", verticalAlign: "bottom" }}
                    />
                  </button>
                </div>
              </div>
              <div
                className="scrollbar"
                style={{
                  maxHeight: "784px",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                {addressType.sort(typeSort).map((type, index) => (
                  <AdressTypeItem
                    key={"type_" + index}
                    addressInfo={type}
                    typeMemberList={typeMember(type.ADR_TYPE_NUM)}
                    checkedInputs={checkedInputs}
                    clickCheckbox={clickCheckbox}
                    clickCheckboxAll={clickCheckboxAll}
                    clickAddressItem={clickAddressItem}
                  />
                ))}
              </div>
            </div>
          ) : (
            <div
              className="text-center"
              style={{
                marginTop: "30px",
              }}
            >
              <img src="/img/icon/profile/host_non.png" alt="hostNon" />
              <div
                style={{
                  marginTop: "16px",
                  font: "normal normal bold 14px/21px Spoqa Han Sans Neo",
                  color: "#3e3e4f",
                }}
              >
                구분된 연락처가 없습니다.
              </div>
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <div
      className="participantSidebar"
      style={{
        transform: `translatex(${xPosition}px)`,
        width: width,
        position: "absolute",
        zIndex: yPosition,
        minHeight: height,
      }}
    >
      <div className="content">
        <div style={{ width: "456px", height: "72px", color: "#2B4AC4" }}>
          <p
            style={{
              marginLeft: "16px",
              display: "inline-block",
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            연락처
          </p>
          <p
            style={{
              marginLeft: "16px",
              display: "inline-block",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            연락처를 선택하면 참석자로 추가됩니다.
          </p>
          <img
            style={{
              float: "right",
              marginRight: "16px",
              marginTop: "16px",
              display: "inline-block",
              cursor: "pointer",
            }}
            src="/img/icon/common/close.png"
            alt="close"
            onClick={() => handlePartiSidebar(false)}
          />
        </div>
        <div>
          <ul>
            {tabContArr.map(section => {
              return section.tabTitle;
            })}
          </ul>
          <div>{tabContArr[activeIndex].tabCont}</div>
        </div>
      </div>
    </div>
  );
}

export default ParticipantSidebar;
