import React, { useEffect, useState } from 'react'
import Supsuggestion from './support/supsuggestion'
import Supfaq from './support/supfaq'
import Supdirectquestion from './support/supdirectquestion'
import Supmyquestion from './support/supmyquestion'
import Supannouncement from './support/supannouncement'
import "css/profilesupportdetail.css"
import ProfileSupport from './profileSupport'
import { useDispatch, useSelector } from 'react-redux'
import { updatePagingsupInfo } from 'actions'
import pagingsupInfo from 'reducers/common/pagingsupInfo'
/**
 * Version : 1.0
 * 파일명 : profileSupportdetal.js
 * 작성일자 : 2022-03-30
 * 작성자 : 정지윤
 * 설명 : 고객센터 작동페이지
 *  수정일자 : 2022-04-12
 * 수정자 : 정지윤
 * 수정내역 : 페이징 초기화 추가
*/
function ProfileSupportdetail(props) {
 
const [supcategory, setsupcatgory] = useState(props.details);


  const PAGING_STATE = useSelector(state => state.pagingsupInfoReducer);


useEffect(()=>{
  if(props.details!==''){
    setsupcatgory(props.details);
  }else{
    return;
  }
},[props.details])

const dispatch = useDispatch();

const [announcmentnum, setannouncement] = useState('');

const onClickmenu = (value, announcenum) =>{
    setsupcatgory(value);
    props.setgodetail("");
    if(announcenum === null || announcenum === undefined){
      setannouncement("")
    } else {
      setannouncement(announcenum)
    }
     dispatch(updatePagingsupInfo({
       ...PAGING_STATE,
       PAGE_COUNT: 0})); 
}


const menuList ={
    0: <Supfaq />,
    1: <Supdirectquestion />,
    2: <Supsuggestion />,
    3: <Supmyquestion />,
    4: <Supannouncement kkk={announcmentnum}/>,
}

const menuConvert = (type) =>{
  switch (type) {
    case 0:
      return (
        <div className='titleFAQ'>
        <div className='SubtitleFAQ'>
          FAQ
        </div>
        <div className='Supmenu0'>
        <div className='cursor'><div className='Supfaq' value='0' onClick={() =>onClickmenu(0)}>FAQ</div></div>
        <div className='cursor'><div className='Supsuggestion' value='2'onClick={() =>onClickmenu(2) }>고객의 소리</div></div>
        <div className='cursor'><div className='Supdirectquestion' value='1'onClick={() =>onClickmenu(1) }>1:1 문의</div></div>
        <div className='cursor'><div className='Supmyquestion' value='3'onClick={() =>onClickmenu(3) }>내 문의함</div></div>
        <div className='cursor'><div className='Supannouncement' value='4'onClick={() =>onClickmenu(4) }>공지사항</div></div>
          </div>
        </div>
      );
      case 1:
      return (
        <div className='titleDQ'>
           <div className='SubtitleDQ'>
            1:1 문의
          </div>
          <div className='Supmenu1'>
          <div className='cursor'><div className='Supfaq' value='0' onClick={() =>onClickmenu(0) }>FAQ</div></div>
        <div className='cursor'><div className='Supsuggestion' value='2'onClick={() =>onClickmenu(2) }>고객의 소리</div></div>
        <div className='cursor'><div className='Supdirectquestion' value='1'onClick={() =>onClickmenu(1) }>1:1 문의</div></div>
        <div className='cursor'><div className='Supmyquestion' value='3'onClick={() =>onClickmenu(3) }>내 문의함</div></div>
        <div className='cursor'><div className='Supannouncement' value='4'onClick={() =>onClickmenu(4) }>공지사항</div></div>
          </div>
        </div>
      );
      case 2:
        return (
          <div className='titleSuggest'>
            <div className='SubtitleSuggest'>
              고객의 소리
            </div> 
            <div className='Supmenu2'>
            <div className='cursor'><div className='Supfaq' value='0' onClick={() =>onClickmenu(0) }>FAQ</div></div>
        <div className='cursor'><div className='Supsuggestion' value='2'onClick={() =>onClickmenu(2) }>고객의 소리</div></div>
        <div className='cursor'><div className='Supdirectquestion' value='1'onClick={() =>onClickmenu(1) }>1:1 문의</div></div>
        <div className='cursor'><div className='Supmyquestion' value='3'onClick={() =>onClickmenu(3) }>내 문의함</div></div>
        <div className='cursor'><div className='Supannouncement' value='4'onClick={() =>onClickmenu(4) }>공지사항</div></div>
            </div>
        </div>
        );
        case 3:
          return (
            <div className='titlemyDQ'>
              <div className='SubtitlemyDQ'>
              내 문의함
             </div> 
             <div className='Supmenu3'>
             <div className='cursor'><div className='Supfaq' value='0' onClick={() =>onClickmenu(0) }>FAQ</div></div>
        <div className='cursor'><div className='Supsuggestion' value='2'onClick={() =>onClickmenu(2) }>고객의 소리</div></div>
        <div className='cursor'><div className='Supdirectquestion' value='1'onClick={() =>onClickmenu(1) }>1:1 문의</div></div>
        <div className='cursor'><div className='Supmyquestion' value='3'onClick={() =>onClickmenu(3) }>내 문의함</div></div>
        <div className='cursor'><div className='Supannouncement' value='4'onClick={() =>onClickmenu(4) }>공지사항</div></div>
            </div>
            </div>
          );
          case 4:
            return (
              <div className='titleannouncement'>
              <div className='Subtitleannouncement'>
              공지사항
             </div> 
             <div className='Supmenu4'>
             <div className='cursor'><div className='Supfaq' value='0' onClick={() =>onClickmenu(0) }>FAQ</div></div>
        <div className='cursor'><div className='Supsuggestion' value='2'onClick={() =>onClickmenu(2) }>고객의 소리</div></div>
        <div className='cursor'><div className='Supdirectquestion' value='1'onClick={() =>onClickmenu(1) }>1:1 문의</div></div>
        <div className='cursor'><div className='Supmyquestion' value='3'onClick={() =>onClickmenu(3) }>내 문의함</div></div>
        <div className='cursor'><div className='Supannouncement' value='4'onClick={() =>onClickmenu(4) }>공지사항</div></div>
            </div>
              </div>
            );    
        default :
        return  (   
          <div>
            <ProfileSupport onClickmenu={onClickmenu} />
          </div>
        );
        
  }
}
  return (
    <div className='SupcontentAll'>

          <div className='Supheader'>
         {menuConvert(supcategory)}
        </div>
        {supcategory === "5" ? <div></div> :
          <div>{supcategory === '' ? <div></div>
          :     <div className='SupContentArea'>
          {menuList[supcategory]}
            </div>
            }</div>
        }
    
    </div>
    
  )
}

export default ProfileSupportdetail