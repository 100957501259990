/**
 * Version : 1.0
 * 작성일자 : 2022-01-13
 * 작성자 : 권도훈
 * 설명 : 회의 영상 정보
 * 수정일자 : 2022-04-13
 * 수정자 : 권도훈
 * 수정내역 : 결제 완료 팝업 수정
 */
const initalState = {
    REFRESH: false,
    DONE_POPUP: false,
    TODAY: "",
    PAY_DT: "",
    COUNT: "",
    PRODUCT_NM: ""
};

const profileInfo = (state = initalState, action) => {
    switch (action.type) {
        case "profileInfo/UPDATE":
            return {
                ...state,
                REFRESH: true,
                DONE_POPUP: false
            };
        case "profileInfo/RESET":
            return {
                ...state,
                REFRESH: false,
                DONE_POPUP: false
            };
        case "profileInfo/OPEN_POPUP":
            return {
                REFRESH: false,
                DONE_POPUP: true,
                TODAY: action.DATA.TODAY,
                PAY_DT: action.DATA.PAY_DT,
                COUNT: action.DATA.COUNT,
                PRODUCT_NM: action.DATA.PRODUCT_NM
            };
        case "profileInfo/CLOSE_POPUP":
            return {
                ...state,
                REFRESH: true,
                DONE_POPUP: false
            };
        default:
            return state;
    }
};

export default profileInfo;
