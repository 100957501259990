import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import ReactModal from "react-modal";
import GroupListPopup from "component/tag/popup/common/groupListPopup";
/**
 * Version : 1.0
 * 파일명 : setGroupLicensePopup.js
 * 작성일자 : 2021-12-16
 * 작성자 : 강연승
 * 설명 : 라이선스 그룹 할당 팝업
 * 수정일자 : 2022-03-04
 * 수정자 : 강연승
 * 수정내역 : 스크롤바 css 변경
 */
function SetGroupLicensePopup(props) {
  const [groupList, setGroupList] = useState([]);
  /**
   * Set GroupList
   */
  const getGroupList = useCallback(() => {
    axios.get("/group/selectCreateGroupList").then(res => {
      setGroupList(res.data[0].CREATEGROUPLIST);
    });
    // .catch((error) => {
    //   console.log(error);
    // });
  }, []);

  useEffect(() => {
    getGroupList();
  }, [getGroupList]);

  const [selectGroup, setSelectGroup] = useState({});
  const [searchValue, setSearchValue] = useState({
    GROUP_NM: "",
  });
  const onChangeSearchInput = e => {
    if (!groupListPopup) {
      setGroupListPopup(true);
    }
    const { name, value } = e.target;
    setSearchValue({
      ...searchValue,
      [name]: value,
    });
    let searchGroup = groupList.find(i => i.GROUP_NM === e.target.value);
    if (searchGroup !== undefined) {
      setSelectGroup({
        GROUP_NUM: searchGroup.GROUP_NUM,
        GROUP_NM: searchGroup.GROUP_NM,
      });
    } else {
      setSelectGroup({ GROUP_NUM: "", GROUP_NM: "" });
    }
  };
  const handleKeyPress = ({ key }) => {
    if (key === "Enter") {
      onSelectGroup();
    }
  };
  const onSelectGroup = () => {
    let searchGroup = groupList.find(i => i.GROUP_NM === searchValue.GROUP_NM);
    if (searchGroup === undefined) {
      setGroupListPopup(false);
      return;
    } else if (searchGroup !== undefined) {
      setGroupListPopup(false);
      setSelectGroup({
        GROUP_NUM: searchGroup.GROUP_NUM,
        GROUP_NM: searchGroup.GROUP_NM,
      });
      setSearchValue({ GROUP_NM: searchGroup.GROUP_NM });
      setIsActive(true);
    }
  };
  const clickGroupItem = (num, nm) => {
    setSelectGroup({ GROUP_NUM: num, GROUP_NM: nm });
    setSearchValue({ GROUP_NM: nm });
    setIsActive(true);
  };
  const [groupListPopup, setGroupListPopup] = useState(false);
  const handleGroupListPopup = type => {
    if (type === true) {
      setGroupListPopup(true);
    } else if (type === false) {
      setGroupListPopup(false);
    }
  };

  const clickBackgound = () => {
    if (groupListPopup) {
      setGroupListPopup(false);
    }
  };

  const [isActive, setIsActive] = useState(false);
  const activeBtn = e => {
    if (groupList.find(i => i.GROUP_NM === e.target.value)) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  };

  const dialogClose = () => {
    props.close();
    if (isActive) {
      setIsActive(false);
    }
    if (groupListPopup) {
      setGroupListPopup(false);
    }
    setSelectGroup({
      GROUP_NUM: "",
      GROUP_NM: "",
    });
    setSearchValue({ GROUP_NM: "" });
  };

  return (
    <ReactModal
      isOpen={props.isShow} //모달 Open(Boolean)
      className="setGroupLicensePopup" //모달 ClassName
      overlayClassName="AlertOverlay" //모달오버레이 ClassName
      animationType="fade"
      transparent={true}
      closeTimeoutMS={200}
    >
      <div onClick={clickBackgound}>
        <div className="setGroupLicensePopup_header">
          <div className="setGroupLicensePopupTitle">회의 이용권 할당</div>
          <img
            className="cursor"
            src="/img/icon/landing/close.png"
            alt="close"
            onClick={dialogClose}
          />
        </div>
        <div
          className="text-center"
          style={{
            background: "#F6F7FC 0% 0% no-repeat padding-box",
            borderRadius: "12px",
            padding: "24px 0 32px 0",
          }}
        >
          <div
            style={{
              marginBottom: "24px",
              color: "#2B4AC4",
              font: "normal normal bold 20px/27px Spoqa Han Sans Neo",
            }}
          >
            그룹에 회의 이용권 할당하기
          </div>

          <div
            style={{
              marginTop: "24px",
              marginBottom: "32px",
              color: "#3E3E4F",
              font: "normal normal bold 14px/21px Spoqa Han Sans Neo",
            }}
          >
            <div>해당 회의 이용권이 특정 그룹에 할당되어있지 않습니다.</div>
            <div>
              그룹에 할당을 원하실 경우, 그룹명을 검색하여 선택해주세요.
            </div>
          </div>

          <input
            className="groupSearchInput"
            name="GROUP_NM"
            placeholder="그룹명을 입력해주세요."
            onChange={e => (onChangeSearchInput(e), activeBtn(e))}
            onKeyPress={handleKeyPress}
            onFocus={() => handleGroupListPopup(true)}
            value={searchValue.GROUP_NM}
            onClick={e => e.stopPropagation()}
            autoComplete="off"
          />

          <GroupListPopup
            className="groupListPopup scrollbar"
            open={groupListPopup}
            groupList={groupList}
            clickGroupItem={clickGroupItem}
            search={searchValue.GROUP_NM}
          />

          <button
            className={
              isActive
                ? "licenseGroupSettingBtnActive"
                : "licenseGroupSettingBtn"
            }
            onClick={() => (
              props.setGroupLicense(props.license, selectGroup), dialogClose()
            )}
            disabled={!isActive}
          >
            그룹에 할당하기
          </button>
        </div>
      </div>
    </ReactModal>
  );
}

export default SetGroupLicensePopup;
