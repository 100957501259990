/**
 * Import List
 */
import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
/**
 * Version : 1.0
 * 파일명 : addressPopupTextField.js
 * 작성일자 : 2021-09-30
 * 작성자 : 권도훈
 * 설명 : 주소록 팝업 텍스트 필드
 * 수정일자 : 2022-02-24
 * 수정자 : 권도훈
 * 수정내역 : 자동 포커스 추가
*/
const useStyles = makeStyles(() => ({
    root: {
        width: "100%",
    },
    field: {
        fontSize: "12px",
        fontWeight: "bold",
        '& div': {
            height: "2.2rem"
        },
        '& p': {
            color: "red",
        }
    }
}));

function AddressPopupTextField(props) {
    const classes = useStyles();

    const [helperText, setHelperText] = useState("");

    const checkCount = e => {
        if (e.target.value.length < 51) {
            props.onChange(e.target.value);
            setHelperText("");
        } else {
            setHelperText("50글자 이상은 입력하실 수 없습니다.");
        }
    }

    return (
        <Box component="form" noValidate autoComplete="off" className={classes.root}>
            <TextField
                className={classes.field}
                label={props.label}
                type="text"
                autoComplete="current-password"
                variant="outlined"
                size="small"
                fontSize="12px"
                fullWidth
                value={props.value !== undefined ? props.value : ""}
                onChange={e => checkCount(e)}
                onKeyDown={e => {
                    if (e.key === "Enter") {
                        e.preventDefault();
                        return;
                    }
                }}
                helperText={helperText}
                InputProps={{
                    readOnly: props.type === "READ" ? true : false,
                }}
                InputLabelProps={{
                    shrink: true,
                }}
                autoFocus={props.autoFocus}
            />
        </Box>
    )
}

export default AddressPopupTextField
