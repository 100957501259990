import React, { useCallback, useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import Menu from "component/common/menu";
import Header from "component/common/header";
import Main from "component/page/my/schedule/main";
import Address from "component/page/my/address/address";
import Record from "component/page/my/record/record";
import Empty from "component/page/empty";
import Sidebar from "component/common/sidebar/sidebar";
import MeetingNow from "component/common/sidebar/meetingNow";
import Profile from "component/page/my/profile/profile";
import FirstLoginPopup from "component/tag/popup/my/home/firstLoginPopup";
import FreeAccountInfoPopup from "component/tag/popup/my/home/freeAccountInfoPopup";
import RequireChangePw from "component/tag/popup/my/home/requireChangePw";
import Data from "component/page/my/data/data";
import Dashboard from "component/page/my/dashboard/dashboard";
import Loading from "component/tag/loading/loading";
import GroupList from "component/page/group/groupList";
import GroupMain from "component/page/group/groupMain";
import GroupAddress from "component/page/group/address/address";
import GroupRecord from "component/page/group/record/record";
import GroupProfile from "component/page/group/profile/groupProfile";
import GroupDashboard from "component/page/group/dashboard/dashboard";
import Cookies from "universal-cookie";
import GroupData from "component/page/group/data/data";
import { useDispatch } from "react-redux";
import { openAlert, closeAlert, updateGroupInfo } from "actions/index";
import IsOutstandingPopup from "component/tag/popup/my/home/isOutstandingPopup";
import CoachMark from "component/tag/popup/my/home/coachMark";
/**
 * Version : 1.0
 * 파일명 : App.js
 * 작성일자 : 2022-02-03
 * 작성자 : 권도훈
 * 설명 : Pebble 솔루션 메인
 * 수정일자 : 2022-05-23
 * 수정자 : 권도훈
 * 수정내역 : 비밀번호 변경 팝업 처리 수정
 * 수정일자 : 2022-06-08
 * 수정자 : 권도훈
 * 수정내역 : 첫 로그인 처리 수정
 */
function App() {
  /**
   * DISPATCH
   */
  const dispatch = useDispatch();
  /**
   * REDUX
   */
  const REDUCER_STATE = useSelector(state => state.freeLicenseInfoReducer);
  const USER_STATE = useSelector(state => state.userInfoReducer);
  const GROUP_STATE = useSelector(state => state.groupInfoReducer);
  /**
   * TRANSLATION
   */
  const { t, i18n } = useTranslation();
  /**
   * HISTORY
   */
  const history = useHistory();
  /**
   * GROUP UPDATE
   */

  if (GROUP_STATE.GROUP_YN === "") {
    let tmp = history.location.pathname.split("/");
    if (tmp[2] === "group" && tmp[4] !== undefined) {
      axios
        .get("/group/selectGroupInfo", {
          params: {
            GROUP_NUM: tmp[4],
          },
        })
        .then(res => {
          if (res.status === 200) {
            const config = {
              GROUP_NUM: res.data[0].GROUP_NUM,
              GROUP_NM: res.data[0].GROUP_NM,
              GROUP_COLOR: res.data[0].GROUP_INFO_COLOR,
              AUTH_CD: res.data[0].AUTH_CD,
              GROUP_CATEGORY: res.data[0].CTGRY_CD,
              GROUP_IMG: res.data[0].GROUP_IMG,
              GROUP_YN: "Y",
              REC_YN: res.data[0].REC_YN,
              DOWNLOAD_YN: res.data[0].DOWNLOAD_YN,
              PLAY_YN: res.data[0].PLAY_YN,
              HOST_CNT: res.data[0].HOST_CNT,
              LICENSE_CNT: res.data[0].LICENSE_CNT,
              D_DAY: res.data[0].D_DAY,
              CREATE_DT: res.data[0].CREATE_DT,
            };
            dispatch(updateGroupInfo(config));
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  /**
   * 설정
   */
  const [loading, setLoading] = useState(false);
  const [isInput, setIsInput] = useState(false);
  const [dataState, setDataState] = useState(0);
  const [bookmarkState, setBookmarkState] = useState(0);
  const [resSidebarType, setResSidebarType] = useState("res");
  const [calendarApi, setCalendarApi] = useState();
  const [meetingInfo, setMeetingInfo] = useState({
    id: "",
    title: "",
    displayDate: "",
    displayTime: "",
    startTime: "",
    endTime: "",
    participants: "",
    content: "",
    apiType: "",
    recYN: "",
    fileInfoList: "",
  });
  const [bookmarkInputInfo, setBookmarkInputInfo] = useState();
  /**
   * 무료 계정 정보
   */
  const [popupRender, setPopupRender] = useState(<></>);
  /**
   * GROUP NUM
   */
  const [groupNum, setGroupNum] = useState("");

  const [coachmark, setCoachmark] = useState(false);
  /**
   * 비밀변호 변경 주기 체크
   */
  const requireChangePassword = useCallback(() => {
    const loginTypeNm = process.env.REACT_APP_COOKIE_TYPE;

    const cookies = new Cookies();

    const loginType = cookies.get(loginTypeNm);

    if (loginType !== "PEBBLE") {
      isOutstanding();
    } else {
      axios
        .get("/user/password", null)
        .then(res => {
          if (res.status === 200) {
            if (res.data === true) {
              setPopupRender(
                <RequireChangePw />
              );
            } else {
              isOutstanding();
            }
          } else {
            isOutstanding();
          }
        })
        .catch(() => {
          isOutstanding();
        });
    }
  }, []);
  //미결제 알림
  const isOutstanding = () => {
    axios
      .get("/payment/outstanding/popup", null)
      .then(res => {
        if (res.status === 200 && !res.data.isDisabled) {
          setPopupRender(
            <IsOutstandingPopup expireDate={res.data.expireDate} />
          );
        } else {
          setPopupRender(<></>);
        }
      })
      .catch(() => {
        setPopupRender(<></>);
      });
  };
  /**
   * SET USER LANGUAGE OPTION
   */
  useEffect(() => {
    const lang = USER_STATE.LANGUAGE;

    if (lang !== "") {
      i18n.changeLanguage(lang);
    }
  }, [USER_STATE.LANGUAGE, i18n]);

  useEffect(() => {
    if (coachmark) {
      setPopupRender(
        <CoachMark />
      );
      setCoachmark(false);
    }
  }, [coachmark]);
  /**
   * 무료 계정 정보 팝업 렌더링
   */
  useEffect(() => {
    axios.get("/user/isFree", null).then(res => {
      if (res.status === 200) {
        const freeInfo = res.data;

        if (freeInfo.FIRST_LOGIN === "Y") {
          setPopupRender(
            <FirstLoginPopup openCoachMark={() => setCoachmark(true)} />
          );

        } else if (freeInfo.FREE_ACCOUNT === "TRUE" && REDUCER_STATE.DISPLAY === true) {
          if (freeInfo.TRIAL_POPUP === "N" && Number(freeInfo.FREE_DAY) >= 20) {
            setPopupRender(
              <FreeAccountInfoPopup
                freeInfo={freeInfo}
                requireChangePassword={requireChangePassword}
              />
            );
          } else if (freeInfo.EXPIRED_POPUP === "N" && freeInfo.FREE_DAY === "30") {
            setPopupRender(
              <FreeAccountInfoPopup
                freeInfo={freeInfo}
                requireChangePassword={requireChangePassword}
              />
            );
          } else {
            requireChangePassword();
          }
        } else if (freeInfo.FREE_ACCOUNT === "FALSE" && REDUCER_STATE.DISPLAY === true) {
          if (freeInfo.TRIAL_POPUP === "N" || freeInfo.EXPIRED_POPUP === "N") {
            setPopupRender(
              <FreeAccountInfoPopup
                freeInfo={freeInfo}
                requireChangePassword={requireChangePassword}
              />
            );
          } else {
            requireChangePassword();
          }
        } else {
          requireChangePassword();
        }
      }
    });
  }, [REDUCER_STATE.DISPLAY, requireChangePassword]);

  useEffect(() => {
    const cookies = new Cookies();

    //cookie
    const domain = process.env.REACT_APP_COOKIE_DOMAIN;
    const refreshNm = process.env.REACT_APP_COOKIE_REFRESH;
    const authNm = process.env.REACT_APP_COOKIE_AUTH;
    const loginTypeNm = process.env.REACT_APP_COOKIE_TYPE;

    if (cookies.get(loginTypeNm) === undefined) {
      cookies.remove(
        refreshNm,
        {
          path: "/",
          domain: domain
        }
      );

      cookies.remove(
        authNm,
        {
          path: "/",
          domain: domain
        }
      );

      window.location.href = "/";
    }
  }, []);

  const handleDataState = () => {
    setDataState(dataState + 1);
  };

  const handleBookmarkReload = () => {
    setBookmarkState(bookmarkState + 1);
  };

  return (
    <div
      id="menu_toggleBtn_item"
      className="m-center"
      style={{
        backgroundImage: "url(/img/background.png)",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "1680px",
        height: "1050px",
        display: "flex",
      }}
    >
      {popupRender}

      <Menu />

      <div className="content_Div">
        <Header />

        <>
          <Switch>
            <Route exact path="/app/home">
              <div
                style={{
                  display: loading ? "block" : "none",
                  zIndex: "997",
                  position: "absolute",
                  width: "1483px",
                  height: "954px",
                }}
              >
                <Loading />
              </div>
              <Main
                meetingInfo={meetingInfo}
                setMeetingInfo={setMeetingInfo}
                bookmarkInputInfo={bookmarkInputInfo}
                setBookmarkInputInfo={setBookmarkInputInfo}
                setLoading={setLoading}
                isInput={isInput}
                setIsInput={setIsInput}
                dataState={dataState}
                handleDataState={handleDataState}
                bookmarkState={bookmarkState}
                handleBookmarkReload={handleBookmarkReload}
                resSidebarType={resSidebarType}
                setResSidebarType={setResSidebarType}
                calendarApi={calendarApi}
                setCalendarApi={setCalendarApi}
              />
            </Route>
            <Route exact path="/app/home/meetingNow">
              <MeetingNow />
            </Route>
            <Route exact path="/app/home/address">
              <Address />
            </Route>
            <Route exact path="/app/home/record">
              <Record />
            </Route>

            <Route exact path="/app/home/profile">
              <Profile />
            </Route>

            <Route exact path="/app/home/data">
              <Data />
            </Route>

            <Route exact path="/app/home/dashboard">
              <Dashboard />
            </Route>

            <Route exact path="/app/group">
              <div
                style={{
                  display: loading ? "block" : "none",
                  zIndex: "997",
                  position: "absolute",
                  width: "1010px",
                  height: "954px",
                }}
              >
                <Loading />
              </div>
              <GroupList />
            </Route>
            <Route exact path="/app/group/groupMain/:key">
              <div
                style={{
                  display: loading ? "block" : "none",
                  zIndex: "997",
                  position: "absolute",
                  width: "1483px",
                  height: "954px",
                }}
              >
                <Loading />
              </div>
              <GroupMain
                setGroupNum={setGroupNum}
                meetingInfo={meetingInfo}
                setMeetingInfo={setMeetingInfo}
                bookmarkInputInfo={bookmarkInputInfo}
                setBookmarkInputInfo={setBookmarkInputInfo}
                calendarApi={calendarApi}
                setCalendarApi={setCalendarApi}
                setLoading={setLoading}
                isInput={isInput}
                setIsInput={setIsInput}
                resSidebarType={resSidebarType}
                setResSidebarType={setResSidebarType}
                dataState={dataState}
                handleDataState={handleDataState}
                bookmarkState={bookmarkState}
                handleBookmarkReload={handleBookmarkReload}
              />
            </Route>
            <Route exact path="/app/group/meetingNow">
              <MeetingNow />
            </Route>
            <Route exact path="/app/group/address/:key">
              <GroupAddress />
            </Route>
            <Route exact path={"/app/group/record/:key"}>
              <GroupRecord />
            </Route>
            <Route path={"/app/group/groupProfile/:key"}>
              <GroupProfile />
            </Route>
            <Route path={"/app/group/data"}>
              <GroupData />
            </Route>
            <Route path={"/app/group/dashboard"}>
              <GroupDashboard />
            </Route>

            <Route component={Empty}></Route>
          </Switch>

          <Sidebar
            groupNum={groupNum}
            meetingInfo={meetingInfo}
            setMeetingInfo={setMeetingInfo}
            bookmarkInputInfo={bookmarkInputInfo}
            calendarApi={calendarApi}
            isInput={isInput}
            setIsInput={setIsInput}
            setLoading={setLoading}
            resSidebarType={resSidebarType}
            setResSidebarType={setResSidebarType}
            dataState={dataState}
            handleDataState={handleDataState}
            handleBookmarkReload={handleBookmarkReload}
          />
        </>
      </div>
    </div>
  );
}

export default App;
