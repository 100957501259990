/**
 * Version : 1.0
 * 작성일자 : 2022-01-13
 * 작성자 : 권도훈
 * 설명 : 회의 영상 정보
 */
const initalState = {
    VIEW_TYPE: "CARD",
    IS_CHECKED: false,
    CHECKED_ARR: [],
    SEARCH_TEXT: ""
};

const recordInfo = (state = initalState, action) => {
    switch (action.type) {
        case "recordInfo/UPDATE":
            return {
                VIEW_TYPE: action.DATA.VIEW_TYPE,
                IS_CHECKED: action.DATA.IS_CHECKED,
                SEARCH_TEXT: action.DATA.SEARCH_TEXT,
                CHECKED_ARR: action.DATA.CHECKED_ARR
            };
        default:
            return state;
    }
};

export default recordInfo;
