import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Loading from "component/tag/loading/loading";
/**
 * Version : 1.0
 * 파일명 : ReserveTotal.js
 * 작성일자 : 2022-02-23
 * 작성자 : 강연승
 * 설명 : 대시보드 아이템 - 총 예약 시간
 * 수정일자 : 2022-03-04
 * 수정자 : 강연승
 * 수정내역 : 대시보드 추가
 */
function ReserveTime() {
  /**
   * REDUCER
   */
  const REDUCER_STATE = useSelector(state => state.groupInfoReducer);
  /**
   * 언어변환
   */
  const { t } = useTranslation();
  /**
   * STATE
   */
  const [loading, setLoading] = useState(false);
  const [durationTotal, setDurationTotal] = useState(0);
  /**
   * 총 예약 시간 조회
   */
  const getDurationTotal = useCallback(() => {
    setLoading(true);
    let param = {};
    if (REDUCER_STATE.GROUP_YN === "Y") {
      param = {
        GROUP_YN: REDUCER_STATE.GROUP_YN,
        GROUP_NUM: REDUCER_STATE.GROUP_NUM,
      };
    } else if (REDUCER_STATE.GROUP_YN === "N") {
      param = {
        GROUP_YN: REDUCER_STATE.GROUP_YN,
      };
    }
    axios
      .get("/schedule/dashboard/durationTotal", {
        params: param,
      })
      .then(res => {
        if (res.status === 200) {
          setDurationTotal(res.data.DURATION_TOTAL);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [REDUCER_STATE.GROUP_NUM, REDUCER_STATE.GROUP_YN]);
  /**
   * useEffect
   */
  useEffect(() => {
    getDurationTotal();
  }, [getDurationTotal]);
  return (
    <div className="dashboardMeetingItem">
      <div style={{ height: "19px" }}>
        <img src="/img/icon/dashboard/time.png" className="dashboardIcon" />
        <font className="title">
          {t("group.dashboard.txt.resTime." + REDUCER_STATE.GROUP_CATEGORY)}
        </font>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div
          style={{
            display: "flex",
            marginTop: "20px",
            justifyContent: "center",
            alignItems: "flex-end",
          }}
        >
          <div className="reserveTime">
            {(durationTotal !== 0 && durationTotal.split(".")[1] !== "0") ||
            durationTotal === 0
              ? durationTotal
              : durationTotal.split(".")[0]}
          </div>
          <div className="hourText">{t("time.hour")}</div>
        </div>
      )}
    </div>
  );
}

export default ReserveTime;
