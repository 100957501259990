import { combineReducers } from "redux";
import userInfoReducer from "reducers/common/userInfo";
import alramInfoReducer from "reducers/common/alramInfo";
import groupInfoReducer from "reducers/common/groupInfo";
import freeLicenseInfoReducer from "reducers/common/freeLicenseInfo";
import dateTimeInfoReducer from "reducers/common/dateTimeInfo";
import recordInfoReducer from "reducers/common/recordInfo";
import pagingInfoReducer from "reducers/common/pagingInfo";
import loadingInfoReducer from "reducers/common/loadingInfo";
import userCardInfoReducer from "reducers/common/userCardInfo";
import alertInfoReducer from "reducers/common/alertInfo";
import profileInfoReducer from "reducers/common/profileInfo";
import focusCalendarInfoReducer from "reducers/common/focusCalendarInfo";
import licenseCntInfoReducer from "reducers/common/licenseCntInfo";
import statisticsInfoReducer from "reducers/common/statisticsInfo";
import pagingsupInfoReducer from "reducers/common/pagingsupInfo";
import outstandingInfoReducer from "reducers/common/outstandingInfo";
import calendarRangeInfoReducer from "reducers/common/calendarRangeInfo";
/**
 * Version : 1.0
 * 작성일자 : 2021-12-23
 * 작성자 : 권도훈
 * 설명 : reducer
 * 수정일자 : 2022-05-02
 * 수정자 : 강연승
 * 수정내역 : 캘린더 액티브 범위 추가
 */
const rootReducer = combineReducers({
  userInfoReducer,
  alramInfoReducer,
  groupInfoReducer,
  freeLicenseInfoReducer,
  dateTimeInfoReducer,
  recordInfoReducer,
  pagingInfoReducer,
  loadingInfoReducer,
  userCardInfoReducer,
  alertInfoReducer,
  profileInfoReducer,
  focusCalendarInfoReducer,
  licenseCntInfoReducer,
  statisticsInfoReducer,
  pagingsupInfoReducer,
  outstandingInfoReducer,
  calendarRangeInfoReducer,
});

export default rootReducer;
