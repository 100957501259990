import React from "react";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
/**
 * Version : 1.0
 * 파일명 :
 * 작성일자 : 2021-10-05
 * 작성자 : 권도훈
 * 설명 :
 * 수정일자 :  2022-02-14
 * 수정자 : 강연승
 * 수정내역 : 그룹 색상별 이미지 변경
 * props :
 * 전달받은값 - 설명
 */
const useStyles = makeStyles(theme => ({
  root: {
    float: "right",
    "& > *": {
      background: "none",
    },
  },
}));

function LogChangeBtn(props) {
  const groupInfoState = useSelector(state => state.groupInfoReducer);
  const classes = useStyles();

  return (
    <div className={classes.root} onClick={props.onClick}>
      <Avatar className="cursor">
        <img
          src={
            groupInfoState.GROUP_NUM === ""
              ? "/img/icon/schedule/change.png"
              : "/img/icon/schedule/change_" +
                groupInfoState.GROUP_COLOR +
                ".png"
          }
          alt="change"
          style={{ width: "36px" }}
        />
      </Avatar>
    </div>
  );
}

export default LogChangeBtn;
