import React, { useEffect, useRef, useState } from 'react'
import ReactModal from "react-modal";
import "css/dormantLoginPopUp.css"

function DormantsuccessPopUp(props) {

  const [count, setCount] = useState(60);
  const countRef = useRef(60);
  /**
   * TIMER
   */
  useEffect(() => {
    if (props.state) {
      const loop = setInterval(() => {
        countRef.current -= 1;

        if (countRef.current === 0) {
          clearInterval(loop);
           closePopup();
        } else {
          setCount(countRef.current);
        }
      }, 1000);
    }
  }, [props.state]);

  const closePopup = () => {
    window.location.href = "/";
    props.close();
    // history.push("")
}
  return (
    <div>
        <ReactModal
         isOpen={props.open} //모달 Open(Boolean)
         className="DormantsuccessPopup" //모달 ClassName
         overlayClassName="DormantsuccessPopup_Overlay" //모달오버레이 ClassName
         onRequestClose={closePopup}
         shouldCloseOnEsc={true}
         animationType="fade"
         transparent={true}
         closeTimeoutMS={200} 
        > 
      
      <div style={{textAlign : "center",
                        marginBottom : "24px",
                        marginTop : "10px"}}>
                <img src='img/icon/landing/dormant_mail.png' alt='이미지' />         
        </div>

      <div className='Dormantsuccess_top'> 이메일로 인증하기</div>

    <div className='Dormantsuccess_mid'>
      <div>인증 메일이 전송되었습니다!</div>
      <div>메일을 확인하시고 휴면해제를 진행해주세요.</div>
    </div>
      <div className='Dormantsuccess_bottom'>
    <div>현재 페이지는</div>
    <div className='Dormanttime'>&nbsp;{count}초&nbsp;</div>
    <div>후에 메인으로 돌아갑니다.</div>
      </div>

        </ReactModal>


    </div>
  )
}

export default DormantsuccessPopUp