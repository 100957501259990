import React, { useState, useEffect, useCallback } from "react";
import "chart.js/auto";
import { Chart } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { openAlert, closeAlert } from "actions/index";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import WorkTimePopup from "component/tag/popup/group/dashboard/workTimePopup";
import axios from "axios";
import Loading from "component/tag/loading/loading";
/**
 * Version : 1.0
 * 파일명 : ReserveTotal.js
 * 작성일자 : 2022-02-23
 * 작성자 : 강연승
 * 설명 : 대시보드 아이템 - 퍼센트 차트
 * 수정일자 : 2022-06-08
 * 수정자 : 강연승
 * 수정내역 : 대시보드 영역 클릭시 통계로 이동
 */
function DoughnutChart() {
  /**
   * REDUCER
   */
  const REDUCER_STATE = useSelector(state => state.groupInfoReducer);
  /**
   * DISPATCH
   */
  const dispatch = useDispatch();
  /**
   * HISTORY
   */
  const history = useHistory();
  /**
   * 언어변환
   */
  const { t } = useTranslation();
  Chart.defaults.plugins.legend.display = false;
  /**
   * STATE
   */
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [dataA, setDataA] = useState(0); // 첫번째 차트 데이터
  const [dataB, setDataB] = useState(0); // 두번째 차트 데이터
  const [dataC, setDataC] = useState(0); // 세번째 차트 데이터
  /**
   * 총 예약 시간 조회
   */
  const getChartData = useCallback(() => {
    setLoading(true);
    let param = {
      GROUP_NUM: REDUCER_STATE.GROUP_NUM,
      GROUP_CATEGORY: REDUCER_STATE.GROUP_CATEGORY,
    };
    axios
      .get("/schedule/dashboard/percentChart", {
        params: param,
      })
      .then(res => {
        if (res.status === 200) {
          setTotal(res.data.TOTAL);
          setDataA(res.data.DATA_A);
          setDataB(res.data.DATA_B);
          setDataC(res.data.DATA_C);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [
    REDUCER_STATE.GROUP_NUM,
    REDUCER_STATE.GROUP_YN,
    REDUCER_STATE.GROUP_CATEGORY,
  ]);
  /**
   * useEffect
   */
  useEffect(() => {
    getChartData();
  }, [getChartData]);
  /**
   * 근무시간 팝업
   */
  const openWorkTimeSetting = () => {
    setIsOpenPopup(true);
  };
  const closeWorkTimeSetting = () => {
    setIsOpenPopup(false);
  };
  /**
   * 근무시간 저장
   */
  const saveWorkTime = workTime => {
    closeWorkTimeSetting();
    let workStart = workTime.startHour + ":" + workTime.startMin + ":00";
    let workEnd = workTime.endHour + ":" + workTime.endMin + ":00";
    axios
      .put("/group/updateWorkTime", {
        GROUP_NUM: REDUCER_STATE.GROUP_NUM,
        WORK_START: workStart,
        WORK_END: workEnd,
      })
      .then(res => {
        if (res.status === 200) {
          const obj = {
            TEXT: "근무시간이 변경되었습니다.",
            submitEventHandler: () => {
              dispatch(closeAlert());
              getChartData();
            },
          };
          dispatch(openAlert(obj));
        }
      });
  };
  return (
    <>
      <WorkTimePopup
        open={isOpenPopup}
        close={closeWorkTimeSetting}
        save={saveWorkTime}
      />
      <div
        className="dashboardDoughnutChartArea"
        onClick={() =>
          history.push({
            pathname: "/app/group/data/" + REDUCER_STATE.GROUP_NUM,
          })
        }
      >
        <div>
          <img
            src="/img/icon/dashboard/doughnut.png"
            className="dashboardIcon"
          />
          <font className="title">
            {t(
              "group.dashboard.txt.percentChart.title." +
                REDUCER_STATE.GROUP_CATEGORY
            )}
          </font>
          {REDUCER_STATE.GROUP_YN === "Y" &&
          REDUCER_STATE.AUTH_CD === "CD0301" &&
          (REDUCER_STATE.GROUP_CATEGORY === "CD0401" ||
            REDUCER_STATE.GROUP_CATEGORY === "CD0402") ? (
            <div
              className="btnTimeSetting"
              onClick={e => (e.stopPropagation(), openWorkTimeSetting())}
            >
              {t("group.dashboard.txt.percentChart.btn.workTime")}
            </div>
          ) : null}
        </div>
        {loading ? (
          <Loading />
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              marginTop: "12px",
            }}
          >
            {REDUCER_STATE.GROUP_CATEGORY === "CD0406" ? (
              <div className="socialItem">
                <div className="socialDiv">
                  <font className="socialValue">{dataA}</font>
                  <font className="social">
                    {t("group.thema.social.address")}
                  </font>
                </div>
                <div className="chartTitle" style={{ marginTop: "62px" }}>
                  {t(
                    "group.dashboard.txt.percentChart.label1." +
                      REDUCER_STATE.GROUP_CATEGORY
                  )}
                </div>
              </div>
            ) : (
              <div className={dataA === 0 ? "nodataItem" : "chartItem"}>
                {dataA === 0 ? (
                  <canvas
                    width="135px"
                    height="135px"
                    style={{
                      backgroundImage: "url(/img/icon/dashboard/no_data.png)",
                    }}
                  ></canvas>
                ) : (
                  <Doughnut
                    data={{
                      labels: [
                        t(
                          "group.dashboard.txt.percentChart.chartLabel.1." +
                            REDUCER_STATE.GROUP_CATEGORY +
                            ".data"
                        ),
                        t(
                          "group.dashboard.txt.percentChart.chartLabel.1." +
                            REDUCER_STATE.GROUP_CATEGORY +
                            ".noData"
                        ),
                      ],
                      datasets: [
                        {
                          data: [dataA, total - dataA],
                          backgroundColor: ["#436bff", "rgba(0, 0, 0, 0.1)"],
                        },
                      ],
                    }}
                    options={{
                      maintainAspectRatio: false,
                      borderWidth: 0,
                    }}
                    plugins={[
                      {
                        beforeDraw: function (chart) {
                          let width = chart.width;
                          let height = chart.height;
                          let ctx = chart.ctx;
                          ctx.restore();
                          ctx.font =
                            "normal normal 500 19px Spoqa Han Sans Neo";
                          ctx.fillStyle = "#436bff";
                          ctx.textBaseline = "middle";
                          let text =
                              dataA === 0
                                ? "-"
                                : ((dataA / total) * 100).toFixed(0) + "%",
                            textX = Math.round(
                              (width - ctx.measureText(text).width) / 2
                            ),
                            textY = height / 2;
                          ctx.fillText(text, textX, textY);
                          ctx.save();
                        },
                      },
                    ]}
                    width="138"
                    height="138"
                  />
                )}
                <div className="chartTitle">
                  {t(
                    "group.dashboard.txt.percentChart.label1." +
                      REDUCER_STATE.GROUP_CATEGORY
                  )}
                </div>
              </div>
            )}
            <div className={dataB === 0 ? "nodataItem" : "chartItem"}>
              {dataB === 0 ? (
                <canvas
                  width="135px"
                  height="135px"
                  style={{
                    backgroundImage: "url(/img/icon/dashboard/no_data.png)",
                  }}
                ></canvas>
              ) : (
                <Doughnut
                  data={{
                    labels: [
                      t(
                        "group.dashboard.txt.percentChart.chartLabel.2." +
                          REDUCER_STATE.GROUP_CATEGORY +
                          ".data"
                      ),
                      t(
                        "group.dashboard.txt.percentChart.chartLabel.2." +
                          REDUCER_STATE.GROUP_CATEGORY +
                          ".noData"
                      ),
                    ],
                    datasets: [
                      {
                        data: [dataB, total - dataB],
                        backgroundColor: [
                          REDUCER_STATE.GROUP_CATEGORY === "CD0406"
                            ? "#436bff"
                            : REDUCER_STATE.GROUP_CATEGORY === "CD0401" ||
                              REDUCER_STATE.GROUP_CATEGORY === "CD0402"
                            ? "#ff734f"
                            : "#ffb423",
                          "rgba(0, 0, 0, 0.1)",
                        ],
                      },
                    ],
                  }}
                  options={{
                    maintainAspectRatio: false,
                    borderWidth: 0,
                  }}
                  plugins={[
                    {
                      beforeDraw: function (chart) {
                        let width = chart.width;
                        let height = chart.height;
                        let ctx = chart.ctx;
                        ctx.restore();
                        ctx.font = "normal normal 500 19px Spoqa Han Sans Neo";
                        if (REDUCER_STATE.GROUP_CATEGORY === "CD0406") {
                          ctx.fillStyle = "#436bff";
                        } else if (
                          REDUCER_STATE.GROUP_CATEGORY === "CD0401" ||
                          REDUCER_STATE.GROUP_CATEGORY === "CD0402"
                        ) {
                          ctx.fillStyle = "#ff734f";
                        } else {
                          ctx.fillStyle = "#ffb423";
                        }

                        ctx.textBaseline = "middle";
                        let text =
                            dataB === 0
                              ? "-"
                              : ((dataB / total) * 100).toFixed(0) + "%",
                          textX = Math.round(
                            (width - ctx.measureText(text).width) / 2
                          ),
                          textY = height / 2;
                        ctx.fillText(text, textX, textY);
                        ctx.save();
                      },
                    },
                  ]}
                  width="138"
                  height="138"
                />
              )}
              <div className="chartTitle">
                {t(
                  "group.dashboard.txt.percentChart.label2." +
                    REDUCER_STATE.GROUP_CATEGORY
                )}
              </div>
            </div>

            <div className={dataC === 0 ? "nodataItem" : "chartItem"}>
              {dataC === 0 ? (
                <canvas
                  width="135px"
                  height="135px"
                  style={{
                    backgroundImage: "url(/img/icon/dashboard/no_data.png)",
                  }}
                ></canvas>
              ) : (
                <Doughnut
                  data={{
                    labels: [
                      t(
                        "group.dashboard.txt.percentChart.chartLabel.3." +
                          REDUCER_STATE.GROUP_CATEGORY +
                          ".data"
                      ),
                      t(
                        "group.dashboard.txt.percentChart.chartLabel.3." +
                          REDUCER_STATE.GROUP_CATEGORY +
                          ".noData"
                      ),
                    ],
                    datasets: [
                      {
                        data: dataC === 0 ? dataC : [dataC, total - dataC],
                        backgroundColor: [
                          REDUCER_STATE.GROUP_CATEGORY === "CD0401" ||
                          REDUCER_STATE.GROUP_CATEGORY === "CD0402"
                            ? "#ffb423"
                            : "#ff734f",
                          "rgba(0, 0, 0, 0.1)",
                        ],
                      },
                    ],
                  }}
                  options={{
                    maintainAspectRatio: false,
                    borderWidth: 0,
                  }}
                  plugins={[
                    {
                      beforeDraw: function (chart) {
                        let width = chart.width;
                        let height = chart.height;
                        let ctx = chart.ctx;
                        ctx.restore();
                        ctx.font = "normal normal 500 19px Spoqa Han Sans Neo";
                        if (
                          REDUCER_STATE.GROUP_CATEGORY === "CD0401" ||
                          REDUCER_STATE.GROUP_CATEGORY === "CD0402"
                        ) {
                          ctx.fillStyle = "#ffb423";
                        } else {
                          ctx.fillStyle = "#ff734f";
                        }
                        ctx.textBaseline = "middle";
                        let text =
                            dataC === 0
                              ? "-"
                              : ((dataC / total) * 100).toFixed(0) + "%",
                          textX = Math.round(
                            (width - ctx.measureText(text).width) / 2
                          ),
                          textY = height / 2;
                        ctx.fillText(text, textX, textY);
                        ctx.save();
                      },
                    },
                  ]}
                  width="138"
                  height="138"
                />
              )}
              <div className="chartTitle">
                {t(
                  "group.dashboard.txt.percentChart.label3." +
                    REDUCER_STATE.GROUP_CATEGORY
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default DoughnutChart;
