import { fetchAxiosEvent } from "axios/common/axiosApi";
import Loading from "component/tag/loading/newLoading";
import LicenseList from "component/tag/table/profile/licenseList";
import React, { Suspense, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import LicenseSettingPopup from "component/tag/popup/license/licenseSettingPopup";
import SetGroupLicensePopup from "component/tag/popup/license/setGroupLicensePopup";
import LicenseConfirmAlert from "component/tag/popup/license/licenseConfirmAlert";
import PaySuccessPopup from "component/tag/popup/my/profile/paySuccessPopup";
import ProductPopup from "component/tag/popup/my/profile/productPopup";
import axios from "axios";
import Paging from "../../common/suspensePaging";
import CommonPaging from "../../common/payment/commonPaging";
import LicenseListHeader from "./licenseListHeader";
/**
 * Version : 1.0
 * 파일명 : profileLicenseContent.js
 * 작성일자 : 2021-11-25
 * 작성자 : 권도훈
 * 설명 : 프로필 라이선스 부분
 * 수정일자 : 2022-06-13
 * 수정자 : 강연승
 * 수정내역 : sobridge 데모 - 구독하기 버튼 주석처리
 */
function ProfileLicenseContent() {
  /**
   * TRANSLATION
   */
  const { t } = useTranslation();
  /**
   * REDUX
   */
  const GROUP_STATE = useSelector(state => state.groupInfoReducer);
  /**
   * LOCATION
   */
  const location = useLocation();

  const [init, setInit] = useState(true);
  const [isSearch, setIsSearch] = useState(false);
  const [page, setPage] = useState({
    ROW_COUNT: 10,
    PAGE_COUNT: 0,
  });
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);

  // const [render, setRender]

  /**
   * 상품 결제 팝업
   */
  const [showProductPopup, setShowProductPopup] = useState(false);

  /**
   *
   */
  const [showLicenseSettingPopup, setShowLicenseSettingPopup] = useState(false);
  const [selectLicense, setSelectLicense] = useState({});
  const [licenseList, setLicenseList] = useState([]);
  const [openSetGroupLicense, setOpenSetGroupLicense] = useState(false);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [confirmRequest, setConfirmRequest] = useState({});

  //회의이용권 목록 조회
  const getLicenseList = useCallback(() => {
    const obj = {
      config: {
        params: {
          GROUP_YN: GROUP_STATE.GROUP_YN,
          GROUP_NUM: GROUP_STATE.GROUP_NUM,
          ROW_COUNT: page.ROW_COUNT,
          PAGE_COUNT: page.PAGE_COUNT,
        },
      },
    };

    let url = "";
    if (GROUP_STATE.AUTH_CD === "CD0302") {
      url = "/license/groupList";
    } else {
      url = "/license/list";
    }

    setList(fetchAxiosEvent("GET", url, obj));
  }, [
    GROUP_STATE.AUTH_CD,
    GROUP_STATE.GROUP_NUM,
    GROUP_STATE.GROUP_YN,
    page.PAGE_COUNT,
    page.ROW_COUNT,
  ]);

  const getLicenseTotal = useCallback(() => {
    let url, obj;

    if (GROUP_STATE.AUTH_CD === "CD0302") {
      url = "/license/groupTotal";
      obj = {
        config: {
          params: {
            GROUP_NUM: GROUP_STATE.GROUP_NUM,
          },
        },
      };
    } else {
      url = "/license/total";
      obj = {
        config: {},
      };
    }

    setTotal(fetchAxiosEvent("GET", url, obj));
  }, [GROUP_STATE.AUTH_CD, GROUP_STATE.GROUP_NUM]);

  useEffect(() => {
    if (init) {
      if (location.state !== undefined && location.state.showPopup === true) {
        setShowProductPopup(true);
      }

      getLicenseList();
      getLicenseTotal();
      setInit(false);
    }
  }, [getLicenseList, getLicenseTotal, init, location.state]);

  useEffect(() => {
    if (isSearch) {
      getLicenseList();
      setIsSearch(false);
    }
  }, [getLicenseList, isSearch]);

  useEffect(() => {
    getLicenseList();
  }, [getLicenseList, GROUP_STATE.GROUP_COLOR]);
  /**
   * 라이선스 타그룹 할당
   */
  const openSetGroupLicensePopup = () => {
    setOpenSetGroupLicense(true);
  };
  const closeSetGroupLicensePopup = () => {
    setOpenSetGroupLicense(false);
  };
  const allocateGroup = () => {
    if (showLicenseSettingPopup === true) {
      setShowLicenseSettingPopup(false);
      openSetGroupLicensePopup();
    }
  };
  const setGroupLicense = (license, group) => {
    const request = {
      USER_LICENSE_NUM: license.USER_LICENSE_NUM,
      GROUP_NUM: group.GROUP_NUM,
      GROUP_ASSIGNED_YN: "Y",
    };
    axios
      .put("/license/setGroupLicense", request)
      .then(res => {
        if (res.status === 200) {
          setConfirmPopup(true);
          const confirmParams = {
            className: "setLicenseAlert",
            btnNo: false,
            contents: (
              <div>
                <div
                  style={{
                    font: "normal normal normal 20px/27px Spoqa Han Sans Neo",
                    color: "#2B4AC4",
                    textAlign: "left",
                  }}
                >
                  {t("my.profile.msg.successAllocate")}
                </div>
                <div
                  style={{
                    font: "normal normal normal 14px/21px Spoqa Han Sans Neo",
                    color: "#3E3E4F",
                    textAlign: "left",
                    marginTop: "12px",
                  }}
                >
                  {t("my.profile.txt.allocate_1") +
                    group.GROUP_NM +
                    t("my.profile.txt.allocate_2")}
                </div>
              </div>
            ),
            EventHandler: function () {
              getLicenseTotal();
              getLicenseList();
            },
          };
          setConfirmRequest(confirmParams);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
  const closeConfirmPopup = () => {
    setConfirmPopup(false);
  };
  /**
   * 태그
   */
  return (
    <>
      <PaySuccessPopup />
      <ProductPopup
        isRefresh={setInit}
        type="PRODUCT"
        isShow={showProductPopup}
        setIsShow={setShowProductPopup}
      />
      <LicenseSettingPopup
        showLicenseSettingPopup={showLicenseSettingPopup}
        setShowLicenseSettingPopup={setShowLicenseSettingPopup}
        license={selectLicense}
        licenseList={licenseList}
        getLicenseTotal={getLicenseTotal}
        getLicenseData={getLicenseList}
        allocateGroup={allocateGroup}
      />
      <SetGroupLicensePopup
        isShow={openSetGroupLicense}
        close={closeSetGroupLicensePopup}
        // groupList={props.group}
        license={selectLicense}
        setGroupLicense={setGroupLicense}
      />
      <LicenseConfirmAlert
        open={confirmPopup}
        close={closeConfirmPopup}
        request={confirmRequest}
      />

      <div className="pf-common-container">
        {init ? (
          <></>
        ) : (
          <Suspense
            fallback={
              <div className="pf-license-container-loading">
                <Loading />
              </div>
            }
          >
            <div className="profile_content_title">
              <LicenseListHeader res={total} />
              {/* {GROUP_STATE.AUTH_CD === "CD0302" ? (
                <></>
              ) : (
                <button
                  className={
                    GROUP_STATE.GROUP_YN === "N" || GROUP_STATE.GROUP_YN === ""
                      ? "profile_content_whiteBtn cursor"
                      : "cursor profile_content_Btn_" + GROUP_STATE.GROUP_COLOR
                  }
                  onClick={() => setShowProductPopup(true)}
                >
                  구독하기
                </button>
              )} */}
            </div>

            <div className="pf-license-content">
              <table className="pf-license-table">
                <colgroup>
                  <col width="16px" />
                  <col width="78px" />
                  <col width="58px" />
                  <col width="134px" />
                  <col width="32px" />
                  <col width="94px" />
                  <col width="46px" />
                  <col width="150px" />
                  <col width="140px" />
                  <col width="104px" />
                  <col width="16px" />
                </colgroup>

                <thead>
                  <tr className="pf-license-table-tr thead">
                    <th />
                    <th>{t("my.profile.txt.remainingPeriod")}</th>
                    <th />
                    <th>{t("my.profile.txt.licenseCode")}</th>
                    <th />
                    <th>{t("my.profile.txt.scheduleCount")}</th>
                    <th />
                    <th className="pf-license-table-font left">
                      {t("my.profile.txt.allocatedGroup")}
                    </th>
                    <th />
                    <th />
                    <th />
                  </tr>
                </thead>

                <tbody>
                  <Suspense
                    fallback={
                      <tr>
                        <td>
                          <div className="pf-license-container-loading">
                            <Loading />
                          </div>
                        </td>
                      </tr>
                    }
                  >
                    <LicenseList
                      res={list}
                      setLicenseList={setLicenseList}
                      setSelectLicense={setSelectLicense}
                      setShowLicenseSettingPopup={setShowLicenseSettingPopup}
                    />
                  </Suspense>
                </tbody>
              </table>

              <div className="pf-license-paging">
                <CommonPaging
                  page={page}
                  setPage={setPage}
                  res={total}
                  setSearch={setIsSearch}
                />
              </div>
            </div>
          </Suspense>
        )}
      </div>
    </>
  );
}

export default ProfileLicenseContent;
