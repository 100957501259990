import React, { useCallback, useEffect, useState } from 'react'
import DataCardItem from 'component/tag/div/group/data/dataCardItem';
import Loading from 'component/tag/loading/loading';
import { useSelector } from 'react-redux';
/**
 * Version : 1.0
 * 파일명 : DataStatisticsCardList.js
 * 수정일자 : 2022-03-08
 * 작성자 : 권도훈
 * 설명 : 데이터 통계 카드 타입
 * 수정일자 : 2022-03-21
 * 수정자 : 권도훈
 * 수정내역 : UI 수정
*/
function DataStatisticsCardList(props) {
    const [init, setInit] = useState(false);

    useEffect(() => {
        if (!init) {
            setInit(true);
        }
    }, [init]);
    return (
        <div className="data-statistics-container-card scrollbar">
            {
                init
                    ?
                    props.data.length > 0
                        ?
                        props.data.map((data, idx) => (
                            <div
                                key={"card_" + idx}
                                className="data-statistics-card"
                            >
                                <DataCardItem
                                    data={data}
                                    setDateDetail={props.setDateDetail}
                                    date={props.date}
                                    setDetailSearch={props.setDetailSearch}
                                />
                            </div>
                        ))
                        :
                        <div className='data-statistics-container-empty'>
                            <img
                                src='/img/icon/data/no_status_new.png'
                                alt='NO DATA'
                            />
                            <section>
                                <font>아직 데이터가 없습니다!</font>
                                <font>미팅 데이터가 누적되면 통계가 생성됩니다.</font>
                            </section>
                        </div>
                    :
                    <></>
            }
        </div>
    )
}

export default DataStatisticsCardList
