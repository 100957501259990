import React from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
/**
 * Version : 1.0
 * 파일명 : DeleteMemberAlram.js
 * 작성일자 : 2021-12-16
 * 작성자 : 권도훈
 * 설명 : 그룹원 탈퇴 알람
 * 수정일자 : 2022-02-03
 * 수정자 : 권도훈
 * 수정내역 : history push로 변경
 */
function DeleteMemberAlram(props) {
  /**
   * history
   */
  const history = useHistory();
  /**
   * 내용 포맷
   */
  const contentConvert = (data) => {
    const split = data.split("%SPLIT%");

    return (
      <div>
        <div>
          {split[0]} 님이 [ {split[1]} ] 그룹을 탈퇴하였습니다.
        </div>
      </div>
    );
  };
  return (
    <div
      className="alarm_Item cursor"
      key={props.data.ALRAM_NUM}
      onClick={() => {
        axios
          .get("/group/checkUseGroup", {
            params: {
              GROUP_NUM: props.data.GROUP_NUM,
            },
          })
          .then((res) => {
            if (res.status === 200) {
              console.log(res.data);
              if (res.data.hasOwnProperty("MSG")) {
                alert(res.data.MSG);
                return;
              } else if (res.data.hasOwnProperty("OK")) {
                history.push({
                  pathname: "/app/group/groupMain/" + props.data.GROUP_NUM
                });
                // window.location.href = "/app/group/groupMain/" + props.data.GROUP_NUM;
              }
            }
          });
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "24px",
          color: props.data.READ_YN === "Y" ? "grey" : "red"
        }}
      >
        <div className="font-bold">
          그룹원 탈퇴
        </div>

        <div
          style={{
            fontSize: "14px"
          }}
        >
          {props.date}
        </div>
      </div>

      <div
        style={{
          marginTop: "24px",
          marginBottom: "24px",
          color: props.data.READ_YN === "Y" ? "grey" : "black",
        }}
        className="m-0"
      >
        {contentConvert(props.data.ALRAM_CONTENT)}
      </div>
    </div>
  );
}

export default DeleteMemberAlram;
