import React from 'react'
import ReactModal from "react-modal";
import "css/alertsupimage.css"
import { useHistory } from 'react-router-dom';
/* Version : 1.0
* 파일명 : supportPopup.js
* 작성일자 : 2022-03-15
* 작성자 : 정지윤
* 설명 : 문의 후 팝업 창
*/
function SupportPopupimage(props) {


    const closePopup = () => {
      
        props.close();
    }
    

  return (
    <div>
        <ReactModal
         isOpen={props.open} //모달 Open(Boolean)
         className="supportPopupimage" //모달 ClassName
         overlayClassName="supportPopupimage_Overlay" //모달오버레이 ClassName
         onRequestClose={closePopup}
         shouldCloseOnEsc={true}
         animationType="fade"
         transparent={true}
         closeTimeoutMS={200}
        > 
        <div className='modal_image_background'>
      
      <div className='modal_image_center'>
        {props.image}
        </div>

        <div>

        </div>
      </div>
        </ReactModal>
    </div>
    
    
  )
}

export default SupportPopupimage
