/**
 * Import List
 */
import React from "react";
import { useHistory } from "react-router-dom";
import "css/empty.css";
/**
 * Version : 1.0
 * 파일명 : empty.js
 * 작성일자 : 2021-09-30
 * 작성자 : 권도훈
 * 설명 : 잘못된 접근 페이지
 * 수정일자 :  2021-09-30
 * 수정자 : 권도훈
 * 수정내역 :
*/
function Empty() {
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  return (
    <div className="mt-50 mb-8 w-320 h-full absolute inline text-center">
      <div className="font-bold">
        잘못된 접근입니다.
      </div>
      <button
        onClick={() => goBack()}
        className="blue-btn cursor border-radius-5 w-48 h-20"
      >
        돌아가기
      </button>
    </div>
  );
}

export default Empty;