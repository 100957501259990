import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import RecordHeader from "component/tag/div/my/record/recordHeader";
import RecordEmpty from "./recordEmpty";
import Loading from "component/tag/loading/loading";
import Alert from "component/tag/modal/alert";
import { updatePagingInfo, updateRecordInfo } from "actions/index";
import axios from "axios";
/**
 * Version : 1.0
 * 파일명 : recordCardList.js
 * 작성일자 : 2021-10-21
 * 작성자 : 권도훈
 * 설명 : 주소록
 * 수정일자 : 2022-05-23
 * 수정자 : 권도훈
 * 수정내역 : 다운로드 카운트 증가 수정
 */
function RecordCardList() {
  /**
   * 언어변환
   */
  const { t } = useTranslation();
  /**
   * DISPATCH
   */
  const dispatch = useDispatch();
  /**
   * REDUCER
   */
  const REDUCER_STATE = useSelector(state => state.groupInfoReducer);
  const RECORD_STATE = useSelector(state => state.recordInfoReducer);
  const PAGING_STATE = useSelector(state => state.pagingInfoReducer);
  /**
   * loading
   */
  const [loading, setLoading] = useState(false);
  /**
   * Data
   */
  const [date, setDate] = useState([]);
  const [data, setData] = useState([]);
  /**
   * 체크박스
   */
  const [checkedInputs, setCheckedInputs] = useState([]);
  /**
   * 알림창 설정
   */
  const [alertProps, setAlertProps] = useState({
    text: "",
    isShow: false,
    cancelBtnDisplay: false,
    okBtnDisplay: true,
    okBtnEventHandler: function () { },
    cancleBtnEventHandler: function () {
      closeAlertEvent();
    },
  });
  /**
   * Today 값
   */
  const [today, setToday] = useState({
    year: "",
    month: "",
    date: "",
    day: "",
  });
  /**
   * 요일
   */
  const [week] = useState([
    t("date.sun"),
    t("date.mon"),
    t("date.tue"),
    t("date.wed"),
    t("date.thu"),
    t("date.fri"),
    t("date.sat"),
  ]);
  /**
   * 날짜 형식 포맷
   */
  const setDateFormat = useCallback(
    target => {
      const splitDate = target.split("-");
      const year = splitDate[0];
      const month = splitDate[1];
      const date = splitDate[2];

      if (
        year === today.year.toString() &&
        month === today.month.toString() &&
        date === today.date.toString()
      ) {
        return t("date.today");
      } else {
        var targetDay = new Date(target).getDay();
        var todayLabel = week[targetDay];
        return year + "/" + month + "/" + date + " (" + todayLabel + ")";
      }
    },
    [t, today.date, today.month, today.year, week]
  );
  /**
   * 알림창 닫기
   */
  const closeAlertEvent = useCallback(() => {
    setAlertProps({
      ...alertProps,
      isShow: false,
    });
  }, [alertProps]);
  /**
   * SET TOTAL
   */
  const getRecordCardTotal = useCallback(() => {
    setLoading(true);

    const config = {
      params: {
        Group_YN: REDUCER_STATE.GROUP_YN,
        Group_Num: REDUCER_STATE.GROUP_NUM,
        AUTH_CD: REDUCER_STATE.AUTH_CD,
        page_Row: PAGING_STATE.ROW_COUNT,
        page_Now: PAGING_STATE.PAGE_COUNT,
        Search_Text: RECORD_STATE.SEARCH_TEXT,
      },
    };

    axios
      .get("/record/RecordFileCnt", config)
      .then(res => {
        if (res.status === 200) {
          const paging_obj = {
            ROW_COUNT: PAGING_STATE.ROW_COUNT,
            PAGE_COUNT: PAGING_STATE.PAGE_COUNT,
            TOTAL: res.data[0].RECCNT,
          };
          dispatch(updatePagingInfo(paging_obj));
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [
    PAGING_STATE.PAGE_COUNT,
    PAGING_STATE.ROW_COUNT,
    RECORD_STATE.SEARCH_TEXT,
    REDUCER_STATE.AUTH_CD,
    REDUCER_STATE.GROUP_NUM,
    REDUCER_STATE.GROUP_YN,
    dispatch,
  ]);
  /**
   * SET DATA
   */
  const getRecordCardData = useCallback(() => {
    setLoading(true);

    const config = {
      params: {
        Group_YN: REDUCER_STATE.GROUP_YN,
        Group_Num: REDUCER_STATE.GROUP_NUM,
        AUTH_CD: REDUCER_STATE.AUTH_CD,
        page_Row: PAGING_STATE.ROW_COUNT,
        page_Now: PAGING_STATE.PAGE_COUNT,
        Search_Text: RECORD_STATE.SEARCH_TEXT,
      },
    };

    axios
      .get("/record/RecordFile", config)
      .then(res => {
        if (res.status === 200) {
          let line = [];
          let chkNum_SEQ = 0;
          let strDateFormat = "";

          for (let i = 0; i < res.data.length; i++) {
            if (i === 0) {
              strDateFormat = setDateFormat(res.data[i].RECDAY);
              res.data[i].chkNum = chkNum_SEQ;
              line.push({
                type: "line",
                date: res.data[i].RECDAY,
                TITLE: strDateFormat,
                chkNum: i,
                childCount: 0,
              });
            } else {
              if (res.data[i].RECDAY !== res.data[i - 1].RECDAY) {
                chkNum_SEQ = i;
                strDateFormat = setDateFormat(res.data[i].RECDAY);
                res.data[i].chkNum = chkNum_SEQ;
                line.push({
                  type: "line",
                  date: res.data[i].RECDAY,
                  TITLE: strDateFormat,
                  chkNum: i,
                  childCount: 0,
                });
              } else {
                res.data[i].chkNum = chkNum_SEQ;
              }
            }
          }
          setDate(line);
          setData(res.data);
        }

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [
    PAGING_STATE.PAGE_COUNT,
    PAGING_STATE.ROW_COUNT,
    RECORD_STATE.SEARCH_TEXT,
    REDUCER_STATE.AUTH_CD,
    REDUCER_STATE.GROUP_NUM,
    REDUCER_STATE.GROUP_YN,
    setDateFormat,
  ]);
  /**
   * 조회
   */
  useEffect(() => {
    if (PAGING_STATE.ROW_COUNT < 16) {
      const paging_obj = {
        ROW_COUNT: 16,
        PAGE_COUNT: 0,
        TOTAL: 0,
      };
      dispatch(updatePagingInfo(paging_obj));
    } else {
      getRecordCardTotal();
      getRecordCardData();
    }
  }, [
    PAGING_STATE.ROW_COUNT,
    RECORD_STATE.VIEW_TYPE,
    dispatch,
    getRecordCardData,
    getRecordCardTotal,
  ]);
  /**
   * 날짜 설정
   */
  useEffect(() => {
    const date = new Date();
    const format = {
      year: date.getFullYear(),
      month:
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1,
      date: date.getDate(),
      day: date.getDay(),
    };
    setToday(format);
  }, []);
  /**
   * 체크박스 USE_EFFECT
   */
  useEffect(() => {
    if (RECORD_STATE.CHECKED_ARR.length > 0) {
      setCheckedInputs(RECORD_STATE.CHECKED_ARR);
    } else {
      setCheckedInputs([]);
    }
  }, [RECORD_STATE.CHECKED_ARR]);
  /**
   * 스크롤
   */
  const infiniteScroll = () => {
    if (PAGING_STATE.PAGE_COUNT < PAGING_STATE.TOTAL) {
      let scrollHeight =
        document.getElementById("record_card_div").scrollHeight;
      let scrollTop = document.getElementById("record_card_div").scrollTop;
      let clientHeight =
        document.getElementById("record_card_div").clientHeight;

      if (scrollTop + clientHeight === scrollHeight) {
        if (
          PAGING_STATE.ROW_COUNT > PAGING_STATE.TOTAL ||
          PAGING_STATE.ROW_COUNT === PAGING_STATE.TOTAL
        ) {
          return;
        } else if (PAGING_STATE.ROW_COUNT < PAGING_STATE.TOTAL) {
          let diff = PAGING_STATE.TOTAL - PAGING_STATE.ROW_COUNT;

          if (diff < PAGING_STATE.ROW_COUNT) {
            const paging_obj = {
              ROW_COUNT: PAGING_STATE.ROW_COUNT + 16,
              PAGE_COUNT: 0,
              TOTAL: PAGING_STATE.TOTAL,
            };
            dispatch(updatePagingInfo(paging_obj));
          } else {
            const paging_obj = {
              ROW_COUNT: PAGING_STATE.ROW_COUNT + diff,
              PAGE_COUNT: 0,
              TOTAL: PAGING_STATE.TOTAL,
            };
            dispatch(updatePagingInfo(paging_obj));
          }
        }
      }
    }
  };
  const clickCheckbox_Card = (checked, id, num) => {
    if (checked) {
      setCheckedInputs([...checkedInputs, id]);
      const obj = {
        VIEW_TYPE: RECORD_STATE.VIEW_TYPE,
        IS_CHECKED: true,
        CHECKED_ARR: [...checkedInputs, id],
        SEARCH_TEXT: RECORD_STATE.SEARCH_TEXT,
      };
      dispatch(updateRecordInfo(obj));
    } else {
      let num = checkedInputs.filter(el => el !== id);
      setCheckedInputs(num);
      const obj = {
        VIEW_TYPE: RECORD_STATE.VIEW_TYPE,
        IS_CHECKED: false,
        CHECKED_ARR: num,
        SEARCH_TEXT: RECORD_STATE.SEARCH_TEXT,
      };
      dispatch(updateRecordInfo(obj));
    }
    document.getElementById("Rec_Chk_ALL_" + num).checked = false;

    for (
      let i = 1;
      i < document.getElementsByName("Rec_Chk_" + num).length;
      i++
    ) {
      if (document.getElementsByName("Rec_Chk_" + num)[i].checked) {
        document.getElementById("Rec_Chk_ALL_" + num).checked = true;
      }
    }
  };
  const clickCheckboxAll_Card = (checked, name) => {
    if (checked) {
      for (let i = 1; i < document.getElementsByName(name).length; i++) {
        checkedInputs.push(document.getElementsByName(name)[i].value);
      }
      setCheckedInputs([...checkedInputs]);
      const obj = {
        VIEW_TYPE: RECORD_STATE.VIEW_TYPE,
        IS_CHECKED: true,
        CHECKED_ARR: [...checkedInputs],
        SEARCH_TEXT: RECORD_STATE.SEARCH_TEXT,
      };
      dispatch(updateRecordInfo(obj));
    } else {
      let checkedArr = checkedInputs;
      for (let i = 1; i < document.getElementsByName(name).length; i++) {
        checkedArr = checkedArr.filter(
          el => el !== document.getElementsByName(name)[i].value
        );
      }
      setCheckedInputs(checkedArr);
      const obj = {
        VIEW_TYPE: RECORD_STATE.VIEW_TYPE,
        IS_CHECKED: false,
        CHECKED_ARR: checkedArr,
        SEARCH_TEXT: RECORD_STATE.SEARCH_TEXT,
      };
      dispatch(updateRecordInfo(obj));
    }
  };
  /**
   * 녹화파일 다운로드
   */
  const downloadRecordFile = (url, num) => {
    const config = {
      params: {
        Rec_Num: num
      }
    }

    axios.put("/record/RecordFileCnt", null, config)
      .then(res => {
        if (res.status === 200) {
          window.open(
            url,
            "_blank",
            "width=600,height=250,left=0,top=0,visible=no,scrollbars=no,status=no,titlebar=no,toolbar=no,menubar=no,resizeable=no,location=no"
          );
        }
      })
      .catch(() => {

      });
  };
  /**
   * 데이터 삭제
   */
  const deleteRecordData = num => {
    setAlertProps({
      ...alertProps,
      text: t("my.record.msg.delete.confirm"),
      isShow: true,
      cancelBtnDisplay: true,
      okBtnEventHandler: function () {
        setLoading(true);
        closeAlertEvent();

        const config = {
          params: {
            Rec_Num: num,
          },
        };

        axios
          .delete("/record/RecordFile", config)
          .then(res => {
            if (res.status === 200) {
              getRecordCardTotal();
              getRecordCardData();
              setAlertProps({
                ...alertProps,
                isShow: true,
                text: t("my.record.msg.delete.success"),
                cancelBtnDisplay: false,
                okBtnEventHandler: function () {
                  closeAlertEvent();
                },
              });
            }
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      },
      cancleBtnEventHandler: function () {
        closeAlertEvent();
      },
    });
  };
  /**
   * 체크된 DATA 삭제
   */
  const deleteCheckedRecordData = () => {
    setAlertProps({
      ...alertProps,
      text: t("my.record.msg.delete.confirm"),
      isShow: true,
      cancelBtnDisplay: true,
      okBtnEventHandler: function () {
        closeAlertEvent();
        setLoading(true);

        let targetStr = "";

        for (let i = 0; i < checkedInputs.length; i++) {
          targetStr = targetStr + checkedInputs[i] + ",";
        }

        const config = {
          params: {
            Rec_Num: targetStr,
          },
        };

        axios
          .delete("/record/RecordFile", config)
          .then(res => {
            if (res.status === 200) {
              getRecordCardTotal();
              getRecordCardData();

              setCheckedInputs([]);
              const obj = {
                VIEW_TYPE: RECORD_STATE.VIEW_TYPE,
                IS_CHECKED: false,
                CHECKED_ARR: [],
                SEARCH_TEXT: RECORD_STATE.SEARCH_TEXT,
              };
              dispatch(updateRecordInfo(obj));

              setAlertProps({
                ...alertProps,
                isShow: true,
                text: t("my.record.msg.delete.success"),
                cancelBtnDisplay: false,
                okBtnEventHandler: function () {
                  closeAlertEvent();
                },
              });
            }
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      },
      cancleBtnEventHandler: function () {
        closeAlertEvent();
      },
    });
  };
  return (
    <div
      style={{
        margin: "26px 40px",
        height: "845px",
        width: "1404px",
      }}
    >
      <RecordHeader deleteCheckedRecordData={deleteCheckedRecordData} />

      <div
        style={{
          backgroundColor: "rgba(255,255,255,0.4)",
          height: "845px",
          marginTop: "23px",
          borderRadius: "12px",
        }}
      >
        <div
          id="record_card_div"
          className="scrollbar"
          style={{
            overflowY: "auto",
            height: "844px",
            padding: "0px 0px 0px 42px",
          }}
          onScroll={() => infiniteScroll()}
        >
          <Alert alertProps={alertProps} />
          {loading ? (
            <div
              style={{
                height: "100%",
              }}
            >
              <Loading />
            </div>
          ) : date.length > 0 ? (
            date.map((item, dateIndex) => {
              item.childCount = 0;
              return (
                <div
                  key={"record_card_key_" + dateIndex}
                  id={"record_date_" + dateIndex}
                  name={"record_date_" + item.date}
                  className="dateLine_Timeline"
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="checkbox"
                      name={"Rec_Chk_" + item.chkNum}
                      id={"Rec_Chk_ALL_" + item.chkNum}
                      className="Rec_Chk_ALL"
                      onChange={e => {
                        clickCheckboxAll_Card(
                          e.currentTarget.checked,
                          "Rec_Chk_" + item.chkNum
                        );
                      }}
                      style={{
                        float: "left",
                        width: "20px",
                        height: "20px",
                        marginTop: "5px",
                        display: "none",
                      }}
                    />
                    <label
                      className="float-left"
                      htmlFor={"Rec_Chk_ALL_" + item.chkNum}
                    >
                      <span
                        className={
                          REDUCER_STATE.GROUP_YN === "N" ||
                            REDUCER_STATE.GROUP_YN === ""
                            ? "record_card_span_undefined"
                            : "groupRecord_card_span"
                        }
                      />
                    </label>
                    <p
                      className={
                        REDUCER_STATE.GROUP_YN === "N" ||
                          REDUCER_STATE.GROUP_YN === ""
                          ? "record_date_timeline"
                          : "groupRecord_date_timeline"
                      }
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        paddingLeft: "22px",
                        color:
                          REDUCER_STATE.GROUP_YN === "N" ||
                            REDUCER_STATE.GROUP_YN === ""
                            ? "#2B4AC4"
                            : "#3B3F4C",
                      }}
                    >
                      {item.TITLE}
                    </p>
                  </div>

                  <div id={"record_card_section_" + item.date}>
                    {data.map((data_item, index) => {
                      if (data_item.RECDAY === item.date) {
                        item.childCount = item.childCount + 1;
                        return (
                          <div
                            name={"cardItem_" + item.chkNum}
                            id={"cardItemID_" + index}
                            className={
                              data_item.GROUP_YN === "Y"
                                ? "fileItem_Timeline record_card_" +
                                data_item.GROUP_COLOR
                                : "fileItem_Timeline record_card_undefined"
                            }
                            key={
                              "record_card_item_" +
                              data_item.RECDAY +
                              "_" +
                              index
                            }
                            style={{
                              marginRight:
                                item.childCount % 4 === 0 &&
                                  item.childCount !== 0
                                  ? "0px"
                                  : "24px",
                            }}
                          >
                            <div
                              style={{
                                paddingLeft: "24px",
                                paddingRight: "16px",
                                marginTop: "16px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                className={
                                  data_item.GROUP_YN === "Y"
                                    ? "record_card_title_" +
                                    data_item.GROUP_COLOR
                                    : "record_card_title_undefined"
                                }
                                style={{
                                  fontSize: "20px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  maxWidth: "250px",
                                }}
                              >
                                {data_item.REC_TITLE}
                              </div>
                              <input
                                className="float-right Rec_Chk"
                                name={"Rec_Chk_" + data_item.chkNum}
                                id={"Rec_Chk_" + data_item.REC_NUM}
                                onChange={e => {
                                  clickCheckbox_Card(
                                    e.currentTarget.checked,
                                    data_item.REC_NUM,
                                    data_item.chkNum
                                  );
                                }}
                                checked={
                                  checkedInputs.includes(data_item.REC_NUM)
                                    ? true
                                    : false
                                }
                                value={data_item.REC_NUM}
                                type="checkbox"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  cursor: "pointer",
                                  borderRadius: "3px",
                                  display: "none",
                                }}
                              />
                              <label
                                className="float-right"
                                htmlFor={"Rec_Chk_" + data_item.REC_NUM}
                              >
                                <span
                                  className={
                                    data_item.GROUP_YN === "Y"
                                      ? "record_card_span_" +
                                      data_item.GROUP_COLOR
                                      : "record_card_span_undefined"
                                  }
                                />
                              </label>
                            </div>

                            <div
                              className="text-center"
                              style={{
                                marginTop: "23px",
                                // marginBottom: "41px",
                                paddingLeft: "24px",
                                paddingRight: "16px",
                              }}
                            >
                              {REDUCER_STATE.GROUP_YN === "N" ? (
                                <>
                                  <img
                                    className="cursor"
                                    // src="/img/icon/record/card_play.png"
                                    src={
                                      data_item.GROUP_YN === "Y"
                                        ? "/img/icon/record/play_" +
                                        data_item.GROUP_COLOR +
                                        ".png"
                                        : "/img/icon/record/play_undefined.png"
                                    }
                                    alt="play"
                                    onClick={() =>
                                      window.open(data_item.STREAM_URL)
                                    }
                                  />
                                  <img
                                    className="cursor"
                                    src={
                                      data_item.GROUP_YN === "Y"
                                        ? "/img/icon/record/down_" +
                                        data_item.GROUP_COLOR +
                                        ".png"
                                        : "/img/icon/record/down_undefined.png"
                                    }
                                    alt="download"
                                    onClick={() =>
                                      downloadRecordFile(data_item.FILE_URL, data_item.REC_NUM)
                                    }
                                  />
                                  <img
                                    className="cursor"
                                    src={
                                      data_item.GROUP_YN === "Y"
                                        ? "/img/icon/record/delete_" +
                                        data_item.GROUP_COLOR +
                                        ".png"
                                        : "/img/icon/record/delete_undefined.png"
                                    }
                                    alt="delete"
                                    onClick={() =>
                                      deleteRecordData(data_item.REC_NUM)
                                    }
                                  />
                                </>
                              ) : (
                                <></>
                              )}
                              {REDUCER_STATE.PLAY_YN === "Y" ? (
                                <img
                                  className="cursor"
                                  // src="/img/icon/record/card_play.png"
                                  src={
                                    data_item.GROUP_YN === "Y"
                                      ? "/img/icon/record/play_" +
                                      data_item.GROUP_COLOR +
                                      ".png"
                                      : "/img/icon/record/play_undefined.png"
                                  }
                                  alt="play"
                                  onClick={() =>
                                    window.open(data_item.STREAM_URL)
                                  }
                                />
                              ) : (
                                <></>
                              )}
                              {REDUCER_STATE.DOWNLOAD_YN === "Y" ? (
                                <img
                                  className="cursor"
                                  src={
                                    data_item.GROUP_YN === "Y"
                                      ? "/img/icon/record/down_" +
                                      data_item.GROUP_COLOR +
                                      ".png"
                                      : "/img/icon/record/down_undefined.png"
                                  }
                                  alt="download"
                                  onClick={() =>
                                    downloadRecordFile(data_item.FILE_URL, data_item.REC_NUM)
                                  }
                                />
                              ) : (
                                <></>
                              )}
                              {REDUCER_STATE.AUTH_CD === "CD0301" ? (
                                <img
                                  className="cursor"
                                  src={
                                    data_item.GROUP_YN === "Y"
                                      ? "/img/icon/record/delete_" +
                                      data_item.GROUP_COLOR +
                                      ".png"
                                      : "/img/icon/record/delete_undefined.png"
                                  }
                                  alt="delete"
                                  onClick={() =>
                                    deleteRecordData(data_item.REC_NUM)
                                  }
                                />
                              ) : (
                                <></>
                              )}
                            </div>

                            <div
                              style={{
                                paddingLeft: "24px",
                                paddingRight: "16px",
                                marginBottom: "20px",
                                marginTop: "32px",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: "14px",
                                  color: "#3B3F4C",
                                  float: "left",
                                }}
                              >
                                {data_item.STHM} ~ {data_item.EDHM} (
                                {data_item.DURTM})
                              </div>
                              {data_item.GROUP_YN === "Y" ? (
                                <p
                                  className={
                                    "record_card_groupNM_" +
                                    data_item.GROUP_COLOR
                                  }
                                  style={{
                                    float: "right",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    margin: "3px 0 0 0px",
                                  }}
                                >
                                  {data_item.GROUP_NM}
                                </p>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div
                            key={
                              "record_card_item_" +
                              data_item.RECDAY +
                              "_" +
                              index
                            }
                          ></div>
                        );
                      }
                    })}
                  </div>
                </div>
              );
            })
          ) : (
            <RecordEmpty />
          )}
        </div>
      </div>
    </div>
  );
}

export default RecordCardList;
