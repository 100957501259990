import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateStatisticsDetail } from "actions/index";
/**
 * Version : 1.0
 * 파일명 : DataCardItem.js
 * 작성일자 : 2022-03-04
 * 작성자 : 권도훈
 * 설명 : 통계 카드 아이템
 * 수정일자 : 2022-04-15
 * 수정자 : 강연승
 * 수정내역 : 테마별 텍스트 적용
 */
function DataCardItem(props) {
  /**
   * DISPATCH
   */
  const dispatch = useDispatch();
  /**
   * REDUCER
   */
  const GROUP_STATE = useSelector(state => state.groupInfoReducer);
  /**
   * 상세보기
   */
  const goDetail = data => {
    props.setDateDetail({
      START_DATE: new Date(props.date.START_DATE),
      END_DATE: new Date(props.date.END_DATE),
    });
    props.setDetailSearch(true);
    dispatch(updateStatisticsDetail(data));
  };
  /**
   * 참석률
   */
  const calRate = (count, total) => {
    let rate = 0;

    if (total > 0) {
      rate = Math.floor((count / total) * 100);
    }

    return rate + "%";
  };
  return (
    <>
      <article className="data-statistics-card-article">
        <div
          className="data-statistics-card-profile"
          style={{
            background: props.data.BACKGROUND_COLOR,
          }}
        >
          {props.data.NAME.substr(0, 1)}
        </div>

        <font className="data-statistics-card-name">{props.data.NAME}</font>

        <font className="data-statistics-card-email">{props.data.EMAIL}</font>
      </article>

      <ul className="data-statistics-card-ul">
        <li className="data-statistics-card-data">
          <div>
            <img src="/img/icon/data/icon_number.png" alt="total" />
            <font>
              {GROUP_STATE.GROUP_CATEGORY === "CD0401" ||
              GROUP_STATE.GROUP_CATEGORY === "CD0402"
                ? "총 개설 회의 수"
                : GROUP_STATE.GROUP_CATEGORY === "CD0403"
                ? "총 개설 수업 수"
                : GROUP_STATE.GROUP_CATEGORY === "CD0404" ||
                  GROUP_STATE.GROUP_CATEGORY === "CD0405"
                ? "총 개설 강의 수"
                : "총 개설 모임 수"}
            </font>
          </div>
          <font>{props.data.TOTAL}</font>
        </li>

        <li className="data-statistics-card-data margin">
          <div>
            <img src="/img/icon/data/icon_time.png" alt="duration" />
            <font>
              {GROUP_STATE.GROUP_CATEGORY === "CD0401" ||
              GROUP_STATE.GROUP_CATEGORY === "CD0402"
                ? "총 회의시간"
                : GROUP_STATE.GROUP_CATEGORY === "CD0403"
                ? "총 수업시간"
                : GROUP_STATE.GROUP_CATEGORY === "CD0404" ||
                  GROUP_STATE.GROUP_CATEGORY === "CD0405"
                ? "총 강의시간"
                : "총 모임시간"}
            </font>
          </div>
          <font>{props.data.DURATION + "분"}</font>
        </li>

        <li className="data-statistics-card-data margin">
          <div>
            <img src="/img/icon/data/icon_attendee.png" alt="participant" />
            <font>참석인원/초대인원</font>
          </div>
          <font>
            {props.data.PARTICIPANT_COUNT +
              "/" +
              (props.data.INVITE_PARTICIPANTS_COUNT +
                props.data.NON_INVITE_PARTICIPANTS_COUNT)}
          </font>
        </li>

        <li className="data-statistics-card-data margin">
          <div>
            <img src="/img/icon/data/icon_rate.png" alt="rate" />
            <font>
              {GROUP_STATE.GROUP_CATEGORY === "CD0403" ||
              GROUP_STATE.GROUP_CATEGORY === "CD0404" ||
              GROUP_STATE.GROUP_CATEGORY === "CD0405"
                ? "평균 출석률"
                : "평균 참석률"}
            </font>
          </div>

          <font>
            {/* {props.data.ATTENDANCE_RATE + "%"} */}
            {calRate(
              props.data.PARTICIPANT_COUNT,
              props.data.INVITE_PARTICIPANTS_COUNT +
                props.data.NON_INVITE_PARTICIPANTS_COUNT
            )}
          </font>
        </li>
      </ul>

      <div
        className="data-statistics-card-detailBtn cursor"
        onClick={() => goDetail(props.data)}
      >
        자세히 보기
      </div>
    </>
  );
}

export default DataCardItem;
