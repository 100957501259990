import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
/**
 * Version : 1.0
 * 파일명 : recordCardList.js
 * 작성일자 : 2021-10-21
 * 작성자 : 권도훈
 * 설명 : 주소록
 * 수정일자 : 2022-02-09
 * 수정자 : 강연승
 * 수정내역 : 연락처 그룹 테마별 텍스트 적용
 */
function RecordEmpty() {
  /**
   * SELECTOR
   */
  const RECORD_STATE = useSelector(state => state.recordInfoReducer);
  const REDUCER_STATE = useSelector(state => state.groupInfoReducer);
  /**
   * 언어변환
   */
  const { t } = useTranslation();
  return (
    <>
      {RECORD_STATE.VIEW_TYPE === "CARD" ? (
        <div
          className="flex-y-center"
          style={{
            height: "800px",
            width: "1360px",
            position: "absolute",
            color: "#84889B",
          }}
        >
          <img src="/img/icon/record/no_file.png" alt="empty" />
          <div
            style={{
              fontSize: "20px",
              margin: "32px 0px",
            }}
          >
            {t("my.record.txt.empty.title")}
          </div>
          <div
            style={{
              fontSize: "16px",
            }}
          >
            <div>
              {REDUCER_STATE.GROUP_YN === "N"
                ? t("my.record.txt.empty.comment.1.CD0401")
                : t(
                    "my.record.txt.empty.comment.1." +
                      REDUCER_STATE.GROUP_CATEGORY
                  )}
            </div>
            <div>{t("my.record.txt.empty.comment.2")}</div>
          </div>
        </div>
      ) : (
        <tr>
          <td>
            <div
              className="flex-y-center"
              style={{
                height: "655px",
                width: "1360px",
                position: "absolute",
                color: "#84889B",
              }}
            >
              <img src="/img/icon/record/no_file.png" alt="empty" />
              <div
                style={{
                  fontSize: "20px",
                  margin: "32px 0px",
                }}
              >
                {t("my.record.txt.empty.title")}
              </div>
              <div
                style={{
                  fontSize: "16px",
                }}
              >
                <div>
                  {REDUCER_STATE.GROUP_YN === "N"
                    ? t("my.record.txt.empty.comment.1.CD0401")
                    : t(
                        "my.record.txt.empty.comment.1." +
                          REDUCER_STATE.GROUP_CATEGORY
                      )}
                </div>
                <div>{t("my.record.txt.empty.comment.2")}</div>
              </div>
            </div>
          </td>
        </tr>
      )}
    </>
  );
}

export default RecordEmpty;
