/**
 * Import List
 */
import React, { useEffect } from "react";
import { Cookies } from "react-cookie";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import axios from "axios";
import { isMobile, isTablet } from "react-device-detect";
/**
 * CSS
 */
import "css/alarm.css";
import "css/alert.css";
import "css/common.css";
import "css/data.css";
import "css/account.css";
import "css/address.css";
import "css/calendar.css";
import "css/clause.css";
import "css/content.css";
import "css/header.css";
import "css/login.css";
import "css/menu.css";
import "css/mobile_auth.css";
import "css/modal.css";
import "css/record.css";
import "css/schedule.css";
import "css/sidebar.css";
import "css/payment.css";
import "css/profile.css";
import "css/popup.css";
import "css/group.css";
import "css/dashboard.css";
/**
 * Component
 */
import Login from "component/page/account/login";
import Empty from "component/page/empty";
import AuthMail from "component/page/account/authMail";
import MakeAccount from "./account/makeAccount";
import Landing from "component/page/Landing/Landing";
import CallBack_kakao from "component/api/callBack_kakao";
import CallBack_naver from "component/api/callBack_naver";
import CallBack_common from "component/api/callBack_common";
import Loginpopup from "component/tag/popup/account/loginpopup";
import CallBack_google from "component/api/callBack_google";
import "lang/i18n";
import DeleteAccount from "./account/deleteAccount";
import ChangePassword from "./account/changePassword";
import { updateAlramCount } from "actions/index";
import { useDispatch } from "react-redux";
import HeadernoBtn from "component/tag/div/common/header_noBtn";
import Alert from "component/tag/modal/newAlert";
import App from "./app";
import Support from "./Landing/support";
import Recipt from "./recipt";
import LoginAdmin from "./account/loginadmin";
import DormantCheck from "./account/dormantCheck";
/**
 * Version : 1.0
 * 파일명 : pebble.js
 * 작성일자 : 2021-09-30
 * 작성자 : 권도훈
 * 설명 : Pebble 기본 파일
 * 수정일자 : 2022-04-19
 * 수정자 : 정지윤
 * 수정내역 : 관리자 로그인 추가
 * 수정일자 : 2022-05-25
 * 수정자 : 정지윤
 * 수정내역 : 휴면계정 로그인 추가
 * 수정일자 : 2022-06-08
 * 수정자 : 권도훈
 * 수정내역 : 모바일 분기 처리
 */
function Pebble() {
  /**
   * Cookie
   */
  const cookies = new Cookies();

  //cookie
  const domain = process.env.REACT_APP_COOKIE_DOMAIN;
  const refreshNm = process.env.REACT_APP_COOKIE_REFRESH;
  const authNm = process.env.REACT_APP_COOKIE_AUTH;
  const loginTypeNm = process.env.REACT_APP_COOKIE_TYPE;
  const autoLoginNm = process.env.REACT_APP_COOKIE_AUTO_LOGIN;
  /**
   * dispatch
   */
  const dispatch = useDispatch();

  //mobile
  // useEffect(() => {
  //   if (isMobile && !isTablet) {
  //     const isViewPc = cookies.get(viewTypeNm);

  //     if (isViewPc === undefined || isViewPc === false) {
  //       window.location.href = "https://m.pebbling.co.kr";
  //     }
  //   }
  // }, [cookies, viewTypeNm]);
  /**
   * url 설정
   */
  let front_url = "";
  let server_url = "";

  if (process.env.NODE_ENV === "development") {
    front_url =
      process.env.REACT_APP_FRONT_URL + process.env.REACT_APP_FRONT_PORT;
    server_url =
      process.env.REACT_APP_SERVER_URL + process.env.REACT_APP_SERVER_PORT;
  } else {
    front_url = process.env.REACT_APP_FRONT_URL;
    server_url = process.env.REACT_APP_SERVER_URL;
  }

  const refreshAuthToken = () => {
    cookies.set(
      authNm,
      "refresh",
      {
        path: "/",
        maxAge: 60 * 60,
        domain: domain
      }
    );

    const config = {
      params: {
        Request_Token: cookies.get(refreshNm)
      },
    };

    axios
      .put("/user/token", null, config)
      .then(res => {
        cookies.remove(
          authNm,
          {
            path: "/",
            domain: domain
          }
        );

        if (res.status === 200) {
          cookies.set(
            authNm,
            res.data.AUTH,
            {
              path: "/",
              maxAge: 60 * 60,
              domain: domain
            }
          );

          cookies.set(
            loginTypeNm,
            res.data.TYPE,
            {
              path: "/",
              maxAge: 60 * 60,
              domain: domain
            }
          );

          if (res.data.REFRESH !== undefined) {
            cookies.set(
              refreshNm,
              res.data.REFRESH,
              {
                path: "/",
                maxAge: 24 * 60 * 60 * 30,
                domain: domain
              }
            );
          }
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  /**
   * Axios 설정
   */
  axios.defaults.baseURL = front_url;
  axios.defaults.withCredentials = false;
  axios.interceptors.request.use(
    function (config) {
      if (config.params == null) {
        config.params = {};
      }

      config.url = server_url + config.url;
      config.params.REFRESH = cookies.get(refreshNm);
      config.params.AUTHORIZATION = cookies.get(authNm);
      config.params.TYPE = cookies.get(loginTypeNm);

      if (
        config.url === server_url + "/user/kakao" ||
        config.url === server_url + "/user/naver" ||
        config.url === server_url + "/user/google" ||
        config.url === server_url + "/user/login" ||
        config.url === server_url + "/user/logout" ||
        config.url === server_url + "/user/member" ||
        config.url === server_url + "/schedule/sendPasswordMail" ||
        config.url === server_url + "/schedule/sendAuthMail" ||
        config.url === server_url + "/user/checkUseableAccount" ||
        config.url === server_url + "/user/checkAuthCode" ||
        config.url === server_url + "/user/error" ||
        config.url === server_url + "/user/token" ||
        config.url === server_url + "/payment/product" ||
        config.url === server_url + "/schedule/reDirectZoomHostUrl" ||
        config.url === server_url + "/support" ||
        config.url === server_url + "/support/gusetsup" ||
        config.url === server_url + "/support/sendguestMail" ||
        config.url === server_url + "/payment/recipt" ||
        config.url === server_url + "/support/loginadmin" ||
        config.url === server_url + "/user/tokenAdmin" ||
        config.url === server_url + "/support/getlogininfo" ||
        config.url === server_url + "/user/DormantAuth" ||
        config.url === server_url + "/user/Dormantcheck"



      ) {
        return config;
      }

      if (cookies.get(authNm) === undefined) {
        if (cookies.get(refreshNm) !== undefined) {
          refreshAuthToken();
          return;
        } else {
          window.location.href = "/";

          if (cookies.get(autoLoginNm) !== undefined) {
            cookies.remove(
              autoLoginNm,
              {
                path: "/",
                domain: domain
              }
            );
          }
          if (cookies.get(loginTypeNm) !== undefined) {
            cookies.remove(
              loginTypeNm,
              {
                path: "/",
                domain: domain
              }
            );
          }
          return;
        }
      } else {
        return config;
      }
    },
    function (error) {
      return Promise.reject(error);
    }
  );
  axios.interceptors.response.use(
    function (response) {
      if (response.headers.check_token !== undefined) {
        if (response.headers.check_token === "FAIL") {
          cookies.remove(
            loginTypeNm,
            {
              path: "/",
              domain: domain
            }
          );

          cookies.remove(
            authNm,
            {
              path: "/",
              domain: domain
            }
          );

          cookies.remove(
            refreshNm,
            {
              path: "/",
              domain: domain
            }
          );

          window.location.href = "/";
        }
      }

      if (response.headers.refresh_authorization !== undefined) {
        cookies.remove(
          authNm,
          {
            path: "/",
            domain: domain
          }
        );

        cookies.set(
          authNm,
          response.headers.refresh_authorization,
          {
            path: "/",
            maxAge: 60 * 60,
            domain: domain
          }
        );
      }

      if (response.headers.update_refreshtoken !== undefined) {
        cookies.remove(
          refreshNm,
          {
            path: "/",
            domain: domain
          }
        );

        cookies.set(
          refreshNm,
          response.headers.update_refreshtoken,
          {
            path: "/",
            maxAge: 24 * 60 * 60 * 30,
            domain: domain
          }
        );
      }

      if (response.headers.alram_count !== undefined) {
        dispatch(updateAlramCount(response.headers.alram_count));
      }

      return response;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  return (
    <div>
      <Alert />

      <Router>
        <Switch>
          <Route exact path="/">
            <Landing />
            {/* <Maintainance /> */}
          </Route>

          <Route path="/login">
            <Login />
          </Route>

          <Route path="/loginpopup" component={Loginpopup}></Route>

          <Route path="/delete">
            <HeadernoBtn />
            <DeleteAccount />
          </Route>

          <Route path="/password">
            <ChangePassword />
          </Route>

          <Route path="/app">
            <App />
          </Route>

          <Route path="/authMail">
            <AuthMail />
          </Route>

          <Route path="/makeAccount">
            <MakeAccount />
          </Route>

          <Route path="/support">
            <Support />
          </Route>

          <Route path="/loginadmin">
            <LoginAdmin />
          </Route>
          <Route path="/dormantAuth">
            <DormantCheck />
          </Route>


          <Route path="/callback/kakao" component={CallBack_kakao}></Route>

          <Route path="/callback/naver" component={CallBack_naver}></Route>

          <Route path="/callback/google" component={CallBack_google}></Route>

          <Route path="/callback/:type" component={CallBack_common}></Route>

          <Route path="/recipt">
            <Recipt />
          </Route>

          <Route component={Empty}></Route>
        </Switch>
      </Router>
    </div>
  );
}

export default Pebble;
