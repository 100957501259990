import React from 'react'
import { useHistory } from 'react-router-dom';
/**
 * Version : 1.0
 * 파일명 : DeleteGroupAlram.js
 * 작성일자 : 2021-12-16
 * 작성자 : 권도훈
 * 설명 : 그룹 삭제 알람
 * 수정일자 : 2022-02-03
 * 수정자 : 권도훈
 * 수정내역 : history push로 변경
*/
function DeleteGroupAlram(props) {
    /**
     * history
     */
    const history = useHistory();
    /**
     * 내용 포맷
     */
    const contentConvert = (data) => {
        const split = data.split("%SPLIT%");

        return (
            <div>
                <div>[ {split[0]} ] [ {split[1]} ] 님이 그룹을 삭제하였습니다.</div>
            </div>
        )
    }
    return (
        <div
            className="alarm_Item cursor"
            key={props.data.ALRAM_NUM}
            onClick={() => {
                history.push({
                    pathname: "/app/group"
                });
                // window.location.href = "/app/group";
            }}
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: "24px",
                    color: props.data.READ_YN === "Y" ? "grey" : "blue"
                }}
            >
                <div className="font-bold">
                    그룹 삭제
                </div>

                <div
                    style={{
                        fontSize: "14px"
                    }}
                >
                    {props.date}
                </div>
            </div>

            <div
                style={{
                    marginTop: "24px",
                    marginBottom: "24px",
                    color: props.data.READ_YN === "Y" ? "grey" : "black",
                }}
                className="m-0"
            >
                {contentConvert(props.data.ALRAM_CONTENT)}
            </div>
        </div>
    )
}

export default DeleteGroupAlram
