/**
 * Import List
 */
import axios from "axios";
import Google from "component/api/google";
import Kakao from "component/api/kakao";
import Naver from "component/api/naver";
import React, { useState } from "react";
import { openAlert, closeAlert } from "actions/index";
import { useDispatch } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
/**
 * Version : 1.0
 * 파일명 : authMail.js
 * 작성일자 : 2021-09-30
 * 작성자 : 권도훈
 * 설명 : 인증코드 메일 전송
 * 수정일자 : 2022-06-15
 * 수정자 : 강연승
 * 수정내역 : sobridge 데모 - sns 간편가입 주석
 */
function GetCode(props) {
  /**
   * DISPATCH
   */
  const dispatch = useDispatch();
  /**
   * state
   */
  // const [isActiveBtn, setIsActiveBtn] = useState(false);
  // const [isDisabled, setIsDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  /**
   * 사용 가능한 메일 확인 & 메일 전송
   */
  const checkUseableAccount = () => {
    setLoading(true);

    if (checkEmail(props.mail)) {
      const config = {
        params: {
          Request_ID: props.mail,
        },
      };

      axios
        .get("/user/checkUseableAccount", config)
        .then(res => {
          if (res.status === 200) {
            let params = {
              type: "INSERT",
              to: props.mail,
            };

            if (props.groupInviteCode !== "") {
              params = {
                ...params,
                code: props.groupInviteCode,
              };
            }

            axios.post("/schedule/sendAuthMail", params).catch(() => {
              const obj = {
                TEXT: "오류가 발생했습니다.",
                submitEventHandler: () => {
                  dispatch(closeAlert());
                  setLoading(false);
                },
              };
              dispatch(openAlert(obj));
            });
            props.setState(true);
          } else {
            const obj = {
              TEXT: "사용할 수 없는 이메일입니다.",
              submitEventHandler: () => {
                dispatch(closeAlert());
                setLoading(false);
              },
            };
            dispatch(openAlert(obj));
          }
        })
        .catch(() => {
          const obj = {
            TEXT: "사용할 수 없는 이메일입니다.",
            submitEventHandler: () => {
              dispatch(closeAlert());
              setLoading(false);
            },
          };
          dispatch(openAlert(obj));
        });
    } else {
      const obj = {
        TEXT: "잘못된 이메일 형식입니다.",
        submitEventHandler: () => {
          dispatch(closeAlert());
          setLoading(false);
        },
      };
      dispatch(openAlert(obj));
      return;
    }
  };
  /**
   * 메일 값 변경
   */
  // const onChangeEvent = (e) => {
  //   props.setMail(e.target.value);

  //   if (e.target.value !== "" && e.target.value !== null) {
  //     setIsActiveBtn(true);
  //     setIsDisabled(false);
  //   } else {
  //     setIsActiveBtn(false);
  //     setIsDisabled(true);
  //   }
  // };
  /**
   * 이메일 정규식
   */
  const checkEmail = email => {
    var reg =
      /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
    return reg.test(email);
  };
  /**
   * 태그
   */
  return (
    <div
      style={{
        height: "100%",
        position: "absolute",
        width: "100%",
        backgroundImage: "url(/img/login_background.png)",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <section
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          className="cursor"
          src="/img/logo.png"
          alt="logo_title"
          onClick={() => (window.location.href = "/")}
        />

        <div
          style={{
            color: "#2B4AC4",
            fontSize: "32px",
            fontWeight: "bold",
            marginTop: "16px",
            marginBottom: "24px",
          }}
        >
          간단한 인증으로 SoBridge 사용하기
        </div>

        <div
          style={{
            color: "#2B4AC4",
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          몇 가지 절차만 밟으면 SoBridge을 사용하실 수 있습니다.
        </div>

        <img
          alt="STEP01"
          src="/img/icon/makeAccount/step1.png"
          style={{
            margin: "40px 0",
          }}
        />

        <input
          onChange={e => props.setMail(e.target.value)}
          onKeyDown={e => {
            if (e.key === "Enter") {
              if (e.target.value === "") {
                const obj = {
                  TEXT: "이메일을 입력해주세요.",
                  submitEventHandler: () => {
                    dispatch(closeAlert());
                  },
                };
                dispatch(openAlert(obj));
                return;
              } else {
                checkUseableAccount();
              }
            }
          }}
          style={{
            outline: "none",
            border: "none",
            marginBottom: "24px",
          }}
          className="makeAccountInput"
          type="text"
          placeholder="실제로 사용하는 메일 주소를 입력해주세요."
        />

        <button
          className="makeAccountBtn"
          onClick={() => checkUseableAccount()}
          disabled={props.mail === "" || loading ? true : false}
        >
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            <font>인증코드 받기</font>
          )}
        </button>

        {/* <img
          src="/img/or.png"
          alt="OR"
          style={{
            margin: "40px 0",
          }}
        />

        <Naver
          type="CREATE"
          groupCode={props.groupInviteCode !== "" ? props.groupInviteCode : ""}
        />
        <Kakao
          type="CREATE"
          groupCode={props.groupInviteCode !== "" ? props.groupInviteCode : ""}
        />
        <Google
          type="CREATE"
          groupCode={props.groupInviteCode !== "" ? props.groupInviteCode : ""}
        /> */}
      </section>
    </div>
  );
}

export default GetCode;
