import React, { useCallback, useEffect, useRef, useState } from 'react'
import { openAlert, closeAlert } from "actions/index";
import { CircularProgress } from "@material-ui/core";
import axios from 'axios';
import { useDispatch } from 'react-redux';
/**
 * Version : 1.0
 * 파일명 : PaymentAuth.js
 * 작성일자 : 2022-04-05
 * 작성자 : 권도훈
 * 설명 : 결제 토큰 인증 페이지
 * 수정일자 : 2022-04-19
 * 수정자 : 권도훈
 * 수정내역 : 닫기 이벤트 수정
 */
function PaymentAuth(props) {
    /**
     * dispatch
     */
    const dispatch = useDispatch();
    /**
     * view type
     */
    const [init, setInit] = useState(true);
    const [viewType, setViewType] = useState("TERM");
    const [btnText, setBtnText] = useState("동의합니다.");
    const [activeInput, setActiveInput] = useState("");
    const [loading, setLoading] = useState(false);
    const [disable, setDisable] = useState(false);
    /**
     * info
     */
    const [userData, setUserData] = useState({
        name: "",
        mobilePhoneNumber: "",
        birthDate: "",
        genderBirthCode: "",
        mobileCarrier: ""
    });
    const [token, setToken] = useState("");
    const [authNumber, setAuthNumber] = useState("");
    const [termsList, setTermsList] = useState([]);
    const authRef = useRef();

    /**
     * 미동의 이용약관 조회
     */
    const getTermsList = useCallback(() => {
        axios.get("/payment/terms")
            .then(res => {
                if (res.status === 200) {
                    setTermsList(res.data);
                    // setIsAuth(true);
                }
            })
            .catch(() => {
                const obj = {
                    TEXT: "이용약관 조희를 실패했습니다.",
                    submitEventHandler: () => dispatch(closeAlert())
                };
                dispatch(openAlert(obj));
            })
    }, [dispatch]);
    /**
     * 팝업 타입 초기화
     */
    useEffect(() => {
        if (init) {
            setViewType("TERM");
            setUserData({
                name: "",
                mobilePhoneNumber: "",
                birthDate: "",
                genderBirthCode: "",
                mobileCarrier: ""
            });
            setActiveInput("");
            setLoading(false);
            setAuthNumber("");
            setDisable(false);
            getTermsList();
            setInit(false);
        }
    }, [getTermsList, init]);

    useEffect(() => {
        if (viewType === "TERM") {
            setBtnText("동의합니다.");
        } else if (viewType === "PHONE") {
            setBtnText("인증 요청");
        } else if (viewType === "CONFIRM") {
            setBtnText("인증 확인");
        } else {
            setBtnText("이제 결제 수단을 등록할 수 있어요!");
        }
    }, [viewType]);

    useEffect(() => {
        if (userData.name !== ""
            && userData.mobilePhoneNumber !== ""
            && userData.birthDate !== ""
            && userData.genderBirthCode !== ""
            && userData.mobileCarrier !== ""
            && viewType === "PHONE") {
            setDisable(false);
        } else if (viewType === "PHONE") {
            if (userData.name !== ""
                || userData.mobilePhoneNumber !== ""
                || userData.birthDate !== ""
                || userData.genderBirthCode !== ""
                || userData.mobileCarrier !== "") {
                setDisable(true);
            }
        }

        if (viewType === "CONFIRM") {
            if (authNumber !== "") {
                setDisable(false);
            } else {
                setDisable(true);
            }
        }
    }, [authNumber, userData.birthDate, userData.genderBirthCode, userData.mobileCarrier, userData.mobilePhoneNumber, userData.name, viewType]);
    /**
     * 인증완료
     */
    const authSuccess = () => {
        props.setType("ADD_PAYMENT");
        props.setIsRender(true);
    }
    /**
     * 핸드폰 인증
     */
    const confirmMobile = () => {
        const config = {
            params: {
                token: token,
                authNumber: authNumber
            }
        }

        axios.post("/payment/mobile", null, config)
            .then(res => {
                if (res.status === 200) {

                    if (res.data.isSuccess === true) {
                        setViewType("SUCCESS");
                        setDisable(false);
                    } else {
                        let msg = res.data.message.split(": ")[1];
                        let json = JSON.parse(msg);

                        const obj = {
                            TEXT: json[0].message,
                            submitEventHandler: () => {
                                setAuthNumber("");
                                authRef.current.focus();
                                dispatch(closeAlert());
                            }
                        };
                        dispatch(openAlert(obj));
                    }
                }

            })
            .catch(() => {
                setLoading(false);
                const obj = {
                    TEXT: "인증이 실패했습니다.",
                    submitEventHandler: () => {
                        dispatch(closeAlert());
                    }
                };
                dispatch(openAlert(obj));
            })
    }
    /**
     * 휴대폰 인증 번호
     */
    const getAuthNumber = () => {
        setActiveInput("");
        setLoading(true);

        if (userData.name === "") {
            const obj = {
                TEXT: "사용자 성명을 입력해주세요.",
                submitEventHandler: () => {
                    setLoading(false);
                    dispatch(closeAlert());
                }
            };
            dispatch(openAlert(obj));
        } else if ("") {
            const obj = {
                TEXT: "사용자 주민번호 앞 7자리를 입력해주세요.",
                submitEventHandler: () => {
                    setLoading(false);
                    dispatch(closeAlert());
                }
            };
            dispatch(openAlert(obj));
        } else if (userData.birthDate === "" || userData.birthDate.length <= 5) {
            const obj = {
                TEXT: "사용자 주민번호 앞 6자리를 입력해주세요.",
                submitEventHandler: () => {
                    setLoading(false);
                    dispatch(closeAlert());
                }
            };
            dispatch(openAlert(obj));
        } else if (userData.genderBirthCode === "" || userData.birthDate.genderBirthCode < 1) {
            const obj = {
                TEXT: "성별코드를 입력해주세요.",
                submitEventHandler: () => {
                    setLoading(false);
                    dispatch(closeAlert());
                }
            };
            dispatch(openAlert(obj));

        } else if (userData.mobilePhoneNumber === "") {
            const obj = {
                TEXT: "사용자 휴대폰번호를 입력해주세요.",
                submitEventHandler: () => {
                    setLoading(false);
                    dispatch(closeAlert());
                }
            };
            dispatch(openAlert(obj));
        } else if (userData.mobileCarrier === "") {
            const obj = {
                TEXT: "통신사를 선택해주세요.",
                submitEventHandler: () => {
                    setLoading(false);
                    dispatch(closeAlert());
                }
            };
            dispatch(openAlert(obj));
        } else if (userData.mobileCarrier === "MVNO") {
            const obj = {
                TEXT: "알뜰폰 통신망을 선택해주세요.",
                submitEventHandler: () => {
                    setLoading(false);
                    dispatch(closeAlert());
                }
            };
            dispatch(openAlert(obj));
        } else {
            const config = {
                params: userData
            }

            config.params.customerToken = token;

            axios.get("/payment/mobile", config)
                .then(res => {
                    setLoading(false);

                    if (res.status === 200) {
                        if (res.data.isSuccess === true) {
                            setToken(res.data.newCustomerToken);
                            setViewType("CONFIRM");
                            setDisable(true);
                        } else {
                            let msg = res.data.message.split(": ")[1];
                            let json = JSON.parse(msg);

                            const obj = {
                                TEXT: json[0].message,
                                submitEventHandler: () => {
                                    dispatch(closeAlert());
                                }
                            };
                            dispatch(openAlert(obj));
                        }
                    }
                })
                .catch(() => {
                    setLoading(false);
                    const obj = {
                        TEXT: "인증이 실패했습니다.",
                        submitEventHandler: () => {
                            setLoading(false);
                            dispatch(closeAlert());
                        }
                    };
                    dispatch(openAlert(obj));
                })
        }
    }
    /**
     * 약관 동의
     */
    const agreeTerms = () => {
        setLoading(true);

        let id_list = "";

        for (let i = 0; i < termsList.length; i++) {
            if (i < termsList.length - 1) {
                id_list += termsList[i].id + ","
            } else {
                id_list += termsList[i].id;
            }
        }

        const config = {
            params: {
                TERMS_ID: id_list
            }
        }

        axios.post("/payment/terms", null, config)
            .then(res => {
                setLoading(false);

                if (res.status === 200) {
                    setToken(res.data.customerToken);
                    setDisable(true);
                    setViewType("PHONE");
                }
            })
            .catch(() => {
                setLoading(false);
                const obj = {
                    TEXT: "약관 동의를 실패했습니다.",
                    submitEventHandler: () => {
                        setLoading(false);
                        dispatch(closeAlert());
                    }
                };
                dispatch(openAlert(obj));
            })
    }
    /**
     * 버튼 이벤트
     */
    const btnEventHandler = () => {
        if (viewType === "TERM") {
            agreeTerms();
        } else if (viewType === "PHONE") {
            getAuthNumber();
        } else if (viewType === "CONFIRM") {
            confirmMobile();
        } else {
            authSuccess();
        }
    }
    /**
     * 숫자 포맷
     */
    const numberFormat = (amount) => {
        return amount.toString().replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
    }
    /**
     * 취소
     */
    const closeHandler = () => {
        if (props.initType === "PRODUCT") {
            props.setType("PAYMENT");
            props.setIsRender(true);
        } else {
            props.popupClose();
        }
    }
    return (
        <div className='py-product-auth'>
            <div className='py-product-auth-header'>
                <font>
                    결제 수단 등록
                </font>
            </div>

            <div className='py-product-auth-container'>
                {
                    viewType !== "SUCCESS"
                        ?
                        <>
                            <div className='py-product-auth-container-comment'>
                                <div>
                                    <div className='py-product-auth-container-comment-header'>
                                        <div
                                            className='py-product-auth-container-comment-icon'
                                            style={{
                                                backgroundImage: "url(/img/icon/payment/info.png)",
                                                backgroundPosition: "center",
                                                backgroundSize: "cover",
                                                backgroundRepeat: "no-repeat",
                                            }}
                                        />

                                        <font className='py-product-auth-container-comment-title'>
                                            사용자 인증
                                        </font>
                                    </div>

                                    <div className='py-product-auth-container-comment-detail'>
                                        <div>
                                            카드 및 계좌 등록을 위해서는
                                        </div>
                                        <div>
                                            한번의 사용자 인증이 필요합니다.
                                        </div>
                                    </div>
                                </div>

                                <button
                                    className='py-product-auth-container-comment-cancle cursor'
                                    onClick={() => closeHandler()}
                                >
                                    취소
                                </button>
                            </div>

                            <div className='py-product-auth-container-content'>
                                <div className='py-product-auth-container-content-inner'>
                                    <font className='py-product-auth-content-title'>
                                        {
                                            viewType === "TERM"
                                                ?
                                                "1) 이용약관 동의"
                                                :
                                                "2) 휴대폰 인증"
                                        }
                                    </font>
                                    <div className='py-product-auth-content-body'>
                                        {
                                            viewType === "TERM"
                                                ?
                                                <div className='py-product-auth-term-list'>
                                                    {
                                                        termsList.map((data, idx) => (
                                                            <article
                                                                key={idx}
                                                                className="cursor py-auth-term-item"
                                                                onClick={() => window.open(data.url)}
                                                            >
                                                                <div>
                                                                    {
                                                                        data.required === "true"
                                                                            ? "[필수] "
                                                                            : "[선택] "
                                                                    }
                                                                    {data.title}
                                                                </div>
                                                            </article>
                                                        ))
                                                    }
                                                </div>
                                                :
                                                viewType === "PHONE"
                                                    ?
                                                    <div className='py-product-auth-phone-list'>
                                                        <div className="py-auth-phone-item">
                                                            <label className="py-auth-phone-item-title">
                                                                사용자 성명
                                                            </label>

                                                            <div className="py-auth-phone-item-textArea">
                                                                <input
                                                                    className="py-auth-phone-input"
                                                                    type="text"
                                                                    placeholder="사용자 성명을 입력해주세요."
                                                                    onFocus={() => setActiveInput("name")}
                                                                    onBlur={() => setActiveInput("")}
                                                                    onChange={e => {
                                                                        setUserData({
                                                                            ...userData,
                                                                            name: e.target.value
                                                                        })
                                                                    }}
                                                                />

                                                                <div className="py-auth-phone-item-line">
                                                                    <span
                                                                        className={
                                                                            activeInput === "name"
                                                                                ? "payment_mobile_focus"
                                                                                : "payment_mobile_non_focus"
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="py-auth-phone-item">
                                                            <label className="py-auth-phone-item-title">
                                                                주민등록번호
                                                            </label>

                                                            <div className="py-auth-phone-birth">
                                                                <div className="py-auth-phone-item-textArea birth">
                                                                    <input
                                                                        className="py-auth-phone-input birth"
                                                                        type="text"
                                                                        maxLength="6"
                                                                        placeholder="생년월일"
                                                                        onFocus={() => setActiveInput("birthDate")}
                                                                        onBlur={() => setActiveInput("")}
                                                                        onChange={e => {
                                                                            e.target.value = numberFormat(e.target.value);
                                                                            setUserData({
                                                                                ...userData,
                                                                                birthDate: e.target.value
                                                                            })
                                                                        }}
                                                                    />

                                                                    <div className="py-auth-phone-item-line birth">
                                                                        <span
                                                                            className={
                                                                                activeInput === "birthDate"
                                                                                    ? "payment_mobile_focus"
                                                                                    : "payment_mobile_non_focus"
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <p className="py-auth-phone-item-min">-</p>

                                                                <div className="py-auth-phone-item-textArea gender">
                                                                    <input
                                                                        className="py-auth-phone-input gender"
                                                                        type="text"
                                                                        maxLength="1"
                                                                        placeholder="0"
                                                                        onFocus={() => setActiveInput("genderBirthCode")}
                                                                        onBlur={() => setActiveInput("")}
                                                                        onChange={e => {
                                                                            e.target.value = numberFormat(e.target.value);
                                                                            setUserData({
                                                                                ...userData,
                                                                                genderBirthCode: e.target.value
                                                                            })
                                                                        }}
                                                                    />

                                                                    <div className="py-auth-phone-item-line gender">
                                                                        <span
                                                                            className={
                                                                                activeInput === "genderBirthCode"
                                                                                    ? "payment_mobile_focus"
                                                                                    : "payment_mobile_non_focus"
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="payment_mobile_genderBirth_star">
                                                                    ******
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="py-auth-phone-item">
                                                            <label className="py-auth-phone-item-title">
                                                                휴대폰 번호
                                                            </label>

                                                            <div className="py-auth-phone-item-textArea">
                                                                <input
                                                                    className="py-auth-phone-input"
                                                                    type="text"
                                                                    placeholder="핸드폰 번호를 입력해주세요."
                                                                    onFocus={() => setActiveInput("mobilePhoneNumber")}
                                                                    onBlur={() => setActiveInput("")}
                                                                    onChange={e => {
                                                                        e.target.value = numberFormat(e.target.value);
                                                                        setUserData({
                                                                            ...userData,
                                                                            mobilePhoneNumber: e.target.value
                                                                        })
                                                                    }}
                                                                />

                                                                <div className="py-auth-phone-item-line">
                                                                    <span
                                                                        className={
                                                                            activeInput === "mobilePhoneNumber"
                                                                                ? "payment_mobile_focus"
                                                                                : "payment_mobile_non_focus"
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="py-auth-phone-item">
                                                            <label className="py-auth-phone-item-title">
                                                                통신사
                                                            </label>

                                                            <div className="py-auth-phone-btn-carrier-area">
                                                                <button
                                                                    className={
                                                                        userData.mobileCarrier === "SKT"
                                                                            ? "py-auth-phone-btn-carrier active cursor"
                                                                            : "py-auth-phone-btn-carrier cursor"
                                                                    }
                                                                    onClick={() => {
                                                                        setActiveInput("mobileCarrier");
                                                                        setUserData({
                                                                            ...userData,
                                                                            mobileCarrier: "SKT"
                                                                        });
                                                                    }}
                                                                >
                                                                    SKT
                                                                </button>

                                                                <button
                                                                    className={
                                                                        userData.mobileCarrier === "KT"
                                                                            ? "py-auth-phone-btn-carrier active cursor"
                                                                            : "py-auth-phone-btn-carrier cursor"
                                                                    }
                                                                    onClick={() => {
                                                                        setActiveInput("mobileCarrier");
                                                                        setUserData({
                                                                            ...userData,
                                                                            mobileCarrier: "KT"
                                                                        });
                                                                    }}
                                                                >
                                                                    KT
                                                                </button>

                                                                <button
                                                                    className={
                                                                        userData.mobileCarrier === "LGU"
                                                                            ? "py-auth-phone-btn-carrier active cursor"
                                                                            : "py-auth-phone-btn-carrier cursor"
                                                                    }
                                                                    onClick={() => {
                                                                        setActiveInput("mobileCarrier");
                                                                        setUserData({
                                                                            ...userData,
                                                                            mobileCarrier: "LGU"
                                                                        });
                                                                    }}
                                                                >
                                                                    LGU
                                                                </button>

                                                                <button
                                                                    className={
                                                                        userData.mobileCarrier === "SKT_MVNO"
                                                                            ? "py-auth-phone-btn-carrier active cursor"
                                                                            : "py-auth-phone-btn-carrier cursor"
                                                                    }
                                                                    onClick={() => {
                                                                        setActiveInput("mobileCarrier");
                                                                        setUserData({
                                                                            ...userData,
                                                                            mobileCarrier: "SKT_MVNO"
                                                                        });
                                                                    }}
                                                                >
                                                                    SKT 알뜰폰
                                                                </button>

                                                                <button
                                                                    className={
                                                                        userData.mobileCarrier === "KT_MVNO"
                                                                            ? "py-auth-phone-btn-carrier active cursor"
                                                                            : "py-auth-phone-btn-carrier cursor"
                                                                    }
                                                                    onClick={() => {
                                                                        setActiveInput("mobileCarrier");
                                                                        setUserData({
                                                                            ...userData,
                                                                            mobileCarrier: "KT_MVNO"
                                                                        });
                                                                    }}
                                                                >
                                                                    KT 알뜰폰
                                                                </button>

                                                                <button
                                                                    className={
                                                                        userData.mobileCarrier === "LGU_MVNO"
                                                                            ? "py-auth-phone-btn-carrier active cursor"
                                                                            : "py-auth-phone-btn-carrier cursor"
                                                                    }
                                                                    onClick={() => {
                                                                        setActiveInput("mobileCarrier");
                                                                        setUserData({
                                                                            ...userData,
                                                                            mobileCarrier: "LGU_MVNO"
                                                                        });
                                                                    }}
                                                                >
                                                                    LG U+ 알뜰폰
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    viewType === "CONFIRM"
                                                        ?
                                                        <div className='py-product-auth-num-list'>
                                                            <div className="py-auth-phone-item">
                                                                <label className="py-auth-phone-item-title">
                                                                    인증 번호
                                                                </label>

                                                                <div className="py-auth-phone-item-auth">
                                                                    <div className="py-auth-phone-item-num-area">
                                                                        <input
                                                                            className="py-auth-phone-input"
                                                                            type="text"
                                                                            placeholder="인증 번호를 입력해주세요."
                                                                            onFocus={() => setActiveInput("mobileAuthNumber")}
                                                                            onBlur={() => setActiveInput("")}
                                                                            onChange={e => {
                                                                                e.target.value = numberFormat(e.target.value);
                                                                                setAuthNumber(e.target.value);
                                                                            }}
                                                                            value={authNumber}
                                                                            ref={authRef}
                                                                        />

                                                                        <div className="py-auth-phone-item-line">
                                                                            <span
                                                                                className={
                                                                                    activeInput === "mobileAuthNumber"
                                                                                        ? "payment_mobile_focus"
                                                                                        : "payment_mobile_non_focus"
                                                                                }
                                                                            />

                                                                            <span
                                                                                className={
                                                                                    activeInput === "mobileAuthNumber"
                                                                                        ? "py-authphone-item-line-default none"
                                                                                        : "py-authphone-item-line-default"
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <button
                                                                        className={
                                                                            loading
                                                                                ? "py-auth-btn-num loading cursor"
                                                                                : "py-auth-btn-num cursor"
                                                                        }
                                                                        onClick={() => getAuthNumber()}
                                                                    >
                                                                        {
                                                                            loading
                                                                                ?
                                                                                <CircularProgress size={24} />
                                                                                :
                                                                                "재전송"
                                                                        }
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <></>
                                        }
                                    </div>
                                </div>

                                <button
                                    className={
                                        loading || disable
                                            ? "py-product-auth-btn loading"
                                            : "py-product-auth-btn cursor"
                                    }
                                    disabled={disable}
                                    onClick={() => btnEventHandler()}
                                >
                                    {
                                        loading
                                            ?
                                            <CircularProgress size={16} />
                                            :
                                            btnText
                                    }
                                </button>
                            </div>
                        </>
                        :
                        <div className='py-product-auth-success'>
                            <div className='py-product-auth-success-comment'>
                                사용자 인증에 성공 했습니다.
                            </div>

                            <button
                                className={
                                    loading || disable
                                        ? "py-product-auth-btn loading"
                                        : "py-product-auth-btn cursor"
                                }
                                disabled={disable}
                                onClick={() => btnEventHandler()}
                            >
                                {
                                    loading
                                        ?
                                        <CircularProgress size={16} />
                                        :
                                        btnText
                                }
                            </button>
                        </div>
                }
            </div >
        </div >
    )
}

export default PaymentAuth
