import axios from 'axios';
import ChangePw from 'component/tag/div/account/changePw';
import SendPwMail from 'component/tag/div/account/sendPwMail';
import { useHistory } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router';
import { useDispatch } from "react-redux";
import { openAlert, closeAlert } from "actions/index";
/**
 * Version : 1.0
 * 파일명 : changePassword.js
 * 작성일자 : 2021-12-06
 * 작성자 : 권도훈
 * 설명 : 비밀번호 변경 페이지
 * 수정일자 : 2022-02-09
 * 수정자 : 권도훈
 * 수정내역 : UI 수정
*/
function ChangePassword() {
    /**
     * history
     */
    const history = useHistory();
    /**
     * location
     */
    const location = useLocation();
    /**
     * dispatch
     */
    const dispatch = useDispatch();
    /**
     * 이메일
     */
    const [email, setEmail] = useState("");
    /**
     * 렌더링
     */
    const [render, setRender] = useState(<></>);
    /**
     * 로딩
     */
    const [loading, setLoading] = useState(false);
    /**
     * 변경 메일 발송
     */
    const sendEmail = useCallback(() => {
        if (email === "" && email === null) {
            alert("메일이 비어있습니다.");
            return;
        }

        const config = {
            params: {
                request_id: email,
            }
        }

        setLoading(true);

        axios.get("/user/member", config)
            .then(res => {
                if (res.status === 200) {
                    console.log(res.data);
                    if (res.data[0].User_Type === "PEBBLE") {
                        const params = {
                            type: "INSERT",
                            to: email,
                        };

                        axios.post("/schedule/sendPasswordMail", params)
                            .then(res => {
                                if (res.status === 200) {
                                    const obj = {
                                        TEXT: "입력하신 메일 주소로 비밀번호 변경 메일을 보냈습니다.",
                                        submitEventHandler: () => {
                                            dispatch(closeAlert());
                                            history.push(
                                                {
                                                    pathname: "/login",
                                                    state: {
                                                        tag: "block",
                                                        type: "default"
                                                    }
                                                }
                                            );
                                        }
                                    };
                                    dispatch(openAlert(obj));
                                }
                            })
                            .catch(() => {
                                const obj = {
                                    TEXT: "메일 발송에 실패했습니다.",
                                    submitEventHandler: () => {
                                        dispatch(closeAlert());
                                        setLoading(false);
                                    }
                                };
                                dispatch(openAlert(obj));
                            });
                    } else {
                        const obj = {
                            TEXT: res.data[0].User_Type + " 사용자 입니다.",
                            submitEventHandler: () => {
                                dispatch(closeAlert());
                                setLoading(false);
                                history.push(
                                    {
                                        pathname: "/login",
                                        state: {
                                            tag: "block",
                                            type: "default"
                                        }
                                    }
                                );
                            }
                        };
                        dispatch(openAlert(obj));
                        // setRender(<SnsAccount TYPE={res.data[0].User_Type} />);
                    }

                } else {
                    const obj = {
                        TEXT: "존재하지 않는 계정입니다.",
                        submitEventHandler: () => {
                            dispatch(closeAlert());
                            setLoading(false);
                        }
                    };
                    dispatch(openAlert(obj));
                }
            })
            .catch(() => {
                const obj = {
                    TEXT: "존재하지 않는 계정입니다.",
                    submitEventHandler: () => {
                        dispatch(closeAlert());
                        setLoading(false);
                    }
                };
                dispatch(openAlert(obj));
            })
    }, [dispatch, email, history]);
    /**
     * 비밀번호 변경
     */
    const updatePassword = useCallback((password) => {
        const config = {
            params: {
                EMAIL: email,
                PASSWORD: password.newPW
            }
        }

        setLoading(true);

        axios.put("/user/member", null, config)
            .then(res => {
                if (res.status === 200) {
                    const obj = {
                        TEXT: "비밀번호가 변경되었습니다.",
                        submitEventHandler: () => {
                            dispatch(closeAlert());
                            setLoading(false);
                            history.push(
                                {
                                    pathname: "/login",
                                    state: {
                                        tag: "block",
                                        type: "default"
                                    }
                                }
                            );
                        }
                    };
                    dispatch(openAlert(obj));
                } else {
                    const obj = {
                        TEXT: "변경에 오류가 발생했습니다.",
                        submitEventHandler: () => {
                            dispatch(closeAlert());
                            setLoading(false);
                        }
                    };
                    dispatch(openAlert(obj));
                }
            })
            .catch(() => {
                const obj = {
                    TEXT: "90일 이내에 사용한 비밀번호로 변경이 불가능합니다.",
                    submitEventHandler: () => {
                        dispatch(closeAlert());
                        setLoading(false);
                    }
                };
                dispatch(openAlert(obj));
            })
    }, [dispatch, email, history]);
    /**
     * 인증여부에 따른 렌더링
     */
    useEffect(() => {
        if (location.state !== undefined) {
            if (location.state.auth) {
                setEmail(location.state.email);
                setRender(<ChangePw loading={loading} updatePassword={updatePassword} />);
            } else {
                setRender(<SendPwMail loading={loading} email={email} setEmail={setEmail} sendEmail={sendEmail} />);
            }
        }
    }, [email, loading, location, sendEmail, updatePassword]);
    /**
     * 태그
     */
    return (
        <div
            style={{
                height: "100%",
                position: "absolute",
                width: "100%"
            }}
        >
            {render}
        </div>
    )
}

export default ChangePassword
