/**
 * Import List
 */
import React, { useEffect, useRef, useState } from 'react'
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
/**
 * Version : 1.0
 * 파일명 : addressCategoryReadField.js
 * 작성일자 : 2021-09-30
 * 작성자 : 권도훈
 * 설명 : 주소록 팝업 텍스트 필드
 * 수정일자 : 2022-01-12
 * 수정자 : 권도훈
 * 수정내역 : 연락처 구조 변경
*/
const useStyles = makeStyles((theme) => ({
    field: {
        minHeight: "2.2rem",
        fontSize: "12px",
        fontWeight: "bold",
        width: "538px",
        whiteSpace: "nowrap",
        float: "left",
        '& input': {
            display: "none",
        },
        '&> div': {
            cursor: "pointer",
            minHeight: "2.2rem",
            paddingRight: "14px"
        },
        '& fieldset': {
            border: "none"
        }
    },
    button: {
        float: "left",
        width: "1.5rem",
        cursor: "pointer"
    }
}));

function AddressCategoryReadField(props) {
    const classes = useStyles();
    const category = props.selectedCategory;

    /**
     * 좌우스크롤상태
     */
    const interval = useRef(null);
    const [isPressScrollBtn, setIsPressScrollBtn] = useState(false);
    const [scrollWay, setScrollSWay] = useState();
    const [scrollTarget, setScrollTarget] = useState();

    useEffect(() => {
        if (isPressScrollBtn) {
            const element = document.getElementById(scrollTarget);

            interval.current = setInterval(() => {
                if (scrollWay === "left") {
                    element.scrollLeft -= 3;
                } else if (scrollWay === "right") {
                    element.scrollLeft += 3;
                }
            }, 1);
        } else {
            clearTimeout(interval.current);
        }
    }, [isPressScrollBtn, scrollTarget, scrollWay]);
    /**
   * 좌우스크롤버튼이벤트
   */
    const moveLeftScroll = (e) => {
        e.stopPropagation();
        setScrollSWay("left");
        setScrollTarget("scrollItemGroup");
        setIsPressScrollBtn(true);
    };

    const moveRightScroll = (e) => {
        e.stopPropagation();
        setScrollSWay("right");
        setScrollTarget("scrollItemGroup");
        setIsPressScrollBtn(true);
    };

    const stopScroll = (e) => {
        e.stopPropagation();
        setIsPressScrollBtn(false);
    }

    return (
        <div name="address_category">
            <TextField
                className={classes.field}
                label={props.label}
                type="text"
                autoComplete="current-password"
                variant="outlined"
                size="small"
                value=""
                fontSize="12px"
                fullWidth
                InputProps={{
                    startAdornment: (
                        <>
                            <img
                                alt="left_arrow"
                                src="/img/left_arrow.png"
                                className={classes.button}
                                onMouseDown={e => moveLeftScroll(e)}
                                onMouseLeave={e => stopScroll(e)}
                                onMouseUp={e => stopScroll(e)}
                            />

                            <div id="scrollItemGroup" className="address_CategorySlider"
                            // onClick={() => props.categoryPopupView("scrollItemGroup")}
                            >
                                {category.map((data, index) => (
                                    <div
                                        // onClick={() => props.categoryPopupView("scrollItemGroup")}
                                        key={index}
                                        className="scrollItem"
                                        data-type-num={data.type_cd}
                                        style={{
                                            background: props.colorConverter(data.type_bgc)
                                        }}
                                    >
                                        <p className="inline">{data.type_nm}</p>
                                    </div>
                                ))}
                            </div>
                        </>
                    ),
                    endAdornment: (
                        < img
                            alt="right_arrow"
                            src="/img/right_arrow.png"
                            className={classes.button}
                            onMouseDown={e => moveRightScroll(e)}
                            onMouseLeave={e => stopScroll(e)}
                            onMouseUp={e => stopScroll(e)}
                        />
                    )
                }}
            />
        </div >
    )
}

export default AddressCategoryReadField