import React from 'react'
import { useHistory } from 'react-router-dom'
/**
 * Version : 1.0
 * 파일명 : UpdateRecordAlram.js
 * 작성일자 : 2021-12-16
 * 작성자 : 권도훈
 * 설명 : 녹화파일 생성 알람
 * 수정일자 : 2022-02-03
 * 수정자 : 권도훈
 * 수정내역 : history push로 변경
*/
function UpdateRecordAlram(props) {
    /**
     * history
     */
    const history = useHistory();
    /**
     * 내용 포맷
     */
    const contentConvert = (data) => {
        return (
            <div>
                <div>NOW!</div>
                <div>[ {data} ] 녹화파일이 생성되었습니다.</div>
            </div>
        )
    }
    return (
        <div
            className="alarm_Item cursor"
            key={props.data.ALRAM_NUM}
            onClick={() => {
                history.push({
                    pathname: "/app/home/record"
                });
                // window.location.href = "/app/home/record";
            }}
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: "24px",
                    color: props.data.READ_YN === "Y" ? "grey" : "blue"
                }}
            >
                <div className="font-bold">
                    회의영상파일 생성
                </div>

                <div
                    style={{
                        fontSize: "14px"
                    }}
                >
                    {props.date}
                </div>
            </div>

            <div
                style={{
                    marginBottom: "24px",
                    marginTop: "24px",
                    color: props.data.READ_YN === "Y" ? "grey" : "black",
                }}
            >
                {
                    contentConvert(props.data.ALRAM_CONTENT)
                }
            </div>
        </div>
    )
}

export default UpdateRecordAlram
