import AvailableDay from "component/tag/div/my/home/availableDay";
import UnavailableDay from "component/tag/div/my/home/unavailableDay";
import React, { useState } from "react";
import ReactModal from "react-modal";
import { updateFreeLicenseInfo } from "actions/index";
import { useDispatch } from "react-redux";
import axios from "axios";
import { useHistory } from "react-router-dom";
/**
 * Version : 1.0
 * 파일명 : freeAccountInfoPopup.js
 * 작성일자 : 2021-11-30
 * 작성자 : 권도훈
 * 설명 : 무료 이용 기간 안내 팝업
 * 수정일자 : 2022-03-07
 * 수정자 : 권도훈
 * 수정내역 : 비밀번호 검사 추가
*/
function FreeAccountInfoPopup(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const [isPopup, setIsPopup] = useState(true);
    /**
     * body
     */
    ReactModal.setAppElement("body");
    /**
     * 남은기간
     */
    const dayCount = 30 - props.freeInfo.FREE_DAY;
    /**
     * 팝업 닫기
     */
    const closePopup = () => {
        const config = {
            DISPLAY: false
        }
        dispatch(updateFreeLicenseInfo(config));
        setIsPopup(false);
        props.requireChangePassword();
    }
    const updateAvailableInfo = () => {
        const obj = {
            params: {
                TRIAL_POPUP: "Y",
                EXPIRED_POPUP: "N",
            }
        }

        axios.put("/user/readFreePopup", null, obj);
    }
    const updateUnavailableInfo = () => {
        const obj = {
            params: {
                TRIAL_POPUP: "Y",
                EXPIRED_POPUP: "Y",
            }
        }

        axios.put("/user/readFreePopup", null, obj);
    }

    /**
     * 상품팝업 이동
     */
    const goProduct = () => {
        history.push("/app/home/profile", {
            showPopup: true,
        });
    };
    /**
     * 태그
     */
    return (
        <ReactModal
            isOpen={isPopup} //모달 Open(Boolean)
            className={
                props.freeInfo.TRIAL_POPUP === "N" && props.freeInfo.FREE_DAY !== "30"
                    ?
                    props.freeInfo.FREE_DAY !== ""
                        ?
                        "availableDay"
                        :
                        "unavailableDayAlert"
                    :
                    "unavailableDayAlert"
            }//모달 ClassName
            overlayClassName="AlertOverlay" //모달오버레이 ClassName
            animationType="fade"
            transparent={true}
        >
            <div>
                <div className="freeInfo_closeBtn">
                    <img
                        className="cursor"
                        src="/img/icon/landing/close.png"
                        alt="X"
                        onClick={() => {
                            if (props.freeInfo.TRIAL_POPUP === "N" && props.freeInfo.FREE_DAY !== "30") {
                                if (props.freeInfo.FREE_DAY !== "") {
                                    updateAvailableInfo();
                                } else {
                                    updateUnavailableInfo();
                                }
                            } else {
                                updateUnavailableInfo();
                            }
                            closePopup();
                        }}
                    />
                </div>
                {
                    props.freeInfo.TRIAL_POPUP === "N" && props.freeInfo.FREE_DAY !== "30"
                        ?
                        props.freeInfo.FREE_DAY !== ""
                            ?
                            <AvailableDay dayCount={dayCount} closePopup={closePopup} goProduct={goProduct} updateInfo={updateAvailableInfo} />
                            :
                            <UnavailableDay closePopup={closePopup} goProduct={goProduct} updateInfo={updateUnavailableInfo} />
                        :
                        <UnavailableDay closePopup={closePopup} goProduct={goProduct} updateInfo={updateUnavailableInfo} />
                }
            </div>
        </ReactModal>
    )
}

export default FreeAccountInfoPopup
