import React, { useEffect, useState } from "react";
import ProfilePayContent from "component/tag/div/my/profile/profilePayContent";
import ProfileUserInfo from "component/tag/div/my/profile/profileUserInfo";
import ProfileLicenseContent from "component/tag/div/my/profile/profileLicenseContent";
import { useDispatch } from "react-redux";
import { updateProfileInfo, updateGroupInfo } from "actions/index";
import { useTranslation } from "react-i18next";
import ProfileSupport from "component/tag/div/my/profile/profileSupport";
import ProfileSupportdetail from "component/tag/div/my/profile/profileSupportdetail";
/**
 * Version : 1.0
 * 파일명 : profile.js
 * 작성일자 : 2021-11-25
 * 작성자 : 권도훈
 * 설명 : 프로필 기본 양식
 * 수정일자 : 2022-06-13
 * 수정자 : 강연승
 * 수정내역 : sobridge 데모 - 결제내역 주석처리
 */
function Profile() {
  /**
   * TRANSLATION
   */
  const { t } = useTranslation();
  /**
   * REDUX
   */
  const dispatch = useDispatch();
  /**
   * 뷰 타입
   */
  const [viewType, setViewType] = useState("LICENSE");
  /**
   * 기본조회
   */

  const [godetail, setgodetail] = useState("");

  useEffect(() => {
    dispatch(updateProfileInfo());
    const config = {
      GROUP_NUM: "",
      GROUP_NM: "",
      GROUP_COLOR: "",
      AUTH_CD: "",
      GROUP_CATEGORY: "",
      GROUP_IMG: "",
      GROUP_YN: "N",
      REC_YN: "",
      DOWNLOAD_YN: "",
      PLAY_YN: "",
      HOST_CNT: "0",
      LICENSE_CNT: "0",
      D_DAY: "",
      CREATE_DT: "",
    };
    dispatch(updateGroupInfo(config));
  }, [dispatch]);
  /**
   * 태그
   */

  return (
    <div className="profile">
      <div className="profile_content">
        <div className="profile_content_header">
          <img
            src={"/img/icon/profile/profile.png"}
            alt="profile"
            style={{ marginRight: "16px" }}
          />
          {viewType === "SUPPORT" ? "고객센터" : t("my.profile.txt.title")}
        </div>

        <div>
          {viewType === "SUPPORT" ? (
            <div className="porfile_support_body">
              <ProfileSupportdetail
                details={godetail}
                setgodetail={setgodetail}
              />
            </div>
          ) : (
            <div className="profile_content_body">
              <div className="profile_profile_content_child">
                {viewType === "LICENSE" ? (
                  <ProfileLicenseContent />
                ) : // <ProfilePayContent />
                null}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="profile_sidebar">
        <div className="profile_sidebar_header">
          {t("my.profile.txt.sideTitle")}
        </div>

        <ProfileUserInfo
          setViewType={setViewType}
          viewType={viewType}
          setgodetail={setgodetail}
          godetail={godetail}
        />
      </div>
    </div>
  );
}

export default Profile;
