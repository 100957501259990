/**
 * Version : 1.0
 * 작성일자 : 2021-12-23
 * 작성자 : 권도훈
 * 설명 : 알람정보
*/
const initalState = {
    display: false,
    count: "0"
}

const alramInfo = (state = initalState, action) => {
    switch (action.type) {
        case "alramInfo/COUNT_UPDATE":
            return {
                ...state,
                count: action.count
            };
        case "alramInfo/POPUP_OPEN":
            return {
                count: "0",
                display: true
            };
        case "alramInfo/POPUP_CLOSE":
            return {
                ...state,
                display: false
            }
        default:
            return state;
    }
}

export default alramInfo;