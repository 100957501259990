import React from 'react'
import ReactModal from "react-modal";
/**
 * Version : 1.0
 * 파일명 : LicenseUnsubscribeResultPopup.js
 * 작성일자 : 2022-04-25
 * 작성자 : 권도훈
 * 설명 : 구독해지 결과 팝업
 */
function LicenseUnsubscribeResultPopup(props) {
    //갱신
    const refreshLicenseList = () => {
        props.setIsShow(false);
        props.getLicenseTotal();
        props.getLicenseData();
        props.setShowLicenseSettingPopup(false);
    }
    //만료일 포맷
    const expireDateFormat = (date) => {
        const dateArr = date.split("-");
        return dateArr[0] + "년 " + dateArr[1] + "월 " + dateArr[2] + "일";
    }

    ReactModal.setAppElement("body");
    return (
        <ReactModal
            isOpen={props.isShow}
            className="py-unsubscribe-result-popup"
            overlayClassName="NewAlertOverlay"
            animationType="fade"
            transparent={true}
            closeTimeoutMS={200}
            style={{
                overlay: {
                    zIndex: "999"
                }
            }}
        >
            <div className='py-unsubscribe-result-popup-inner'>
                <div className='py-unsubscribe-result-title'>
                    구독 해지 완료
                </div>

                <img
                    src="/img/icon/payment/unsubscription_new.png"
                    alt=''
                />

                <div className='py-unsubscribe-result-comment'>
                    <div>
                        <font className='py-unsubscribe-result-expire'>
                            {expireDateFormat(props.expireDT)}
                        </font>
                        까지 이용 가능합니다.
                    </div>

                    <div>
                        이후 해당 이용권은 이용 및 결제가 중지됩니다.
                    </div>
                </div>

                <div
                    className='py-unsubscribe-result-btn cursor'
                    onClick={() => refreshLicenseList()}
                >
                    확인
                </div>
            </div>
        </ReactModal>
    )
}

export default LicenseUnsubscribeResultPopup
