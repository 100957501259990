import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Loading from "component/tag/loading/loading";
/**
 * Version : 1.0
 * 파일명 : AccCntArea.js
 * 작성일자 : 2022-02-23
 * 작성자 : 강연승
 * 설명 : 대시보드 아이템 - 누적 절감 금액 및 회의 시간 총계
 * 수정일자 : 2022-06-08
 * 수정자 : 강연승
 * 수정내역 : 시간 단위 "n시간 n분"으로 조정
 */
function AccCntArea() {
  /**
   * REDUCER
   */
  const USER_STATE = useSelector(state => state.userInfoReducer);
  const REDUCER_STATE = useSelector(state => state.groupInfoReducer);
  /**
   * 언어변환
   */
  const { t } = useTranslation();
  /**
   * STATE
   */
  const [loading, setLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [resultCntA, setResultCntA] = useState(0);
  const [resultCntB, setResultCntB] = useState(0);
  const [isShowTooltip, setIsShowTooltip] = useState(false);
  const [tooltipType, setTooltipType] = useState("");
  /**
   * 그룹 카테고리 코드 변환
   */
  const groupCategoryConvert = ctgryCD => {
    switch (ctgryCD) {
      case "CD0401":
        return "company";
      case "CD0402":
        return "company";
      case "CD0403":
        return "school";
      case "CD0404":
        return "private_institute";
      case "CD0405":
        return "university";
      case "CD0406":
        return "social";
      default:
        return "default";
    }
  };
  /**
   * 총 예약 시간 조회
   */
  const getAccCount = useCallback(() => {
    setLoading(true);
    let param = {
      GROUP_CATEGORY: groupCategoryConvert(REDUCER_STATE.GROUP_CATEGORY),
    };
    if (REDUCER_STATE.GROUP_YN === "Y") {
      param = {
        ...param,
        GROUP_YN: REDUCER_STATE.GROUP_YN,
        GROUP_NUM: REDUCER_STATE.GROUP_NUM,
      };
      if (activeIndex === 1) {
        param = {
          ...param,
          TERM_TYPE: "YEAR",
        };
      } else if (activeIndex === 2) {
        param = {
          ...param,
          TERM_TYPE: "MONTH",
        };
      }
    } else if (REDUCER_STATE.GROUP_YN === "N") {
      if (activeIndex === 1) {
        param = {
          ...param,
          TERM_TYPE: "YEAR",
        };
      } else if (activeIndex === 2) {
        param = {
          ...param,
          TERM_TYPE: "MONTH",
        };
      }
    }
    axios
      .get("/schedule/dashboard/accCount", {
        params: param,
      })
      .then(res => {
        if (res.status === 200) {
          setResultCntA(res.data.RESULT_A);
          if (res.data.hasOwnProperty("RESULT_B")) {
            setResultCntB(res.data.RESULT_B);
          }
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [
    REDUCER_STATE.GROUP_NUM,
    REDUCER_STATE.GROUP_YN,
    REDUCER_STATE.GROUP_CATEGORY,
    activeIndex,
  ]);
  /**
   * useEffect
   */
  useEffect(() => {
    getAccCount();
  }, [getAccCount]);
  /**
   * 테마별 누적 절감 금액 및 회의 시간 총계 viewType
   */
  const accViewType = code => {
    switch (code) {
      case "CD0401":
        return (
          <div className="accValue">
            <div className="currency">₩</div>
            <div className="accCost">{resultCntA.toLocaleString()}</div>
          </div>
        );
      case "CD0402":
        return (
          <div className="accValue">
            <div className="currency">₩</div>
            <div className="accCost">{resultCntA.toLocaleString()}</div>
          </div>
        );
      case "CD0403":
        return (
          <div className="accValue">
            <div className="accTime">{Math.floor(resultCntA / 60)}</div>
            <div className="time">시간</div>
            <div className="accMinute">{resultCntA % 60}</div>
            <div className="time">분</div>
          </div>
        );
      case "CD0404":
        return (
          <div className="accValue">
            <div className="accTime">{Math.floor(resultCntA / 60)}</div>
            <div className="time">시간</div>
            <div className="accMinute">{resultCntA % 60}</div>
            <div className="time">분</div>
          </div>
        );
      case "CD0405":
        return (
          <div className="accValue">
            <div className="accTime">{Math.floor(resultCntA / 60)}</div>
            <div className="time">시간</div>
            <div className="accMinute">{resultCntA % 60}</div>
            <div className="time">분</div>
          </div>
        );
      case "CD0406":
        return (
          <div className="accValue">
            <div className="accTime">{resultCntA}</div>
            <div className="time">모임</div>
          </div>
        );
      default:
        return (
          <div className="myAcc">
            <div className="res">{resultCntA}</div>
            <div className="borderLine" />
            <div className="parti">{resultCntB}</div>
          </div>
        );
    }
  };

  const titleForamt = () => {
    let date = new Date();
    let year = date.getFullYear();
    let month =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    let prevMonth =
      month === "01"
        ? "12"
        : date.getMonth() < 10
        ? "0" + date.getMonth()
        : date.getMonth();
    switch (activeIndex) {
      case 0:
        if (REDUCER_STATE.GROUP_YN === "Y") {
          return (
            " (" +
            REDUCER_STATE.CREATE_DT.split("-")[0] +
            "." +
            REDUCER_STATE.CREATE_DT.split("-")[1] +
            " ~ " +
            year +
            "." +
            month +
            ")"
          );
        } else if (REDUCER_STATE.GROUP_YN === "N") {
          return (
            " (" +
            USER_STATE.SIGNUP_DT.split("-")[0] +
            "." +
            USER_STATE.SIGNUP_DT.split("-")[1] +
            " ~ " +
            year +
            "." +
            month +
            ")"
          );
        }
      case 1:
        return " (" + year + ".01 ~ " + year + "." + month + ")";
      case 2:
        if (month === "01") {
          return " (" + (year - 1) + "." + prevMonth + ")";
        } else {
          return " (" + year + "." + prevMonth + ")";
        }
    }
  };
  /**
   * 툴팁 설정
   */
  const onMouseTooltip = (overYN, type) => {
    setTooltipType(type);
    if (overYN === "over") {
      setIsShowTooltip(true);
    } else if (overYN === "out") {
      setIsShowTooltip(false);
    }
  };
  return (
    <div
      className="dashboardAccCntArea"
      style={{ height: REDUCER_STATE.GROUP_YN === "Y" ? "214px" : "252px" }}
    >
      <div>
        <img
          src={
            "/img/icon/dashboard/" +
            groupCategoryConvert(REDUCER_STATE.GROUP_CATEGORY) +
            ".png"
          }
          className="dashboardIcon"
        />
        <font className="title">
          {REDUCER_STATE.GROUP_YN === "N"
            ? t(
                "group.dashboard.txt.acc.title." + REDUCER_STATE.GROUP_CATEGORY
              ) + titleForamt()
            : t(
                "group.dashboard.txt.acc.title." + REDUCER_STATE.GROUP_CATEGORY
              ) + titleForamt()}
        </font>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop:
              REDUCER_STATE.GROUP_CATEGORY === undefined ? "21px" : "0",
          }}
        >
          {accViewType(REDUCER_STATE.GROUP_CATEGORY)}
          <div className="buttonArea">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
              }}
            >
              <div
                className="tooltipUp"
                style={{
                  display:
                    isShowTooltip && tooltipType === "UP" && activeIndex !== 0
                      ? "block"
                      : "none",
                }}
              >
                <div
                  style={{
                    font: "normal normal bold 12px/18px Spoqa Han Sans Neo",
                    color: "#FFFFFF",
                  }}
                >
                  <div>
                    {activeIndex === 0
                      ? " "
                      : t("group.dashboard.txt.acc.btn." + (activeIndex - 1))}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="button"
              style={{ cursor: activeIndex === 0 ? "default" : "pointer" }}
              onMouseOver={() => {
                if (activeIndex !== 0) {
                  onMouseTooltip("over", "UP");
                }
              }}
              onMouseOut={() => {
                if (activeIndex !== 0) {
                  onMouseTooltip("out", "UP");
                }
              }}
              onClick={() => {
                if (activeIndex === 0) {
                  return;
                } else {
                  setActiveIndex(activeIndex - 1);
                }
              }}
            >
              <img
                className="arrowIcon"
                src="/img/icon/dashboard/arrowUp.png"
                alt="up"
              />
            </div>
            <div className="buttonMenu">
              {t("group.dashboard.txt.acc.btn." + activeIndex)}
            </div>
            <div
              className="button"
              style={{ cursor: activeIndex === 2 ? "default" : "pointer" }}
              onMouseOver={() => {
                if (activeIndex !== 2) {
                  onMouseTooltip("over", "DOWN");
                }
              }}
              onMouseOut={() => {
                if (activeIndex !== 2) {
                  onMouseTooltip("out", "DOWN");
                }
              }}
              onClick={() => {
                if (activeIndex === 2) {
                  return;
                } else {
                  setActiveIndex(activeIndex + 1);
                }
              }}
            >
              <img
                className="arrowIcon"
                src="/img/icon/dashboard/arrowDown.png"
                alt="down"
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <div
                className="tooltipDown"
                style={{
                  display:
                    isShowTooltip && tooltipType === "DOWN" && activeIndex !== 2
                      ? "block"
                      : "none",
                }}
              >
                <div
                  style={{
                    font: "normal normal bold 12px/18px Spoqa Han Sans Neo",
                    color: "#FFFFFF",
                  }}
                >
                  <div>
                    {activeIndex === 2
                      ? " "
                      : t("group.dashboard.txt.acc.btn." + (activeIndex + 1))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AccCntArea;
