import React, { useState, useEffect, useCallback } from "react";
import UseLogItem from "../uselog_item";
import axios from "axios";
import { useInView } from "react-intersection-observer";
import LogChangeBtn from "component/tag/btn/common/logChangeBtn";
import ScheduleDetail from "component/page/my/schedule/scheduleDetail";
import { useDispatch, useSelector } from "react-redux";
import { updateDateTimeInfo } from "actions/index";
import Loading from "component/tag/loading/loading";
/**
 * Version : 1.0
 * 파일명 :
 * 작성일자 : 2021-10-05
 * 작성자 : 권도훈
 * 설명 :
 * 수정일자 : 2022-05-02
 * 수정자 : 강연승
 * 수정내역 : 그룹 컬러 프롭스 추가
 * props :
 * 전달받은값 - 설명
 */
function Uselog(props) {
  /**
   * 글로벌 스테이트
   */
  const groupInfoState = useSelector(state => state.groupInfoReducer);
  const [scheduleLogsCnt, setScheduleLogsCnt] = useState(0);
  const [scheduleLogs, setScheduleLogs] = useState([]);
  const [historyDates, setHistroyDates] = useState([]);
  const [scheduleInfo, setScheduleInfo] = useState({});

  const [ref, inView] = useInView();
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const getHistoryDates = history => {
    let tmp = [];
    for (let i = 0; i < history.length; i++) {
      const dateStr = history[i].END_TIME.replace(/\T.*/, "");
      tmp.push(dateStr);
    }
    const set = new Set(tmp);
    setHistroyDates([...set]);
  };

  const sliceHistory = (date, history) => {
    let tmpArr = [];
    for (let i = 0; i < history.length; i++) {
      if (date === history[i].END_TIME.replace(/\T.*/, "")) {
        tmpArr.push(history[i]);
      }
    }
    return tmpArr;
  };

  const getUseLogData = useCallback(async () => {
    setLoading(true);
    let url = "";
    let params = {
      HISTORY: "Y",
      CURRENTPAGE: page,
      RECORDPERPAGE: 10,
    };
    if (groupInfoState.GROUP_YN === "N" && groupInfoState.GROUP_NUM === "") {
      url = "/schedule/selectMySchedule";
    } else if (
      groupInfoState.GROUP_YN === "Y" &&
      groupInfoState.GROUP_NUM === ""
    ) {
      url = "/schedule/selectGroupSchedule";
    } else if (
      groupInfoState.GROUP_YN === "Y" &&
      groupInfoState.GROUP_NUM !== ""
    ) {
      url = "/schedule/selectGroupSchedule";
      params = {
        ...params,
        GROUP_NUM: groupInfoState.GROUP_NUM,
      };
    } else if (
      groupInfoState.GROUP_YN === "" &&
      groupInfoState.GROUP_NUM === ""
    ) {
      if (props.groupYN === "N") {
        url = "/schedule/selectMySchedule";
      } else if (props.groupYN === "Y") {
        url = "/schedule/selectGroupSchedule";
        params = {
          ...params,
          GROUP_NUM: props.groupNum,
        };
      }
    }
    await axios
      .get(url, {
        params: params,
      })
      .then(({ data }) => {
        const totalCnt = data[0].TOTAL;
        const resultData = data[0].SCHEDULE;
        const mergeData = scheduleLogs.concat(...resultData);
        setScheduleLogsCnt(totalCnt);
        setScheduleLogs(mergeData);
        getHistoryDates(mergeData);
      })
      .catch(error => {
        console.log(error);
      });
    setLoading(false);
  }, [page, groupInfoState.GROUP_YN, groupInfoState.GROUP_NUM]);

  useEffect(() => {
    getUseLogData();
  }, [getUseLogData]);

  useEffect(() => {
    if (scheduleLogs.length !== scheduleLogsCnt) {
      // 사용자가 마지막 요소를 보고 있고, 로딩 중이 아니라면
      if (inView && !loading) {
        setPage(prevState => prevState + 10);
      } else {
        return;
      }
    }
  }, [inView, loading]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [scheduleParticipants, setScheduleParticipants] = useState([]);

  const handleDialogOpen = (data, duration) => {
    if (
      data.FILE_INFO !== null &&
      data.FILE_INFO !== undefined &&
      data.FILE_INFO !== ""
    ) {
      const scheduleFileList = data.FILE_INFO.split("|");
      let rawfileInfo = [];
      let fileInfoList = [];
      for (let i = 0; i < scheduleFileList.length; i++) {
        rawfileInfo = scheduleFileList[i].split(";");
        const fileInfo = {
          fileNum: rawfileInfo[0],
          fileNm: rawfileInfo[1],
          fileURL: rawfileInfo[2],
          fileDownNm: rawfileInfo[3],
          fileSize: rawfileInfo[4],
        };
        fileInfoList.push(fileInfo);
      }
      setScheduleInfo({
        id: data.SCHEDULE_NUM,
        title: data.TITLE,
        startTime: data.START_TIME,
        endTime: data.END_TIME,
        duration: duration,
        content: data.CONTENTS,
        apiType: data.API_TYPE,
        inviteURL: data.INVITE_URL,
        hostKey: data.HOST_KEY,
        hostYN: data.HOST_YN,
        groupYN: data.GROUP_YN,
        groupNum: data.GROUP_NUM,
        groupNm: data.GROUP_NM,
        groupColor: data.GROUP_COLOR,
        fileInfoList: fileInfoList,
        recYN: data.REC_YN,
        hostNm: data.HOST_NM,
        hostId: data.HOST_ID,
        meetingPW: data.MEETING_PW,
        sipUrl: data.SIP_URL,
        meetingLink: data.MEETING_LINK,
      });
    } else {
      setScheduleInfo({
        id: data.SCHEDULE_NUM,
        title: data.TITLE,
        startTime: data.START_TIME,
        endTime: data.END_TIME,
        duration: duration,
        content: data.CONTENTS,
        apiType: data.API_TYPE,
        inviteURL: data.INVITE_URL,
        hostKey: data.HOST_KEY,
        hostYN: data.HOST_YN,
        groupYN: data.GROUP_YN,
        groupNum: data.GROUP_NUM,
        groupNm: data.GROUP_NM,
        groupColor: data.GROUP_COLOR,
        recYN: data.REC_YN,
        hostNm: data.HOST_NM,
        hostId: data.HOST_ID,
        meetingPW: data.MEETING_PW,
        sipUrl: data.SIP_URL,
        meetingLink: data.MEETING_LINK,
      });
    }
    axios
      .get("/schedule/selectScheduleParticipants", {
        params: {
          schedule_num: data.SCHEDULE_NUM,
        },
      })
      .then(({ data }) => setScheduleParticipants(data))
      .catch(error => {
        console.log(error);
      });
    setDialogOpen(true);
    const config = {
      INPUT_TYPE: "sidebar",
      START_DATE: "",
      END_DATE: "",
      START_TIME: "",
      END_TIME: "",
    };
    dispatch(updateDateTimeInfo(config));
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const dateFormat = date => {
    let mon =
      new Date(date).getMonth() + 1 < 10
        ? "0" + (new Date(date).getMonth() + 1)
        : new Date(date).getMonth() + 1;
    let dt = new Date(date).getDate();
    let e = new Date(date).getDay();
    let e_ko;
    if (e === 0) {
      e_ko = "일요일";
    } else if (e === 1) {
      e_ko = "월요일";
    } else if (e === 2) {
      e_ko = "화요일";
    } else if (e === 3) {
      e_ko = "수요일";
    } else if (e === 4) {
      e_ko = "목요일";
    } else if (e === 5) {
      e_ko = "금요일";
    } else if (e === 6) {
      e_ko = "토요일";
    }
    return mon + "월 " + dt + "일 " + e_ko;
  };

  const handleResSchedule = (title, content, participants, apiType) => {
    props.setMeetingInfo({
      title: title,
      participant: participants,
      content: content,
      apiType: apiType,
    });
    setScheduleParticipants(participants);
    // props.calendarApi.changeView("dayGridMonth", new Date());
    props.setIsInput(true);
    props.setResSidebarType("res");
  };

  useEffect(() => {
    setScheduleLogsCnt(0);
    setScheduleLogs([]);
    setHistroyDates([]);
    getUseLogData();
  }, [props.dataState]);

  return (
    <>
      <div className="sidebar_schedule">
        <div className="sidebar_header">
          <div className="sidebar_title sidebar_title_history">
            {groupInfoState.GROUP_YN === "Y" ? "그룹 히스토리" : "히스토리"}
          </div>
          <LogChangeBtn
            onClick={() => props.setIsToggle(props.isToggle ? false : true)}
          />
        </div>

        <div
          className="sidebar_scheduleList"
          style={{
            height: props.height,
          }}
        >
          {/* {scheduleLogs.map((data, index) => (
            <div className="uselogGroup" key={data.SCHEDULE_NUM}>
              {scheduleLogs.length - 1 == index ? (
                <div ref={ref}>{checkDate(data, index)}</div>
              ) : (
                <div>{checkDate(data, index)}</div>
              )}
            </div>
          ))} */}
          {historyDates.map((data, index) => (
            <div className="historyGroup" key={data}>
              {historyDates.length - 1 === index ? (
                <div ref={ref}>
                  <HistoryDate
                    dateStr={data}
                    dateForm={dateFormat(data)}
                    history={sliceHistory(data, scheduleLogs)}
                    handleDialogOpen={handleDialogOpen}
                  />
                </div>
              ) : (
                <div>
                  <HistoryDate
                    dateStr={data}
                    dateForm={dateFormat(data)}
                    history={sliceHistory(data, scheduleLogs)}
                    handleDialogOpen={handleDialogOpen}
                  />
                </div>
              )}
            </div>
          ))}
          {loading ? (
            <div>
              <Loading />
            </div>
          ) : null}
        </div>
      </div>
      <ScheduleDetail
        detailType="history"
        setLoading={props.setLoading}
        scheduleInfo={scheduleInfo}
        scheduleParticipants={scheduleParticipants}
        datastate={props.dataState}
        handleDataState={props.handleDataState}
        handleResSchedule={handleResSchedule}
        open={dialogOpen}
        close={handleDialogClose}
      />
    </>
  );
}

function HistoryDate(props) {
  const todayStr = new Date().toISOString().replace(/\T.*/, "");

  return (
    <>
      <div
        style={{
          color: "#3E3E4F",
          margin: "16px 32px",
          font: "normal normal bold 14px/18px Spoqa Han Sans Neo",
        }}
      >
        {props.dateStr === todayStr ? (
          <div style={{ display: "inline" }}>| 오늘 </div>
        ) : (
          <div
            style={{
              display: "inline",
            }}
          >
            | {props.dateForm}
          </div>
        )}
        <div
          style={{
            display: "inline",
            width: "18px",
            height: "18px",
            borderRadius: "4px",
            backgroundColor: "#3E3E4F",
            color: "#FFFFFF",
            marginLeft: "8px",
            padding: "0 5px",
          }}
        >
          {props.history.length}
        </div>
      </div>

      {props.history.map(data => (
        <UseLogItem
          key={data.SCHEDULE_NUM}
          data={data}
          onClick={props.handleDialogOpen}
        />
      ))}
    </>
  );
}

export default Uselog;
