import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ParticipantSidebar from "./participantSidebar";
import AddressPopup from "component/tag/popup/common/addressPopup";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import Loading from "component/tag/loading/loading";
import { useTranslation } from "react-i18next";
import { openAlert, closeAlert } from "actions/index";
import FailReservationPopup from "component/tag/popup/schedule/failReservationPopup";
/**
 * Version : 1.0
 * 파일명 : meetingNow.js
 * 작성일자 : 2021-10-06
 * 작성자 : 강연승
 * 설명 : 즉시 미팅 화면
 * 수정일자 : 2022-09-02
 * 수정자 : 강연승
 * 수정내역 : UI 변경
 */
function MeetingNow() {
  /**
   * 언어변환
   */
  const { t } = useTranslation();
  /**
   * DISPATCH
   */
  const dispatch = useDispatch();
  const userState = useSelector(state => state.userInfoReducer);
  /**
   * url 설정
   */
  let server_url = "";

  if (process.env.NODE_ENV === "development") {
    server_url =
      process.env.REACT_APP_SERVER_URL + process.env.REACT_APP_SERVER_PORT;
  } else {
    server_url = process.env.REACT_APP_SERVER_URL;
  }

  const [loading, setLoading] = useState(false);
  const [inviteURL, setInviteURL] = useState("링크를 불러오는 중 입니다.");
  const [scheduleInfo, setScheduleInfo] = useState({});

  /**
   * 글로벌 스테이트
   */
  const groupInfoState = useSelector(state => state.groupInfoReducer);
  const userInfoState = useSelector(state => state.userInfoReducer);
  const licenseCntState = useSelector(state => state.licenseCntInfoReducer);

  const [recordYN, setRecordYN] = useState(
    groupInfoState.REC_YN === "Y" ? "Y" : "N"
  );

  const meetingHour = [
    { num: 0.5, time: "30분", value: 0.5 },
    { num: 1, time: "1시간", value: 1 },
    { num: 1.5, time: "1시간 30분", value: 1.5 },
    { num: 2, time: "2시간", value: 2 },
    { num: 2.5, time: "2시간 30분", value: 2.5 },
    { num: 3, time: "3시간", value: 3 },
    { num: 3.5, time: "3시간 30분", value: 3.5 },
    { num: 4, time: "4시간", vvalue: 4 },
    { num: 4.5, time: "4시간 30분", value: 4.5 },
    { num: 5, time: "5시간", value: 5 },
    { num: 5.5, time: "5시간 30분", value: 5.5 },
    { num: 6, time: "6시간", value: 6 },
    { num: 6.5, time: "6시간 30분", value: 6.5 },
    { num: 7, time: "7시간", value: 7 },
    { num: 7.5, time: "7시간 30분", value: 7.5 },
    { num: 8, time: "8시간", value: 8 },
    { num: 8.5, time: "8시간 30분", value: 8.5 },
    { num: 9, time: "9시간", value: 9 },
    { num: 9.5, time: "9시간 30분", value: 9.5 },
    { num: 10, time: "10시간", value: 10 },
  ];
  const [showDuration, setShowDuration] = useState(false);
  const [duration, setDuration] = useState(meetingHour[0].value);
  const [inputs, setInputs] = useState({ PRTCP_NM: "", PRTCP_EMAIL: "" });
  const [participants, setParticipants] = useState([]);
  const { PRTCP_NM, PRTCP_EMAIL } = inputs;
  const onChange = e => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const validationEmail = value => {
    var emailType =
      /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
    return emailType.test(value);
  };

  const handleKeyPress = ({ key }) => {
    if (key === "Enter") {
      onCreate();
    }
  };

  const onCreate = () => {
    if (PRTCP_EMAIL === "" || validationEmail(PRTCP_EMAIL) === false) {
      const obj = {
        TEXT: t("schedule.msg.errorEmailFormat"),
        submitEventHandler: () => dispatch(closeAlert()),
      };
      dispatch(openAlert(obj));
      return;
    } else {
      if (PRTCP_EMAIL === userState.EMAIL) {
        const obj = {
          TEXT: t("schedule.msg.impossibleSelectMyself"),
          submitEventHandler: () => dispatch(closeAlert()),
        };
        dispatch(openAlert(obj));
        return;
      }
      for (let i = 0; i < participants.length; i++) {
        if (PRTCP_EMAIL === participants[i].PRTCP_EMAIL) {
          const obj = {
            TEXT: t("schedule.msg.alreadyScheduleParticipant"),
            submitEventHandler: () => dispatch(closeAlert()),
          };
          dispatch(openAlert(obj));
          return;
        }
      }
    }
    if (Array.isArray(addressAll) && addressAll.length > 0) {
      for (let i = 0; i < addressAll.length; i++) {
        if (PRTCP_EMAIL === addressAll[i].ADR_EMAIL) {
          const participant = {
            PRTCP_NM: addressAll[i].ADR_NM,
            PRTCP_EMAIL: addressAll[i].ADR_EMAIL,
          };
          setParticipants(participants.concat(participant));
          break;
        } else {
          const participant = {
            PRTCP_NM,
            PRTCP_EMAIL,
          };
          setParticipants(participants.concat(participant));
        }
      }
    } else {
      const participant = {
        PRTCP_NM,
        PRTCP_EMAIL,
      };
      setParticipants(participants.concat(participant));
    }
    setInputs({
      PRTCP_NM: "",
      PRTCP_EMAIL: "",
    });
  };

  const onRemove = PRTCP_EMAIL => {
    setParticipants(
      participants.filter(
        participant => participant.PRTCP_EMAIL !== PRTCP_EMAIL
      )
    );
  };
  const [partiSidebar, setPartiSidebar] = useState(false);
  const [xPosition, setX] = useState(0);
  const handlePartiSidebar = type => {
    if (type === true) {
      setPartiSidebar(true);
      setX(228);
    } else if (type === false) {
      setPartiSidebar(false);
      setX(0);
    }
  };
  const clickAddressItem = (nm, email) => {
    if (email === userState.EMAIL) {
      const obj = {
        TEXT: t("schedule.msg.impossibleSelectMyself"),
        submitEventHandler: () => dispatch(closeAlert()),
      };
      dispatch(openAlert(obj));
      return;
    }
    for (let i = 0; i < participants.length; i++) {
      if (email === participants[i].PRTCP_EMAIL) {
        const obj = {
          TEXT: t("schedule.msg.alreadyScheduleParticipant"),
          submitEventHandler: () => dispatch(closeAlert()),
        };
        dispatch(openAlert(obj));
        return;
      }
    }
    setParticipants(participants.concat({ PRTCP_NM: nm, PRTCP_EMAIL: email }));
  };

  const [addressPopup, setAddressPopup] = useState(false);
  const handleAddress = type => {
    if (type === true) {
      setAddressPopup(true);
      getAddressAll();
    } else if (type === false) {
      setAddressPopup(false);
    }
  };
  const [addressAll, setAddressAll] = useState([]);
  const getAddressAll = () => {
    let url = "";
    let params = {
      params: {},
    };
    if (groupInfoState.GROUP_YN === "Y") {
      url = "/address/GroupAddress";
      params = {
        params: {
          GROUP_NUM: groupInfoState.GROUP_NUM,
        },
      };
    } else if (groupInfoState.GROUP_YN === "N") {
      url = "/address/AddressCreator";
    }
    axios
      .get(url, params)
      .then(res => {
        if (res.status === 200) {
          setAddressAll(res.data);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const clickBackgound = () => {
    if (addressPopup) {
      setAddressPopup(false);
    }
    if (platformSelectBox) {
      setPlatformSelectBox(false);
    }
    if (showDuration) {
      setShowDuration(false);
    }
  };

  const addCheckedAddressList = addressList => {
    let tmpAddress = [];
    for (let i = 0; i < addressList.length; i++) {
      if (addressList[i].ADR_EMAIL === userState.EMAIL) {
        const obj = {
          TEXT: t("schedule.msg.impossibleSelectMyself"),
          submitEventHandler: () => dispatch(closeAlert()),
        };
        dispatch(openAlert(obj));
        return;
      }
      for (let j = 0; j < participants.length; j++) {
        if (addressList[i].ADR_EMAIL === participants[j].PRTCP_EMAIL) {
          const obj = {
            TEXT: t("schedule.msg.alreadyScheduleParticipant"),
            submitEventHandler: () => dispatch(closeAlert()),
          };
          dispatch(openAlert(obj));
          return;
        }
      }
      tmpAddress.push({
        PRTCP_NM: addressList[i].ADR_NM,
        PRTCP_EMAIL: addressList[i].ADR_EMAIL,
      });
    }
    setParticipants(participants.concat(tmpAddress));
  };

  const [active, setActive] = useState(false);

  const openMeeting = () => {
    if (platform === "") {
      const obj = {
        TEXT: "화상회의 플랫폼을 선택해주세요.",
        submitEventHandler: () => dispatch(closeAlert()),
      };
      dispatch(openAlert(obj));
      return;
    }
    setLoading(true);
    let timeNow = new Date(
      new Date() * 1 + 3600000 * parseFloat(userInfoState.OFFSET)
    )
      .toISOString()
      .replace(/\..*/, "");
    let endTime = new Date(
      new Date() * 1 + 3600000 * (parseFloat(userInfoState.OFFSET) + duration)
    )
      .toISOString()
      .replace(/\..*/, "");
    const formData = new FormData();
    let scheduleInfo = {
      TITLE: "즉시미팅",
      CONTENTS: "",
      START_TIME: timeNow,
      END_TIME: endTime,
      API_TYPE: platform,
      GROUP_YN: groupInfoState.GROUP_YN,
    };
    // if (userInfoState.PLATFORM !== "") {
    //   scheduleInfo = {
    //     ...scheduleInfo,
    //     API_TYPE: userInfoState.PLATFORM,
    //   };
    // }

    if (groupInfoState.GROUP_YN === "Y") {
      scheduleInfo = {
        ...scheduleInfo,
        GROUP_NUM: groupInfoState.GROUP_NUM,
        REC_YN: recordYN,
      };
    } else if (groupInfoState.GROUP_YN === "N") {
      scheduleInfo = {
        ...scheduleInfo,
        REC_YN: recordYN,
      };
    }

    if (Array.isArray(participants) && participants.length > 0) {
      scheduleInfo = {
        ...scheduleInfo,
        participants: participants,
      };
    }
    formData.append("scheduleInfo", JSON.stringify(scheduleInfo));

    axios
      .post("/schedule/insertSchedule", formData)
      .then(response => {
        if (response.status === 200) {
          setLoading(false);
          if (response.data[0].hasOwnProperty("MSG")) {
            if (
              response.data[0].MSG === "parti_add" ||
              response.data[0].MSG === "schedule_new" ||
              response.data[0].MSG === "api_call" ||
              response.data[0].MSG === "account_limit"
            ) {
              const obj = {
                TEXT: t("schedule.msg." + response.data[0].MSG),
                submitEventHandler: () => {
                  dispatch(closeAlert());
                },
              };
              dispatch(openAlert(obj));
              return;
            } else {
              openFailPopup(response.data[0].MSG);
              return;
            }
          }
          console.log(response.data[0]);
          setScheduleInfo(response.data[0]);
          setInviteURL(response.data[0].SCHEDULE.INVITE_URL);
          setActive(true);
        } else {
          setLoading(false);
          const obj = {
            TEXT: "회의가 예약되지 않았습니다.",
            submitEventHandler: () => dispatch(closeAlert()),
          };
          dispatch(openAlert(obj));
        }
      })
      .catch(error => {
        setLoading(false);
        const obj = {
          TEXT: "회의 예약과정에서 오류가 발생하였습니다.",
          submitEventHandler: () => dispatch(closeAlert()),
        };
        dispatch(openAlert(obj));
        console.log(error);
      });
  };
  const history = useHistory();

  const closeClick = () => {
    history.goBack();
    // setActive(false);
  };

  const startMeeting = () => {
    if (scheduleInfo.SCHEDULE.API_TYPE === "CD0501") {
      window.open(scheduleInfo.SCHEDULE.INVITE_URL, "_blank");
    } else if (scheduleInfo.SCHEDULE.API_TYPE === "CD0502") {
      window.open(
        server_url +
          "/schedule/reDirectZoomHostUrl?meeting_key=" +
          scheduleInfo.SCHEDULE.MEETING_KEY,
        "_blank"
      );
    }
    history.goBack();
    // setActive(false);
  };
  /* 회의 이용권 관련 예약 실패 팝업 */
  const [showFailPopup, setShowFailPopup] = useState(false);
  const [resultMSG, setResultMSG] = useState("");
  const openFailPopup = msg => {
    setShowFailPopup(true);
    setResultMSG(msg);
  };
  const closeFailPopup = () => {
    setShowFailPopup(false);
  };

  /**
   * 화상회의 플랫폼 선택
   */
  const [platformSelectBox, setPlatformSelectBox] = useState(false);
  const [platform, setPlatform] = useState(userInfoState.PLATFORM);

  useEffect(() => {
    setActive(false);
  }, []);

  return (
    <>
      <FailReservationPopup
        open={showFailPopup}
        close={closeFailPopup}
        msg={resultMSG}
      />
      <div
        style={{
          display: loading ? "block" : "none",
          zIndex: "997",
          position: "absolute",
          width: "1483px",
          height: "954px",
        }}
      >
        <Loading />
      </div>
      <div
        style={{ display: "flex", justifyContent: "center" }}
        onClick={clickBackgound}
      >
        <ParticipantSidebar
          width={456}
          height={"954px"}
          defaultX={250}
          activeX={513}
          partiSidebar={partiSidebar}
          groupYN={groupInfoState.GROUP_YN}
          groupNum={groupInfoState.GROUP_NUM}
          clickAddressItem={clickAddressItem}
          addCheckedAddressList={addCheckedAddressList}
          handlePartiSidebar={handlePartiSidebar}
        />
        <div
          className={active ? "meetingNowResult" : "meetingNow"}
          style={{
            transform: `translatex(${xPosition}px)`,
            transition: "ease",
          }}
        >
          {!active ? (
            <>
              <div className="meetingNow_header">
                <div className="meetingNow_header_title">
                  지금 바로 미팅하기
                </div>
                <img
                  className="meetingNow_header_closeIcon"
                  src="/img/icon/meetingNow/close.png"
                  alt="close"
                  onClick={closeClick}
                />
              </div>

              <div className="meetingNow_durationArea">
                <div className="meetingNow_durationArea_header">
                  <img src="/img/icon/meetingNow/time.png" alt="time" />
                  <div className="meetingNow_durationArea_title">시간 선택</div>
                </div>

                <div className="meetingNow_durationArea_contents">
                  <div className="meetingNow_durationArea_selectBox_label">
                    현재 시간부터 몇 시간 이용
                  </div>
                  <div className="meetingNow_durationArea_selectBox">
                    <div
                      className="cursor selectHour"
                      onClick={() => {
                        setShowDuration(!showDuration);
                      }}
                    >
                      <div
                        style={{
                          font: "normal normal normal 14px/18px Spoqa Han Sans Neo",
                          color: "#3E3E4F",
                        }}
                      >
                        {meetingHour.find(i => i.value === duration).time}
                      </div>
                      <img
                        src={
                          showDuration
                            ? "/img/icon/meetingNow/up.png"
                            : "/img/icon/meetingNow/drop.png"
                        }
                        alt="DROP"
                      />
                    </div>
                    {showDuration ? (
                      <div
                        className="meetingNow_durationPopup scrollbar"
                        style={{ display: showDuration ? "block" : "none" }}
                      >
                        {licenseCntState.FREE_CNT > 0 &&
                        licenseCntState.PAY_CNT === 0
                          ? meetingHour.slice(0, 6).map(data => (
                              <div
                                className="meetingNow_durationItem"
                                key={"duration_" + data.value}
                                onClick={() => (
                                  setDuration(data.value),
                                  setShowDuration(!showDuration)
                                )}
                              >
                                <div>{data.time}</div>
                              </div>
                            ))
                          : meetingHour.map(data => (
                              <div
                                className="meetingNow_durationItem"
                                key={"duration_" + data.value}
                                onClick={() => (
                                  setDuration(data.value),
                                  setShowDuration(!showDuration)
                                )}
                              >
                                <div>{data.time}</div>
                              </div>
                            ))}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="meetingNow_platformArea">
                <div className="meetingNow_platformArea_header">
                  <img src="/img/icon/meetingNow/platform.png" alt="platform" />
                  <div className="meetingNow_platformArea_title">
                    화상회의 플랫폼 선택
                  </div>
                </div>
                <div className="meetingNow_platformArea_contents">
                  {platform === "CD0501" ? (
                    <img
                      className="cursor meetingNow_platformArea_webexBtn"
                      src="/img/icon/meetingNow/webex.png"
                      alt="webex"
                      onClick={() => {
                        setPlatform("CD0501");
                      }}
                    />
                  ) : (
                    <img
                      className="cursor meetingNow_platformArea_webexBtn_disable"
                      src="/img/icon/meetingNow/webex_disable.png"
                      alt="webex"
                      onClick={() => {
                        setPlatform("CD0501");
                      }}
                    />
                  )}
                  {platform === "CD0502" ? (
                    <img
                      className="cursor meetingNow_platformArea_zoomBtn"
                      src="/img/icon/meetingNow/zoom.png"
                      alt="zoom"
                      onClick={() => {
                        setPlatform("CD0502");
                      }}
                    />
                  ) : (
                    <img
                      className="cursor meetingNow_platformArea_zoomBtn_disable"
                      src="/img/icon/meetingNow/zoom_disable.png"
                      alt="zoom"
                      onClick={() => {
                        setPlatform("CD0502");
                      }}
                    />
                  )}
                  {/* {platform === "CD0503" ? (
                    <img
                      className="meetingNow_platformArea_chimeBtn"
                      src="/img/icon/meetingNow/chime.png"
                      alt="chime"
                      // onClick={() => {
                      //   setPlatform("CD0503");
                      // }}
                    />
                  ) : (
                    <img
                      className="meetingNow_platformArea_chimeBtn_disable"
                      src="/img/icon/meetingNow/chime_disable.png"
                      alt="chime"
                      // onClick={() => {
                      //   setPlatform("CD0503");
                      // }}
                    />
                  )}
                  {platform === "CD0504" ? (
                    <img
                      className="meetingNow_platformArea_pexipBtn"
                      src="/img/icon/meetingNow/pexip.png"
                      alt="pexip"
                      // onClick={() => {
                      //   setPlatform("CD0504");
                      // }}
                    />
                  ) : (
                    <img
                      className="meetingNow_platformArea_pexipBtn_disable"
                      src="/img/icon/meetingNow/pexip_disable.png"
                      alt="pexip"
                      // onClick={() => {
                      //   setPlatform("CD0504");
                      // }}
                    />
                  )} */}
                </div>
              </div>

              <div className="meetingNow_recordArea">
                <div className="meetingNow_recordArea_header">
                  <img
                    src="/img/icon/meetingNow/record.png"
                    alt=""
                    style={{ marginLeft: "4px" }}
                  />
                  <div className="meetingNow_recordArea_title">녹화 여부</div>
                  <button
                    className={
                      recordYN === "Y"
                        ? "meetingNow-record-btn meetingNow-record-btn-active"
                        : "meetingNow-record-btn"
                    }
                    onClick={() => {
                      if (recordYN === "Y") {
                        setRecordYN("N");
                      } else if (recordYN === "N") {
                        setRecordYN("Y");
                      }
                    }}
                  >
                    {recordYN === "Y" ? "녹화 On" : "녹화 Off"}
                  </button>
                </div>
              </div>

              <div className="meetingNow_guestArea">
                <div className="meetingNow_guestArea_header">
                  <img src="/img/icon/meetingNow/guest.png" alt="guest" />
                  <div className="meetingNow_guestArea_title">참석자 추가</div>
                </div>
                <div className="meetingNow_guestArea_contents">
                  <div className="meetingNow_guestArea_input">
                    <input
                      type="text"
                      name="PRTCP_EMAIL"
                      value={PRTCP_EMAIL}
                      placeholder="참여시킬 사람의 메일을 입력해주세요."
                      autoComplete="off"
                      onChange={onChange}
                      onFocus={() => (
                        handlePartiSidebar(true), handleAddress(true)
                      )}
                      onKeyPress={handleKeyPress}
                      onClick={e => e.stopPropagation()}
                    />
                    <AddressPopup
                      className="meetingNowAddressPopup scrollbar"
                      open={addressPopup}
                      address={addressAll}
                      clickAddressItem={clickAddressItem}
                      search={inputs.PRTCP_EMAIL}
                    />
                  </div>
                </div>
              </div>

              <div>
                {participants.length > 0 ? (
                  <>
                    <div
                      style={{
                        marginTop: "8px",
                        marginBottom: "4px",
                        color: "#2B4AC4",
                        font: "normal normal bold 12px/18px Spoqa Han Sans Neo",
                        textAlign: "center",
                      }}
                    >
                      초대할 참석자
                    </div>
                    <div className="participant_List scrollbar">
                      <ul>
                        {participants.map(participant => (
                          <li
                            className="participantItem"
                            key={participant.PRTCP_EMAIL}
                          >
                            <div
                              style={{
                                marginLeft:
                                  participant.PRTCP_NM !== "" ? "24px" : "42px",
                                marginRight:
                                  participant.PRTCP_NM !== "" ? "17px" : "35px",
                              }}
                            >
                              {participant.PRTCP_NM !== null &&
                              participant.PRTCP_NM !== undefined &&
                              participant.PRTCP_NM !== "" ? (
                                <div
                                  style={{
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    maxWidth: "54px",
                                  }}
                                  title={participant.PRTCP_NM}
                                >
                                  {participant.PRTCP_NM}
                                </div>
                              ) : (
                                <a style={{ textAlign: "center" }}>-</a>
                              )}
                            </div>
                            <div
                              style={{
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                maxWidth: "160px",
                              }}
                              title={participant.PRTCP_EMAIL}
                            >
                              {participant.PRTCP_EMAIL}
                            </div>
                            <img
                              className="cursor"
                              src="/img/icon/meetingNow/delete.png"
                              alt="delete"
                              onClick={() => onRemove(participant.PRTCP_EMAIL)}
                            />
                          </li>
                        ))}
                      </ul>
                    </div>
                  </>
                ) : (
                  <div
                    style={{
                      marginTop: "8px",
                      color: "#C1C3CD",
                      font: "normal normal bold 12px/16px Spoqa Han Sans Neo",
                      textAlign: "center",
                    }}
                  >
                    참석자를 추가하지 않고 회의룸을 만들 수 있습니다.
                  </div>
                )}
              </div>
              <div className="btnArea">
                <button
                  className="startBtn"
                  onClick={() => (openMeeting(), handlePartiSidebar(false))}
                >
                  회의 시작
                </button>
              </div>
            </>
          ) : (
            <>
              <div
                style={{
                  textAlign: "left",
                  margin: "24px 24px 8px 0",
                  color: "#2B4AC4",
                  font: "normal normal bold 24px/32px Spoqa Han Sans Neo",
                }}
              >
                <img
                  src="/img/icon/meetingNow/close.png"
                  alt="close"
                  onClick={closeClick}
                  style={{
                    float: "right",
                    cursor: "pointer",
                  }}
                />
              </div>
              {participants.length > 0 ? (
                <div>
                  <img src="/img/icon/meetingNow/mail.png" alt="" />
                  <div
                    style={{
                      textAlign: "center",
                      font: "normal normal bold 20px/19px Spoqa Han Sans Neo",
                      color: "#2B4AC4",
                      clear: "both",
                      paddingTop: "22px",
                    }}
                  >
                    다음의 사람들에게 초대장을 보냈습니다!
                  </div>
                  <div
                    className="scrollbar"
                    style={{
                      margin: "23px 58px 19px",
                      maxHeight: "100px",
                      overflowY: "auto",
                      borderRadius: "7px",
                      border: "solid 1px #d2d2d2",
                      padding: "17px 0 17px 50px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px",
                    }}
                  >
                    {participants.map(data => (
                      <div
                        key={data.PRTCP_EMAIL}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          // justifyContent: "center",
                        }}
                      >
                        <img
                          src="/img/icon/meetingNow/participant.png"
                          alt=""
                        />
                        <div
                          style={{
                            font: "normal normal normal 15px/16px Spoqa Han Sans Neo",
                            color: "#111111",
                            marginLeft: "20px",
                          }}
                        >
                          {data.PRTCP_EMAIL}
                        </div>
                        {data.PRTCP_NM === "" ? (
                          <></>
                        ) : (
                          <div
                            style={{
                              font: "normal normal normal 15px/14px Spoqa Han Sans Neo",
                              color: "#707581",
                              marginLeft: "18px",
                            }}
                          >
                            {data.PRTCP_NM}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>

                  <div
                    style={{ padding: "21px 38px 20px", background: "#F2F2FB" }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        font: "normal normal 500 16px/16px Spoqa Han Sans Neo",
                        color: "#111111",
                        marginBottom: "21px",
                      }}
                    >
                      링크를 공유해서 초대할 수 있습니다.
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        background: "#e1e1ed",
                        borderRadius: "7px",
                        padding: "10px 23px 9px",
                      }}
                    >
                      <div
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          width: "330px",
                          font: "normal normal normal 14px/17px Spoqa Han Sans Neo",
                          color: "#111",
                        }}
                        title={inviteURL}
                      >
                        {inviteURL}
                      </div>
                      <CopyToClipboard
                        text={inviteURL}
                        onCopy={() => {
                          const obj = {
                            TEXT: "클립보드에 복사되었습니다.",
                            submitEventHandler: () => dispatch(closeAlert()),
                          };
                          dispatch(openAlert(obj));
                        }}
                      >
                        <div className="link-copy">
                          <img src="/img/icon/meetingNow/copy.png" alt="" />
                        </div>
                      </CopyToClipboard>
                    </div>
                  </div>

                  {scheduleInfo.SCHEDULE.API_TYPE === "CD0501" ? (
                    <div className="meetingNowResult-webex-meetingInfo">
                      <CopyToClipboard
                        text={scheduleInfo.SCHEDULE.HOST_KEY}
                        onCopy={() => {
                          const obj = {
                            TEXT: "클립보드에 복사되었습니다.",
                            submitEventHandler: () => dispatch(closeAlert()),
                          };
                          dispatch(openAlert(obj));
                        }}
                      >
                        <div className="meetingNowResult-webex-meetingInfo-item">
                          <div className="meetingNowResult-webex-meetingInfo-label">
                            호스트키
                          </div>
                          <div className="meetingNowResult-webex-meetingInfo-value">
                            {scheduleInfo.SCHEDULE.HOST_KEY}
                          </div>
                        </div>
                      </CopyToClipboard>
                      <CopyToClipboard
                        text={scheduleInfo.SCHEDULE.MEEINTG_PW}
                        onCopy={() => {
                          const obj = {
                            TEXT: "클립보드에 복사되었습니다.",
                            submitEventHandler: () => dispatch(closeAlert()),
                          };
                          dispatch(openAlert(obj));
                        }}
                      >
                        <div className="meetingNowResult-webex-meetingInfo-item">
                          <div className="meetingNowResult-webex-meetingInfo-label">
                            비밀번호
                          </div>
                          <div className="meetingNowResult-webex-meetingInfo-value">
                            {scheduleInfo.SCHEDULE.MEETING_PW}
                          </div>
                        </div>
                      </CopyToClipboard>
                      <CopyToClipboard
                        text={scheduleInfo.SCHEDULE.SIP_URL}
                        onCopy={() => {
                          const obj = {
                            TEXT: "클립보드에 복사되었습니다.",
                            submitEventHandler: () => dispatch(closeAlert()),
                          };
                          dispatch(openAlert(obj));
                        }}
                      >
                        <div className="meetingNowResult-webex-meetingInfo-item">
                          <div className="meetingNowResult-webex-meetingInfo-label">
                            SIP URL
                          </div>
                          <div className="meetingNowResult-webex-meetingInfo-value">
                            {scheduleInfo.SCHEDULE.SIP_URL}
                          </div>
                        </div>
                      </CopyToClipboard>
                      <CopyToClipboard
                        text={scheduleInfo.SCHEDULE.MEETING_LINK}
                        onCopy={() => {
                          const obj = {
                            TEXT: "클립보드에 복사되었습니다.",
                            submitEventHandler: () => dispatch(closeAlert()),
                          };
                          dispatch(openAlert(obj));
                        }}
                      >
                        <div className="meetingNowResult-webex-meetingInfo-item">
                          <div className="meetingNowResult-webex-meetingInfo-label">
                            회의 링크
                          </div>
                          <div className="meetingNowResult-webex-meetingInfo-value">
                            {scheduleInfo.SCHEDULE.MEETING_LINK}
                          </div>
                        </div>
                      </CopyToClipboard>
                    </div>
                  ) : null}

                  <div className="btnArea" style={{ marginTop: "9px" }}>
                    <button className="startBtn" onClick={startMeeting}>
                      미팅룸 입장하기
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <img src="/img/icon/meetingNow/now.png" alt="" />
                  <div
                    style={{
                      textAlign: "center",
                      font: "normal normal bold 20px/19px Spoqa Han Sans Neo",
                      color: "#2B4AC4",
                      clear: "both",
                      marginTop: "18px",
                      paddingBottom: "22px",
                    }}
                  >
                    지금 바로 회의를 시작합니다!
                  </div>

                  <div
                    style={{ padding: "21px 38px 20px", background: "#F2F2FB" }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        font: "normal normal 500 16px/16px Spoqa Han Sans Neo",
                        color: "#111111",
                        marginBottom: "17px",
                      }}
                    >
                      링크를 공유해서 초대할 수 있습니다.
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        background: "#e1e1ed",
                        borderRadius: "7px",
                        padding: "10px 23px 9px",
                      }}
                    >
                      <div
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          width: "330px",
                          font: "normal normal normal 14px/17px Spoqa Han Sans Neo",
                          color: "#111",
                        }}
                        title={inviteURL}
                      >
                        {inviteURL}
                      </div>
                      <CopyToClipboard
                        text={inviteURL}
                        onCopy={() => {
                          const obj = {
                            TEXT: "클립보드에 복사되었습니다.",
                            submitEventHandler: () => dispatch(closeAlert()),
                          };
                          dispatch(openAlert(obj));
                        }}
                      >
                        <div className="link-copy">
                          <img src="/img/icon/meetingNow/copy.png" alt="" />
                        </div>
                      </CopyToClipboard>
                    </div>
                  </div>

                  {scheduleInfo.SCHEDULE.API_TYPE === "CD0501" ? (
                    <div className="meetingNowResult-webex-meetingInfo">
                      <CopyToClipboard
                        text={scheduleInfo.SCHEDULE.HOST_KEY}
                        onCopy={() => {
                          const obj = {
                            TEXT: "클립보드에 복사되었습니다.",
                            submitEventHandler: () => dispatch(closeAlert()),
                          };
                          dispatch(openAlert(obj));
                        }}
                      >
                        <div className="meetingNowResult-webex-meetingInfo-item">
                          <div className="meetingNowResult-webex-meetingInfo-label">
                            호스트키
                          </div>
                          <div className="meetingNowResult-webex-meetingInfo-value">
                            {scheduleInfo.SCHEDULE.HOST_KEY}
                          </div>
                        </div>
                      </CopyToClipboard>
                      <CopyToClipboard
                        text={scheduleInfo.SCHEDULE.MEEINTG_PW}
                        onCopy={() => {
                          const obj = {
                            TEXT: "클립보드에 복사되었습니다.",
                            submitEventHandler: () => dispatch(closeAlert()),
                          };
                          dispatch(openAlert(obj));
                        }}
                      >
                        <div className="meetingNowResult-webex-meetingInfo-item">
                          <div className="meetingNowResult-webex-meetingInfo-label">
                            비밀번호
                          </div>
                          <div className="meetingNowResult-webex-meetingInfo-value">
                            {scheduleInfo.SCHEDULE.MEETING_PW}
                          </div>
                        </div>
                      </CopyToClipboard>
                      <CopyToClipboard
                        text={scheduleInfo.SCHEDULE.SIP_URL}
                        onCopy={() => {
                          const obj = {
                            TEXT: "클립보드에 복사되었습니다.",
                            submitEventHandler: () => dispatch(closeAlert()),
                          };
                          dispatch(openAlert(obj));
                        }}
                      >
                        <div className="meetingNowResult-webex-meetingInfo-item">
                          <div className="meetingNowResult-webex-meetingInfo-label">
                            SIP URL
                          </div>
                          <div className="meetingNowResult-webex-meetingInfo-value">
                            {scheduleInfo.SCHEDULE.SIP_URL}
                          </div>
                        </div>
                      </CopyToClipboard>
                      <CopyToClipboard
                        text={scheduleInfo.SCHEDULE.MEETING_LINK}
                        onCopy={() => {
                          const obj = {
                            TEXT: "클립보드에 복사되었습니다.",
                            submitEventHandler: () => dispatch(closeAlert()),
                          };
                          dispatch(openAlert(obj));
                        }}
                      >
                        <div className="meetingNowResult-webex-meetingInfo-item">
                          <div className="meetingNowResult-webex-meetingInfo-label">
                            회의 링크
                          </div>
                          <div className="meetingNowResult-webex-meetingInfo-value">
                            {scheduleInfo.SCHEDULE.MEETING_LINK}
                          </div>
                        </div>
                      </CopyToClipboard>
                    </div>
                  ) : null}

                  <div className="btnArea" style={{ marginTop: "9px" }}>
                    <button className="startBtn" onClick={startMeeting}>
                      미팅룸 입장하기
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default MeetingNow;
