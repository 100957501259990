import axios from 'axios';
import Loading from 'component/tag/loading/loading';
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { updateUserInfo } from "actions/index";
import Alert from "component/tag/modal/alert";
import { useTranslation } from 'react-i18next';
/**
 * Version : 1.0
 * 파일명 : ProfileTimeZonePopup.js
 * 작성일자 : 2022-01-13
 * 작성자 : 권도훈
 * 설명 : 사용자 타임존 설정 팝업
 * 수정일자 :  2022-01-19
 * 수정자 : 권도훈
 * 수정내역 : 다국어 적용
 */
function ProfileTimeZonePopup(props) {
    /**
     * TRANSLATION
     */
    const { t } = useTranslation();
    /**
     * REDUX
     */
    const USER_STATE = useSelector(state => state.userInfoReducer);
    /**
     * DISPATCH
     */
    const dispatch = useDispatch();
    /**
     * STATE
     */
    const [userTimeZone, setUserTimeZone] = useState({
        CONTINENT: "",
        CITY: ""
    })
    const [loading, setLoading] = useState(false);
    const [showContinent, setShowContinent] = useState(false);
    const [showCity, setShowCity] = useState(false);
    const [continent, setContinent] = useState([]);
    const [city, setCity] = useState([]);
    /**
     * ALERT
     */
    const [alertProps, setAlertProps] = useState({
        text: "",
        isShow: false,
        cancelBtnDisplay: false,
        okBtnDisplay: true,
        okBtnEventHandler: function () { },
        cancleBtnEventHandler: function () {
            closeAlertEvent();
        },
    });
    /**
     * CLOSE ALERT
     */
    const closeAlertEvent = useCallback(() => {
        setAlertProps({
            ...alertProps,
            isShow: false,
        });
    }, [alertProps]);
    /**
     * SET CONTINENT LIST
     */
    const getContinentList = useCallback(() => {
        setLoading(true);

        axios.get("/profile/continent")
            .then(res => {
                if (res.status === 200) {
                    setContinent(res.data);
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            })
    }, []);
    /**
     * SET CITY LIST
     */
    const getCityList = useCallback(() => {
        setLoading(true);
        axios.get("/profile/city")
            .then(res => {
                if (res.status === 200) {
                    setCity(res.data);
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            })
    }, []);
    /**
     * SET USER TIMEZONE INFO
     */
    const setUserInfo = useCallback(() => {
        const split = USER_STATE.TIMEZONE.split("/");
        setUserTimeZone({
            CONTINENT: split[0],
            CITY: split[1]
        })
    }, [USER_STATE.TIMEZONE]);
    /**
     * REQUEST
     */
    useEffect(() => {
        if (!props.timeZonePopup) {
            setUserInfo();
            getContinentList();
            getCityList();
            setShowCity(false);
            setShowContinent(false);
        }
    }, [getCityList, getContinentList, props.timeZonePopup, setUserInfo]);
    /**
     * SAVE TIMEZONE
     */
    const saveTimeZone = () => {
        setAlertProps({
            ...alertProps,
            text: t("my.profile.msg.saveTimeZone"),
            isShow: true,
            cancelBtnDisplay: true,
            okBtnEventHandler: function () {
                closeAlertEvent();

                if (userTimeZone.CITY === "") {
                    setAlertProps({
                        ...alertProps,
                        isShow: true,
                        text: t("my.profile.msg.selectCity"),
                        cancelBtnDisplay: false,
                        okBtnEventHandler: function () {
                            closeAlertEvent();
                        },
                    });
                    return;
                }
                setLoading(true);

                const timezone = userTimeZone.CONTINENT + "/" + userTimeZone.CITY;
                const config = {
                    params: {
                        TIMEZONE: timezone
                    }
                }

                axios.post("/user/timezone", null, config)
                    .then(res => {
                        if (res.status === 200) {
                            const obj = {
                                ...USER_STATE,
                                TIMEZONE: timezone
                            }
                            dispatch(updateUserInfo(obj));
                            props.setTimeZonePopup(false);
                            setAlertProps({
                                ...alertProps,
                                isShow: true,
                                text: t("my.profile.msg.successTimeZone"),
                                cancelBtnDisplay: false,
                                okBtnEventHandler: function () {
                                    closeAlertEvent();
                                },
                            });
                        }
                        setLoading(false);
                    })
                    .catch(() => {
                        setLoading(false);
                        setAlertProps({
                            ...alertProps,
                            isShow: true,
                            text: t("my.profile.msg.failTimeZone"),
                            cancelBtnDisplay: false,
                            okBtnEventHandler: function () {
                                closeAlertEvent();
                            },
                        });
                    })
            },
            cancleBtnEventHandler: function () {
                closeAlertEvent();
            }
        });
    }
    /**
     * TAG
     */
    return (
        <>
            <Alert alertProps={alertProps} />

            {
                loading
                    ?
                    <div
                        style={{
                            height: "100%"
                        }}
                    >
                        <Loading />
                    </div >
                    :
                    <div
                        style={{
                            margin: "12px 16px 16px 16px",
                            // height: "118px"
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            justifyContent: "center"
                        }}
                        onClick={() => {
                            if (showContinent) {
                                setShowContinent(false);
                            } else if (showCity) {
                                setShowCity(false);
                            }
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "100%"
                            }}
                        >
                            <div
                                style={{
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    color: "#3E3E4F"
                                }}
                            >
                                {t("my.profile.txt.selectTimeZone")}
                            </div>
                            <img
                                className='cursor'
                                src="/img/icon/profile/close.png"
                                alt="X"
                                onClick={() => props.setTimeZonePopup(false)}
                            />
                        </div>

                        <div
                            style={{
                                display: "flex",
                                width: "100%",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginTop: "8px",
                                marginBottom: "16px"
                            }}
                        >
                            <div
                                style={{
                                    width: "152px",
                                    height: "36px",
                                    background: "#FFFFFF",
                                    border: "1px solid #D1D3DA",
                                    borderRadius: "8px"
                                }}
                                className='cursor'
                                onClick={() => {
                                    setShowCity(false);
                                    setShowContinent(!showContinent);
                                }}
                            >
                                <div
                                    style={{
                                        padding: "8px 16px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between"
                                    }}
                                >
                                    <div
                                        style={{
                                            fontSize: "14px",
                                            color: "#3E3E4F"
                                        }}
                                    >
                                        {userTimeZone.CONTINENT}
                                    </div>
                                    <img
                                        src={
                                            showContinent
                                                ? "/img/icon/profile/up.png"
                                                : "/img/icon/profile/drop.png"
                                        }
                                        alt="DROP"
                                    />
                                </div>
                            </div>

                            <div
                                style={{
                                    width: "152px",
                                    height: "36px",
                                    background: "#FFFFFF",
                                    border: "1px solid #D1D3DA",
                                    borderRadius: "8px"
                                }}
                                className='cursor'
                                onClick={() => {
                                    setShowContinent(false);
                                    setShowCity(!showCity);
                                }}
                            >
                                <div
                                    style={{
                                        padding: "8px 16px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between"
                                    }}
                                >
                                    <div
                                        style={{
                                            fontSize: "14px",
                                            color: "#3E3E4F"
                                        }}
                                    >
                                        {userTimeZone.CITY}
                                    </div>
                                    <img
                                        src={
                                            showCity
                                                ? "/img/icon/profile/up.png"
                                                : "/img/icon/profile/drop.png"
                                        }
                                        alt="DROP"
                                    />
                                </div>

                                <div
                                    style={{
                                        padding: "8px 16px",
                                        display: showContinent ? "flex" : "none",
                                        boxShadow: "0px 5px 10px #00000029",
                                        border: "1px solid #D1D3DA",
                                        borderRadius: "8px",
                                        position: "absolute",
                                        inset: "0px auto auto 0px",
                                        transform: "translate(15px, 83px)",
                                        width: "122px",
                                        alignItems: "flex-start",
                                        flexDirection: "column",
                                        background: "#FFFFFF",
                                        height: "150px",
                                        overflowY: "auto"
                                    }}
                                    className='scrollbar'
                                    onClick={e => e.stopPropagation()}
                                >
                                    {
                                        continent.map((data, idx) => {
                                            if (data.CONTINENT !== userTimeZone.CONTINENT) {
                                                return (
                                                    <div
                                                        className='cursor'
                                                        key={"CONTINENT_LIST_" + idx}
                                                        style={{
                                                            fontSize: "14px",
                                                            color: "#3E3E4F",
                                                            padding: "5px 0",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            borderBottom: "1px solid #e7e7e7",
                                                            width: "100%",
                                                        }}
                                                        onClick={() => {
                                                            setUserTimeZone({
                                                                CONTINENT: data.CONTINENT,
                                                                CITY: ""
                                                            });
                                                            setShowContinent(false);
                                                        }}
                                                    >
                                                        {data.CONTINENT}
                                                    </div>
                                                )
                                            } else {
                                                return (
                                                    null
                                                )
                                            }
                                        })
                                    }
                                </div>

                                <div
                                    style={{
                                        padding: "8px 16px",
                                        display: showCity ? "flex" : "none",
                                        boxShadow: "0px 5px 10px #00000029",
                                        border: "1px solid #D1D3DA",
                                        borderRadius: "8px",
                                        position: "absolute",
                                        inset: "0px auto auto 0px",
                                        transform: "translate(175px, 83px)",
                                        width: "122px",
                                        alignItems: "flex-start",
                                        flexDirection: "column",
                                        background: "#FFFFFF",
                                        height: "150px",
                                        overflowY: "auto"
                                    }}
                                    className='scrollbar'
                                    onClick={e => e.stopPropagation()}
                                >
                                    {
                                        city.map((data, idx) => {
                                            if (data.CONTINENT === userTimeZone.CONTINENT) {
                                                return (
                                                    <div
                                                        className='cursor'
                                                        key={"CITY_LIST_" + idx}
                                                        style={{
                                                            fontSize: "14px",
                                                            color: "#3E3E4F",
                                                            padding: "5px 0",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            borderBottom: "1px solid #e7e7e7",
                                                            width: "100%",
                                                        }}
                                                        onClick={() => {
                                                            setUserTimeZone({
                                                                ...userTimeZone,
                                                                CITY: data.CITY
                                                            });
                                                            setShowCity(false);
                                                        }}
                                                    >
                                                        {data.CITY}
                                                    </div>
                                                )
                                            } else {
                                                return (
                                                    null
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </div>
                        </div>

                        <div>
                            <button
                                className='cursor'
                                onClick={() => props.setTimeZonePopup(false)}
                                style={{
                                    width: "64px",
                                    height: "36px",
                                    background: "#3E3E4F",
                                    borderRadius: "8px",
                                    border: "none",
                                    outline: "none",
                                    color: "#FFFFFF",
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    marginRight: "8px"
                                }}
                            >
                                {t("common.btn.cancle")}
                            </button>

                            <button
                                className='cursor'
                                style={{
                                    width: "64px",
                                    height: "36px",
                                    background: "#D4667E",
                                    borderRadius: "8px",
                                    border: "none",
                                    outline: "none",
                                    color: "#FFFFFF",
                                    fontSize: "14px",
                                    fontWeight: "bold"
                                }}
                                onClick={() => saveTimeZone()}
                            >
                                {t("common.btn.save")}
                            </button>
                        </div>
                    </div >
            }
        </>
    )
}

export default ProfileTimeZonePopup
