import React, { useEffect, useRef, useState } from "react";
import "css/landing.css";
import "css/reponsive/landing/landing.css";
import { useHistory } from "react-router-dom";
import Footer from "component/tag/div/common/landing/footer";
import useScrollFadeIn from "component/hook/scrollFade";
import useScrollCount from "component/hook/scrollCount";
import { useTranslation } from "react-i18next";
import UpdatePopup from "component/tag/popup/landing/updatePopup";
import { Cookies } from "react-cookie";
/**
 * Version : 1.0
 * 파일명 :
 * 작성일자 : 2021-10-05
 * 작성자 : 권도훈
 * 설명 : 랜딩 페이지
 * 수정일자 : 2022-06-15
 * 수정자 : 강연승
 * 수정내역 : sobridge 데모 - 팝업, 무료체험 버튼, 내용 주석처리
 */
function Landing() {
  /**
   * ref
   */
  const topRef = useRef();
  const counterRef = useRef();
  const guideRef = useRef();
  const productRef = useRef();
  const experienceRef = useRef();

  const [init, setInit] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [popup, setPopup] = useState(false);

  /**
   * history
   */
  const history = useHistory();

  /**
   * 언어변환
   */
  const { t } = useTranslation();

  /**
   * CUSTOM HOOK
   */
  const featureFade1 = useScrollFadeIn(1, 0);
  const featureFade2 = useScrollFadeIn(1, 0);
  const featureFade3 = useScrollFadeIn(1, 0);
  const guideFade = useScrollFadeIn(1, 0);
  const producteFade = useScrollFadeIn(1, 0);
  const experienceFade = useScrollFadeIn(1, 0);
  // const experienceFade2 = useScrollFadeIn(1, 0);
  const solutionCount = useScrollCount(0, 1, true);
  const customerCount = useScrollCount(50, 56, false);
  const useCount = useScrollCount(10, 437847, false);


  useEffect(() => {
    const url = new URL(window.location.href).host.toLowerCase();
    const list = ["yonsei", "kentech", "sy"];

    list.forEach(el => {
      if (url.includes(el)) {
        history.push("/login");
        return;
      }
    });

    setInit(true);
  }, [history]);

  /**
   * FADE
   */
  useEffect(() => {
    if (!isVisible && init) {
      setIsVisible(true);
    }
  }, [init, isVisible]);

  useEffect(() => {
    const view = new URL(window.location.href).searchParams.get("view");

    if (view !== null && view === "experience") {
      experienceRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    const cookies = new Cookies();
    const domain = process.env.REACT_APP_COOKIE_DOMAIN;

    const popup1 = cookies.get("update_popup_1", {
      path: "/",
      domain: domain,
    });
    const popup2 = cookies.get("update_popup_2", {
      path: "/",
      domain: domain,
    });

    if (popup1 === undefined || popup2 === undefined) {
      setPopup(true);
    }
  }, []);

  const goLoginPage = () => {
    history.push({
      pathname: "/login",
      state: {
        tag: "block",
        type: "default",
      },
    });
  };
  const goMakeAccount = () => {
    window.location.href = "/authMail";
  };

  return (
    <div
      className="scrollbar"
      style={{
        position: "absolute",
        width: "100%",
        opacity: init ? "1" : "0"
      }}
    >
      <header>
        <div className="hd_content">
          <div
            className="cursor"
            onClick={() =>
              topRef.current.scrollIntoView({ behavior: "smooth" })
            }
          >
            <img src="/img/landing_logo.png" alt="logo_title" />
          </div>

          <section>
            <div className="hd_txt_btn_area">
              {/* <button
                className="cursor"
                onClick={() =>
                  window.open(
                    "https://rivtech.notion.site/6572d32acc384bb087e85b44584bdae8"
                  )
                }
              >
                개선사항
              </button>

              <button
                className="cursor"
                onClick={() =>
                  guideRef.current.scrollIntoView({ behavior: "smooth" })
                }
              >
                사용 가이드
              </button>

              <button
                className="cursor"
                onClick={() =>
                  productRef.current.scrollIntoView({ behavior: "smooth" })
                }
              >
                가격 정책
              </button>

              <button
                className="cursor"
                onClick={() =>
                  experienceRef.current.scrollIntoView({ behavior: "smooth" })
                }
              >
                무료 체험
              </button> */}
            </div>

            <button className="cursor hd_btn" onClick={() => goMakeAccount()}>
              {t("btn.SIGN_UP")}
            </button>

            <button className="cursor hd_btn" onClick={() => goLoginPage()}>
              {t("btn.SIGN_IN")}
            </button>
          </section>
        </div>
      </header>

      <div className="drag-none body">
        <div
          className="bd_top_btn"
          onClick={() => topRef.current.scrollIntoView({ behavior: "smooth" })}
        >
          <img src="/img/icon/landing/main/top_arrow.png" alt="top" />
        </div>

        <div ref={topRef} />
        <section className="bd_title_section">
          <div
            style={{
              transform: isVisible
                ? "translate3d(0, 0, 0)"
                : "translate3d(0, 50%, 0)",
              transitionProperty: "all",
              transitionDuration: "1s",
              transitionTimingFunction: "cubic-bezier(0, 0, 0.2, 1)",
              transitionDelay: "0s",
              opacity: isVisible ? 1 : 0,
            }}
          >
            <img
              className="bd_title_logo"
              src="/img/icon/landing/main/logo_center.png"
              alt="login_title"
            />

            <article className="bd_title_logo_article">
              <p style={{ fontSize: "32px" }}>
                When, Where, Everyone, Together
              </p>
              <p style={{ fontSize: "36px" }}>'So-Bridge'</p>
            </article>

            {/* <button
              className="cursor bd_title_btn"
              // onClick={() => goMakeAccount()}
            >
              SoBridge 무료로 사용해보기
            </button> */}

            <article className="bd_title_pebble_article">
              <p
                style={{
                  transform: isVisible
                    ? "translate3d(0, 0, 0)"
                    : "translate3d(0, 20px, 0)",
                  transitionProperty: "all",
                  transitionDuration: "1s",
                  transitionTimingFunction: "cubic-bezier(0, 0, 0.2, 1)",
                  transitionDelay: "0.2s",
                  opacity: isVisible ? 1 : 0,
                }}
              >
                모든 온라인 미팅을 하나의 캘린더에!!
              </p>

              <img
                src="/img/icon/landing/main/pebbles_point.png"
                alt="point"
                style={{
                  transform: isVisible
                    ? "translate3d(0, 0, 0)"
                    : "translate3d(0, 20px, 0)",
                  transitionProperty: "all",
                  transitionDuration: "1s",
                  transitionTimingFunction: "cubic-bezier(0, 0, 0.2, 1)",
                  transitionDelay: "0.4s",
                  opacity: isVisible ? 1 : 0,
                }}
              />

              <img
                src="/img/icon/landing/main/pebbles.png"
                alt="img_meeting"
                style={{
                  transform: isVisible
                    ? "translate3d(0, 0, 0)"
                    : "translate3d(0, 20px, 0)",
                  transitionProperty: "all",
                  transitionDuration: "1s",
                  transitionTimingFunction: "cubic-bezier(0, 0, 0.2, 1)",
                  transitionDelay: "0.6s",
                  opacity: isVisible ? 1 : 0,
                }}
              />
            </article>
          </div>
        </section>

        <section
          className="bd_calendar_section"
          style={{
            transform: isVisible
              ? "translate(0, 800px)"
              : "translate(0, 900px)",
            transitionProperty: "all",
            transitionDuration: "1s",
            transitionTimingFunction: "cubic-bezier(0, 0, 0.2, 1)",
            transitionDelay: "0.8s",
            opacity: isVisible ? 1 : 0,
          }}
        >
          <img src="/img/icon/landing/main/calendar.png" alt="cal" />
        </section>

        <div ref={counterRef} />
        <section className="bd_pr_section">
          <div className="bd_pr_content">
            {/* <article className="bd_pr_title_article">
              <p className="bd_pr_title">Why Use SoBridge?</p>

              <p className="bd_pr_description">
                최대 레퍼런스로 검증된 화상회의 예약 및 관리 솔루션의 SaaS 버전
              </p>
            </article>

            <ul>
              <li>
                <title {...solutionCount}></title>
                <strong>The world's most</strong>
                <strong>chosen solution</strong>
              </li>

              <li className="center">
                <title {...customerCount}></title>
                <strong>The largest number</strong>
                <strong>of customers in Korea</strong>
              </li>

              <li>
                <title {...useCount}></title>
                <strong>currently in use</strong>
                <strong>by up to 450,000 users</strong>
              </li>
            </ul> */}

            <div className="bd_pr_feature">
              <p>SoBridge 주요 장점</p>

              <img src="/img/icon/landing/main/features.png" alt="features" />
            </div>

            <div {...featureFade1}>
              <div className="bd_pr_feature_item">
                <img
                  src="/img/icon/landing/main/feature1.png"
                  alt="feature1.png"
                />

                <article className="left">
                  <div className="bd_pr_feature_item_title">
                    스케줄링 캘린더
                  </div>

                  <div className="bd_pr_feature_item_description">
                    <div>Drag&Drop 방식으로 손쉽게 회의 예약,</div>
                    <div>아무리 많은 회의 일정도 한눈에 보고 손쉽게</div>
                    <div>관리할 수 있습니다.</div>
                  </div>
                </article>
              </div>
            </div>

            <div {...featureFade2}>
              <div className="bd_pr_feature_item center">
                <article className="right">
                  <div className="bd_pr_feature_item_title">
                    타임라인 & 즐겨찾기
                  </div>

                  <div className="bd_pr_feature_item_description right">
                    <div>시간 별로 내 일정을 한 눈에 보고!</div>
                    <div>즐겨찾기로 빠르게 예약하고!</div>
                    <div>다양한 미팅을 간편하게 예약할 수 있습니다.</div>
                  </div>
                </article>

                <img
                  src="/img/icon/landing/main/feature2.png"
                  alt="feature2.png"
                />
              </div>
            </div>

            <div {...featureFade3}>
              <div className="bd_pr_feature_item last">
                <img
                  src="/img/icon/landing/main/feature3.png"
                  alt="feature3.png"
                />

                <article className="left">
                  <div className="bd_pr_feature_item_title">그룹 미팅</div>

                  <div className="bd_pr_feature_item_description">
                    <div>회사, 학원, 동호회, 강의 등</div>
                    <div>다양한 그룹들을 만들고 그룹원들과 함께</div>
                    <div>그룹 특성에 맞게 사용할 수 있습니다.</div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="bd_guide_section" ref={guideRef}>
          <div {...guideFade}>
            <div className="bd_guide_title">
              모든 순간을 SoBridge와 함께 해보세요
            </div>

            <iframe
              className="bd_guide_video"
              src="https://www.youtube.com/embed/8gzjRLBJxqw"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </section> */}

        {/* <div ref={productRef} />
        <section
          className="bd_product_section"
          style={{
            backgroundImage: "url(/img/icon/landing/main/landing_price_bg.png)",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div {...producteFade}>
            <img
              src="/img/icon/landing/main/landing_price_img.png"
              alt="charge"
            />
          </div>
        </section> */}

        <div ref={experienceRef} />
        <section className="bd_ep_section" {...experienceFade}>
          {/* <div>
            <div className="bd_ep_fuction_area">
              <div className="bd_ep_fuction_title">
                SoBridge 무료 체험 이용해보기
              </div>

              <img
                className="bd_ep_fuction_logo"
                src="/img/icon/landing/main/coupon.png"
                alt="coupon"
              />

              <ul>
                <li>
                  <div>최초 가입 시 30일간 무료</div>

                  <img src="/img/icon/landing/main/check.png" alt="check" />
                </li>

                <li>
                  <div>모든 기능 사용 가능</div>

                  <img src="/img/icon/landing/main/check.png" alt="check" />
                </li>

                <li>
                  <div>
                    <div>1일 최대 3시간</div>

                    <div>예약 및 회의 가능</div>
                  </div>

                  <img src="/img/icon/landing/main/check.png" alt="check" />
                </li>
              </ul>

              <div className="bd_ep_btn_area">
                <button
                  className="cursor bd_ep_btn"
                  onClick={() => goMakeAccount()}
                >
                  30일 무료체험 사용하기
                </button>
              </div>
            </div>
          </div> */}

          <div>
            <ul className="bd_ep_item_list">
              <li>
                <div className="bd_ep_item_title blue">
                  <div>국내 첫 화상회의</div>

                  <div>일정 관리</div>
                </div>

                <div className="bd_ep_item_description">
                  <div>개인, 단체의 화상회의 일정을</div>

                  <div>한 곳에서 관리해보세요!</div>
                </div>

                <img src="/img/icon/landing/main/illust1.png" alt="illust1" />
              </li>

              <li className="center">
                <div className="bd_ep_item_title orange">
                  <div>계정 공유</div>
                </div>

                <div className="bd_ep_item_description">
                  <div>1개의 계정을</div>

                  <div>여러 명과 공유하여 사용해보세요!</div>

                  <div>계정을 인원 수대로 사지 않아도 됩니다.</div>
                </div>

                <img src="/img/icon/landing/main/illust2.png" alt="illust2" />
              </li>

              <li>
                <div className="bd_ep_item_title purple">
                  <div>영상회의 서비스를</div>

                  <div>하나의 플랫폼에서?</div>
                </div>

                <div className="bd_ep_item_description">
                  <div>Zoom, Webex를 한 곳에서</div>
                  <div>이용해 볼 수 있는 SoBridge 만의 플랫폼!</div>
                </div>

                <img src="/img/icon/landing/main/illust3.png" alt="illust3" />
              </li>
            </ul>
          </div>
        </section>

        <Footer />
      </div>
    </div>
  );
}

export default Landing;
