import { CircularProgress } from '@material-ui/core'
import React from 'react'
/**
 * Version : 1.0
 * 파일명 : profileCardItem.js
 * 작성일자 : 2021-11-16
 * 작성자 : 권도훈
 * 설명 : 사용자 결제수단 리스트 아이템
 * 수정일자 :  2021-12-22
 * 수정자 : 권도훈
 * 수정내역 : 로딩 이미지 수정
 */
function ProfileLoading(props) {
    return (
        <div
            className="profile_loading"
            style={{
                display: props.isShow ? "flex" : "none"
            }}
        >
            {/* <img
                src="/img/pebble_loading.gif"
                alt="loading"
            /> */}
            <CircularProgress />
        </div>
    )
}

export default ProfileLoading
