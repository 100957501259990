import React from 'react'
/**
 * Version : 1.0
 * 파일명 : availableDay.js
 * 작성일자 : 2021-11-30
 * 작성자 : 권도훈
 * 설명 : 무료 이용 팝업 기간 안내 내용
 * 수정일자 : 2022-03-02
 * 수정자 : 권도훈
 * 수정내역 : 문구 수정
*/
function AvailableDay(props) {
    return (
        <div>
            <div className="freeInfo_content">
                <p className="freeInfo_title">
                    무료 체험이 <font className="freeInfo_font_orange">{props.dayCount}일</font> 남았어요!
                </p>
                {
                    props.dayCount > 1
                        ?
                        <img className="freeInfo_mainIMG" src="/img/icon/landing/d_day_10.png" alt="10DAY" />
                        :
                        <img className="freeInfo_mainIMG" src="/img/icon/landing/d_day_1.png" alt="1DAY" />
                }
                <p className="freeInfo_coment_main_orange">
                    30일 무료 체험 회의이용권이 {props.dayCount}일 후 종료됩니다.
                </p>

                <p className="freeInfo_coment_sub">
                    <div>
                        {props.dayCount}일 이후 예약된 미팅을 예정대로 하시려면
                    </div>
                    <div>
                        회의이용권 추가 구매가 필요합니다.
                    </div>
                </p>

                <div className="freeInfo_btn_area">
                    <button
                        className="cursor"
                        onClick={() => {
                            props.updateInfo();
                            props.closePopup();
                        }}
                    >
                        알겠습니다.
                    </button>
                    <button
                        className="cursor"
                        onClick={() => {
                            props.updateInfo();
                            props.goProduct();
                        }}
                    >
                        구매하러 가기
                    </button>
                </div>
            </div>
        </div>
    )
}

export default AvailableDay
