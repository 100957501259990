import React from "react";
import Dialog from "@material-ui/core/Dialog";
/**
 * Version : 1.0
 * 파일명 : denyInviteGroupAlert.js
 * 작성일자 : 2022-01-06
 * 작성자 : 강연승
 * 설명 : 초대받은 그룹 거절 확인창
 * 수정일자 :
 * 수정자 :
 * 수정내역 :
 */
function DenyInviteGroupAlert(props) {
  return (
    <Dialog
      className={props.request.className}
      maxWidth={"xs"}
      open={props.open}
      onClose={props.close}
    >
      <div>{props.request.contents}</div>
      <div className="btnArea">
        <button className="btnYes" onClick={() => props.close()}>
          확 인
        </button>
      </div>
    </Dialog>
  );
}

export default DenyInviteGroupAlert;
