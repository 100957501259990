import axios from "axios";
import PrivacyPolicyPopup from "component/tag/popup/common/privacyPolicyPopup";
import TosPopup from "component/tag/popup/common/tosPopup";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { openAlert, closeAlert } from "actions/index";
import { useDispatch } from "react-redux";
/**
 * Version : 1.0
 * 파일명 : InsertInfo.js
 * 작성일자 : 2021-10-05
 * 작성자 : 권도훈
 * 설명 : 사용자 정보 입력
 * 수정일자 : 2022-05-10
 * 수정자 : 권도훈
 * 수정내역 : 회원가입 버튼 수정
 */
function InsertInfo(props) {
  /**
   * DISPATCH
   */
  const dispatch = useDispatch();
  /**
   * LOCATION
   */
  const location = useLocation();

  /**
   * POPUP
   */
  const [tos, setTos] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);

  const [name, setNM] = useState("");
  const [pw, setPW] = useState("");
  const [pwCheck, setPWCheck] = useState("");
  const [agree, setAgree] = useState({
    read_1: false,
    read_2: false,
  });
  const [isActiveBtn, setIsActiveBtn] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [requestMail, setRequestMail] = useState();
  const [groupCode, setGroupCode] = useState("");

  useEffect(() => {
    // setRequestMail(new URL(window.location.href).searchParams.get("mail"));
    setRequestMail(location.state.mail);
    if (location.state.code !== undefined && location.state.code !== "") {
      setGroupCode(location.state.code);
    }

    if (
      name !== "" &&
      pw !== "" &&
      agree.read_1 === true &&
      agree.read_2 === true &&
      pwCheck !== ""
    ) {
      setIsActiveBtn(true);
      setIsDisabled(false);
    } else {
      setIsActiveBtn(false);
      setIsDisabled(true);
    }
  }, [agree, location.state.code, location.state.mail, name, pw, pwCheck]);

  const createAccount = () => {
    if (name === "" && name === null) {
      const obj = {
        TEXT: "이름이 비어있습니다.",
        submitEventHandler: () => {
          dispatch(closeAlert());
        },
      };
      dispatch(openAlert(obj));
      return;
    } else if (pw === "" && pw === null) {
      const obj = {
        TEXT: "비밀번호가 비어있습니다.",
        submitEventHandler: () => {
          dispatch(closeAlert());
        },
      };
      dispatch(openAlert(obj));
      return;
    } else if (pwCheck !== pw) {
      const obj = {
        TEXT: "비밀번호가 일치하지 않습니다.",
        submitEventHandler: () => {
          dispatch(closeAlert());
        },
      };
      dispatch(openAlert(obj));
      return;
    } else if (!agree) {
      const obj = {
        TEXT: "이용약관에 동의가 필요합니다.",
        submitEventHandler: () => {
          dispatch(closeAlert());
        },
      };
      dispatch(openAlert(obj));
      return;
    } else if (!isValidPassword(pw)) {
      const obj = {
        TEXT: "잘못된 비밀번호 형식입니다.",
        submitEventHandler: () => {
          dispatch(closeAlert());
        },
      };
      dispatch(openAlert(obj));
      return;
    }

    let params = {
      Request_MAIL: requestMail,
      Request_PW: pw,
      Request_NM: name,
    };

    if (groupCode !== "") {
      params = {
        ...params,
        code: groupCode,
      };
    }

    setIsDisabled(true);

    axios
      .post("/user/member", params)
      .then(res => {
        if (res.data) {
          props.setNM(name);
          props.setState(true);
        } else {
          const obj = {
            TEXT: "오류가 발생했습니다.",
            submitEventHandler: () => {
              dispatch(closeAlert());
            },
          };
          dispatch(openAlert(obj));
          setIsDisabled(false);
        }
      })
      .catch(error => {
        axios.post("/user/error", null, { headers: { error_info: error } });
        setIsDisabled(false);
      });
  };

  const isValidPassword = () => {
    var pattern1 = /[0-9]/;
    var pattern2 = /[a-zA-Z]/;
    var pattern3 = /[~!@\\#$%<>^&*]/; // 원하는 특수문자 추가 제거 \ 확인

    if (
      !pattern1.test(pw) ||
      !pattern2.test(pw) ||
      !pattern3.test(pw) ||
      pw.length < 8 ||
      pw.length > 50
    ) {
      return false;
    }

    return true;
  };

  return (
    <div
      style={{
        height: "100%",
        position: "absolute",
        width: "100%",
        backgroundImage: "url(/img/login_background.png)",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <section
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <TosPopup
          isShow={tos}
          setIsShow={setTos}
          setAgree={setAgree}
          agree={agree}
        />
        <PrivacyPolicyPopup
          isShow={privacyPolicy}
          setIsShow={setPrivacyPolicy}
          setAgree={setAgree}
          agree={agree}
        />

        <img src="/img/logo.png" alt="logo_title" />

        <div
          style={{
            color: "#2B4AC4",
            fontSize: "32px",
            fontWeight: "bold",
            marginTop: "16px",
            marginBottom: "24px",
          }}
        >
          상세 정보 입력하기
        </div>

        <div
          style={{
            color: "#2B4AC4",
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          아래 정보만 입력하면 가입이 완료됩니다!
        </div>

        <img
          alt="STEP03"
          src="/img/icon/makeAccount/step3.png"
          style={{
            marginTop: "40px",
            marginBottom: "32px",
          }}
        />

        <section>
          <div
            style={{
              color: "#2B4AC4",
              fontSize: "14px",
              fontWeight: "bold",
            }}
          >
            이름
          </div>

          <input
            className="insertInfoInput"
            onChange={e => setNM(e.target.value)}
            type="text"
            placeholder="플랫폼에서 사용할 이름을 입력해주세요."
          />
        </section>

        <section>
          <div
            style={{
              color: "#2B4AC4",
              fontSize: "14px",
              fontWeight: "bold",
            }}
          >
            비밀번호
          </div>

          <input
            className="insertInfoInput"
            onChange={e => setPW(e.target.value)}
            type="password"
            placeholder="영어 대소문자, 숫자, 특수문자를 조합한 8자리 이상."
          />
        </section>

        <section>
          <div
            style={{
              color: "#2B4AC4",
              fontSize: "14px",
              fontWeight: "bold",
            }}
          >
            비밀번호 확인
          </div>

          <input
            className="insertInfoInput"
            onChange={e => setPWCheck(e.target.value)}
            type="password"
            placeholder="영어 대소문자, 숫자, 특수문자를 조합한 8자리 이상."
          />
        </section>

        <section>
          <div
            style={{
              color: "#2B4AC4",
              fontSize: "14px",
              fontWeight: "bold",
              marginBottom: "12px",
            }}
          >
            서비스 이용을 위해 동의가 필요합니다.
          </div>

          <div
            style={{
              width: "384px",
              height: "48px",
              border: "1px solid #DFE1E5",
              background: "#FFFFFF",
              borderRadius: "8px",
              marginBottom: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={
                  agree.read_1
                    ? "/img/icon/makeAccount/check2.png"
                    : "/img/icon/makeAccount/check1.png"
                }
                alt="check"
                className="cursor inline"
                onClick={() =>
                  setAgree({
                    ...agree,
                    read_1: !agree.read_1,
                  })
                }
                style={{
                  margin: "0 8px",
                }}
              />
              <div
                className="inline"
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: agree.read_1 ? "#3E3E4F" : "#84889B",
                }}
              >
                [필수] 이용약관 동의
              </div>
            </div>

            <img
              alt="view"
              src="/img/icon/address/next_page_btn.png"
              className="cursor"
              style={{
                width: "24px",
                marginRight: "8px",
              }}
              // onClick={() => {
              //   setTos(true);
              // }}
              onClick={() =>
                window.open(
                  "https://junnhhh.notion.site/junnhhh/So-Bridge-2022-06-15-fba408b180a747338817fe3330a57579"
                )
              }
            />
          </div>

          <div
            style={{
              width: "384px",
              height: "48px",
              border: "1px solid #DFE1E5",
              background: "#FFFFFF",
              borderRadius: "8px",
              marginBottom: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={
                  agree.read_2
                    ? "/img/icon/makeAccount/check2.png"
                    : "/img/icon/makeAccount/check1.png"
                }
                alt="check"
                className="cursor inline"
                onClick={() =>
                  setAgree({
                    ...agree,
                    read_2: !agree.read_2,
                  })
                }
                style={{
                  margin: "0 8px",
                }}
              />
              <div
                className="inline"
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: agree.read_2 ? "#3E3E4F" : "#84889B",
                }}
              >
                [필수] 개인정보처리방침 동의
              </div>
            </div>

            <img
              alt="view"
              src="/img/icon/address/next_page_btn.png"
              className="cursor"
              style={{
                width: "24px",
                marginRight: "8px",
              }}
              // onClick={() => {
              //   setPrivacyPolicy(true);
              // }}
              onClick={() =>
                window.open(
                  "https://junnhhh.notion.site/junnhhh/So-Bridge-2022-06-15-ad6ad8d4b56844ba858bb7debd890d1b"
                )
              }
            />
          </div>
        </section>

        <button
          className="signUpBtn"
          style={{
            marginTop: "24px",
          }}
          onClick={createAccount}
          disabled={isDisabled}
        >
          가입하기
        </button>
      </section>
    </div>
  );
}

export default InsertInfo;
