import React from "react";

/**
 * Version : 1.0
 * 파일명 : addressPopup.js
 * 작성일자 : 2021-11-22
 * 작성자 : 강연승
 * 설명 : 주소록 팝업
 * 수정일자 : 2022-03-29
 * 수정자 : 강연승
 * 수정내역 : 주소록 이름, 이메일 타이틀 추가
 * props :
 * open - popup 오픈 상태값
 * address - 검색되는 전체 리스트
 * clickAddressItem - 검색한 주소록 선택 이벤트
 * search - 검색 input 값
 */

function AddressPopup(props) {
  let row = [];
  props.address.forEach(adr => {
    if (
      adr.ADR_NM.indexOf(props.search) === -1 &&
      adr.ADR_EMAIL.indexOf(props.search) === -1
    ) {
      return;
    }
    row.push(adr);
  });
  return (
    <div
      className={props.className}
      style={{
        display: props.open ? "block" : "none",
      }}
    >
      {props.search !== ""
        ? row.map(data => (
            <div
              className="addressPopupItem"
              key={data.ADR_EMAIL}
              onClick={() =>
                props.clickAddressItem(data.ADR_NM, data.ADR_EMAIL)
              }
            >
              <div title={data.ADR_NM} className="addressNM">
                {data.ADR_NM}
              </div>
              <div title={data.ADR_EMAIL} className="addressEmail">
                {data.ADR_EMAIL}
              </div>
            </div>
          ))
        : null}
    </div>
  );
}

export default AddressPopup;
