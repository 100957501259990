import React from "react";
/**
 * Version : 1.0
 * 파일명 : deleteResult.js
 * 작성일자 : 2021-11-29
 * 작성자 : 권도훈
 * 설명 : 탈퇴 완료 팝업
 * 수정일자 : 2022-01-07
 * 수정자 : 권도훈
 * 수정내역 : UI 수정
 */
function DeleteResult() {
  const goLanding = () => {
    window.location.href = "/";
  };
  return (
    <div
      style={{
        height: "135px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          color: "#2B4AC4",
          fontSize: "18px",
          fontWeight: "bold",
        }}
      >
        탈퇴 처리 되었습니다.
      </div>
      <div
        style={{
          color: "#3E3E4F",
          fontSize: "14px",
        }}
      >
        그동안 SoBridge를 이용해주셔서 감사합니다.
      </div>

      <div>
        <button
          className="cursor common_ok_textBtn"
          style={{
            border: "none",
            outline: "none",
            backgroundColor: "#FFFFFF",
            fontSize: "14px",
            fontWeight: "bold",
          }}
          onClick={() => goLanding()}
        >
          확 인
        </button>
      </div>
    </div>
  );
}

export default DeleteResult;
