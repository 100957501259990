import React, { useEffect, useState, useCallback, useRef } from "react";
import ReactModal from "react-modal";
import "chart.js/auto";
import { Chart } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import axios from "axios";
import { useSelector } from "react-redux";
import Loading from "component/tag/loading/loading";
import DataStatisticsParticipantLogPaging from "component/tag/div/group/data/dataStatisticsParticipantLogPaging";

/**
 * Version : 1.0
 * 파일명 : DataStatisticsDetailPopup.js
 * 작성일자 : 2022-03-16
 * 작성자 : 강연승
 * 설명 : 데이터 통계 상세내용 팝업
 * 수정일자 : 2022-05-04
 * 수정자 : 강연승
 * 수정내역 : 그룹 컬러 수정
 */
function DataStatisticsDetailPopup(props) {
  /**
   * REDUCER
   */
  const USER_INFO = useSelector(state => state.userInfoReducer);
  const GROUP_STATE = useSelector(state => state.groupInfoReducer);
  const STATISTICS_STATE = useSelector(state => state.statisticsInfoReducer);
  /**
   * Chart
   */
  Chart.defaults.plugins.legend.display = false;
  // const chartRef = useRef(null);
  /**
   * STATE
   */
  const [loading, setLoading] = useState(false);
  const [chartLoading, setChartLoading] = useState(false);
  const [logListTotal, setLogListTotal] = useState(0);
  const [logList, setLogList] = useState([]);
  const [attendance, setAttendance] = useState({
    total_Duration: 0,
    dataA_Cnt: 0,
    dataA_Duration: 0,
    dataB_Cnt: 0,
    dataB_Duration: 0,
    dataC_Cnt: 0,
    dataC_Duration: 0,
  });
  const [pagingState, setPagingState] = useState({
    ROW_COUNT: 5,
    PAGE_COUNT: 0,
    TOTAL: 0,
  });
  // useEffect(() => {
  //   if (props.data.EMAIL !== undefined) {
  //     getParticipantAttendance();
  //   }
  // }, [props]);

  /**
   * 참석률 조회
   */
  // const getParticipantAttendance = () => {
  //   setChartLoading(true);
  //   let param = {
  //     USER_NUM: STATISTICS_STATE.detail_data.USER_NUM,
  //     GROUP_NUM: GROUP_STATE.GROUP_NUM,
  //     GROUP_CATEGORY: GROUP_STATE.GROUP_CATEGORY,
  //     START_DATE: dateConvert(props.date.START_DATE),
  //     END_DATE: dateConvert(props.date.END_DATE),
  //     EMAIL: props.data.EMAIL,
  //   };
  //   axios
  //     .get("/schedule/statistics/participantAttendance", {
  //       params: param,
  //     })
  //     .then(res => {
  //       if (res.status === 200) {
  //         setAttendance({
  //           dataA_Cnt: res.data.DATA_A_CNT,
  //           dataA_Duration: res.data.DATA_A_DURATION,
  //           dataB_Cnt: res.data.DATA_B_CNT,
  //           dataB_Duration: res.data.DATA_B_DURATION,
  //           dataC_Cnt: res.data.DATA_C_CNT,
  //           dataC_Duration: res.data.DATA_C_DURATION,
  //         });
  //       }
  //       setChartLoading(false);
  //     })
  //     .catch(() => {
  //       setChartLoading(false);
  //     });
  // };
  /**
   * 그룹 색상 컨버터
   */
  const groupColor = color => {
    switch (color) {
      case "red":
        return "#de8799";
      case "orange":
        return "#d78e71";
      case "yellow":
        return "#cdb14c";
      case "bean":
        return "#97C12A";
      case "green":
        return "#64d483";
      case "mint":
        return "#6cd6bf";
      case "pine":
        return "#75BDD9";
      case "pink":
        return "#cb86dd";
      case "purple":
        return "#a994e1";
      case "black":
        return "#3b3f4c";
      default:
        return "#2B4AC4";
    }
  };
  /**
   * 날짜 포맷
   */
  const dateConvert = value => {
    let year = value.getFullYear();
    let month = value.getMonth() + 1;
    let days = value.getDate();

    if (month < 10) {
      month = "0" + month;
    }

    if (days < 10) {
      days = "0" + days;
    }

    const result = year + "-" + month + "-" + days;

    return result;
  };
  /**
   * 로그 리스트 날짜 컨버터
   */
  const logDateConvert = value => {
    let date = value.split(" ")[0];
    return date;
  };
  /**
   * 로그 리스트 시간 컨버터
   */
  const logTimeConvert = value => {
    if (value !== undefined && value !== null && value !== "") {
      let time = value.split(" ")[1];
      let hour = time.split(":")[0];
      let min = time.split(":")[1];

      if (hour > 12) {
        hour = "오후 " + (hour - 12);
      } else if (hour === "12") {
        hour = "오후 " + hour;
      } else if (hour < 12) {
        if (hour < 10) {
          hour = "오전 " + hour.substr(1, 2);
        } else {
          hour = "오전 " + hour;
        }
      }
      return hour + ":" + min;
    }
  };
  /**
   * 로그 리스트 업무상태 컨버터
   */
  const logWorkType = value => {
    switch (value) {
      case "WORK_TIME_IN":
        return "근 무";
      case "WORK_TIME_OUT":
        return "근무 외";
      case "WORK_WEEKEND":
        return "주 말";
      case "ATTENDANCE":
        return "출 석";
      case "TARDY":
        return "지 각";
      case "ABSENT":
        return "결 석";
      case "ATTEND":
        return "참 여";
      case "NO_ATTEND":
        return "불 참";
      default:
        break;
    }
  };
  /**
   * 참석 로그 조회
   */
  const getAttendanceLogList = useCallback(() => {
    if (props.data.EMAIL === undefined) {
      return;
    }
    setLoading(true);
    setChartLoading(true);
    let param = {
      USER_NUM: STATISTICS_STATE.detail_data.USER_NUM,
      GROUP_NUM: GROUP_STATE.GROUP_NUM,
      GROUP_CATEGORY: GROUP_STATE.GROUP_CATEGORY,
      START_DATE: dateConvert(props.date.START_DATE),
      END_DATE: dateConvert(props.date.END_DATE),
      WORK_START: props.time.start_time,
      WORK_END: props.time.end_time,
      EMAIL: props.data.EMAIL,
      PAGE_COUNT: pagingState.PAGE_COUNT,
      ROW_COUNT: pagingState.ROW_COUNT,
    };
    axios
      .get("/schedule/statistics/participantLog", {
        params: param,
      })
      .then(res => {
        if (res.status === 200) {
          const totalCnt = res.data.TOTAL;
          const resultData = res.data.LOG;
          setAttendance({
            total_Duration: res.data.TOTAL_DURATION,
            dataA_Cnt: res.data.DATA_A_CNT,
            dataA_Duration: res.data.DATA_A_DURATION,
            dataB_Cnt: res.data.DATA_B_CNT,
            dataB_Duration: res.data.DATA_B_DURATION,
            dataC_Cnt: res.data.DATA_C_CNT,
            dataC_Duration: res.data.DATA_C_DURATION,
          });
          setLogListTotal(totalCnt);
          setLogList(resultData);
          const paging_obj = {
            ROW_COUNT: pagingState.ROW_COUNT,
            PAGE_COUNT: pagingState.PAGE_COUNT,
            TOTAL: totalCnt,
          };
          setPagingState(paging_obj);
        }
        setLoading(false);
        setChartLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setChartLoading(false);
      });
  }, [props, pagingState.PAGE_COUNT, pagingState.ROW_COUNT]);
  /**
   * useEffect
   */
  useEffect(() => {
    getAttendanceLogList();
  }, [getAttendanceLogList]);

  /**
   * PDF 참석자 상세 다운로드
   */
  const downloadPdf = () => {
    const url = process.env.REACT_APP_REPORT_URL;
    let downloadFileNm =
      GROUP_STATE.GROUP_NM +
      "_참석자통계_" +
      props.data.NAME +
      "_(" +
      dateConvert(props.date.START_DATE) +
      "%7E" +
      dateConvert(props.date.END_DATE) +
      ")";
    let file = "";
    let arg =
      "USER_NUM%23" +
      STATISTICS_STATE.detail_data.USER_NUM +
      "%23GROUP_NUM%23" +
      GROUP_STATE.GROUP_NUM +
      "%23EMAIL%23" +
      props.data.EMAIL +
      "%23START_DATE%23" +
      dateConvert(props.date.START_DATE) +
      "%23END_DATE%23" +
      dateConvert(props.date.END_DATE) +
      "%23UNLOCODE%23" +
      USER_INFO.TIMEZONE +
      "%23NAME%23" +
      props.data.NAME;
    if (
      GROUP_STATE.GROUP_CATEGORY === "CD0401" ||
      GROUP_STATE.GROUP_CATEGORY === "CD0402"
    ) {
      file = "partiDetail_company.jrf";
      arg +=
        "%23WORK_START%23" +
        props.time.start_time +
        ":00" +
        "%23WORK_END%23" +
        props.time.end_time +
        ":00" +
        "%23";
    } else if (
      GROUP_STATE.GROUP_CATEGORY === "CD0403" ||
      GROUP_STATE.GROUP_CATEGORY === "CD0404" ||
      GROUP_STATE.GROUP_CATEGORY === "CD0405"
    ) {
      file = "partiDetail_education.jrf";
      arg += "%23";
    } else {
      file = "partiDetail_social.jrf";
      arg += "%23";
    }
    window.open(
      url + "?file=" + file + "&arg=" + arg + "&downloadName=" + downloadFileNm,
      "_blank",
      "width=840,height=700,status=no,titlebar=no,toolbar=no,menubar=no,location=no"
    );
  };
  return (
    <ReactModal
      isOpen={props.open} //모달 Open(Boolean)
      className="data-statistics-detail-popup" //모달 ClassName
      overlayClassName="data-statistics-detail-popup-Overlay" //모달오버레이 ClassName
      onRequestClose={() => {
        props.close();
      }}
      shouldCloseOnEsc={true}
      animationType="fade"
      transparent={true}
      closeTimeoutMS={200}
    >
      <div className="data-statistics-detail-popup-header">
        <div className="data-statistics-detail-popup-header-title">
          <div>참석자 상세 통계</div>
          <button className="data-statistics-detail-popup-header-btnPDF">
            <img src="/img/icon/data/pdf.png" alt="pdf" />
            <div
              className="data-statistics-detail-popup-header-btnPDF-text"
              onClick={downloadPdf}
            >
              PDF 다운로드
            </div>
          </button>
        </div>
        <img
          className="cursor"
          src="/img/icon/common/close.png"
          alt="close"
          onClick={props.close}
        />
      </div>
      {
        // 기업 참석자 상세 통계
        GROUP_STATE.GROUP_CATEGORY === "CD0401" ||
        GROUP_STATE.GROUP_CATEGORY === "CD0402" ? (
          <>
            <div className="data-statistics-detail-popup-info">
              <div className="data-statistics-detail-popup-groupParticipantInfo">
                <div className="data-statistics-detail-popup-groupInfo">
                  <div
                    className="data-statistics-detail-popup-groupProfile"
                    style={{
                      background: groupColor(GROUP_STATE.GROUP_COLOR),
                    }}
                  >
                    {GROUP_STATE.GROUP_NM.substr(0, 1)}
                  </div>
                  <div
                    className="data-statistics-detail-popup-groupNm"
                    style={{ color: groupColor(GROUP_STATE.GROUP_COLOR) }}
                  >
                    {GROUP_STATE.GROUP_NM}
                  </div>
                </div>
                <div className="data-statistics-detail-popup-participant-info">
                  <div className="data-statistics-detail-popup-participant-name">
                    {props.data.NAME}
                  </div>
                  <div className="data-statistics-detail-popup-participant-email">
                    {props.data.EMAIL}
                  </div>
                </div>
              </div>
              <div className="data-statistics-detail-popup-dateTimeInfo">
                <div className="data-statistics-detail-popup-dateRange">
                  <div
                    className="data-statistics-detail-popup-label"
                    style={{
                      color: groupColor(GROUP_STATE.GROUP_COLOR),
                    }}
                  >
                    | 통계 기간
                  </div>
                  <div className="data-statistics-detail-popup-data">
                    {dateConvert(props.date.START_DATE)} ~{" "}
                    {dateConvert(props.date.END_DATE)}
                  </div>
                </div>
                <div className="data-statistics-detail-popup-useTime">
                  <div
                    className="data-statistics-detail-popup-label"
                    style={{ color: groupColor(GROUP_STATE.GROUP_COLOR) }}
                  >
                    | 주말 + 근무 외 사용시간
                  </div>
                  <div className="data-statistics-detail-popup-duration">
                    {attendance.total_Duration}
                    시간
                  </div>
                </div>
              </div>
              <div className="data-statistics-detail-popup-meetingCntInfo">
                <div className="data-statistics-detail-popup-meetingCnt">
                  <div
                    className="data-statistics-detail-popup-label"
                    style={{ color: groupColor(GROUP_STATE.GROUP_COLOR) }}
                  >
                    | 총 회의 수
                  </div>
                  <div className="data-statistics-detail-popup-cnt">
                    {props.data.INVITE_TOTAL}회
                  </div>
                </div>
              </div>
            </div>

            <div className="data-statistics-detail-popup-attendance-percent">
              <div className="data-statistics-detail-popup-attendance-percent-header">
                | 참석율
              </div>
              {chartLoading ? (
                <div className="data-statistics-detail-popup-attendance-percent-emptyBody">
                  <Loading />
                </div>
              ) : (
                <div className="data-statistics-detail-popup-attendance-percent-body">
                  <div className="data-statistics-detail-popup-attendance-percent-chart">
                    {attendance.dataB_Duration + attendance.dataC_Duration ===
                    0 ? (
                      <img
                        src="/img/icon/dashboard/no_data.png"
                        alt="noData"
                        style={{ width: "88px", height: "88px" }}
                      />
                    ) : (
                      <Doughnut
                        // ref={chartRef}
                        data={{
                          labels: ["주말 사용", "근무 외 사용"],
                          datasets: [
                            {
                              data: [
                                attendance.dataB_Duration,
                                attendance.dataC_Duration,
                              ],
                              backgroundColor: ["#37B392", "#EE6C4D"],
                            },
                          ],
                        }}
                        options={{
                          maintainAspectRatio: false,
                          borderWidth: 0,
                        }}
                        plugins={[
                          {
                            beforeDraw: function (chart) {
                              let width = chart.width;
                              let height = chart.height;
                              let ctx = chart.ctx;
                              ctx.restore();
                              ctx.font =
                                "normal normal bold 14px Spoqa Han Sans Neo";
                              ctx.textBaseline = "middle";
                              let text =
                                  attendance.dataB_Duration +
                                  attendance.dataC_Duration +
                                  "h",
                                textX = Math.round(
                                  (width - ctx.measureText(text).width) / 2
                                ),
                                textY = height / 2;
                              ctx.fillText(text, textX, textY);
                              ctx.save();
                            },
                          },
                        ]}
                        width="88"
                        height="88"
                      />
                    )}
                  </div>
                  <div className="data-statistics-detail-popup-attendance-noWorkTimeInfo">
                    <div className="data-statistics-detail-popup-attendance-noWorkTimeInfo-label">
                      총 사용 시간 <br />
                      (주말+근무 외)
                    </div>
                    <div className="data-statistics-detail-popup-attendance-noWorkTimeInfo-value">
                      {attendance.total_Duration}
                      시간
                    </div>
                  </div>
                  <div className="data-statistics-detail-popup-attendance-workTimeInfo">
                    <div className="data-statistics-detail-popup-attendance-workTimeInfo-workTime">
                      <div className="data-statistics-detail-popup-attendance-workTimeInfo-workTime-label">
                        <div className="data-statistics-detail-popup-attendance-workTimeInfo-workTime-box" />
                        근무시간 사용
                      </div>
                      <div className="data-statistics-detail-popup-attendance-workTimeInfo-workTime-value">
                        {attendance.dataA_Cnt +
                          "회, " +
                          attendance.dataA_Duration +
                          "시간"}
                      </div>
                    </div>
                    <div className="data-statistics-detail-popup-attendance-workTimeInfo-weekWorkTime">
                      <div className="data-statistics-detail-popup-attendance-workTimeInfo-weekWorkTime-label">
                        <div className="data-statistics-detail-popup-attendance-workTimeInfo-weekWorkTime-box" />
                        주말 사용
                      </div>
                      <div className="data-statistics-detail-popup-attendance-workTimeInfo-weekWorkTime-value">
                        {attendance.dataB_Cnt +
                          "회, " +
                          attendance.dataB_Duration +
                          "시간"}
                      </div>
                    </div>
                    <div className="data-statistics-detail-popup-attendance-workTimeInfo-noWorkTime">
                      <div className="data-statistics-detail-popup-attendance-workTimeInfo-noWorkTime-label">
                        <div className="data-statistics-detail-popup-attendance-workTimeInfo-noWorkTime-box" />
                        근무 외 사용
                      </div>
                      <div className="data-statistics-detail-popup-attendance-workTimeInfo-noWorkTime-value">
                        {attendance.dataC_Cnt +
                          "회, " +
                          attendance.dataC_Duration +
                          "시간"}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="data-statistics-detail-popup-attendance-log">
              <div className="data-statistics-detail-popup-attendance-log-header">
                <div>| 참석 로그</div>
                <DataStatisticsParticipantLogPaging
                  pagingState={pagingState}
                  setPagingState={setPagingState}
                />
              </div>
              {loading ? (
                <div className="data-statistics-detail-popup-attendance-log-body-empty">
                  <Loading />
                </div>
              ) : (
                <div className="data-statistics-detail-popup-attendance-log-body">
                  <table className="data-statistics-detail-popup-attendance-log-table">
                    <colgroup>
                      <col width="126px" />
                      <col width="184px" />
                      <col width="88px" />
                      <col width="88px" />
                      <col width="96px" />
                      <col width="72px" />
                    </colgroup>
                    <thead>
                      <th className="data-statistics-detail-popup-attendance-log-table-left">
                        회의 날짜
                      </th>
                      <th className="data-statistics-detail-popup-attendance-log-table-left">
                        회의 제목
                      </th>
                      <th className="data-statistics-detail-popup-attendance-log-table-center">
                        입장시간
                      </th>
                      <th className="data-statistics-detail-popup-attendance-log-table-center">
                        퇴장시간
                      </th>
                      <th className="data-statistics-detail-popup-attendance-log-table-center">
                        총 참여 시간
                      </th>
                      <th className="data-statistics-detail-popup-attendance-log-table-center">
                        업무상태
                      </th>
                    </thead>
                    <tbody>
                      {logList.map((data, index) => (
                        <tr key={index}>
                          <td className="data-statistics-detail-popup-attendance-log-table-left">
                            {logDateConvert(data.START_TIME)}
                          </td>
                          <td
                            className="data-statistics-detail-popup-attendance-log-table-title"
                            title={data.TITLE}
                          >
                            {data.TITLE}
                          </td>
                          <td className="data-statistics-detail-popup-attendance-log-table-center">
                            {data.WORK_TYPE === "NO_ATTEND"
                              ? "-"
                              : logTimeConvert(data.START_TIME)}
                          </td>
                          <td className="data-statistics-detail-popup-attendance-log-table-center">
                            {data.WORK_TYPE === "NO_ATTEND"
                              ? "-"
                              : logTimeConvert(data.END_TIME)}
                          </td>
                          <td className="data-statistics-detail-popup-attendance-log-table-center">
                            {data.WORK_TYPE === "NO_ATTEND"
                              ? "-"
                              : data.DURATION + " / " + data.HISTORY_DURATION}
                          </td>
                          <td
                            className={
                              "data-statistics-detail-popup-attendance-log-table-center data-statistics-detail-popup-attendance-type-" +
                              data.WORK_TYPE
                            }
                          >
                            {logWorkType(data.WORK_TYPE)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </>
        ) : // 교육 참석자 상세 통계
        GROUP_STATE.GROUP_CATEGORY === "CD0403" ||
          GROUP_STATE.GROUP_CATEGORY === "CD0404" ||
          GROUP_STATE.GROUP_CATEGORY === "CD0405" ? (
          <>
            <div className="data-statistics-detail-popup-info">
              <div className="data-statistics-detail-popup-groupParticipantInfo">
                <div className="data-statistics-detail-popup-groupInfo">
                  <div
                    className="data-statistics-detail-popup-groupProfile"
                    style={{
                      background: groupColor(GROUP_STATE.GROUP_COLOR),
                    }}
                  >
                    {GROUP_STATE.GROUP_NM.substr(0, 1)}
                  </div>
                  <div
                    className="data-statistics-detail-popup-groupNm"
                    style={{ color: groupColor(GROUP_STATE.GROUP_COLOR) }}
                  >
                    {GROUP_STATE.GROUP_NM}
                  </div>
                </div>
              </div>
              <div className="data-statistics-detail-popup-dateTimeInfo">
                <div className="data-statistics-detail-popup-dateRange">
                  <div
                    className="data-statistics-detail-popup-label"
                    style={{
                      color: groupColor(GROUP_STATE.GROUP_COLOR),
                    }}
                  >
                    | 참석자
                  </div>
                  <div className="data-statistics-detail-popup-data">
                    {props.data.NAME + "   " + props.data.EMAIL}
                  </div>
                </div>
                <div className="data-statistics-detail-popup-useTime">
                  <div
                    className="data-statistics-detail-popup-label"
                    style={{ color: groupColor(GROUP_STATE.GROUP_COLOR) }}
                  >
                    | 통계 기간
                  </div>
                  <div className="data-statistics-detail-popup-duration">
                    {dateConvert(props.date.START_DATE)} ~{" "}
                    {dateConvert(props.date.END_DATE)}
                  </div>
                </div>
              </div>
              <div className="data-statistics-detail-popup-eduAttendInfo">
                <div className="data-statistics-detail-popup-edu-total">
                  <div
                    className="data-statistics-detail-popup-label"
                    style={{ color: groupColor(GROUP_STATE.GROUP_COLOR) }}
                  >
                    | 총 강의 수
                  </div>
                  <div className="data-statistics-detail-popup-cnt">
                    {props.data.INVITE_TOTAL}회
                  </div>
                </div>
                <div className="data-statistics-detail-popup-edu-attend">
                  <div
                    className="data-statistics-detail-popup-label"
                    style={{ color: groupColor(GROUP_STATE.GROUP_COLOR) }}
                  >
                    | 출석율
                  </div>
                  <div className="data-statistics-detail-popup-cnt">
                    {attendance.dataA_Cnt === 0 &&
                    attendance.dataB_Cnt === 0 &&
                    attendance.dataC_Cnt === 0
                      ? "0%"
                      : (
                          ((attendance.dataA_Cnt + attendance.dataB_Cnt) /
                            (attendance.dataA_Cnt +
                              attendance.dataB_Cnt +
                              attendance.dataC_Cnt)) *
                          100
                        ).toFixed(0) + "%"}
                  </div>
                </div>
              </div>
            </div>

            <div className="data-statistics-detail-popup-attendance-percent">
              <div className="data-statistics-detail-popup-attendance-percent-header">
                | 참석율
              </div>
              {chartLoading ? (
                <div className="data-statistics-detail-popup-attendance-percent-emptyBody">
                  <Loading />
                </div>
              ) : (
                <div className="data-statistics-detail-popup-attendance-percent-body">
                  <div className="data-statistics-detail-popup-attendance-percent-chart">
                    {attendance.dataA_Cnt +
                      attendance.dataB_Cnt +
                      attendance.dataC_Cnt ===
                    0 ? (
                      <img
                        src="/img/icon/dashboard/no_data.png"
                        alt="noData"
                        style={{ width: "88px", height: "88px" }}
                      />
                    ) : (
                      <Doughnut
                        // ref={chartRef}
                        data={{
                          labels: ["출석", "지각", "결석"],
                          datasets: [
                            {
                              data: [
                                attendance.dataA_Cnt,
                                attendance.dataB_Cnt,
                                attendance.dataC_Cnt,
                              ],
                              backgroundColor: [
                                "#2993bc",
                                "#efac00",
                                "#ee6c4d",
                              ],
                            },
                          ],
                        }}
                        options={{
                          maintainAspectRatio: false,
                          borderWidth: 0,
                        }}
                        plugins={[
                          {
                            beforeDraw: function (chart) {
                              let width = chart.width;
                              let height = chart.height;
                              let ctx = chart.ctx;
                              ctx.restore();
                              ctx.font =
                                "normal normal bold 14px Spoqa Han Sans Neo";
                              ctx.textBaseline = "middle";
                              let text =
                                  (
                                    ((attendance.dataA_Cnt +
                                      attendance.dataB_Cnt) /
                                      (attendance.dataA_Cnt +
                                        attendance.dataB_Cnt +
                                        attendance.dataC_Cnt)) *
                                    100
                                  ).toFixed(0) + "%",
                                textX = Math.round(
                                  (width - ctx.measureText(text).width) / 2
                                ),
                                textY = height / 2;
                              ctx.fillText(text, textX, textY);
                              ctx.save();
                            },
                          },
                        ]}
                        width="88"
                        height="88"
                      />
                    )}
                  </div>
                  <div className="data-statistics-detail-popup-attendance-noWorkTimeInfo">
                    <div className="data-statistics-detail-popup-attendance-noWorkTimeInfo-label">
                      전체 출석율
                    </div>
                    <div className="data-statistics-detail-popup-attendance-noWorkTimeInfo-value">
                      {attendance.dataA_Cnt === 0 &&
                      attendance.dataB_Cnt === 0 &&
                      attendance.dataC_Cnt === 0
                        ? "0%"
                        : (
                            ((attendance.dataA_Cnt + attendance.dataB_Cnt) /
                              (attendance.dataA_Cnt +
                                attendance.dataB_Cnt +
                                attendance.dataC_Cnt)) *
                            100
                          ).toFixed(0) + "%"}
                    </div>
                  </div>
                  <div className="data-statistics-detail-popup-attendance-eduInfo">
                    <div className="data-statistics-detail-popup-attendance-eduInfo-attendance">
                      <div className="data-statistics-detail-popup-attendance-eduInfo-attendance-label">
                        <div className="data-statistics-detail-popup-attendance-eduInfo-attendance-box" />
                        출석
                      </div>
                      <div className="data-statistics-detail-popup-attendance-eduInfo-attendance-value">
                        {attendance.dataA_Cnt === 0 &&
                        attendance.dataB_Cnt === 0 &&
                        attendance.dataC_Cnt === 0
                          ? "0회, 0%"
                          : attendance.dataA_Cnt +
                            "회, " +
                            (
                              (attendance.dataA_Cnt /
                                (attendance.dataA_Cnt +
                                  attendance.dataB_Cnt +
                                  attendance.dataC_Cnt)) *
                              100
                            ).toFixed(0) +
                            "%"}
                      </div>
                    </div>
                    <div className="data-statistics-detail-popup-attendance-eduInfo-tardy">
                      <div className="data-statistics-detail-popup-attendance-eduInfo-tardy-label">
                        <div className="data-statistics-detail-popup-attendance-eduInfo-tardy-box" />
                        지각
                      </div>
                      <div className="data-statistics-detail-popup-attendance-eduInfo-tardy-value">
                        {attendance.dataA_Cnt === 0 &&
                        attendance.dataB_Cnt === 0 &&
                        attendance.dataC_Cnt === 0
                          ? "0회, 0%"
                          : attendance.dataB_Cnt +
                            "회, " +
                            (
                              (attendance.dataB_Cnt /
                                (attendance.dataA_Cnt +
                                  attendance.dataB_Cnt +
                                  attendance.dataC_Cnt)) *
                              100
                            ).toFixed(0) +
                            "%"}
                      </div>
                    </div>
                    <div className="data-statistics-detail-popup-attendance-eduInfo-absent">
                      <div className="data-statistics-detail-popup-attendance-eduInfo-absent-label">
                        <div className="data-statistics-detail-popup-attendance-eduInfo-absent-box" />
                        결석
                      </div>
                      <div className="data-statistics-detail-popup-attendance-eduInfo-absent-value">
                        {attendance.dataA_Cnt === 0 &&
                        attendance.dataB_Cnt === 0 &&
                        attendance.dataC_Cnt === 0
                          ? "0회, 0%"
                          : attendance.dataC_Cnt +
                            "회, " +
                            (
                              (attendance.dataC_Cnt /
                                (attendance.dataA_Cnt +
                                  attendance.dataB_Cnt +
                                  attendance.dataC_Cnt)) *
                              100
                            ).toFixed(0) +
                            "%"}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="data-statistics-detail-popup-attendance-log">
              <div className="data-statistics-detail-popup-attendance-log-header">
                <div>| 참석 로그</div>
                <DataStatisticsParticipantLogPaging
                  pagingState={pagingState}
                  setPagingState={setPagingState}
                />
              </div>
              {loading ? (
                <div className="data-statistics-detail-popup-attendance-log-body-empty">
                  <Loading />
                </div>
              ) : (
                <div className="data-statistics-detail-popup-attendance-log-body">
                  <table className="data-statistics-detail-popup-attendance-log-table">
                    <colgroup>
                      <col width="120px" />
                      <col width="184px" />
                      <col width="88px" />
                      <col width="88px" />
                      <col width="64px" />
                      <col width="96px" />
                      <col width="72px" />
                    </colgroup>
                    <thead>
                      <th className="data-statistics-detail-popup-attendance-log-table-left">
                        강의 날짜
                      </th>
                      <th className="data-statistics-detail-popup-attendance-log-table-left">
                        강의 제목
                      </th>
                      <th className="data-statistics-detail-popup-attendance-log-table-center">
                        입장시간
                      </th>
                      <th className="data-statistics-detail-popup-attendance-log-table-center">
                        퇴장시간
                      </th>
                      <th className="data-statistics-detail-popup-attendance-log-table-center">
                        재입장
                      </th>
                      <th className="data-statistics-detail-popup-attendance-log-table-center">
                        총 참여 시간
                      </th>
                      <th className="data-statistics-detail-popup-attendance-log-table-center">
                        출석 여부
                      </th>
                    </thead>
                    <tbody>
                      {logList.map((data, index) => (
                        <tr key={index}>
                          <td className="data-statistics-detail-popup-attendance-log-table-left">
                            {logDateConvert(data.START_TIME)}
                          </td>
                          <td
                            className="data-statistics-detail-popup-attendance-log-table-title"
                            title={data.TITLE}
                          >
                            {data.TITLE}
                          </td>
                          <td className="data-statistics-detail-popup-attendance-log-table-center">
                            {data.ATTEND_TYPE === "ABSENT"
                              ? "-"
                              : logTimeConvert(data.START_TIME)}
                          </td>
                          <td className="data-statistics-detail-popup-attendance-log-table-center">
                            {data.ATTEND_TYPE === "ABSENT"
                              ? "-"
                              : logTimeConvert(data.END_TIME)}
                          </td>
                          <td className="data-statistics-detail-popup-attendance-log-table-center">
                            {data.ATTEND_TYPE === "ABSENT"
                              ? "-"
                              : data.ENTER_CNT + "회"}
                          </td>
                          <td className="data-statistics-detail-popup-attendance-log-table-center">
                            {data.ATTEND_TYPE === "ABSENT"
                              ? "-"
                              : data.DURATION + " / " + data.HISTORY_DURATION}
                          </td>
                          <td
                            className={
                              "data-statistics-detail-popup-attendance-log-table-center data-statistics-detail-popup-attendance-type-" +
                              data.ATTEND_TYPE
                            }
                          >
                            {logWorkType(data.ATTEND_TYPE)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </>
        ) : (
          // 모임 참석자 상세 통계
          <>
            <div className="data-statistics-detail-popup-info">
              <div className="data-statistics-detail-popup-groupParticipantInfo">
                <div className="data-statistics-detail-popup-groupInfo">
                  <div
                    className="data-statistics-detail-popup-groupProfile"
                    style={{
                      background: groupColor(GROUP_STATE.GROUP_COLOR),
                    }}
                  >
                    {GROUP_STATE.GROUP_NM.substr(0, 1)}
                  </div>
                  <div
                    className="data-statistics-detail-popup-groupNm"
                    style={{ color: groupColor(GROUP_STATE.GROUP_COLOR) }}
                  >
                    {GROUP_STATE.GROUP_NM}
                  </div>
                </div>
                <div className="data-statistics-detail-popup-participant-info">
                  <div className="data-statistics-detail-popup-participant-name">
                    {props.data.NAME}
                  </div>
                  <div className="data-statistics-detail-popup-participant-email">
                    {props.data.EMAIL}
                  </div>
                </div>
              </div>
              <div className="data-statistics-detail-popup-dateTimeInfo">
                <div className="data-statistics-detail-popup-dateRange">
                  <div
                    className="data-statistics-detail-popup-label"
                    style={{
                      color: groupColor(GROUP_STATE.GROUP_COLOR),
                    }}
                  >
                    | 통계 기간
                  </div>
                  <div className="data-statistics-detail-popup-data">
                    {dateConvert(props.date.START_DATE)} ~{" "}
                    {dateConvert(props.date.END_DATE)}
                  </div>
                </div>
                <div className="data-statistics-detail-popup-useTime">
                  <div
                    className="data-statistics-detail-popup-label"
                    style={{ color: groupColor(GROUP_STATE.GROUP_COLOR) }}
                  >
                    | 총 모임 시간
                  </div>
                  <div className="data-statistics-detail-popup-duration">
                    {attendance.total_Duration}
                    시간
                  </div>
                </div>
              </div>
              <div className="data-statistics-detail-popup-meetingCntInfo">
                <div className="data-statistics-detail-popup-meetingCnt">
                  <div
                    className="data-statistics-detail-popup-label"
                    style={{ color: groupColor(GROUP_STATE.GROUP_COLOR) }}
                  >
                    | 참석율
                  </div>
                  <div className="data-statistics-detail-popup-cnt">
                    {attendance.dataA_Cnt === 0 && attendance.dataB_Cnt === 0
                      ? "0%"
                      : (
                          (attendance.dataA_Cnt /
                            (attendance.dataA_Cnt + attendance.dataB_Cnt)) *
                          100
                        ).toFixed(0) + "%"}
                  </div>
                </div>
              </div>
            </div>
            <div className="data-statistics-detail-popup-attendance-percent">
              <div className="data-statistics-detail-popup-attendance-percent-header">
                | 참석율
              </div>
              {chartLoading ? (
                <div className="data-statistics-detail-popup-attendance-percent-emptyBody">
                  <Loading />
                </div>
              ) : (
                <div className="data-statistics-detail-popup-attendance-percent-body">
                  <div className="data-statistics-detail-popup-attendance-percent-chart">
                    {attendance.dataA_Cnt + attendance.dataB_Cnt === 0 ? (
                      <img
                        src="/img/icon/dashboard/no_data.png"
                        alt="noData"
                        style={{ width: "88px", height: "88px" }}
                      />
                    ) : (
                      <Doughnut
                        // ref={chartRef}
                        data={{
                          labels: ["참여", "불참"],
                          datasets: [
                            {
                              data: [
                                attendance.dataA_Cnt,
                                attendance.dataB_Cnt,
                              ],
                              backgroundColor: ["#2B4AC4", "#C63353"],
                            },
                          ],
                        }}
                        options={{
                          maintainAspectRatio: false,
                          borderWidth: 0,
                        }}
                        plugins={[
                          {
                            beforeDraw: function (chart) {
                              let width = chart.width;
                              let height = chart.height;
                              let ctx = chart.ctx;
                              ctx.restore();
                              ctx.font =
                                "normal normal bold 14px Spoqa Han Sans Neo";
                              ctx.textBaseline = "middle";
                              let text =
                                  (
                                    (attendance.dataA_Cnt /
                                      (attendance.dataA_Cnt +
                                        attendance.dataB_Cnt)) *
                                    100
                                  ).toFixed(0) + "%",
                                textX = Math.round(
                                  (width - ctx.measureText(text).width) / 2
                                ),
                                textY = height / 2;
                              ctx.fillText(text, textX, textY);
                              ctx.save();
                            },
                          },
                        ]}
                        width="88"
                        height="88"
                      />
                    )}
                  </div>
                  <div className="data-statistics-detail-popup-attendance-socialInfo">
                    <div className="data-statistics-detail-popup-attendance-socialInfo-attend">
                      <div className="data-statistics-detail-popup-attendance-socialInfo-attend-label">
                        <div className="data-statistics-detail-popup-attendance-socialInfo-attend-box" />
                        참여
                      </div>
                      <div className="data-statistics-detail-popup-attendance-socialInfo-attend-value">
                        {attendance.dataA_Cnt === 0 &&
                        attendance.dataB_Cnt === 0
                          ? "0회, 0%"
                          : attendance.dataA_Cnt +
                            "회, " +
                            (
                              (attendance.dataA_Cnt /
                                (attendance.dataA_Cnt + attendance.dataB_Cnt)) *
                              100
                            ).toFixed(0) +
                            "%"}
                      </div>
                    </div>
                    <div className="data-statistics-detail-popup-attendance-socialInfo-noAttend">
                      <div className="data-statistics-detail-popup-attendance-socialInfo-noAttend-label">
                        <div className="data-statistics-detail-popup-attendance-socialInfo-noAttend-box" />
                        불참
                      </div>
                      <div className="data-statistics-detail-popup-attendance-socialInfo-noAttend-value">
                        {attendance.dataA_Cnt === 0 &&
                        attendance.dataB_Cnt === 0
                          ? "0회, 0%"
                          : attendance.dataB_Cnt +
                            "회, " +
                            (
                              (attendance.dataB_Cnt /
                                (attendance.dataA_Cnt + attendance.dataB_Cnt)) *
                              100
                            ).toFixed(0) +
                            "%"}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="data-statistics-detail-popup-attendance-log">
              <div className="data-statistics-detail-popup-attendance-log-header">
                <div>| 참석 로그</div>
                <DataStatisticsParticipantLogPaging
                  pagingState={pagingState}
                  setPagingState={setPagingState}
                />
              </div>
              {loading ? (
                <div className="data-statistics-detail-popup-attendance-log-body-empty">
                  <Loading />
                </div>
              ) : (
                <div className="data-statistics-detail-popup-attendance-log-body">
                  <table className="data-statistics-detail-popup-attendance-log-table">
                    <colgroup>
                      <col width="126px" />
                      <col width="184px" />
                      <col width="88px" />
                      <col width="88px" />
                      <col width="96px" />
                      <col width="72px" />
                    </colgroup>
                    <thead>
                      <th className="data-statistics-detail-popup-attendance-log-table-left">
                        모임 날짜
                      </th>
                      <th className="data-statistics-detail-popup-attendance-log-table-left">
                        모임 제목
                      </th>
                      <th className="data-statistics-detail-popup-attendance-log-table-center">
                        입장시간
                      </th>
                      <th className="data-statistics-detail-popup-attendance-log-table-center">
                        퇴장시간
                      </th>
                      <th className="data-statistics-detail-popup-attendance-log-table-center">
                        총 참여 시간
                      </th>
                      <th className="data-statistics-detail-popup-attendance-log-table-center">
                        참석 여부
                      </th>
                    </thead>
                    <tbody>
                      {logList.map((data, index) => (
                        <tr key={index}>
                          <td className="data-statistics-detail-popup-attendance-log-table-left">
                            {logDateConvert(data.START_TIME)}
                          </td>
                          <td
                            className="data-statistics-detail-popup-attendance-log-table-title"
                            title={data.TITLE}
                          >
                            {data.TITLE}
                          </td>
                          <td className="data-statistics-detail-popup-attendance-log-table-center">
                            {data.ATTEND_TYPE === "NO_ATTEND"
                              ? "-"
                              : logTimeConvert(data.START_TIME)}
                          </td>
                          <td className="data-statistics-detail-popup-attendance-log-table-center">
                            {data.ATTEND_TYPE === "NO_ATTEND"
                              ? "-"
                              : logTimeConvert(data.END_TIME)}
                          </td>
                          <td className="data-statistics-detail-popup-attendance-log-table-center">
                            {data.ATTEND_TYPE === "NO_ATTEND"
                              ? "-"
                              : data.DURATION + " / " + data.HISTORY_DURATION}
                          </td>
                          <td
                            className={
                              "data-statistics-detail-popup-attendance-log-table-center data-statistics-detail-popup-attendance-type-" +
                              data.ATTEND_TYPE
                            }
                          >
                            {logWorkType(data.ATTEND_TYPE)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </>
        )
      }
    </ReactModal>
  );
}

export default DataStatisticsDetailPopup;
