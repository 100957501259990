import React from 'react'
import { useHistory } from 'react-router-dom';
/**
 * Version : 1.0
 * 파일명 : ImminentLicenseAlram.js
 * 작성일자 : 2021-12-16
 * 작성자 : 권도훈
 * 설명 : 회의이용권 기한 만료 임박 알람
 * 수정일자 : 2022-02-03
 * 수정자 : 권도훈
 * 수정내역 : history push로 변경
*/
function ImminentLicenseAlram(props) {
    /**
     * history
     */
    const history = useHistory();
    /**
     * 내용 포맷
     */
    const contentConvert = (data) => {
        const split = data.split("%SPLIT%");

        return (
            <div>
                <div>D-{split[0]}</div>
                <div>[ {split[1]} ] 회의이용권 종료 {split[0]}일 전 입니다.</div>
            </div>
        )
    }
    return (
        <div
            className="alarm_Item cursor"
            key={props.data.ALRAM_NUM}
            onClick={() => {
                history.push({
                    pathname: "/app/home/profile"
                });
                // window.location.href = "/app/home/profile";
            }}
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: "24px",
                    color: props.data.READ_YN === "Y" ? "grey" : "brown"
                }}
            >
                <div className="font-bold">
                    마감 임박 !!
                </div>

                <div
                    style={{
                        fontSize: "14px"
                    }}
                >
                    {props.date}
                </div>
            </div>


            <div
                style={{
                    marginTop: "24px",
                    marginBottom: "24px",
                    color: props.data.READ_YN === "Y" ? "grey" : "black",
                }}
                className="m-0"
            >
                {
                    contentConvert(props.data.ALRAM_CONTENT)
                }
            </div>
        </div>
    )
}

export default ImminentLicenseAlram
