/**
 * Import List
 */
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { openAlert, closeAlert } from "actions/index";
import { useDispatch } from "react-redux";
/**
 * Version : 1.0
 * 파일명 : checkCode.js
 * 작성일자 : 2021-09-30
 * 작성자 : 권도훈
 * 설명 : 인증코드 체크
 * 수정일자 : 2022-02-09
 * 수정자 : 권도훈
 * 수정내역 : UI 수정
 */
function CheckCode(props) {
  /**
   * DISPATCH
   */
  const dispatch = useDispatch();
  /**
   * STATE
   */
  const [count, setCount] = useState(60);
  const countRef = useRef(60);
  /**
   * TIMER
   */
  useEffect(() => {
    if (props.state) {
      const loop = setInterval(() => {
        countRef.current -= 1;

        if (countRef.current === 0) {
          clearInterval(loop);
          window.location.href = "/";
        } else {
          setCount(countRef.current);
        }
      }, 1000);
    }
  }, [props.state]);
  /**
   * 인증 메일 재전송
   */
  const reSendAuthMail = () => {
    countRef.current = 60;

    let params = {
      type: "UPDATE",
      to: props.mail,
    };
    console.log(props.groupInviteCode);
    if (props.groupInviteCode !== "") {
      params = {
        ...params,
        code: props.groupInviteCode,
      };
    }
    axios
      .post("/schedule/sendAuthMail", params)
      .then(() => {
        const obj = {
          TEXT: "발송했습니다. 메일함을 확인해주세요!",
          submitEventHandler: () => {
            dispatch(closeAlert());
          },
        };
        dispatch(openAlert(obj));
      })
      .catch(() => {
        const obj = {
          TEXT: "메일발송에 오류가 발생했습니다.",
          submitEventHandler: () => {
            dispatch(closeAlert());
          },
        };
        dispatch(openAlert(obj));
      });
  };

  return (
    <div
      style={{
        height: "100%",
        position: "absolute",
        width: "100%",
        backgroundImage: "url(/img/login_background.png)",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <section
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          className="cursor"
          src="/img/logo.png"
          alt="logo_title"
          onClick={() => (window.location.href = "/")}
        />

        <div
          style={{
            color: "#2B4AC4",
            fontSize: "32px",
            fontWeight: "bold",
            marginTop: "16px",
            marginBottom: "24px",
          }}
        >
          간단한 인증으로 SoBridge 사용하기
        </div>

        <div
          style={{
            color: "#2B4AC4",
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          몇 가지 절차만 밟으면 SoBridge을 사용하실 수 있습니다.
        </div>

        <img
          alt="STEP02"
          src="/img/icon/makeAccount/step2.png"
          style={{
            margin: "40px 0",
          }}
        />

        <div
          style={{
            fontSize: "16px",
            fontWeight: "bold",
            color: "#8B5FBF",
            textAlign: "center",
          }}
        >
          <div>인증 메일이 전송되었습니다!</div>

          <div>메일을 확인하시고 회원가입을 진행해주세요.</div>
        </div>

        <button
          className="cursor"
          style={{
            width: "184px",
            height: "48px",
            background: "#FFFFFF",
            boxShadow: "0px 5px 10px #00000029",
            border: "1px solid #8B5FBF",
            borderRadius: "14px",
            color: "#8B5FBF",
            font: "normal normal bold 14px Spoqa Han Sans Neo",
            marginTop: "34px",
            marginBottom: "40px",
          }}
          onClick={() => reSendAuthMail()}
        >
          인증코드 다시 받기
        </button>

        <div
          style={{
            color: "#666B82",
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          현재 페이지는 {count}초 후에 메인으로 돌아갑니다.
        </div>
      </section>
    </div>
  );
}

export default CheckCode;
