import React, { useState } from 'react'
import ReactModal from "react-modal";
import axios from "axios";
import { useHistory } from 'react-router-dom';
/**
 * Version : 1.0
 * 파일명 : IsOutstandingPopup.js
 * 작성일자 : 2022-04-25
 * 작성자 : 권도훈
 * 설명 : 미결제 알림 팝업
 */
function IsOutstandingPopup(props) {
    const history = useHistory();

    const [isShow, setIsShow] = useState(true);

    const updateReadPopup = () => {
        const config = {
            params: {
                OUTSTANDING_YN: "Y"
            }
        }

        axios.post("/payment/outstanding/popup", null, config)
    }

    ReactModal.setAppElement("body");
    return (
        <ReactModal
            isOpen={isShow} //모달 Open(Boolean)
            className="pf-pay-fail-popup" //모달 ClassName
            overlayClassName="NewAlertOverlay" //모달오버레이 ClassName
            animationType="fade"
            transparent={true}
            closeTimeoutMS={200}
        >
            <img
                className='pf-pay-fail-close cursor'
                src="/img/icon/payment/close.png"
                alt=''
                onClick={() => {
                    updateReadPopup();
                    setIsShow(false);
                }}
            />
            <div className='pf-pay-fail-popup-inner'>
                <img
                    src="/img/icon/payment/interruption.png"
                    alt=''
                />

                <div className='pf-pay-fail-popup-title'>
                    구독 결제 실패
                </div>

                <div className='pf-pay-fail-popup-comment'>
                    <div>
                        <font>
                            {props.expireDate}
                        </font>
                        까지 결제하지 않을 경우,
                    </div>

                    <div>
                        이용권 구독이 자동 해지됩니다.
                    </div>
                </div>

                <div className='pf-pay-fail-popup-comment red'>
                    <div>
                        *모든 데이터들이 삭제될 수 있습니다.
                    </div>
                    <div>
                        기간 안에 재결제 해주시기 바랍니다!
                    </div>
                </div>

                <button
                    className='pf-pay-fail-popup-btn cursor'
                    onClick={() => {
                        updateReadPopup();
                        setIsShow(false);
                        history.push("/app/home/profile");
                    }}
                >
                    결제 하러 가기
                </button>
            </div>
        </ReactModal>
    )
}

export default IsOutstandingPopup
