import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
/**
 * Version : 1.0
 * 파일명 : ProductList.js
 * 작성일자 : 2022-04-05
 * 작성자 : 권도훈
 * 설명 : 상품 선택 리스트
 * 수정일자 : 2022-05-02
 * 수정자 : 권도훈
 * 수정내역 : 금액 이미지 교체
 */
function ProductList(props) {
  const [init, setInit] = useState(true);
  const [product, setProduct] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({
    NAME: "",
    AMOUNT: 0,
    VAT: 0,
    COUNT: 1,
    CODE: "",
  });
  /**
   * 상품 리스트 조회
   */
  const getProductList = useCallback(() => {
    props.setLoading(true);

    axios
      .get("/payment/product", null)
      .then(res => {
        setProduct(res.data);
        setInit(false);
        props.setLoading(false);
      })
      .catch(() => {
        setInit(false);
        props.setLoading(false);
      });
  }, [props]);
  /**
   * init
   */
  useEffect(() => {
    if (init) {
      getProductList();
    }
  }, [getProductList, init]);
  /**
   * 가격 포맷
   */
  const amountFormat = amount => {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "원";
  };
  return (
    <>
      <img src="/img/icon/payment/popup_price_20220504.png" alt="contents" />

      <nav className="py-product-list">
        {product.map((data, idx) => (
          <div
            key={idx}
            className="py-product-list-item"
            style={{
              backgroundImage: "url(/img/icon/payment/ticket_templete.png)",
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="py-product-list-item-inner">
              <div className="py-product-list-item-info">
                <section className="py-product-list-item-info-top">
                  <font className="py-product-list-item-info-title">
                    {data.PRODUCT_NAME}
                  </font>

                  <section className="py-product-list-item-info-amount">
                    <section className="py-product-list-item-info-amount-comment">
                      <font>정식 오픈 10% 할인</font>
                      <font>정가 {amountFormat(data.PRODUCT_PRICE)}</font>
                    </section>

                    <font>
                      {amountFormat(data.PRODUCT_PRICE - data.PRODUCT_DISCOUNT)}
                    </font>
                    {/* <font>
                                                {data.PRODUCT_COMMENT}
                                            </font> */}
                  </section>
                </section>

                <section className="py-product-list-item-info-bot">
                  <section className="py-product-list-item-info-coment">
                    <font>* Zoom, Webex 모두 사용</font>
                    <font>* 시간 무제한 화상회의 진행</font>
                    <font>* 다양한 그룹 생성</font>
                  </section>

                  <font className="py-product-list-item-info-paydt">
                    매월 10일 등록하신 결제 수단으로 자동 청구됩니다.
                  </font>
                </section>
              </div>

              <div className="py-product-list-btn-area">
                <div className="py-product-list-btn-area-top">
                  <div className="py-product-list-counter">
                    <div className="py-product-list-counter-input">
                      <input
                        type="text"
                        value={selectedProduct.COUNT}
                        onChange={e => {
                          setSelectedProduct({
                            ...selectedProduct,
                            COUNT: e.target.value
                              .replace(/[^0-9.]/g, "")
                              .replace(/(\..*)\./g, "$1"),
                          });
                        }}
                      />

                      <nav className="py-product-list-counter-input-btn">
                        <div
                          className="cursor"
                          onClick={() => {
                            setSelectedProduct({
                              ...selectedProduct,
                              COUNT: selectedProduct.COUNT + 1,
                            });
                          }}
                        >
                          <img src="/img/icon/payment/num_up.png" alt="up" />
                        </div>

                        <div
                          className="cursor"
                          onClick={() => {
                            if (selectedProduct.COUNT > 1) {
                              setSelectedProduct({
                                ...selectedProduct,
                                COUNT: selectedProduct.COUNT - 1,
                              });
                            }
                          }}
                        >
                          <img
                            src="/img/icon/payment/num_down.png"
                            alt="down"
                          />
                        </div>
                      </nav>
                    </div>

                    <font className="py-product-list-counter-text">개</font>
                  </div>

                  <section className="py-product-list-counter-comment">
                    <font>이용권 수에 따라</font>
                    <font>금액이 달라질 수 있습니다.</font>
                  </section>
                </div>

                <div
                  className="py-product-list-seleted-btn cursor"
                  onClick={() => {
                    props.setSelectedProduct({
                      NAME: data.PRODUCT_NAME,
                      AMOUNT: data.PRODUCT_PRICE - data.PRODUCT_DISCOUNT,
                      VAT: (data.PRODUCT_PRICE - data.PRODUCT_DISCOUNT) / 10,
                      COUNT: selectedProduct.COUNT,
                      CODE: data.PRODUCT_CODE,
                    });
                    props.setType("PAYMENT");
                    props.setIsRender(true);
                  }}
                >
                  <font>SoBridge 구독하기</font>

                  <img src="/img/icon/payment/next.png" alt="next" />
                </div>
              </div>
            </div>
          </div>
        ))}
      </nav>
    </>
  );
}

export default ProductList;
