import React, { useState, useEffect, useCallback } from "react";
import DashboardPaging from "./dashboardPaging";
import Loading from "component/tag/loading/loading";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";
/**
 * Version : 1.0
 * 파일명 : ReserveTotal.js
 * 작성일자 : 2022-02-23
 * 작성자 : 강연승
 * 설명 : 대시보드 아이템 - 완료된 회의 리스트
 * 수정일자 : 2022-06-08
 * 수정자 : 강연승
 * 수정내역 : 진행여부 항목 추가
 */
function EndMeetingList() {
  /**
   * REDUCER
   */
  const REDUCER_STATE = useSelector(state => state.groupInfoReducer);
  /**
   * 언어변환
   */
  const { t } = useTranslation();
  /**
   * STATE
   */
  const [loading, setLoading] = useState(false);
  const [endListTotal, setEndListTotal] = useState(0);
  const [endList, setEndList] = useState([]);
  const [pagingState, setPagingState] = useState({
    ROW_COUNT: 5,
    PAGE_COUNT: 0,
    TOTAL: 0,
  });
  /**
   * 회의 리스트 조회
   */
  const getMeetingEndList = useCallback(() => {
    setLoading(true);
    let param = {};
    if (REDUCER_STATE.GROUP_YN === "Y") {
      param = {
        GROUP_YN: REDUCER_STATE.GROUP_YN,
        GROUP_NUM: REDUCER_STATE.GROUP_NUM,
        HISTORY: "Y",
        CURRENTPAGE: pagingState.PAGE_COUNT,
        RECORDPERPAGE: pagingState.ROW_COUNT,
      };
    } else if (REDUCER_STATE.GROUP_YN === "N") {
      param = {
        GROUP_YN: REDUCER_STATE.GROUP_YN,
        HISTORY: "Y",
        CURRENTPAGE: pagingState.PAGE_COUNT,
        RECORDPERPAGE: pagingState.ROW_COUNT,
      };
    }
    axios
      .get("/schedule/dashboard/meetingList", {
        params: param,
      })
      .then(res => {
        if (res.status === 200) {
          const totalCnt = res.data[0].TOTAL;
          const resultData = res.data[0].SCHEDULE;
          setEndListTotal(totalCnt);
          setEndList(resultData);
          const paging_obj = {
            ROW_COUNT: pagingState.ROW_COUNT,
            PAGE_COUNT: pagingState.PAGE_COUNT,
            TOTAL: totalCnt,
          };
          setPagingState(paging_obj);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [
    REDUCER_STATE.GROUP_NUM,
    REDUCER_STATE.GROUP_YN,
    pagingState.PAGE_COUNT,
    pagingState.ROW_COUNT,
  ]);
  /**
   * useEffect
   */
  useEffect(() => {
    getMeetingEndList();
  }, [getMeetingEndList]);

  const dateFormat = dateTime => {
    let tmp = dateTime.replaceAll("-", ".");
    tmp = tmp.replace("T", " ");
    tmp = tmp.slice(0, -3);
    return tmp;
  };
  return (
    <div className="dashboardEndMeetingList">
      <div className="dashboard_item_header">
        <div>
          <img
            src="/img/icon/dashboard/complete.png"
            className="dashboardIcon"
          />
          <font className="title">
            {t(
              "group.dashboard.txt.meetingList.end.title." +
                REDUCER_STATE.GROUP_CATEGORY
            )}
          </font>
        </div>
        <font className="totalCnt">
          {t("group.dashboard.txt.meetingList.total", {
            TOTAL_CNT: endListTotal,
          })}
        </font>
      </div>
      {loading ? (
        <Loading />
      ) : endListTotal === 0 ? (
        <div className="noMeetingList">
          {t(
            "group.dashboard.txt.meetingList.end.empty." +
              REDUCER_STATE.GROUP_CATEGORY
          )}
        </div>
      ) : (
        <div className="meetingList">
          <table className="meetingList_table">
            <colgroup>
              <col width="130px" />
              <col width="130px" />
              <col width="90px" />
              <col width="160px" />
              <col width="80px" />
            </colgroup>
            <thead>
              <tr>
                <th>{t("group.dashboard.txt.meetingList.table.start")}</th>
                <th>{t("group.dashboard.txt.meetingList.table.end")}</th>
                <th style={{ textAlign: "center" }}>진행여부</th>
                <th>{t("group.dashboard.txt.meetingList.table.title")}</th>
                <th>{t("group.dashboard.txt.meetingList.table.host")}</th>
              </tr>
            </thead>
            <tbody>
              {endList.map((data, index) => (
                <tr key={index}>
                  <td>
                    {data.MEETING_YN === "N"
                      ? data.START_TIME.replaceAll("-", ".")
                      : dateFormat(data.START_TIME)}
                  </td>
                  <td>
                    {data.MEETING_YN === "N"
                      ? data.END_TIME.replaceAll("-", ".")
                      : dateFormat(data.END_TIME)}
                  </td>
                  <td style={{ textAlign: "center", paddingRight: "0px" }}>
                    {data.MEETING_YN}
                  </td>
                  <td className="td_title" title={data.TITLE}>
                    {data.TITLE}
                  </td>
                  <td className="td_hostNm" title={data.HOST_NM}>
                    {data.HOST_NM}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div
        style={{
          height: "15px",
          display: loading || endListTotal === 0 ? "none" : "flex",
          alignItems: "center",
        }}
      >
        <DashboardPaging
          pagingState={pagingState}
          setPagingState={setPagingState}
        />
      </div>
    </div>
  );
}

export default EndMeetingList;
