import React, { useState } from "react";
import "css/profilesupsuggestion.css";
import { useDispatch } from "react-redux";
import { openAlert, closeAlert } from "actions";
import axios from "axios";
import "css/alertsupmain.css";
import SupportPopupmain from "component/tag/popup/support/supportPopupmain";

/**
 * Version : 1.0
 * 파일명 : supsuggestion.js
 * 작성일자 : 2022-03-30
 * 작성자 : 정지윤
 * 설명 : 고객센터 고객의 소리 페이지
 * 수정일자 : 2022-03-31
 * 수정자 : 정지윤
 * 수정내역 : 버튼 영억 재조정
 */

function Supsuggestion() {
  let server_url = "";

  if (process.env.NODE_ENV === "development") {
    server_url =
      process.env.REACT_APP_SERVER_URL + process.env.REACT_APP_SERVER_PORT;
  } else {
    server_url = process.env.REACT_APP_SERVER_URL;
  }

  const [showModal, setShowModal] = useState(false);
  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  const dispatch = useDispatch();
  const [SuggestFile, setSuggestFile] = useState([]);

  const onChangeFile = e => {
    let tmpArr = [];
    let suggestFileTotalSize = 0;

    for (let i = 0; i < SuggestFile.length; i++) {
      suggestFileTotalSize += SuggestFile[i].size;
    }
    for (let i = 0; i < e.target.files.length; i++) {
      let sizeTotal = suggestFileTotalSize;
      if (SuggestFile.find(file => file.name === e.target.files[i].name)) {
        const obj = {
          TEXT: "이미 선택된 파일입니다.",
          submitEventHandler: () => dispatch(closeAlert()),
        };
        dispatch(openAlert(obj));
        return;
      }
      if (e.target.files[i].size > 10485760) {
        const obj = {
          TEXT: "10MB 이하의 파일을 첨부해주세요.",
          submitEventHandler: () => dispatch(closeAlert()),
        };
        dispatch(openAlert(obj));
        return;
      }
      if (sizeTotal + e.target.files[i].size > 10485760) {
        const obj = {
          TEXT: "파일 크기 합계가 10MB 이상입니다. 사이즈를 줄여주세요",
          submitEventHandler: () => dispatch(closeAlert()),
        };
        dispatch(openAlert(obj));
        return;
      }
      // if (e.target.files[i].type.replace(/\/.*/, "") !== "image") {
      //   const obj = {
      //     TEXT: "이미지 파일이 아닙니다. png, jpg, bmg, gif파일을 업로드 해주세요.",
      //     submitEventHandler: () => dispatch(closeAlert()),
      //   };
      //   dispatch(openAlert(obj));
      //   return;
      // } else {
      //   if(e.target.files[i].type.split("/")[1] !== "png" &&
      //   e.target.files[i].type.split("/")[1] !== "jpg" &&
      //   e.target.files[i].type.split("/")[1] !== "gif"&&
      //   e.target.files[i].type.split("/")[1] !== "bmp"){
      //     const obj = {
      //       TEXT: "이미지 파일이 아닙니다. png, jpg, bmp, gif파일을 업로드 해주세요.",
      //       submitEventHandler: () => dispatch(closeAlert()),
      //     };
      //     dispatch(openAlert(obj));
      //     return;
      //   }
      // }
      ////////////////////////////////////////
      suggestFileTotalSize += e.target.files[i].size;
      tmpArr.push(e.target.files[i]);
    }
    setSuggestFile(SuggestFile.concat(tmpArr));
    e.target.value = "";
  };

  const onRemoveFile = file => {
    setSuggestFile(
      SuggestFile.filter(suggestFile => suggestFile.name !== file.name)
    );
  };

  const [Suggest, setSuggest] = useState("");

  var changev = Suggest;
  let contentcv = changev.replaceAll(/(?:\r\n|\r|\n)/g, "<br />");

  const handleSuggest = e => {
    setSuggest(e.target.value);
  };

  const setdataclear = () => {
    setSuggest("");
    setSuggestFile([]);
  };

  const onClicksend = () => {
    if (Suggest.length === 0) {
      const obj = {
        TEXT: "내용을 입력해주세요.",
        submitEventHandler: () => dispatch(closeAlert()),
      };
      dispatch(openAlert(obj));
      return;
    } else {
      const formData = new FormData();

      if (Array.isArray(SuggestFile) && SuggestFile.length > 0) {
        for (let i = 0; i < SuggestFile.length; i++) {
          formData.append("files", SuggestFile[i]);
        }
      }

      let parameter = {
        SUPPORT_CONTENT: contentcv,
      };
      formData.append("params", JSON.stringify(parameter));

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      axios.post("/support/Suggest", formData, config).then(response => {
        if (response.status === 200) {
          return;
        }
      });
      setdataclear();
      openModal();
    }
  };

  return (
    <div>
      <div className="suggestionHeader">
        <div className="suggestionHeaderbold">SoBridge</div>
        <div>의 중심은 항상&nbsp;</div>
        <div className="suggestionHeaderbold">고객님</div>
        <div>입니다.</div>
      </div>

      <div className="suggestionsHeader">
        <div className="suggestionsHeader-1">
          SoBridge를 이용하면서 느끼신 불편사항이나 바라는 점을 알려주세요
        </div>
        <div className="suggestionsHeader-2">
          고객님의 소중한 의견으로 한 뼘 더 자라는 SoBridge이 되겠습니다.
        </div>
      </div>

      <div className="suggestioninputtext">
        <div className="suggestioninputtexttitle">의견 작성</div>
        <div>
          <textarea
            className="suggestioninputtextmain"
            placeholder="고객님의 소중한 의견을 입력해주세요."
            value={Suggest}
            onChange={handleSuggest}
          />
        </div>
      </div>

      <div className="suggestioninputfile">
        <div className="suggestioninputfiletitle">파일 첨부</div>

        <div className="suggestion_inputFileholder">
          <div className="suggestion_inputFileinfo">
            {Array.isArray(SuggestFile) && SuggestFile.length > 0 ? (
              <div className="fileListscrollbar">
                {SuggestFile.map(data => (
                  <div key={data.name} className="suggestion_img">
                    <font
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",

                        font: "normal normal normal 14px/18px Spoqa Han Sans Neo",
                        color: "#3E3E4F",
                      }}
                    >
                      {data.name}
                    </font>
                    <img
                      className="cursor"
                      src="/img/icon/schedule/remove.png"
                      alt="remove"
                      style={{
                        verticalAlign: "top",
                        float: "right",
                        marginRight: "8px",
                      }}
                      onClick={() => onRemoveFile(data)}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div className="inputFileplaceholder">
                10MB이내 파일만 가능합니다.
              </div>
            )}
          </div>
          <div className="inputfilebtn">
            <label htmlFor="inputFile" className="suggestionimagebtn cursor">
              등록
            </label>
            <input
              type="file"
              id="inputFile"
              className="suggestioninputfilemain"
              placeholder="등록"
              // accept="image/png, image/jpg, image/bmp, image/gif"
              style={{ display: "none" }}
              onChange={onChangeFile}
              name="file"
              multiple
            />
          </div>
        </div>
      </div>

      <div className="supsuggestionline"></div>

      <div className="suggestionfooter">
        <div>
          {" "}
          <li>
            본문 내용에 고객님의 개인정보(주민번호 등)가 포함되지 않도록
            주의해주세요
          </li>
        </div>
      </div>

      <div className="suggestionfooter-2">
        <div className="suggestionbutton cursor" onClick={() => onClicksend()}>
          보내기
        </div>
      </div>

      <SupportPopupmain open={showModal} close={closeModal}></SupportPopupmain>
    </div>
  );
}

export default Supsuggestion;
