import React, { useState } from "react";
import ReactModal from "react-modal";
import { useDispatch } from "react-redux";
import { openAlert, closeAlert } from "actions/index";
import axios from "axios";
/**
 * Version : 1.0
 * 파일명 : requireChangePw.js
 * 작성일자 : 2021-12-07
 * 작성자 : 권도훈
 * 설명 : 비밀번호 주기적 변경 팝업
 * 수정일자 : 2022-02-09
 * 수정자 : 권도훈
 * 수정내역 : UI 수정
*/
function RequireChangePw() {
    /**
     * DISPATCH
     */
    const dispatch = useDispatch();
    /**
     * POPUP
     */
    const [isPopup, setIsPopup] = useState(true);
    /**
     * body
     */
    ReactModal.setAppElement("body");
    /**
     * 비밀번호
     */
    const [password, setPassword] = useState({
        newPW: "",
        reEnterPW: ""
    });
    /**
     * 비밀번호 검사
     */
    const isValidPassword = (pw) => {
        var pattern1 = /[0-9]/;
        var pattern2 = /[a-zA-Z]/;
        var pattern3 = /[~!@\\#$%<>^&*]/;     // 원하는 특수문자 추가 제거 \ 확인

        if (!pattern1.test(pw) || !pattern2.test(pw) || !pattern3.test(pw) || pw.length < 8 || pw.length > 50) {
            return false;
        }

        return true;
    }
    /**
   * 90일 뒤 변경
   */
    const delayChangePW = () => {
        const config = {
            params: {
                REQUEST_TYPE: "DELAY",
            },
        };

        axios.put("/user/password", null, config).then(res => {
            if (res.status === 200 && res.data === true) {
                const obj = {
                    TEXT: "90일 후에 다시 알려드립니다.",
                    submitEventHandler: () => {
                        dispatch(closeAlert());
                        setIsPopup(false);
                    },
                };
                dispatch(openAlert(obj));
            } else {
                const obj = {
                    TEXT: "변경이 실패했습니다.",
                    submitEventHandler: () => {
                        dispatch(closeAlert());
                    },
                };
                dispatch(openAlert(obj));
            }
        });
    };
    /**
     * 비밀번호 변경 버튼 이벤트
     */
    const changeBtnEvent = () => {
        setIsPopup(false);

        if (password.newPW === "" || password.reEnterPW === "") {
            // alert("비밀번호가 비어있습니다.");
            const obj = {
                TEXT: "비밀번호가 비어있습니다.",
                submitEventHandler: () => {
                    dispatch(closeAlert());
                    setIsPopup(true);
                }
            };
            dispatch(openAlert(obj));
        } else if (password.newPW !== password.reEnterPW) {
            // alert("비밀번호가 일치하지 않습니다.");
            const obj = {
                TEXT: "비밀번호가 일치하지 않습니다.",
                submitEventHandler: () => {
                    dispatch(closeAlert());
                    setIsPopup(true);
                }
            };
            dispatch(openAlert(obj));
        } else if (!isValidPassword(password.newPW) || !isValidPassword(password.reEnterPW)) {
            // alert("비밀번호는 영문&숫자&특수기호 8자리 이상으로 구성하여야 합니다.");
            const obj = {
                TEXT: "비밀번호는 영문&숫자&특수기호 8자리 이상으로 구성하여야 합니다.",
                submitEventHandler: () => {
                    dispatch(closeAlert());
                    setIsPopup(true);
                }
            };
            dispatch(openAlert(obj));
        } else {
            const config = {
                params: {
                    REQUEST_TYPE: "CHANGE",
                    PASSWORD: password.newPW,
                },
            };

            axios.put("/user/password", null, config)
                .then((res) => {
                    if (res.status === 200) {
                        if (res.data === true) {
                            const obj = {
                                TEXT: "변경이 완료되었습니다.",
                                submitEventHandler: () => {
                                    dispatch(closeAlert());
                                }
                            };
                            dispatch(openAlert(obj));
                        } else {
                            // alert("변경에 실패했습니다.");
                            const obj = {
                                TEXT: "현재 비밀번호와 동일한 비밀번호로 변경할 수 없습니다.",
                                submitEventHandler: () => {
                                    dispatch(closeAlert());
                                    setIsPopup(true);
                                }
                            };
                            dispatch(openAlert(obj));
                        }
                    }
                })
                .catch(() => {
                    const obj = {
                        TEXT: "변경에 실패했습니다.",
                        submitEventHandler: () => {
                            dispatch(closeAlert());
                            setIsPopup(true);
                        }
                    };
                    dispatch(openAlert(obj));
                });
        }
    }
    /**
     * 태그
     */
    return (
        <ReactModal
            isOpen={isPopup} //모달 Open(Boolean)
            className="changePasswordAlert" //모달 ClassName
            overlayClassName="NewAlertOverlay" //모달오버레이 ClassName
            animationType="fade"
            transparent={true}
        >
            <div className="changePW_content">
                <div
                    style={{
                        fontSize: "24px",
                        fontWeight: "bold",
                        color: "#3E3E4F"
                    }}
                >
                    비밀번호 변경이 필요합니다.
                </div>

                <input
                    className="changePwInput"
                    type="password"
                    placeholder="비밀번호 입력 (8자리 이상, 영문숫자특수기호 입력)"
                    onChange={
                        e => {
                            setPassword({
                                ...password,
                                newPW: e.target.value
                            })
                        }
                    }
                    style={{
                        marginBottom: "16px",
                        marginTop: "32px"
                    }}
                />

                <input
                    className="changePwInput"
                    type="password"
                    placeholder="비밀번호 재입력"
                    onChange={
                        e => {
                            setPassword({
                                ...password,
                                reEnterPW: e.target.value
                            })
                        }
                    }
                />

                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "330px",
                        marginTop: "24px"
                    }}
                >
                    <button
                        className="changePwBtn cursor"
                        style={{
                            background: "#3E3E4F",
                            width: "200px"
                        }}
                        onClick={() => delayChangePW()}
                    >
                        90일 뒤 변경
                    </button>

                    <button
                        className="changePwBtn cursor"
                        style={{
                            background: "#2B4AC4",
                            width: "112px"
                        }}
                        onClick={() => changeBtnEvent()}
                    >
                        변경
                    </button>
                </div>

            </div>
        </ReactModal>
    )
}

export default RequireChangePw
