import React from "react";
/**
 * Version : 1.0
 * 파일명 : unavailableDay.js
 * 작성일자 : 2021-11-30
 * 작성자 : 권도훈
 * 설명 : 무료 이용 종료 팝업 내용
 * 수정일자 : 2022-01-14
 * 수정자 : 권도훈
 * 수정내역 : 문구 수정
 */
function UnavailableDay(props) {
  return (
    <div>
      <div className="freeInfo_content">
        <div className="freeInfo_title">무료 체험이 종료되었습니다.</div>

        <img
          className="freeInfo_mainIMG"
          src="/img/icon/landing/30free_end.png"
          alt="D-DAY"
        />

        <div className="freeInfo_coment_main_blue">
          30일 무료 체험 회의이용권가 종료되었습니다.
        </div>

        <div className="freeInfo_coment_sub">
          <div>30일 동안 SoBridge를 잘 사용해보셨나요?</div>
          <div>예약된 미팅이 있는 경우, 예정대로 미팅 하시려면</div>
          <div>회의이용권 구매가 필요합니다.</div>
        </div>

        <div className="freeInfo_btn_area">
          <button
            className="cursor"
            onClick={() => {
              props.updateInfo();
              props.closePopup();
            }}
          >
            알겠습니다.
          </button>
          <button
            className="cursor"
            onClick={() => {
              props.closePopup();
              props.updateInfo();
              props.goProduct();
            }}
          >
            구매하러 가기
          </button>
        </div>
      </div>
    </div>
  );
}

export default UnavailableDay;
