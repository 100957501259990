import { CircularProgress } from '@material-ui/core'
import React from 'react'
/**
 * Version : 1.0
 * 파일명 : sendPwMail.js
 * 작성일자 : 2021-12-06
 * 작성자 : 권도훈
 * 설명 : 비밀번호 변경 페이지
 * 수정일자 : 2022-02-09
 * 수정자 : 권도훈
 * 수정내역 : UI 수정
*/
function SendPwMail(props) {
    return (
        <div
            style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <img
                className="cursor"
                src="/img/logo.png"
                alt="logo_title"
                onClick={() => window.location.href = "/"}
            />

            <div
                style={{
                    fontSize: "28px",
                    fontWeight: "bold",
                    color: "#3E3E4F",
                    marginTop: "16px",
                    marginBottom: "24px"
                }}
            >
                비밀번호를 잊어버리셨나요?
            </div>

            <div
                style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#3E3E4F"
                }}
            >
                기존에 가입하신 이메일을 입력하시면, 비밀번호 변경 메일을 발송해드립니다.
            </div>

            <section
                style={{
                    width: "560px",
                    height: "240px",
                    background: "#F8F9FD",
                    borderRadius: "16px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "56px"
                }}
            >
                <input
                    placeholder='메일 주소를 입력해주세요.'
                    className='searchPwInput'
                    type="email"
                    onChange={
                        e => props.setEmail(e.target.value)
                    }
                    onKeyDown={
                        e => {
                            if (e.key === "Enter") {
                                props.sendEmail();
                            }
                        }
                    }
                />

                <button
                    style={{
                        marginTop: "16px"
                    }}
                    className='searchPwBtn cursor'
                    disabled={
                        props.email === "" || props.loading
                            ?
                            true
                            :
                            false
                    }
                    onClick={() => props.sendEmail()}
                >
                    {
                        props.loading
                            ?
                            <CircularProgress
                                style={{
                                    width: "24px",
                                    height: "24px"
                                }}
                            />
                            :
                            "비밀번호 변경 메일 받기"
                    }
                </button>
            </section>
        </div>
    )
}

export default SendPwMail
