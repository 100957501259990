import DataUseLogList from 'component/tag/table/data/dataUseLogList'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { updateGroupInfo } from "actions/index";
import DataStatistics from 'component/tag/table/data/dataStatistics';
import { useTranslation } from 'react-i18next';
/**
 * Version : 1.0
 * 파일명 : data.js
 * 작성일자 : 2021-12-28
 * 작성자 : 권도훈
 * 설명 : 사용자 데이터 페이지
 * 수정일자 : 2022-03-04
 * 수정자 : 권도훈
 * 수정내역 : 구조 수정
 */
function Data() {
    /**
     * DISPATCH
     */
    const dispatch = useDispatch();
    /**
     * 언어변환
     */
    const { t } = useTranslation();
    /**
     * SET DEFAULT STATE
     */
    useEffect(() => {
        const config = {
            GROUP_YN: "N",
            GROUP_NUM: "",
            AUTH_CD: ""
        }
        dispatch(updateGroupInfo(config));
    }, [dispatch]);
    /**
     * 태그
     */
    return (
        <div
            style={{
                height: "902px",
                width: "1484px",
            }}
        >
            <div
                style={{
                    margin: "26px 40px",
                    height: "845px",
                    width: "1404px"
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <img
                        src='/img/icon/log/data.png'
                        alt='ICON'
                    />
                    <div
                        style={{
                            fontSize: "28px",
                            fontWeight: "bold",
                            color: "#2B4AC4",
                            marginLeft: "16px"
                        }}
                    >
                        {t("my.data.txt.title")}
                    </div>
                </div>

                <div
                    style={{
                        backgroundColor: "rgba(255,255,255,0.4)",
                        height: "845px",
                        marginTop: "23px",
                        borderRadius: "12px"
                    }}
                >
                    <DataUseLogList />
                </div>
            </div>
        </div>
    )
}

export default Data
