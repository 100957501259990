/**
 * Import List
 */
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import axios from "axios";
import { useHistory, useLocation } from "react-router";
import Cookies from "universal-cookie";
import { updateFreeLicenseInfo } from "actions/index";
import { useDispatch } from "react-redux";

/**
 * Version : 1.0
 * 파일명 : login.js
 * 작성일자 : 2021-09-30
 * 작성자 : 권도훈
 * 설명 : 사용자 로그인 페이지
 * 수정일자 : 2022-03-03
 * 수정자 : 권도훈
 * 수정내역 : UI 수정
 */
function LoginAdmin() {
  //cookie
  const domain = process.env.REACT_APP_COOKIE_DOMAIN;
  const typeNm = process.env.REACT_APP_COOKIE_REQUEST_TYPE;
  const refreshNm = process.env.REACT_APP_COOKIE_REFRESH;
  const authNm = process.env.REACT_APP_COOKIE_AUTH;
  const loginTypeNm = process.env.REACT_APP_COOKIE_TYPE;
  const autoLoginNm = process.env.REACT_APP_COOKIE_AUTO_LOGIN;

  const dispatch = useDispatch();
  let params = (new URL(document.location)).searchParams;



  /**
   * useLocation
   */
  const location = useLocation();
  /**
   * code
   */
  const [code, setCode] = useState();
  const [autoCheck, setAutoCheck] = useState(false);

  /**
   * 로딩
   */
  const [loading, setLoading] = useState(false);
  /**
   * 로그인 요청 타입
   */
  const [loginType, setLoginType] = useState("");
  /**
   * history
   */
  const history = useHistory();

  var cookies = useMemo(() => {
    return new Cookies();
  }, []);



  let config = {
    params: {
      IDEN: params.get('data')
    }
  }
  const setdata = useCallback(() => {
    axios.get("/support/getlogininfo", config).then(res => {
      if (res.status === 200) {

        loginAdmin(res.data.User_ID, res.data.User_type)

      }
    })
  })
  useEffect(() => {
    setdata()
  }, [setdata])



  const loginAdmin = useCallback((id, type) => {

    setLoading(true);
    const params = {
      Request_ID: id,
      Request_Type: type
    };
    axios
      .post("/support/loginadmin", params)
      .then(res => {
        if (res.status === 200) {
          createToken(id);
        } else {
          setLoading(false);
          return false;
        }
      })
      .catch(error => {
        axios.post("/user/error", null, { headers: { error_info: error } });
      });



  }, []);





  /**
   * 사용자 로그인 여부 체크
   */
  useEffect(() => {
    const isAutoLogin = cookies.get(autoLoginNm);
    const type = cookies.get(loginTypeNm);
    const refreshToken = cookies.get(refreshNm);
    const authToken = cookies.get(authNm);

    const config = {
      params: {
        Request_Token: refreshToken,
      },
    };

    if (
      type !== undefined &&
      refreshToken !== undefined &&
      authToken !== undefined
    ) {
      setLoading(true);
      //재발급 만들어야함
      axios
        .put("/user/token", null, config)
        .then(res => {
          cookies.remove(
            authNm,
            {
              path: "/",
              domain: domain
            }
          );

          if (res.status === 200) {
            cookies.set(
              authNm,
              res.data.AUTH,
              {
                path: "/",
                maxAge: 60 * 60,
                domain: domain
              }
            );

            cookies.set(
              loginTypeNm,
              res.data.TYPE,
              {
                path: "/",
                maxAge: 60 * 60,
                domain: domain
              }
            );

            if (res.data.REFRESH !== undefined) {
              cookies.set(
                refreshNm,
                res.data.REFRESH,
                {
                  path: "/",
                  maxAge: 24 * 60 * 60 * 30,
                  domain: domain
                }
              );
            }
            cookies.remove(
              typeNm,
              {
                path: "/",
                domain: domain
              }
            );
            // checkFreeAccount();
            const config = {
              DISPLAY: true,
            };
            dispatch(updateFreeLicenseInfo(config));
            history.push("/app/home");
            // window.location.href = "/app/home";
          } else {
            setLoading(false);
            return;
          }
        })
        .catch(() => {
          setLoading(false);
          return;
        });
    } else if (isAutoLogin !== undefined) {
      if (authToken !== undefined) {
        setLoading(true);
        //재발급 만들어야함.
        axios
          .put("/user/token", null, config)
          .then(res => {
            cookies.remove(
              authNm,
              {
                path: "/",
                domain: domain
              }
            );

            if (res.status === 200) {
              cookies.set(
                authNm,
                res.data.AUTH,
                {
                  path: "/",
                  maxAge: 60 * 60,
                  domain: domain
                }
              );

              cookies.set(
                loginTypeNm,
                res.data.TYPE,
                {
                  path: "/",
                  maxAge: 60 * 60,
                  domain: domain
                }
              );

              if (res.data.REFRESH !== undefined) {
                cookies.set(
                  refreshNm,
                  res.data.REFRESH,
                  {
                    path: "/",
                    maxAge: 24 * 60 * 60 * 30,
                    domain: domain
                  }
                );
              }

              cookies.remove(
                typeNm,
                {
                  path: "/",
                  domain: domain
                }
              );

              const config = {
                DISPLAY: true,
              };
              dispatch(updateFreeLicenseInfo(config));
              history.push("/app/home");
            } else {
              setLoading(false);
              return;
            }
          })
          .catch(() => {
            setLoading(false);
            return;
          });
      } else {
        cookies.remove(
          autoLoginNm,
          {
            path: "/",
            domain: domain
          }
        );
        return;
      }
    }
  }, [authNm, autoLoginNm, cookies, dispatch, domain, history, loginType, loginTypeNm, refreshNm, typeNm]);

  const createToken = (id) => {
    const config = {
      params: {
        Request_ID: id
      },
    };
    axios
      .post("/user/tokenAdmin", null, config)
      .then(res => {
        if (res.status === 200) {
          cookies.set(
            authNm,
            res.data.AUTH,
            {
              path: "/",
              maxAge: 60 * 60,
              domain: domain
            }
          );

          cookies.set(
            refreshNm,
            res.data.REFRESH,
            {
              path: "/",
              maxAge: 24 * 60 * 60 * 30,
              domain: domain
            }
          );

          cookies.set(
            loginTypeNm,
            res.data.TYPE,
            {
              path: "/",
              maxAge: 60 * 60,
              domain: domain
            }
          );

          history.push("/app/home");
        }
      })
  };

  return (
    <div>

    </div>
  )
}

export default LoginAdmin;
