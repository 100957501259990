import DataUseLogList from "component/tag/table/data/dataUseLogList";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateGroupInfo } from "actions/index";
import DataStatistics from "component/tag/table/data/dataStatistics";
import { useTranslation } from "react-i18next";
import Loading from "component/tag/loading/loading";
import DashboardContents from "component/page/group/dashboard/dashboardContents";
/**
 * Version : 1.0
 * 파일명 : Dashboard.js
 * 작성일자 : 2022-02-11
 * 작성자 : 권도훈
 * 설명 : 사용자 대시보드 페이지
 * 수정일자 : 2022-03-04
 * 수정자 : 강연승
 * 수정내역 : 대시보드 추가
 */
function Dashboard() {
  /**
   * DISPATCH
   */
  const dispatch = useDispatch();
  /**
   * 언어변환
   */
  const { t } = useTranslation();
  /**
   * Loading
   */
  const [loading, setLoading] = useState(false);
  /**
   * SET DEFAULT STATE
   */
  useEffect(() => {
    const config = {
      GROUP_YN: "N",
      GROUP_NUM: "",
      AUTH_CD: "",
    };
    dispatch(updateGroupInfo(config));
  }, [dispatch]);
  /**
   * 태그
   */
  return (
    <div
      style={{
        height: "902px",
        width: "1484px",
      }}
    >
      <div
        style={{
          margin: "26px 40px",
          height: "845px",
          width: "1404px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img src="/img/icon/dashboard/dash.png" alt="ICON" />
          <div
            style={{
              fontSize: "28px",
              fontWeight: "bold",
              color: "#2B4AC4",
              marginLeft: "16px",
            }}
          >
            {t("btn.menu.dashboard")}
          </div>
        </div>

        <div
          style={{
            backgroundColor: "rgba(255,255,255,0.4)",
            height: "845px",
            marginTop: "23px",
            borderRadius: "12px",
          }}
        >
          {/* <img
                        src="/img/icon/dashboard/coming_img02.png"
                        alt='COMING SOON!'
                    /> */}
          {loading ? (
            <div
              style={{
                height: "100%",
              }}
            >
              <Loading />
            </div>
          ) : (
            <DashboardContents />
          )}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
