import React, { useEffect, useState } from "react";
import axios from "axios";
import AddParticipant from "component/tag/input/schedule/addParticipant";
import ParticipantList from "component/tag/list/schedule/participantList";
import ParticipantSidebar from "./participantSidebar";
import ResSuccess from "component/tag/modal/schedule/resSuccess";
import AddressPopup from "component/tag/popup/common/addressPopup";
import DatePickerker from "component/tag/datepicker/scheduleDatePicker";
import FailReservationPopup from "component/tag/popup/schedule/failReservationPopup";
import { getDay } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import {
  updateDateTimeInfo,
  openAlert,
  openConfirm,
  closeAlert,
} from "actions/index";
import { useTranslation } from "react-i18next";
/**
 * Version : 1.0
 * 파일명 :
 * 작성일자 : 2021-10-05
 * 작성자 : 권도훈
 * 설명 :
 * 수정일자 : 2022-07-13
 * 수정자 : 강연승
 * 수정내역 : 회의 간격 10분 수정
 */
function EditSidebar(props) {
  /**
   * 언어변환
   */
  const { t } = useTranslation();
  const userState = useSelector(state => state.userInfoReducer);
  const dateTimeState = useSelector(state => state.dateTimeInfoReducer);
  const groupInfoState = useSelector(state => state.groupInfoReducer);
  const dispatch = useDispatch();

  const [data, setData] = useState(props.meetingInfo);
  const { id, title, participant, content, apiType, fileInfoList, recYN } =
    data;
  const [totalFiles, setTotalFiles] = useState([]);
  const [oldFiles, setOldFiles] = useState([]);
  useEffect(() => {
    if (
      fileInfoList !== undefined &&
      fileInfoList !== null &&
      fileInfoList !== ""
    ) {
      let tmp = [];
      for (let i = 0; i < fileInfoList.length; i++) {
        tmp.push({
          FILE_NM: fileInfoList[i].fileDownNm,
          ORI_FILE_NM: fileInfoList[i].fileNm,
          type: "old",
          fileSize: fileInfoList[i].fileSize,
        });
      }
      setOldFiles(oldFiles.concat(tmp));
      setTotalFiles(totalFiles.concat(tmp));
    }
  }, [fileInfoList]);
  const [inputs, setInputs] = useState({
    PRTCP_NM: "",
    PRTCP_EMAIL: "",
  });
  const [participants, setParticipants] = useState(participant);
  const { PRTCP_NM, PRTCP_EMAIL } = inputs;
  const onChangeParticipant = e => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
    setData({
      ...data,
      participant: participants,
    });
  };

  const [newFileList, setNewFileList] = useState([]);
  const onChangeFile = e => {
    let tmpArr = [];
    let totalTmp = [];
    let scheduleFileTotalSize = 0;
    for (let i = 0; i < oldFiles.length; i++) {
      scheduleFileTotalSize += parseInt(oldFiles[i].fileSize);
    }
    for (let i = 0; i < newFileList.length; i++) {
      scheduleFileTotalSize += newFileList[i].size;
    }
    for (let i = 0; i < e.target.files.length; i++) {
      let sizeTotal = scheduleFileTotalSize;
      if (newFileList.find(file => file.name === e.target.files[i].name)) {
        const obj = {
          TEXT: t("schedule.msg.alreadySelectFile"),
          submitEventHandler: () => dispatch(closeAlert()),
        };
        dispatch(openAlert(obj));
        return;
      }
      if (oldFiles.find(file => file.ORI_FILE_NM === e.target.files[i].name)) {
        const obj = {
          TEXT: t("schedule.msg.alreadyUploadFile"),
          submitEventHandler: () => dispatch(closeAlert()),
        };
        dispatch(openAlert(obj));
        return;
      }
      if (e.target.files[i].size > 10485760) {
        const obj = {
          TEXT: t("schedule.msg.overSizeFile"),
          submitEventHandler: () => dispatch(closeAlert()),
        };
        dispatch(openAlert(obj));
        return;
      }
      if (sizeTotal + e.target.files[i].size > 10485760) {
        const obj = {
          TEXT: t("schedule.msg.totalOverSizeFile"),
          submitEventHandler: () => dispatch(closeAlert()),
        };
        dispatch(openAlert(obj));
        return;
      }
      scheduleFileTotalSize += e.target.files[i].size;
      tmpArr.push(e.target.files[i]);
      totalTmp.push({ FILE_NM: e.target.files[i].name, type: "new" });
    }
    setNewFileList(newFileList.concat(tmpArr));
    setTotalFiles(totalFiles.concat(totalTmp));
    e.target.value = "";
  };
  const onRemoveFile = (fileNM, type) => {
    if (type === "old") {
      setOldFiles(oldFiles.filter(list => list.FILE_NM !== fileNM));
    } else if (type === "new") {
      setNewFileList(newFileList.filter(list => list.name !== fileNM));
    }
    setTotalFiles(totalFiles.filter(list => list.FILE_NM !== fileNM));
  };

  useEffect(() => {
    setData(props.meetingInfo);
    setParticipants(participant);
  }, []);

  useEffect(() => {
    setData({
      ...data,
      displayDate: props.meetingInfo.displayDate,
      displayTime: props.meetingInfo.displayTime,
      startTime: props.meetingInfo.startTime,
      endTime: props.meetingInfo.endTime,
    });
  }, [props.meetingInfo.startTime, props.meetingInfo.endTime]);

  const onChangeEvent = e => {
    const { value, name } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const cancelEvent = () => {
    props.setMeetingInfo({
      id: "",
      title: "",
      displayDate: "",
      displayTime: "",
      startTime: "",
      endTime: "",
      participant: [],
      content: "",
      apiType: "",
    });
    setParticipants([]);
    if (isActive) {
      setIsActive(false);
    }
    props.setIsInput(false);
    props.setResSidebarType("res");
    props.calendarApi.unselect();

    const config = {
      INPUT_TYPE: "",
      START_DATE: "",
      START_TIME: "",
      END_DATE: "",
      END_TIME: "",
    };
    dispatch(updateDateTimeInfo(config));
  };

  const handleEditSchedule = () => {
    if (
      dateTimeState.START_DATE === "" ||
      dateTimeState.END_DATE === "" ||
      dateTimeState.START_TIME === "" ||
      dateTimeState.END_TIME === ""
    ) {
      const obj = {
        TEXT: t("schedule.msg.noSelectScheduleTime"),
        submitEventHandler: () => dispatch(closeAlert()),
      };
      dispatch(openAlert(obj));
      return;
    }
    if (dateTimeState.START_TIME >= dateTimeState.END_TIME) {
      const obj = {
        TEXT: t("schedule.msg.overStartTime"),
        submitEventHandler: () => dispatch(closeAlert()),
      };
      dispatch(openAlert(obj));
      const config = {
        ...dateTimeState,
        INPUT_TYPE: "picker",
        END_TIME: "",
      };
      dispatch(updateDateTimeInfo(config));
      return;
    }
    if (apiType === null || apiType === undefined) {
      return;
    }
    if (!Array.isArray(participants) || participants.length < 1) {
      return;
    }
    props.setLoading(true);
    const formData = new FormData();

    /* 신규 파일 */
    if (Array.isArray(newFileList) && newFileList.length > 0) {
      for (let i = 0; i < newFileList.length; i++) {
        formData.append("files", newFileList[i]);
      }
    }

    let scheduleInfo = {
      SCHEDULE_NUM: id,
      START_TIME: dateTimeState.START_TIME,
      END_TIME: dateTimeState.END_TIME,
      API_TYPE: apiType,
      GROUP_YN: groupInfoState.GROUP_YN,
      REC_YN: recYN,
    };
    if (title !== null && title !== undefined && title !== "") {
      scheduleInfo = {
        ...scheduleInfo,
        TITLE: title,
      };
    } else if (title === null || title === undefined || title === "") {
      scheduleInfo = {
        ...scheduleInfo,
        TITLE: t("schedule.noTitle"),
      };
    }
    if (content !== null && content !== undefined && content !== "") {
      scheduleInfo = {
        ...scheduleInfo,
        CONTENTS: content.replace(/(\n|\r\n)/g, "<br />"),
      };
    } else if (content === null || content === undefined || content === "") {
      scheduleInfo = {
        ...scheduleInfo,
        CONTENTS: "",
      };
    }
    if (groupInfoState.GROUP_YN === "Y") {
      scheduleInfo = {
        ...scheduleInfo,
        GROUP_NUM: groupInfoState.GROUP_NUM,
      };
    }
    if (Array.isArray(participants) && participants.length > 0) {
      scheduleInfo = {
        ...scheduleInfo,
        participants: participants,
      };
    }
    if (Array.isArray(oldFiles) && oldFiles.length > 0) {
      scheduleInfo = {
        ...scheduleInfo,
        file_info: oldFiles,
      };
    } else if (oldFiles.length === 0) {
      if (fileInfoList === undefined) {
        scheduleInfo = {
          ...scheduleInfo,
          file_info: null,
        };
      } else if (fileInfoList !== undefined && fileInfoList.length > 0) {
        scheduleInfo = {
          ...scheduleInfo,
          file_info: [],
        };
      }
    }

    formData.append("scheduleInfo", JSON.stringify(scheduleInfo));
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    axios
      .put("/schedule/updateSchedule", formData, config)
      .then(response => {
        if (response.status === 200) {
          props.setLoading(false);
          if (response.data[0].hasOwnProperty("MSG")) {
            if (
              response.data[0].MSG === "parti_add" ||
              response.data[0].MSG === "schedule_new" ||
              response.data[0].MSG === "api_call" ||
              response.data[0].MSG === "account_limit"
            ) {
              const obj = {
                TEXT: t("schedule.msg." + response.data[0].MSG),
                submitEventHandler: () => {
                  dispatch(closeAlert());
                },
              };
              dispatch(openAlert(obj));
              return;
            } else {
              openFailPopup(response.data[0].MSG);
              return;
            }
          }
          handleDialogOpen(
            response.data[0].SCHEDULE,
            response.data[0].PARTICIPANTS
          );
          // const obj = {
          //   TEXT: "회의가 수정되었습니다.",
          //   submitEventHandler: () => {
          //     dispatch(closeAlert());
          //     props.handleDataState();
          //     props.calendarApi.unselect();
          //     props.setMeetingInfo({
          //       id: "",
          //       title: "",
          //       displayDate: "",
          //       displayTime: "",
          //       startTime: "",
          //       endTime: "",
          //       participant: [],
          //       content: "",
          //       apiType: "",
          //     });
          //     props.setIsInput(false);
          //     if (isActive) {
          //       setIsActive(false);
          //     }
          //     props.setResSidebarType("res");
          //   },
          // };
          // dispatch(openAlert(obj));
        } else {
          props.setLoading(false);
          const obj = {
            TEXT: t("schedule.msg.failEditeSchedule"),
            submitEventHandler: () => {
              dispatch(closeAlert());
              props.setIsInput(true);
              if (isActive) {
                setIsActive(false);
              }
              props.setResSidebarType("res");
            },
          };
          dispatch(openAlert(obj));
        }
      })
      .catch(error => {
        props.setLoading(false);
        const obj = {
          TEXT: t("schedule.msg.errorEditSchedule"),
          submitEventHandler: () => {
            dispatch(closeAlert());
            props.setIsInput(true);
          },
        };
        dispatch(openAlert(obj));
        console.log(error);
      });
  };

  const [dialogOpen, setDialogOpen] = useState(false);
  const [resultSchedule, setResultSchedule] = useState({});
  const [resultParticipants, setResultParticipants] = useState([]);
  const handleDialogOpen = (schedule, participants) => {
    setResultSchedule(schedule);
    setResultParticipants(participants);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    props.handleDataState();
    props.calendarApi.unselect();
    if (isActive) {
      setIsActive(false);
    }
    props.setMeetingInfo({
      title: "",
      displayDate: "",
      displayTime: "",
      startTime: "",
      endTime: "",
      participant: [],
      content: "",
      apiType: "",
    });
    props.setIsInput(false);
    const config = {
      ...dateTimeState,
      START_DATE: "",
      END_DATE: "",
      START_TIME: "",
      END_TIME: "",
    };
    dispatch(updateDateTimeInfo(config));
    props.setResSidebarType("res");
  };

  const handleKeyPress = ({ key }) => {
    if (key === "Enter") {
      onCreate();
    }
  };
  const validationEmail = value => {
    var emailType =
      /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
    return emailType.test(value);
  };
  const onCreate = () => {
    if (PRTCP_EMAIL === "" || validationEmail(PRTCP_EMAIL) === false) {
      const obj = {
        TEXT: t("schedule.msg.errorEmailFormat"),
        submitEventHandler: () => dispatch(closeAlert()),
      };
      dispatch(openAlert(obj));
      return;
    } else {
      if (PRTCP_EMAIL === userState.EMAIL) {
        const obj = {
          TEXT: t("schedule.msg.impossibleSelectMyself"),
          submitEventHandler: () => dispatch(closeAlert()),
        };
        dispatch(openAlert(obj));
        return;
      }
      for (let i = 0; i < participants.length; i++) {
        if (PRTCP_EMAIL === participants[i].PRTCP_EMAIL) {
          const obj = {
            TEXT: t("schedule.msg.alreadyScheduleParticipant"),
            submitEventHandler: () => dispatch(closeAlert()),
          };
          dispatch(openAlert(obj));
          return;
        }
      }
    }
    if (Array.isArray(addressAll) && addressAll.length > 0) {
      for (let i = 0; i < addressAll.length; i++) {
        if (PRTCP_EMAIL === addressAll[i].ADR_EMAIL) {
          const participant = {
            PRTCP_NM: addressAll[i].ADR_NM,
            PRTCP_EMAIL: addressAll[i].ADR_EMAIL,
          };
          setParticipants(participants.concat(participant));
          break;
        } else {
          const participant = {
            PRTCP_NM,
            PRTCP_EMAIL,
          };
          setParticipants(participants.concat(participant));
        }
      }
    } else {
      const participant = {
        PRTCP_NM,
        PRTCP_EMAIL,
      };
      setParticipants(participants.concat(participant));
    }
    setInputs({
      PRTCP_NM: "",
      PRTCP_EMAIL: "",
    });
  };

  const onRemove = PRTCP_EMAIL => {
    setParticipants(
      participants.filter(
        participant => participant.PRTCP_EMAIL !== PRTCP_EMAIL
      )
    );
  };
  const [partiSidebar, setPartiSidebar] = useState(false);
  const handlePartiSidebar = type => {
    if (type === true) {
      setPartiSidebar(true);
    } else if (type === false) {
      setPartiSidebar(false);
    }
  };
  const clickAddressItem = (nm, email) => {
    if (email === userState.EMAIL) {
      const obj = {
        TEXT: t("schedule.msg.impossibleSelectMyself"),
        submitEventHandler: () => dispatch(closeAlert()),
      };
      dispatch(openAlert(obj));
      return;
    }
    for (let i = 0; i < participants.length; i++) {
      if (email === participants[i].PRTCP_EMAIL) {
        const obj = {
          TEXT: t("schedule.msg.alreadyScheduleParticipant"),
          submitEventHandler: () => dispatch(closeAlert()),
        };
        dispatch(openAlert(obj));
        return;
      }
    }
    setParticipants(participants.concat({ PRTCP_NM: nm, PRTCP_EMAIL: email }));
    setData({
      ...data,
      participant: participants,
    });
  };

  const [addressPopup, setAddressPopup] = useState(false);
  const handleAddress = type => {
    if (type === true) {
      setAddressPopup(true);
      getAddressAll();
    } else if (type === false) {
      setAddressPopup(false);
    }
  };
  const [addressAll, setAddressAll] = useState([]);
  const getAddressAll = () => {
    let url = "";
    let params = {
      params: {},
    };
    if (groupInfoState.GROUP_YN === "Y") {
      url = "/address/GroupAddress";
      params = {
        params: {
          GROUP_NUM: groupInfoState.GROUP_NUM,
        },
      };
    } else if (groupInfoState.GROUP_YN === "N") {
      url = "/address/AddressCreator";
    }
    axios
      .get(url, params)
      .then(res => {
        if (res.status === 200) {
          setAddressAll(res.data);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const clickBackgound = () => {
    if (addressPopup) {
      setAddressPopup(false);
    }
    if (showSelectTime) {
      setShowSelectTime(false);
      setSelectBoxType("");
    }
  };
  const addCheckedAddressList = addressList => {
    let tmpAddress = [];
    for (let i = 0; i < addressList.length; i++) {
      if (addressList[i].ADR_EMAIL === userState.EMAIL) {
        const obj = {
          TEXT: t("schedule.msg.impossibleSelectMyself"),
          submitEventHandler: () => dispatch(closeAlert()),
        };
        dispatch(openAlert(obj));
        return;
      }
      for (let j = 0; j < participants.length; j++) {
        if (addressList[i].ADR_EMAIL === participants[j].PRTCP_EMAIL) {
          const obj = {
            TEXT: t("schedule.msg.alreadyScheduleParticipant"),
            submitEventHandler: () => dispatch(closeAlert()),
          };
          dispatch(openAlert(obj));
          return;
        }
      }
      tmpAddress.push({
        PRTCP_NM: addressList[i].ADR_NM,
        PRTCP_EMAIL: addressList[i].ADR_EMAIL,
      });
    }
    setParticipants(participants.concat(tmpAddress));
  };

  const [isActive, setIsActive] = useState(false);
  const activeBtn = () => {
    if (
      dateTimeState.START_TIME !== "" &&
      dateTimeState.START_TIME !== null &&
      dateTimeState.START_TIME !== undefined &&
      dateTimeState.END_TIME !== "" &&
      dateTimeState.END_TIME !== null &&
      dateTimeState.END_TIME !== undefined &&
      apiType !== "" &&
      apiType !== null &&
      apiType !== undefined &&
      participants.length > 0
    ) {
      setIsActive(true);
    } else if (
      dateTimeState.START_TIME === "" ||
      dateTimeState.START_TIME === null ||
      dateTimeState.START_TIME === undefined ||
      dateTimeState.END_TIME === "" ||
      dateTimeState.END_TIME === null ||
      dateTimeState.END_TIME === undefined ||
      apiType === "" ||
      apiType === null ||
      apiType === undefined ||
      participants.length < 1
    ) {
      setIsActive(false);
    }
  };
  useEffect(() => {
    activeBtn();
  }, [data, participants]);

  const convertApiType = code => {
    switch (code) {
      case "CD0501":
        return "Webex";
      case "CD0502":
        return "Zoom";
      case "CD0503":
        return "Chime";
      case "CD0501":
        return "Pexip";
      case undefined:
        return undefined;
    }
  };
  /**
   * datepicker 포맷
   */
  const datePickerFormat = time => {
    if (time === "" || time === undefined) {
      return;
    }
    let tmp = time.replace().replace(/\T.*/, "");
    let tmpArr = tmp.split("-");
    let year = tmpArr[0];
    let month = tmpArr[1];
    let date = tmpArr[2];
    let day = getDay(new Date(time));
    let dayStr = "";
    switch (day) {
      case 0:
        dayStr = t("date.sunday");
        break;
      case 1:
        dayStr = t("date.monday");
        break;
      case 2:
        dayStr = t("date.tuesday");
        break;
      case 3:
        dayStr = t("date.wednesday");
        break;
      case 4:
        dayStr = t("date.thursday");
        break;
      case 5:
        dayStr = t("date.friday");
        break;
      case 6:
        dayStr = t("date.saturday");
        break;
    }
    const result = t("date.fullFormat", {
      YEAR: year,
      MONTH: month,
      DATE: date,
      DAY: dayStr,
    });
    return result;
  };
  const timeArr = [
    { num: 0, time: t("date.timeAM", { TIME: "00:00" }), value: "00:00:00" },
    { num: 1, time: t("date.timeAM", { TIME: "00:10" }), value: "00:10:00" },
    { num: 2, time: t("date.timeAM", { TIME: "00:20" }), value: "00:20:00" },
    { num: 3, time: t("date.timeAM", { TIME: "00:30" }), value: "00:30:00" },
    { num: 4, time: t("date.timeAM", { TIME: "00:40" }), value: "00:40:00" },
    { num: 5, time: t("date.timeAM", { TIME: "00:50" }), value: "00:50:00" },

    { num: 6, time: t("date.timeAM", { TIME: "01:00" }), value: "01:00:00" },
    { num: 7, time: t("date.timeAM", { TIME: "01:10" }), value: "01:10:00" },
    { num: 8, time: t("date.timeAM", { TIME: "01:20" }), value: "01:20:00" },
    { num: 9, time: t("date.timeAM", { TIME: "01:30" }), value: "01:30:00" },
    { num: 10, time: t("date.timeAM", { TIME: "01:40" }), value: "01:40:00" },
    { num: 11, time: t("date.timeAM", { TIME: "01:50" }), value: "01:50:00" },

    { num: 12, time: t("date.timeAM", { TIME: "02:00" }), value: "02:00:00" },
    { num: 13, time: t("date.timeAM", { TIME: "02:10" }), value: "02:10:00" },
    { num: 14, time: t("date.timeAM", { TIME: "02:20" }), value: "02:20:00" },
    { num: 15, time: t("date.timeAM", { TIME: "02:30" }), value: "02:30:00" },
    { num: 16, time: t("date.timeAM", { TIME: "02:40" }), value: "02:40:00" },
    { num: 17, time: t("date.timeAM", { TIME: "02:50" }), value: "02:50:00" },

    { num: 18, time: t("date.timeAM", { TIME: "03:00" }), value: "03:00:00" },
    { num: 19, time: t("date.timeAM", { TIME: "03:10" }), value: "03:10:00" },
    { num: 20, time: t("date.timeAM", { TIME: "03:20" }), value: "03:20:00" },
    { num: 21, time: t("date.timeAM", { TIME: "03:30" }), value: "03:30:00" },
    { num: 22, time: t("date.timeAM", { TIME: "03:40" }), value: "03:40:00" },
    { num: 23, time: t("date.timeAM", { TIME: "03:50" }), value: "03:50:00" },

    { num: 24, time: t("date.timeAM", { TIME: "04:00" }), value: "04:00:00" },
    { num: 25, time: t("date.timeAM", { TIME: "04:10" }), value: "04:10:00" },
    { num: 26, time: t("date.timeAM", { TIME: "04:20" }), value: "04:20:00" },
    { num: 27, time: t("date.timeAM", { TIME: "04:30" }), value: "04:30:00" },
    { num: 28, time: t("date.timeAM", { TIME: "04:40" }), value: "04:40:00" },
    { num: 29, time: t("date.timeAM", { TIME: "04:50" }), value: "04:50:00" },

    { num: 30, time: t("date.timeAM", { TIME: "05:00" }), value: "05:00:00" },
    { num: 31, time: t("date.timeAM", { TIME: "05:10" }), value: "05:10:00" },
    { num: 32, time: t("date.timeAM", { TIME: "05:20" }), value: "05:20:00" },
    { num: 33, time: t("date.timeAM", { TIME: "05:30" }), value: "05:30:00" },
    { num: 34, time: t("date.timeAM", { TIME: "05:40" }), value: "05:40:00" },
    { num: 35, time: t("date.timeAM", { TIME: "05:50" }), value: "05:50:00" },

    { num: 36, time: t("date.timeAM", { TIME: "06:00" }), value: "06:00:00" },
    { num: 37, time: t("date.timeAM", { TIME: "06:10" }), value: "06:10:00" },
    { num: 38, time: t("date.timeAM", { TIME: "06:20" }), value: "06:20:00" },
    { num: 39, time: t("date.timeAM", { TIME: "06:30" }), value: "06:30:00" },
    { num: 40, time: t("date.timeAM", { TIME: "06:40" }), value: "06:40:00" },
    { num: 41, time: t("date.timeAM", { TIME: "06:50" }), value: "06:50:00" },

    { num: 42, time: t("date.timeAM", { TIME: "07:00" }), value: "07:00:00" },
    { num: 43, time: t("date.timeAM", { TIME: "07:10" }), value: "07:10:00" },
    { num: 44, time: t("date.timeAM", { TIME: "07:20" }), value: "07:20:00" },
    { num: 45, time: t("date.timeAM", { TIME: "07:30" }), value: "07:30:00" },
    { num: 46, time: t("date.timeAM", { TIME: "07:40" }), value: "07:40:00" },
    { num: 47, time: t("date.timeAM", { TIME: "07:50" }), value: "07:50:00" },

    { num: 48, time: t("date.timeAM", { TIME: "08:00" }), value: "08:00:00" },
    { num: 49, time: t("date.timeAM", { TIME: "08:10" }), value: "08:10:00" },
    { num: 50, time: t("date.timeAM", { TIME: "08:20" }), value: "08:20:00" },
    { num: 51, time: t("date.timeAM", { TIME: "08:30" }), value: "08:30:00" },
    { num: 52, time: t("date.timeAM", { TIME: "08:40" }), value: "08:40:00" },
    { num: 53, time: t("date.timeAM", { TIME: "08:50" }), value: "08:50:00" },

    { num: 54, time: t("date.timeAM", { TIME: "09:00" }), value: "09:00:00" },
    { num: 55, time: t("date.timeAM", { TIME: "09:10" }), value: "09:10:00" },
    { num: 56, time: t("date.timeAM", { TIME: "09:20" }), value: "09:20:00" },
    { num: 57, time: t("date.timeAM", { TIME: "09:30" }), value: "09:30:00" },
    { num: 58, time: t("date.timeAM", { TIME: "09:40" }), value: "09:40:00" },
    { num: 59, time: t("date.timeAM", { TIME: "09:50" }), value: "09:50:00" },

    { num: 60, time: t("date.timeAM", { TIME: "10:00" }), value: "10:00:00" },
    { num: 61, time: t("date.timeAM", { TIME: "10:10" }), value: "10:10:00" },
    { num: 62, time: t("date.timeAM", { TIME: "10:20" }), value: "10:20:00" },
    { num: 63, time: t("date.timeAM", { TIME: "10:30" }), value: "10:30:00" },
    { num: 64, time: t("date.timeAM", { TIME: "10:40" }), value: "10:40:00" },
    { num: 65, time: t("date.timeAM", { TIME: "10:50" }), value: "10:50:00" },

    { num: 66, time: t("date.timeAM", { TIME: "11:00" }), value: "11:00:00" },
    { num: 67, time: t("date.timeAM", { TIME: "11:10" }), value: "11:10:00" },
    { num: 68, time: t("date.timeAM", { TIME: "11:20" }), value: "11:20:00" },
    { num: 69, time: t("date.timeAM", { TIME: "11:30" }), value: "11:30:00" },
    { num: 70, time: t("date.timeAM", { TIME: "11:40" }), value: "11:40:00" },
    { num: 71, time: t("date.timeAM", { TIME: "11:50" }), value: "11:50:00" },

    { num: 72, time: t("date.timePM", { TIME: "12:00" }), value: "12:00:00" },
    { num: 73, time: t("date.timePM", { TIME: "12:10" }), value: "12:10:00" },
    { num: 74, time: t("date.timePM", { TIME: "12:20" }), value: "12:20:00" },
    { num: 75, time: t("date.timePM", { TIME: "12:30" }), value: "12:30:00" },
    { num: 76, time: t("date.timePM", { TIME: "12:40" }), value: "12:40:00" },
    { num: 77, time: t("date.timePM", { TIME: "12:50" }), value: "12:50:00" },

    { num: 78, time: t("date.timePM", { TIME: "01:00" }), value: "13:00:00" },
    { num: 79, time: t("date.timePM", { TIME: "01:10" }), value: "13:10:00" },
    { num: 80, time: t("date.timePM", { TIME: "01:20" }), value: "13:20:00" },
    { num: 81, time: t("date.timePM", { TIME: "01:30" }), value: "13:30:00" },
    { num: 82, time: t("date.timePM", { TIME: "01:40" }), value: "13:40:00" },
    { num: 83, time: t("date.timePM", { TIME: "01:50" }), value: "13:50:00" },

    { num: 84, time: t("date.timePM", { TIME: "02:00" }), value: "14:00:00" },
    { num: 85, time: t("date.timePM", { TIME: "02:10" }), value: "14:10:00" },
    { num: 86, time: t("date.timePM", { TIME: "02:20" }), value: "14:20:00" },
    { num: 87, time: t("date.timePM", { TIME: "02:30" }), value: "14:30:00" },
    { num: 88, time: t("date.timePM", { TIME: "02:40" }), value: "14:40:00" },
    { num: 89, time: t("date.timePM", { TIME: "02:50" }), value: "14:50:00" },

    { num: 90, time: t("date.timePM", { TIME: "03:00" }), value: "15:00:00" },
    { num: 91, time: t("date.timePM", { TIME: "03:10" }), value: "15:10:00" },
    { num: 92, time: t("date.timePM", { TIME: "03:20" }), value: "15:20:00" },
    { num: 93, time: t("date.timePM", { TIME: "03:30" }), value: "15:30:00" },
    { num: 94, time: t("date.timePM", { TIME: "03:40" }), value: "15:40:00" },
    { num: 95, time: t("date.timePM", { TIME: "03:50" }), value: "15:50:00" },

    { num: 96, time: t("date.timePM", { TIME: "04:00" }), value: "16:00:00" },
    { num: 97, time: t("date.timePM", { TIME: "04:10" }), value: "16:10:00" },
    { num: 98, time: t("date.timePM", { TIME: "04:20" }), value: "16:20:00" },
    { num: 99, time: t("date.timePM", { TIME: "04:30" }), value: "16:30:00" },
    { num: 100, time: t("date.timePM", { TIME: "04:40" }), value: "16:40:00" },
    { num: 101, time: t("date.timePM", { TIME: "04:50" }), value: "16:50:00" },

    { num: 102, time: t("date.timePM", { TIME: "05:00" }), value: "17:00:00" },
    { num: 103, time: t("date.timePM", { TIME: "05:10" }), value: "17:10:00" },
    { num: 104, time: t("date.timePM", { TIME: "05:20" }), value: "17:20:00" },
    { num: 105, time: t("date.timePM", { TIME: "05:30" }), value: "17:30:00" },
    { num: 106, time: t("date.timePM", { TIME: "05:40" }), value: "17:40:00" },
    { num: 107, time: t("date.timePM", { TIME: "05:50" }), value: "17:50:00" },

    { num: 108, time: t("date.timePM", { TIME: "06:00" }), value: "18:00:00" },
    { num: 109, time: t("date.timePM", { TIME: "06:10" }), value: "18:10:00" },
    { num: 110, time: t("date.timePM", { TIME: "06:20" }), value: "18:20:00" },
    { num: 111, time: t("date.timePM", { TIME: "06:30" }), value: "18:30:00" },
    { num: 112, time: t("date.timePM", { TIME: "06:40" }), value: "18:40:00" },
    { num: 113, time: t("date.timePM", { TIME: "06:50" }), value: "18:50:00" },

    { num: 114, time: t("date.timePM", { TIME: "07:00" }), value: "19:00:00" },
    { num: 115, time: t("date.timePM", { TIME: "07:10" }), value: "19:10:00" },
    { num: 116, time: t("date.timePM", { TIME: "07:20" }), value: "19:20:00" },
    { num: 117, time: t("date.timePM", { TIME: "07:30" }), value: "19:30:00" },
    { num: 118, time: t("date.timePM", { TIME: "07:40" }), value: "19:40:00" },
    { num: 119, time: t("date.timePM", { TIME: "07:50" }), value: "19:50:00" },

    { num: 120, time: t("date.timePM", { TIME: "08:00" }), value: "20:00:00" },
    { num: 121, time: t("date.timePM", { TIME: "08:10" }), value: "20:10:00" },
    { num: 122, time: t("date.timePM", { TIME: "08:20" }), value: "20:20:00" },
    { num: 123, time: t("date.timePM", { TIME: "08:30" }), value: "20:30:00" },
    { num: 124, time: t("date.timePM", { TIME: "08:40" }), value: "20:40:00" },
    { num: 125, time: t("date.timePM", { TIME: "08:50" }), value: "20:50:00" },

    { num: 126, time: t("date.timePM", { TIME: "09:00" }), value: "21:00:00" },
    { num: 127, time: t("date.timePM", { TIME: "09:10" }), value: "21:10:00" },
    { num: 128, time: t("date.timePM", { TIME: "09:20" }), value: "21:20:00" },
    { num: 129, time: t("date.timePM", { TIME: "09:30" }), value: "21:30:00" },
    { num: 130, time: t("date.timePM", { TIME: "09:40" }), value: "21:40:00" },
    { num: 131, time: t("date.timePM", { TIME: "09:50" }), value: "21:50:00" },

    { num: 132, time: t("date.timePM", { TIME: "10:00" }), value: "22:00:00" },
    { num: 133, time: t("date.timePM", { TIME: "10:10" }), value: "22:10:00" },
    { num: 134, time: t("date.timePM", { TIME: "10:20" }), value: "22:20:00" },
    { num: 135, time: t("date.timePM", { TIME: "10:30" }), value: "22:30:00" },
    { num: 136, time: t("date.timePM", { TIME: "10:40" }), value: "22:40:00" },
    { num: 137, time: t("date.timePM", { TIME: "10:50" }), value: "22:50:00" },

    { num: 138, time: t("date.timePM", { TIME: "11:00" }), value: "23:00:00" },
    { num: 139, time: t("date.timePM", { TIME: "11:10" }), value: "23:10:00" },
    { num: 140, time: t("date.timePM", { TIME: "11:20" }), value: "23:20:00" },
    { num: 141, time: t("date.timePM", { TIME: "11:30" }), value: "23:30:00" },
    { num: 142, time: t("date.timePM", { TIME: "11:40" }), value: "23:40:00" },
    { num: 143, time: t("date.timePM", { TIME: "11:50" }), value: "23:50:00" },
  ];
  const timePickerSlice = type => {
    let index = 0;
    if (dateTimeState.START_DATE === "" || dateTimeState.END_DATE === "") {
      return;
    }
    if (
      dateTimeState.START_DATE === new Date().toISOString().replace(/\T.*/, "")
    ) {
      let hour = new Date().getHours();
      let min = new Date().getMinutes();
      if (dateTimeState.START_DATE === dateTimeState.END_DATE) {
        if (type === "start") {
          index = hour * 6;
          if (min < 10) {
            return index + 1;
          } else if (min >= 10 && min < 20) {
            index += 2;
            return index;
          } else if (min >= 20 && min < 30) {
            index += 3;
            return index;
          } else if (min >= 30 && min < 40) {
            index += 4;
            return index;
          } else if (min >= 40 && min < 50) {
            index += 5;
            return index;
          } else if (min >= 50 && min < 60) {
            index += 6;
            return index;
          }
        } else if (type === "end") {
          let tmpArr = dateTimeState.START_TIME.split("T");
          index = tmpArr[1].replace(/\:.*/, "");
          if (tmpArr[1].split(":")[1] === "00") {
            index = index * 6 + 1;
          } else if (tmpArr[1].split(":")[1] === "10") {
            index = index * 6 + 2;
          } else if (tmpArr[1].split(":")[1] === "20") {
            index = index * 6 + 3;
          } else if (tmpArr[1].split(":")[1] === "30") {
            index = index * 6 + 4;
          } else if (tmpArr[1].split(":")[1] === "40") {
            index = index * 6 + 5;
          } else if (tmpArr[1].split(":")[1] === "50") {
            index = index * 6 + 6;
          }
          return index;
        }
      } else if (dateTimeState.START_DATE !== dateTimeState.END_DATE) {
        if (type === "start") {
          index = hour * 6;
          if (min < 10) {
            return index + 1;
          } else if (min >= 10 && min < 20) {
            index += 2;
            return index;
          } else if (min >= 20 && min < 30) {
            index += 3;
            return index;
          } else if (min >= 30 && min < 40) {
            index += 4;
            return index;
          } else if (min >= 40 && min < 50) {
            index += 5;
            return index;
          } else if (min >= 50 && min < 60) {
            index += 6;
            return index;
          }
        } else if (type === "end") {
          return index;
        }
      }
    } else {
      if (dateTimeState.START_DATE === dateTimeState.END_DATE) {
        if (type === "start") {
          return index;
        } else if (type === "end") {
          let tmpArr = dateTimeState.START_TIME.split("T");
          index = tmpArr[1].replace(/\:.*/, "");
          if (tmpArr[1].split(":")[1] === "00") {
            index = index * 6 + 1;
          } else if (tmpArr[1].split(":")[1] === "10") {
            index = index * 6 + 2;
          } else if (tmpArr[1].split(":")[1] === "20") {
            index = index * 6 + 3;
          } else if (tmpArr[1].split(":")[1] === "30") {
            index = index * 6 + 4;
          } else if (tmpArr[1].split(":")[1] === "40") {
            index = index * 6 + 5;
          } else if (tmpArr[1].split(":")[1] === "50") {
            index = index * 6 + 6;
          }
          return index;
        }
      } else if (dateTimeState.START_DATE !== dateTimeState.END_DATE) {
        if (type === "start") {
          return index;
        } else if (type === "end") {
          return index;
        }
      }
    }
  };

  const timePickerFormat = time => {
    if (time === "") {
      return "";
    }
    let tmpArr = time.split("T");
    let timeStr = tmpArr[1];
    return timeStr;
  };

  const [isMouseOver, setIsMouseOver] = useState(false);
  const [tooltipType, setTooltipType] = useState("");
  const onMouseTooltip = (overYN, type) => {
    setTooltipType(type);
    if (overYN === "over") {
      setIsMouseOver(true);
    } else if (overYN === "out") {
      setIsMouseOver(false);
    }
  };
  const [showSelectTime, setShowSelectTime] = useState(false);
  const [selectboxType, setSelectBoxType] = useState("");

  /* 회의 이용권 관련 예약 실패 팝업 */
  const [showFailPopup, setShowFailPopup] = useState(false);
  const [resultMSG, setResultMSG] = useState("");
  const openFailPopup = msg => {
    setShowFailPopup(true);
    setResultMSG(msg);
  };
  const closeFailPopup = () => {
    setShowFailPopup(false);
  };
  return (
    <>
      <ParticipantSidebar
        width={456}
        height={"954px"}
        defaultX={750}
        activeX={1011}
        partiSidebar={partiSidebar}
        groupYN={groupInfoState.GROUP_YN}
        groupNum={groupInfoState.GROUP_NUM}
        clickAddressItem={clickAddressItem}
        addCheckedAddressList={addCheckedAddressList}
        handlePartiSidebar={handlePartiSidebar}
      />
      <FailReservationPopup
        open={showFailPopup}
        close={closeFailPopup}
        msg={resultMSG}
      />

      <div className="resSidebar">
        <div className="resSidebar_margin">
          <div className="resSidebar_top">
            <div className="resSidebar_title">
              {groupInfoState.GROUP_YN === "N"
                ? t("schedule.sidebar.edit.title.CD0401")
                : t(
                    "schedule.sidebar.edit.title." +
                      groupInfoState.GROUP_CATEGORY
                  )}
            </div>
            <img
              className="resSidebar_closeBtn cursor"
              src="/img/icon/schedule/close.png"
              alt="close"
              onClick={() => {
                const obj = {
                  TEXT: t("schedule.msg.cancelEditSchedule"),
                  submitEventHandler: () => {
                    cancelEvent();
                    dispatch(closeAlert());
                  },
                };
                dispatch(openConfirm(obj));
              }}
            />
          </div>
        </div>

        <div
          style={{ maxHeight: "794px", overflowY: "hidden" }}
          onClick={() => (clickBackgound(), handlePartiSidebar(false))}
        >
          <div style={{ margin: "8px 32px 16px 32px" }}>
            <img
              className="resIcon"
              src="/img/icon/schedule/title.png"
              alt="title"
            />
            <input
              // ref={inputRef}
              className="resSidebar_input"
              type="text"
              placeholder={t("schedule.sidebar.placeholder.title")}
              value={title}
              name="title"
              maxLength="50"
              onChange={onChangeEvent}
              autoFocus
            />
          </div>

          <div className="startArea" style={{ margin: "16px 32px 8px" }}>
            <img
              className="resIcon"
              src="/img/icon/schedule/start.png"
              alt="start"
            />

            <DatePickerker
              type="START_DATE"
              date={dateTimeState}
              setDate={newValue => {
                let config = {
                  ...dateTimeState,
                  INPUT_TYPE: "picker",
                  START_DATE: newValue.START_DATE,
                };
                if (
                  dateTimeState.START_DATE !== "" &&
                  dateTimeState.START_TIME !== "" &&
                  dateTimeState.END_DATE !== "" &&
                  dateTimeState.END_TIME !== ""
                ) {
                  const config = {
                    INPUT_TYPE: "picker",
                    START_DATE: newValue.START_DATE,
                    START_TIME:
                      newValue.START_DATE +
                      "T" +
                      timePickerFormat(dateTimeState.START_TIME),
                    END_DATE: newValue.START_DATE,
                    END_TIME:
                      newValue.START_DATE +
                      "T" +
                      timePickerFormat(dateTimeState.END_TIME),
                  };
                  dispatch(updateDateTimeInfo(config));
                  return;
                }
                if (dateTimeState.START_TIME !== "") {
                  config = {
                    ...config,
                    START_TIME:
                      newValue.START_DATE +
                      "T" +
                      timePickerFormat(dateTimeState.START_TIME),
                  };
                }
                if (dateTimeState.END_DATE !== "") {
                  config = {
                    ...config,
                    END_DATE: newValue.START_DATE,
                  };
                }
                if (dateTimeState.END_TIME !== "") {
                  config = {
                    ...config,
                    END_TIME:
                      newValue.START_DATE +
                      "T" +
                      timePickerFormat(dateTimeState.END_TIME),
                  };
                }
                dispatch(updateDateTimeInfo(config));
              }}
            />
            <div
              className={
                showSelectTime && selectboxType === "startTime"
                  ? "cursor selectTimeActive"
                  : "cursor selectTime"
              }
              onClick={() => {
                setShowSelectTime(!showSelectTime);
                setSelectBoxType("startTime");
              }}
            >
              <div
                className="inline-block"
                style={{
                  font: "normal normal normal 14px/26px Spoqa Han Sans Neo",
                  color: "#3E3E4F",
                }}
              >
                {dateTimeState.START_TIME === ""
                  ? t("schedule.sidebar.placeholder.start")
                  : timeArr.find(
                      i =>
                        i.value === timePickerFormat(dateTimeState.START_TIME)
                    ).time}
              </div>
              <img
                className="inline-block"
                style={{
                  float: "right",
                  marginTop: "6px",
                  marginRight: "12px",
                }}
                src={
                  showSelectTime && selectboxType === "startTime"
                    ? "/img/icon/schedule/time_up_active.png"
                    : "/img/icon/schedule/time_down.png"
                }
                alt="DROP"
              />
            </div>
            {showSelectTime && selectboxType === "startTime" ? (
              <div
                className="durationPopup scrollbar"
                style={{
                  display:
                    showSelectTime && selectboxType === "startTime"
                      ? "block"
                      : "none",
                }}
              >
                {timeArr.slice(timePickerSlice("start")).map(data => (
                  <div
                    className="durationItem"
                    key={"start_" + data.value}
                    onClick={() => {
                      let config = {
                        ...dateTimeState,
                        INPUT_TYPE: "picker",
                        START_TIME: dateTimeState.START_DATE + "T" + data.value,
                      };
                      if (dateTimeState.END_TIME !== "") {
                        if (
                          dateTimeState.START_DATE + "T" + data.value >=
                          dateTimeState.END_TIME
                        ) {
                          let findNum = timeArr.find(
                            i => i.value === data.value
                          ).num;
                          if (findNum !== 143) {
                            config = {
                              ...config,
                              END_TIME:
                                dateTimeState.END_DATE +
                                "T" +
                                timeArr.find(i => i.num === findNum + 1).value,
                            };
                          } else if (findNum === 143) {
                            const selectDate = new Date(
                              dateTimeState.START_DATE
                            );
                            const nextDate = new Date(
                              selectDate.setDate(selectDate.getDate() + 1)
                            );
                            const nextDateStr = nextDate
                              .toISOString()
                              .replace(/\T.*/, "");
                            config = {
                              ...config,
                              START_TIME:
                                dateTimeState.START_DATE + "T" + data.value,
                              END_DATE: nextDateStr,
                              END_TIME: nextDateStr + "T" + timeArr[0].value,
                            };
                          }
                        }
                      }
                      dispatch(updateDateTimeInfo(config));
                      setShowSelectTime(!showSelectTime);
                    }}
                  >
                    <div>{data.time}</div>
                  </div>
                ))}
              </div>
            ) : null}
          </div>

          <div className="endArea" style={{ margin: "8px 32px 16px" }}>
            <img
              className="resIcon"
              src="/img/icon/schedule/end.png"
              alt="end"
            />

            <DatePickerker
              type="END_DATE"
              date={dateTimeState}
              setDate={newValue => {
                if (dateTimeState.END_TIME === "") {
                  const config = {
                    ...dateTimeState,
                    INPUT_TYPE: "picker",
                    END_DATE: newValue.END_DATE,
                  };
                  dispatch(updateDateTimeInfo(config));
                } else if (dateTimeState.END_TIME !== "") {
                  if (
                    dateTimeState.START_TIME.split("T")[1] === "23:50:00" &&
                    newValue.END_DATE === dateTimeState.START_DATE
                  ) {
                    const selectDate = new Date(dateTimeState.START_DATE);
                    const nextDate = new Date(
                      selectDate.setDate(selectDate.getDate() + 1)
                    );
                    const nextDateStr = nextDate
                      .toISOString()
                      .replace(/\T.*/, "");
                    const config = {
                      ...dateTimeState,
                      INPUT_TYPE: "picker",
                      END_DATE: nextDateStr,
                      END_TIME: nextDateStr + "T" + timeArr[0].value,
                    };
                    dispatch(updateDateTimeInfo(config));
                    return;
                  }
                  const config = {
                    ...dateTimeState,
                    INPUT_TYPE: "picker",
                    END_DATE: newValue.END_DATE,
                    END_TIME:
                      newValue.END_DATE +
                      "T" +
                      timePickerFormat(dateTimeState.END_TIME),
                  };
                  dispatch(updateDateTimeInfo(config));
                }
              }}
            />
            <div
              className={
                showSelectTime && selectboxType === "endTime"
                  ? "cursor selectTimeActive"
                  : "cursor selectTime"
              }
              onClick={() => {
                setShowSelectTime(!showSelectTime);
                setSelectBoxType("endTime");
              }}
            >
              <div
                className="inline-block"
                style={{
                  font: "normal normal normal 14px/26px Spoqa Han Sans Neo",
                  color: "#3E3E4F",
                }}
              >
                {dateTimeState.END_TIME === ""
                  ? t("schedule.sidebar.placeholder.end")
                  : timeArr.find(
                      i => i.value === timePickerFormat(dateTimeState.END_TIME)
                    ).time}
              </div>
              <img
                className="inline-block"
                style={{
                  float: "right",
                  marginTop: "6px",
                  marginRight: "12px",
                }}
                src={
                  showSelectTime && selectboxType === "endTime"
                    ? "/img/icon/schedule/time_up_active.png"
                    : "/img/icon/schedule/time_down.png"
                }
                alt="DROP"
              />
            </div>
            {showSelectTime && selectboxType === "endTime" ? (
              <div
                className="durationPopup scrollbar"
                style={{
                  display:
                    showSelectTime && selectboxType === "endTime"
                      ? "block"
                      : "none",
                }}
              >
                {timeArr.slice(timePickerSlice("end")).map(data => (
                  <div
                    className="durationItem"
                    key={"end_" + data.value}
                    onClick={() => {
                      const config = {
                        ...dateTimeState,
                        INPUT_TYPE: "picker",
                        END_TIME: dateTimeState.END_DATE + "T" + data.value,
                      };
                      dispatch(updateDateTimeInfo(config));
                      setShowSelectTime(!showSelectTime);
                    }}
                  >
                    <div>{data.time}</div>
                  </div>
                ))}
              </div>
            ) : null}
          </div>

          <div style={{ margin: "16px 32px" }}>
            <img
              className="resIcon"
              src="/img/icon/schedule/platform.png"
              alt="platform"
            />
            <input
              className="resSidebar_input"
              type="text"
              placeholder={t("schedule.sidebar.placeholder.platform")}
              value={convertApiType(apiType)}
              name="apiType"
              readOnly
              disabled
            />
            <div className="platformChkboxArea">
              <div>
                <input
                  id="webex"
                  // className="resSidebar_input"
                  type="radio"
                  checked={apiType === "CD0501"}
                  value="CD0501"
                  style={{ marginRight: "8px" }}
                  name="apiType"
                  disabled
                  onChange={onChangeEvent}
                />
                <img
                  style={{
                    display:
                      apiType === "CD0501" || apiType === undefined
                        ? "initial"
                        : "none",
                  }}
                  src="/img/platform/webex3.png"
                  alt="webex"
                />
                <img
                  style={{
                    display:
                      apiType === "CD0501" || apiType === undefined
                        ? "none"
                        : "initial",
                  }}
                  src="/img/platform/webex3_inactive.png"
                  alt="webex"
                />
              </div>
              <div>
                <input
                  id="zoom"
                  // className="resSidebar_input"
                  type="radio"
                  checked={apiType === "CD0502"}
                  value="CD0502"
                  name="apiType"
                  style={{ marginRight: "8px" }}
                  disabled
                  onChange={onChangeEvent}
                />
                <img
                  style={{
                    display:
                      apiType === "CD0502" || apiType === undefined
                        ? "initial"
                        : "none",
                  }}
                  src="/img/platform/zoom3.png"
                  alt="zoom"
                />
                <img
                  style={{
                    display:
                      apiType === "CD0502" || apiType === undefined
                        ? "none"
                        : "initial",
                  }}
                  src="/img/platform/zoom3_inactive.png"
                  alt="zoom"
                />
              </div>
              {/* <div>
                <input
                  id="chime"
                  // className="resSidebar_input"
                  type="radio"
                  checked={apiType === "CD0503"}
                  value="CD0503"
                  name="apiType"
                  style={{ marginRight: "8px" }}
                  disabled
                  onChange={onChangeEvent}
                />
                <img
                  style={{ display: "none" }}
                  src="/img/platform/chime3.png"
                  alt="chime"
                />
                <img
                  style={{ display: "initial" }}
                  src="/img/platform/chime3_inactive.png"
                  alt="chime"
                />
              </div>
              <div>
                <input
                  id="pexip"
                  // className="resSidebar_input"
                  type="radio"
                  checked={apiType === "CD0504"}
                  value="CD0504"
                  name="apiType"
                  disabled
                  style={{ marginRight: "8px" }}
                  onChange={onChangeEvent}
                />
                <img
                  style={{ display: "none" }}
                  src="/img/platform/pexip3.png"
                  alt="pexip"
                />
                <img
                  style={{ display: "initial" }}
                  src="/img/platform/pexip3_inactive.png"
                  alt="pexip"
                />
              </div> */}
            </div>
          </div>

          <div style={{ margin: "16px 32px" }}>
            <img
              className="resIcon"
              src="/img/icon/schedule/record.png"
              alt="record"
            />
            <label htmlFor="recordingButton">
              {recYN === "Y" ? (
                <div
                  className={
                    groupInfoState.GROUP_YN === "Y" &&
                    groupInfoState.AUTH_CD === "CD0302"
                      ? "recButtonOn"
                      : "recButtonOn cursor"
                  }
                >
                  {t("schedule.sidebar.btn.recordOn")}
                </div>
              ) : (
                <div
                  className={
                    groupInfoState.GROUP_YN === "Y" &&
                    groupInfoState.AUTH_CD === "CD0302"
                      ? "recButtonOff"
                      : "recButtonOff cursor"
                  }
                >
                  {t("schedule.sidebar.btn.recordOff")}
                </div>
              )}
            </label>
            <input
              type="checkbox"
              id="recordingButton"
              name="rec"
              style={{ display: "none" }}
              onChange={e => {
                if (e.currentTarget.checked) {
                  setData({
                    ...data,
                    recYN: "Y",
                  });
                } else if (!e.currentTarget.checked) {
                  setData({
                    ...data,
                    recYN: "N",
                  });
                }
              }}
              disabled={
                groupInfoState.GROUP_YN === "Y" &&
                groupInfoState.AUTH_CD === "CD0302"
              }
              checked={
                (groupInfoState.GROUP_YN === "N" && recYN === "Y") ||
                (groupInfoState.GROUP_YN === "Y" &&
                  groupInfoState.AUTH_CD === "CD0301" &&
                  recYN === "Y") ||
                (groupInfoState.GROUP_YN === "Y" &&
                  groupInfoState.AUTH_CD === "CD0302" &&
                  groupInfoState.REC_YN === "Y")
              }
            />
            <img
              className="inline-block"
              style={{ verticalAlign: "middle" }}
              src="/img/icon/schedule/tooltip_gr.png"
              alt="tooltip"
              onMouseOver={() => onMouseTooltip("over", "rec")}
              onMouseOut={() => onMouseTooltip("out", "rec")}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <div
                className="recTooltip"
                style={{
                  display:
                    isMouseOver && tooltipType === "rec" ? "block" : "none",
                }}
              >
                <div
                  style={{
                    font: "normal normal bold 12px/18px Spoqa Han Sans Neo",
                    color: "#FFFFFF",
                  }}
                >
                  <div>{t("schedule.sidebar.tooltip.record.text")}</div>
                  <div>{t("schedule.sidebar.tooltip.record.warning")}</div>
                </div>
              </div>
            </div>
          </div>

          <div style={{ margin: "16px 32px" }}>
            <img
              className="resIcon"
              src="/img/icon/schedule/participant.png"
              alt="participant"
            />
            <AddParticipant
              className="resSidebar_input_participant"
              PRTCP_EMAIL={PRTCP_EMAIL}
              onChange={onChangeParticipant}
              onKeyPress={handleKeyPress}
              onFocus={() => (handlePartiSidebar(true), handleAddress(true))}
            />
            <button
              className={
                PRTCP_EMAIL.length > 0
                  ? "resSidebar_btn_addParticipant_active"
                  : "resSidebar_btn_addParticipant"
              }
              onClick={() => {
                if (PRTCP_EMAIL.length > 0) {
                  onCreate();
                }
              }}
            >
              {t("schedule.sidebar.btn.addParticipant")}
            </button>
            <AddressPopup
              className="sidebarAddressPopup scrollbar"
              open={addressPopup}
              address={addressAll}
              clickAddressItem={clickAddressItem}
              search={inputs.PRTCP_EMAIL}
            />
            <ParticipantList
              className="participantList scrollbar"
              participants={participants}
              length={participants.length}
              onRemove={onRemove}
            />
          </div>

          <div style={{ margin: "16px 32px" }}>
            <img
              className="resIcon_content"
              src="/img/icon/schedule/contents.png"
              alt="contents"
            />
            <textarea
              className="resSidebar_textArea scrollbar"
              type="text"
              placeholder={t("schedule.sidebar.placeholder.content")}
              value={content}
              name="content"
              maxLength="255"
              onChange={onChangeEvent}
            />
          </div>

          <div style={{ margin: "16px 32px" }}>
            <img
              className="resIcon"
              src="/img/icon/schedule/file.png"
              alt="file"
            />
            <label htmlFor="inputFile" className="inputFileBtn">
              {t("schedule.sidebar.btn.addFile")}
            </label>
            <input
              id="inputFile"
              className="resSidebar_input"
              type="file"
              style={{ display: "none" }}
              placeholder={t("schedule.sidebar.btn.addFile")}
              name="file"
              onChange={onChangeFile}
              multiple
            />
            <img
              className="inline-block"
              style={{ verticalAlign: "middle", marginLeft: "8px" }}
              src="/img/icon/schedule/tooltip_gr.png"
              alt="tooltip"
              onMouseOver={() => onMouseTooltip("over", "file")}
              onMouseOut={() => onMouseTooltip("out", "file")}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <div
                className="fileTooltip"
                style={{
                  display:
                    isMouseOver && tooltipType === "file" ? "block" : "none",
                }}
              >
                <div
                  style={{
                    font: "normal normal bold 12px/18px Spoqa Han Sans Neo",
                    color: "#FFFFFF",
                  }}
                >
                  <div>{t("schedule.sidebar.tooltip.file")}</div>
                </div>
              </div>
            </div>
            {Array.isArray(totalFiles) && totalFiles.length > 0 ? (
              <div className="fileList scrollbar">
                <ul>
                  {totalFiles.map(data => (
                    <li key={data.FILE_NM}>
                      <font
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          width: "259px",
                          marginRight: "20px",
                          display: "inline-block",
                          font: "normal normal normal 14px/18px Spoqa Han Sans Neo",
                          color: "#3E3E4F",
                        }}
                      >
                        {data.type === "old" ? data.ORI_FILE_NM : data.FILE_NM}
                      </font>
                      <img
                        className="cursor"
                        src="/img/icon/schedule/remove.png"
                        alt="remove"
                        style={{
                          verticalAlign: "top",
                          float: "right",
                          marginRight: "8px",
                        }}
                        onClick={() => onRemoveFile(data.FILE_NM, data.type)}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
        <button
          className={
            isActive ? "resSidebar_saveBtnActive" : "resSidebar_saveBtn"
          }
          onClick={() => {
            const obj = {
              TEXT: t("schedule.msg.alertEditSchedule"),
              submitEventHandler: () => {
                handleEditSchedule();
                handlePartiSidebar(false);
                dispatch(closeAlert());
              },
            };
            dispatch(openConfirm(obj));
            handlePartiSidebar(false);
          }}
          disabled={!isActive}
        >
          {t("schedule.sidebar.btn.update")}
        </button>
      </div>
      <ResSuccess
        type="update"
        displayDate={datePickerFormat(dateTimeState.START_DATE)}
        displayTime={
          timePickerFormat(dateTimeState.START_TIME) +
          " ~ " +
          timePickerFormat(dateTimeState.END_TIME)
        }
        schedule={resultSchedule}
        participants={resultParticipants}
        open={dialogOpen}
        close={handleDialogClose}
      />
    </>
  );
}

export default EditSidebar;
