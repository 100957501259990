import { CircularProgress } from '@material-ui/core'
import React from 'react'
/**
 * Version : 1.0
 * 파일명 : loading.js
 * 작성일자 : 2022-04-19
 * 작성자 : 권도훈
 * 설명 : 공통 로딩 컴포넌트(css 수정)
 */
function Loading() {
    return (
        <div className='common-loading'>
            <CircularProgress size={24} />
        </div>
    )
}

export default Loading
