import React from "react";
import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
/**
 * Version : 1.0
 * 파일명 : bookmarkResultPopup.js
 * 작성일자 : 2021-11-24
 * 작성자 : 강연승
 * 설명 : 북마크 생성, 수정 완료 팝업
 * 수정일자 : 2022-02-10
 * 수정자 : 강연승
 * 수정내역 : 텍스트 수정 영상회의 -> 화상회의, 웹폰트 변경
 * props :
 * 전달받은값 - 설명
 */

function BookmarkResultPopup(props) {
  /**
   * 언어변환
   */
  const { t } = useTranslation();
  return (
    <ReactModal
      isOpen={props.open} //모달 Open(Boolean)
      className="bookmarkResultPopup" //모달 ClassName
      overlayClassName="bookmarkResultPopup_Overlay" //모달오버레이 ClassName
      onRequestClose={props.close}
      shouldCloseOnEsc={true}
      animationType="fade"
      transparent={true}
      closeTimeoutMS={200}
    >
      <div style={{ textAlign: "center" }}>
        <div
          style={{
            font: "normal normal bold 20px/27px Spoqa Han Sans Neo",
            color: "#2B4AC4",
            textAlign: "center",
            marginBottom: "40px",
          }}
        >
          {t("bookmark.popup.title." + props.type)}
        </div>

        <div
          style={{
            font: "normal normal bold 16px/21px Spoqa Han Sans Neo",
            color: "#2B4AC4",
            // marginBottom: "8px",
            margin: "0 auto 8px",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            maxWidth: "200px",
          }}
        >
          {props.bookmark.TITLE}
        </div>

        <div
          className="scrollbar"
          style={{
            font: "normal normal bold 16px/21px Spoqa Han Sans Neo",
            color: "#3E3E4F",
            // marginBottom: "24px",
            margin: "8px auto 24px",
            maxWidth: "200px",
            maxHeight: "200px",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <div>{props.bookmark.CONTENTS}</div>
        </div>

        {Array.isArray(props.participants) && props.participants.length > 0 ? (
          <div
            className="scrollbar"
            style={{
              font: "normal normal normal 14px/24px Spoqa Han Sans Neo",
              color: "#3E3E4F",
              maxHeight: "200px",
              overflowY: "auto",
            }}
          >
            <div className="scheduleModalContent">
              {props.participants.map(data => (
                <div key={data.PRTCP_EMAIL}>{data.PRTCP_EMAIL}</div>
              ))}
            </div>
          </div>
        ) : null}
        <button
          style={{
            margin: "2rem auto 0 auto",
            display: "block",
            width: "64px",
            height: "36px",
            backgroundColor: "#2B4AC4",
            border: "none",
            borderRadius: "8px",
            color: "#FFFFFF",
            font: "normal normal bold 14px/18px Spoqa Han Sans Neo",
            cursor: "pointer",
          }}
          onClick={() => {
            props.close();
          }}
        >
          확 인
        </button>
      </div>
    </ReactModal>
  );
}

export default BookmarkResultPopup;
