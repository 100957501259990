import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
/**
 * Version : 1.0
 * 파일명 : meetingHostEmpty.js
 * 작성일자 : 2022-01-20
 * 작성자 : 강연승
 * 설명 : 미팅호스트 없는 경우
 * 수정일자 : 2022-02-10
 * 수정자 : 강연승
 * 수정내역 : 텍스트 수정 영상회의 -> 화상회의, 웹폰트 변경
 */
function MeetingHostEmpty() {
  /**
   * TRANSLATION
   */
  const { t } = useTranslation();
  const groupCategoryConvert = ctgryCD => {
    switch (ctgryCD) {
      case "CD0401":
        return "default";
      case "CD0402":
        return "company";
      case "CD0403":
        return "school";
      case "CD0404":
        return "private_institute";
      case "CD0405":
        return "university";
      case "CD0406":
        return "social";
      default:
        return "default";
    }
  };
  /**
   * REDUX
   */
  const groupInfoState = useSelector(state => state.groupInfoReducer);
  return (
    <tr>
      <td>
        <div
          className="flex-y-center"
          style={{
            height: "620px",
            width: "924px",
            position: "absolute",
            font: "normal normal bold 14px/21px Spoqa Han Sans Neo",
            color: "#BFC1E5",
            marginTop: "30px",
            justifyContent: "flex-start",
          }}
        >
          <img src="/img/icon/profile/host_non.png" alt="hostNon" />
          <div
            style={{
              marginTop: "16px",
            }}
          >
            {t("group.profile.txt.emptyHostTitle", {
              MEETING_HOST: t(
                "group.thema." +
                  groupCategoryConvert(groupInfoState.GROUP_CATEGORY) +
                  ".meetingHost"
              ),
            })}
          </div>
          <div>
            {t("group.profile.txt.emptyHostComment", {
              MEETING_HOST: t(
                "group.thema." +
                  groupCategoryConvert(groupInfoState.GROUP_CATEGORY) +
                  ".meetingHost"
              ),
            })}
          </div>
        </div>
      </td>
    </tr>
  );
}

export default MeetingHostEmpty;
