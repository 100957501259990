import React, { useState, useEffect } from "react";
/**
 * Version : 1.0
 * 파일명 :
 * 작성일자 : 2021-10-05
 * 작성자 : 권도훈
 * 설명 :
 * 수정일자 : 2022-05-04
 * 수정자 : 강연승
 * 수정내역 : 그룹 컬러 수정
 * props :
 * 전달받은값 - 설명
 */
function Uselog_item(props) {
  const [displayTime, setDisplayTime] = useState();
  const [ampm, setAmPm] = useState();
  const [duration, setDuration] = useState("");

  useEffect(() => {
    displaytimeFormat(props.data.START_TIME);
    durationSchedule(props.data.START_TIME, props.data.END_TIME);
  }, [props.data]);

  function displaytimeFormat(t) {
    const tmpArr = t.split("T");
    const timeArr = tmpArr[1].split(":");
    let hour = timeArr[0];
    let min = timeArr[1];
    if (hour > 12) {
      hour = hour - 12;
      setAmPm("오후");
    } else if (hour === "12") {
      setAmPm("오후");
    } else if (hour < 12) {
      setAmPm("오전");
    }
    setDisplayTime(hour + ":" + min);
  }

  function durationSchedule(stime, etime) {
    var startTime = new Date(stime);
    var endTime = new Date(etime);
    var dTime = endTime - startTime;

    var h = Math.floor((dTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var m = Math.floor((dTime % (1000 * 60 * 60)) / (1000 * 60));
    var s = Math.floor((dTime % (1000 * 60)) / 1000);

    if (h === 0) {
      //1시간 미만일 때
      if (m === 0) {
        setDuration(s + "초");
      } else {
        if (s === 0) {
          setDuration(m + "분");
        } else {
          setDuration(m + "분 " + s + "초");
        }
      }
    } else {
      //1시간 이상일 때
      if (m === 0) {
        if (s === 0) {
          setDuration(h + "시간");
        } else {
          setDuration(h + "시간 " + s + "초");
        }
      } else {
        if (s === 0) {
          setDuration(h + "시간 " + m + "분");
        } else {
          setDuration(h + "시간 " + m + "분 " + s + "초");
        }
      }
    }
  }

  const groupColor = color => {
    switch (color) {
      case "red":
        return "#c63353";
      case "orange":
        return "#A54824";
      case "yellow":
        return "#cdb14c";
      case "bean":
        return "#97C12A";
      case "green":
        return "#64d483";
      case "mint":
        return "#6cd6bf";
      case "pine":
        return "#75BDD9";
      case "pink":
        return "#cb86dd";
      case "purple":
        return "#a994e1";
      case "black":
        return "#3b3f4c";
      default:
        return "#2B4AC4";
    }
  };

  return (
    <div
      className="border-hover-blue w-80 my-6 mx-auto border-white bg-white border-radius-10 cursor"
      style={{ width: "344px", height: "112px" }}
    >
      <div
        className="mx-3 p-4"
        onClick={() => props.onClick(props.data, duration)}
      >
        <div>
          <div
            className="font-bold"
            style={{
              font: "normal normal normal 20px/27px Spoqa Han Sans Neo",
              color: "#3E3E4F",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              maxWidth: "250px",
            }}
          >
            {props.data.TITLE}
          </div>
          {props.data.GROUP_YN === "Y" ? (
            <div
              className="font-12"
              style={{
                color: groupColor(props.data.GROUP_COLOR),
                marginTop: "8px",
              }}
            >
              {props.data.GROUP_NM}
            </div>
          ) : null}
        </div>
        <div
          style={{
            marginTop: props.data.GROUP_YN === "N" ? "31px" : "7px",
          }}
        >
          <div className="font-14 text-right">
            {ampm} {displayTime} ({duration})
          </div>
        </div>
      </div>
    </div>
  );
}

export default Uselog_item;
