import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import DescriptionIcon from '@material-ui/icons/Description'
/**
 * Version : 1.0
 * 파일명 : uploadFilelist.js
 * 작성일자 : 2021-10-05
 * 작성자 : 권도훈
 * 설명 : 업로드 파일 목록
 * 수정일자 :  2021-11-11
 * 수정자 : 권도훈
 * 수정내역 : 
 * props : 
 * 전달받은값 - 설명
*/
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    maxHeight: '10rem',
    overflowY: 'auto'
  },
  demo: {
    backgroundColor: 'none',
    "& > span": {
      fontWeight: "bold"
    },
  }
}));

function UploadFilelist(props) {
  const classes = useStyles();

  return (
    <div
      className={classes.root}
      style={{
        display: props.fileNM !== "" ? "block" : "none",
      }}
    >
      <Grid>
        <div className={classes.demo}>
          <List dense={true}>
            {
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    style={{
                      background: "#2B4AC4",
                    }}
                  >
                    <DescriptionIcon />
                  </Avatar>
                </ListItemAvatar>

                <ListItemText primary={props.fileNM} />

                <IconButton
                  aria-label="delete"
                  style={{
                    marginLeft: "10px"
                  }}
                  onClick={() => props.deleteEvent()}
                >
                  <img
                    src="/img/icon/address/delete_18.png"
                    alt="img"
                  />
                </IconButton>

                <IconButton
                  aria-label="upload"
                  onClick={() => props.uploadEvent()}
                >
                  <img
                    src="/img/icon/address/upload.png"
                    alt="img"
                  />
                </IconButton>
              </ListItem>
            }
          </List>
        </div>
      </Grid >
    </div >
  );
}

export default UploadFilelist