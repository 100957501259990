import React from 'react'
import { useTranslation } from 'react-i18next'
/**
 * Version : 1.0
 * 파일명 : dataEmpty.js
 * 작성일자 : 2021-12-28
 * 작성자 : 권도훈
 * 설명 : 데이터 리스트 EMPTY
 * 수정일자 : 2022-02-07
 * 수정자 : 권도훈
 * 수정내역 : 다국어 적용
*/
function DataEmpty() {
    /**
     * TRANSLATION
     */
    const { t } = useTranslation();
    return (
        <tr>
            <td>
                <div
                    className="flex-y-center"
                    style={{
                        height: "655px",
                        width: "1360px",
                        position: "absolute"
                    }}
                >
                    <img
                        src="/img/icon/landing/no_data.png"
                        alt='NO SERVICE'
                    />
                    <div>
                        {t("my.data.txt.empty")}
                    </div>
                </div>
            </td>
        </tr>
    )
}

export default DataEmpty
