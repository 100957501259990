import axios from 'axios';
import DeleteGroupAlram from 'component/tag/div/common/alramTemplate/deleteGroupAlram';
import DeleteLicenseAlram from 'component/tag/div/common/alramTemplate/deleteLicenseAlram';
import DeleteMemberAlram from 'component/tag/div/common/alramTemplate/deleteMemberAlram';
import DeleteScheduleAlram from 'component/tag/div/common/alramTemplate/deleteScheduleAlram';
import EmptyAlram from 'component/tag/div/common/alramTemplate/emptyAlram';
import EndLicenseAlram from 'component/tag/div/common/alramTemplate/endLicenseAlram';
import ImminentLicenseAlram from 'component/tag/div/common/alramTemplate/imminentLicenseAlram';
import InviteGroupAlram from 'component/tag/div/common/alramTemplate/inviteGroupAlram';
import InviteScheduleAlram from 'component/tag/div/common/alramTemplate/inviteScheduleAlram';
import UpdateLicenseAlram from 'component/tag/div/common/alramTemplate/updateLicenseAlram';
import UpdateMemberAlram from 'component/tag/div/common/alramTemplate/updateMemberAlram';
import UpdateRecordAlram from 'component/tag/div/common/alramTemplate/updateRecordAlram';
import UpdateScheduleAlram from 'component/tag/div/common/alramTemplate/updateScheduleAlram';
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { closeAlramList, updateAlramCount } from "actions/index";
import Loading from 'component/tag/loading/loading';
/**
 * Version : 1.0
 * 파일명 :
 * 작성일자 : 2021-10-12
 * 작성자 : 권도훈
 * 설명 : 알람 리스트
 * 수정일자 : 2022-02-03
 * 수정자 : 권도훈
 * 수정내역 : 크기 수정
*/
function Alarmlistpopup() {
    const alramInfo = useSelector(state => state.alramInfoReducer);
    const dispatch = useDispatch();
    /**
     * 로딩
     */
    const [loading, setLoading] = useState(false);
    /**
     * 렌더
     */
    const [render, setRender] = useState([]);
    /**
     * CLOSE EVENT
     */
    const goClose = useCallback(() => {
        dispatch(closeAlramList())
    }, [dispatch]);

    const dateFormat = useCallback((data) => {
        const label = ['월요일', '화요일', '수요일', '목요일', '금요일', '토요일', '일요일'];

        const today = new Date();
        const currentDate = new Date(today.getFullYear(), today.getMonth() + 1, today.getDate());

        const dateSplit = data.split("-");
        const alramDate = new Date(dateSplit[0], dateSplit[1], dateSplit[2]);

        const diff = currentDate.getTime() - alramDate.getTime();
        const dayCount = diff / (1000 * 60 * 60 * 24);
        let labelCount = dayCount % 7;

        if (today.getDay() - labelCount > 0) {
            labelCount = today.getDay() - labelCount - 1;
        } else {
            labelCount = 8 - labelCount;
        }

        if (dayCount === 0) {
            return "오늘"
        } else if (dayCount === 1) {
            return "어제"
        } else {
            return dateSplit[0] + ". " + dateSplit[1] + ". " + dateSplit[2] + " " + label[labelCount];
        }
    }, []);
    /**
     * ADD CLOSE EVENT LISTENER
     */
    useEffect(() => {
        if (alramInfo.display) {
            window.addEventListener("click", goClose);
            return () => window.removeEventListener("click", goClose);
        }
    }, [alramInfo.display, goClose]);
    /**
     * 알람 리스트 설정
     */
    useEffect(() => {
        if (alramInfo.display) {
            setLoading(true);

            const config = {
                params: {
                    READ_YN: "N",
                    GROUP_NUM: ""
                }
            }

            axios.get("/alram/list", config)
                .then(res => {
                    setLoading(false);
                    if (res.status === 200) {
                        if (res.data.length > 0) {
                            axios.put("/alram/list", null, config)
                                .then(res => {
                                    if (res.status === 200) {
                                        dispatch(updateAlramCount("0"));
                                    }
                                })
                                .catch(err => {
                                    console.log(err);
                                })

                            let render = [];
                            for (let i = 0; i < res.data.length; i++) {
                                switch (res.data[i].ALRAM_TYPE) {
                                    case "CD0601": render.push(<InviteScheduleAlram key={res.data[i].ALRAM_NUM} data={res.data[i]} date={dateFormat(res.data[i].CREATE_DT)} />); break;
                                    case "CD0602": render.push(<InviteGroupAlram key={res.data[i].ALRAM_NUM} data={res.data[i]} date={dateFormat(res.data[i].CREATE_DT)} />); break;
                                    case "CD0603": render.push(<UpdateScheduleAlram key={res.data[i].ALRAM_NUM} data={res.data[i]} date={dateFormat(res.data[i].CREATE_DT)} />); break;
                                    case "CD0604": render.push(<DeleteScheduleAlram key={res.data[i].ALRAM_NUM} data={res.data[i]} date={dateFormat(res.data[i].CREATE_DT)} />); break;
                                    case "CD0605": render.push(<DeleteGroupAlram key={res.data[i].ALRAM_NUM} data={res.data[i]} date={dateFormat(res.data[i].CREATE_DT)} />); break;
                                    case "CD0606": render.push(<UpdateMemberAlram key={res.data[i].ALRAM_NUM} data={res.data[i]} date={dateFormat(res.data[i].CREATE_DT)} />); break;
                                    case "CD0607": render.push(<DeleteMemberAlram key={res.data[i].ALRAM_NUM} data={res.data[i]} date={dateFormat(res.data[i].CREATE_DT)} />); break;
                                    case "CD0608": render.push(<UpdateLicenseAlram key={res.data[i].ALRAM_NUM} data={res.data[i]} date={dateFormat(res.data[i].CREATE_DT)} />); break;
                                    case "CD0609": render.push(<DeleteLicenseAlram key={res.data[i].ALRAM_NUM} data={res.data[i]} date={dateFormat(res.data[i].CREATE_DT)} />); break;
                                    case "CD0610": render.push(<UpdateRecordAlram key={res.data[i].ALRAM_NUM} data={res.data[i]} date={dateFormat(res.data[i].CREATE_DT)} />); break;
                                    case "CD0611": render.push(<ImminentLicenseAlram key={res.data[i].ALRAM_NUM} data={res.data[i]} date={dateFormat(res.data[i].CREATE_DT)} />); break;
                                    case "CD0612": render.push(<EndLicenseAlram key={res.data[i].ALRAM_NUM} data={res.data[i]} date={dateFormat(res.data[i].CREATE_DT)} />); break;
                                    default: render.push(<></>); break;
                                }
                            }
                            setRender(render);

                        } else {
                            setRender(<EmptyAlram />);
                        }
                    } else {
                        setRender(<EmptyAlram />);
                    }
                }
                ).catch(() => {
                    setLoading(false);
                    setRender(<EmptyAlram />);
                })
        } else {
            setRender(<></>);
        }
    }, [alramInfo.display, dateFormat, dispatch]);
    /**
     * 태그
     */
    return (
        <div
            id="alarm_Div"
            className="alarm_Div scrollbar"
            style={{
                display: alramInfo.display ? "block" : "none",
                transform: "translate(870px, 96px)"
            }}
            onClick={e => e.stopPropagation()}
        >
            {
                loading
                    ?
                    <div
                        style={{
                            height: "69px"
                        }}
                    >
                        <Loading />
                    </div>
                    :
                    render
            }
        </div>
    )
}

export default Alarmlistpopup
