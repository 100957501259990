import { Avatar } from "@material-ui/core";
import axios from "axios";
import Paging from "component/tag/div/common/paging";
import Loading from "component/tag/loading/loading";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import DataEmpty from "./addressEmpty";
import FileuploaderSingle from "component/tag/file/common/fileuploaderSingle";
import UploadFilelist from "component/tag/list/common/uploadFilelist";
import Alert from "component/tag/modal/alert";
import Addresscategorypopup from "component/tag/popup/my/address/addresscategorypopup";
import AddressDetailPopup from "component/tag/popup/my/address/addressDetailPopup";
/**
 * Version : 1.0
 * 파일명 : addressList.js
 * 작성일자 : 2021-09-30
 * 작성자 : 권도훈
 * 설명 : 주소록 LIST 타입
 * 수정일자 : 2022-05-04
 * 수정자 : 강연승
 * 수정내역 : 그룹 컬러 수정
 */
function AddressList() {
  /**
   * 언어변환
   */
  const { t } = useTranslation();
  /**
   * REDUCER
   */
  const REDUCER_STATE = useSelector(state => state.groupInfoReducer);
  const USER_STATE = useSelector(state => state.userInfoReducer);
  /**
   * data
   */
  const [data, setData] = useState([]);
  /**
   * 팝업
   */
  const [isPopup, setIsPopup] = useState(false);
  const [popupType, setPopupType] = useState("ADD");
  const [popupFavorite, setPopupFavorite] = useState(false);
  const [popupInfo, setPopupInfo] = useState({
    INDEX: 0,
    ADR_NUM: "",
    NAME: "",
    EAMIL: "",
    PHONE: "",
    MEMO: "",
    CATEGORY: [],
    FAVORITE_YN: "N",
    COLOR: "",
    IMG: "",
  });
  const [isExcel, setIsExcel] = useState(false);
  /**
   * 로딩
   */
  const [loading, setLoading] = useState(false);
  /**
   * 페이지 정보
   */
  const [page, setPage] = useState({
    ROW_COUNT: 10,
    PAGE_COUNT: 0,
  });
  const [total, setTotal] = useState(0);
  /**
   * 검색 정보
   */
  const [searchText, setSearchText] = useState("");
  const textRef = useRef();
  /**
   * 체크박스
   */
  const [checkedInputs, setCheckedInputs] = useState([]);
  /**
   * 정렬 종류, 정렬 순서
   */
  const [sortType, setSortType] = useState("name");
  const [sortOrder, setSortOrder] = useState("ASC");
  /**
   * 엑셀 파일 정보
   */
  const [excelInfo, setExcelInfo] = useState({
    name: "",
    base64: "",
    size: "",
  });
  /**
   * 구분 팝업 설정
   */
  const [isCategory, setIsCategory] = useState(false);
  const [category, setCategory] = useState("");
  /**
   * 알림창 설정
   */
  const [alertProps, setAlertProps] = useState({
    text: "",
    isShow: false,
    cancelBtnDisplay: false,
    okBtnDisplay: true,
    okBtnEventHandler: function () {},
    cancleBtnEventHandler: function () {
      closeAlertEvent();
    },
  });
  /**
   * 사용자아이콘 컬러정보(임시)
   */
  const [colorLabel] = useState([
    "#D4667E",
    "#DC82C3",
    "#CD704B",
    "#BD9D29",
    "#97C12A",
    "#28B750",
    "#27B395",
    "#6E85D7",
    "#9479DA",
    "#2B4AC4",
  ]);
  /**
   * 알림창 닫기
   */
  const closeAlertEvent = useCallback(() => {
    setAlertProps({
      ...alertProps,
      isShow: false,
    });
  }, [alertProps]);
  /**
   * GET ADDRESS TOTAL
   */
  const getAddressTotal = useCallback(() => {
    setLoading(true);

    const config = {
      params: {
        Group_YN: REDUCER_STATE.GROUP_YN,
        Group_Num: REDUCER_STATE.GROUP_NUM,
        Search_Text: searchText,
        page_Row: page.ROW_COUNT,
        page_Now: page.PAGE_COUNT,
        Adr_Type_Num: category,
      },
    };

    let url = "";

    if (category === "") {
      url = "/address/AddressCount";
    } else {
      url = "/address/AddressTypeCount";
    }

    axios
      .get(url, config)
      .then(res => {
        if (res.status === 200) {
          setTotal(res.data[0].ADRCNT);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [
    REDUCER_STATE.GROUP_NUM,
    REDUCER_STATE.GROUP_YN,
    category,
    page.PAGE_COUNT,
    page.ROW_COUNT,
    searchText,
  ]);
  /**
   * GET ADDRESS
   */
  const getAddressData = useCallback(() => {
    setLoading(true);

    const config = {
      params: {
        Group_YN: REDUCER_STATE.GROUP_YN,
        Group_Num: REDUCER_STATE.GROUP_NUM,
        Search_Text: searchText,
        page_Row: page.ROW_COUNT,
        page_Now: page.PAGE_COUNT,
        Adr_Type_Num: category,
      },
    };

    let url = "";

    if (category === "") {
      url = "/address/Address";
    } else {
      url = "/address/Filter";
    }

    axios
      .get(url, config)
      .then(res => {
        if (res.status === 200) {
          for (let i = 0; i < res.data.length; i++) {
            let type = [];

            if (res.data[i].ADR_TYPE_NM !== undefined) {
              const step = res.data[i].ADR_TYPE_NM.split("/");

              for (let j = 0; j < step.length; j++) {
                const step2 = step[j].split("#")[0].split("@");
                type.push({
                  type_cd: step2[0],
                  type_nm: step2[1],
                  type_bgc: step[j].split("#")[1],
                });
              }
            }

            res.data[i].COLOR = colorLabel[i];
            res.data[i].TYPESPLIT = type;
          }

          setData(res.data);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [
    REDUCER_STATE.GROUP_NUM,
    REDUCER_STATE.GROUP_YN,
    category,
    colorLabel,
    page.PAGE_COUNT,
    page.ROW_COUNT,
    searchText,
  ]);
  /**
   * 주소록 즐겨찾기
   */
  const favoriteAddress = useCallback(
    (adrNum, favoriteYN) => {
      setLoading(true);

      const config = {
        params: {
          Address_Num: adrNum,
          Favorite_YN: favoriteYN === "Y" ? "N" : "Y",
        },
      };

      axios.put("/address/Favorite", null, config).then(res => {
        setLoading(false);

        if (res.status === 200) {
          if (!isPopup) {
            getAddressData();
            getAddressTotal();
          }
        }
      });
    },
    [getAddressData, getAddressTotal, isPopup]
  );
  /**
   * 엑셀 팝업닫기
   */
  const closeExcelPopup = useCallback(() => {
    setIsExcel(false);
    deleteExcelFileInfo();
  }, []);
  /**
   * 필터 팝업닫기
   */
  const closeCategoryPopup = useCallback(() => {
    setIsCategory(false);
  }, []);
  /**
   * 필터 팝업닫기
   */
  const closePopup = useCallback(() => {
    setIsPopup(false);
  }, []);
  /**
   * 조회
   */
  useEffect(() => {
    getAddressData();
    getAddressTotal();
  }, [getAddressData, getAddressTotal]);

  useEffect(() => {
    if (popupFavorite && !isPopup) {
      getAddressData();
      setPopupFavorite(false);
    }
  }, [getAddressData, isPopup, popupFavorite]);
  /**
   * 검색 방식 닫기 이벤트 추가
   */
  useEffect(() => {
    if (isExcel) {
      window.addEventListener("click", closeExcelPopup);
      return () => {
        window.removeEventListener("click", closeExcelPopup);
      };
    }
  }, [closeExcelPopup, isExcel]);
  /**
   * 필터 닫기 이벤트 추가
   */
  useEffect(() => {
    if (isCategory) {
      window.addEventListener("click", closeCategoryPopup);
      return () => {
        window.removeEventListener("click", closeCategoryPopup);
      };
    }
  }, [closeCategoryPopup, isCategory]);
  /**
   * 팝업 닫기 이벤트 추가
   */
  useEffect(() => {
    if (isPopup) {
      window.addEventListener("click", closePopup);
      return () => {
        window.removeEventListener("click", closePopup);
      };
    }
  }, [closePopup, isPopup]);
  /**
   * 체크박스 클릭이벤트
   */
  const clickCheckbox = (checked, id) => {
    if (checked) {
      setCheckedInputs([...checkedInputs, id]);
    } else {
      setCheckedInputs(checkedInputs.filter(el => el !== id));
    }
  };
  const clickCheckboxAll = checked => {
    if (checked) {
      let num = [];

      for (let i = 0; i < data.length; i++) {
        num.push(data[i].ADR_NUM);
      }

      setCheckedInputs(num);
    } else {
      setCheckedInputs([]);
    }
  };
  /**
   * 멀티 삭제
   */
  const deleteCheckedAddress = () => {
    setAlertProps({
      ...alertProps,
      text:
        REDUCER_STATE.GROUP_YN === "N"
          ? t("my.address.msg.confirm.delete.CD0401")
          : t("my.address.msg.confirm.delete." + REDUCER_STATE.GROUP_CATEGORY),
      isShow: true,
      cancelBtnDisplay: true,
      okBtnEventHandler: function () {
        setLoading(true);
        closeAlertEvent();

        let str = "";

        for (let i = 0; i < checkedInputs.length; i++) {
          str = str + checkedInputs[i] + ",";
        }

        const config = {
          params: {
            Address_Num: str,
          },
        };

        axios.delete("/address/Address", config).then(res => {
          setLoading(false);

          if (res.status === 200) {
            setCheckedInputs([]);
            getAddressData();
            getAddressTotal();
            setAlertProps({
              ...alertProps,
              isShow: true,
              text: t("my.address.msg.result.delete"),
              cancelBtnDisplay: false,
              okBtnEventHandler: function () {
                closeAlertEvent();
              },
            });
          }
        });
      },
      cancleBtnEventHandler: function () {
        closeAlertEvent();
      },
    });
  };
  /**
   * 주소록 삭제
   */
  const deleteAddress = num => {
    setAlertProps({
      ...alertProps,
      text:
        REDUCER_STATE.GROUP_YN === "N"
          ? t("my.address.msg.confirm.delete.CD0401")
          : t("my.address.msg.confirm.delete." + REDUCER_STATE.GROUP_CATEGORY),
      isShow: true,
      cancelBtnDisplay: true,
      okBtnEventHandler: function () {
        setLoading(true);
        closeAlertEvent();

        const config = {
          params: {
            Address_Num: num,
          },
        };

        axios.delete("/address/Address", config).then(res => {
          setLoading(false);

          if (res.status === 200) {
            setCheckedInputs([]);
            getAddressData();
            getAddressTotal();
            setAlertProps({
              ...alertProps,
              isShow: true,
              text: t("my.address.msg.result.delete"),
              cancelBtnDisplay: false,
              okBtnEventHandler: function () {
                closeAlertEvent();
              },
            });
          }
        });
      },
      cancleBtnEventHandler: function () {
        closeAlertEvent();
      },
    });
  };
  /**
   * 정렬 이벤트
   */
  const clickSortBtn = type => {
    setSortType(type);

    if (sortOrder === "ASC") {
      setSortOrder("DESC");
      if (type === "name") {
        data.sort(function (a, b) {
          if (a.FAVORITE_YN === "Y" || b.FAVORITE_YN === "Y") {
            return 0;
          } else if (a.ADR_NM > b.ADR_NM) {
            return -1;
          } else {
            return 0;
          }
        });
      } else if (type === "mail") {
        data.sort(function (a, b) {
          if (a.FAVORITE_YN === "Y" || b.FAVORITE_YN === "Y") {
            return 0;
          } else if (a.ADR_EMAIL > b.ADR_EMAIL) {
            return -1;
          } else {
            return 0;
          }
        });
      } else if (type === "tel") {
        data.sort(function (a, b) {
          if (a.FAVORITE_YN === "Y" || b.FAVORITE_YN === "Y") {
            return 0;
          } else if (a.ADR_PHONE > b.ADR_PHONE) {
            return -1;
          } else {
            return 0;
          }
        });
      }
    } else {
      setSortOrder("ASC");
      if (type === "name") {
        data.sort(function (a, b) {
          if (a.FAVORITE_YN === "Y" || b.FAVORITE_YN === "Y") {
            return 0;
          } else if (b.ADR_NM > a.ADR_NM) {
            return -1;
          } else {
            return 0;
          }
        });
      } else if (type === "mail") {
        data.sort(function (a, b) {
          if (a.FAVORITE_YN === "Y" || b.FAVORITE_YN === "Y") {
            return 0;
          } else if (b.ADR_EMAIL > a.ADR_EMAIL) {
            return -1;
          } else {
            return 0;
          }
        });
      } else if (type === "tel") {
        data.sort(function (a, b) {
          if (a.FAVORITE_YN === "Y" || b.FAVORITE_YN === "Y") {
            return 0;
          } else if (b.ADR_PHONE > a.ADR_PHONE) {
            return -1;
          } else {
            return 0;
          }
        });
      }
    }
  };
  /**
   * 색상 컨버터
   */
  const colorConverter = color => {
    switch (color) {
      case "red":
        return "#D4667E";
      case "pink":
        return "#DC82C3";
      case "orange":
        return "#CD704B";
      case "yellow":
        return "#BD9D29";
      case "lightgreen":
        return "#97C12A";
      case "green":
        return "#28B750";
      case "mint":
        return "#27B395";
      case "blue":
        return "#6E85D7";
      case "purple":
        return "#9479DA";
      default:
        return "#2B4AC4";
    }
  };
  /**
   * 연락처 양식 다운로드
   */
  const downloadExcelForm = () => {
    const link = document.createElement("a");
    link.href = "/file/address_template.xlsx";
    link.setAttribute("download", "SoBridge_연락처_양식.xlsx");
    document.body.appendChild(link);
    link.click();
  };
  /**
   * 엑셀 파일 업로드
   */
  const uploadEcxcel = () => {
    setAlertProps({
      ...alertProps,
      text: t("my.address.msg.confirm.upload"),
      isShow: true,
      cancelBtnDisplay: true,
      okBtnEventHandler: function () {
        setLoading(true);
        closeAlertEvent();

        const groupinfo = {
          Group_YN: REDUCER_STATE.GROUP_YN,
          Group_Num: REDUCER_STATE.GROUP_NUM,
        };

        const formData = new FormData();
        formData.append("files", excelInfo);
        formData.append("groupInfo", JSON.stringify(groupinfo));

        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        axios
          .post("/address/AddressExcel", formData, config)
          .then(res => {
            setLoading(false);

            if (res.status === 200) {
              setAlertProps({
                ...alertProps,
                isShow: true,
                text: t("my.address.msg.result.upload"),
                cancelBtnDisplay: false,
                okBtnEventHandler: function () {
                  closeAlertEvent();
                  setIsExcel(false);
                  getAddressData();
                  getAddressTotal();
                },
              });
            }
          })
          .catch(err => {
            setLoading(false);

            if (err.response.status === 400) {
              setAlertProps({
                ...alertProps,
                isShow: true,
                text: t("my.address.msg.err.file.type"),
                cancelBtnDisplay: false,
                okBtnEventHandler: function () {
                  closeAlertEvent();
                  setIsExcel(false);
                  getAddressData();
                  getAddressTotal();
                },
              });
            } else if (err.response.status === 417) {
              setAlertProps({
                ...alertProps,
                isShow: true,
                text: t("my.address.msg.err.file.upload"),
                cancelBtnDisplay: false,
                okBtnEventHandler: function () {
                  closeAlertEvent();
                  setIsExcel(false);
                  getAddressData();
                  getAddressTotal();
                },
              });
            }
          });
      },
      cancleBtnEventHandler: function () {
        closeAlertEvent();
      },
    });
  };
  /**
   * 엑셀 파일 다운로드
   */
  const downloadExcel = () => {
    setAlertProps({
      ...alertProps,
      text: t("my.address.msg.confirm.download"),
      isShow: true,
      cancelBtnDisplay: true,
      okBtnEventHandler: function () {
        setLoading(true);
        closeAlertEvent();

        const config = {
          params: {
            Group_YN: REDUCER_STATE.GROUP_YN,
            Group_Num: REDUCER_STATE.GROUP_NUM,
          },
          responseType: "blob",
        };

        axios.get("/address/AddressExcelDownload", config).then(res => {
          setLoading(false);

          if (res.status === 200) {
            const url = window.URL.createObjectURL(
              new Blob([res.data], { type: res.headers["content-type"] })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileNameConverter());
            document.body.appendChild(link);
            link.click();
          }
        });
      },
      cancleBtnEventHandler: function () {
        closeAlertEvent();
      },
    });
  };
  /**
   * 업로드한 파일 정보 제거
   */
  const deleteExcelFileInfo = () => {
    setExcelInfo({
      name: "",
      base64: "",
      size: "",
    });
  };
  /**
   * 필터링 전체
   */
  const filterAll = () => {
    setIsCategory(false);
    setSearchText("");
    setCategory("");
  };
  /**
   * 특정 필터링
   */
  const filterCategory = typeNum => {
    setIsCategory(false);
    setSearchText("");
    setPage({
      ...page,
      PAGE_COUNT: 0,
    });
    setCategory(typeNum);
  };
  /**
   * 위치 계산
   */
  const calculateHeight = index => {
    let val = 0;

    if (index === 0) {
      val = 322;
    } else {
      if (index === 8 || index === 9) {
        val = 197 + 54 * index + 10 * index;
      } else {
        val = 322 + 54 * index + 10 * index;
      }
    }

    return "translate(80px," + val + "px)";
  };
  /**
   * 연락처 파일명 컨버터
   */
  const fileNameConverter = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month =
      today.getMonth() + 1 < 10
        ? "0" + (today.getMonth() + 1)
        : today.getMonth() + 1;
    const day = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();
    const result = year + "." + month + "." + day;

    if (REDUCER_STATE.GROUP_YN === "Y") {
      return (
        REDUCER_STATE.GROUP_NM +
        t("my.address.txt.filename." + REDUCER_STATE.GROUP_CATEGORY) +
        "(" +
        result +
        ").xlsx"
      );
    } else {
      return (
        USER_STATE.NAME +
        t("my.address.txt.filename.CD0401") +
        "(" +
        result +
        ").xlsx"
      );
    }
  };
  /**
   * 그룹 테마 텍스트에 따른 left 수치 변경
   */
  const excelUploadPopupSizeConverter = groupThema => {
    switch (groupThema) {
      case "CD0401":
        return "239px";
      case "CD0402":
        return "239px";
      case "CD0403":
        return "222px";
      case "CD0404":
        return "239px";
      case "CD0405":
        return "239px";
      case "CD0406":
        return "222px";
      default:
        return "";
    }
  };

  /**
   * TAG
   */
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Alert alertProps={alertProps} />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          alignItems: "center",
          margin: "0 37px 13px 45px",
          paddingTop: "8px",
          width: "1330px",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "56px",
            }}
          >
            <div
              style={{
                color: "#3B3F4C",
                font: "normal normal bold 16px/21px Spoqa Han Sans Neo",
              }}
            >
              {REDUCER_STATE.GROUP_YN === "N"
                ? t("my.address.txt.header.CD0401", {
                    TOTAL: total,
                  })
                : t("my.address.txt.header." + REDUCER_STATE.GROUP_CATEGORY, {
                    TOTAL: total,
                  })}
            </div>

            <button
              className={
                loading || isExcel || isCategory || isPopup
                  ? REDUCER_STATE.GROUP_YN === "N" ||
                    REDUCER_STATE.GROUP_YN === ""
                    ? "address_addBtn"
                    : " address_addBtn_" + REDUCER_STATE.GROUP_COLOR
                  : REDUCER_STATE.GROUP_YN === "N" ||
                    REDUCER_STATE.GROUP_YN === ""
                  ? "address_addBtn cursor"
                  : "cursor address_addBtn_" + REDUCER_STATE.GROUP_COLOR
              }
              style={{
                marginLeft: "24px",
              }}
              disabled={loading || isExcel || isCategory || isPopup}
              onClick={e => {
                e.stopPropagation();
                setPopupType("ADD");
                setIsPopup(!isPopup);
                setPopupInfo({
                  INDEX: 0,
                  ADR_NUM: "",
                  NAME: "",
                  EAMIL: "",
                  PHONE: "",
                  MEMO: "",
                  CATEGORY: [],
                  FAVORITE_YN: "N",
                  COLOR: "",
                  IMG: "",
                });
              }}
            >
              <img
                src={
                  loading || isExcel || isCategory || isPopup
                    ? REDUCER_STATE.GROUP_YN === "N" ||
                      REDUCER_STATE.GROUP_YN === ""
                      ? "/img/icon/address/add_inactive.png"
                      : "/img/icon/address/add_" +
                        REDUCER_STATE.GROUP_COLOR +
                        ".png"
                    : REDUCER_STATE.GROUP_YN === "N" ||
                      REDUCER_STATE.GROUP_YN === ""
                    ? "/img/icon/address/add.png"
                    : "/img/icon/address/add_" +
                      REDUCER_STATE.GROUP_COLOR +
                      ".png"
                }
                alt="img"
                style={{
                  position: "relative",
                  float: "left",
                  left: "8px",
                  top: "2px",
                }}
              />
              <font
                style={{
                  position: "relative",
                  left: "3px",
                }}
              >
                {REDUCER_STATE.GROUP_YN === "N"
                  ? t("my.address.txt.add.CD0401")
                  : t("my.address.btn.add." + REDUCER_STATE.GROUP_CATEGORY)}
              </font>
            </button>

            <button
              className={
                loading || isExcel || isCategory || isPopup
                  ? "address_addBtn"
                  : "address_addBtn cursor"
              }
              style={{
                marginLeft: "16px",
                width: "161px",
                display: checkedInputs.length > 0 ? "block" : "none",
              }}
              onClick={() => deleteCheckedAddress()}
              disabled={loading || isExcel || isCategory || isPopup}
            >
              <img
                src="/img/icon/address/delete_18.png"
                alt="img"
                style={{
                  position: "relative",
                  float: "left",
                  left: "8px",
                  top: "1px",
                }}
              />
              <font
                style={{
                  position: "relative",
                  left: "3px",
                }}
              >
                {REDUCER_STATE.GROUP_YN === "N"
                  ? t("my.address.btn.delete.CD0401")
                  : t("my.address.btn.delete." + REDUCER_STATE.GROUP_CATEGORY)}
              </font>
            </button>
          </div>

          <div
            style={{
              width: "440px",
              height: "56px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              position: "relative",
            }}
          >
            <input
              style={{
                width: "314px",
                height: "36px",
                backgroundColor: "#FFFFFF",
                border: "1px solid #EDEDF0",
                borderRadius: "8px",
                outline: "none",
                fontSize: "14px",
              }}
              type="text"
              placeholder={t("my.address.placeholder.search")}
              readOnly={loading || isExcel || isCategory || isPopup}
              ref={textRef}
              onChange={e => {
                if (e.target.value === "") {
                  setSearchText(e.target.value);
                }
              }}
              onKeyDown={e => {
                if (e.key === "Enter") {
                  setSearchText(e.target.value);
                }
              }}
            />

            <button
              className={
                REDUCER_STATE.GROUP_YN === "N" || REDUCER_STATE.GROUP_YN === ""
                  ? loading ||
                    isExcel ||
                    isCategory ||
                    isPopup ||
                    data.length < 1
                    ? "address_searchBtn drag-none"
                    : "address_searchBtn cursor"
                  : loading ||
                    isExcel ||
                    isCategory ||
                    isPopup ||
                    data.length < 1
                  ? "groupAddress_searchBtn drag-none"
                  : "groupAddress_searchBtn cursor"
              }
              onClick={() => setSearchText(textRef.current.value)}
              disabled={
                loading || isExcel || isCategory || isPopup || data.length < 1
              }
            >
              {t("my.address.btn.search")}
            </button>

            <button
              className={
                REDUCER_STATE.GROUP_YN === "N" || REDUCER_STATE.GROUP_YN === ""
                  ? loading ||
                    isExcel ||
                    isCategory ||
                    isPopup ||
                    data.length < 1
                    ? "address_downloadBtn drag-none"
                    : "address_downloadBtn cursor"
                  : loading ||
                    isExcel ||
                    isCategory ||
                    isPopup ||
                    data.length < 1
                  ? "groupAddress_downloadBtn drag-none"
                  : "groupAddress_downloadBtn cursor"
              }
              disabled={
                loading || isExcel || isCategory || isPopup || data.length < 1
              }
              onClick={() => downloadExcel()}
            >
              <img src="/img/icon/address/download.png" alt="img" />
            </button>

            <button
              className={
                REDUCER_STATE.GROUP_YN === "N" || REDUCER_STATE.GROUP_YN === ""
                  ? loading || isCategory || isPopup
                    ? "address_downloadBtn drag-none"
                    : "address_downloadBtn cursor"
                  : loading || isCategory || isPopup
                  ? "groupAddress_downloadBtn drag-none"
                  : "groupAddress_downloadBtn cursor"
              }
              // className={
              //   loading || isCategory || isPopup
              //     ? // ? "address_addBtn"
              //       // : "address_addBtn cursor"
              //       "address_downloadBtn drag-none"
              //     : "address_downloadBtn cursor"
              // }
              disabled={loading || isCategory || isPopup}
              onClick={() => {
                deleteExcelFileInfo();
                setIsExcel(!isExcel);
              }}
            >
              <img src="/img/icon/address/upload_wh.png" alt="" />
            </button>

            {isExcel ? (
              <div
                id="excelUploadPopup"
                className="adr-excel-upload"
                onClick={e => e.stopPropagation()}
              >
                <img
                  className="adr-excel-upload-close cursor"
                  src="/img/icon/address/close.png"
                  alt=""
                  onClick={() => {
                    if (excelInfo.name !== "") {
                      deleteExcelFileInfo();
                    }
                    setIsExcel(false);
                  }}
                />

                <div className="adr-excel-upload-inner">
                  <section className="adr-excel-upload-section">
                    <p>엑셀 업로드</p>
                    <p>엑셀 파일을 업로드하여 연락처를 한 번에 등록하세요.</p>
                  </section>

                  <div className="adr-excel-upload-content">
                    {excelInfo.name === "" ? (
                      <>
                        <button
                          className="adr-excel-download-btn cursor"
                          onClick={() => downloadExcelForm()}
                        >
                          {REDUCER_STATE.GROUP_YN === "N"
                            ? t("my.address.txt.download.form.CD0401")
                            : t(
                                "my.address.txt.download.form." +
                                  REDUCER_STATE.GROUP_CATEGORY
                              )}
                        </button>

                        <label
                          htmlFor="fileuploaderSingle"
                          className="adr-excel-select-btn cursor"
                        >
                          {t("my.address.txt.file")}
                        </label>
                      </>
                    ) : (
                      <>
                        <div className="adr-excel-fileinfo">
                          <div className="adr-excel-fileinfo-inner">
                            <div>
                              <img
                                src="/img/icon/address/file_icon.png"
                                alt=""
                              />
                              <div>{excelInfo.name}</div>
                            </div>

                            <img
                              className="cursor"
                              src="/img/icon/address/delete_36.png"
                              alt=""
                              onClick={() => deleteExcelFileInfo()}
                            />
                          </div>
                        </div>

                        <button
                          className="adr-excel-upload-btn cursor"
                          onClick={() => uploadEcxcel()}
                        >
                          업로드
                        </button>
                      </>
                    )}
                  </div>

                  <FileuploaderSingle
                    type="excel"
                    setFileInfo={setExcelInfo}
                    fileType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    fileSize="10485760"
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>

        {loading ? (
          <div
            style={{
              height: "744px",
            }}
          >
            <Loading />
          </div>
        ) : (
          <div
            style={{
              height: "744px",
              width: "1330px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "0 37px",
            }}
          >
            {isPopup ? (
              <div
                style={{
                  display: isPopup ? "block" : "none",
                  position: "absolute",
                  inset: "0px auto auto 0px",
                  transform: calculateHeight(popupInfo.INDEX),
                  background: "#FFFFFF",
                  height: "180px",
                  width: "1330px",
                  boxShadow: "0 5px 16px rgb(0 0 0 / 16%)",
                  borderRadius: "12px",
                  zIndex: "300",
                }}
                onClick={e => e.stopPropagation()}
              >
                <AddressDetailPopup
                  isPopup={isPopup}
                  setIsPopup={setIsPopup}
                  type={popupType}
                  info={popupInfo}
                  colorConverter={colorConverter}
                  deleteAddress={deleteAddress}
                  popupFavorite={popupFavorite}
                  setPopupFavorite={setPopupFavorite}
                  getAddressData={getAddressData}
                  getAddressTotal={getAddressTotal}
                />
              </div>
            ) : (
              <></>
            )}

            <table
              style={{
                width: "100%",
                maxHeight: "680px",
                borderCollapse: "separate",
                borderSpacing: "0px 10px",
                opacity: isPopup ? "0.6" : "1",
              }}
            >
              <colgroup>
                <col width="8px" />
                <col width="48px" />
                <col width="24px" />
                <col width="64px" />
                <col width="16px" />
                <col width="128px" />
                <col width="16px" />
                <col width="228px" />
                <col width="16px" />
                <col width="176px" />
                <col width="16px" />
                <col width="422px" />
                <col width="32px" />
                <col width="136px" />
              </colgroup>

              <thead>
                <tr
                  style={{
                    backgroundColor: "#FFFFFF",
                    color:
                      REDUCER_STATE.GROUP_YN === "N" ||
                      REDUCER_STATE.GROUP_YN === ""
                        ? "#2B4AC4"
                        : "#3B3F4C",
                    fontWeight: "bold",
                    fontSize: "16px",
                    height: "56px",
                  }}
                >
                  <th
                    style={{
                      borderTopLeftRadius: "10px",
                      borderBottomLeftRadius: "10px",
                    }}
                  />
                  <th>
                    <input
                      className="cursor address_checkbox"
                      type="checkbox"
                      name="Adr_Chk"
                      id="Adr_Chk_ALL"
                      onChange={e => {
                        clickCheckboxAll(e.currentTarget.checked);
                      }}
                      checked={checkedInputs.length === 0 ? false : true}
                    />
                  </th>
                  <th />
                  <th className="text-left">
                    {t("my.address.txt.table.profile")}
                  </th>
                  <th />
                  <th className="text-left">
                    {t("my.address.txt.table.name")}
                    {sortType === "name" && sortOrder === "DESC" ? (
                      <img
                        className="cursor address_header_icon"
                        onClick={() => clickSortBtn("name")}
                        src={
                          loading
                            ? "/img/icon/address/sort_up_disable.png"
                            : REDUCER_STATE.GROUP_YN === "N" ||
                              REDUCER_STATE.GROUP_YN === ""
                            ? "/img/icon/address/sort_up.png"
                            : "/img/icon/common/sort_up.png"
                        }
                        alt="name_sort_up"
                      />
                    ) : (
                      <img
                        className="cursor address_header_icon"
                        onClick={() => clickSortBtn("name")}
                        src={
                          loading
                            ? "/img/icon/address/sort_down_disable.png"
                            : REDUCER_STATE.GROUP_YN === "N" ||
                              REDUCER_STATE.GROUP_YN === ""
                            ? "/img/icon/address/sort_down.png"
                            : "/img/icon/common/sort_down.png"
                        }
                        alt="name_sort_down"
                      />
                    )}
                  </th>
                  <th />
                  <th className="text-left">
                    {t("my.address.txt.table.email")}
                    {sortType === "mail" && sortOrder === "DESC" ? (
                      <img
                        className="cursor address_header_icon"
                        onClick={() => clickSortBtn("mail")}
                        src={
                          loading
                            ? "/img/icon/address/sort_up_disable.png"
                            : REDUCER_STATE.GROUP_YN === "N" ||
                              REDUCER_STATE.GROUP_YN === ""
                            ? "/img/icon/address/sort_up.png"
                            : "/img/icon/common/sort_up.png"
                        }
                        alt="mail_sort_up"
                      />
                    ) : (
                      <img
                        className="cursor address_header_icon"
                        onClick={() => clickSortBtn("mail")}
                        src={
                          loading
                            ? "/img/icon/address/sort_down_disable.png"
                            : REDUCER_STATE.GROUP_YN === "N" ||
                              REDUCER_STATE.GROUP_YN === ""
                            ? "/img/icon/address/sort_down.png"
                            : "/img/icon/common/sort_down.png"
                        }
                        alt="mail_sort_down"
                      />
                    )}
                  </th>
                  <th />
                  <th className="text-left bg-white">
                    {t("my.address.txt.table.tel")}
                    {sortType === "tel" && sortOrder === "DESC" ? (
                      <img
                        className="cursor address_header_icon"
                        onClick={() => clickSortBtn("tel")}
                        src={
                          loading
                            ? "/img/icon/address/sort_up_disable.png"
                            : REDUCER_STATE.GROUP_YN === "N" ||
                              REDUCER_STATE.GROUP_YN === ""
                            ? "/img/icon/address/sort_up.png"
                            : "/img/icon/common/sort_up.png"
                        }
                        alt="tel_sort_up"
                      />
                    ) : (
                      <img
                        className="cursor address_header_icon"
                        onClick={() => clickSortBtn("tel")}
                        src={
                          loading
                            ? "/img/icon/address/sort_down_disable.png"
                            : REDUCER_STATE.GROUP_YN === "N" ||
                              REDUCER_STATE.GROUP_YN === ""
                            ? "/img/icon/address/sort_down.png"
                            : "/img/icon/common/sort_down.png"
                        }
                        alt="tel_sort_down"
                      />
                    )}
                  </th>
                  <th />
                  <th className="text-left">
                    {t("my.address.txt.table.category")}
                    <img
                      className="cursor address_header_icon"
                      onClick={() => {
                        setIsCategory(isCategory ? false : true);
                      }}
                      style={{
                        width: "18px",
                        height: "18px",
                      }}
                      src={
                        loading
                          ? "/img/icon/address/filter_disable.png"
                          : REDUCER_STATE.GROUP_YN === "N" ||
                            REDUCER_STATE.GROUP_YN === ""
                          ? "/img/icon/address/filter.png"
                          : "/img/icon/common/filter.png"
                      }
                      alt="filter_btn"
                    />
                    <div
                      style={{
                        display: isCategory ? "block" : "none",
                        position: "absolute",
                        inset: "0px auto auto 0px",
                        transform: "translate(570px, 300px)",
                        zIndex: "400",
                        background: "white",
                        boxShadow: "rgb(0 0 0 / 16%) 0px 5px 16px",
                        borderRadius: "8px",
                        left: "250px",
                      }}
                    >
                      <Addresscategorypopup
                        isCategory={isCategory}
                        colorConverter={colorConverter}
                        filterAll={filterAll}
                        filterCategory={filterCategory}
                      />
                    </div>
                  </th>
                  <th />
                  <th
                    style={{
                      borderTopRightRadius: "10px",
                      borderBottomRightRadius: "10px",
                    }}
                  />
                </tr>
              </thead>

              <tbody>
                {data.length > 0 ? (
                  data.map((data, index) => (
                    <tr
                      className="cursor"
                      key={"address_tr_" + index}
                      style={{
                        fontSize: "14px",
                        height: "52px",
                        textAlign: "center",
                        backgroundColor: "rgba(255,255,255,0.6)",
                        marginTop: "12px",
                      }}
                      onClick={e => {
                        e.stopPropagation();
                        calculateHeight(index);
                        setPopupType("READ");
                        setPopupInfo({
                          INDEX: index,
                          ADR_NUM: data.ADR_NUM,
                          NAME: data.ADR_NM === undefined ? "" : data.ADR_NM,
                          EAMIL:
                            data.ADR_EMAIL === undefined ? "" : data.ADR_EMAIL,
                          PHONE:
                            data.ADR_PHONE === undefined ? "" : data.ADR_PHONE,
                          MEMO:
                            data.ADR_MEMO === undefined ? "" : data.ADR_MEMO,
                          CATEGORY:
                            data.TYPESPLIT === undefined ? [] : data.TYPESPLIT,
                          FAVORITE_YN: data.FAVORITE_YN,
                          COLOR: data.COLOR,
                          IMG: data.FILE_NM === undefined ? "" : data.FILE_NM,
                        });
                        setIsPopup(true);
                      }}
                    >
                      <td
                        style={{
                          borderTopLeftRadius: "10px",
                          borderBottomLeftRadius: "10px",
                        }}
                      />
                      <td onClick={e => e.stopPropagation()}>
                        <input
                          className="cursor address_checkbox"
                          type="checkbox"
                          name="Adr_Chk"
                          onChange={e => {
                            e.stopPropagation();
                            clickCheckbox(
                              e.currentTarget.checked,
                              data.ADR_NUM
                            );
                          }}
                          checked={
                            checkedInputs.includes(data.ADR_NUM) ? true : false
                          }
                        />
                      </td>

                      <td
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          height: "52px",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          className="cursor"
                          onClick={e => {
                            e.stopPropagation();
                            favoriteAddress(data.ADR_NUM, data.FAVORITE_YN);
                          }}
                          style={{
                            width: "24px",
                            height: "24px",
                          }}
                          src={
                            data.FAVORITE_YN === "Y"
                              ? "/img/icon/address/favorite.png"
                              : "/img/icon/address/favorite_disable.png"
                          }
                          alt="favorite_btn"
                        />
                      </td>

                      <td>
                        {data.img === undefined ? (
                          <Avatar
                            style={{
                              marginLeft: "12px",
                              background: data.COLOR,
                            }}
                          >
                            {data.ADR_NM !== ""
                              ? data.ADR_NM.substr(0, 1)
                              : data.ADR_EMAIL.substr(0, 1)}
                          </Avatar>
                        ) : (
                          <Avatar
                            style={{
                              marginLeft: "12px",
                            }}
                            alt="user_img"
                            src={data.img}
                          />
                        )}
                      </td>
                      <td />
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {data.ADR_NM !== "" ? data.ADR_NM : ""}
                      </td>
                      <td />
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {data.ADR_EMAIL}
                      </td>
                      <td />
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {data.ADR_PHONE}
                      </td>
                      <td />
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {data.TYPESPLIT.map((item, index) => {
                          return (
                            <div
                              key={"TYPESPLIT_" + index}
                              className="scrollItem"
                              style={{
                                background: colorConverter(item.type_bgc),
                              }}
                            >
                              {item.type_nm.length > 4 ? (
                                <p className="inline">
                                  {item.type_nm.substr(0, 4) + "..."}
                                </p>
                              ) : (
                                <p className="inline">{item.type_nm}</p>
                              )}
                            </div>
                          );
                        })}
                      </td>
                      <td />
                      <td
                        style={{
                          borderBottomRightRadius: "10px",
                          borderTopRightRadius: "10px",
                        }}
                      >
                        <img
                          onClick={e => {
                            e.stopPropagation();
                            calculateHeight(index);
                            setPopupType("EDIT");
                            setPopupInfo({
                              INDEX: index,
                              ADR_NUM: data.ADR_NUM,
                              NAME:
                                data.ADR_NM === undefined ? "" : data.ADR_NM,
                              EAMIL:
                                data.ADR_EMAIL === undefined
                                  ? ""
                                  : data.ADR_EMAIL,
                              PHONE:
                                data.ADR_PHONE === undefined
                                  ? ""
                                  : data.ADR_PHONE,
                              MEMO:
                                data.ADR_MEMO === undefined
                                  ? ""
                                  : data.ADR_MEMO,
                              CATEGORY:
                                data.TYPESPLIT === undefined
                                  ? []
                                  : data.TYPESPLIT,
                              FAVORITE_YN: data.FAVORITE_YN,
                              COLOR: data.COLOR,
                              IMG:
                                data.FILE_NM === undefined ? "" : data.FILE_NM,
                            });
                            setIsPopup(true);
                          }}
                          className="cursor"
                          style={{
                            width: "48px",
                            height: "48px",
                          }}
                          src={
                            REDUCER_STATE.GROUP_YN === "N" ||
                            REDUCER_STATE.GROUP_YN === ""
                              ? "/img/icon/address/edit.png"
                              : "/img/icon/address/edit_gray.png"
                          }
                          alt="edit_btn"
                        />
                        <img
                          onClick={e => {
                            e.stopPropagation();
                            deleteAddress(data.ADR_NUM);
                          }}
                          className="cursor"
                          style={{
                            width: "48px",
                            height: "48px",
                          }}
                          src={
                            REDUCER_STATE.GROUP_YN === "N" ||
                            REDUCER_STATE.GROUP_YN === ""
                              ? "/img/icon/address/delete.png"
                              : "/img/icon/address/delete_gray.png"
                          }
                          alt="delete_btn"
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <DataEmpty />
                )}
              </tbody>
            </table>

            <Paging page={page} setPage={setPage} total={total} />
          </div>
        )}
      </div>
    </div>
  );
}

export default AddressList;
