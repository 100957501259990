import React, { useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import axios from "axios";

import { useSelector, useDispatch } from "react-redux";
import { updateUserInfo } from "actions/index";
/**
 * Version : 1.0
 * 파일명 : profileBtn.js
 * 작성일자 : 2021-10-05
 * 작성자 : 권도훈
 * 설명 : 헤더 사용자 버튼
 * 수정일자 :  2022-02-14
 * 수정자 : 강연승
 * 수정내역 : color CSS 수정
 */
function ProfileBtn() {
  const userinfo = useSelector(state => state.userInfoReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    axios
      .get("/user/profile", {})
      .then(res => {
        dispatch(updateUserInfo(res.data[0]));
      })
      .catch(err => {
        console.log(err);
      });
  }, [dispatch]);

  return (
    <>
      <Avatar
        alt="U"
        style={{
          float: "left",
        }}
        src={userinfo.IMG}
      ></Avatar>
      {/* src="/img/user_img.jpg" */}
      <div
        className="font-bold"
        style={{
          font: "normal normal bold 16px/21px Spoqa Han Sans Neo",
          float: "left",
          color: "#2B4AC4",
          margin: "8px 15px",
        }}
      >
        {userinfo.NAME !== "" ? userinfo.NAME + " 님" : ""}
      </div>
    </>
  );
}

export default ProfileBtn;
// const mapStateToProps = state => {
//   console.log(state.USER_NAME);
//   console.log("#############################");
//   return { USER_NAME: state.USER_NAME }
// }

// export default connect(mapStateToProps, { updateUserName })(ProfileBtn);
