import React, { useCallback, useEffect, useState } from "react";
import Loading from "component/tag/loading/loading";
import { useDispatch, useSelector } from "react-redux";
import { updateStatisticsDetail } from "actions/index";
import Paging from "component/tag/div/common/paging";
/**
 * Version : 1.0
 * 파일명 : DataStatisticsList.js
 * 작성일자 : 2022-03-04
 * 작성자 : 권도훈
 * 설명 : 데이터 통계 리스트 타입
 * 수정일자 : 2022-04-22
 * 수정자 : 강연승
 * 수정내역 : 참석률 오류 수정
 */
function DataStatisticsList(props) {
  /**
   * REDUCER
   */
  // const STATISTICS_STATE = useSelector(state => state.statisticsInfoReducer);
  const GROUP_STATE = useSelector(state => state.groupInfoReducer);
  /**
   * DISPATCH
   */
  const dispatch = useDispatch();

  const [init, setInit] = useState(false);
  /**
   * 상세보기
   */
  const goDetail = useCallback(
    data => {
      props.setDateDetail({
        START_DATE: new Date(props.date.START_DATE),
        END_DATE: new Date(props.date.END_DATE),
      });
      props.setDetailSearch(true);
      dispatch(updateStatisticsDetail(data));
    },
    [dispatch, props]
  );

  useEffect(() => {
    if (!init) {
      setInit(true);
    }
  }, [init]);

  /**
   * 참석률
   */
  const calRate = (count, total) => {
    console.log("count : " + count, "total : " + total);
    let rate = 0;

    if (total > 0) {
      rate = Math.floor((count / total) * 100);
    }

    return rate + "%";
  };

  return (
    <div className="data-statistics-container-list scrollbar">
      {props.data.length > 0 ? (
        <>
          <table className="data-statistics-list">
            <colgroup>
              <col width="24px" />
              <col width="196px" />
              <col width="16px" />
              <col width="200px" />
              <col width="16px" />
              <col width="175px" />
              <col width="16px" />
              <col width="148px" />
              <col width="16px" />
              <col width="159px" />
              <col width="24px" />
              <col width="120px" />
              <col width="100px" />
              <col width="122px" />
              <col width="8px" />
            </colgroup>

            {init ? (
              <>
                <thead className="data-statistics-list-thead">
                  <tr className="data-statistics-list-tr">
                    <th />
                    <th className="data-statistics-list-flex th">
                      <font>
                        {GROUP_STATE.GROUP_CATEGORY === "CD0401" ||
                        GROUP_STATE.GROUP_CATEGORY === "CD0402"
                          ? "미팅 호스트"
                          : GROUP_STATE.GROUP_CATEGORY === "CD0403"
                          ? "교사"
                          : GROUP_STATE.GROUP_CATEGORY === "CD0404"
                          ? "강사"
                          : GROUP_STATE.GROUP_CATEGORY === "CD0405"
                          ? "교수"
                          : "그룹 리더"}
                      </font>
                      <img
                        className="data-statistics-list-sort cursor"
                        src={
                          props.sort.type === "HOST_NAME" &&
                          props.sort.direction === "DESC"
                            ? "/img/icon/common/sort_up.png"
                            : "/img/icon/common/sort_down.png"
                        }
                        alt="sort"
                        onClick={() => {
                          props.setSort({
                            type: "HOST_NAME",
                            direction:
                              props.sort.type === "HOST_NAME" &&
                              props.sort.direction === "DESC"
                                ? "ASC"
                                : "DESC",
                          });
                          props.setDataSearch(true);
                        }}
                      />
                    </th>
                    <th />
                    <th className="data-statistics-list-flex-left th">
                      {GROUP_STATE.GROUP_CATEGORY === "CD0401" ||
                      GROUP_STATE.GROUP_CATEGORY === "CD0402"
                        ? "미팅 호스트 메일"
                        : GROUP_STATE.GROUP_CATEGORY === "CD0403"
                        ? "교사 메일"
                        : GROUP_STATE.GROUP_CATEGORY === "CD0404"
                        ? "강사 메일"
                        : GROUP_STATE.GROUP_CATEGORY === "CD0405"
                        ? "교수 메일"
                        : "그룹 리더 메일"}
                    </th>
                    <th />
                    <th className="data-statistics-list-flex th">
                      <font>
                        {GROUP_STATE.GROUP_CATEGORY === "CD0401" ||
                        GROUP_STATE.GROUP_CATEGORY === "CD0402"
                          ? "총 개설 회의 수"
                          : GROUP_STATE.GROUP_CATEGORY === "CD0403"
                          ? "총 개설 수업 수"
                          : GROUP_STATE.GROUP_CATEGORY === "CD0404" ||
                            GROUP_STATE.GROUP_CATEGORY === "CD0405"
                          ? "총 개설 강의 수"
                          : "총 개설 모임 수"}
                      </font>
                      <img
                        className="data-statistics-list-sort cursor"
                        src={
                          props.sort.type === "TOTAL_SCHECULE_COUNT" &&
                          props.sort.direction === "DESC"
                            ? "/img/icon/common/sort_up.png"
                            : "/img/icon/common/sort_down.png"
                        }
                        alt="sort"
                        onClick={() => {
                          props.setSort({
                            type: "TOTAL_SCHECULE_COUNT",
                            direction:
                              props.sort.type === "TOTAL_SCHECULE_COUNT" &&
                              props.sort.direction === "DESC"
                                ? "ASC"
                                : "DESC",
                          });
                          props.setDataSearch(true);
                        }}
                      />
                    </th>
                    <th />
                    <th className="data-statistics-list-flex th">
                      <font>
                        {GROUP_STATE.GROUP_CATEGORY === "CD0401" ||
                        GROUP_STATE.GROUP_CATEGORY === "CD0402"
                          ? "총 회의시간"
                          : GROUP_STATE.GROUP_CATEGORY === "CD0403"
                          ? "총 수업시간"
                          : GROUP_STATE.GROUP_CATEGORY === "CD0404" ||
                            GROUP_STATE.GROUP_CATEGORY === "CD0405"
                          ? "총 강의시간"
                          : "총 모임시간"}
                      </font>
                      <img
                        className="data-statistics-list-sort cursor"
                        src={
                          props.sort.type === "TOTAL_DURATION" &&
                          props.sort.direction === "DESC"
                            ? "/img/icon/common/sort_up.png"
                            : "/img/icon/common/sort_down.png"
                        }
                        alt="sort"
                        onClick={() => {
                          props.setSort({
                            type: "TOTAL_DURATION",
                            direction:
                              props.sort.type === "TOTAL_DURATION" &&
                              props.sort.direction === "DESC"
                                ? "ASC"
                                : "DESC",
                          });
                          props.setDataSearch(true);
                        }}
                      />
                    </th>
                    <th />
                    <th className="data-statistics-list-flex th">
                      <font>참석인원/초대인원</font>
                      <img
                        className="data-statistics-list-sort cursor"
                        src={
                          props.sort.type === "PARTICIPANT_COUNT" &&
                          props.sort.direction === "DESC"
                            ? "/img/icon/common/sort_up.png"
                            : "/img/icon/common/sort_down.png"
                        }
                        alt="sort"
                        onClick={() => {
                          props.setSort({
                            type: "PARTICIPANT_COUNT",
                            direction:
                              props.sort.type === "PARTICIPANT_COUNT" &&
                              props.sort.direction === "DESC"
                                ? "ASC"
                                : "DESC",
                          });
                          props.setDataSearch(true);
                        }}
                      />
                    </th>
                    <th />
                    <th className="data-statistics-list-flex th">
                      {GROUP_STATE.GROUP_CATEGORY === "CD0403" ||
                      GROUP_STATE.GROUP_CATEGORY === "CD0404" ||
                      GROUP_STATE.GROUP_CATEGORY === "CD0405"
                        ? "평균 출석률"
                        : "평균 참석률"}
                    </th>
                    <th />
                    <th />
                    <th />
                  </tr>
                </thead>

                <tbody>
                  {props.data.map((data, idx) => (
                    <tr
                      className="data-statistics-list-tr tbody"
                      key={"data-tr-" + idx}
                    >
                      <td />
                      <td className="data-statistics-list-flex td">
                        <div
                          className="data-statistics-list-profile"
                          style={{
                            background: data.BACKGROUND_COLOR,
                          }}
                        >
                          {data.NAME.substr(0, 1)}
                        </div>

                        <div className="data-statistics-list-name">
                          {data.NAME}
                        </div>
                      </td>
                      <td />
                      <td className="left">{data.EMAIL}</td>
                      <td />
                      <td>{data.TOTAL}</td>
                      <td />
                      <td>{data.DURATION + "분"}</td>
                      <td />
                      {data.PARTICIPANT_COUNT +
                        "/" +
                        (data.INVITE_PARTICIPANTS_COUNT +
                          data.NON_INVITE_PARTICIPANTS_COUNT)}
                      <td />
                      <td>
                        {calRate(
                          data.PARTICIPANT_COUNT,
                          data.INVITE_PARTICIPANTS_COUNT +
                            data.NON_INVITE_PARTICIPANTS_COUNT
                        )}
                      </td>
                      <td />
                      <td
                        className="data-statistics-list-detail cursor"
                        onClick={() => goDetail(data)}
                      >
                        자세히 보기
                      </td>
                      <td />
                    </tr>
                  ))}
                </tbody>
              </>
            ) : (
              <></>
            )}
          </table>

          <div className="data-statistics-list-paginig">
            <Paging
              page={props.page}
              setPage={props.setPage}
              total={props.total}
            />
          </div>
        </>
      ) : (
        <div className="data-statistics-container-empty">
          <img src="/img/icon/data/no_status_new.png" alt="NO DATA" />
          <section>
            <font>아직 데이터가 없습니다!</font>
            <font>미팅 데이터가 누적되면 통계가 생성됩니다.</font>
          </section>
        </div>
      )}
    </div>
  );
}

export default DataStatisticsList;
