import React from 'react'
import { useHistory } from 'react-router-dom';
/**
 * Version : 1.0
 * 파일명 : EndLicenseAlram.js
 * 작성일자 : 2021-12-16
 * 작성자 : 권도훈
 * 설명 : 회의이용권 기한 만료 알람
 * 수정일자 : 2022-02-03
 * 수정자 : 권도훈
 * 수정내역 : history push로 변경
*/
function EndLicenseAlram(props) {
    /**
     * history
     */
    const history = useHistory();
    /**
     * 내용 포맷
     */
    const contentConvert = (data) => {
        return (
            <div>
                <div>당일!</div>
                <div>[ {data} ] 회의이용권 기간이 종료되었습니다.</div>
            </div>
        )
    }
    return (
        <div
            className="alarm_Item cursor"
            key={props.data.ALRAM_NUM}
            onClick={() => {
                history.push({
                    pathname: "/app/home/profile"
                });
                // window.location.href = "/app/home/profile";
            }}
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: "24px",
                    color: props.data.READ_YN === "Y" ? "grey" : "gold"
                }}
            >
                <div className="font-bold">
                    기한 만료
                </div>

                <div
                    style={{
                        fontSize: "14px"
                    }}
                >
                    {props.date}
                </div>
            </div>

            <div
                style={{
                    marginTop: "24px",
                    marginBottom: "24px",
                    color: props.data.READ_YN === "Y" ? "grey" : "black",
                }}
                className="m-0"
            >
                {
                    contentConvert(props.data.ALRAM_CONTENT)
                }
            </div>
        </div>
    )
}

export default EndLicenseAlram
