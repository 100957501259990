import React from "react";
import ReactModal from "react-modal";
/**
 * Version : 1.0
 * 파일명 : licenseConfirmAlert.js
 * 작성일자 : 2021-12-21
 * 작성자 : 강연승
 * 설명 : 라이선스 세팅 팝업 내 컨펌창
 * 수정일자 : 2022-01-18
 * 수정자 : 강연승
 * 수정내역 : 모달창 모듈 변경
 */
function LicenseConfirmAlert(props) {
  return (
    <ReactModal
      isOpen={props.open} //모달 Open(Boolean)
      className={props.request.className} //모달 ClassName
      overlayClassName="AlertOverlay" //모달오버레이 ClassName
      animationType="fade"
      transparent={true}
      closeTimeoutMS={200}
    >
      <div>{props.request.contents}</div>
      {props.request.btnNo ? (
        <div className="btnArea">
          <button
            className="btnYes"
            onClick={() => (props.request.EventHandler(), props.close())}
          >
            예
          </button>
          <button className="btnNo" onClick={props.close}>
            아니오
          </button>
        </div>
      ) : (
        <div className="btnArea">
          <button
            className="btnNo"
            onClick={() => (props.request.EventHandler(), props.close())}
          >
            확 인
          </button>
        </div>
      )}
    </ReactModal>
  );
}

export default LicenseConfirmAlert;
