import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
/**
 * Version : 1.0
 * 파일명 : platformPopup.js
 * 작성일자 : 2021-12-29
 * 작성자 : 강연승
 * 설명 : 화상 회의 플랫폼 선택 팝업
 * 수정일자 : 2022-06-13
 * 수정자 : 강연승
 * 수정내역 : sobridge 데모 - 차임, 펩십 이미지 미출력 주석 처리
 * props :
 * 전달받은값 - 설명
 */
function PlatformPopup(props) {
  /**
   * body
   */
  ReactModal.setAppElement("body");
  const [platform, setPlatfrom] = useState(props.apiType);

  useEffect(() => {
    setPlatfrom(props.apiType);
  }, [props.apiType]);

  const savePlatfrom = () => {
    props.setData({ ...props.data, apiType: platform });
    props.close();
  };

  const closePopup = () => {
    props.close();
    if (props.apiType !== platform) {
      if (props.apiType === undefined) {
        setPlatfrom(undefined);
      } else {
        setPlatfrom(props.apiType);
      }
    }
  };

  return (
    <ReactModal
      isOpen={props.open} //모달 Open(Boolean)
      className="platformPopup" //모달 ClassName
      overlayClassName="platformPopup_Overlay" //모달오버레이 ClassName
      onRequestClose={closePopup}
      shouldCloseOnEsc={true}
      animationType="fade"
      transparent={true}
      closeTimeoutMS={200}
    >
      <div className="popup_header">
        <div className="popup_title">
          <font>화상회의 플랫폼 선택</font>
          <img
            className="closeBtn"
            src="/img/icon/common/close.png"
            alt="close"
            onClick={closePopup}
          />
        </div>
      </div>
      <div className="popup_contents_background">
        <div className="popup_expln">
          매번 다른 회의! 특징에 맞는 플랫폼을 선택하여 진행해보세요.
        </div>
        <div className="popup_contents">
          <div
            className={
              platform === "CD0501"
                ? "platformBox platformBox_webex cursor selectPlatform"
                : "platformBox platformBox_webex cursor"
            }
            onClick={() => setPlatfrom("CD0501")}
            onDoubleClick={() => {
              setPlatfrom("CD0501");
              savePlatfrom();
            }}
          >
            <div className="platformBoxText">
              <div
                className={
                  platform === "CD0501"
                    ? "platformExpln webexActive"
                    : "platformExpln"
                }
              >
                강력한 보안
              </div>
              <div
                className={
                  platform === "CD0501"
                    ? "platformNm webexActive"
                    : "platformNm"
                }
              >
                시스코 웹엑스
              </div>
            </div>
            <div className="platformBoxImg">
              <img
                className="platformImg"
                style={{
                  display:
                    platform === "CD0501" || platform === undefined
                      ? "initial"
                      : "none",
                }}
                src="/img/platform/webex1.png"
                alt="webex"
              />
              <img
                style={{
                  display:
                    platform === "CD0501" || platform === undefined
                      ? "none"
                      : "initial",
                }}
                className="platformImg"
                src="/img/platform/webex1_disable.png"
                alt="webex"
              />
            </div>
          </div>
          <div
            className={
              platform === "CD0502"
                ? "platformBox platformBox_zoom cursor selectPlatform"
                : "platformBox platformBox_zoom cursor"
            }
            onClick={() => setPlatfrom("CD0502")}
            onDoubleClick={() => {
              setPlatfrom("CD0502");
              savePlatfrom();
            }}
          >
            <div className="platformBoxText">
              <div
                className={
                  platform === "CD0502"
                    ? "platformExpln zoomActive"
                    : "platformExpln"
                }
              >
                간편한 회의 참여
              </div>
              <div
                className={
                  platform === "CD0502" ? "platformNm zoomActive" : "platformNm"
                }
              >
                줌
              </div>
            </div>
            <div className="platformBoxImg">
              <img
                style={{
                  display:
                    platform === "CD0502" || platform === undefined
                      ? "initial"
                      : "none",
                }}
                className="platformImg"
                src="/img/platform/zoom1.png"
                alt="zoom"
              />
              <img
                style={{
                  display:
                    platform === "CD0502" || platform === undefined
                      ? "none"
                      : "initial",
                }}
                className="platformImg"
                src="/img/platform/zoom1_disable.png"
                alt="zoom"
              />
            </div>
          </div>
          {/* <div
            className={
              platform === "CD0503"
                ? "platformBox platformBox_chime selectPlatform"
                : "platformBox platformBox_chime"
            }
            // onClick={() => setPlatfrom("CD0503")}
            // onDoubleClick={() => {
            //   setPlatfrom("CD0503");
            //   savePlatfrom();
            // }}
          >
            <div className="platformBoxText">
              <div
                className={
                  platform === "CD0503"
                    ? "platformExpln chimeActive"
                    : "platformExpln"
                }
              >
                Coming Soon
              </div>
              <div
                className={
                  platform === "CD0503"
                    ? "platformNm chimeActive"
                    : "platformNm"
                }
              >
                아마존 차임
              </div>
            </div>
            <div className="platformBoxImg">
              <img
                style={{ display: "none" }}
                // style={{
                //   display:
                //     platform === "CD0503" || platform === undefined
                //       ? "initial"
                //       : "none",
                // }}
                className="platformImg"
                src="/img/platform/chime1.png"
                alt="chime"
              />
              <img
                style={{ display: "initial" }}
                // style={{
                //   display:
                //     platform === "CD0503" || platform === undefined
                //       ? "none"
                //       : "initial",
                // }}
                className="platformImg"
                src="/img/platform/chime1_disable.png"
                alt="chime"
              />
            </div>
          </div>
          <div
            className={
              platform === "CD0504"
                ? "platformBox platformBox_pexip selectPlatform"
                : "platformBox platformBox_pexip"
            }
            // onClick={() => setPlatfrom("CD0504")}
            // onDoubleClick={() => {
            //   setPlatfrom("CD0504");
            //   savePlatfrom();
            // }}
          >
            <div className="platformBoxText">
              <div
                className={
                  platform === "CD0504"
                    ? "platformExpln pexipActive"
                    : "platformExpln"
                }
              >
                Coming Soon
              </div>
              <div
                className={
                  platform === "CD0504"
                    ? "platformNm pexipActive"
                    : "platformNm"
                }
              >
                펩십
              </div>
            </div>
            <div className="platformBoxImg">
              <img
                style={{ display: "none" }}
                // style={{
                //   display:
                //     platform === "CD0504" || platform === undefined
                //       ? "initial"
                //       : "none",
                // }}
                className="platformImg"
                src="/img/platform/pexip1.png"
                alt="pexip"
              />
              <img
                style={{ display: "initial" }}
                // style={{
                //   display:
                //     platform === "CD0504" || platform === undefined
                //       ? "none"
                //       : "initial",
                // }}
                className="platformImg"
                src="/img/platform/Pexip1_disable.png"
                alt="pexip"
              />
            </div>
          </div> */}
        </div>
      </div>
      <div className="popup_footer">
        <label htmlFor="rememberPlatformBtn" style={{ height: "20px" }}>
          {props.rememberPlatform ? (
            <img
              className="cursor"
              src="/img/icon/schedule/checked.png"
              alt="check"
            />
          ) : (
            <img
              className="cursor"
              src="/img/icon/schedule/check.png"
              alt="nonCheck"
            />
          )}
        </label>
        <input
          id="rememberPlatformBtn"
          className="savePlatform"
          type="checkbox"
          checked={props.rememberPlatform}
          style={{ display: "none" }}
          onChange={e => {
            props.setRememberPlatform(e.currentTarget.checked);
          }}
        />
        <font className="chkLabel">선택한 플랫폼 다음 번에도 사용</font>
        <button className="confirmBtn" onClick={savePlatfrom}>
          확 인
        </button>
      </div>
    </ReactModal>
  );
}

export default PlatformPopup;
