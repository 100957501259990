import React from "react";
import ReactModal from "react-modal";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
/**
 * Version : 1.0
 * 파일명 : noLicensePopup.js
 * 작성일자 : 2021-12-20
 * 작성자 : 강연승
 * 설명 : 라이선스 미보유 팝업
 * 수정일자 : 2022-02-11
 * 수정자 : 강연승
 * 수정내역 : 오류 수정
 */
function NoLicensePopup(props) {
  /**
   * body
   */
  ReactModal.setAppElement("body");
  /**
   * 글로벌 스테이트
   */
  const groupInfoState = useSelector(state => state.groupInfoReducer);
  const history = useHistory();
  const goLicense = () => {
    if (groupInfoState.GROUP_YN === "Y") {
      history.push({
        pathname: "/app/group/groupProfile/" + groupInfoState.GROUP_NUM,
        state: {
          viewType: "LICENSE",
        },
      });
    } else {
      history.push({ pathname: "/app/home/profile" });
    }
  };
  return (
    <ReactModal
      isOpen={props.open} //모달 Open(Boolean)
      className="noLicensePopup" //모달 ClassName
      overlayClassName="noLicensePopup_Overlay" //모달오버레이 ClassName
      onRequestClose={props.close}
      shouldCloseOnEsc={true}
      animationType="fade"
      transparent={true}
      closeTimeoutMS={200}
    >
      <div>
        <img
          src="/img/icon/landing/close.png"
          alt="close"
          onClick={props.close}
          style={{
            cursor: "pointer",
            float: "right",
          }}
        />
        {/* </div> */}
        <div className="text-center" style={{ clear: "both" }}>
          <img
            src="/img/non_pass.png"
            alt="noLicense"
            style={{ marginBottom: "24px" }}
          />
          <div className="popupTitle">
            현재 고객님께서 보유하고 계신 회의 이용권이 없습니다.
          </div>
          <div className="popupContents">
            회의 이용권을 구매하여 회의 개설 및 예약을 통해 화상회의를
            이용해보세요!
            <br />
            회의 이용권 하나로 모든 사람들과 공유하여 사용할 수 있습니다.
            <br />
            SoBridge의 기능을 마음껏 이용해보세요!
          </div>
          <div className="btnArea">
            <button className="btnOK" onClick={props.close}>
              알겠습니다!
            </button>
            <button className="btnGoLicense" onClick={goLicense}>
              회의 이용권 보러 가기
            </button>
          </div>
        </div>
      </div>
    </ReactModal>
  );
}

export default NoLicensePopup;
