import { useRef, useEffect, useCallback } from 'react';
/**
 * Version : 1.0
 * 파일명 : scrollFade.jsx
 * 작성일자 : 2022-02-11
 * 작성자 : 권도훈
 * 설명 : 스크롤 시 페이드 hook
 */

const useScrollFadeIn = (duration, delay) => {
    const element = useRef();
    
    const moveEventHandler = () => {
        return 'translate3d(0, 50%, 0)';
    }

    const scrollEventHandler = useCallback(([entry]) => {
        const { current } = element;
        if (entry.isIntersecting) {
            current.style.transitionProperty = 'all';
            current.style.transitionDuration = `${duration}s`;
            current.style.transitionTimingFunction = 'cubic-bezier(0, 0, 0.2, 1)';
            current.style.transitionDelay = `${delay}s`;
            current.style.opacity = 1;
            current.style.transform = 'translate3d(0, 0, 0)';
        }
        },
        [delay, duration],
    );

    useEffect(() => {
        let observer;

        if (element.current) {
            observer = new IntersectionObserver(scrollEventHandler, { threshold: 0 });
            observer.observe(element.current);
        }

        return () => observer;
    }, [scrollEventHandler]);

    return {
        ref: element,
        style: { opacity: 0, transform: moveEventHandler() },
    };
};

export default useScrollFadeIn;