import { closeAlert, openAlert } from 'actions';
import axios from 'axios';
import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

function DormantCheck() {


  const dispatch = useDispatch();
  let params = (new URL(document.location)).searchParams;
  
  let aaaa = params.get('check')

  console.log(params)
  const history = useHistory();



  const CheckAuth = useCallback(()=>{

    let config = {
      params : {
        code : params.get('check'),
      }
    }
    console.log(params)
    axios.get("/user/Dormantcheck" ,config).then(res => {
      if(res.status === 200){
        console.log(res.data)


        const obj = {
          TEXT: res.data,
          submitEventHandler: () => {
            dispatch(closeAlert());
            history.push({
              pathname: "/",
            });
          }
        };
        dispatch(openAlert(obj));

      }

    })

  },[])

  useEffect(() => {
    CheckAuth()
  },[CheckAuth])




  return (
    <div>      <div>
    <img
      src="/img/logo_main.png"
      alt="logo_main"
      style={{
        marginLeft: "116px",
        marginTop: "34px",
      }}
    />
  </div></div>
  )
}

export default DormantCheck