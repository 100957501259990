/**
 * Import List
 */
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router";
import Cookies from "universal-cookie";
import { useDispatch } from "react-redux";
import { openAlert, closeAlert, openPayDonePopup } from "actions/index";
import PaySuccessPopup from "component/tag/popup/my/profile/paySuccessPopup";
import { useTranslation } from "react-i18next";
/**
 * Version : 1.0
 * 파일명 : callBack_common.js
 * 작성일자 : 2021-10-29
 * 작성자 : 권도훈
 * 설명 : 공통 CallBack 페이지
 * 수정일자 : 2022-02-07
 * 수정자 : 권도훈
 * 수정내역 : 다국어 적용
 */
function CallBack_common() {
  /**
   * translation
   */
  const { t } = useTranslation();
  /**
   * DISPATCH
   */
  const dispatch = useDispatch();
  /**
   * history
   */
  const history = useHistory();
  /**
   * STATE
   */
  const [showDonePopup, setShowDonePopup] = useState(false);
  /**
   * cookie
   */
  const cookie = new Cookies();
  const isLogin = cookie.get(
    process.env.REACT_APP_COOKIE_TYPE,
    {
      path: "/",
      domain: process.env.REACT_APP_COOKIE_DOMAIN
    }
  );
  /**
   * 요청 타입
   */
  const routeMatch = useRouteMatch("/callback/:type");
  const type = routeMatch.params.type;
  /**
   * 메일 인증
   */
  const authMailCode = useCallback(() => {
    const mail = new URL(window.location.href).searchParams.get("mail");
    const code = new URL(window.location.href).searchParams.get("code");
    const config = {
      params: {
        request_mail: mail,
        request_code: code,
      },
    };

    let groupCode = "";
    if (new URL(window.location.href).searchParams.get("groupcode")) {
      groupCode = new URL(window.location.href).searchParams.get("groupcode");
    }

    axios
      .get("/user/checkAuthCode", config)
      .then((res) => {
        if (res.status === 200) {
          if (res.data === true) {
            if (groupCode !== "") {
              history.push({
                pathname: "/makeAccount",
                state: {
                  mail: mail,
                  code: groupCode,
                },
              });
            } else if (groupCode === "") {
              history.push({
                pathname: "/makeAccount",
                state: {
                  mail,
                },
              });
            }
          } else {
            const obj = {
              TEXT: t("api.msg.authcode.denied"),
              submitEventHandler: () => {
                dispatch(closeAlert());
                history.push({
                  pathname: "/authMail",
                });
              }
            };
            dispatch(openAlert(obj));
          }
        } else {
          const obj = {
            TEXT: t("api.msg.authcode.fail"),
            submitEventHandler: () => {
              dispatch(closeAlert());
              history.push({
                pathname: "/authMail",
              });
            }
          };
          dispatch(openAlert(obj));
        }
      })
      .catch(() => {
        const obj = {
          TEXT: t("api.msg.authcode.fail"),
          submitEventHandler: () => {
            dispatch(closeAlert());
            history.push({
              pathname: "/authMail",
            });
          }
        };
        dispatch(openAlert(obj));
      });
  }, [dispatch, history, t]);
  /**
   *  비밀번호 변경
   */
  const changePassword = useCallback(() => {
    const mail = new URL(window.location.href).searchParams.get("mail");
    const code = new URL(window.location.href).searchParams.get("code");

    const config = {
      params: {
        request_mail: mail,
        request_code: code,
      },
    };

    axios
      .get("/user/checkAuthCode", config)
      .then((res) => {
        if (res.status === 200) {
          if (res.data === true) {
            history.push({
              pathname: "/password",
              state: {
                auth: true,
                email: mail,
              },
            });
          } else {
            const obj = {
              TEXT: t("api.msg.authcode.denied"),
              submitEventHandler: () => {
                dispatch(closeAlert());
                history.push({
                  pathname: "/password",
                  state: {
                    auth: false,
                  },
                });
              }
            };
            dispatch(openAlert(obj));
          }
        } else {
          const obj = {
            TEXT: t("api.msg.authcode.fail"),
            submitEventHandler: () => {
              dispatch(closeAlert());
              history.push({
                pathname: "/password",
                state: {
                  auth: false,
                },
              });
            }
          };
          dispatch(openAlert(obj));
        }
      })
      .catch(() => {
        const obj = {
          TEXT: t("api.msg.authcode.fail"),
          submitEventHandler: () => {
            dispatch(closeAlert());
            history.push({
              pathname: "/authMail",
            });
          }
        };
        dispatch(openAlert(obj));
      });
  }, [dispatch, history, t]);

  /**
   * 그룹초대
   */
  const inviteGroup = useCallback(() => {
    const code = new URL(window.location.href).searchParams.get("code");

    const config = {
      params: {
        code: code,
      },
    };

    if (isLogin !== undefined) {
      axios
        .get("/group/inviteGroup", config)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            history.push({
              pathname: "/app/group",
            });
          } else {
            alert("잘못된 요청입니다.");
            window.location.href = "/login?tag=block";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      alert("로그인 후 진행해주세요!");
      window.location.href = "/login?code=" + code;
      // window.open(
      //   "/loginpopup?code=" + code,
      //   "_blank",
      //   "width=570,height=600,scrollbars=no,status=no,titlebar=no,toolbar=no,menubar=no,resizeable=no,location=no"
      // );
    }
  }, [history, isLogin]);
  /**
   * 결제 성공
   */
  const paySuccess = useCallback(() => {
    const paymentKey = new URL(window.location.href).searchParams.get("paymentKey");
    const count = new URL(window.location.href).searchParams.get("count");
    const amount = new URL(window.location.href).searchParams.get("amount");
    const orderID = new URL(window.location.href).searchParams.get("orderId");
    const productCode = new URL(window.location.href).searchParams.get("productCode");
    const groupNum = new URL(window.location.href).searchParams.get("groupNum");

    const config = {
      params: {
        GROUP_NUM: groupNum,
        AMOUNT: amount,
        ORDER_ID: orderID,
        COUNT: count,
        PAYMENT_KEY: paymentKey,
        PRODUCT_CODE: productCode
      }
    }

    console.log(config.params);

    axios.post("/payment/sdk", null, config)
      .then(res => {
        if (res.status === 200) {
          // const obj = {
          //   TODAY: "오늘",
          //   PAY_DT: "다음결제일",
          //   COUNT: count,
          //   PRODUCT_NM: "월간 정기권"
          // };
          // dispatch(openPayDonePopup(obj));
          let url = "";

          if (groupNum === "") {
            url = "/app/home/profile";
          } else {
            url = "/app/group/groupProfile/" + groupNum;
          }

          history.push(url);
        } else {
          const obj = {
            TEXT: t("api.msg.pay.fail"),
            submitEventHandler: () => {
              dispatch(closeAlert());
              history.goBack();
            }
          };
          dispatch(openAlert(obj));
        }

      })
      .catch(err => {
        const obj = {
          TEXT: err.response.data.MSG,
          submitEventHandler: () => {
            dispatch(closeAlert());
            history.goBack();
          }
        };
        dispatch(openAlert(obj));
      })
  }, [dispatch, history, t]);
  /**
   * 결제 실패
   */
  const payFail = useCallback(() => {
    const code = new URL(window.location.href).searchParams.get("code");
    // const msg = new URL(window.location.href).searchParams.get("message");
    // const orderId = new URL(window.location.href).searchParams.get("orderId");
    let msg = "";

    switch (code) {
      case "PAY_PROCESS_CANCELED":
        msg = t("api.msg.pay.cancle");
        break;
      case "PAY_PROCESS_ABORTED":
        msg = t("api.msg.pay.abort");
        break;
      case "REJECT_CARD_COMPANY":
        msg = t("api.msg.pay.reject");
        break;
      default:
        msg = t("api.msg.pay.error");
        break;
    }

    const obj = {
      TEXT: msg,
      submitEventHandler: () => {
        dispatch(closeAlert());
        history.goBack();
      }
    };
    dispatch(openAlert(obj));
  }, [dispatch, history, t]);

  const connectPay = useCallback(() => {
    const code = new URL(window.location.href).searchParams.get("code");
    alert(code);
  }, []);
  /**
   * 처리
   */
  useEffect(() => {
    switch (type) {
      case "auth":
        authMailCode();
        break;

      case "password":
        changePassword();
        break;

      case "inviteGroup":
        inviteGroup();
        break;

      case "paySuccess":
        paySuccess();
        break;

      case "payFail":
        payFail();
        break;

      case "connectPay":
        connectPay();
        break;

      default:
        alert("잘못된 요청입니다.");
        window.location.href = "/";
        break;
    }
  }, [authMailCode, changePassword, connectPay, history, inviteGroup, payFail, paySuccess, type]);
  /**
   * 태그 렌더링 부분
   */
  return (
    <div>
      <PaySuccessPopup isShow={showDonePopup} setIsShow={setShowDonePopup} />

      <img
        className="block mx-auto mt-50"
        src="/img/pebble_loading.gif"
        alt="loading"
      />
    </div>
  );
}

export default CallBack_common;
