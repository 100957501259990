import React from "react";
import { Badge, Avatar } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { openAlramList, closeAlramList } from "actions/index";
/**
 * Version : 1.0
 * 파일명 :
 * 작성일자 : 2021-10-05
 * 작성자 : 권도훈
 * 설명 :
 * 수정일자 : 2022-05-04
 * 수정자 : 강연승
 * 수정내역 : 그룹 컬러 수정
 */
function MsgBtn() {
  const alramInfo = useSelector(state => state.alramInfoReducer);
  const dispatch = useDispatch();

  return (
    <Badge
      className="cursor"
      overlap="circular"
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      badgeContent={
        <Avatar
          // onClick={openImageUploadPopup}
          id="alram_avatar"
          className="cursor"
          style={{
            width: "20px",
            height: "20px",
            fontSize: "12px",
            fontWeight: "bold",
            color: "#FFFFFF",
            background: "#2b4ac4",
            display: alramInfo.count !== "0" ? "flex" : "none",
          }}
        >
          {alramInfo.count}
        </Avatar>
      }
      // style={{
      //     // top: "28px",
      //     // right: "24px",
      //     // float: "left",
      //     // position: "relative",
      // }}
      onClick={() => {
        if (alramInfo.display) {
          dispatch(closeAlramList());
        } else {
          dispatch(openAlramList());
        }
      }}
    >
      <Avatar
        style={{
          width: "48px",
          height: "48px",
          background: "white",
          boxShadow: "0px 6px 15px #96969629",
        }}
      >
        <img src="/img/icon/common/alarm.png" alt="alarm" />
      </Avatar>
    </Badge>
  );
}

export default MsgBtn;
