import { Avatar } from "@material-ui/core";
import axios from "axios";
import AddressCategoryReadField from "component/tag/textField/my/address/addressCategoryReadField";
import AddressCategoryTextField from "component/tag/textField/my/address/addressCategoryTextField";
import AddressPopupReadField from "component/tag/textField/my/address/addressPopupReadField";
import AddressPopupTextField from "component/tag/textField/my/address/addressPopupTextField";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Alert from "component/tag/modal/alert";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { openAlert, closeAlert } from "actions/index";
/**
 * Version : 1.0
 * 파일명 : AddressDetailPopup.js
 * 작성일자 : 2021-11-03
 * 작성자 : 권도훈
 * 설명 : 연락처 정보 팝업
 * 수정일자 : 2022-03-25
 * 수정자 : 권도훈
 * 수정내역 : 아바타 수정
 */
function AddressDetailPopup(props) {
  /**
   * 언어변환
   */
  const { t } = useTranslation();
  /**
   * dispatch
   */
  const dispatch = useDispatch();
  /**
   * REDUCER
   */
  const REDUCER_STATE = useSelector(state => state.groupInfoReducer);
  const [isAdd, setIsAdd] = useState(false);
  const [type, setType] = useState("");
  const [data, setData] = useState({
    INDEX: 0,
    ADR_NUM: "",
    NAME: "",
    EAMIL: "",
    PHONE: "",
    MEMO: "",
    CATEGORY: [],
    FAVORITE_YN: "N",
    COLOR: "",
    IMG: "",
  });
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  /**
   * 카테고리 팝업
   */
  const [newCategoryText, setNewCategoryText] = useState("");
  const [isNewCategory, setIsNewCategory] = useState({
    ADR_TYPE_NUM: "",
    ADR_TYPE_NM: "",
    ADR_TYPE_BGC: "",
  });
  const [editCategoryTarget, setEditCategoryTarget] = useState({
    NAME: "",
    NUM: "",
  });
  const [isEditColor, setIsEditColor] = useState(false);

  const [categoryColor] = useState([
    { text: t("color.default"), bgc: "#2B4AC4", label: "default" },
    { text: t("color.pink"), bgc: "#DC82C3", label: "pink" },
    { text: t("color.red"), bgc: "#D4667E", label: "red" },
    { text: t("color.orange"), bgc: "#CD704B", label: "orange" },
    { text: t("color.yellow"), bgc: "#BD9D29", label: "yellow" },
    { text: t("color.lightgreen"), bgc: "#97C12A", label: "lightgreen" },
    { text: t("color.green"), bgc: "#28B750", label: "green" },
    { text: t("color.mint"), bgc: "#27B395", label: "mint" },
    { text: t("color.blue"), bgc: "#6E85D7", label: "blue" },
    { text: t("color.purple"), bgc: "#9479DA", label: "purple" },
  ]);
  /**
   * 알림창 설정
   */
  const [alertProps, setAlertProps] = useState({
    text: "",
    isShow: false,
    cancelBtnDisplay: false,
    okBtnDisplay: true,
    okBtnEventHandler: function () { },
    cancleBtnEventHandler: function () {
      closeAlertEvent();
    },
  });
  /**
   * 알림창 닫기
   */
  const closeAlertEvent = useCallback(() => {
    setAlertProps({
      ...alertProps,
      isShow: false,
    });
  }, [alertProps]);
  /**
   * 주소록 즐겨찾기
   */
  const favoriteAddress = useCallback(() => {
    const config = {
      params: {
        Address_Num: data.ADR_NUM,
        Favorite_YN: data.FAVORITE_YN === "Y" ? "N" : "Y",
      },
    };

    axios.put("/address/Favorite", null, config).then(res => {
      if (res.status === 200) {
        setData({
          ...data,
          FAVORITE_YN: config.params.Favorite_YN,
        });
      }
    });
  }, [data]);

  useEffect(() => {
    if (props.isPopup) {
      setType(props.type);
      setData(props.info);
      setSelectedCategory(props.info.CATEGORY);
    }
  }, [props]);

  /**
   * 필터 닫기 이벤트 추가
   */
  useEffect(() => {
    if (isAdd) {
      window.addEventListener("click", closePopup);
      return () => {
        window.removeEventListener("click", closePopup);
      };
    }
  }, [isAdd]);

  const detachcategory = (e, data) => {
    e.stopPropagation();
    setSelectedCategory(
      selectedCategory.filter(el => el.type_cd !== data.type_cd)
    );

    let arr = categoryList;
    arr = arr.filter(el => el.ADR_TYPE_NUM === data.type_cd);

    if (arr.length === 0) {
      setCategoryList([
        {
          ADR_TYPE_NUM: data.type_cd,
          ADR_TYPE_NM: data.type_nm,
          ADR_TYPE_BGC: data.type_bgc,
        },
        ...categoryList,
      ]);
    }
  };
  /**
   * 사용자 카테고리 조회
   */
  const getcategoryData = () => {
    const config = {
      params: {
        Group_YN: REDUCER_STATE.GROUP_YN,
        Group_Num: REDUCER_STATE.GROUP_NUM,
      },
    };

    axios.get("/address/AdrType", config).then(res => {
      if (res.status === 200) {
        let arr = res.data;
        for (let i = 0; i < selectedCategory.length; i++) {
          arr = arr.filter(
            el => el.ADR_TYPE_NUM !== selectedCategory[i].type_cd
          );
        }
        setCategoryList(arr);
      }
    });
  };
  /**
   * 새로운 분류 생성
   */
  const saveNewCategory = () => {
    const config = {
      params: {
        Group_YN: REDUCER_STATE.GROUP_YN,
        Group_Num: REDUCER_STATE.GROUP_NUM,
        Adr_Type_Nm: newCategoryText,
        Adr_Type_Bgc: "#4865CC",
      },
    };

    axios
      .post("/address/AdrType", null, config)
      .then(res => {
        if (res.status === 200) {
          getcategoryData();
          setNewCategoryText("");
        } else {
          props.setAlertProps({
            ...props.alertProps,
            isShow: true,
            text: t("my.address.txt.err.create"),
            cancelBtnDisplay: false,
            okBtnEventHandler: function () {
              props.closeAlertEvent();
            },
          });
        }
      })
      .catch(() => {
        props.setAlertProps({
          ...props.alertProps,
          isShow: true,
          text: t("my.address.txt.err.create"),
          cancelBtnDisplay: false,
          okBtnEventHandler: function () {
            props.closeAlertEvent();
          },
        });
      });
  };
  /**
   * 팝업 닫기
   */
  const closePopup = () => {
    setIsAdd(false);
  };
  /**
   * 위치 계산
   */
  const calculateHeight = index => {
    let val = 0;

    if (index === 6 || index === 7 || index === 8 || index === 9) {
      val = -330;
    } else {
      val = 65;
    }

    return "translate(759px," + val + "px)";
  };
  /**
   * 구분 설정 색상 변경
   */
  const updateCategoryColor = bgc => {
    const config = {
      params: {
        Group_YN: REDUCER_STATE.GROUP_YN,
        Group_Num: REDUCER_STATE.GROUP_NUM,
        Adr_Type_Nm: editCategoryTarget.NAME,
        Adr_Type_Bgc: bgc,
        Adr_Type_Num: editCategoryTarget.NUM,
      },
    };

    axios.put("/address/AdrType", null, config).then(res => {
      if (res.status === 200) {
        setIsEditColor(false);
        getcategoryData();
      }
    });
  };
  /**
   * 구분 태그 선택
   */
  const addSelectCategory = data => {
    if (selectedCategory.length < 5) {
      setSelectedCategory([
        ...selectedCategory,
        {
          type_cd: data.ADR_TYPE_NUM,
          type_nm: data.ADR_TYPE_NM,
          type_bgc: data.ADR_TYPE_BGC,
        },
      ]);
      let arr = categoryList;
      arr = arr.filter(el => el.ADR_TYPE_NUM !== data.ADR_TYPE_NUM);
      setCategoryList(arr);
    } else {
      const obj = {
        TEXT: t("my.address.msg.err.maxtag"),
        submitEventHandler: () => {
          dispatch(closeAlert());
        },
      };
      dispatch(openAlert(obj));
    }
  };
  /**
   * 구분 태그 삭제
   */
  const deleteCategory = () => {
    const config = {
      params: {
        Adr_Type_Num: editCategoryTarget.NUM,
      },
    };

    axios
      .delete("/address/AdrType", config)
      .then(() => {
        setEditCategoryTarget({
          NAME: "",
          NUM: "",
        });
        setIsEditColor(false);
        getcategoryData();
      })
      .catch(err => {
        console.log(err);
      });
  };

  const serachCategoryList = text => {
    let list = categoryList;
    list = list.filter(el => el.ADR_TYPE_NM === text);

    if (list.length > 0) {
      setIsNewCategory({
        ADR_TYPE_NUM: list[0].ADR_TYPE_NUM,
        ADR_TYPE_NM: list[0].ADR_TYPE_NM,
        ADR_TYPE_BGC: list[0].ADR_TYPE_BGC,
      });

      console.log(list);
      return;
    }

    let selected = selectedCategory;
    selected = selected.filter(el => el.type_nm === text);

    if (selected.length > 0) {
      setIsNewCategory({
        ADR_TYPE_NUM: "SELECTED",
        ADR_TYPE_NM: "SELECTED",
        ADR_TYPE_BGC: "SELECTED",
      });
      return;
    }

    setIsNewCategory({
      ADR_TYPE_NUM: "",
      ADR_TYPE_NM: "",
      ADR_TYPE_BGC: "",
    });
  };
  /**
   * 저장 버튼 클릭
   */
  const saveEvent = () => {
    if (data.EAMIL === "") {
      setAlertProps({
        ...alertProps,
        isShow: true,
        text: t("my.address.msg.err.nullmail"),
        cancelBtnDisplay: false,
        okBtnEventHandler: function () {
          closeAlertEvent();
        },
      });
    } else {
      if (!checkEmail(data.EAMIL.replaceAll(" ", ""))) {
        setAlertProps({
          ...alertProps,
          isShow: true,
          text: "잘못된 이메일 형식입니다.",
          cancelBtnDisplay: false,
          okBtnEventHandler: function () {
            closeAlertEvent();
          },
        });
        return;
      }

      if (type === "ADD") {
        createAddress();
      } else if (type === "EDIT") {
        updateAddress();
      }
    }
  };
  /**
   * 주소록 저장
   */
  const createAddress = () => {
    const config = {
      params: {
        Group_YN: REDUCER_STATE.GROUP_YN,
        Group_Num: REDUCER_STATE.GROUP_NUM,
        Adr_Nm: data.NAME,
        Adr_Email: data.EAMIL.replaceAll(" ", ""),
        Adr_Phone: data.PHONE,
        Adr_Memo: data.MEMO,
        Type_Num: convertCategory(),
      },
    };

    axios.post("/address/Address", null, config).then(res => {
      if (res.status === 200) {
        setAlertProps({
          ...alertProps,
          isShow: true,
          text: t("my.address.msg.result.save"),
          cancelBtnDisplay: false,
          okBtnEventHandler: function () {
            closeAlertEvent();
            props.setIsPopup(false);
            props.getAddressData();
            props.getAddressTotal();
          },
        });
      }
    });
  };
  /**
   * 주소록 수정
   */
  const updateAddress = () => {
    const config = {
      params: {
        Group_YN: REDUCER_STATE.GROUP_YN,
        Group_Num: REDUCER_STATE.GROUP_NUM,
        Adr_Nm: data.NAME,
        Adr_Email: data.EAMIL.replaceAll(" ", ""),
        Adr_Phone: data.PHONE,
        Adr_Memo: data.MEMO,
        Type_Num: convertCategory(),
        Address_Num: data.ADR_NUM,
      },
    };

    axios.put("/address/Address", null, config).then(res => {
      if (res.status === 200) {
        setAlertProps({
          ...alertProps,
          isShow: true,
          text: t("my.address.msg.result.edit"),
          cancelBtnDisplay: false,
          okBtnEventHandler: function () {
            closeAlertEvent();
            props.setIsPopup(false);
            props.getAddressData();
            props.getAddressTotal();
          },
        });
      }
    });
  };
  /**
   * 데이터 전송용 컨버터
   */
  const convertCategory = () => {
    let strTypeNum = "";

    if (selectedCategory.length > 0) {
      selectedCategory.map(
        data => (strTypeNum = strTypeNum + data.type_cd + ",")
      );
    }

    return strTypeNum;
  };
  /**
   * 이메일 정규식
   */
  const checkEmail = email => {
    var reg =
      /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
    return reg.test(email);
  };
  return (
    <div
      style={{
        padding: "20px 32px 36px 24px",
        height: "124px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
      onClick={() => {
        if (isAdd) {
          setIsAdd(false);
        }
        if (isEditColor) {
          setIsEditColor(false);
        }
      }}
    >
      <div onClick={e => e.stopPropagation()}>
        <Alert alertProps={alertProps} />
      </div>

      <div
        style={{
          display: "flex",
          alignItems:
            type === "ADD" || type === "EDIT" ? "flex-start" : "center",
          justifyContent: "space-between",
        }}
      >
        {type === "ADD" ? (
          <div
            style={{
              color: "#2B4AC4",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            {REDUCER_STATE.GROUP_YN === "N"
              ? t("my.address.txt.popup.newaddress.CD0401")
              : t(
                "my.address.txt.popup.newaddress." +
                REDUCER_STATE.GROUP_CATEGORY
              )}
          </div>
        ) : type === "READ" ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <img
              className="cursor"
              onClick={() => {
                favoriteAddress();
                props.setPopupFavorite(!props.popupFavorite);
              }}
              style={{
                width: "24px",
                height: "24px",
              }}
              src={
                data.FAVORITE_YN === "Y"
                  ? "/img/icon/address/favorite.png"
                  : "/img/icon/address/favorite_disable.png"
              }
              alt="favorite_btn"
            />
            <Avatar
              style={{
                marginLeft: "12px",
                background: data.COLOR,
                // src={ data.img }
              }}
            >
              {
                data.NAME !== ""
                  ? data.NAME.substr(0, 1)
                  : data.EAMIL.substr(0, 1)
              }
            </Avatar>
          </div>
        ) : (
          <div
            style={{
              color: "#2B4AC4",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            {REDUCER_STATE.GROUP_YN === "N"
              ? t("my.address.txt.popup.editaddress.CD0401")
              : t(
                "my.address.txt.popup.editaddress." +
                REDUCER_STATE.GROUP_CATEGORY
              )}
          </div>
        )}

        <div
          style={{
            display: "flex",
          }}
        >
          <div
            style={{
              marginRight: "16px",
              width: "128px",
              height: "45px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {type === "READ" ? (
              <AddressPopupReadField
                label={t("my.address.txt.popup.label.name")}
                value={data.NAME}
                type={type}
                onChange={value => setData({ ...data, NAME: value })}
              />
            ) : (
              <AddressPopupTextField
                label={t("my.address.txt.popup.label.name")}
                value={data.NAME}
                type={type}
                autoFocus={props.isPopup}
                onChange={value => setData({ ...data, NAME: value })}
              />
            )}
          </div>

          <div
            style={{
              marginRight: "16px",
              width: "248px",
              height: "45px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {type === "READ" ? (
              <AddressPopupReadField
                label={t("my.address.txt.popup.label.email")}
                value={data.EAMIL}
                type={type}
                onChange={value => setData({ ...data, EAMIL: value })}
              />
            ) : (
              <AddressPopupTextField
                label={t("my.address.txt.popup.label.email")}
                value={data.EAMIL}
                type={type}
                autoFocus={false}
                onChange={value => setData({ ...data, EAMIL: value })}
              />
            )}
          </div>

          <div
            style={{
              marginRight: "16px",
              width: "176px",
              height: "45px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {type === "READ" ? (
              <AddressPopupReadField
                label={t("my.address.txt.popup.label.phone")}
                value={data.PHONE}
                type={type}
                onChange={value => setData({ ...data, PHONE: value })}
              />
            ) : (
              <AddressPopupTextField
                label={t("my.address.txt.popup.label.phone")}
                value={data.PHONE}
                type={type}
                autoFocus={false}
                onChange={value => setData({ ...data, PHONE: value })}
              />
            )}
          </div>

          <div
            style={{
              width: "538px",
              height: "45px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {type === "READ" ? (
              <AddressCategoryReadField
                className="inline"
                label={t("my.address.txt.popup.label.category")}
                colorConverter={props.colorConverter}
                selectedCategory={selectedCategory}
              />
            ) : (
              <AddressCategoryTextField
                className="inline"
                label={t("my.address.txt.popup.label.category")}
                colorConverter={props.colorConverter}
                selectedCategory={selectedCategory}
                // categoryPopupView={categoryPopupView}
                clickEvent={() => {
                  getcategoryData();
                  setIsAdd(true);
                }}
                detachcategory={detachcategory}
              />
            )}

            <div
              name="address_category"
              id="address_categoryEditor"
              className="address_categotyEditor absolute bg-white border-gray"
              style={{
                display: isAdd ? "block" : "none",
                maxHeight: "344px",
                background: "#FFFFFF",
                inset: "0px auto auto 0px",
                transform: calculateHeight(data.INDEX),
              }}
              onClick={e => {
                e.stopPropagation();
                if (isEditColor) {
                  setIsEditColor(false);
                }
              }}
            >
              <div name="address_category">
                <input
                  name="address_category"
                  className="address_categoryEditor_input"
                  type="text"
                  maxLength="25"
                  onChange={e => {
                    setNewCategoryText(e.target.value);
                    serachCategoryList(e.target.value);
                  }}
                  onKeyDown={e => {
                    if (e.key === "Enter") {
                      saveNewCategory();
                    }
                  }}
                  onClick={e => {
                    e.stopPropagation();

                    if (isEditColor) {
                      setIsEditColor(false);
                    }
                  }}
                  value={newCategoryText}
                  placeholder={t("my.address.txt.popup.category.placeholder")}
                  autoComplete="off"
                />
                <p
                  name="address_category"
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    color: "#3E3E4F",
                    marginTop: "16px",
                    marginBottom: "0px",
                    marginLeft: "16px",
                  }}
                >
                  {t("my.address.txt.popup.category.choose")}
                </p>
              </div>

              <div
                name="address_category"
                id="address_categoryContent"
                className="address_categoryContent scrollbar"
              >
                {newCategoryText === "" ? (
                  categoryList.map((data, index) => (
                    <div key={"itemArr_" + index !== undefined ? index : "000"}>
                      <div
                        data-type-num={data.ADR_TYPE_NUM}
                        className="cursor font-white address_categoryEditor_item"
                        onClick={() => addSelectCategory(data)}
                      >
                        <div
                          className="inline"
                          style={{
                            background: props.colorConverter(data.ADR_TYPE_BGC),
                            width: "72px",
                            height: "24px",
                            borderRadius: "6px",
                            marginLeft: "16px",
                            padding: "2px 8px",
                          }}
                        >
                          {data.ADR_TYPE_NM}
                        </div>
                        <img
                          src="/img/icon/address/more.png"
                          alt="more"
                          id={"option_btn_" + index}
                          onClick={e => {
                            e.stopPropagation();
                            setEditCategoryTarget({
                              NAME: data.ADR_TYPE_NM,
                              NUM: data.ADR_TYPE_NUM,
                            });
                            setIsEditColor(true);
                          }}
                          style={{
                            float: "right",
                          }}
                        />
                      </div>
                    </div>
                  ))
                ) : isNewCategory.ADR_TYPE_NUM === "" ? (
                  <div name="address_category">
                    <div
                      name="address_category"
                      className="cursor address_category_item_cover"
                      style={{
                        width: "538px",
                        height: "48px",
                      }}
                      onClick={() => saveNewCategory()}
                    >
                      <div
                        style={{
                          padding: "12px 16px",
                        }}
                      >
                        <div
                          name="address_category"
                          className="inline"
                          style={{
                            color: "#2B4AC4",
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                        >
                          {t("my.address.btn.category.create")}
                        </div>

                        <div
                          name="address_category"
                          className="inline"
                          style={{
                            background: "#4865CC",
                            width: "72px",
                            height: "24px",
                            borderRadius: "6px",
                            fontSize: "14px",
                            fontWeight: "bold",
                            color: "white",
                            marginLeft: "16px",
                            padding: "2px 10px",
                          }}
                        >
                          {newCategoryText}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : isNewCategory.ADR_TYPE_NUM !== "SELECTED" ? (
                  <div>
                    <div
                      data-type-num={isNewCategory.ADR_TYPE_NUM}
                      className="cursor font-white address_categoryEditor_item"
                      onClick={() => {
                        addSelectCategory(isNewCategory);
                        setNewCategoryText("");
                      }}
                    >
                      <div
                        className="inline"
                        style={{
                          background: props.colorConverter(
                            isNewCategory.ADR_TYPE_BGC
                          ),
                          width: "72px",
                          height: "24px",
                          borderRadius: "6px",
                          marginLeft: "16px",
                          padding: "2px 8px",
                        }}
                      >
                        {isNewCategory.ADR_TYPE_NM}
                      </div>
                      <img
                        src="/img/icon/address/more.png"
                        alt="more"
                        onClick={e => {
                          e.stopPropagation();
                          setEditCategoryTarget({
                            NAME: isNewCategory.ADR_TYPE_NM,
                            NUM: isNewCategory.ADR_TYPE_NUM,
                          });
                          setIsEditColor(true);
                        }}
                        style={{
                          float: "right",
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                <div
                  id="address_categorySettingPopup"
                  style={{
                    display: isEditColor ? "block" : "none",
                    width: "144px",
                    height: "376px",
                    position: "absolute",
                    bottom: "0px",
                    right: "0px",
                    boxShadow: "0px 5px 16px #00000029",
                    borderRadius: "12px",
                    background: "white",
                  }}
                >
                  <div
                    className="cursor"
                    style={{
                      width: "144px",
                      textAlign: "center",
                      marginTop: "14px",
                      marginBottom: "14px",
                    }}
                    onClick={e => {
                      e.stopPropagation();
                      deleteCategory();
                    }}
                  >
                    <img
                      style={{
                        width: "14px",
                        height: "14px",
                      }}
                      className="inline"
                      src="/img/icon/address/category_delete.png"
                      alt="del_btn"
                    />
                    <p
                      className="inline"
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#3E3E4F",
                        marginLeft: "8px",
                      }}
                    >
                      {t("my.address.btn.category.delete")}
                    </p>
                  </div>

                  <div>
                    {categoryColor.map((data, index) => (
                      <div
                        key={"address_color_option_" + index}
                        className="address_color_option cursor"
                        style={{
                          width: "144px",
                          height: "32px",
                        }}
                        onClick={() => updateCategoryColor(data.label)}
                      >
                        <div
                          key={index}
                          style={{
                            background: props.colorConverter(data.label),
                            width: "16px",
                            height: "16px",
                            borderRadius: "4px",
                            float: "left",
                            marginLeft: "16px",
                            marginTop: "8px",
                            marginBottom: "8px",
                          }}
                        />
                        <div
                          className="inline"
                          style={{
                            fontSize: "14px",
                            fontWeight: "bold",
                            color: "#9B9FAF",
                            paddingLeft: "12px",
                            top: "5px",
                            position: "relative",
                          }}
                        >
                          {data.text}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          marginTop: "24px",
        }}
      >
        <div
          style={{
            marginRight: "48px",
            width: "954px",
            height: "45px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {type === "READ" ? (
            <AddressPopupReadField
              label={t("my.address.txt.popup.label.memo")}
              value={data.MEMO}
              type={type}
              onChange={value => setData({ ...data, MEMO: value })}
            />
          ) : (
            <AddressPopupTextField
              label={t("my.address.txt.popup.label.memo")}
              value={data.MEMO}
              type={type}
              autoFocus={false}
              onChange={value => setData({ ...data, MEMO: value })}
            />
          )}
        </div>

        {type === "READ" ? (
          <div
            style={{
              width: "135px",
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <img
              onClick={() => {
                setType("EDIT");
              }}
              className="cursor"
              style={{
                width: "48px",
                height: "48px",
              }}
              src="/img/icon/address/edit.png"
              alt="edit_btn"
            />
            <img
              onClick={() => props.deleteAddress(data.ADR_NUM)}
              className="cursor"
              style={{
                width: "48px",
                height: "48px",
              }}
              src="/img/icon/address/delete.png"
              alt="delete_btn"
            />
          </div>
        ) : (
          <div>
            <button
              className="address_popup_confirmBtn font-bold font-red font-14"
              onClick={() => props.setIsPopup(false)}
            >
              {t("common.btn.cancle")}
            </button>
            <button
              style={{ marginLeft: "8px" }}
              className="address_popup_confirmBtn font-bold font-blue font-14"
              onClick={() => saveEvent()}
            >
              {t("common.btn.save")}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default AddressDetailPopup;
