/**
 * Import List
 */
import React, { useState, useRef, useEffect, useCallback } from "react";
import Calendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import momentPlugin from "@fullcalendar/moment";
import { useTranslation } from "react-i18next";
import Alert from "component/tag/modal/alert";
import { useDispatch, useSelector } from "react-redux";
import {
  responseFocusCalendar,
  closeAlramList,
  openAlert,
  closeAlert,
  updateCalendarRangeInfo,
} from "actions/index";
/**
 * Version : 1.0
 * 파일명 : calendar_item.js
 * 작성일자 : 2021-10-05
 * 작성자 : 강연승
 * 설명 : 풀캘린더
 * 수정일자 : 2022-07-13
 * 수정자 : 강연승
 * 수정내역 : 슬롯 10분 설정
 */
function Calendar_item(props) {
  /**
   * REDUX
   */
  const USER_STATE = useSelector(state => state.userInfoReducer);
  const CALENDAR_STATE = useSelector(state => state.focusCalendarInfoReducer);
  const DATETIME_STATE = useSelector(state => state.dateTimeInfoReducer);
  /**
   * DISPATCH
   */
  const dispatch = useDispatch();
  /**
   * 언어변환
   */
  const { t } = useTranslation();

  const calendarRef = useRef();
  const [calendarApi, setCalendarApi] = useState();

  const [title, setTitle] = useState("");
  const [calendarViewType, setCalendarViewType] = useState("");
  const groupColor = color => {
    switch (color) {
      case "red":
        return "#D4667E";
      case "orange":
        return "#D77953";
      case "yellow":
        return "#E1A000";
      case "bean":
        return "#87AD26";
      case "green":
        return "#239D45";
      case "mint":
        return "#229A80";
      case "pine":
        return "#3EA0C6";
      case "pink":
        return "#BD66D4";
      case "purple":
        return "#9479DA";
      case "black":
        return "#666B82";
      default:
        // return "#2B4AC4";
        return "#6E85D7";
    }
  };

  const calendarMovePrev = () => {
    calendarApi.prev();
    setTitle(calendarApi.view.title);
    let start = calendarApi.view.activeStart.toISOString().replace(/\..*/, "");
    let end = calendarApi.view.activeEnd.toISOString().replace(/\..*/, "");
    props.getSchedules(start, end);
  };
  const calendarMoveNext = () => {
    calendarApi.next();
    setTitle(calendarApi.view.title);
    let start = calendarApi.view.activeStart.toISOString().replace(/\..*/, "");
    let end = calendarApi.view.activeEnd.toISOString().replace(/\..*/, "");
    props.getSchedules(start, end);
  };

  const calendarMoveToday = () => {
    calendarApi.today();
    setTitle(calendarApi.view.title);
    let start = calendarApi.view.activeStart.toISOString().replace(/\..*/, "");
    let end = calendarApi.view.activeEnd.toISOString().replace(/\..*/, "");
    props.getSchedules(start, end);
  };

  const changeViewType = type => {
    if (type === calendarApi.view.type) {
      return;
    }
    if (type === "dayGridMonth" && calendarApi.view.type === "timeGridWeek") {
      calendarApi.changeView("dayGridMonth");
    } else if (
      type === "timeGridWeek" &&
      calendarApi.view.type === "dayGridMonth"
    ) {
      calendarApi.changeView("timeGridWeek", new Date());
    }
    setTitle(calendarApi.view.title);
    setCalendarViewType(calendarApi.view.type);
    let start = calendarApi.view.activeStart.toISOString().replace(/\..*/, "");
    let end = calendarApi.view.activeEnd.toISOString().replace(/\..*/, "");
    props.setSchedules([]);
    props.getSchedules(start, end);
  };
  /**
   * 알림창 설정
   */
  const [alertProps, setAlertProps] = useState({
    text: "",
    isShow: false,
    cancelBtnDisplay: false,
    okBtnDisplay: true,
    okBtnEventHandler: function () {},
    cancleBtnEventHandler: function () {
      closeAlertEvent();
    },
  });
  /**
   * 알림창 닫기
   */
  const closeAlertEvent = useCallback(() => {
    setAlertProps({
      ...alertProps,
      isShow: false,
    });
  }, [alertProps]);

  useEffect(() => {
    if (calendarRef !== undefined) {
      setCalendarApi(calendarRef.current.getApi());
      let activeStart = calendarRef.current._calendarApi.view.activeStart
        .toISOString()
        .replace(/\..*/, "");
      let activeEnd = calendarRef.current._calendarApi.view.activeEnd
        .toISOString()
        .replace(/\..*/, "");
      props.setSchedules([]);
      props.getSchedules(activeStart, activeEnd);
      const obj = {
        ACTIVE_START: activeStart,
        ACTIVE_END: activeEnd,
      };
      dispatch(updateCalendarRangeInfo(obj));
    }
  }, [calendarRef]);

  useEffect(() => {
    if (calendarApi !== undefined) {
      props.setCalendarApi(calendarApi);
      setTitle(calendarApi.view.title);
      setCalendarViewType(calendarApi.view.type);
      let activeStart = calendarApi.view.activeStart
        .toISOString()
        .replace(/\..*/, "");
      let activeEnd = calendarApi.view.activeEnd
        .toISOString()
        .replace(/\..*/, "");
      props.setSchedules([]);
      props.getSchedules(activeStart, activeEnd);
      const obj = {
        ACTIVE_START: activeStart,
        ACTIVE_END: activeEnd,
      };
      dispatch(updateCalendarRangeInfo(obj));
    }
  }, [calendarApi]);

  /**
   * FOCUS
   */
  useEffect(() => {
    if (CALENDAR_STATE.IS_FOCUS) {
      calendarRef.current
        .getApi()
        .changeView("timeGridWeek", CALENDAR_STATE.START_DATE);
      dispatch(responseFocusCalendar());
      dispatch(closeAlramList());
    }
  }, [
    CALENDAR_STATE.IS_FOCUS,
    CALENDAR_STATE.START_DATE,
    calendarApi,
    dispatch,
  ]);

  return (
    <div
      className={
        props.groupColor !== null &&
        props.groupColor !== undefined &&
        props.groupColor !== ""
          ? "groupCalendar groupCalendar_" + props.groupColor
          : "myCalendar"
      }
    >
      <div className="fc">
        <div className="fc-header-toolbar fc-toolbar ">
          <div className="fc-toolbar-chunk">
            <button
              type="button"
              title="Previous month"
              aria-pressed="false"
              className="fc-prev-button fc-button fc-button-primary"
              onClick={calendarMovePrev}
            >
              <span className="fc-icon fc-icon-chevron-left"></span>
            </button>
            <button
              type="button"
              title="This month"
              disabled=""
              aria-pressed="false"
              className="fc-today-button fc-button fc-button-primary"
              style={{ margin: "0 10px" }}
              onClick={calendarMoveToday}
            >
              {t("calendar.TODAY")}
            </button>
            <button
              type="button"
              title="Next month"
              aria-pressed="false"
              className="fc-next-button fc-button fc-button-primary"
              onClick={calendarMoveNext}
            >
              <span className="fc-icon fc-icon-chevron-right"></span>
            </button>
          </div>
          <div className="fc-toolbar-chunk">
            <h2 className="fc-toolbar-title" id="fc-dom-2042">
              {title}
            </h2>
          </div>
          <div className="fc-toolbar-chunk">
            <div className="fc-button-group">
              <button
                type="button"
                title="month view"
                aria-pressed="true"
                className={
                  calendarViewType === "dayGridMonth"
                    ? "fc-dayGridMonth-button fc-button fc-button-primary fc-button-active"
                    : "fc-dayGridMonth-button fc-button fc-button-primary"
                }
                // className={
                //   "fc-dayGridMonth-button fc-button fc-button-primary fc-button-active"
                // }
                onClick={() => changeViewType("dayGridMonth")}
              >
                {t("calendar.MONTH")}
              </button>
              <button
                type="button"
                title="week view"
                aria-pressed="false"
                className={
                  calendarViewType === "timeGridWeek"
                    ? "fc-timeGridWeek-button fc-button fc-button-primary fc-button-active"
                    : "fc-timeGridWeek-button fc-button fc-button-primary"
                }
                // className={"fc-timeGridWeek-button fc-button fc-button-primary"}
                onClick={() => changeViewType("timeGridWeek")}
              >
                {t("calendar.WEEK")}
              </button>
              <button
                type="button"
                title="즐겨찾기"
                className={
                  props.isInput ? "exchange_btn" : "cursor exchange_btn"
                }
                disabled={props.isInput}
                onClick={props.changeViewType}
              >
                <img
                  src="/img/icon/bookmark/change_bookmark.png"
                  alt="exchange"
                  style={{ verticalAlign: "bottom" }}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <Calendar
        // schedulerLicenseKey="0736858632-fcs-1604369387"
        ref={calendarRef}
        plugins={[
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          momentPlugin,
        ]}
        eventBackgroundColor={groupColor(props.groupColor)}
        eventBorderColor={groupColor(props.groupColor)}
        initialView="dayGridMonth"
        // locale="ko"
        timeZone={USER_STATE.TIMEZONE}
        // timeZone="UTC"
        // expansionRows={false}
        aspectRatio="1.207"
        dayMaxEventRows={true}
        // scrollTime="09:00:00"
        scrollTime={new Date().toTimeString()}
        headerToolbar={false}
        // headerToolbar={{
        //   start: "prev today next",
        //   center: "title",
        //   end: "dayGridMonth,timeGridWeek",
        // }}
        views={{
          dayGridMonth: {
            titleFormat: "YYYY. MM",
            dayPopoverFormat: "YYYY. M. D ",
            dateClick: props.clickDate,
            displayEventTime: false,
            // viewClassNames: function (arg) {
            //   console.log(arg);
            //   if (
            //     props.groupColor !== null &&
            //     props.groupColor !== undefined &&
            //     props.groupColor !== ""
            //   ) {
            //     return `groupCalendar_${props.groupColor}`;
            //     // return [`groupCalendar_${props.groupColor}`];
            //   }
            // },
            dayHeaderContent: function (arg) {
              let weekday = "";
              switch (arg.dow) {
                case 0:
                  weekday = t("calendar.SUN");
                  break;
                case 1:
                  weekday = t("calendar.MON");
                  break;
                case 2:
                  weekday = t("calendar.TUE");
                  break;
                case 3:
                  weekday = t("calendar.WED");
                  break;
                case 4:
                  weekday = t("calendar.THU");
                  break;
                case 5:
                  weekday = t("calendar.FRI");
                  break;
                case 6:
                  weekday = t("calendar.SAT");
                  break;
                default:
              }
              return {
                html:
                  "<div style='font: normal normal bold 18px/24px Spoqa Han Sans Neo'>" +
                  weekday +
                  "</div>",
              };
            },
            eventClassNames: function (arg) {
              if (arg.event._def.extendedProps.groupYN === "Y") {
                return `groupEvent_${arg.event._def.extendedProps.groupColor}`;
              }
            },
            // dayCellContent: function (arg) {
            //   return {
            //     html:
            //       "<div class='fc-daygrid-day-number'>" +
            //       arg.date.getDate() +
            //       "</div>",
            //   };
            // },
            eventDrop: function (info) {
              info.revert();
              return;
            },
            eventResize: function (info) {
              info.revert();
              return;
            },
          },
          timeGridWeek: {
            titleFormat: "YYYY. MM",
            titleRangeSeparator: " ~ ",
            dayCellClassNames: function (arg) {
              let year = arg.date.getFullYear();
              let mon =
                arg.date.getMonth() + 1 < 10
                  ? "0" + (arg.date.getMonth() + 1)
                  : arg.date.getMonth() + 1;
              let dt =
                arg.date.getDate() < 10
                  ? "0" + arg.date.getDate()
                  : arg.date.getDate();
              let dateString = year + "-" + mon + "-" + dt;
              if (DATETIME_STATE.START_DATE === dateString) {
                return "selectDate";
              } else {
                return null;
              }
            },
            select: props.selectTime,
            dayHeaderContent: function (arg) {
              let weekday = "";
              switch (arg.dow) {
                case 0:
                  weekday = t("calendar.SUN");
                  break;
                case 1:
                  weekday = t("calendar.MON");
                  break;
                case 2:
                  weekday = t("calendar.TUE");
                  break;
                case 3:
                  weekday = t("calendar.WED");
                  break;
                case 4:
                  weekday = t("calendar.THU");
                  break;
                case 5:
                  weekday = t("calendar.FRI");
                  break;
                case 6:
                  weekday = t("calendar.SAT");
                  break;
                default:
              }
              return {
                html:
                  "<div class='weekday'>" +
                  weekday +
                  "</div><div class='date'>" +
                  arg.date.getDate() +
                  "</div>",
              };
            },
            slotLabelFormat: {
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
            },
            eventTimeFormat: {
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
            },
            eventDrop: function (info) {
              if (info.event.extendedProps.hostYN !== "Y") {
                info.revert();
                const obj = {
                  TEXT: "초대된 회의는 수정하실수 없습니다.",
                  submitEventHandler: () => dispatch(closeAlert()),
                };
                dispatch(openAlert(obj));
                return;
              }
              if (props.isInput) {
                info.revert();
                return;
              }
              if (
                new Date(info.event.start).toISOString().replace(/\..*/, "") <
                new Date(
                  new Date() * 1 + 3600000 * parseFloat(USER_STATE.OFFSET)
                )
                  .toISOString()
                  .replace(/\..*/, "")
              ) {
                info.revert();
                return;
              }
              if (
                new Date(info.oldEvent.start)
                  .toISOString()
                  .replace(/\..*/, "") <
                new Date(
                  new Date() * 1 + 3600000 * parseFloat(USER_STATE.OFFSET)
                )
                  .toISOString()
                  .replace(/\..*/, "")
              ) {
                info.revert();
                return;
              }
              if (
                new Date(info.oldEvent.end).toISOString().replace(/\..*/, "") <
                  new Date(
                    new Date() * 1 + 3600000 * parseFloat(USER_STATE.OFFSET)
                  )
                    .toISOString()
                    .replace(/\..*/, "") &&
                new Date(info.event.start).toISOString().replace(/\..*/, "") >
                  new Date(
                    new Date() * 1 + 3600000 * parseFloat(USER_STATE.OFFSET)
                  )
                    .toISOString()
                    .replace(/\..*/, "")
              ) {
                info.revert();
                return;
              }

              // if (
              setAlertProps({
                ...alertProps,
                isShow: true,
                text: t("schedule.msg.editScheduleTime"),
                cancelBtnDisplay: true,
                okBtnEventHandler: function () {
                  closeAlertEvent();
                  props.saveEventDrop(info, "drop");
                },
                cancleBtnEventHandler: function () {
                  closeAlertEvent();
                  info.revert();
                  return;
                },
              });
            },
            eventResize: function (info) {
              if (info.event.extendedProps.hostYN !== "Y") {
                info.revert();
                const obj = {
                  TEXT: "초대된 회의는 수정하실수 없습니다.",
                  submitEventHandler: () => dispatch(closeAlert()),
                };
                dispatch(openAlert(obj));
                return;
              }
              if (props.isInput) {
                info.revert();
                return;
              }
              if (
                new Date(info.oldEvent.start)
                  .toISOString()
                  .replace(/\..*/, "") <
                new Date(
                  new Date() * 1 + 3600000 * parseFloat(USER_STATE.OFFSET)
                )
                  .toISOString()
                  .replace(/\..*/, "")
              ) {
                info.revert();
                return;
              }
              if (
                new Date(info.event.start).toISOString().replace(/\..*/, "") <
                  new Date(
                    new Date() * 1 + 3600000 * parseFloat(USER_STATE.OFFSET)
                  )
                    .toISOString()
                    .replace(/\..*/, "") &&
                new Date(info.event.end).toISOString().replace(/\..*/, "") >
                  new Date(
                    new Date() * 1 + 3600000 * parseFloat(USER_STATE.OFFSET)
                  )
                    .toISOString()
                    .replace(/\..*/, "")
              ) {
                info.revert();
                return;
              }

              if (
                new Date(info.event.end).toISOString().replace(/\..*/, "") <
                new Date(
                  new Date() * 1 + 3600000 * parseFloat(USER_STATE.OFFSET)
                )
                  .toISOString()
                  .replace(/\..*/, "")
              ) {
                info.revert();
                return;
              }
              setAlertProps({
                ...alertProps,
                isShow: true,
                text: t("schedule.msg.editScheduleTime"),
                cancelBtnDisplay: true,
                okBtnEventHandler: function () {
                  closeAlertEvent();
                  props.saveEventDrop(info, "resize");
                },
                cancleBtnEventHandler: function () {
                  closeAlertEvent();
                  info.revert();
                  return;
                },
              });
            },
            viewDidMount: function (info) {
              if (info.view.type !== calendarViewType) {
                setCalendarViewType(info.view.type);
              }
              setTitle(info.view.title);
            },
            dayHeaderDidMount: function (info) {
              setTitle(info.view.title);
            },
          },
        }}
        slotDuration="00:10:00"
        eventClick={schedule_list => {
          props.selectSchedule(schedule_list.event);
        }}
        allDaySlot={false} //Week,day 일때 all-day 슬롯 표출 여부
        events={props.scheduleList}
        nowIndicator={true} //현재 시간을 나타내는 마커를 표시할지 여부.
        editable={true} // enable draggable events
        dragScroll={true}
        eventDurationEditable={true} //크기를 조정하여 이벤트 기간을 편집할 수 있도록 허용. 드래그로 크기조정
        eventStartEditable={true} //끌기를 통해 이벤트의 시작 시간을 편집할 수 있도록 허용. 드래그앤드랍
        selectOverlap={true} //사용자가 이벤트에 의해 점유되는 기간을 선택할 수 있는지 여부를 결정한다.
        selectable={true} //사용자가 클릭하여 끌어서 여러 일 또는 시간대를 강조 표시하도록 허용.
        selectMirror={true} //드래그한 영역 표시
        unselectAuto={false} //페이지의 다른 곳을 클릭하면 현재 선택 항목이 지워지는지 여부.
      />
      <Alert alertProps={alertProps} />
    </div>
  );
}

export default Calendar_item;
