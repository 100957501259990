import React, { forwardRef, useEffect, useState } from 'react'
import Picker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import { getMonth, getYear } from 'date-fns';
/**
 * Version : 1.0
 * 파일명 : StatisticsDatePicker.js
 * 작성일자 : 2022-03-14
 * 작성자 : 권도훈
 * 설명 : StatisticsDatePicker
 * 수정일자 : 2022-03-21
 * 수정자 : 권도훈
 * 수정내역 : 위치 수정
*/
function StatisticsDatePicker(props) {
    /**
     * select Box
     */
    const [yearView, setYearView] = useState(false);
    const [monthView, setMonthView] = useState(false);
    const [years, setYears] = useState([]);
    const [months, setMonths] = useState([]);
    const [days, setDays] = useState([]);
    const [dateRange, setDateRange] = useState({
        minDate: "",
        maxDate: ""
    });
    const [isSearch, setIsSearch] = useState(false);
    /**
     * DATE VALUE
     */
    useEffect(() => {
        setYears(range(1990, getYear(new Date()), 1));
        setMonths(["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"]);
        setDays(["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"]);

        if (props.type === "END_DATE" && props.date.START_DATE !== dateRange.minDate) {
            setDateRange({
                minDate: props.date.START_DATE,
                maxDate: new Date()
            });
        } else if (dateRange.minDate === "" && dateRange.maxDate === "") {
            if (props.type === "START_DATE") {
                setDateRange({
                    minDate: "",
                    maxDate: new Date()
                });
            } else {
                setDateRange({
                    minDate: props.date.START_DATE,
                    maxDate: new Date()
                });
            }
        }

    }, [dateRange, props.date.START_DATE, props.type]);

    useEffect(() => {
        if (props.date.END_DATE < props.date.START_DATE) {
            props.setDate({
                ...props.date,
                END_DATE: props.date.START_DATE
            });
        }
    }, [props]);

    useEffect(() => {
        if (isSearch) {
            // props.getData();
            props.setDataSearch(true);
            setIsSearch(false);
        }
    }, [isSearch, props]);
    /**
     * Custom input
     */
    const CustomInput = forwardRef(({ value, onClick }, ref) => (
        <div className='data-statistics-datepicker-inner'>
            <font className="drag-none">
                {dateConvert(value)}
            </font>
            <img
                className={
                    props.loading
                        ? 'drag-none'
                        : 'cursor'
                }
                src='/img/icon/data/calendar_button.png'
                alt='cal'
                onClick={onClick}
                ref={ref}
            />
        </div>
    ));
    /**
     * year range
     */
    const range = (sYear, eYear, step) => {
        let array = [];
        for (let i = eYear; i > sYear; --i) {
            if (!(i % step)) {
                array.push(i);
            }
        }
        return array;
    }

    const dateConvert = (value) => {
        const date = new Date(value);
        const day = days[date.getDay()];

        return value + " " + day;
    }

    return (
        <Picker
            dateFormat="yyyy-MM-dd"
            // selected={inputText}
            selected={
                props.type === "START_DATE"
                    ? props.date.START_DATE
                    : props.date.END_DATE
            }
            onChange={(date) => {
                if (props.type === "START_DATE") {
                    props.setDate({
                        ...props.date,
                        START_DATE: date
                    });
                } else {
                    props.setDate({
                        ...props.date,
                        END_DATE: date
                    });
                }

                setIsSearch(true);
            }}
            className="statistics-datepicker"
            popperClassName={
                props.type === "START_DATE"
                    ? "statistics-datepicker-start-popper"
                    : "statistics-datepicker-end-popper"
            }
            locale={ko}
            placeholderText="날짜 선택"
            minDate={dateRange.minDate}
            maxDate={dateRange.maxDate}
            customInput={<CustomInput />}
            renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
            }) => (
                <div
                    style={{
                        margin: "24px 24px 0 24px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <img
                        className='cursor'
                        onClick={decreaseMonth}
                        disabled={prevMonthButtonDisabled}
                        src="/img/icon/log/left.png"
                        alt='<'
                    />

                    <div
                        style={{
                            width: "82px",
                            height: "32px",
                            border: "1px solid #2B4AC4",
                            borderRadius: "8px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-around",
                            marginRight: "4px",
                            marginLeft: "24px"
                        }}
                        className='cursor'
                        onClick={() => {
                            setMonthView(false);
                            setYearView(!yearView);
                        }}
                    >
                        <div
                            style={{
                                color: "#2B4AC4",
                                fontSize: "14px",
                                fontWeight: "bold"
                            }}
                        >
                            {getYear(date)}
                        </div>
                        <img
                            src={yearView ? "/img/icon/log/up.png" : "/img/icon/log/down.png"}
                            alt="YEAR"
                        />
                        <div
                            className='react-datepicker-custom-selectbox scrollbar'
                            style={{
                                display: yearView ? "block" : "none",
                                transform: "translate(74px, 58px)"
                            }}
                        >
                            {
                                years.map((option, idx) => (
                                    <div
                                        className='cursor'
                                        key={idx}
                                        style={{
                                            marginTop: idx === 0
                                                ? "8px"
                                                : "0",
                                            marginBottom: idx === years.length - 1
                                                ? "8px"
                                                : "0",
                                            borderTopRightRadius: idx === 0
                                                ? "8px"
                                                : "0px",
                                            borderTopLeftRadius: idx === 0
                                                ? "8px"
                                                : "0px",
                                            borderBottomLeftRadius: idx === years.length - 1
                                                ? "8px"
                                                : "0px",
                                            borderBottomRightRadius: idx === years.length - 1
                                                ? "8px"
                                                : "0px",
                                        }}
                                        onClick={() => changeYear(option)}
                                    >
                                        <div
                                            style={{
                                                marginLeft: "16px"
                                            }}
                                        >
                                            {option}
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                    <div
                        style={{
                            width: "82px",
                            height: "32px",
                            border: "1px solid #2B4AC4",
                            borderRadius: "8px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-around",
                            marginRight: "24px"
                        }}
                        className='cursor'
                        onClick={() => {
                            setYearView(false);
                            setMonthView(!monthView);
                        }}
                    >
                        <div
                            style={{
                                color: "#2B4AC4",
                                fontSize: "14px",
                                fontWeight: "bold"
                            }}
                        >
                            {months[getMonth(date)]}
                        </div>
                        <img
                            src={monthView ? "/img/icon/log/up.png" : "/img/icon/log/down.png"}
                            alt="MONTH"
                        />
                        <div
                            className='react-datepicker-custom-selectbox scrollbar'
                            style={{
                                display: monthView ? "block" : "none",
                                transform: "translate(128px, 58px)"
                            }}
                        >
                            {
                                months.map((option, idx) => (
                                    <div
                                        className='cursor'
                                        key={idx}
                                        style={{
                                            marginTop: idx === 0
                                                ? "8px"
                                                : "0",
                                            marginBottom: idx === months.length - 1
                                                ? "8px"
                                                : "0",
                                            borderTopRightRadius: idx === 0
                                                ? "8px"
                                                : "0px",
                                            borderTopLeftRadius: idx === 0
                                                ? "8px"
                                                : "0px",
                                            borderBottomLeftRadius: idx === months.length - 1
                                                ? "8px"
                                                : "0px",
                                            borderBottomRightRadius: idx === months.length - 1
                                                ? "8px"
                                                : "0px"
                                        }}
                                        onClick={() => changeMonth(idx)}
                                    >
                                        <div
                                            style={{
                                                marginLeft: "16px"
                                            }}
                                        >
                                            {option}
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                    <img
                        className='cursor'
                        onClick={increaseMonth}
                        disabled={nextMonthButtonDisabled}
                        src="/img/icon/log/right.png"
                        alt='>'
                    />
                </div >
            )}
        />
    )
}

export default StatisticsDatePicker
