/**
 * Version : 1.0
 * 작성일자 : 2022-01-13
 * 작성자 : 권도훈
 * 설명 : 회의 영상 정보
 */
const initalState = {
    IS_LOADING: false
};

const loadingInfo = (state = initalState, action) => {
    switch (action.type) {
        case "loadingInfo/UPDATE":
            return {
                IS_LOADING: action.DATA.IS_LOADING,
            };
        default:
            return state;
    }
};

export default loadingInfo;
