import React, { useState } from "react";
import GroupMemberList from "component/tag/table/profile/groupMemberList";
import GroupMemberInvitePopup from "component/tag/popup/group/profile/groupMemberInvitePopup";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
/**
 * Version : 1.0
 * 파일명 : profileGroupMember.js
 * 작성일자 : 2021-11-29
 * 작성자 : 강연승
 * 설명 : 그룹 프로필 - 그룹 멤버 부분
 * 수정일자 : 2022-05-04
 * 수정자 : 강연승
 * 수정내역 : 그룹 컬러 수정
 */
function ProfileGroupMember(props) {
  /**
   * REDUX
   */
  const GROUP_STATE = useSelector(state => state.groupInfoReducer);
  const PAGING_STATE = useSelector(state => state.pagingInfoReducer);
  /**
   * 언어변환
   */
  const { t } = useTranslation();

  const [refresh, setRefresh] = useState(false);
  /* 그룹 카테고리 코드 변환 */
  const groupCategoryConvert = ctgryCD => {
    switch (ctgryCD) {
      case "CD0401":
        return "company";
      case "CD0402":
        return "company";
      case "CD0403":
        return "school";
      case "CD0404":
        return "private_institute";
      case "CD0405":
        return "university";
      case "CD0406":
        return "social";
      default:
        return "company";
    }
  };
  /**
   * 미팅호스트 초대 팝업 설정
   */
  const [inviteMemberOpen, setInviteMemberOpen] = useState(false);
  const handleDialogOpen = () => {
    setInviteMemberOpen(true);
  };
  const handleDialogClose = () => {
    setInviteMemberOpen(false);
  };

  /**
   * 태그
   */
  return (
    <div className="pf-common-container">
      <div className="profile_content_title">
        <div>
          {GROUP_STATE.AUTH_CD === "CD0301" ? (
            <font className="pf-content-title-txt title">
              {t("group.profile.meetingHostSetting.ownerTitle", {
                MEETING_HOST: t(
                  "group.thema." +
                    groupCategoryConvert(GROUP_STATE.GROUP_CATEGORY) +
                    ".meetingHost"
                ),
                CNT: PAGING_STATE.TOTAL,
              })}
            </font>
          ) : (
            <font className="pf-content-title-txt title">
              {t("group.profile.meetingHostSetting.hostTitle", {
                MEETING_HOST: t(
                  "group.thema." +
                    groupCategoryConvert(GROUP_STATE.GROUP_CATEGORY) +
                    ".meetingHost"
                ),
                CNT: PAGING_STATE.TOTAL,
              })}
            </font>
          )}
        </div>
        {/* <p className="profile_content_title_text">
          
        </p> */}

        {GROUP_STATE.AUTH_CD === "CD0301" ? (
          <div>
            <button
              className={
                "cursor group_profile_invite_btn_" + GROUP_STATE.GROUP_COLOR
              }
              onClick={handleDialogOpen}
            >
              {t("group.profile.meetingHostSetting.hostInvite", {
                MEETING_HOST: t(
                  "group.thema." +
                    groupCategoryConvert(GROUP_STATE.GROUP_CATEGORY) +
                    ".meetingHost"
                ),
              })}
            </button>
          </div>
        ) : null}
      </div>

      <div>
        <GroupMemberInvitePopup
          open={inviteMemberOpen}
          close={handleDialogClose}
          setRefresh={setRefresh}
        />

        <GroupMemberList
          refresh={refresh}
          setRefresh={setRefresh}
          getGroupInfo={props.getGroupInfo}
        />
      </div>
    </div>
  );
}

export default ProfileGroupMember;
