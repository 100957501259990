import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
/**
 * Version : 1.0
 * 파일명 : LicenseListHeader.js
 * 작성일자 : 2022-04-22
 * 작성자 : 권도훈
 * 설명 : 회의이용권 목록 헤더
 * 수정일자 : 2022-06-13
 * 수정자 : 강연승
 * 수정내역 : sobridge 데모 - 결제 안내 텍스트 주석처리
 */
function LicenseListHeader(props) {
  const total = props.res.data.read();

  const { t } = useTranslation();
  const GROUP_STATE = useSelector(state => state.groupInfoReducer);

  return (
    <div>
      <font className="pf-content-title-txt title">
        {GROUP_STATE.GROUP_YN === "Y"
          ? GROUP_STATE.AUTH_CD === "CD0302"
            ? t("my.profile.txt.grouplicenseListTitle") +
              " (" +
              total +
              t("common.txt.count") +
              ")"
            : t("my.profile.txt.licenseListTitle")
          : t("my.profile.txt.licenseListTitle") +
            " (" +
            total +
            t("common.txt.count") +
            ")"}
      </font>

      {/* <font className="pf-content-title-txt comment">
                결제는 매월 10일에 등록하신 결제 수단으로 자동 청구됩니다.
            </font> */}
    </div>
  );
}

export default LicenseListHeader;
