/**
 * Version : 1.0
 * 작성일자 : 2022-01-04
 * 작성자 : 강연승
 * 설명 : 예약 날짜, 시간 정보
 * 수정일자 :  2022-01-14
 * 수정자 : 강연승
 * 수정내역 : SCHEDULE_DATE 제거, START_DATE / END_DATE 추가 재업로드
 */
const initalState = {
  INPUT_TYPE: "",
  START_DATE: "",
  END_DATE: "",
  START_TIME: "",
  END_TIME: "",
};

const dateTimeInfo = (state = initalState, action) => {
  switch (action.type) {
    case "dateTimeInfo/UPDATE":
      return {
        INPUT_TYPE: action.data.INPUT_TYPE,
        START_DATE: action.data.START_DATE,
        END_DATE: action.data.END_DATE,
        START_TIME: action.data.START_TIME,
        END_TIME: action.data.END_TIME,
      };
    default:
      return state;
  }
};

export default dateTimeInfo;
