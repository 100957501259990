/**
 * Import List
 */
import React, { useState, useEffect } from "react";
import { useRouteMatch, useLocation } from "react-router-dom";
import Timeline from "./timeline";
import Uselog from "./uselog";
import ResSidebar from "./resSidebar";
import EditSidebar from "./editSidebar";
import CreateBookmarkSidebar from "./createBookmarkSidebar";
import EditBookmarkSidebar from "./editBookmarkSidebar";
import MeetingNowBtn from "../meetingNowBtn";
/**
 * Version : 1.0
 * 파일명 : sidebar.js
 * 작성일자 : 2021-10-05
 * 작성자 : 권도훈
 * 설명 : 우측 사이드바
 * 수정일자 : 2022-04-21
 * 수정자 : 강연승
 * 수정내역 : 사이드바 수정
 */
function InputRender({
  resSidebarType,
  setResSidebarType,
  calendarApi,
  meetingInfo,
  handleDataState,
  setMeetingInfo,
  bookmarkInputInfo,
  handleBookmarkReload,
  setLoading,
  setIsInput,
}) {
  switch (resSidebarType) {
    case "res":
      return (
        <ResSidebar
          calendarApi={calendarApi}
          meetingInfo={meetingInfo}
          handleDataState={handleDataState}
          setMeetingInfo={setMeetingInfo}
          setLoading={setLoading}
          setIsInput={setIsInput}
        />
      );
    case "edit":
      return (
        <EditSidebar
          setResSidebarType={setResSidebarType}
          calendarApi={calendarApi}
          meetingInfo={meetingInfo}
          handleDataState={handleDataState}
          setMeetingInfo={setMeetingInfo}
          setLoading={setLoading}
          setIsInput={setIsInput}
        />
      );
    case "createBookmark":
      return (
        <CreateBookmarkSidebar
          setResSidebarType={setResSidebarType}
          handleBookmarkReload={handleBookmarkReload}
          setLoading={setLoading}
          setIsInput={setIsInput}
        />
      );
    case "editBookmark":
      return (
        <EditBookmarkSidebar
          bookmarkInputInfo={bookmarkInputInfo}
          setResSidebarType={setResSidebarType}
          handleBookmarkReload={handleBookmarkReload}
          setLoading={setLoading}
          setIsInput={setIsInput}
        />
      );
  }
}

function RenderEvent({
  tag,
  isToggle,
  setIsToggle,
  groupNum,
  setIsInput,
  setLoading,
  setResSidebarType,
  dataState,
  handleDataState,
  calendarApi,
  setMeetingInfo,
}) {
  switch (tag) {
    case "address":
      return <></>;
    case "record":
      return <></>;
    case "home":
      if (isToggle) {
        return (
          <div style={{ margin: "0 40px" }}>
            <MeetingNowBtn />
            <Timeline
              groupYN="N"
              groupNum=""
              setIsInput={setIsInput}
              setLoading={setLoading}
              setResSidebarType={setResSidebarType}
              dataState={dataState}
              handleDataState={handleDataState}
              calendarApi={calendarApi}
              isToggle={isToggle}
              setIsToggle={setIsToggle}
              setMeetingInfo={setMeetingInfo}
              height={"708px"}
            />
          </div>
        );
      } else {
        return (
          <div style={{ margin: "0 40px" }}>
            <MeetingNowBtn />
            <Uselog
              groupYN="N"
              groupNum=""
              setIsInput={setIsInput}
              setLoading={setLoading}
              setResSidebarType={setResSidebarType}
              dataState={dataState}
              handleDataState={handleDataState}
              calendarApi={calendarApi}
              isToggle={isToggle}
              setIsToggle={setIsToggle}
              setMeetingInfo={setMeetingInfo}
              height={"708px"}
            />
          </div>
        );
      }
    case "group":
      if (isToggle) {
        return (
          <div style={{ margin: "32px 40px 0" }}>
            <Timeline
              groupYN="Y"
              groupNum=""
              setIsInput={setIsInput}
              setLoading={setLoading}
              setResSidebarType={setResSidebarType}
              dataState={dataState}
              handleDataState={handleDataState}
              calendarApi={calendarApi}
              isToggle={isToggle}
              setIsToggle={setIsToggle}
              setMeetingInfo={setMeetingInfo}
              height={"850px"}
            />
          </div>
        );
      } else {
        return (
          <div style={{ margin: "32px 40px 0" }}>
            <Uselog
              groupYN="Y"
              groupNum=""
              setIsInput={setIsInput}
              setLoading={setLoading}
              setResSidebarType={setResSidebarType}
              dataState={dataState}
              handleDataState={handleDataState}
              calendarApi={calendarApi}
              isToggle={isToggle}
              setIsToggle={setIsToggle}
              setMeetingInfo={setMeetingInfo}
              height={"850px"}
            />
          </div>
        );
      }
    case "groupMain":
      if (isToggle) {
        return (
          <div style={{ margin: "0 40px" }}>
            <MeetingNowBtn />
            <Timeline
              groupYN="Y"
              groupNum={groupNum}
              setIsInput={setIsInput}
              setLoading={setLoading}
              setResSidebarType={setResSidebarType}
              dataState={dataState}
              handleDataState={handleDataState}
              calendarApi={calendarApi}
              isToggle={isToggle}
              setIsToggle={setIsToggle}
              setMeetingInfo={setMeetingInfo}
              height={"708px"}
            />
          </div>
        );
      } else {
        return (
          <div style={{ margin: "0 40px" }}>
            <MeetingNowBtn />
            <Uselog
              groupYN="Y"
              groupNum={groupNum}
              setIsInput={setIsInput}
              setLoading={setLoading}
              setResSidebarType={setResSidebarType}
              dataState={dataState}
              handleDataState={handleDataState}
              calendarApi={calendarApi}
              isToggle={isToggle}
              setIsToggle={setIsToggle}
              setMeetingInfo={setMeetingInfo}
              height={"708px"}
            />
          </div>
        );
      }
    default:
      return <></>;
  }
}

function Sidebar(props) {
  const type = useRouteMatch("/app/:type");
  const location = useLocation();
  let tag = useRouteMatch("/app/" + type.params.type + "/:tag");
  const [isToggle, setIsToggle] = useState(true);

  if (type.params.type === "group" && tag === null) {
    tag = "group";
  } else if (type.params.type === "home" && tag === null) {
    tag = "home";
  } else {
    tag = tag.params.tag;
  }
  useEffect(() => {
    props.setIsInput(false);
    props.setResSidebarType("res");
    setIsToggle(true);
  }, [tag]);

  useEffect(() => {
    if (location.state !== null && location.state !== undefined) {
      if (location.state.detailType === "history") {
        props.setIsInput(true);
        props.setResSidebarType("res");
      } else if (location.state.detailType === "timeline") {
        props.setIsInput(true);
        props.setResSidebarType("edit");
      }
    }
  }, [location]);
  return (
    <>
      <div
        className="float-right border-l-navy"
        style={{
          display: tag === "address" || tag === "meetingNow" ? "none" : "block",
          width: "472px",
        }}
      >
        {props.isInput ? (
          <InputRender
            resSidebarType={props.resSidebarType}
            setResSidebarType={props.setResSidebarType}
            calendarApi={props.calendarApi}
            meetingInfo={props.meetingInfo}
            handleDataState={props.handleDataState}
            setMeetingInfo={props.setMeetingInfo}
            bookmarkInputInfo={props.bookmarkInputInfo}
            handleBookmarkReload={props.handleBookmarkReload}
            setLoading={props.setLoading}
            setIsInput={props.setIsInput}
          />
        ) : (
          <RenderEvent
            tag={tag ? tag : null}
            isToggle={isToggle}
            setIsToggle={setIsToggle}
            groupNum={props.groupNum}
            setIsInput={props.setIsInput}
            setLoading={props.setLoading}
            setResSidebarType={props.setResSidebarType}
            dataState={props.dataState}
            handleDataState={props.handleDataState}
            calendarApi={props.calendarApi}
            setMeetingInfo={props.setMeetingInfo}
          />
        )}
      </div>
    </>
  );
}

export default React.memo(Sidebar);
