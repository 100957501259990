import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
/**
 * Version : 1.0
 * 파일명 : licenseEmpty.js
 * 작성일자 : 2021-12-15
 * 작성자 : 권도훈
 * 설명 : 회의이용권 없는 경우
 * 수정일자 : 2022-02-10
 * 수정자 : 강연승
 * 수정내역 : 텍스트 수정 영상회의 -> 화상회의, 웹폰트 변경
 */
function LicenseEmpty() {
  /**
   * TRANSLATION
   */
  const { t } = useTranslation();
  /**
   * GlobalState
   */
  const groupInfoState = useSelector(state => state.groupInfoReducer);
  return (
    <tr>
      <td>
        <div
          className="flex-y-center"
          style={{
            height: "620px",
            width: "924px",
            position: "absolute",
            font: "normal normal bold 14px/21px Spoqa Han Sans Neo",
            color: "#BFC1E5",
            marginTop: "30px",
            justifyContent: "flex-start",
          }}
        >
          <img src="/img/icon/profile/license_non.png" alt="licenseNon" />
          <div
            style={{
              marginTop: "16px",
            }}
          >
            {groupInfoState.GROUP_YN === "Y" &&
            groupInfoState.AUTH_CD === "CD0302"
              ? t("group.profile.txt.emptyLicenseTitle")
              : t("my.profile.txt.emptyLicenseTitle")}
          </div>
          <div>
            {groupInfoState.GROUP_YN === "Y" &&
            groupInfoState.AUTH_CD === "CD0302"
              ? t("group.profile.txt.emptyLicenseComment")
              : t("my.profile.txt.emptyLicenseComment")}
          </div>
        </div>
      </td>
    </tr>
  );
}

export default LicenseEmpty;
