import React from "react";
import ReactModal from "react-modal";
import "css/alertsupmain.css";
import { useHistory } from "react-router-dom";
/* Version : 1.0
 * 파일명 : supportPopup.js
 * 작성일자 : 2022-03-15
 * 작성자 : 정지윤
 * 설명 : 문의 후 팝업 창
 */
function SupportPopupmain(props) {
  const closePopup = () => {
    props.close();
  };

  return (
    <div className="SPM">
      <ReactModal
        isOpen={props.open} //모달 Open(Boolean)
        className="supportPopupmain" //모달 ClassName
        overlayClassName="supportPopup_Overlay" //모달오버레이 ClassName
        onRequestClose={closePopup}
        shouldCloseOnEsc={true}
        animationType="fade"
        transparent={true}
        closeTimeoutMS={200}
      >
        <div className="checkimage">
          <img
            className="checkimagefile"
            src="/img/checksup.png"
            alt="체크"
          ></img>
        </div>

        <div className="tt-1">
          <div
            className="texthead"
            style={{
              zIndex: 99,
            }}
          >
            <div className="textblack">고객님의</div>
            <div className="textblue">문의사항</div>
            <div className="textblack">이</div>
          </div>

          <div
            className="outlinemain"
            style={{
              zIndex: 1,
            }}
          >
            <div className="innertextmain">
              <div className="textblack">접수되었습니다!</div>

              {/* <div className='textmid'>
                                <div className='textmid1'>
                                  문의하신 내용은 정확히 검토 후
                                </div> 

                                <div className='textmid2'>
                                   <div className='textblack'>입력하신</div>  <div className='textorange'>  메일로 회신</div> <div className='textblack'>드리도록 하겠습니다.</div>
                                </div> 
                          </div> */}

              <div className="textunder">
                <div>항상 SoBridge에 관심과 사랑을 보여주시는 고객님께</div>
                <div>
                  진심으로 감사드리며, 앞으로 더 나은 서비스를 제공할 수 있도록
                </div>
                <div>최선을 다 하겠습니다.</div>
              </div>

              <div className="textlast">-SoBridge 드림-</div>
            </div>
          </div>
        </div>
        <div className="hover">
          <div className="gohomebutton" onClick={closePopup}>
            <span className="buttontext cursor"> 확인 </span>
          </div>
        </div>
      </ReactModal>
    </div>
  );
}

export default SupportPopupmain;
