import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { openAlert, closeAlert } from "actions/index";
import { useDispatch } from 'react-redux';
/**
 * Version : 1.0
 * 파일명 : PaymentCard.js
 * 작성일자 : 2022-04-07
 * 작성자 : 권도훈
 * 설명 : 카드 등록
 * 수정일자 : 2022-04-19
 * 수정자 : 권도훈
 * 수정내역 : 버튼 UI 수정
 */
function PaymentCard(props) {
    /**
     * DISPATCH
     */
    const dispatch = useDispatch();
    const [disable, setDisable] = useState(true);
    const [isSave, setIsSave] = useState(false);
    const [cardType, setCardType] = useState("PERSONAL");
    const [cardInfo, setCardInfo] = useState({
        cardNumber: "",
        cardExpirationYear: "",
        cardExpirationMonth: "",
        cardCvc: "",
        cardPassword: "",
        customerIdentityNumber: ""
    });
    const [digit, setDigit] = useState({
        cardCompany: "",
        cardNumberDigit: "",
        cardType: "",
        cvcDigit: "",
        issueCompany: "",
        ownerType: "",
        passwordDigit: ""
    });
    const num1Ref = useRef("");
    const num2Ref = useRef("");
    const num3Ref = useRef("");
    const num4Ref = useRef("");
    const monthRef = useRef("");
    const yearRef = useRef("");
    const cvcRef = useRef("");
    const passwordRef = useRef("");

    const closeHandler = useCallback(() => {
        if (props.initType === "PRODUCT") {
            props.setType("PAYMENT");
            props.setIsRender(true);
            dispatch(closeAlert());
        } else {
            props.popupClose();
            props.isRefresh(true);
            dispatch(closeAlert());
        }

    }, [dispatch, props]);

    const saveCardPayment = useCallback(() => {
        axios.delete("/payment/recurring", null)
            .then(res => {
                if (res.status === 200) {
                    const config = {
                        params: cardInfo
                    }

                    let url = "";

                    if (cardType === "PERSONAL") {
                        url = "/payment/card";
                    } else {
                        url = "/payment/billing";
                    }

                    axios.post(url, null, config)
                        .then(res => {
                            if (res.status === 200) {
                                if (res.data.isSuccess === true) {
                                    const obj = {
                                        TEXT:
                                            <>
                                                <div className='alert-font title'>
                                                    인증 완료!
                                                </div>
                                                <div className='alert-font content'>
                                                    카드가 등록되었습니다.
                                                </div>
                                            </>,
                                        submitEventHandler: () => closeHandler()
                                    };
                                    dispatch(openAlert(obj));
                                } else {
                                    let msg = res.data.message.split(": ")[1].split('message":"')[1].replace('"}"', "").replaceAll("\n", "");
                                    const obj = {
                                        TEXT: msg,
                                        submitEventHandler: () => dispatch(closeAlert())
                                    };
                                    dispatch(openAlert(obj));
                                }
                            }
                        })
                        .catch(() => {
                            const obj = {
                                TEXT: "저장에 실패했습니다.",
                                submitEventHandler: () => dispatch(closeAlert())
                            };
                            dispatch(openAlert(obj));
                        })
                }
            })
            .catch(() => {
                const config = {
                    params: cardInfo
                }

                let url = "";

                if (cardType === "PERSONAL") {
                    url = "/payment/card";
                } else {
                    url = "/payment/billing";
                }

                axios.post(url, null, config)
                    .then(res => {
                        if (res.status === 200) {
                            if (res.data.isSuccess === true) {
                                const obj = {
                                    TEXT:
                                        <>
                                            <div className='alert-font title'>
                                                인증 완료!
                                            </div>
                                            <div className='alert-font content'>
                                                카드가 등록되었습니다.
                                            </div>
                                        </>,
                                    submitEventHandler: () => closeHandler()
                                };
                                dispatch(openAlert(obj));
                            } else {
                                let msg = res.data.message.split(": ")[1].split('message":"')[1].replace('"}"', "").replaceAll("\n", "");
                                const obj = {
                                    TEXT: msg,
                                    submitEventHandler: () => dispatch(closeAlert())
                                };
                                dispatch(openAlert(obj));
                            }
                        }
                    })
                    .catch(() => {
                        const obj = {
                            TEXT: "저장에 실패했습니다.",
                            submitEventHandler: () => dispatch(closeAlert())
                        };
                        dispatch(openAlert(obj));
                    })
            })
    }, [cardInfo, cardType, closeHandler, dispatch]);

    useEffect(() => {
        if (
            num1Ref.current.value.length > 3 &&
            num2Ref.current.value.length > 3 &&
            num3Ref.current.value.length > 3 &&
            num4Ref.current.value.length > 3 &&
            cardInfo.cardExpirationMonth.length > 1 &&
            cardInfo.cardExpirationYear.length > 1 &&
            cardInfo.cardPassword.length > 1
        ) {
            if (cardType === "PERSONAL" && cardInfo.cardCvc.length > 2) {
                setDisable(false);
            } else if (cardType === "CORPORATE" && cardInfo.customerIdentityNumber.length > 9) {
                setDisable(false);
            }
        } else {
            setDisable(true);
        }
    }, [cardInfo.cardCvc.length, cardInfo.cardExpirationMonth.length, cardInfo.cardExpirationYear.length, cardInfo.cardPassword.length, cardInfo.customerIdentityNumber.length, cardType]);

    useEffect(() => {
        if (isSave) {
            saveCardPayment();
            setIsSave(false);
        }
    }, [isSave, saveCardPayment]);
    /**
     * 숫자 포맷
     */
    const numberFormat = (num) => {
        return num.toString().replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
    }
    /**
     * 취소
     */
    const cancleHandler = () => {
        props.setType("ADD_PAYMENT");
        props.setIsRender(true);
    };
    /**
     * 카드사 조회
     */
    const getCardCompany = () => {
        const config = {
            params: {
                binNum: num1Ref.current.value + num2Ref.current.value.substr(0, 2)
            }
        }

        axios.get("/payment/cardcompany", config)
            .then(res => {
                if (res.status === 200) {
                    if (res.data.isSuccess === true) {
                        setDigit({
                            cardCompany: res.data.cardCompany,
                            cardNumberDigit: res.data.cardNumberDigit,
                            cardType: res.data.cardType,
                            cvcDigit: res.data.cvcDigit,
                            issueCompany: res.data.issueCompany,
                            ownerType: res.data.ownerType,
                            passwordDigit: res.data.passwordDigit,
                        });
                    } else {
                        setDigit({
                            cardCompany: "",
                            cardNumberDigit: "",
                            cardType: "",
                            cvcDigit: "",
                            issueCompany: "",
                            ownerType: "",
                            passwordDigit: ""
                        });
                        setCardInfo({
                            ...cardInfo,
                            cardCvc: "",
                            cardPassword: ""
                        });
                    }
                }
            })
            .catch(() => {
                setDigit({
                    cardCompany: "",
                    cardNumberDigit: "",
                    cardType: "",
                    cvcDigit: "",
                    issueCompany: "",
                    ownerType: "",
                    passwordDigit: ""
                });
                setCardInfo({
                    ...cardInfo,
                    cardCvc: "",
                    cardPassword: ""
                });
            })
    }

    return (
        <div className='py-product-add'>
            <div className='py-product-add-header'>
                <font>
                    결제 수단 등록
                </font>
            </div>

            <div className='py-product-add-container'>
                <div className='py-product-card'>
                    <div className='py-product-card-inner'>
                        <section className='py-product-card-section'>
                            <p className='py-product-card-title'>
                                카드 등록
                            </p>

                            <p className='py-product-card-comment'>
                                카드 정보를 입력해주세요.
                            </p>

                            <div className='py-product-card-type'>
                                <div className='py-product-card-type-inner'>
                                    <div className='py-product-card-type-item'>
                                        <img
                                            className={
                                                cardType !== "PERSONAL"
                                                    ? 'cursor'
                                                    : ''
                                            }
                                            src={
                                                cardType === "PERSONAL"
                                                    ? "/img/icon/payment/checked.png"
                                                    : "/img/icon/payment/check.png"
                                            }
                                            alt=""
                                            onClick={() => {
                                                if (cardType !== "PERSONAL") {
                                                    setDisable(true);
                                                    setCardType("PERSONAL");
                                                    setCardInfo({
                                                        ...cardInfo,
                                                        customerIdentityNumber: ""
                                                    });
                                                }
                                            }}
                                        />
                                        <font>
                                            개인/개인(법인)카드
                                        </font>
                                    </div>

                                    <div className='py-product-card-type-item'>
                                        <img
                                            className={
                                                cardType !== "CORPORATE"
                                                    ? 'cursor'
                                                    : ''
                                            }
                                            src={
                                                cardType === "CORPORATE"
                                                    ? "/img/icon/payment/checked.png"
                                                    : "/img/icon/payment/check.png"
                                            }
                                            alt=""
                                            onClick={() => {
                                                if (cardType !== "CORPORATE") {
                                                    setDisable(true);
                                                    setCardType("CORPORATE");
                                                    setCardInfo({
                                                        ...cardInfo,
                                                        cardCvc: ""
                                                    });
                                                }
                                            }}
                                        />
                                        <font>
                                            법인공용카드
                                        </font>
                                    </div>
                                </div>
                            </div>

                        </section>

                        <div className='py-product-card-input num'>
                            <div className='py-product-card-input-inner num'>
                                <div className='py-product-card-input-comment'>
                                    {
                                        digit.cardCompany === ""
                                            ? "카드 번호를 입력해주세요."
                                            : digit.cardCompany
                                    }
                                </div>

                                <div className='py-product-card-input-num-area'>
                                    <input
                                        type='text'
                                        placeholder='0000'
                                        maxLength="4"
                                        ref={num1Ref}
                                        onChange={
                                            e => {
                                                e.target.value = numberFormat(e.target.value);

                                                getCardCompany();

                                                if (e.target.value.length > 3) {
                                                    num2Ref.current.focus();
                                                }
                                            }
                                        }
                                    />
                                    <font>-</font>
                                    <input
                                        type='text'
                                        placeholder='0000'
                                        maxLength="4"
                                        ref={num2Ref}
                                        onChange={
                                            e => {
                                                e.target.value = numberFormat(e.target.value);

                                                getCardCompany();

                                                if (e.target.value.length > 3) {
                                                    num3Ref.current.focus();
                                                }
                                            }
                                        }
                                    />
                                    <font>-</font>
                                    <input
                                        type='text'
                                        placeholder='0000'
                                        maxLength="4"
                                        ref={num3Ref}
                                        onChange={
                                            e => {
                                                e.target.value = numberFormat(e.target.value);
                                                if (e.target.value.length > 3) {
                                                    num4Ref.current.focus();
                                                }
                                            }
                                        }
                                    />
                                    <font>-</font>
                                    <input
                                        type='text'
                                        placeholder='0000'
                                        maxLength="4"
                                        ref={num4Ref}
                                        onChange={
                                            e => {
                                                e.target.value = numberFormat(e.target.value);
                                                if (e.target.value.length > 3) {
                                                    monthRef.current.focus();
                                                }
                                            }
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='py-product-card-input expire'>
                            <div className='py-product-card-input-inner expire'>
                                <div className='py-product-card-input-expire-area'>
                                    <div className='py-product-card-input-comment'>
                                        유효기간
                                    </div>

                                    <div className='py-product-card-input-expire-area'>
                                        <input
                                            type='text'
                                            placeholder='MM'
                                            maxLength="2"
                                            value={cardInfo.cardExpirationMonth}
                                            ref={monthRef}
                                            onChange={e => {
                                                setCardInfo({
                                                    ...cardInfo,
                                                    cardExpirationMonth: numberFormat(e.target.value)
                                                });
                                                if (e.target.value.length > 1) {
                                                    yearRef.current.focus();
                                                }
                                            }}
                                        />
                                        <font>/</font>
                                        <input
                                            type='text'
                                            placeholder='YY'
                                            maxLength="2"
                                            value={cardInfo.cardExpirationYear}
                                            ref={yearRef}
                                            onChange={e => {
                                                setCardInfo({
                                                    ...cardInfo,
                                                    cardExpirationYear: numberFormat(e.target.value)
                                                });
                                                if (e.target.value.length > 1) {
                                                    cvcRef.current.focus();
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='py-product-card-input-side'>
                            {
                                cardType === "PERSONAL"
                                    ?
                                    <div className='py-product-card-input cvc'>
                                        <div className='py-product-card-input-inner cvc'>
                                            <div className='py-product-card-input-comment'>
                                                CVC
                                            </div>

                                            <div className='py-product-card-input-cvc-area'>
                                                <input
                                                    type='text'
                                                    placeholder='CVC'
                                                    maxLength={digit.cvcDigit}
                                                    value={cardInfo.cardCvc}
                                                    ref={cvcRef}
                                                    onChange={e => {
                                                        setCardInfo({
                                                            ...cardInfo,
                                                            cardCvc: numberFormat(e.target.value)
                                                        });
                                                        if (e.target.value.length > 2) {
                                                            passwordRef.current.focus();
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className='py-product-card-input cvc'>
                                        <div className='py-product-card-input-inner cvc'>
                                            <div className='py-product-card-input-comment'>
                                                사업자등록번호
                                            </div>

                                            <div className='py-product-card-input-cvc-area'>
                                                <input
                                                    type='text'
                                                    placeholder='사업자등록번호 10자리'
                                                    maxLength="10"
                                                    value={cardInfo.customerIdentityNumber}
                                                    ref={cvcRef}
                                                    onChange={e => {
                                                        setCardInfo({
                                                            ...cardInfo,
                                                            customerIdentityNumber: numberFormat(e.target.value)
                                                        });
                                                        if (e.target.value.length > 9) {
                                                            passwordRef.current.focus();
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                            }


                            <div className='py-product-card-input password'>
                                <div className='py-product-card-input-inner password'>
                                    <div className='py-product-card-input-comment'>
                                        카드 비밀번호
                                    </div>

                                    <div className='py-product-card-input-password-area'>
                                        <input
                                            type='password'
                                            // placeholder='비밀번호 앞 2자리'
                                            placeholder={
                                                digit.passwordDigit === ""
                                                    ? '비밀번호'
                                                    : '비밀번호 ' + digit.passwordDigit + '자리'
                                            }
                                            maxLength={digit.passwordDigit}
                                            value={cardInfo.cardPassword}
                                            ref={passwordRef}
                                            onChange={e => {
                                                setCardInfo({
                                                    ...cardInfo,
                                                    cardPassword: numberFormat(e.target.value)
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='py-product-card-btn-area'>
                            <button
                                className='py-product-card-btn cancle cursor'
                                onClick={() => cancleHandler()}
                            >
                                취소
                            </button>

                            <button
                                className='py-product-card-btn ok cursor'
                                disabled={disable}
                                onClick={() => {
                                    setCardInfo({
                                        ...cardInfo,
                                        cardNumber:
                                            num1Ref.current.value +
                                            num2Ref.current.value +
                                            num3Ref.current.value +
                                            num4Ref.current.value
                                    });
                                    setIsSave(true);
                                }}
                            >
                                등록
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentCard
