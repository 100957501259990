import React from "react";
import { useHistory } from "react-router";
/**
 * Version : 1.0
 * 파일명 : favoriteGroupItem.js
 * 작성일자 : 2021-12-08
 * 작성자 : 강연승
 * 설명 : 즐겨찾는 그룹 리스트 항목
 * 수정일자 : 2022-02-10
 * 수정자 : 강연승
 * 수정내역 : 텍스트 수정 영상회의 -> 화상회의, 웹폰트 변경
 */
function FavoriteGroupItem(props) {
  const history = useHistory();
  return (
    <div
      className={`groupBgColor_${props.groupInfo.GROUP_COLOR} cursor`}
      style={{
        width: "216px",
        height: "96px",
        borderRadius: "16px",
        border: "none",
        padding: "24px",
      }}
      onClick={
        () =>
          history.push({
            pathname: "/app/group/groupMain/" + props.groupInfo.GROUP_NUM,
            state: {
              detailType: "history",
              title: "",
              content: "",
              participants: props.groupInfo.MEMBER_LIST.map(data => {
                return { PRTCP_NM: data.USER_NM, PRTCP_EMAIL: data.USER_ID };
              }),
            },
          })

        // props.resBookmark(props.groupInfo)
      }
    >
      <div
        style={{
          font: "normal normal normal 24px/32px Spoqa Han Sans Neo",
          color: "#FFFFFF",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
          maxWidth: "200px",
        }}
      >
        {props.groupInfo.GROUP_NM}
      </div>

      <div
        style={{
          height: "18px",
          font: "normal normal bold 14px/18px Spoqa Han Sans Neo",
        }}
      >
        <div
          style={{
            display: "inline-block",
            float: "left",
            marginTop: "48px",
            font: "normal normal bold 12px/16px Spoqa Han Sans Neo",
            color: "#FFFFFF",
          }}
        >
          멤버 {props.groupInfo.MEMBER_CNT}
        </div>
        <div
          className={`groupMeetingColor_${props.groupInfo.GROUP_COLOR}`}
          style={{ display: "inline-block", float: "right", marginTop: "30px" }}
        >
          <div style={{ textAlign: "right" }}>최근미팅</div>
          {props.groupInfo.LAST_MEETING_DT.replace(/\s.*$/, "")}
        </div>
      </div>
    </div>
  );
}

export default FavoriteGroupItem;
