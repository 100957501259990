/**
 * Version : 1.0
 * 작성일자 : 2022-04-21
 * 작성자 : 권도훈
 * 설명 : 즉시결제 팝업
 */
const initalState = {
    IS_OPEN: false,
    POPUP_TYPE: "SUCCESS",
    SUCCESS_COUNT: 0,
    FAIL_COUNT: 0,
    FAIL_MSG: ""
};

const outstandingInfo = (state = initalState, action) => {
    switch (action.type) {
        case "outstandingInfo/OPEN_SUCCESS_POPUP":
            return {
                ...state,
                IS_OPEN: true,
                POPUP_TYPE: "SUCCESS"
            };
        case "outstandingInfo/OPEN_STOP_POPUP":
            return {
                IS_OPEN: true,
                POPUP_TYPE: "STOP",
                SUCCESS_COUNT: action.DATA.SUCCESS_COUNT,
                FAIL_COUNT: action.DATA.FAIL_COUNT,
                FAIL_MSG: action.DATA.FAIL_MSG
            };
        case "outstandingInfo/OPEN_FAIL_POPUP":
            return {
                ...state,
                IS_OPEN: true,
                POPUP_TYPE: "FAIL",
                FAIL_MSG: action.DATA.FAIL_MSG
            };
        case "outstandingInfo/CLOSE_POPUP":
            return {
                ...state,
                IS_OPEN: false
            };
        default:
            return state;
    }
};

export default outstandingInfo;