import React, { useState, useEffect, useCallback } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Alert from "component/tag/modal/alert";
import LicenseConfirmAlert from "./licenseConfirmAlert";
import axios from "axios";
import ReactModal from "react-modal";
import { useDispatch } from "react-redux";
import LicenseSubscribe from "./licenseSubscribePopup";
import LicenseUnsubscribeResultPopup from "./licenseUnsubscribeResultPopup";
// import { openAlert, openConfirm, updatePagingInfo } from "actions/index";
/**
 * Version : 1.0
 * 파일명 : licenseSettingPopup.js
 * 작성일자 : 2021-12-15
 * 작성자 : 강연승
 * 설명 : 라이선스 할당관리 팝업
 * 수정일자 :  2022-04-25
 * 수정자 : 권도훈
 * 수정내역 : 구독해지 팝업 추가
 */
function LicenseSettingPopup(props) {
  /**
   * DISPATCH
   */
  const dispatch = useDispatch();
  /**
   * STATE
   */
  const [visibleBallon, setVisibleBallon] = useState(false);
  const [visibleGuideTooltip, setVisibleGuideTooltip] = useState(false);
  const [assignYN, setAssignYN] = useState(false);
  const [total, setTotal] = useState(0);
  const [licenseScheduleList, setLicenseScheduleList] = useState([]);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [confirmRequest, setConfirmRequest] = useState({});
  const [licenseState, setLicenseState] = useState(true);
  const [page, setPage] = useState({
    ROW_COUNT: 10,
    PAGE_COUNT: 0,
  });
  const [subscribePopup, setSubscribePopup] = useState(false);
  const [unsubscribeResult, setUnsubscribeResult] = useState(false);
  const [expireDT, setExpireDT] = useState("");

  /**
   * 알림창 설정
   */
  const [alertProps, setAlertProps] = useState({
    text: "",
    isShow: false,
    cancelBtnDisplay: false,
    okBtnDisplay: true,
    okBtnEventHandler: function () {},
    cancleBtnEventHandler: function () {
      closeAlertEvent();
    },
  });
  /**
   * 알림창 닫기
   */
  const closeAlertEvent = useCallback(() => {
    setAlertProps({
      ...alertProps,
      isShow: false,
    });
  }, [alertProps]);

  const getLicenseScheduleList = useCallback(config => {
    axios.get("/license/scheduleList", config).then(res => {
      if (res.status === 200) {
        setLicenseScheduleList(res.data);
        setTotal(res.data.length);
      }
    });
  }, []);

  const licenseFormat = license => {
    if (license === undefined || license === "") {
      return "";
    } else if (license !== undefined && license !== "") {
      const licenseArr = license.split("-");
      return licenseArr[0].replace(/(.{6})/g, "$1-");
    }
  };

  const scheduleTimeFormat = time => {
    if (time !== undefined) {
      const tmpArr = time.split("T");
      const timeArr = tmpArr[1].split(":");
      return timeArr[0] + ":" + timeArr[1];
    } else {
      return;
    }
  };

  const deleteSchedule = schedule => {
    axios
      .put("/schedule/deleteSchedule", {
        SCHEDULE_NUM: schedule.SCHEDULE_NUM,
        TITLE: schedule.TITLE,
        START_TIME: schedule.START_TIME,
        END_TIME: schedule.END_TIME,
        CONTENTS: schedule.CONTENTS,
        API_TYPE: schedule.API_TYPE,
      })
      .then(response => {
        if (response.status === 200) {
          props.getLicenseTotal();
          props.getLicenseData();
          const scheduleConfig = {
            params: {
              USER_LICENSE_NUM: props.license.USER_LICENSE_NUM,
              ROW_COUNT: page.ROW_COUNT,
              PAGE_COUNT: page.PAGE_COUNT,
            },
          };
          getLicenseScheduleList(scheduleConfig);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
  /**
   * 타입 별 렌더링
   */
  useEffect(() => {
    const config = {
      params: {
        USER_LICENSE_NUM: props.license.USER_LICENSE_NUM,
        ROW_COUNT: page.ROW_COUNT,
        PAGE_COUNT: page.PAGE_COUNT,
      },
    };
    getLicenseScheduleList(config);
    setTotal(props.license.SCHEDULE_COUNT);
  }, [props.license, getLicenseScheduleList, page.PAGE_COUNT, page.ROW_COUNT]);

  /**
   * 라이선스 세팅 및 할당 팝업
   */
  useEffect(() => {
    if (props.showLicenseSettingPopup && props.license !== undefined) {
      let findLicense = props.licenseList.find(
        i =>
          i.GROUP_ASSIGNED_YN === props.license.GROUP_ASSIGNED_YN &&
          i.GROUP_NUM === props.license.GROUP_NUM &&
          i.USER_LICENSE_NUM !== props.license.USER_LICENSE_NUM
      );
      if (findLicense === undefined) {
        setAssignYN(false);
      } else {
        setAssignYN(true);
      }
    }
  }, [props.license, props.licenseList, props.showLicenseSettingPopup]);

  useEffect(() => {
    lockYNConverter(props.license.LOCK_YN);
  }, [props.license]);
  /**
   * 페이징
   */
  const changePage = type => {
    if (type === "prev") {
      if (page.PAGE_COUNT - 10 < 0 || total <= 10) {
        setAlertProps({
          ...alertProps,
          isShow: true,
          text: "첫 페이지입니다.",
          cancelBtnDisplay: false,
          okBtnEventHandler: function () {
            closeAlertEvent();
          },
        });
        return;
      } else if (page.PAGE_COUNT >= 10) {
        let diff = page.PAGE_COUNT - 10;

        if (diff < 10) {
          setPage({
            ...page,
            PAGE_COUNT: 0,
          });
        } else {
          setPage({
            ...page,
            PAGE_COUNT: page.PAGE_COUNT - 10,
          });
        }
      }
    } else if (type === "next") {
      if (page.PAGE_COUNT + 10 >= total || page.ROW_COUNT === total) {
        setAlertProps({
          ...alertProps,
          isShow: true,
          text: "마지막 페이지입니다",
          cancelBtnDisplay: false,
          okBtnEventHandler: function () {
            closeAlertEvent();
          },
        });
        return;
      } else if (page.PAGE_COUNT < total) {
        let diff = page.PAGE_COUNT + 10;

        if (diff > total) {
          setPage({
            ...page,
            PAGE_COUNT: total - (total - page.PAGE_COUNT),
          });
        } else {
          setPage({
            ...page,
            PAGE_COUNT: page.PAGE_COUNT + 10,
          });
        }
      }
    }
  };
  /**
   * 첫 페이지로 이동
   */
  const goFirstPage = () => {
    if (page.PAGE_COUNT - 10 < 0 || total <= 10) {
      setAlertProps({
        ...alertProps,
        isShow: true,
        text: "첫 페이지입니다.",
        cancelBtnDisplay: false,
        okBtnEventHandler: function () {
          closeAlertEvent();
        },
      });
      return;
    } else if (page.PAGE_COUNT >= 10) {
      setPage({
        ...page,
        PAGE_COUNT: 0,
      });
    }
  };
  /**
   * 마지막 페이지로 이동
   */
  const goLastPage = () => {
    if (page.PAGE_COUNT + 10 >= total || page.ROW_COUNT === total) {
      setAlertProps({
        ...alertProps,
        isShow: true,
        text: "마지막 페이지입니다",
        cancelBtnDisplay: false,
        okBtnEventHandler: function () {
          closeAlertEvent();
        },
      });
      return;
    } else if (page.PAGE_COUNT < total) {
      setPage({
        ...page,
        PAGE_COUNT: total - (total % 10),
      });
    }
  };

  const confirmScheduleDelete = schedule => {
    setConfirmPopup(true);
    const confirmParams = {
      className: "LicenseAlert",
      btnNo: true,
      contents: (
        <div>
          <div
            style={{
              font: "normal normal normal 20px/27px Spoqa Han Sans Neo",
              color: "#2B4AC4",
            }}
          >
            회의를 삭제하시겠습니까?
          </div>
          <div
            style={{
              font: "normal normal normal 16px/24px Spoqa Han Sans Neo",
              color: "#3E3E4F",
              marginTop: "11px",
            }}
          >
            삭제할 경우, 회의 개설자 및 모든 참여자에게
            <br />
            회의 삭제 알람/메일이 보내지고 일정은 영구삭제 됩니다.
          </div>
        </div>
      ),
      EventHandler: function () {
        deleteSchedule(schedule);
      },
    };
    setConfirmRequest(confirmParams);
  };
  const confirmReleaseLicense = () => {
    setConfirmPopup(true);
    const confirmParams = {
      className: "LicenseAlert",
      btnNo: true,
      contents: (
        <div>
          <div
            style={{
              font: "normal normal normal 20px/27px Spoqa Han Sans Neo",
              color: "#2B4AC4",
            }}
          >
            회의 이용권을 해지하시겠습니까?
          </div>
          <div
            style={{
              font: "normal normal normal 16px/24px Spoqa Han Sans Neo",
              color: "#3E3E4F",
              marginTop: "11px",
            }}
          >
            해당 그룹에 할당되어 있는 회의 이용권을 해지할 경우,
            <br />
            마이 SoBridge에서 사용 및 타그룹으로 재할당이 가능합니다.
          </div>
        </div>
      ),
      EventHandler: function () {
        releaseGroupLicenseYes();
      },
    };
    setConfirmRequest(confirmParams);
  };
  const closeConfirmPopup = () => {
    setConfirmPopup(false);
  };
  const releaseGroupLicenseYes = () => {
    releaseGroupLicense(props.license);
    // closeConfirmPopup();
  };

  const lockYNConverter = lockYN => {
    if (lockYN === "Y") {
      return setLicenseState(true);
    } else if (lockYN === "N") {
      return setLicenseState(false);
    }
  };
  const onChangeLicenseState = e => {
    setLicenseState(e.target.checked);
    let lockYN = "";
    if (e.target.checked) {
      lockYN = "Y";
    } else {
      lockYN = "N";
    }
    if (lockYN === "N" && visibleBallon) {
      setVisibleBallon(false);
    }
    axios
      .put("/license/lockLicense", {
        LOCK_YN: lockYN,
        USER_LICENSE_NUM: props.license.USER_LICENSE_NUM,
      })
      .then(res => {
        if (res.status === 200) {
          props.getLicenseTotal();
          props.getLicenseData();
          if (lockYN === "Y") {
            setVisibleBallon(true);
            setTimeout(() => {
              setVisibleBallon(false);
            }, 3000);
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
  const confirmAssignSchedule = license => {
    setConfirmPopup(true);
    const confirmParams = {
      className: "assignSchedule",
      btnNo: true,
      contents: (
        <div>
          <div
            style={{
              font: "normal normal normal 20px/27px Spoqa Han Sans Neo",
              color: "#2B4AC4",
            }}
          >
            회의 재배정
          </div>
          <div
            style={{
              font: "normal normal normal 16px/24px Spoqa Han Sans Neo",
              color: "#3E3E4F",
              marginTop: "11px",
            }}
          >
            해당 회의 이용권으로 예약한 회의들을,
            <br />
            다른 회의 이용권으로 재배정 하시겠습니까?
          </div>
        </div>
      ),
      EventHandler: function () {
        assignSchedule(license);
      },
    };
    setConfirmRequest(confirmParams);
  };
  const assignSchedule = license => {
    let params = {
      LICENSE_NUM: license.USER_LICENSE_NUM,
    };
    if (license.GROUP_NUM !== "") {
      params = {
        ...params,
        GROUP_NUM: license.GROUP_NUM,
      };
    } else if (license.GROUP_NUM === "") {
      params = {
        ...params,
        GROUP_NUM: license.USER_NUM,
      };
    }
    axios
      .put("/schedule/asingeLicenseSchedule", params)
      .then(res => {
        if (res.status === 200) {
          setVisibleGuideTooltip(true);
          setTimeout(() => {
            setVisibleGuideTooltip(false);
          }, 3000);
          props.getLicenseTotal();
          props.getLicenseData();
          const scheduleConfig = {
            params: {
              USER_LICENSE_NUM: license.USER_LICENSE_NUM,
              ROW_COUNT: page.ROW_COUNT,
              PAGE_COUNT: page.PAGE_COUNT,
            },
          };
          getLicenseScheduleList(scheduleConfig);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const releaseGroupLicense = license => {
    const request = {
      USER_LICENSE_NUM: license.USER_LICENSE_NUM,
      GROUP_NUM: null,
      GROUP_ASSIGNED_YN: "N",
    };
    axios
      .put("/license/setGroupLicense", request)
      .then(res => {
        if (res.status === 200) {
          props.setShowLicenseSettingPopup(false);
          setConfirmPopup(true);
          const confirmParams = {
            className: "setLicenseAlert",
            btnNo: false,
            contents: (
              <div>
                <div
                  style={{
                    font: "normal normal normal 20px/27px Spoqa Han Sans Neo",
                    color: "#2B4AC4",
                    textAlign: "left",
                  }}
                >
                  회의이용권 할당 해지 완료
                </div>
                <div
                  style={{
                    font: "normal normal normal 14px/21px Spoqa Han Sans Neo",
                    color: "#3E3E4F",
                    textAlign: "left",
                    marginTop: "12px",
                  }}
                >
                  회의이용권이 할당 해지되었습니다.
                </div>
              </div>
            ),
            EventHandler: function () {
              props.getLicenseTotal();
              props.getLicenseData();
            },
          };
          setConfirmRequest(confirmParams);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const meetingCheck = (start, end) => {
    const now = new Date();
    if (new Date(start) <= now && now < new Date(end)) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <LicenseSubscribe
        isShow={subscribePopup}
        setIsShow={setSubscribePopup}
        license={props.license}
        setUnsubscribeResult={setUnsubscribeResult}
        setExpireDT={setExpireDT}
      />
      <LicenseUnsubscribeResultPopup
        isShow={unsubscribeResult}
        setIsShow={setUnsubscribeResult}
        expireDT={expireDT}
        getLicenseTotal={props.getLicenseTotal}
        getLicenseData={props.getLicenseData}
        setShowLicenseSettingPopup={props.setShowLicenseSettingPopup}
      />
      <LicenseConfirmAlert
        open={confirmPopup}
        close={closeConfirmPopup}
        request={confirmRequest}
      />
      <Alert alertProps={alertProps} />

      <ReactModal
        isOpen={props.showLicenseSettingPopup} //모달 Open(Boolean)
        className="licenseSettingPopup" //모달 ClassName
        overlayClassName="NewAlertOverlay" //모달오버레이 ClassName
        animationType="fade"
        transparent={true}
        closeTimeoutMS={200}
      >
        <div className="licenseSettingPopup_header">
          <div className="licenseSettingPopupTitle">회의 이용권 할당 관리</div>
          <img
            className="cursor"
            src="/img/icon/landing/close.png"
            alt="close"
            onClick={() => props.setShowLicenseSettingPopup(false)}
          />
        </div>

        <div className={licenseState ? "licenseInfo" : "licenseInfoActive"}>
          <div className="licenseInfo-inner">
            <div className="licenseInfo-text-area">
              <div>
                <font
                  className={
                    licenseState
                      ? "lc-setting-popup-header-font type"
                      : "lc-setting-popup-header-font type active"
                  }
                >
                  이용권 코드
                </font>

                <font
                  className={
                    licenseState
                      ? "lc-setting-popup-header-font value"
                      : "lc-setting-popup-header-font value active"
                  }
                >
                  {licenseFormat(props.license.LICENSE_CODE)}
                </font>
              </div>

              <div>
                <font
                  className={
                    licenseState
                      ? "lc-setting-popup-header-font type"
                      : "lc-setting-popup-header-font type active"
                  }
                >
                  할당 그룹
                </font>

                <font
                  className={
                    licenseState
                      ? "lc-setting-popup-header-font value"
                      : "lc-setting-popup-header-font value active"
                  }
                >
                  {props.license.GROUP_NUM === ""
                    ? "그룹에 할당되어 있지 않음"
                    : props.license.GROUP_NM}
                </font>
              </div>
            </div>

            <FormControlLabel
              className="licenseStateBtn"
              control={
                <Switch
                  color="primary"
                  checked={licenseState}
                  name="licenseState"
                  onChange={onChangeLicenseState}
                />
              }
              label={
                licenseState ? "회의 이용권 비활성화" : "회의 이용권 활성화"
              }
              labelPlacement="start"
            />
          </div>
        </div>

        <div
          className={
            licenseState && total === 0
              ? "licenseSettingContent disable"
              : "licenseSettingContent"
          }
        >
          <div className="lc-content-inner">
            <div className="lc-content-relative">
              <div className="lc-content-header">
                <div>
                  <div className="scheduleListTitle">| 예약된 회의 리스트</div>

                  <font>해당 이용권으로 예약된 회의 리스트입니다.</font>
                </div>

                <button
                  className={
                    licenseState && assignYN && total > 0
                      ? "scheduleReassignBtnActive"
                      : "scheduleReassignBtn"
                  }
                  disabled={!licenseState || !assignYN || total === 0}
                  onClick={() => confirmAssignSchedule(props.license)}
                >
                  회의 재배정
                </button>
              </div>

              <div
                className="balloon"
                style={{
                  display: visibleBallon ? "block" : "none",
                }}
              >
                <div
                  style={{
                    font: "normal normal bold 12px/18px Spoqa Han Sans Neo",
                    color: "#FFFFFF",
                  }}
                >
                  해당 회의 이용권의 재배치를 위해서는 예약되어 있는
                  <br />
                  회의들이 타 회의 이용권에 배정되어야 합니다.
                </div>
              </div>

              <table className="lc-content-table">
                <colgroup>
                  <col width="8px" />
                  <col width="96px" />
                  <col width="4px" />
                  <col width="72px" />
                  <col width="4px" />
                  <col width="72px" />
                  <col width="4px" />
                  <col width="72px" />
                  <col width="4px" />
                  <col width="236px" />
                  <col width="4px" />
                  <col width="64px" />
                  <col width="8px" />
                </colgroup>

                <thead>
                  <tr className="lc-content-table-tr thead">
                    <th />
                    <th>예약일</th>
                    <th />
                    <th>시작 시간</th>
                    <th />
                    <th>종료 시간</th>
                    <th />
                    <th>개설자</th>
                    <th />
                    <th className="lc-content-table-left">회의 제목</th>
                    <th />
                    <th>관 리</th>
                    <th />
                  </tr>
                </thead>

                <tbody
                // className="licenseScheduleList_tbody"
                >
                  {Array.isArray(licenseScheduleList) &&
                  licenseScheduleList.length > 0 ? (
                    licenseScheduleList.map(data => (
                      <tr
                        className="lc-content-table-tr tbody"
                        key={data.SCHEDULE_NUM}
                      >
                        <td />
                        <td className="lc-content-table-center">
                          {data.START_TIME.replace(/\T.*/, "")}
                        </td>
                        <td />
                        <td className="lc-content-table-center">
                          {scheduleTimeFormat(data.START_TIME)}
                        </td>
                        <td />
                        <td className="lc-content-table-center">
                          {scheduleTimeFormat(data.END_TIME)}
                        </td>
                        <td />
                        <td className="lc-content-table-center">
                          {data.USER_NM}
                        </td>
                        <td />
                        <td
                          style={{
                            textAlign: "left",
                            maxWidth: "200px",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                          }}
                        >
                          {data.TITLE}
                        </td>
                        <td />
                        {meetingCheck(data.START_TIME, data.END_TIME) ? (
                          <td
                            className="lc-content-table-center cursor"
                            style={{ color: "#de8799", fontWeight: "bold" }}
                            onClick={() => confirmScheduleDelete(data)}
                          >
                            삭 제
                          </td>
                        ) : (
                          <td
                            className="lc-content-table-center"
                            style={{ color: "#2B4AC4", fontWeight: "bold" }}
                          >
                            진행중
                          </td>
                        )}
                        <td />
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="13"
                        className="noSchedule"
                        style={{
                          textAlign: "center",
                          fontFamily: "Spoqa Han Sans Neo",
                          fontSize: "14px",
                        }}
                      >
                        예약된 회의 내역이 없습니다.
                        <br />
                        특정 그룹에 할당이 가능합니다.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div
                className="guideTooltip"
                style={{
                  display: visibleGuideTooltip ? "block" : "none",
                }}
              >
                <div
                  style={{
                    font: "normal normal bold 12px/18px Spoqa Han Sans Neo",
                    color: "#FFFFFF",
                  }}
                >
                  재배정이 완료되었습니다.
                  <br />
                  남아있는 내역은 배정 가능한 시간대가 없어 배정에 실패한
                  회의입니다.
                  <br />
                  회의 내역이 모두 비워져야 회의 이용권 재할당이 가능합니다.
                </div>
              </div>

              {total > 0 ? (
                <div className="profile_licenseSettingPopup_paging">
                  <div>
                    {total > 0
                      ? total +
                        "개 중 " +
                        (page.PAGE_COUNT + 1) +
                        "-" +
                        (page.PAGE_COUNT + 10 >= total
                          ? total
                          : page.PAGE_COUNT + 10)
                      : "0개"}
                  </div>
                  <img
                    className="cursor"
                    src="/img/icon/address/first_page_btn.png"
                    alt="<<"
                    onClick={() => goFirstPage()}
                  />
                  <img
                    className="cursor"
                    src="/img/icon/address/prev_page_btn.png"
                    alt="<"
                    onClick={() => changePage("prev")}
                  />
                  <img
                    className="cursor"
                    src="/img/icon/address/next_page_btn.png"
                    alt=">"
                    onClick={() => changePage("next")}
                  />
                  <img
                    className="cursor"
                    src="/img/icon/address/last_page_btn.png"
                    alt=">>"
                    onClick={() => goLastPage()}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {/* </div> */}

        {licenseState && total === 0 ? (
          <div className="profile_licenseSettingPopup_footer">
            {props.license.GROUP_NUM === "" ? (
              <button
                className="profile_licenseSettingPopup_setGroupBtn"
                onClick={() => props.allocateGroup()}
              >
                특정 그룹에 할당하기
              </button>
            ) : (
              <>
                <button
                  className="profile_licenseSettingPopup_otherGroupBtn"
                  onClick={() => confirmReleaseLicense()}
                >
                  현재 그룹 할당 해지
                </button>
                <button
                  className="profile_licenseSettingPopup_otherGroupBtn"
                  style={{ marginLeft: "16px" }}
                  onClick={() => props.allocateGroup()}
                >
                  타 그룹 재할당
                </button>
              </>
            )}
            {props.license.SUBSCRIBE_YN === "Y" ? (
              <button
                className="profile_licenseSettingPopup_unsubscribeBtn"
                style={{ marginLeft: "16px" }}
                onClick={() => setSubscribePopup(true)}
              >
                구독 해지
              </button>
            ) : (
              <></>
            )}
          </div>
        ) : null}
      </ReactModal>
    </>
  );
}

export default LicenseSettingPopup;
