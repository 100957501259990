import React from 'react'
/**
 * Version : 1.0
 * 파일명 : UpdateScheduleAlram.js
 * 작성일자 : 2021-12-16
 * 작성자 : 권도훈
 * 설명 : 회의 수정 알람
 * 수정일자 : 2022-01-25
 * 수정자 : 권도훈
 * 수정내역 : 날짜 추가
*/
function UpdateScheduleAlram(props) {
    /**
     * 내용 포맷
     */
    const contentConvert = (data) => {
        return (
            <div>
                <div>[ {data} ] 회의가 수정되었습니다.</div>
            </div>
        )
    }
    return (
        <div className="alarm_Item cursor">
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: "24px",
                    color: props.data.READ_YN === "Y" ? "grey" : "green"
                }}
            >
                <div className="font-bold">
                    회의 수정
                </div>

                <div
                    style={{
                        fontSize: "14px"
                    }}
                >
                    {props.date}
                </div>
            </div>

            <div
                style={{
                    marginTop: "24px",
                    marginBottom: "24px",
                    color: props.data.READ_YN === "Y" ? "grey" : "black",
                }}
                className="m-0"
            >
                {contentConvert(props.data.ALRAM_CONTENT)}
            </div>
        </div>
    )
}

export default UpdateScheduleAlram
