import React from "react";
import ReactModal from "react-modal";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
/**
 * Version : 1.0
 * 파일명 : resSuccess.js
 * 작성일자 : 2021-11-04
 * 작성자 : 강연승
 * 설명 : 회의 예약 완료 모달
 * 수정일자 : 2022-05-16
 * 수정자 : 강연승
 * 수정내역 : 타이틀 툴팁 추가
 * props :
 * 전달받은값 - 설명
 */
function FileInfo(props) {
  /**
   * url 설정
   */
  let server_url = "";

  if (process.env.NODE_ENV === "development") {
    server_url =
      process.env.REACT_APP_SERVER_URL + process.env.REACT_APP_SERVER_PORT;
  } else {
    server_url = process.env.REACT_APP_SERVER_URL;
  }
  const scheduleFileList = props.file.split("|");
  let rawfileInfo = [];
  let fileInfoList = [];

  for (let i = 0; i < scheduleFileList.length; i++) {
    rawfileInfo = scheduleFileList[i].split(";");
    const fileInfo = {
      fileNum: rawfileInfo[0],
      fileNm: rawfileInfo[1],
      fileURL: rawfileInfo[2],
      fileDownNm: rawfileInfo[3],
      fileSize: rawfileInfo[4],
    };
    fileInfoList.push(fileInfo);
  }
  return (
    <>
      {fileInfoList.map(data => (
        <div
          key={data.fileNum}
          className="scheduleFileInfo"
          onClick={() =>
            window.open(
              server_url +
                "/file/downloadFile?filename=" +
                data.fileDownNm +
                "&downfilename=" +
                data.fileNm,
              "_blank"
            )
          }
        >
          {data.fileNm}
        </div>
      ))}
    </>
  );
}

function ResSuccess(props) {
  /**
   * body
   */
  ReactModal.setAppElement("body");
  /**
   * 언어변환
   */
  const { t } = useTranslation();
  const platformImg = type => {
    switch (type) {
      case "CD0501":
        return (
          <img
            src="/img/icon/schedule/successReservation/webex.png"
            alt="webex"
          />
        );
      case "CD0502":
        return (
          <img
            src="/img/icon/schedule/successReservation/zoom.png"
            alt="zoom"
          />
        );
      case "CD0503":
        return <img src="/img/platform/chime2.png" alt="chime" />;
      case "CD0504":
        return <img src="/img/platform/pexip2.png" alt="pexip" />;
    }
  };

  const dateFormat = dateTime => {
    let DT = new Date(dateTime);
    let year = DT.getFullYear();
    let month =
      DT.getMonth() + 1 < 10 ? "0" + (DT.getMonth() + 1) : DT.getMonth() + 1;
    let date = DT.getDate() < 10 ? "0" + DT.getDate() : DT.getDate();
    let day = DT.getDay();
    let dayStr;
    switch (day) {
      case 0:
        dayStr = t("date.sunday");
        break;
      case 1:
        dayStr = t("date.monday");
        break;
      case 2:
        dayStr = t("date.tuesday");
        break;
      case 3:
        dayStr = t("date.wednesday");
        break;
      case 4:
        dayStr = t("date.thursday");
        break;
      case 5:
        dayStr = t("date.friday");
        break;
      case 6:
        dayStr = t("date.saturday");
        break;
    }
    let hour = DT.getHours();
    let minute = DT.getMinutes() < 10 ? "0" + DT.getMinutes() : DT.getMinutes();
    if (hour > 12) {
      hour = t("date.pm_kr") + (hour - 12);
    } else {
      if (hour === 12) {
        hour = t("date.pm_kr") + hour;
      } else {
        hour = t("date.am_kr") + hour;
      }
    }
    if (minute === "00") {
      let result =
        t("date.fullFormat", {
          YEAR: year,
          MONTH: month,
          DATE: date,
          DAY: dayStr,
        }) +
        hour +
        "시";
      return result;
    } else {
      let result =
        t("date.fullFormat", {
          YEAR: year,
          MONTH: month,
          DATE: date,
          DAY: dayStr,
        }) +
        hour +
        "시 " +
        minute +
        "분";
      return result;
    }
  };

  return (
    <ReactModal
      isOpen={props.open} //모달 Open(Boolean)
      className="resSuccessPopup" //모달 ClassName
      overlayClassName="resSuccessPopup_Overlay" //모달오버레이 ClassName
      onRequestClose={props.close}
      shouldCloseOnEsc={true}
      animationType="fade"
      transparent={true}
      closeTimeoutMS={200}
    >
      <div>
        <img
          className="closeBtn"
          src="/img/icon/schedule/successReservation/close.png"
          alt="close"
          onClick={props.close}
        />
      </div>
      <div className="popup_header">
        <div className="popup_title">
          {props.type === "create"
            ? "회의 예약이 완료되었습니다!"
            : "회의 예약이 수정되었습니다!"}
        </div>
      </div>

      <div className="platformImg">{platformImg(props.schedule.API_TYPE)}</div>

      <div className="popup_contents_background">
        <div className="div_scheduleURL">
          <div className="scheduleURL">{props.schedule.INVITE_URL}</div>
          <CopyToClipboard
            text={props.schedule.INVITE_URL}
            onCopy={() => alert("클립보드에 복사되었습니다.")}
          >
            <img
              className="copyIcon"
              src="/img/icon/schedule/successReservation/copy_link.png"
              alt="meetingLink"
            />
          </CopyToClipboard>
        </div>
        <div className="scheduleURLText">
          링크를 복사하여 회의실을 공유해보세요.
        </div>
        <div className="popup_contents">
          <div className="scheduleTitle" title={props.schedule.TITLE}>
            {props.schedule.TITLE}
          </div>
        </div>

        {props.schedule.REC_YN === "Y" ? (
          <div className="popup_contents">
            <div className="scheduleRecording">
              회의 시작과 동시에 녹화를 진행합니다.
            </div>
          </div>
        ) : null}

        <div className="popup_contents">
          <div className="infoLabel">회의 시작</div>
          <div className="scheduleInfo">
            {dateFormat(props.schedule.START_TIME)}
          </div>
        </div>

        <div className="popup_contents">
          <div className="infoLabel">회의 종료</div>
          <div className="scheduleInfo">
            {dateFormat(props.schedule.END_TIME)}
          </div>
        </div>

        {props.schedule.CONTENTS !== "" &&
        props.schedule.CONTENTS !== undefined ? (
          <div className="popup_contents">
            <div className="infoLabel align-top">내용</div>
            <div className="scheduleInfo scheduleContents scrollbar">
              {props.schedule.CONTENTS.replaceAll("<br />", "\n")}
            </div>
          </div>
        ) : null}

        {Array.isArray(props.participants) && props.participants.length > 0 ? (
          <div className="popup_contents">
            <div className="align-top infoLabel">참석자</div>
            <div className="scheduleInfo participantsList scrollbar">
              {props.participants.map(data => (
                <div key={data.PRTCP_EMAIL}>
                  {data.PRTCP_NM === "" ? (
                    <div className="inline-block">{data.PRTCP_EMAIL}</div>
                  ) : (
                    <>
                      <div className="inline-block">{data.PRTCP_NM}</div>
                      <div
                        className="inline-block"
                        style={{ marginLeft: "10px" }}
                      >
                        {data.PRTCP_EMAIL}
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        ) : null}

        {props.schedule.FILE_INFO !== null &&
        props.schedule.FILE_INFO !== undefined &&
        props.schedule.FILE_INFO !== "" ? (
          <div className="popup_contents">
            <div className="align-top infoLabel">첨부파일</div>
            <div className="scheduleInfo scheduleFileList scrollbar">
              <FileInfo file={props.schedule.FILE_INFO} />
            </div>
          </div>
        ) : null}

        {props.schedule.API_TYPE === "CD0501" &&
        props.schedule.MEETING_PW !== "" &&
        props.schedule.MEETING_PW !== undefined ? (
          <div className="popup_contents">
            <div className="infoLabel">비밀번호</div>
            <div className="scheduleInfo">{props.schedule.MEETING_PW}</div>
          </div>
        ) : null}

        {props.schedule.API_TYPE === "CD0501" &&
        props.schedule.SIP_URL !== "" &&
        props.schedule.SIP_URL !== undefined ? (
          <div className="popup_contents">
            <div className="infoLabel">SIP URL</div>
            <div className="scheduleInfo">{props.schedule.SIP_URL}</div>
          </div>
        ) : null}

        {props.schedule.API_TYPE === "CD0501" &&
        props.schedule.MEETING_LINK !== "" &&
        props.schedule.MEETING_LINK !== undefined ? (
          <div className="popup_contents">
            <div className="infoLabel">미팅 링크</div>
            <CopyToClipboard
              text={props.schedule.MEETING_LINK}
              onCopy={() => alert("클립보드에 복사되었습니다.")}
            >
              <div className="scheduleInfo scheduleURL">
                {props.schedule.MEETING_LINK}
              </div>
            </CopyToClipboard>
          </div>
        ) : null}

        <div className="popup_footer">
          <button className="confirmBtn" onClick={props.close}>
            {t("common.btn.ok")}
          </button>
        </div>
      </div>
    </ReactModal>
  );
}

export default ResSuccess;
