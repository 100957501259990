import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import axios from "axios";
/**
 * Version : 1.0
 * 파일명 : WorkTimePopup.js
 * 작성일자 : 2022-03-02
 * 작성자 : 강연승
 * 설명 : 근무시간 설정 팝업
 * 수정일자 : 2022-04-27
 * 수정자 : 권도훈
 * 수정내역 : 종료 시간 수정
 */
function WorkTimePopup(props) {
  /**
   * REDUCER
   */
  const groupInfoState = useSelector(state => state.groupInfoReducer);
  /**
   * 팝업 설정
   */
  const closePopup = () => {
    props.close();
  };
  /**
   * STATE
   */
  const [showSelectBox, setShowSelectBox] = useState(false);
  const [selectboxType, setSelectBoxType] = useState("");
  const [workTime, setWorkTime] = useState({
    startHour: "",
    startMin: "",
    endHour: "",
    endMin: "",
  });
  const hourArr = [
    { num: 0, value: "00" },
    { num: 1, value: "01" },
    { num: 2, value: "02" },
    { num: 3, value: "03" },
    { num: 4, value: "04" },
    { num: 5, value: "05" },
    { num: 6, value: "06" },
    { num: 7, value: "07" },
    { num: 8, value: "08" },
    { num: 9, value: "09" },
    { num: 10, value: "10" },
    { num: 11, value: "11" },
    { num: 12, value: "12" },
    { num: 13, value: "13" },
    { num: 14, value: "14" },
    { num: 15, value: "15" },
    { num: 16, value: "16" },
    { num: 17, value: "17" },
    { num: 18, value: "18" },
    { num: 19, value: "19" },
    { num: 20, value: "20" },
    { num: 21, value: "21" },
    { num: 22, value: "22" },
    { num: 23, value: "23" }
  ];
  const minuteArr = [
    { num: 0, value: "00" },
    { num: 1, value: "10" },
    { num: 2, value: "20" },
    { num: 3, value: "30" },
    { num: 4, value: "40" },
    { num: 5, value: "50" },
  ];

  const clickBackgound = () => {
    if (showSelectBox) {
      setShowSelectBox(false);
      setSelectBoxType("");
    }
  };

  const getWorkTime = () => {
    let param = {
      GROUP_NUM: groupInfoState.GROUP_NUM,
    };
    axios
      .get("/group/workTime", {
        params: param,
      })
      .then(res => {
        if (res.status === 200) {
          let workStart = res.data.WORK_START;
          let workEnd = res.data.WORK_END;
          let startHour = workStart.split(":")[0];
          let startMin = workStart.split(":")[1];
          let endHour = workEnd.split(":")[0];
          let endMin = workEnd.split(":")[1];
          setWorkTime({
            startHour: startHour,
            startMin: startMin,
            endHour: endHour,
            endMin: endMin,
          });
        }
      });
  };

  useEffect(() => {
    getWorkTime();
  }, []);
  return (
    <ReactModal
      isOpen={props.open} //모달 Open(Boolean)
      className="workTimePopup" //모달 ClassName
      overlayClassName="workTimePopup_Overlay" //모달오버레이 ClassName
      onRequestClose={closePopup}
      shouldCloseOnEsc={true}
      animationType="fade"
      transparent={true}
      closeTimeoutMS={200}
    >
      <div onClick={clickBackgound}>
        <div className="text-center">
          <div className="popupTitle">근무시간 설정</div>
          <div className="popupText">
            <div>
              근무시간을 설정하시면 해당시간과 관련된 데이터를 보실 수 있습니다.
            </div>
          </div>
          <div className="timeArea">
            <div className="selectArea">
              <div
                className="selectBox"
                onClick={() => {
                  setShowSelectBox(!showSelectBox);
                  setSelectBoxType("startHour");
                }}
              >
                <div>{workTime.startHour}</div>
                <img
                  className="selectIcon"
                  src={"/img/icon/dashboard/down.png"}
                  alt="DROP"
                />
              </div>
              {showSelectBox && selectboxType === "startHour" ? (
                <div
                  className="selectPopup scrollbar"
                  style={{
                    display:
                      showSelectBox && selectboxType === "startHour"
                        ? "block"
                        : "none",
                  }}
                >
                  {hourArr.map(data => (
                    <div
                      className="selectItem"
                      key={"startHour_" + data.value}
                      onClick={() => {
                        setWorkTime({ ...workTime, startHour: data.value });
                        setShowSelectBox(!showSelectBox);
                      }}
                    >
                      <div>{data.value}</div>
                    </div>
                  ))}
                </div>
              ) : null}
              <font className="timeText">시</font>
            </div>
            <font className="timeColon">:</font>
            <div className="selectArea">
              <div
                className="selectBox"
                onClick={() => {
                  setShowSelectBox(!showSelectBox);
                  setSelectBoxType("startMin");
                }}
              >
                <div>{workTime.startMin}</div>
                <img
                  className="selectIcon"
                  src={"/img/icon/dashboard/down.png"}
                  alt="DROP"
                />
              </div>
              {showSelectBox && selectboxType === "startMin" ? (
                <div
                  className="selectPopup scrollbar"
                  style={{
                    display:
                      showSelectBox && selectboxType === "startMin"
                        ? "block"
                        : "none",
                  }}
                >
                  {minuteArr.map(data => (
                    <div
                      className="selectItem"
                      key={"startMin_" + data.value}
                      onClick={() => {
                        setWorkTime({ ...workTime, startMin: data.value });
                        setShowSelectBox(!showSelectBox);
                      }}
                    >
                      <div>{data.value}</div>
                    </div>
                  ))}
                </div>
              ) : null}
              <font className="timeText">분</font>
            </div>
            <font className="timeWave">~</font>
            <div className="selectArea">
              <div
                className="selectBox"
                onClick={() => {
                  setShowSelectBox(!showSelectBox);
                  setSelectBoxType("endHour");
                }}
              >
                <div>{workTime.endHour}</div>
                <img
                  className="selectIcon"
                  src={"/img/icon/dashboard/down.png"}
                  alt="DROP"
                />
              </div>
              {showSelectBox && selectboxType === "endHour" ? (
                <div
                  className="selectPopup scrollbar"
                  style={{
                    display:
                      showSelectBox && selectboxType === "endHour"
                        ? "block"
                        : "none",
                  }}
                >
                  {hourArr.map(data => (
                    <div
                      className="selectItem"
                      key={"endHour_" + data.value}
                      onClick={() => {
                        setWorkTime({ ...workTime, endHour: data.value });
                        setShowSelectBox(!showSelectBox);
                      }}
                    >
                      <div>{data.value}</div>
                    </div>
                  ))}
                </div>
              ) : null}
              <font className="timeText">시</font>
            </div>
            <font className="timeColon">:</font>
            <div className="selectArea">
              <div
                className="selectBox"
                onClick={() => {
                  setShowSelectBox(!showSelectBox);
                  setSelectBoxType("endMin");
                }}
              >
                <div>{workTime.endMin}</div>
                <img
                  className="selectIcon"
                  src={"/img/icon/dashboard/down.png"}
                  alt="DROP"
                />
              </div>
              {showSelectBox && selectboxType === "endMin" ? (
                <div
                  className="selectPopup scrollbar"
                  style={{
                    display:
                      showSelectBox && selectboxType === "endMin"
                        ? "block"
                        : "none",
                  }}
                >
                  {minuteArr.map(data => (
                    <div
                      className="selectItem"
                      key={"endMin_" + data.value}
                      onClick={() => {
                        setWorkTime({ ...workTime, endMin: data.value });
                        setShowSelectBox(!showSelectBox);
                      }}
                    >
                      <div>{data.value}</div>
                    </div>
                  ))}
                </div>
              ) : null}
              <font className="timeText">분</font>
            </div>
          </div>
        </div>

        <div className="text-center">
          <button className="btnConfirm" onClick={() => props.save(workTime)}>
            확 인
          </button>
        </div>
      </div>
    </ReactModal>
  );
}

export default WorkTimePopup;
