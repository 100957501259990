import React from "react";
import { useHistory } from "react-router";
/**
 * Version : 1.0
 * 파일명 :
 * 작성일자 : 2021-10-05
 * 작성자 : 권도훈
 * 설명 : 가입완료 페이지
 * 수정일자 : 2022-02-14
 * 수정자 : 권도훈
 * 수정내역 : UI 수정
 */
function SuccessPage(props) {
  /**
   * history
   */
  const history = useHistory();
  /**
   * 로그인 페이지 이동
   */
  const goLogin = () => {
    history.push({
      pathname: "/login",
      state: {
        tag: "none",
        type: "default",
      },
    });
  };

  return (
    <div
      style={{
        height: "100%",
        position: "absolute",
        width: "100%",
        backgroundImage: "url(/img/login_background.png)",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <section
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src="/img/logo.png" alt="logo_title" />

        <div
          style={{
            color: "#2B4AC4",
            fontSize: "32px",
            fontWeight: "bold",
            marginTop: "24px",
            marginBottom: "32px",
          }}
        >
          회원가입이 완료되었습니다!
        </div>

        <div
          style={{
            color: "#2B4AC4",
            fontSize: "20px",
            fontWeight: "bold",
            textAlign: "center",
            marginBottom: "64px",
          }}
        >
          <div>
            <font style={{ color: "#8B5FBF" }}>{props.name} 님</font>{" "}
            환영합니다.
          </div>

          <div>화상회의 솔루션의 신세계를 경험하세요.</div>
        </div>

        <img
          src="/img/icon/makeAccount/join_newillust.png"
          alt="Welcome"
          style={{
            marginBottom: "80px"
          }}
        />

        <button
          className="cursor"
          style={{
            width: "194px",
            height: "56px",
            background: "#2B4AC4",
            boxShadow: "0px 5px 20px #00000029",
            borderRadius: "40px 20px",
            outline: "none",
            border: "none",
            fontSize: "14px",
            fontWeight: "bold",
            color: "#FFFFFF",
          }}
          onClick={() => goLogin()}
        >
          첫 로그인하러 가기
        </button>
      </section>
    </div>
  );
}

export default SuccessPage;
