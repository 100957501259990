import React, { useState } from "react";
import InsertInfo from "./insertInfo";
import SuccessPage from "./successPage";
/**
 * Version : 1.0
 * 파일명 : 
 * 작성일자 : 2021-10-05
 * 작성자 : 권도훈
 * 설명 : 
 * 수정일자 :  2022-01-26
 * 수정자 : 권도훈
 * 수정내역 : UI 수정
*/
function MakeAccount() {
  const [state, setState] = useState(false);
  const [name, setNM] = useState("");

  return (
    <div>
      {
        state
          ?
          <SuccessPage
            name={name}
          />
          :
          <InsertInfo
            setState={setState}
            setNM={setNM} />
      }
    </div>
  );
}

export default MakeAccount;
