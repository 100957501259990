import React, { useState } from 'react'
/**
 * Version : 1.0
 * 파일명 : deleteSurvey.js
 * 작성일자 : 2021-11-29
 * 작성자 : 권도훈
 * 설명 : 탈퇴 설문조사 팝업
 * 수정일자 : 2022-01-20
 * 수정자 : 권도훈
 * 수정내역 : UI 수정 및 이메일 입력 제거
*/
function DeleteSurvey(props) {
    /**
     * 설문 조사 값
     */
    const [surveyValue, setSurveyValue] = useState("");
    const [otherReason, setOtherReason] = useState("");
    /**
     * 설문조사 제출
     */
    const sendSurvey = () => {
        if (surveyValue === "기타") {
            props.setSurveyResult(otherReason);
        } else {
            props.setSurveyResult(surveyValue);
        }

        props.setPage("AGREE");
    };
    /**
     * 
     */
    const onChangeRadioBtn = (value) => {
        if (surveyValue === "기타") {
            setOtherReason("");
        }
        setSurveyValue(value);
    }
    /**
     * 태그
     */
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
            }}
        >
            <div
                style={{
                    color: "#2B4AC4",
                    fontSize: "18px",
                    fontWeight: "bold",
                    marginBottom: "32px"
                }}
            >
                <div>
                    앞으로 더 나은 서비스를 제공하기 위해
                </div>
                <div>
                    탈퇴 사유를 선택해주시기 바랍니다.
                </div>
            </div>

            <div
                style={{
                    color: "#3E3E4F",
                    fontSize: "14px",
                    width: "100%",
                    borderBottom: "1px solid #DFE1E5",
                    marginBottom: "24px"
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px"
                    }}
                >
                    <img
                        className='cursor'
                        src={
                            surveyValue === "사용 빈도가 낮아 이용할 필요가 없어서"
                                ? "/img/icon/landing/record_on.png"
                                : "/img/icon/landing/record_off.png"
                        }
                        alt='V'
                        onClick={() => onChangeRadioBtn("사용 빈도가 낮아 이용할 필요가 없어서")}
                    />
                    <font
                        className="drag-none"
                    >
                        사용 빈도가 낮아 이용할 필요가 없어서
                    </font>
                </div>

                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px"
                    }}
                >
                    <img
                        className='cursor'
                        src={
                            surveyValue === "사용이 불편하거나 어려워서"
                                ? "/img/icon/landing/record_on.png"
                                : "/img/icon/landing/record_off.png"
                        }
                        alt='V'
                        onClick={() => onChangeRadioBtn("사용이 불편하거나 어려워서")}
                    />
                    <font
                        className="drag-none"
                    >
                        사용이 불편하거나 어려워서
                    </font>
                </div>

                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px"
                    }}
                >
                    <img
                        className='cursor'
                        src={
                            surveyValue === "금액이 부담스러워서"
                                ? "/img/icon/landing/record_on.png"
                                : "/img/icon/landing/record_off.png"
                        }
                        alt='V'
                        onClick={() => onChangeRadioBtn("금액이 부담스러워서")}
                    />
                    <font
                        className="drag-none"
                    >
                        금액이 부담스러워서
                    </font>
                </div>

                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "7px"
                    }}
                >
                    <img
                        className='cursor'
                        src={
                            surveyValue === "기타"
                                ? "/img/icon/landing/record_on.png"
                                : "/img/icon/landing/record_off.png"
                        }
                        alt='V'
                        onClick={() => onChangeRadioBtn("기타")}
                    />
                    <font
                        className="drag-none"
                    >
                        기타
                    </font>
                </div>

                <div
                    style={{
                        textAlign: "center"
                    }}
                >
                    <textarea
                        style={{
                            width: "256px",
                            height: "123px",
                            border: "1px solid #2B4AC4",
                            outline: "none",
                            borderRadius: "16px",
                            overflow: "hidden",
                            marginBottom: "32px"
                        }}
                        value={otherReason}
                        disabled={surveyValue === "기타" ? false : true}
                        onChange={e => setOtherReason(e.target.value)}
                    />
                </div>
            </div>

            <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end"
                }}
            >
                <button
                    className='cursor'
                    style={{
                        border: "none",
                        outline: "none",
                        backgroundColor: "#FFFFFF",
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#C63353",
                        marginRight: "20px"
                    }}
                    onClick={() => props.cancleBtnEvent()}
                >
                    취 소
                </button>

                <button
                    className='cursor common_ok_textBtn'
                    style={{
                        border: "none",
                        outline: "none",
                        backgroundColor: "#FFFFFF",
                        fontSize: "14px",
                        fontWeight: "bold"
                    }}
                    disabled={
                        surveyValue === ""
                            ? true
                            : surveyValue === "기타" && otherReason.length < 1
                                ? true
                                : false
                    }
                    onClick={() => sendSurvey()}
                >
                    확 인
                </button>
            </div>
        </div>
    )
}

export default DeleteSurvey
