import React from "react";
import ReactModal from "react-modal";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
/**
 * Version : 1.0
 * 파일명 : InviteGroupAgreePopup.js
 * 작성일자 : 2021-12-07
 * 작성자 : 강연승
 * 설명 : 미팅호스트 초대장 팝업
 * 수정일자 : 2022-02-14
 * 수정자 : 강연승
 * 수정내역 : 그룹 초대장 타입별 텍스트 변경
 */
function InviteGroupAgreePopup(props) {
  /**
   * body
   */
  ReactModal.setAppElement("body");
  /**
   * 언어변환
   */
  const { t } = useTranslation();
  const userinfo = useSelector(state => state.userInfoReducer);
  /* 그룹 카테고리 코드 변환 */
  const groupCategoryConvert = ctgryCD => {
    switch (ctgryCD) {
      case "CD0401":
        return "company";
      case "CD0402":
        return "company";
      case "CD0403":
        return "school";
      case "CD0404":
        return "private_institute";
      case "CD0405":
        return "university";
      case "CD0406":
        return "social";
      default:
        return "company";
    }
  };
  return (
    <ReactModal
      isOpen={props.open} //모달 Open(Boolean)
      className="inviteGroupAgreePopup" //모달 ClassName
      overlayClassName="inviteGroupAgreePopup_Overlay" //모달오버레이 ClassName
      onRequestClose={props.close}
      shouldCloseOnEsc={true}
      animationType="fade"
      transparent={true}
      closeTimeoutMS={200}
    >
      <div className="text-center">
        <div
          style={{
            color: "#3E3E4F",
            font: "normal normal bold 24px/37px Spoqa Han Sans Neo",
            marginTop: "56px",
            marginBottom: "32px",
          }}
        >
          {t("group.invitation.title")}
          <img
            src="/img/icon/common/close.png"
            alt="close"
            onClick={props.close}
            style={{
              cursor: "pointer",
              position: "absolute",
              top: "220px",
              right: "710px",
            }}
          />
        </div>

        <img src="/img/icon/group/invite_newillust.png" alt="inviteIllust" />
        <div
          style={{
            marginTop: "40px",
            marginBottom: "24px",
            color: "#2B4AC4",
            font: "normal normal bold 18px/27px Spoqa Han Sans Neo",
          }}
        >
          <div>
            {t("group.invitation.inviteText1", {
              GROUP_NM: props.groupInfo.GROUP_NM,
              GROUP_OWNER: props.groupInfo.GTYPE,
            })}
          </div>
          <div>
            {t("group.invitation.inviteText2", {
              USER_NM: userinfo.NAME,
              MEETING_HOST: t(
                "group.thema." +
                  groupCategoryConvert(props.groupInfo.CTGRY_CD) +
                  ".meetingHost"
              ),
            })}
          </div>
        </div>

        <div
          style={{
            marginTop: "24px",
            marginBottom: "40px",
            color: "#3E3E4F",
            font: "normal normal bold 12px/21px Spoqa Han Sans Neo",
          }}
        >
          <div>
            {t("group.invitation.inviteText3", {
              MEETING_HOST: t(
                "group.thema." +
                  groupCategoryConvert(props.groupInfo.CTGRY_CD) +
                  ".meetingHost"
              ),
            })}
          </div>
          <div> {t("group.invitation.inviteText4")}</div>
          <div style={{ color: "#D4667E" }}>
            {t("group.invitation.inviteText5")}
          </div>
        </div>

        <div style={{ marginBottom: "40px" }}>
          <button
            style={{
              background: "#3E3E4F 0% 0% no-repeat padding-box",
              borderRadius: "10px",
              width: "176px",
              height: "48px",
              border: "none",
              font: "normal normal bold 14px/21px Spoqa Han Sans Neo",
              color: "#FFFFFF",
              cursor: "pointer",
            }}
            onClick={() => props.denyInviteGroup(props.groupInfo.GROUP_NUM)}
          >
            {t("common.btn.deny")}
          </button>
          <button
            onClick={() =>
              props.agreeInviteGroup(
                props.groupInfo.GROUP_NUM,
                props.groupInfo.GROUP_NM,
                props.groupInfo.ORIGIN_GROUP_COLOR
              )
            }
            style={{
              background: "#2B4AC4 0% 0% no-repeat padding-box",
              borderRadius: "10px",
              width: "176px",
              height: "48px",
              border: "none",
              font: "normal normal bold 14px/21px Spoqa Han Sans Neo",
              color: "#FFFFFF",
              marginLeft: "16px",
              cursor: "pointer",
            }}
          >
            {t("common.btn.agree")}
          </button>
        </div>
      </div>
    </ReactModal>
  );
}

export default InviteGroupAgreePopup;
