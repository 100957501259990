import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { updateRecordInfo, updatePagingInfo } from "actions/index";
/**
 * Version : 1.0
 * 파일명 : RecordHeader.js
 * 작성일자 : 2022-01-13
 * 작성자 : 권도훈
 * 설명 : 회의 영상 공통 헤더
 * 수정일자 : 2022-05-04
 * 수정자 : 강연승
 * 수정내역 : 그룹 컬러 수정
 */
function RecordHeader(props) {
  /**
   * Translation
   */
  const { t } = useTranslation();
  /**
   * REDUCER
   */
  const REDUCER_STATE = useSelector(state => state.groupInfoReducer);
  const RECORD_STATE = useSelector(state => state.recordInfoReducer);
  /**
   * DISPATCH
   */
  const dispatch = useDispatch();
  /**
   * hover
   */
  const [tooltip, setTooltip] = useState(false);
  /**
   * TEXT
   */
  const textRef = useRef();
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={
              REDUCER_STATE.GROUP_YN === "N" || REDUCER_STATE.GROUP_YN === ""
                ? "/img/icon/record/record.png"
                : "/img/icon/record/title_record_" +
                  REDUCER_STATE.GROUP_COLOR +
                  ".png"
            }
            alt="ICON"
          />
          <div>
            <div
              className={
                REDUCER_STATE.GROUP_YN === "N" || REDUCER_STATE.GROUP_YN === ""
                  ? "record_header_title"
                  : "groupPebble_title_" + REDUCER_STATE.GROUP_COLOR
              }
              style={{
                font: "normal normal bold 28px/37px Spoqa Han Sans Neo",
                marginLeft: "16px",
                display: "flex",
                alignItems: "center",
                width: "145px",
                justifyContent: "space-between",
              }}
            >
              <div>
                {REDUCER_STATE.GROUP_YN === "N" || REDUCER_STATE.GROUP_YN === ""
                  ? t("my.record.txt.title.CD0401")
                  : t("my.record.txt.title." + REDUCER_STATE.GROUP_CATEGORY)}
              </div>

              <img
                src="/img/icon/schedule/tooltip_gr.png"
                alt="tooltip"
                onMouseOver={() => setTooltip(true)}
                onMouseOut={() => setTooltip(false)}
              />
            </div>

            <div
              className="record_tooltip"
              style={{
                display: tooltip ? "flex" : "none",
              }}
            >
              <article>
                <div>영상은 최대 1개월 간 보관되고 이후 영구 삭제됩니다.</div>
                <div>중요한 파일의 경우 꼭 로컬에 다운로드 해주세요.</div>
              </article>
            </div>
          </div>

          <div
            className="cursor"
            style={{
              fontSize: "14px",
              fontWeight: "700",
              color:
                REDUCER_STATE.GROUP_YN === "N" || REDUCER_STATE.GROUP_YN === ""
                  ? "#2b4ac4"
                  : "#3B3F4C",
              marginLeft: "15px",
            }}
            onClick={() => {
              const paging_obj = {
                ROW_COUNT: RECORD_STATE.VIEW_TYPE === "CARD" ? 10 : 16,
                PAGE_COUNT: 0,
                TOTAL: 0,
              };
              dispatch(updatePagingInfo(paging_obj));
              const obj = {
                VIEW_TYPE: RECORD_STATE.VIEW_TYPE === "CARD" ? "LIST" : "CARD",
                IS_CHECKED: false,
                CHECKED_ARR: [],
                SEARCH_TEXT: "",
              };
              dispatch(updateRecordInfo(obj));
            }}
          >
            {RECORD_STATE.VIEW_TYPE === "CARD" ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={
                    REDUCER_STATE.GROUP_YN === "N" ||
                    REDUCER_STATE.GROUP_YN === ""
                      ? "/img/icon/record/list.png"
                      : "/img/icon/record/style_list.png"
                  }
                  alt="LIST"
                  style={{
                    // width: "18px",
                    marginRight: "8px",
                  }}
                />
                <font>{t("my.record.btn.list")}</font>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={
                    REDUCER_STATE.GROUP_YN === "N" ||
                    REDUCER_STATE.GROUP_YN === ""
                      ? "/img/icon/record/card.png"
                      : "/img/icon/record/style_card.png"
                  }
                  alt="CARD"
                  style={{
                    // width: "18px",
                    marginRight: "8px",
                  }}
                />
                <font>{t("my.record.btn.card")}</font>
              </div>
            )}
          </div>
        </div>

        <div
          className="cursor"
          style={{
            fontSize: "14px",
            border: "1px solid blue",
            marginLeft: "18px",
            borderRadius: "8px",
            fontWeight: "700",
            color: "#2B4AC4",
            display: RECORD_STATE.IS_CHECKED ? "flex" : "none",
            alignItems: "center",
            width: "165px",
            height: "35px",
          }}
          onClick={() => props.deleteCheckedRecordData()}
        >
          <img src="/img/icon/record/record_delete.png" alt="delete" />
          <font>{t("my.record.btn.delete")}</font>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <input
          className="record_searchText"
          id="searchValue_input"
          type="text"
          placeholder={t("my.record.placeholder.delete")}
          autoComplete="off"
          ref={textRef}
          onChange={e => {
            if (e.target.value === "" && RECORD_STATE.SEARCH_TEXT !== "") {
              const obj = {
                VIEW_TYPE: RECORD_STATE.VIEW_TYPE,
                IS_CHECKED: false,
                CHECKED_ARR: [],
                SEARCH_TEXT: "",
              };
              dispatch(updateRecordInfo(obj));
              const paging_obj = {
                // ROW_COUNT: RECORD_STATE.VIEW_TYPE === "CARD" ? 16 : 10,
                ROW_COUNT: 0,
                PAGE_COUNT: 0,
                TOTAL: 0,
              };
              dispatch(updatePagingInfo(paging_obj));
            }
          }}
          onKeyDown={e => {
            if (e.key === "Enter") {
              const obj = {
                VIEW_TYPE: RECORD_STATE.VIEW_TYPE,
                IS_CHECKED: false,
                CHECKED_ARR: [],
                SEARCH_TEXT: e.target.value,
              };
              dispatch(updateRecordInfo(obj));
              const paging_obj = {
                // ROW_COUNT: RECORD_STATE.VIEW_TYPE === "CARD" ? 16 : 10,
                ROW_COUNT: 0,
                PAGE_COUNT: 0,
                TOTAL: 0,
              };
              dispatch(updatePagingInfo(paging_obj));
            }
          }}
        />
        <button
          className={
            REDUCER_STATE.GROUP_YN === "N" || REDUCER_STATE.GROUP_YN === ""
              ? "record_searchBtn"
              : "groupRecord_searchBtn"
          }
          onClick={() => {
            const obj = {
              VIEW_TYPE: RECORD_STATE.VIEW_TYPE,
              IS_CHECKED: false,
              CHECKED_ARR: [],
              SEARCH_TEXT: textRef.current.value,
            };
            dispatch(updateRecordInfo(obj));
            const paging_obj = {
              // ROW_COUNT: RECORD_STATE.VIEW_TYPE === "CARD" ? 16 : 10,
              ROW_COUNT: 0,
              PAGE_COUNT: 0,
              TOTAL: 0,
            };
            dispatch(updatePagingInfo(paging_obj));
          }}
        >
          {t("my.record.btn.search")}
        </button>
      </div>
    </div>
  );
}

export default RecordHeader;
