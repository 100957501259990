import Alert from "component/tag/modal/alert";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRouteMatch, useLocation } from "react-router";
import ProfileGroupInfo from "component/tag/div/group/profile/profileGroupInfo";
import ProfileGroupMember from "component/tag/div/group/profile/profileGroupMember";
import ProfileLicenseContent from "component/tag/div/my/profile/profileLicenseContent";
import ProfileLoading from "component/tag/div/my/profile/profileLoading";
import { useDispatch, useSelector } from "react-redux";
import { updateGroupInfo, updatePagingInfo } from "actions/index";
/**
 * Version : 1.0
 * 파일명 : groupProfile.js
 * 작성일자 : 2021-11-16
 * 작성자 : 강연승
 * 설명 : 그룹 프로필
 * 수정일자 : 2022-05-04
 * 수정자 : 강연승
 * 수정내역 : 그룹 컬러 수정
 */
function GroupProfile() {
  /**
   * 언어변환
   */
  const { t } = useTranslation();
  /**
   * 기본 도구 설정
   */
  const location = useLocation();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const groupInfoState = useSelector(state => state.groupInfoReducer);
  const PAGING_STATE = useSelector(state => state.pagingInfoReducer);
  /**
   * 로딩
   */
  const [loading, setLoading] = useState(false);

  /**
   * 뷰 타입
   */
  const [viewType, setViewType] = useState("LICENSE");
  /**
   * 데이터 정보
   */
  const groupNum = match.params.key;

  /**
   * 알림창 설정
   */
  const [alertProps, setAlertProps] = useState({
    text: "",
    isShow: false,
    cancelBtnDisplay: false,
    okBtnDisplay: true,
    okBtnEventHandler: function () {},
    cancleBtnEventHandler: function () {
      closeAlertEvent();
    },
  });

  /* 그룹 오너 / 미팅호스트 구별 */
  const [userType, setUserType] = useState("");
  const getUserType = () => {
    axios
      .get("/group/selectUserGroupAuth", {
        params: {
          GROUP_NUM: groupNum,
        },
      })
      .then(({ data }) => {
        if (data[0].AUTH_CD === "CD0301") {
          setUserType("owner");
        } else if (data[0].AUTH_CD === "CD0302") {
          setUserType("host");
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  /**
   * 알림창 닫기
   */
  const closeAlertEvent = useCallback(() => {
    setAlertProps({
      ...alertProps,
      isShow: false,
    });
  }, [alertProps]);

  /**
   * 그룹 프로필 데이터 가져오기
   */
  useEffect(() => {
    getUserType();
    getGroupInfo();
  }, []);

  useEffect(() => {
    if (location.state.viewType !== undefined) {
      setViewType(location.state.viewType);
    } else {
      setViewType("LICENSE");
    }
  }, [location.state]);

  /**
   * 타입 별 렌더링
   */
  useEffect(() => {
    getGroupInfo();
  }, [PAGING_STATE.PAGE_COUNT, PAGING_STATE.ROW_COUNT, viewType]);

  const [groupInfo, setGroupInfo] = useState({
    GROUP_NUM: "",
    CREATE_DT: "",
    USE_YN: "",
    USER_NUM: "",
    GROUP_EXPLN: "",
    GROUP_COLOR: "",
    GROUP_NM: "",
    CTGRY_CD: "",
  });

  const getGroupInfo = () => {
    axios
      .get("/group/selectGroupInfo", {
        params: {
          GROUP_NUM: groupNum,
        },
      })
      .then(res => {
        if (res.status === 200) {
          const config = {
            GROUP_NUM: res.data[0].GROUP_NUM,
            GROUP_NM: res.data[0].GROUP_NM,
            GROUP_COLOR: res.data[0].GROUP_INFO_COLOR,
            AUTH_CD: res.data[0].AUTH_CD,
            GROUP_CATEGORY: res.data[0].CTGRY_CD,
            GROUP_IMG: res.data[0].GROUP_IMG,
            GROUP_YN: "Y",
            REC_YN: res.data[0].REC_YN,
            DOWNLOAD_YN: res.data[0].DOWNLOAD_YN,
            PLAY_YN: res.data[0].PLAY_YN,
            HOST_CNT: res.data[0].HOST_CNT,
            LICENSE_CNT: res.data[0].LICENSE_CNT,
            D_DAY: res.data[0].D_DAY,
            CREATE_DT: res.data[0].CREATE_DT,
          };
          dispatch(updateGroupInfo(config));
          // if (viewType === "MEMBER") {
          //   const paging_obj = {
          //     ROW_COUNT: PAGING_STATE.ROW_COUNT,
          //     PAGE_COUNT: PAGING_STATE.PAGE_COUNT,
          //     TOTAL: res.data[0].HOST_CNT,
          //   };
          //   dispatch(updatePagingInfo(paging_obj));
          // }
          setGroupInfo(res.data[0]);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  /* 그룹 삭제 요청 */
  const deleteGroup = groupNum => {
    if (groupInfoState.LICENSE_CNT > 0) {
      alert(t("group.profile.groupSetting.msg.impossibleDeleteGroup"));
      return;
    }
    axios
      .put("/group/deleteGroup", { GROUP_NUM: groupNum })
      .then(response => {
        if (response.status === 200) {
          setAlertProps({
            ...alertProps,
            isShow: true,
            text: t("group.profile.groupSetting.msg.deleteGroup"),
            cancelBtnDisplay: false,
            okBtnEventHandler: function () {
              closeAlertEvent();
              window.location.href = "/app/group";
            },
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  /* 미팅호스트 - 그룹 탈퇴 */
  const leaveGroup = groupNum => {
    axios
      .put("/group/leaveGroup", { GROUP_NUM: groupNum })
      .then(response => {
        if (response.status === 200) {
          setAlertProps({
            ...alertProps,
            isShow: true,
            text: t("group.profile.groupSetting.msg.exitGroup"),
            cancelBtnDisplay: false,
            okBtnEventHandler: function () {
              closeAlertEvent();
              window.location.href = "/app/group";
            },
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  /**
   * 이름 변경 저장
   */
  const saveGroupName = params => {
    axios
      .put("/group/updateGroupName", params)
      .then(res => {
        if (res.status === 200) {
          getGroupInfo();
          setAlertProps({
            ...alertProps,
            isShow: true,
            text: t("group.profile.groupSetting.msg.editGroupName"),
            cancelBtnDisplay: false,
            okBtnEventHandler: function () {
              closeAlertEvent();
            },
          });
        } else {
          setAlertProps({
            ...alertProps,
            isShow: true,
            text: t("group.profile.groupSetting.msg.failSave"),
            cancelBtnDisplay: false,
            okBtnEventHandler: function () {
              closeAlertEvent();
            },
          });
        }
      })
      .catch(() => {
        setAlertProps({
          ...alertProps,
          isShow: true,
          text: t("group.profile.groupSetting.msg.failSave"),
          cancelBtnDisplay: false,
          okBtnEventHandler: function () {
            closeAlertEvent();
          },
        });
      });
  };

  return (
    <div className="profile">
      <Alert alertProps={alertProps} />

      <div className="profile_content">
        <div
          className={
            "profile_content_header groupPebble_title_" +
            groupInfoState.GROUP_COLOR
          }
        >
          <img
            src={
              "/img/icon/profile/group_profile_" +
              groupInfoState.GROUP_COLOR +
              ".png"
            }
            alt="profile"
            style={{ marginRight: "16px" }}
          />
          {userType === "owner" ? (
            <>{t("group.profile.groupSetting.ownerTitle")}</>
          ) : (
            <>{t("group.profile.groupSetting.hostTitle")}</>
          )}
        </div>

        <div className="profile_content_body">
          <ProfileLoading isShow={loading} />

          <div
            className="profile_profile_content_child"
            style={{
              display: loading ? "none" : "block",
            }}
          >
            {viewType === "LICENSE" ? (
              <ProfileLicenseContent />
            ) : (
              // 개설자 관리
              <ProfileGroupMember getGroupInfo={getGroupInfo} />
            )}
          </div>
        </div>
      </div>

      <div className="profile_sidebar">
        <div
          className={
            "profile_sidebar_header groupPebble_title_" +
            groupInfoState.GROUP_COLOR
          }
        >
          {t("group.profile.groupSetting.setting")}
        </div>

        <ProfileGroupInfo
          groupInfo={groupInfo}
          reloadGroupInfo={getGroupInfo}
          deleteGroup={deleteGroup}
          leaveGroup={leaveGroup}
          viewType={viewType}
          setViewType={setViewType}
          saveGroupName={saveGroupName}
        />
      </div>
    </div>
  );
}

export default GroupProfile;
