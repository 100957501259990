import React from "react";
import ReactModal from "react-modal";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
/**
 * Version : 1.0
 * 파일명 : GroupDeletePopup.js
 * 작성일자 : 2021-12-02
 * 작성자 : 강연승
 * 설명 : 그룹프로필 - 그룹 삭제 및 탈퇴
 * 수정일자 : 2022-02-10
 * 수정자 : 강연승
 * 수정내역 : 텍스트 수정 영상회의 -> 화상회의, 웹폰트 변경
 */

function GroupDeletePopup(props) {
  /**
   * 글로벌 스테이트
   */
  const groupInfoState = useSelector(state => state.groupInfoReducer);
  const closePopup = () => {
    props.close("delete");
  };
  return (
    <>
      {groupInfoState.AUTH_CD === "CD0301" ? (
        <ReactModal
          isOpen={props.open} //모달 Open(Boolean)
          className="groupDeletePopup" //모달 ClassName
          overlayClassName="groupDeletePopup_Overlay" //모달오버레이 ClassName
          onRequestClose={closePopup}
          shouldCloseOnEsc={true}
          animationType="fade"
          transparent={true}
          closeTimeoutMS={200}
        >
          <div className="text-center">
            <img
              src="/img/icon/landing/close.png"
              alt="close"
              onClick={closePopup}
              style={{
                cursor: "pointer",
                float: "right",
                marginTop: "24px",
                marginRight: "24px",
              }}
            />
            <div
              style={{
                color: "#3E3E4F",
                font: "normal normal bold 24px/36px Spoqa Han Sans Neo",
                clear: "both",
                marginBottom: "40px",
              }}
            >
              <div>
                [{groupInfoState.GROUP_NM}] 그룹을
                <br />
                삭제하시겠습니까?
              </div>
            </div>
            <img src="/img/elimination.png" alt="deleteGroup" />
            <div
              style={{
                color: "#3E3E4F",
                marginTop: "43px",
                font: "normal normal bold 16px/21px Spoqa Han Sans Neo",
              }}
            >
              <div>모든 미팅호스트들에게 그룹 삭제 알람이 전송됩니다.</div>
            </div>
          </div>

          <div className="text-center" style={{ margin: "32px 0" }}>
            <button
              style={{
                width: "64px",
                height: "48px",
                backgroundColor: "#2B4AC4",
                border: "1px solid #2B4AC4",
                color: "#ffffff",
                font: "normal normal bold 14px/21px Spoqa Han Sans Neo",
                cursor: "pointer",
                borderRadius: "10px",
              }}
              onClick={closePopup}
            >
              아니오
            </button>

            <button
              style={{
                width: "64px",
                height: "48px",
                backgroundColor: "#D7263D",
                border: "1px solid #C63353",
                color: "#ffffff",
                font: "normal normal bold 14px/21px Spoqa Han Sans Neo",
                cursor: "pointer",
                borderRadius: "10px",
                marginLeft: "8px",
              }}
              onClick={() => (
                props.deleteGroup(groupInfoState.GROUP_NUM), closePopup()
              )}
            >
              삭 제
            </button>
          </div>
        </ReactModal>
      ) : (
        <ReactModal
          isOpen={props.open} //모달 Open(Boolean)
          className="groupMeetingHostDeletePopup" //모달 ClassName
          overlayClassName="groupMeetingHostDeletePopup_Overlay" //모달오버레이 ClassName
          onRequestClose={closePopup}
          shouldCloseOnEsc={true}
          animationType="fade"
          transparent={true}
          closeTimeoutMS={200}
        >
          <div className="text-center">
            <img
              src="/img/icon/group/close.png"
              alt="close"
              onClick={closePopup}
              style={{
                cursor: "pointer",
                float: "right",
                marginTop: "24px",
                marginRight: "24px",
              }}
            />
            <div
              style={{
                color: "#2B4AC4",
                font: "normal normal bold 24px/32px Spoqa Han Sans Neo",
                clear: "both",
              }}
            >
              그룹 탈퇴
            </div>
            <div
              style={{
                color: "#3E3E4F",
                marginTop: "24px",
                font: "normal normal bold 16px/24px Spoqa Han Sans Neo",
              }}
            >
              <div>[{groupInfoState.GROUP_NM}] 그룹을 탈퇴하시겠습니까?</div>
            </div>
            <div
              style={{
                color: "#3E3E4F",
                marginTop: "32px",
                font: "normal normal bold 14px/21px Spoqa Han Sans Neo",
              }}
            >
              <div>
                그룹을 탈퇴할 경우, 해당 그룹의
                <br />
                미팅 일정, 녹화파일권한 등 모든 데이터가 삭제되고
                <br />
                그룹 오너에게 알림이 전송됩니다.
              </div>
            </div>
          </div>

          <div className="text-center" style={{ margin: "32px 0" }}>
            <button
              style={{
                width: "64px",
                height: "48px",
                backgroundColor: "white",
                border: "1px solid #2B4AC4",
                color: "#2B4AC4",
                font: "normal normal bold 14px/21px Spoqa Han Sans Neo",
                cursor: "pointer",
                borderRadius: "10px",
              }}
              onClick={() => (
                props.leaveGroup(groupInfoState.GROUP_NUM), closePopup()
              )}
            >
              탈 퇴
            </button>

            <button
              style={{
                width: "64px",
                height: "48px",
                backgroundColor: "white",
                border: "1px solid #C63353",
                color: "#C63353",
                font: "normal normal bold 14px/21px Spoqa Han Sans Neo",
                cursor: "pointer",
                borderRadius: "10px",
                marginLeft: "16px",
              }}
              onClick={closePopup}
            >
              취 소
            </button>
          </div>
        </ReactModal>
      )}
    </>
  );
}

export default GroupDeletePopup;
