import React, { useCallback, useEffect, useState } from "react";
import "css/profilesupport.css";
import axios from "axios";
import ProfileSupportdetail from "./profileSupportdetail";
/**
 * Version : 1.0
 * 파일명 : profileSupport.js
 * 작성일자 : 2022-03-30
 * 작성자 : 정지윤
 * 설명 : 고객센터 기본화면
 * 수정일자 : 2022-06-13
 * 수정자 : 강연승
 * 수정내역 : sobridge 데모 - 고객센터 정보 수정
 */

function ProfileSupport(props) {
  const [AnnouncementListnohold, setAnnouncementListnohold] = useState([]);
  const [mouseOverType, setMouseOverType] = useState("");
  const [mouseOverStatus, setMouseOverStatus] = useState(false);

  const [countholdlist, setcountholdlist] = useState("");

  const counthold = useCallback(() => {
    axios.get("/support/countannouncementhold").then(res => {
      if (res.status === 200) {
        setcountholdlist(res.data);
      }
    });
  }, []);
  useEffect(() => {
    counthold();
  }, [counthold]);

  const mouseOver = (type, status) => {
    setMouseOverType(type);
    setMouseOverStatus(status);
  };
  const mouseOut = (type, status) => {
    setMouseOverType(type);
    setMouseOverStatus(status);
  };

  const getAnnouncementListnohold = useCallback(() => {
    let config = {
      params: {
        skip: 0,
        amount: 3,
      },
    };
    axios.get("/support/AnnouncementList", config).then(res => {
      if (res.status === 200) {
        setAnnouncementListnohold(res.data);
      }
    });
  }, []);

  useEffect(() => {
    getAnnouncementListnohold();
  }, [getAnnouncementListnohold]);

  return (
    <div>
      <div className="profile_content_support">
        <div className="first_card">
          <div className="cursor">
            <div
              className="hover_blue"
              onClick={() => props.onClickmenu(0)}
              onMouseOver={() => mouseOver("FAQ", true)}
              onMouseOut={() => mouseOut("FAQ", false)}
            >
              <div className="profile_content_faq">
                {mouseOverType === "FAQ" && mouseOverStatus ? (
                  <img
                    src="/img/faqimagecolor.png"
                    alt="FAQ_IMAGE"
                    className="sup_faqimg"
                  />
                ) : (
                  <img
                    src="/img/faqimage.png"
                    alt="FAQ_IMAGE"
                    className="sup_faqimg"
                  />
                )}

                <div className="sup_faq">FAQ</div>

                <div className="sup_faq_text">
                  <span className="text_hover_blue">자주 묻는 질문</span>
                  <span>을 검색해보세요.</span>
                </div>
              </div>
            </div>
          </div>

          <div className="cursor">
            <div
              className="hover_orange"
              onClick={() => props.onClickmenu(1)}
              onMouseOver={() => mouseOver("DQ", true)}
              onMouseOut={() => mouseOut("DQ", false)}
            >
              <div className="profile_content_directquestion">
                {mouseOverType === "DQ" && mouseOverStatus ? (
                  <img
                    src="/img/directquestioncolor.png"
                    className="sup_dqimg"
                    alt="DQ_IMAGE"
                  />
                ) : (
                  <img
                    src="/img/directquestion.png"
                    className="sup_dqimg"
                    alt="DQ_IMAGE"
                  />
                )}

                <div className="sup_directquestion">1:1 문의</div>

                <div className="sup_dq_text">
                  <span className="text_hover_orange">궁금한 사항</span>
                  <span>이 있으시면 문의주세요.</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="second_card">
          <div className="cursor">
            <div
              className="hover_purple"
              onClick={() => props.onClickmenu(2)}
              onMouseOver={() => mouseOver("suggestion", true)}
              onMouseOut={() => mouseOut("suggestion", false)}
            >
              <div className="profile_content_suggestion">
                {mouseOverType === "suggestion" && mouseOverStatus ? (
                  <img
                    src="/img/suggestioncolor.png"
                    className="sup_suggestionimg"
                    alt="soundimg"
                  />
                ) : (
                  <img
                    src="/img/suggestion.png"
                    className="sup_suggestionimg"
                    alt="soundimg"
                  />
                )}

                <div className="sup_suggestion">고객의 소리</div>

                <div className="sup_suggestion_text">
                  <span className="text_hover_purple1"> 불편사항</span>
                  <span>이나</span>
                  <span className="text_hover_purple2">바라는 점</span>
                  <span>을 알려주세요.</span>
                </div>
              </div>
            </div>
          </div>
          <div className="cursor">
            <div
              className="hover_mint"
              onClick={() => props.onClickmenu(3)}
              onMouseOver={() => mouseOver("myquestion", true)}
              onMouseOut={() => mouseOut("myquestion", false)}
            >
              <div className="profile_content_myquestion">
                {mouseOverType === "myquestion" && mouseOverStatus ? (
                  <img
                    alt="myquestionimg"
                    src="/img/myquestioncolor.png"
                    className="sup_myquestionimg"
                  />
                ) : (
                  <img
                    alt="myquestionimg"
                    src="/img/myquestion.png"
                    className="sup_myquestionimg"
                  />
                )}

                <div className="sup_myquestion">내 문의함</div>

                <div className="sup_myquestion_text">
                  <span className="text_hover_mint">문의했던 내용</span>
                  <span>을 확인하세요</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="third_card">
          <div className="profile_content_tel">
            <div className="sup_tel_title">전화문의</div>
            <div className="sup_tel_num">02-760-5057</div>
            <div className="sup_tel_time">
              <div className="sup_tel_time_font">
                <li className="sup_tel_time_font">월-금 : 09:00 ~ 17:00</li>

                <li>점심시간 : 12:00 ~ 13:00</li>

                <li>토/일/공휴일 휴무</li>
              </div>
            </div>
          </div>
          <div className="profile_content_announcement">
            <div className="sup_announcement_title">공지사항</div>
            <div>
              {AnnouncementListnohold.map((data, index) => (
                <div
                  key={index}
                  className="announcementbody cursor"
                  onClick={() =>
                    props.onClickmenu(
                      4,
                      //index+countholdlist)
                      data.Notice_Num
                    )
                  }
                >
                  <div className="announcement_index">{index + 1}</div>
                  <div className="announcement_title">{data.Notice_Title}</div>
                </div>
              ))}
            </div>
            <div className="announcementbodyfinish"></div>
            <div>
              <div className="cursor">
                <div
                  className="announcement_more"
                  onClick={() => props.onClickmenu(4)}
                >
                  {" "}
                  더보기
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileSupport;
