import Alert from "component/tag/modal/alert";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
/**
 * Version : 1.0
 * 파일명 : DataStatisticsParticipantLogPaging.js
 * 작성일자 : 2022-03-18
 * 작성자 : 강연승
 * 설명 : 통계 참석자 상세 참석로그 페이징 div REDUX
 * 수정일자 : 2022-02-23
 * 수정자 : 강연승
 * 수정내역 :
 */
function DataStatisticsParticipantLogPaging(props) {
  /**
   * TRANSLATOIN
   */
  const { t } = useTranslation();
  /**
   * 알림창 설정
   */
  const [alertProps, setAlertProps] = useState({
    text: "",
    isShow: false,
    cancelBtnDisplay: false,
    okBtnDisplay: true,
    okBtnEventHandler: function () {},
    cancleBtnEventHandler: function () {
      closeAlertEvent();
    },
  });
  /**
   * 알림창 닫기
   */
  const closeAlertEvent = useCallback(() => {
    setAlertProps({
      ...alertProps,
      isShow: false,
    });
  }, [alertProps]);
  /**
   * 페이징
   */
  const changePage = type => {
    if (type === "prev") {
      if (
        props.pagingState.PAGE_COUNT - 5 < 0 ||
        props.pagingState.TOTAL <= 5
      ) {
        setAlertProps({
          ...alertProps,
          isShow: true,
          text: t("paging.first"),
          cancelBtnDisplay: false,
          okBtnEventHandler: function () {
            closeAlertEvent();
          },
        });
        return;
      } else if (props.pagingState.PAGE_COUNT >= 5) {
        let diff = props.pagingState.PAGE_COUNT - 5;

        if (diff < 5) {
          const paging_obj = {
            ROW_COUNT: props.pagingState.ROW_COUNT,
            PAGE_COUNT: 0,
            TOTAL: props.pagingState.TOTAL,
          };
          props.setPagingState(paging_obj);
          // dispatch(updatePagingInfo(paging_obj));
        } else {
          const paging_obj = {
            ROW_COUNT: props.pagingState.ROW_COUNT,
            PAGE_COUNT: props.pagingState.PAGE_COUNT - 5,
            TOTAL: props.pagingState.TOTAL,
          };
          props.setPagingState(paging_obj);
          // dispatch(updatePagingInfo(paging_obj));
        }
      }
    } else if (type === "next") {
      if (
        props.pagingState.PAGE_COUNT + 5 >= props.pagingState.TOTAL ||
        props.pagingState.ROW_COUNT === props.pagingState.TOTAL
      ) {
        setAlertProps({
          ...alertProps,
          isShow: true,
          text: t("paging.last"),
          cancelBtnDisplay: false,
          okBtnEventHandler: function () {
            closeAlertEvent();
          },
        });
        return;
      } else if (props.pagingState.PAGE_COUNT < props.pagingState.TOTAL) {
        let diff = props.pagingState.PAGE_COUNT + 5;

        if (diff > props.pagingState.TOTAL) {
          const paging_obj = {
            ROW_COUNT: props.pagingState.ROW_COUNT,
            PAGE_COUNT:
              props.pagingState.TOTAL -
              (props.pagingState.TOTAL - props.pagingState.PAGE_COUNT),
            TOTAL: props.pagingState.TOTAL,
          };
          props.setPagingState(paging_obj);
          // dispatch(updatePagingInfo(paging_obj));
        } else {
          const paging_obj = {
            ROW_COUNT: props.pagingState.ROW_COUNT,
            PAGE_COUNT: props.pagingState.PAGE_COUNT + 5,
            TOTAL: props.pagingState.TOTAL,
          };
          props.setPagingState(paging_obj);
          // dispatch(updatePagingInfo(paging_obj));
        }
      }
    }
  };
  /**
   * 첫 페이지로 이동
   */
  const goFirstPage = () => {
    if (props.pagingState.PAGE_COUNT - 5 < 0 || props.pagingState.TOTAL <= 5) {
      setAlertProps({
        ...alertProps,
        isShow: true,
        text: t("paging.first"),
        cancelBtnDisplay: false,
        okBtnEventHandler: function () {
          closeAlertEvent();
        },
      });
      return;
    } else if (props.pagingState.PAGE_COUNT >= 5) {
      const paging_obj = {
        ROW_COUNT: props.pagingState.ROW_COUNT,
        PAGE_COUNT: 0,
        TOTAL: props.pagingState.TOTAL,
      };
      props.setPagingState(paging_obj);
      // dispatch(updatePagingInfo(paging_obj));
    }
  };
  /**
   * 마지막 페이지로 이동
   */
  const goLastPage = () => {
    if (
      props.pagingState.PAGE_COUNT + 5 >= props.pagingState.TOTAL ||
      props.pagingState.ROW_COUNT === props.pagingState.TOTAL
    ) {
      setAlertProps({
        ...alertProps,
        isShow: true,
        text: t("paging.last"),
        cancelBtnDisplay: false,
        okBtnEventHandler: function () {
          closeAlertEvent();
        },
      });
      return;
    } else if (props.pagingState.PAGE_COUNT < props.pagingState.TOTAL) {
      const diff = props.pagingState.TOTAL % 5;

      if (diff === 0) {
        const paging_obj = {
          ROW_COUNT: props.pagingState.ROW_COUNT,
          PAGE_COUNT: props.pagingState.TOTAL - 5,
          TOTAL: props.pagingState.TOTAL,
        };
        props.setPagingState(paging_obj);
        // dispatch(updatePagingInfo(paging_obj));
      } else {
        const paging_obj = {
          ROW_COUNT: props.pagingState.ROW_COUNT,
          PAGE_COUNT: props.pagingState.TOTAL - diff,
          TOTAL: props.pagingState.TOTAL,
        };
        props.setPagingState(paging_obj);
        // dispatch(updatePagingInfo(paging_obj));
      }
    }
  };
  return (
    <div className="data-statistics-detail-popup-attendance-log-paging">
      <Alert alertProps={alertProps} />

      <img
        src="/img/icon/data/first.png"
        alt="first"
        onClick={() => goFirstPage()}
      />
      <img
        src="/img/icon/data/before.png"
        alt="before"
        onClick={() => changePage("prev")}
      />
      <img
        src="/img/icon/data/next.png"
        alt="next"
        onClick={() => changePage("next")}
      />
      <img
        src="/img/icon/data/last.png"
        alt="last"
        onClick={() => goLastPage()}
      />
      {/* <img
        className="cursor first_btn"
        src="/img/icon/dashboard/first.png"
        alt="<<"
        onClick={() => goFirstPage()}
      />
      <img
        className="cursor prev_btn"
        src="/img/icon/dashboard/pre.png"
        alt="<"
        onClick={() => changePage("prev")}
      /> */}
      {/* <div className="pageText">{props.pagingState.PAGE_COUNT / 5 + 1}</div> */}
      {/* <img
        className="cursor next_btn"
        src="/img/icon/dashboard/next.png"
        alt=">"
        onClick={() => changePage("next")}
      />
      <img
        className="cursor end_btn"
        src="/img/icon/dashboard/end.png"
        alt=">>"
        onClick={() => goLastPage()}
      /> */}
    </div>
  );
}

export default DataStatisticsParticipantLogPaging;
