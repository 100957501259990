// import axios from 'axios';
import axios from "axios";
import Paging from "component/tag/div/common/paging";
import Loading from "component/tag/loading/loading";
import ParticipantsHistoryPopup from "component/tag/popup/common/participantsHistoryPopup";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DataEmpty from "./dataEmpty";
import DatePickerker from "component/tag/datepicker/datePicker";
import { useTranslation } from "react-i18next";
/**
 * Version : 1.0
 * 파일명 : dataUseLogList.js
 * 작성일자 : 2021-12-28
 * 작성자 : 권도훈
 * 설명 : 데이터 리스트 테이블
 * 수정일자 : 2022-05-04
 * 수정자 : 강연승
 * 수정내역 : 그룹 컬러 수정
 */
function DataUseLogList() {
  /**
   * 언어변환
   */
  const { t } = useTranslation();
  /**
   * REDUCER
   */
  const REDUCER_STATE = useSelector(state => state.groupInfoReducer);
  /**
   * 검색 타입
   */
  const [typeBox, setTypeBox] = useState(false);
  const [searchType, setSearchType] = useState("TITLE");
  /**
   * 로딩
   */
  const [loading, setLoading] = useState(true);
  /**
   * 히스토리 팝업
   */
  const [showHistory, setShowHistory] = useState(false);
  const [meetingKey, setMeetingKey] = useState("");
  const [duration, setDuration] = useState(0);
  const [meetingInfo, setMeetingInfo] = useState({});
  /**
   * 페이지 정보
   */
  const [page, setPage] = useState({
    ROW_COUNT: 10,
    PAGE_COUNT: 0,
  });
  const [total, setTotal] = useState(0);
  /**
   * 검색 정보
   */
  const [title, setTitle] = useState("");
  const [host, setHost] = useState("");
  const [searchText, setSearchText] = useState("");
  const [date, setDate] = useState({
    START_DATE: "",
    END_DATE: "",
  });
  const [picker, setPicker] = useState({
    START_DATE: "",
    END_DATE: "",
  });
  /**
   * 로그 데이터
   */
  const [log, setLog] = useState([]);
  /**
   * 로그 데이터 토탈 값
   */
  const getLogTotal = useCallback(() => {
    setLoading(true);

    const config = {
      params: {
        GROUP_YN: REDUCER_STATE.GROUP_YN,
        GROUP_NUM: REDUCER_STATE.GROUP_NUM,
        AUTH_CD: REDUCER_STATE.AUTH_CD,
        TITLE: title,
        HOST_NAME: host,
        START_DATE: pickerFormat(date.START_DATE),
        END_DATE: pickerFormat(date.END_DATE),
      },
    };

    axios
      .get("/schedule/total/log", config)
      .then(res => {
        if (res.status === 200) {
          setTotal(res.data.TOTAL);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [
    REDUCER_STATE.AUTH_CD,
    REDUCER_STATE.GROUP_NUM,
    REDUCER_STATE.GROUP_YN,
    date.END_DATE,
    date.START_DATE,
    host,
    title,
  ]);
  /**
   * 로그 데이터 호출
   */
  const getLogData = useCallback(() => {
    setLoading(true);

    const config = {
      params: {
        GROUP_YN: REDUCER_STATE.GROUP_YN,
        GROUP_NUM: REDUCER_STATE.GROUP_NUM,
        AUTH_CD: REDUCER_STATE.AUTH_CD,
        ROW_COUNT: page.ROW_COUNT,
        PAGE_COUNT: page.PAGE_COUNT,
        TITLE: title,
        HOST_NAME: host,
        START_DATE: pickerFormat(date.START_DATE),
        END_DATE: pickerFormat(date.END_DATE),
      },
    };

    axios
      .get("/schedule/log", config)
      .then(res => {
        if (res.status === 200) {
          setLog(res.data);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [
    REDUCER_STATE.GROUP_YN,
    REDUCER_STATE.GROUP_NUM,
    REDUCER_STATE.AUTH_CD,
    page.ROW_COUNT,
    page.PAGE_COUNT,
    title,
    host,
    date.START_DATE,
    date.END_DATE,
  ]);
  /**
   * 조회
   */
  useEffect(() => {
    getLogData();
    getLogTotal();
  }, [getLogData, getLogTotal]);
  /**
   * 검색 방식 닫기 이벤트 추가
   */
  useEffect(() => {
    if (typeBox) {
      window.addEventListener("click", closeSelectBox);
      return () => {
        window.removeEventListener("click", closeSelectBox);
      };
    }
  }, [typeBox]);
  /**
   * 날짜 포맷
   */
  const dateFormat = date => {
    const dateArr = date.split(" ");

    return (
      <div>
        <div>{dateArr[0]}</div>
        <div>{dateArr[1]}</div>
      </div>
    );
  };
  /**
   * 회의 시간 포맷
   */
  const durationFormat = duration => {
    if (duration >= 60) {
      const hour = Math.floor(duration / 60);
      const min = duration % 60;

      if (min === 0) {
        return hour + t("time.hour");
      } else {
        return hour + t("time.hour") + min + t("time.min");
      }
    } else {
      return duration + t("time.min");
    }
  };
  /**
   * datepicker 포맷
   */
  const pickerFormat = time => {
    if (time === "" || time === undefined) {
      return "";
    }

    let year = time.getFullYear();
    let month = time.getMonth() + 1;
    let date = time.getDate();

    if (month < 10) {
      month = "0" + month;
    }

    if (date < 10) {
      date = "0" + date;
    }

    const result = year + "-" + month + "-" + date;
    return result;
  };
  /**
   * 데이터 초기화
   */
  const resetParams = type => {
    if (type === "TITLE" || type === "HOST") {
      setDate({
        START_DATE: "",
        END_DATE: "",
      });
      setPicker({
        START_DATE: "",
        END_DATE: "",
      });
    } else if (type === "DATE") {
      setDate({
        START_DATE: new Date(),
        END_DATE: new Date(),
      });
      setPicker({
        START_DATE: new Date(),
        END_DATE: new Date(),
      });
    }
    setSearchText("");
    setTitle("");
    setHost("");
    setPage({
      ROW_COUNT: 10,
      PAGE_COUNT: 0,
    });
  };
  /**
   * 셀렉트 팝업 닫기 이벤트 추가용
   */
  const closeSelectBox = () => {
    setTypeBox(false);
  };
  /**
   * hover
   */
  const [tooltip, setTooltip] = useState(false);

  //그룹색상
  const groupColor = color => {
    switch (color) {
      case "red":
        return "#de8799";
      case "orange":
        return "#d78e71";
      case "yellow":
        return "#cdb14c";
      case "bean":
        return "#97C12A";
      case "green":
        return "#64d483";
      case "mint":
        return "#6cd6bf";
      case "pine":
        return "#75BDD9";
      case "pink":
        return "#cb86dd";
      case "purple":
        return "#a994e1";
      case "black":
        return "#3b3f4c";
      default:
        return "#2B4AC4";
    }
  };
  /**
   * 태그
   */
  return (
    <div
      style={{
        backgroundColor: "rgba(255,255,255,0.4)",
        height: "845px",
        marginTop: "23px",
        borderRadius: "12px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <ParticipantsHistoryPopup
          duration={duration}
          showHistory={showHistory}
          setShowHistory={setShowHistory}
          meetingKey={meetingKey}
          meetingInfo={meetingInfo}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "0 37px 13px 45px",
            paddingTop: "8px",
            width: "1330px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "56px",
            }}
          >
            <div
              style={{
                color:
                  REDUCER_STATE.GROUP_YN === "N" ||
                  REDUCER_STATE.GROUP_YN === ""
                    ? "#2B4AC4"
                    : "#3B3F4C",
                fontWeight: "bold",
                fontSize: "16px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div>{t("my.data.txt.subtitle.log")}</div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <img
                  src="/img/icon/schedule/tooltip_gr.png"
                  alt="tooltip"
                  style={{ marginLeft: "16px" }}
                  onMouseOver={() => setTooltip(true)}
                  onMouseOut={() => setTooltip(false)}
                />
                <div
                  className="data_uselog_tooltip"
                  style={{
                    display: tooltip ? "flex" : "none",
                  }}
                >
                  <article>
                    <div>참석자가 1명이라도 없는 회의는 표시되지 않습니다.</div>
                  </article>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              width: "542px",
              height: "56px",
              backgroundColor: "rgba(255,255,255,0.3)",
              borderRadius: "12px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              className="cursor"
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                color:
                  REDUCER_STATE.GROUP_YN === "N" ||
                  REDUCER_STATE.GROUP_YN === ""
                    ? "#2b4ac4"
                    : "#3B3F4C",
                width: "116px",
                height: "36px",
                backgroundColor: "#FFFFFF",
                border:
                  REDUCER_STATE.GROUP_YN === "N" ||
                  REDUCER_STATE.GROUP_YN === ""
                    ? "1px solid #2b4ac4"
                    : "1px solid #3B3F4C",
                borderRadius: "8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginRight: "8px",
              }}
              onClick={() => {
                if (typeBox) {
                  setTypeBox(false);
                } else {
                  setTypeBox(true);
                }
              }}
            >
              <div
                style={{
                  marginLeft: "16px",
                }}
              >
                {searchType === "TITLE"
                  ? t("my.data.txt.option.title")
                  : searchType === "DATE"
                  ? t("my.data.txt.option.date")
                  : t("my.data.txt.option.host")}
              </div>
              <img
                style={{
                  marginRight: "10px",
                }}
                src={
                  typeBox
                    ? REDUCER_STATE.GROUP_YN === "N" ||
                      REDUCER_STATE.GROUP_YN === ""
                      ? "/img/icon/log/up.png"
                      : "/img/icon/data/drop_up.png"
                    : REDUCER_STATE.GROUP_YN === "N" ||
                      REDUCER_STATE.GROUP_YN === ""
                    ? "/img/icon/log/down.png"
                    : "/img/icon/data/drop_down.png"
                }
                alt="V"
              />
              <div
                style={{
                  display: typeBox ? "block" : "none",
                  position: "absolute",
                  background: "white",
                  width: "168px",
                  textAlign: "center",
                  borderRadius: "4px",
                  inset: "0px auto auto 0px",
                  transform:
                    searchType === "DATE"
                      ? "translate(871px, 242px)"
                      : "translate(882px, 242px)",
                }}
              >
                <div
                  className={
                    REDUCER_STATE.GROUP_YN === "N" ||
                    REDUCER_STATE.GROUP_YN === ""
                      ? "cursor data_searchType_selectBoxItem"
                      : "cursor groupData_searchType_selectBoxItem"
                  }
                  style={{
                    borderTopLeftRadius: "4px",
                    borderTopRightRadius: "4px",
                  }}
                  onClick={() => {
                    if (searchType === "TITLE") {
                      setTypeBox(false);
                      resetParams("HOST");
                      setSearchType("HOST");
                    } else {
                      setTypeBox(false);
                      resetParams("TITLE");
                      setSearchType("TITLE");
                    }
                  }}
                >
                  {searchType === "TITLE"
                    ? t("my.data.txt.option.host")
                    : t("my.data.txt.option.title")}
                </div>
                <div
                  className={
                    REDUCER_STATE.GROUP_YN === "N" ||
                    REDUCER_STATE.GROUP_YN === ""
                      ? "cursor data_searchType_selectBoxItem"
                      : "cursor groupData_searchType_selectBoxItem"
                  }
                  style={{
                    borderBottomLeftRadius: "4px",
                    borderBottomRightRadius: "4px",
                  }}
                  onClick={() => {
                    if (searchType === "DATE") {
                      setTypeBox(false);
                      resetParams("HOST");
                      setSearchType("HOST");
                    } else {
                      setTypeBox(false);
                      resetParams("DATE");
                      setSearchType("DATE");
                    }
                  }}
                >
                  {searchType === "DATE"
                    ? t("my.data.txt.option.host")
                    : t("my.data.txt.option.date")}
                </div>
              </div>
            </div>

            <div>
              {searchType === "TITLE" ? (
                <input
                  style={{
                    width: "314px",
                    height: "36px",
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #EDEDF0",
                    borderRadius: "8px",
                    outline: "none",
                    fontSize: "14px",
                  }}
                  type="text"
                  value={searchText}
                  placeholder={t("my.data.txt.placeholder.title")}
                  onChange={e => {
                    if (e.key !== "Enter") {
                      setSearchText(e.target.value);
                    }

                    if (e.target.value === "") {
                      setTitle(e.target.value);
                    }
                  }}
                  onKeyDown={e => {
                    if (e.key === "Enter") {
                      setTitle(e.target.value);
                    }
                  }}
                />
              ) : searchType === "DATE" ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "8px",
                  }}
                >
                  <DatePickerker
                    setDate={setPicker}
                    date={picker}
                    type="START_DATE"
                  />
                  <font
                    style={{
                      fontSize: "14px",
                      color: "#3E3E4F",
                      margin: "0px 12px",
                    }}
                  >
                    ~
                  </font>
                  <DatePickerker
                    setDate={setPicker}
                    date={picker}
                    type="END_DATE"
                  />
                </div>
              ) : (
                <input
                  style={{
                    width: "314px",
                    height: "36px",
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #EDEDF0",
                    borderRadius: "8px",
                    outline: "none",
                    fontSize: "14px",
                  }}
                  type="text"
                  value={searchText}
                  placeholder={t("my.data.txt.placeholder.host")}
                  onChange={e => {
                    if (e.key !== "Enter") {
                      setSearchText(e.target.value);
                    }

                    if (e.target.value === "") {
                      setHost(e.target.value);
                    }
                  }}
                  onKeyDown={e => {
                    if (e.key === "Enter") {
                      setHost(e.target.value);
                    }
                  }}
                />
              )}
            </div>

            <div
              className="cursor"
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                color: "#FFFFFF",
                width: "64px",
                height: "36px",
                background:
                  REDUCER_STATE.GROUP_YN === "N" ||
                  REDUCER_STATE.GROUP_YN === ""
                    ? "#2B4AC4"
                    : "#3B3F4C",
                borderRadius: "8px",
                marginLeft: "8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => {
                if (searchType === "TITLE") {
                  setTitle(searchText);
                } else if (searchType === "DATE") {
                  setDate({
                    START_DATE: picker.START_DATE,
                    END_DATE: picker.END_DATE,
                  });
                } else if (searchType === "HOST") {
                  setHost(searchText);
                }
              }}
            >
              {t("common.btn.search")}
            </div>
          </div>
        </div>

        {loading ? (
          <div
            style={{
              height: "744px",
            }}
          >
            <Loading />
          </div>
        ) : (
          <div
            style={{
              height: "744px",
              width: "1330px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "0 37px",
            }}
          >
            <table
              style={{
                width: "100%",
                maxHeight: "680px",
                borderCollapse: "separate",
                borderSpacing: "0px 10px",
              }}
            >
              <colgroup>
                <col width="24px" />
                <col width="120px" />
                <col width="16px" />
                <col width="120px" />
                <col width="16px" />
                <col width="120px" />
                <col width="24px" />
                <col width="264px" />
                <col width="16px" />
                {REDUCER_STATE.GROUP_YN === "N" ? (
                  <>
                    <col width="168px" />
                    <col width="16px" />
                    <col width="144px" />
                  </>
                ) : (
                  <>
                    <col width="328px" />
                  </>
                )}
                <col width="16px" />
                <col width="104px" />
                <col width="80px" />
                <col width="58px" />
                <col width="24px" />
              </colgroup>

              <thead>
                <tr
                  style={{
                    backgroundColor: "#FFFFFF",
                    color:
                      REDUCER_STATE.GROUP_YN === "N" ||
                      REDUCER_STATE.GROUP_YN === ""
                        ? "#2B4AC4"
                        : "#3B3F4C",
                    fontWeight: "bold",
                    fontSize: "16px",
                    height: "56px",
                  }}
                >
                  <th
                    style={{
                      borderTopLeftRadius: "12px",
                      borderBottomLeftRadius: "12px",
                    }}
                  />
                  <th>{t("my.data.txt.label.startdate")}</th>
                  <th />
                  <th>{t("my.data.txt.label.enddate")}</th>
                  <th />
                  <th>{t("my.data.txt.label.duration")}</th>
                  <th />
                  <th
                    style={{
                      textAlign: "left",
                    }}
                  >
                    {t("my.data.txt.label.title")}
                  </th>
                  <th />
                  {REDUCER_STATE.GROUP_YN === "N" ? (
                    <>
                      <th
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {t("my.data.txt.label.groupname")}
                      </th>
                      <th />
                      <th>{t("my.data.txt.label.host")}</th>
                    </>
                  ) : (
                    <th>{t("my.data.txt.label.host")}</th>
                  )}
                  <th />
                  <th>{t("my.data.txt.label.participant")}</th>
                  <th />
                  <th />
                  <th
                    style={{
                      borderTopRightRadius: "12px",
                      borderBottomRightRadius: "12px",
                    }}
                  />
                </tr>
              </thead>

              <tbody>
                {log.length > 0 ? (
                  log.map((item, idx) => (
                    <tr
                      key={item.SCHEDULE_NUM + "_" + idx}
                      style={{
                        fontSize: "14px",
                        height: "52px",
                        textAlign: "center",
                        backgroundColor: "rgba(255,255,255,0.6)",
                        marginTop: "12px",
                      }}
                    >
                      <td
                        style={{
                          borderTopLeftRadius: "12px",
                          borderBottomLeftRadius: "12px",
                        }}
                      />
                      <td
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "76px",
                            height: "50px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: "13px",
                          }}
                        >
                          {dateFormat(item.START_TIME)}
                        </div>
                      </td>
                      <td />
                      <td
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "76px",
                            height: "50px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: "13px",
                          }}
                        >
                          {dateFormat(item.END_TIME)}
                        </div>
                      </td>
                      <td />
                      <td>{durationFormat(item.DURATION)}</td>
                      <td />
                      <td
                        style={{
                          textAlign: "left",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          maxWidth: "264px",
                        }}
                        title={item.TITLE}
                      >
                        {item.TITLE}
                      </td>
                      <td />
                      {REDUCER_STATE.GROUP_YN === "N" ? (
                        <>
                          <td
                            title={item.GROUP_NAME}
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              maxWidth: "168px",
                              textAlign: "left",
                              color:
                                item.GROUP_NAME === ""
                                  ? "#000000"
                                  : groupColor(item.GROUP_COLOR),
                            }}
                          >
                            {item.GROUP_NAME}
                          </td>
                          <td />
                          <td
                            title={item.HOST_NAME}
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              maxWidth: "144px",
                            }}
                          >
                            {item.HOST_NAME}
                          </td>
                        </>
                      ) : (
                        <td>{item.HOST_NAME}</td>
                      )}
                      <td />
                      <td>{item.PARTICIPANTS_COUNT}</td>
                      <td />
                      <td>
                        <div
                          className="cursor"
                          onClick={() => {
                            setDuration(item.DURATION);
                            setMeetingKey(item.MEETING_KEY);
                            setShowHistory(true);
                            setMeetingInfo(item);
                          }}
                          style={{
                            color: "#2B4AC4",
                            fontWeight: "bold",
                          }}
                        >
                          {t("my.data.btn.more")}
                        </div>
                      </td>
                      <td
                        style={{
                          borderTopRightRadius: "12px",
                          borderBottomRightRadius: "12px",
                        }}
                      />
                    </tr>
                  ))
                ) : (
                  <DataEmpty />
                )}
              </tbody>
            </table>

            <Paging page={page} setPage={setPage} total={total} />
          </div>
        )}
      </div>
    </div>
  );
}

export default DataUseLogList;
