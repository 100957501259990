import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import ConfirmAlert from "../../license/licenseConfirmAlert";
import { useTranslation } from "react-i18next";
/**
 * Version : 1.0
 * 파일명 : groupColorEditPopup.js
 * 작성일자 : 2021-11-29
 * 작성자 : 강연승
 * 설명 : 그룹프로필 - 그룹 카테고리 변경 팝업
 * 수정일자 : 2022-02-10
 * 수정자 : 강연승
 * 수정내역 : 텍스트 수정 영상회의 -> 화상회의, 웹폰트 변경
 */
function GroupCategoryEditPopup(props) {
  /**
   * body
   */
  ReactModal.setAppElement("body");
  const closePopup = () => {
    props.close("category");
    setSelectCategory(props.category);
  };

  /**
   * 언어변환
   */
  const { t } = useTranslation();

  /* 카테고리 선택 */
  const [selectCategory, setSelectCategory] = useState("");
  useEffect(() => {
    setSelectCategory(props.category);
  }, [props.category]);

  const clickCategory = select => {
    setSelectCategory(select);
  };

  const categoryList = [
    // { name: "default", code: "CD0401" },
    { name: "company", code: "CD0402" },
    { name: "school", code: "CD0403" },
    { name: "private_institute", code: "CD0404" },
    { name: "university", code: "CD0405" },
    { name: "social", code: "CD0406" },
  ];

  /* 그룹 카테고리 코드 변환 */
  const groupCategoryConvert = ctgryCD => {
    switch (ctgryCD) {
      // case "CD0401":
      //   return "default";
      case "CD0402":
        return "company";
      case "CD0403":
        return "school";
      case "CD0404":
        return "private_institute";
      case "CD0405":
        return "university";
      case "CD0406":
        return "social";
      default:
        return "company";
    }
  };

  const [confirmPopup, setConfirmPopup] = useState(false);
  const [confirmRequest, setConfirmRequest] = useState({});
  const closeConfirmPopup = () => {
    setConfirmPopup(false);
  };
  const confirmEditThema = () => {
    if (selectCategory === props.category) {
      closePopup();
      return;
    }
    setConfirmPopup(true);
    closePopup();
    const confirmParams = {
      className: "EditThemaAlert",
      btnNo: true,
      contents: (
        <div>
          <div
            style={{
              font: "normal normal normal 18px/24px Spoqa Han Sans Neo",
              color: "#3E3E4F",
            }}
          >
            테마를
            <font style={{ color: "#2B4AC4" }}>
              &nbsp;[
              {t("group.category." + groupCategoryConvert(selectCategory))}]
            </font>
            으로 수정하시겠습니까?
          </div>
          <div
            style={{
              font: "normal normal normal 18px/24px Spoqa Han Sans Neo",
              color: "#3E3E4F",
            }}
          >
            수정 사항은 모든 미팅호스트에게 반영됩니다.
          </div>
        </div>
      ),
      EventHandler: function () {
        props.editGroupInfo("category", selectCategory);
      },
    };
    setConfirmRequest(confirmParams);
  };
  return (
    <>
      <ConfirmAlert
        open={confirmPopup}
        close={closeConfirmPopup}
        request={confirmRequest}
      />
      <ReactModal
        isOpen={props.open} //모달 Open(Boolean)
        className="groupCategoryEditPopup" //모달 ClassName
        overlayClassName="groupCategoryEditPopup_Overlay" //모달오버레이 ClassName
        onRequestClose={closePopup}
        shouldCloseOnEsc={true}
        animationType="fade"
        transparent={true}
        closeTimeoutMS={200}
      >
        <div>
          <img
            src="/img/icon/group/close.png"
            alt="close"
            onClick={closePopup}
            style={{
              cursor: "pointer",
              float: "right",
              marginTop: "16px",
              marginRight: "16px",
            }}
          />
        </div>
        <div className="text-center" style={{ marginTop: "8px" }}>
          <div
            style={{
              color: "#2B4AC4",
              font: "normal normal bold 16px/21px Spoqa Han Sans Neo",
            }}
          >
            그룹 테마 변경
          </div>
          <div
            style={{
              color: "#3E3E4F",
              marginTop: "14px",
              font: "normal normal bold 14px/18px Spoqa Han Sans Neo",
            }}
          >
            변경할 그룹 테마를 선택해주세요.
          </div>
        </div>

        <div className="text-center" style={{ margin: "32px 0 16px" }}>
          {categoryList.map(category => (
            <div
              key={category.code}
              style={
                selectCategory === category.code
                  ? {
                      backgroundColor: "#EAEDF9",
                      color: "#2B4AC4",
                      padding: "11px 0",
                      cursor: "pointer",
                      font: "normal normal normal 14px/18px Spoqa Han Sans Neo",
                    }
                  : {
                      backgroundColor: "#ffffff",
                      color: "#3E3E4F",
                      padding: "11px 0",
                      cursor: "pointer",
                      font: "normal normal normal 14px/18px Spoqa Han Sans Neo",
                    }
              }
              onClick={() => clickCategory(category.code)}
            >
              {t("group.category." + category.name)}
              {selectCategory === category.code ? (
                <img
                  src="/img/icon/profile/change.png"
                  alt="change"
                  style={{
                    verticalAlign: "top",
                    position: "absolute",
                    right: "832px",
                  }}
                />
              ) : null}
            </div>
          ))}
        </div>

        <div className="text-center">
          <button
            style={{
              width: "64px",
              height: "36px",
              backgroundColor: "white",
              border: "none",
              color: "#2B4AC4",
              font: "normal normal bold 14px/18px Spoqa Han Sans Neo",
              cursor: "pointer",
              marginBottom: "18px",
            }}
            onClick={confirmEditThema}
          >
            확 인
          </button>
        </div>
      </ReactModal>
    </>
  );
}

export default GroupCategoryEditPopup;
