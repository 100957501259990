import React, { useState, useEffect, useCallback } from "react";
import DashboardPaging from "./dashboardPaging";
import Loading from "component/tag/loading/loading";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";

/**
 * Version : 1.0
 * 파일명 : ReserveTotal.js
 * 작성일자 : 2022-02-23
 * 작성자 : 강연승
 * 설명 : 대시보드 아이템 - 예약된 회의 리스트
 * 수정일자 : 2022-06-08
 * 수정자 : 강연승
 * 수정내역 : 페이징 수정
 */
function ResMeetingList() {
  /**
   * REDUCER
   */
  const REDUCER_STATE = useSelector(state => state.groupInfoReducer);
  /**
   * 언어변환
   */
  const { t } = useTranslation();
  /**
   * STATE
   */
  const [loading, setLoading] = useState(false);
  const [resListTotal, setResListTotal] = useState(0);
  const [resList, setResList] = useState([]);
  const [pagingState, setPagingState] = useState({
    ROW_COUNT: 5,
    PAGE_COUNT: 0,
    TOTAL: 0,
  });
  /**
   * 회의 리스트 조회
   */
  const getMeetingEndList = useCallback(() => {
    setLoading(true);
    let param = {};
    if (REDUCER_STATE.GROUP_YN === "Y") {
      param = {
        GROUP_YN: REDUCER_STATE.GROUP_YN,
        GROUP_NUM: REDUCER_STATE.GROUP_NUM,
        HISTORY: "N",
        CURRENTPAGE: pagingState.PAGE_COUNT,
        RECORDPERPAGE: pagingState.ROW_COUNT,
      };
    } else if (REDUCER_STATE.GROUP_YN === "N") {
      param = {
        GROUP_YN: REDUCER_STATE.GROUP_YN,
        HISTORY: "N",
        CURRENTPAGE: pagingState.PAGE_COUNT,
        RECORDPERPAGE: pagingState.ROW_COUNT,
      };
    }
    axios
      .get("/schedule/dashboard/meetingList", {
        params: param,
      })
      .then(res => {
        if (res.status === 200) {
          const totalCnt = res.data[0].TOTAL;
          const resultData = res.data[0].SCHEDULE;
          setResListTotal(totalCnt);
          setResList(resultData);
          const paging_obj = {
            ROW_COUNT: pagingState.ROW_COUNT,
            PAGE_COUNT: pagingState.PAGE_COUNT,
            TOTAL: totalCnt,
          };
          setPagingState(paging_obj);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [
    REDUCER_STATE.GROUP_NUM,
    REDUCER_STATE.GROUP_YN,
    pagingState.PAGE_COUNT,
    pagingState.ROW_COUNT,
  ]);
  /**
   * useEffect
   */
  useEffect(() => {
    getMeetingEndList();
  }, [getMeetingEndList]);

  const dateFormat = dateTime => {
    let tmp = dateTime.replaceAll("-", ".");
    tmp = tmp.replace("T", " ");
    tmp = tmp.slice(0, -3);
    return tmp;
  };
  return (
    <div className="dashboardResMeetingList">
      <div className="dashboard_item_header">
        <div>
          <img
            src="/img/icon/dashboard/resList.png"
            className="dashboardIcon"
          />
          <font className="title">
            {t(
              "group.dashboard.txt.meetingList.res.title." +
                REDUCER_STATE.GROUP_CATEGORY
            )}
          </font>
        </div>
        <font className="totalCnt">
          {t("group.dashboard.txt.meetingList.total", {
            TOTAL_CNT: resListTotal,
          })}
        </font>
      </div>
      {loading ? (
        <Loading />
      ) : resListTotal === 0 ? (
        <div className="noMeetingList">
          {t(
            "group.dashboard.txt.meetingList.res.empty." +
              REDUCER_STATE.GROUP_CATEGORY
          )}
        </div>
      ) : (
        <div className="meetingList">
          <table className="meetingList_table">
            <colgroup>
              <col width="140px" />
              <col width="140px" />
              <col width="228px" />
              <col width="82px" />
            </colgroup>
            <thead>
              <tr>
                <th>{t("group.dashboard.txt.meetingList.table.start")}</th>
                <th>{t("group.dashboard.txt.meetingList.table.end")}</th>
                <th>{t("group.dashboard.txt.meetingList.table.title")}</th>
                <th>{t("group.dashboard.txt.meetingList.table.host")}</th>
              </tr>
            </thead>
            <tbody>
              {resList.map((data, index) => (
                <tr key={index}>
                  <td>{dateFormat(data.START_TIME)}</td>
                  <td>{dateFormat(data.END_TIME)}</td>
                  <td className="td_title" title={data.TITLE}>
                    {data.TITLE}
                  </td>
                  <td className="td_hostNm" title={data.HOST_NM}>
                    {data.HOST_NM}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div
        style={{
          height: "15px",
          display: loading || resListTotal === 0 ? "none" : "flex",
          alignItems: "center",
        }}
      >
        <DashboardPaging
          pagingState={pagingState}
          setPagingState={setPagingState}
        />
      </div>
    </div>
  );
}

export default ResMeetingList;
