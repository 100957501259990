import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import Cookies from "universal-cookie";
import DeleteAccountPopup from "component/tag/popup/account/deleteAccountPopup";
/**
 * Version : 1.0
 * 파일명 : deleteAccount.js
 * 작성일자 : 2021-11-29
 * 작성자 : 권도훈
 * 설명 : 회원 탈퇴 처리 페이지
 * 수정일자 : 2022-01-07
 * 수정자 : 권도훈
 * 수정내역 : UI 수정
 */
function DeleteAccount() {
  /**
   * 설정
   */
  const location = useLocation();
  const history = useHistory();
  /**
   * 사용자 정봄
   */
  const [userInfo, setUserInfo] = useState({
    NAME: "",
    EMAIL: "",
    TYPE: "",
    NUM: "",
  });
  /**
   * 동의 여부
   */
  const [isAgree, setIsAgree] = useState(false);
  /**
   * 설문조사 팝업
   */
  const [isPopup, setIsPopup] = useState(false);
  /**
   * 로그인 체크
   */
  useEffect(() => {
    const cookies = new Cookies();

    if (location.state !== undefined) {
      setUserInfo({
        NAME: location.state.NAME,
        EMAIL: location.state.EMAIL,
        TYPE: location.state.TYPE,
      });
    } else {
      //cookie
      const domain = process.env.REACT_APP_COOKIE_DOMAIN;
      const refreshNm = process.env.REACT_APP_COOKIE_REFRESH;
      const authNm = process.env.REACT_APP_COOKIE_AUTH;
      const loginTypeNm =
        new URL(window.location.href).origin === "http://localhost:8080" ||
          new URL(window.location.href).host === "dev.pebbling.co.kr"
          ? "SoBridge_Login_Type_dev"
          : "SoBridge_Login_Type";

      cookies.remove(loginTypeNm, {
        path: "/",
        domain: domain,
      });

      cookies.remove(authNm, {
        path: "/",
        domain: domain,
      });

      cookies.remove(refreshNm, {
        path: "/",
        domain: domain,
      });

      history.push({
        pathname: "/login",
        state: {
          tag: "block",
          type: "delete",
        },
      });
    }
  }, [history, location.state]);
  /**
   * 태그
   */
  return (
    <div
      style={{
        background: "#FCF7F8",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <div
        style={{
          width: "80%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <DeleteAccountPopup isPopup={isPopup} userInfo={userInfo} />

          <div
            style={{
              textAlign: "center",
              // marginTop: "60px",
              marginBottom: "40px",
            }}
          >
            <div
              style={{
                color: "#2B4AC4",
                fontSize: "32px",
                fontWeight: "bold",
                marginBottom: "24px",
              }}
            >
              탈퇴 안내
            </div>
            <div
              style={{
                color: "#3E3E4F",
                fontSize: "20px",
                fontWeight: "bold",
              }}
            >
              회원 탈퇴를 신청하기 전에 안내 사항을 꼭 확인해주세요.
            </div>
          </div>

          <div
            style={{
              width: "784px",
              height: "96px",
              background: "#DBE0F5",
              borderRadius: "16px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              marginBottom: "8px",
              color: "#3E3E4F",
              fontWeight: "bold",
            }}
          >
            <div
              style={{
                fontSize: "16px",
                marginBottom: "8px",
                marginLeft: "20px",
              }}
            >
              ● 사용하고 계신 아이디(
              <font style={{ color: "#2B4AC4" }}>{userInfo.EMAIL}</font>)는
              탈퇴할 경우 재사용 및 복구가 불가능합니다.
            </div>
            <div
              style={{
                fontSize: "14px",
                marginLeft: "35px",
              }}
            >
              탈퇴한 아이디는 본인과 타인 모두 재사용 및 복구가 불가하오니
              신중하게 선택하시기 바랍니다.
            </div>
          </div>

          <div
            style={{
              width: "784px",
              height: "396px",
              background: "#DBE0F5",
              borderRadius: "16px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                color: "#3E3E4F",
                fontWeight: "bold",
                fontSize: "14px",
                margin: "24px 24px 0 24px",
                width: "700px",
              }}
            >
              <div
                style={{
                  fontSize: "16px",
                  marginBottom: "8px",
                }}
              >
                ● 탈퇴 후 회원정보 및 서비스 이용기록은 모두 삭제됩니다.
              </div>
              <div>
                - 삭제되는 내용을 확인하시고 필요한 데이터는 미리 백업해두시기
                바랍니다.
              </div>
              <div>
                - 거래정보가 있는 경우, 전자상거래 등에서의 소비자 보호에 관한
                법률에 따라 계약 또는 청약 철회에 관한 기록, 대금결제 및 재화
                등의 공급에 관한 기록은 5년동안 보존됩니다.
              </div>
              <div>
                - 보유하셨던 SoBridge 포인트는 탈퇴와 함께 삭제되며 환불되지
                않습니다.
              </div>
              <div>- 이미 결제가 완료된 건은 탈퇴로 취소되지 않습니다.</div>
            </div>

            <table
              style={{
                width: "496px",
                height: "200px",
                margin: "0 24px 24px 24px",
                color: "#6B6B78",
                fontSize: "14px",
                fontWeight: "bold",
                borderCollapse: "separate",
                borderSpacing: "0px 1px",
              }}
            >
              <colgroup>
                <col width="153px" />
                <col width="343px" />
              </colgroup>

              <tbody>
                <tr>
                  <td
                    style={{
                      borderTop: "1px solid #A3B1E5",
                      borderBottom: "1px solid #A3B1E5",
                      borderRight: "1px solid #A3B1E5",
                    }}
                  >
                    회의 정보
                  </td>
                  <td
                    style={{
                      borderTop: "1px solid #A3B1E5",
                      borderBottom: "1px solid #A3B1E5",
                    }}
                  >
                    예약된 회의 및 내역 삭제
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      borderBottom: "1px solid #A3B1E5",
                      borderRight: "1px solid #A3B1E5",
                    }}
                  >
                    주소록
                  </td>
                  <td
                    style={{
                      borderBottom: "1px solid #A3B1E5",
                    }}
                  >
                    등록되어있는 명단 삭제
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      borderBottom: "1px solid #A3B1E5",
                      borderRight: "1px solid #A3B1E5",
                    }}
                  >
                    녹화파일
                  </td>
                  <td
                    style={{
                      borderBottom: "1px solid #A3B1E5",
                    }}
                  >
                    기록해두었던 파일 삭제
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      borderBottom: "1px solid #A3B1E5",
                      borderRight: "1px solid #A3B1E5",
                    }}
                  >
                    통계&로그
                  </td>
                  <td
                    style={{
                      borderBottom: "1px solid #A3B1E5",
                    }}
                  >
                    데이터 삭제
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            style={{
              width: "740px",
              display: "flex",
              justifyContent: "space-between",
              marginTop: "24px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <input
                className="cursor"
                type="checkbox"
                onClick={() => setIsAgree(!isAgree)}
              />
              <div
                style={{
                  color: "#2B4AC4",
                  fontSize: "14px",
                  fontWeight: "bold",
                  marginLeft: "8px",
                }}
              >
                안내 사항을 모두 확인하였으며, 이에 동의합니다.
              </div>
            </div>

            <div>
              <button
                className="cursor"
                style={{
                  width: "64px",
                  height: "40px",
                  background: "#C63353",
                  borderRadius: "8px",
                  color: "#FFFFFF",
                  fontSize: "14px",
                  fontWeight: "bold",
                  outline: "none",
                  border: "none",
                  marginRight: "16px",
                }}
                onClick={() => {
                  window.location.href = "/";
                }}
              >
                취소
              </button>

              <button
                className="cursor common_ok_btn"
                style={{
                  width: "64px",
                  height: "40px",
                  borderRadius: "8px",
                  fontSize: "14px",
                  fontWeight: "bold",
                  outline: "none",
                  border: "none",
                }}
                disabled={!isAgree}
                onClick={() => {
                  setIsPopup(true);
                }}
              >
                확인
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteAccount;
