import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
/**
 * Version : 1.0
 * 파일명 : addressEmpty.js
 * 작성일자 : 2021-11-03
 * 작성자 : 권도훈
 * 설명 : 주소록 LIST 타입
 * 수정일자 : 2022-02-09
 * 수정자 : 강연승
 * 수정내역 : 연락처 그룹 테마별 텍스트 적용
 */
function AddressEmpty() {
  /**
   * 언어변환
   */
  const { t } = useTranslation();
  /**
   * REDUCER
   */
  const REDUCER_STATE = useSelector(state => state.groupInfoReducer);
  return (
    <tr>
      <td>
        <div
          className="flex-y-center"
          style={{
            height: "655px",
            width: "1360px",
            position: "absolute",
          }}
        >
          <img src="/img/icon/landing/no_data.png" alt="NO SERVICE" />
          <div>
            {REDUCER_STATE.GROUP_YN === "N"
              ? t("my.address.txt.empty.CD0401")
              : t("my.address.txt.empty." + REDUCER_STATE.GROUP_CATEGORY)}
          </div>
        </div>
      </td>
    </tr>
  );
}

export default AddressEmpty;
