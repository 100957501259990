import React, { useEffect, useState } from "react";
import "css/support.css";
import axios from "axios";
import SupportPopup from "component/tag/popup/support/supportPopup";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openAlert, closeAlert } from "actions";
/**
 * Version : 1.0
 * 파일명 :
 * 작성일자 : 2021-10-05
 * 작성자 : 권도훈
 * 설명 :
 * 수정일자 : 2022-06-13
 * 수정자 : 강연승
 * 수정내역 : sobridge 데모 - 고객센터 정보 수정
 * */
function Support() {
  const [phoneNumber, setphoneNumber] = useState("");
  const [senderEmail, setsenderEmail] = useState("");
  const [content, setContent] = useState("");
  const [checkbox, setcheckbox] = useState("");

  let today = new Date();

  /*
    전화번호 입력 검증
    */
  const regex = /^[0-9\b -]{0,13}$/;
  const regextest = /^[0-9\b -]{9,13}$/;
  //const regex = /^\d{2,3}-\d{3,4}-\d{4}$/;
  /*
    이메일 입력 검증
    */
  const regEmail =
    /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;

  const history = useHistory();
  const dispatch = useDispatch();

  var changev = content;
  let contentcv = changev.replaceAll(/(?:\r\n|\r|\n)/g, "<br />");

  /*
    약관 동의 체크박스
    */
  const handleCheckbox = (checked, id) => {
    if (checked) {
      setcheckbox(...checkbox, id);
    } else {
      setcheckbox("");
    }
  };

  const handlePhoneNumber = e => {
    if (regex.test(e.target.value)) {
      setphoneNumber(e.target.value);
    }
  };
  /*
    전화번호 입력 양식
    */
  // useEffect(() => {

  //     if (phoneNumber.length === 4) {
  //         setphoneNumber(phoneNumber.replace(/-/g, '').replace(/(\d{3})(\d{1})/, '$1-$2'));
  //       }
  //     if (phoneNumber.length === 10) {
  //         setphoneNumber(phoneNumber.replace(/-/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'));
  //       }
  //       if (phoneNumber.length === 13) {
  //         setphoneNumber(phoneNumber.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'));
  //       }
  //   }, [phoneNumber]);

  const handleSenderEmail = e => {
    //    if(regEmail.test(e.target.value)){
    setsenderEmail(e.target.value);
    // }
  };
  const handleContent = e => {
    setContent(e.target.value);
  };

  /*
    전송 버튼
    */
  const onClickSupport = (phoneNumber, senderEmail) => {
    if (regextest.test(phoneNumber) === true) {
      if (senderEmail !== "") {
        if (regEmail.test(senderEmail) === true) {
          if (content !== "") {
            if (checkbox !== "") {
              let config = {
                params: {
                  PHONE_NUM: phoneNumber,
                  EMAIL_ADDRESS: senderEmail,
                  SUPPORT_CONTENT: contentcv,
                },
              };
              console.log(config);
              axios.post("/support/gusetsup", null, config).then(res => {
                if (res.status === 200) {
                }
              });
              openModal();
            } else {
              const obj = {
                TEXT: "개인 정보 수집 및 이용 동의를 체크해주세요",
                submitEventHandler: () => {
                  dispatch(closeAlert());
                },
              };
              dispatch(openAlert(obj));
            }
          } else {
            const obj = {
              TEXT: "문의 내용을 입력해주세요",
              submitEventHandler: () => {
                dispatch(closeAlert());
              },
            };
            dispatch(openAlert(obj));
          }
        } else {
          const obj = {
            TEXT: "이메일 양식이 올바르지 않습니다",
            submitEventHandler: () => {
              dispatch(closeAlert());
            },
          };
          dispatch(openAlert(obj));
        }
      } else {
        const obj = {
          TEXT: "이메일 주소를 입력해 주세요.",
          submitEventHandler: () => {
            dispatch(closeAlert());
          },
        };
        dispatch(openAlert(obj));
      }
    } else {
      const obj = {
        TEXT: "전화번호를 입력해주세요",
        submitEventHandler: () => {
          dispatch(closeAlert());
        },
      };
      dispatch(openAlert(obj));
    }
  };

  /*
    취소버튼
    */
  const onClickCancel = () => {
    history.push("");
  };
  /*
    모달 창 
    */
  const [showModal, setShowModal] = useState(false);
  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  /*
    블로그 이동
    */
  const goblog = () => {
    window.open("https://blog.naver.com/rivsw", "_blank");
  };
  const goyoutube = () => {
    window.open(
      "https://www.youtube.com/channel/UCZrfMX-OAHNaEkqM_6eY_nA",
      "_blank"
    );
  };

  const gohome = () => {
    history.push("");
  };

  return (
    <div
      className="supportbackground"
      style={{
        backgroundImage: "url(/img/supbackground.png",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "1903px",
        height: "1080px",
        zIndex: 1,
      }}
    >
      <section className="supportheader">
        <img
          className="supportpebblemark cursor"
          src="/img/logo.png"
          alt="logo_title"
          onClick={gohome}
        />
      </section>

      <div className="supportbody">
        <div
          className="imageintroduce"
          style={{
            backgroundImage: "url(/img/supbackground2.jpg",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            width: "681px",
            height: "890px",
            zIndex: 10,
          }}
        >
          <div className="spttitle">고객센터</div>
          <div className="sptmid-1">
            <div className="tel-textbox">
              <span className="tel-text">전화문의</span>
            </div>
            <div className="tex-number">
              <span className="telnumber">02-760-5057</span>
            </div>
          </div>
          <div className="sptmid-2">
            <span className="---1000-1800-1200-1300">
              월 - 금&nbsp;:&nbsp;
              <span className="text-style-1">09:00&nbsp;~&nbsp;17:00</span>
              &nbsp;
              <span className="text-style-2">|</span>&nbsp; 토/일/공휴일은 휴무
              <div>
                점심시간&nbsp;:&nbsp;
                <span className="text-style-3">12:00&nbsp;~&nbsp;13:00</span>
              </div>
            </span>
          </div>

          {/* <div className="link-three">
            <div
              className="ytlink cursor"
              // onClick={goyoutube}
            >
              <img
                className="sociallink1"
                src="/img/youtube.png"
                alt="유튜브링크"
              ></img>
            </div>
            <div
              className="nblink cursor"
              // onClick={goblog}
            >
              <img
                className="sociallink"
                src="/img/blog.png"
                alt="블로그링크"
              ></img>
            </div>
            <div className="iglink">
              <img
                className="sociallink"
                src="/img/instargram.png"
                alt="인스타그램링크"
              ></img>
            </div>
          </div> */}
        </div>

        <section
          className="inputtext"
          style={{
            backgroundColor: "white",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            width: "681px",
            height: "890px",
            zIndex: 10,
          }}
        >
          <div className="texttitle">
            SoBridge에 궁금하신 점을 친절히 안내해 드리겠습니다.
          </div>
          <div className="shape-2"></div>
          <div>
            <div className="textcategory">
              {" "}
              연락처 <div className="textblue">(필수)</div>{" "}
            </div>
            <input
              className="textinput1"
              type="text"
              placeholder="010-1234-1234"
              value={phoneNumber}
              onChange={handlePhoneNumber}
              pattern="[0-9]{3}-[0-9]{4}-[0-9]{4}"
            ></input>
          </div>

          <div>
            <div className="textcategory">
              {" "}
              이메일 <div className="textblue">(필수)</div>{" "}
            </div>
            <input
              className="textinput1"
              type="text"
              placeholder="abc@pebble.co.kr"
              onChange={handleSenderEmail}
            ></input>
          </div>

          <div>
            <div className="textcategory">
              {" "}
              문의내용 <div className="textblue">(필수)</div>{" "}
            </div>

            <textarea
              className="textinput2"
              placeholder="오류,개선 등"
              onChange={handleContent}
            ></textarea>
          </div>

          <div className="checkboxbox">
            <input
              className="checkbox cursor"
              type="checkbox"
              id="1"
              onChange={e => {
                handleCheckbox(e.currentTarget.checked, "1");
              }}
              checked={checkbox.includes("1") ? true : false}
            ></input>
            <div className="checkboxtext">
              {" "}
              개인 정보 수집 및 이용에 동의합니다.
            </div>
          </div>

          <div className="buttonbox">
            <div
              className="cancelbutton cursor"
              onClick={() => onClickCancel()}
            >
              취소
            </div>
            <div
              className="sendbutton cursor"
              onClick={() => onClickSupport(phoneNumber, senderEmail)}
            >
              보내기
            </div>
          </div>
          <SupportPopup open={showModal} close={closeModal}></SupportPopup>
        </section>
      </div>
    </div>
  );
}

export default Support;
