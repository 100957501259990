import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { openAlert, closeAlert, openConfirm } from "actions/index";
import { useTranslation } from "react-i18next";
import axios from "axios";
/**
 * Version : 1.0
 * 파일명 : participantList.js
 * 작성일자 : 2021-10-05
 * 작성자 : 강연승
 * 설명 : 회의 예약 시 입력된 참석자 리스트
 * 수정일자 : 2022-04-27
 * 수정자 : 권도훈
 * 수정내역 : 연락처 추가 버튼
 */
function Participant({ participant, onRemove }) {
  const { t } = useTranslation();
  const GROUP_STATE = useSelector(state => state.groupInfoReducer);
  /**
   * dispatch
   */
  const dispatch = useDispatch();
  //그룹 카테고리
  const groupCategoryConvert = ctgryCD => {
    switch (ctgryCD) {
      case "CD0401":
        return "company";
      case "CD0402":
        return "company";
      case "CD0403":
        return "school";
      case "CD0404":
        return "private_institute";
      case "CD0405":
        return "university";
      case "CD0406":
        return "social";
      default:
        return "company";
    }
  };
  //연락처 저장
  const saveAddress = email => {
    const config = {
      params: {
        Group_Num: GROUP_STATE.GROUP_NUM,
        Adr_Email: email,
      },
    };

    axios
      .post("/address/schedule", null, config)
      .then(res => {
        if (res.status === 200) {
          const obj = {
            TEXT: "등록되었습니다.",
            submitEventHandler: () => dispatch(closeAlert()),
          };
          dispatch(openAlert(obj));
        }
      })
      .catch(() => {
        const obj = {
          TEXT: "오류가 발생했습니다.",
          submitEventHandler: () => dispatch(closeAlert()),
        };
        dispatch(openAlert(obj));
      });
  };
  //클릭 이벤트 핸들러
  const clickEventHandler = email => {
    const obj = {
      TEXT: (
        <>
          <div>해당 참석자를</div>
          <div>
            {t(
              "group.thema." +
                groupCategoryConvert(GROUP_STATE.GROUP_CATEGORY) +
                ".address"
            )}
            에 등록하시겠습니까?
          </div>
        </>
      ),
      submitEventHandler: () => {
        // deletePayment();
        // props.setType("ADD_PAYMENT");
        // props.setIsRender(true);
        dispatch(closeAlert());
        saveAddress(email);
      },
    };
    dispatch(openConfirm(obj));
  };

  return (
    <li
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "8px 0",
      }}
    >
      <div className="participantEmail" title={participant.PRTCP_EMAIL}>
        {participant.PRTCP_EMAIL}
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          className="cursor"
          style={{
            marginRight: "8px",
            color: "#8b9ddf",
            fontSize: "13px",
            fontWeight: "500",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => clickEventHandler(participant.PRTCP_EMAIL)}
        >
          <img
            src="/img/icon/schedule/address_add.png"
            alt=""
            style={{
              marginRight: "4px",
            }}
          />
          {t(
            "group.thema." +
              groupCategoryConvert(GROUP_STATE.GROUP_CATEGORY) +
              ".address"
          )}{" "}
          추가
        </div>

        <img
          className="cursor"
          src="/img/icon/schedule/remove.png"
          alt="remove"
          // style={{ marginRight: "8px" }}
          onClick={() => onRemove(participant.PRTCP_EMAIL)}
        />
      </div>
    </li>
  );
}

function ParticipantList({ className, participants, length, onRemove }) {
  return (
    <div className={className}>
      {length > 0 ? (
        <div
          style={{
            font: "normal normal bold 14px/24px Spoqa Han Sans Neo",
            color: "#2B4AC4",
            marginTop: "11px",
            marginBottom: "5px",
          }}
        >
          참석자 {length}명
        </div>
      ) : null}
      <ul>
        {participants.map(participant => (
          <Participant
            participant={participant}
            key={participant.PRTCP_EMAIL}
            onRemove={onRemove}
          />
        ))}
      </ul>
    </div>
  );
}

export default ParticipantList;
