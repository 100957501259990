import React from "react";
import ReactModal from "react-modal";
/**
 * Version : 1.0
 * 파일명 : loginFailPopup.js
 * 작성일자 : 2021-11-30
 * 작성자 : 권도훈
 * 설명 : 로그인 실패 팝업
 * 수정일자 : 2022-02-10
 * 수정자 : 강연승
 * 수정내역 : 텍스트 수정 영상회의 -> 화상회의, 웹폰트 변경
 */
function LoginFailPopup(props) {
  /**
   * body
   */
  ReactModal.setAppElement("body");
  /**
   * 회원가입 페이지 이동
   */
  const goMakeAccount = () => {
    window.location.href = "/authMail";
  };
  /**
   * 팝업 닫기
   */
  const closePopup = () => {
    props.setIsFail(false);
  };
  /**
   * 태그
   */
  return (
    <ReactModal
      isOpen={props.isFail} //모달 Open(Boolean)
      className="LoginFailAlert" //모달 ClassName
      overlayClassName="AlertOverlay" //모달오버레이 ClassName
      animationType="fade"
      transparent={true}
    >
      <div>
        <div className="loginFail_closeBtn cursor">
          <img
            src="/img/icon/landing/close.png"
            alt="X"
            onClick={() => closePopup()}
          />
        </div>

        <div className="loginFail_content">
          <p className="loginFail_title">회원 정보를 찾지 못 했습니다.</p>

          <img
            className="loginFail_errorIMG"
            src="/img/icon/landing/error.png"
            alt="ERROR"
          />

          <p className="loginFail_coment">
            <div>빠르고 간편하게 가입하셔서</div>
            <div>최상의 화상회의 플랫폼 SoBridge를 경험해보세요!</div>
          </p>

          <button
            className="loginFail_makeAccountBtn cursor"
            onClick={() => goMakeAccount()}
          >
            회원가입 하러 가기
          </button>

          <div className="loginFail_balloon">
            <img src="/img/icon/landing/coupon.png" alt="C" />

            <p>
              <div>최초 회원가입 시</div>
              <div>30일 기한 무료 회의이용권 제공!</div>
            </p>
          </div>
        </div>
      </div>
    </ReactModal>
  );
}

export default LoginFailPopup;
