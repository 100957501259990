/**
 * Import List
 */
import React, { useState, useEffect } from "react";
import GetCode from "./getCode";
import CheckCode from "./checkCode";
import { useLocation } from "react-router";
/**
 * Version : 1.0
 * 파일명 : authMail.js
 * 작성일자 : 2021-09-30
 * 작성자 : 권도훈
 * 설명 : 인증코드 메일 전송 및 인증코드 체크
 * 수정일자 : 2022-02-09
 * 수정자 : 권도훈
 * 수정내역 : UI 수정
 */
function AuthMail() {
  const [state, setState] = useState(false);
  const [mail, setMail] = useState("");
  const [groupInviteCode, setGroupInviteCode] = useState("");
  /**
   * useLocation
   */
  const location = useLocation();
  useEffect(() => {
    if (location.state !== undefined) {
      if (
        location.state.code !== undefined &&
        location.state.type === "inviteGroup"
      ) {
        setGroupInviteCode(location.state.code);
      }
    }
  }, [location]);

  return (
    <div>
      {
        state
          ?
          <CheckCode
            state={state}
            mail={mail}
            groupInviteCode={groupInviteCode}
          />
          :
          <GetCode
            setState={setState}
            setMail={setMail}
            mail={mail}
            groupInviteCode={groupInviteCode}
          />
      }
    </div>
  );
}

export default AuthMail;
