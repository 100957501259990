/**
 * Version : 1.0
 * 작성일자 : 2022-01-14
 * 작성자 : 권도훈
 * 설명 : 사용자 카드 정보
 */
const initalState = [];

const userCardInfo = (state = initalState, action) => {
    switch (action.type) {
        case "userCardInfo/UPDATE":
            return action.DATA;
        default:
            return state;
    }
};

export default userCardInfo;
