import axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import "css/profilefaq.css";
import { useDispatch, useSelector } from 'react-redux';
import { updatePagingsupInfo } from 'actions';
import Paging from "component/tag/div/common/supportPaging";
/**
 * Version : 1.0
 * 파일명 : supfaq.js
 * 작성일자 : 2022-03-30
 * 작성자 : 정지윤
 * 설명 : 고객센터 FAQ
 * * 수정일자 : 2022-04-14
 * 수정자 : 정지윤
 * 수정내역 : FAQ 파일첨부 정보 수정
*/




function Supfaq() {
  
  let server_url = "";
  let change = '';

  if (process.env.NODE_ENV === "development") {
    server_url =
      process.env.REACT_APP_SERVER_URL + process.env.REACT_APP_SERVER_PORT;
  } else {
    server_url = process.env.REACT_APP_SERVER_URL;
  }


  const [inputkeyword, setinputkeyword] = useState('');
  const [selectcategory, setselectcategory] = useState('');

  const handleInputkeyword = (e) =>{
    setinputkeyword(e.target.value);
  }
  const handleInputcategory = (e) =>{
    setselectcategory(e.target.value);
  }

  const PAGING_STATE = useSelector(state => state.pagingsupInfoReducer);

  const dispatch = useDispatch();


  const [FAQlist, setFAQList] = useState([]);
  const getFAQList = useCallback((inputkeyword,selectcategory) => {
    let category = '';
    if(selectcategory !== undefined&&selectcategory !== ''){
        category = selectcategory;
    }else{
        category=null;
    }
   

    let config={
        params:{
          content : inputkeyword,
          category : category,
          skip : PAGING_STATE.PAGE_COUNT,
          amount : PAGING_STATE.ROW_COUNT,
        }
   }
axios.get("/support/FAQList", config).then((res) => {
    if(res.status ===200){
      setFAQList(res.data);
    }
   })

},[PAGING_STATE.PAGE_COUNT,PAGING_STATE.ROW_COUNT])

useEffect(() =>{
  getFAQList(inputkeyword,selectcategory)
},[getFAQList])

const [FAQtotal, setFAQtotal] = useState('');

const countFAQ = useCallback((inputkeyword,selectcategory)=>{


  let config ={
    params : {
      content : inputkeyword,
      Notice_Type : selectcategory,
    }
  }

  axios.get("/support/countFAQ",config)
  .then((res)=>
  {
    if(res.status===200){
      setFAQtotal(res.data)
      const paging_obj = {
        ROW_COUNT: PAGING_STATE.ROW_COUNT,
        PAGE_COUNT: PAGING_STATE.PAGE_COUNT,
        TOTAL: res.data
      };
      dispatch(updatePagingsupInfo(paging_obj));
    }
  })

},[PAGING_STATE.PAGE_COUNT,PAGING_STATE.ROW_COUNT, dispatch]);


useEffect(()=>{
  countFAQ(inputkeyword,selectcategory);
},[countFAQ])



const [getfiles, setgetfiles] = useState([]);

const [opennumber, setopennumber] = useState('');

const onClickbutton = (value, FAQnum) =>{

  let config={
    params : {
      notice_num : FAQnum
    }
  }
  axios.get("/support/getFAQ", config).then((res)=>{
    if(res.status===200){
      setgetfiles(res.data.FAQfiles)
      setopennumber('')
      setopennumber(value)
    }
  })


axios.put("/support/updateclickcount",null,config).then((res)=>{
  if(res.status===200){
  }
});




}

const onClickbuttonClose = (value) =>{
if(value === opennumber){
  setopennumber('');
}
}

const onclicksearch = (inputkeyword,selectcategory) =>{
  getFAQList(inputkeyword,selectcategory);
countFAQ(inputkeyword,selectcategory);
}
const  convertHTML = (value)=>{

  return <div dangerouslySetInnerHTML={ {__html: value} }>
  </div>
}




  return (
    <div>
        {FAQlist.length >0 ?
         <div className='profile_faq_main'>
            
         <div className='FAQ_Search'>
         <div>
         {/* <select className='FAQ_Select_Category' onChange={handleInputcategory} value={selectcategory}>
             <option value=''>문의 항목</option>
             <option value='CD1302'>결제</option>
               <option value='CD1303'>예약</option>
               <option value='CD1304'>가입/탈퇴</option>
         </select> */}
         </div>

         <div className='FAQ_Searchbar'>
<input type='text' className='FAQ_Searchbar_input' placeholder='자주 묻는 질문 검색' value={inputkeyword} onChange={handleInputkeyword} />
<img src='/img/searchglasses.png'  className='cursor'alt='검색' onClick={() => onclicksearch(inputkeyword, selectcategory)}></img>
         </div>

         </div>
         
         <div>
         {FAQlist.map((data, index) =>(
                           <div key={index} className= 'faq_main'>
                               <div className='faq_body_title' id={index}>
                               {
                                  opennumber === (index) 
                                  ? <div className='faq_body_title_noclick opensrr'>

                                    <div className='faq_body_title_onclick_header cursor' onClick={()=>onClickbuttonClose(index)}>
                                     
                                    <div style={{ display : "flex"}}><img src='/img/questionmarkcolor.png' alt='questionmark'/>
                                    <div className='faq_body_title_text'>{data.Notice_Title}</div>
                                    </div>
                                     <div className='dttm_img'><div><img  className='imgcursorfaq'src="/img/cursorup.png" alt='cursor'></img></div></div>
                                   </div>

                                   <div className='faq_body_line'></div>
                                  
                                   <div className='faq_body_content_big'>
                                    <div className='faq_body_content'>
                                    <img src='/img/answermarkcolor.png'  className='FAQ_answermark'alt='questionmark'/>
                                    <div className='faq_body_content_text'>
                                    {convertHTML(data.Notice_Content)}
                                    </div>

                                    </div>
                                    <div className='faq_body_files' >
                                 

                                      <div>
                                        {getfiles.length === 0? <div ></div>:<div style={{display : "flex"}}>  
                                           <div  className='faq_file_title'style={{
                                      marginRight : "20px"
                                     
                                    }}>첨부파일</div><div  className='faq_body_filedownload'style={{
             
                           }}>
                                         
                                        {getfiles.map((data) => (
                                         <div key={data.FILE_NUM}  
                                         
                                         
                                         className='myquestion_file_txt cursor'
                                       
                                         onClick={() =>
                                          window.open(
                                            server_url +
                                              "/file/downloadFile?filename=" +
                                              data.FILE_NM +
                                              "&downfilename=" +
                                              data.ORI_FILE_NM,
                                            "_blank"
                                          )
                                        }>
                                            {data.ORI_FILE_NM}
                                        </div>

                                       )
                                       )}</div></div>
                                        }
                                       
                                         </div>
                                    </div>
                                    </div>
                                  </div>

                                 : 
                                  <div className='faq_body_title_noclick cursor' onClick={()=>onClickbutton(index, data.Notice_Num)}>
                                    <div className='faq_body_title_noclick_header'>
                                       <div style={{ display : "flex"}}><img src='/img/questionmark.png' alt='questionmark'/>
                                       <div  className='faq_body_title_text1'>{data.Notice_Title}</div>
                                       </div>
                                       
                                       <div className='dttm_img'><div style={{paddingTop : "6px"}}>
                                         <img  className='faq_cursordown'src="/img/cursordown.png" alt='cursor'></img></div>
                                       </div>                                            
                                   </div>
                                  </div>

                               }

                               </div>

     
                           </div>
                           ))}
         </div>

         

       </div>
        :<div><div style={{textAlign : 'center', marginTop : '60px'}}>등록된 FAQ 가 없습니다.</div><div  style={{textAlign : 'center', marginTop : '20px'}}>빠른 시일 내로 찾아뵙겠습니다.</div></div>}
           
            <div className='FAQpaging'>
            {FAQlist.length > 0 ?<Paging /> : <div></div>}
            </div>




    </div>
  )
}

export default Supfaq