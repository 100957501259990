import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import en from "lang/locales/lang.en.json";
import ko from "lang/locales/lang.ko.json";

const resource = {
  en: {
    translation: en,
  },
  "en-US": {
    translation: en,
  },
  ko: {
    translation: ko,
  },
};

i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    // detection: { order: ["path", "navigator"] },
    // detection: { order: ["navigator", "path"] },
    debug: false,
    resources: resource,
    fallbackLng: {
      en: ["en"],
      "en-US": ["en"],
      default: ["ko"],
    },
    // fallbackLng: "en",
    // defaultNS: "translation",
    // ns: "translation",
    keySeparator: ".",
    interpolation: {
      escapeValue: false,
    },
    react: {
      bindI18n: "languageChanged",
      bindI18nStore: "",
      transEmptyNodeValue: "",
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br", "strong", "i"],
      useSuspense: false,
      // useSuspense: true,
    },
  });

export default i18n;
