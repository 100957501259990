import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateStatisticsType, closedateStatisticsDetail } from "actions/index";
import StatisticsDatePicker from "component/tag/datepicker/statisticsDatePicker";
/**
 * Version : 1.0
 * 파일명 : DataStatisticsHeader.js
 * 작성일자 : 2022-03-04
 * 작성자 : 권도훈
 * 설명 : 통계 헤더
 * 수정일자 : 2022-06-08
 * 수정자 : 강연승
 * 수정내역 : 미팅호스트인 경우 목록가기 버튼 disable
 */
function DataStatisticsHeader(props) {
  /**
   * REDUCER
   */
  const STATISTICS_STATE = useSelector(state => state.statisticsInfoReducer);
  const GROUP_STATE = useSelector(state => state.groupInfoReducer);
  /**
   * DISPATCH
   */
  const dispatch = useDispatch();
  /**
   * Init State
   */
  const [isInit, setIsInit] = useState(false);
  /**
   * Init
   */
  useEffect(() => {
    if (isInit && props.hostName === "") {
      props.setDataSearch(true);
      setIsInit(false);
    }
  }, [isInit, props]);
  /**
   * 타입 변경
   */
  const toggleType = type => {
    dispatch(updateStatisticsType(type));
  };
  /**
   * 상세보기 닫기
   */
  const closeDetailPage = () => {
    props.closeDetail();
    dispatch(closedateStatisticsDetail());
  };

  const dateFormat = val => {
    let year = val.getFullYear();
    let month = val.getMonth() + 1;
    let date = val.getDate();

    if (month < 10) {
      month = "0" + month;
    }

    if (date < 10) {
      date = "0" + date;
    }

    return year + "-" + month + "-" + date;
  };
  return (
    <nav
      className={
        STATISTICS_STATE.isDetail
          ? "data-statistics-header detail"
          : "data-statistics-header"
      }
    >
      {STATISTICS_STATE.isDetail ? (
        <>
          <article className="data-statistics-header-article drag-none">
            <font className="data-statistics-header-title">통계</font>

            <div className="data-statistics-header-date">
              <font>조회 기간 : </font>
              <font>{dateFormat(props.dateDetail.START_DATE)}</font>
              <font> - </font>
              <font>{dateFormat(props.dateDetail.END_DATE)}</font>
            </div>
          </article>

          <ul className="data-statistics-header-ul">
            <li
              className={
                props.loading || props.total === 0
                  ? "data-statistics-card-btn disable drag-none"
                  : "data-statistics-card-btn og cursor"
              }
              onClick={() => {
                if (props.total > 0) {
                  props.downloadDetailExcel();
                }
              }}
            >
              <div>
                <img
                  src={
                    props.loading
                      ? "/img/icon/data/btn_download_gr.png"
                      : "/img/icon/data/btn_download.png"
                  }
                  alt="download"
                />
              </div>
            </li>

            <li
              className={
                props.loading || props.total === 0
                  ? "data-statistics-card-btn disable mg drag-none"
                  : "data-statistics-card-btn pp cursor"
              }
              onClick={() => {
                if (props.total > 0) {
                  props.downloadDetailPdf();
                }
              }}
            >
              <div>
                <img
                  src={
                    props.loading
                      ? "/img/icon/data/btn_print_gr.png"
                      : "/img/icon/data/btn_print.png"
                  }
                  alt="print"
                />
              </div>
            </li>

            {GROUP_STATE.AUTH_CD === "CD0302" ? null : (
              <li
                className={
                  props.loading
                    ? "data-statistics-back-btn disable drag-none"
                    : "data-statistics-back-btn cursor"
                }
                onClick={() => closeDetailPage()}
              >
                <div>
                  <img
                    src={
                      props.loading
                        ? "/img/icon/data/btn_list_gr.png" //필요
                        : "/img/icon/data/btn_list.png"
                    }
                    alt="print"
                  />

                  <font>목록가기</font>
                </div>
              </li>
            )}
          </ul>
        </>
      ) : (
        <>
          <article className="data-statistics-header-article drag-none">
            <font className="data-statistics-header-title">통계</font>

            <div className="data-statistics-header-options">
              <div className="data-statistics-header-datepicker-area">
                <img src="/img/icon/data/period.png" alt="period" />
                <font>통계 기간 설정</font>
                <div className="data-statistics-datepicker-card">
                  <StatisticsDatePicker
                    setDate={props.setDate}
                    date={props.date}
                    type="START_DATE"
                    setDataSearch={props.setDataSearch}
                    sort={props.sort}
                  />
                </div>
                <div className="data-statistics-datepicker-range">~</div>
                <div className="data-statistics-datepicker-card">
                  <StatisticsDatePicker
                    setDate={props.setDate}
                    date={props.date}
                    type="END_DATE"
                    setDataSearch={props.setDataSearch}
                    sort={props.sort}
                  />
                </div>
              </div>

              <div className="data-statistics-header-search-area">
                <img src="/img/icon/data/search.png" alt="search" />
                <font>
                  {GROUP_STATE.GROUP_CATEGORY === "CD0401" ||
                  GROUP_STATE.GROUP_CATEGORY === "CD0402"
                    ? "미팅호스트 검색"
                    : GROUP_STATE.GROUP_CATEGORY === "CD0403"
                    ? "교사 검색"
                    : GROUP_STATE.GROUP_CATEGORY === "CD0404"
                    ? "강사 검색"
                    : GROUP_STATE.GROUP_CATEGORY === "CD0405"
                    ? "교수 검색"
                    : "그룹리더 검색"}
                </font>
                <div className="data-statistics-search-card">
                  <input
                    type="text"
                    placeholder={
                      GROUP_STATE.GROUP_CATEGORY === "CD0401" ||
                      GROUP_STATE.GROUP_CATEGORY === "CD0402"
                        ? "미팅호스트를 입력하세요."
                        : GROUP_STATE.GROUP_CATEGORY === "CD0403"
                        ? "교사를 입력하세요."
                        : GROUP_STATE.GROUP_CATEGORY === "CD0404"
                        ? "강사를 입력하세요"
                        : GROUP_STATE.GROUP_CATEGORY === "CD0405"
                        ? "교수를 입력하세요."
                        : "그룹리더를 입력하세요."
                    }
                    readOnly={props.loading}
                    onKeyDown={e => {
                      if (e.key === "Enter") {
                        props.setHostName(e.target.value);
                        props.setDataSearch(true);
                        setIsInit(true);
                      }
                    }}
                    onChange={e => {
                      props.setHostName(e.target.value);
                    }}
                  />
                </div>

                <button
                  className={
                    props.loading
                      ? "data-statistics-searchBtn-card"
                      : "data-statistics-searchBtn-card cursor"
                  }
                  disabled={props.loading}
                  onClick={() => {
                    props.setDataSearch(true);
                    setIsInit(true);
                  }}
                >
                  검 색
                </button>
              </div>
            </div>
          </article>

          <ul className="data-statistics-header-ul">
            <li
              className={
                props.loading
                  ? "data-statistics-card-btn disable drag-none"
                  : "data-statistics-card-btn og cursor"
              }
              onClick={() => props.downloadExcel()}
            >
              <div>
                <img
                  src={
                    props.loading
                      ? "/img/icon/data/btn_download_gr.png"
                      : "/img/icon/data/btn_download.png"
                  }
                  alt="download"
                />
              </div>
            </li>

            <li
              className={
                props.loading
                  ? "data-statistics-card-btn disable mg drag-none"
                  : "data-statistics-card-btn pp cursor"
              }
              onClick={() => props.downloadHostListPdf()}
            >
              <div>
                <img
                  src={
                    props.loading
                      ? "/img/icon/data/btn_print_gr.png"
                      : "/img/icon/data/btn_print.png"
                  }
                  alt="print"
                />
              </div>
            </li>

            <li className="data-statistics-card-toggle">
              <div
                className={
                  STATISTICS_STATE.type === "CARD"
                    ? "data-statistics-card-btn toggle"
                    : "data-statistics-card-btn toggle list"
                }
              />
              <div
                className={
                  STATISTICS_STATE.type !== "CARD"
                    ? "data-statistics-card-btn none cursor"
                    : "data-statistics-card-btn none"
                }
                onClick={() => {
                  if (STATISTICS_STATE.type !== "CARD") {
                    toggleType("CARD");
                    props.setSort({
                      type: "HOST_NAME",
                      direction: "ASC",
                    });
                    props.setStatisticsPage({
                      ROW_COUNT: 15,
                      PAGE_COUNT: 0,
                    });
                    props.setDataSearch(true);
                  }
                }}
              >
                <div>
                  <img
                    className="data-statistics-toggle-img"
                    src={
                      props.loading || STATISTICS_STATE.type !== "CARD"
                        ? "/img/icon/data/card.png"
                        : "/img/icon/data/card_selected.png"
                    }
                    alt="card"
                  />
                </div>
              </div>

              <div
                className={
                  STATISTICS_STATE.type !== "LIST"
                    ? "data-statistics-card-btn none cursor"
                    : "data-statistics-card-btn none"
                }
                onClick={() => {
                  if (STATISTICS_STATE.type !== "LIST") {
                    toggleType("LIST");
                    props.setStatisticsPage({
                      ROW_COUNT: 10,
                      PAGE_COUNT: 0,
                    });
                    props.setDataSearch(true);
                  }
                }}
              >
                <div>
                  <img
                    className="data-statistics-toggle-img"
                    src={
                      props.loading || STATISTICS_STATE.type !== "LIST"
                        ? "/img/icon/data/list.png"
                        : "/img/icon/data/list_selected.png"
                    }
                    alt="list"
                  />
                </div>
              </div>
            </li>
          </ul>
        </>
      )}
    </nav>
  );
}

export default DataStatisticsHeader;
