/**
 * Version : 1.0
 * 작성일자 : 2021-12-23
 * 작성자 : 권도훈
 * 설명 : 그룹정보
 * 수정일자 :  2022-04-21
 * 수정자 : 강연승
 * 수정내역 : 그룹 YN 초기값 수정
 */
const initalState = {
  GROUP_YN: "",
  GROUP_NUM: "",
  AUTH_CD: "",
  GROUP_NM: "",
  GROUP_COLOR: "",
  GROUP_CATEGORY: "",
  GROUP_IMG: "",
  REC_YN: "",
  DOWNLOAD_YN: "",
  PLAY_YN: "",
  HOST_CNT: "",
  LICENSE_CNT: "",
  D_DAY: "",
  CREATE_DT: "",
};

const groupInfo = (state = initalState, action) => {
  switch (action.type) {
    case "groupInfo/UPDATE":
      return {
        GROUP_YN: action.DATA.GROUP_YN,
        GROUP_NUM: action.DATA.GROUP_NUM,
        AUTH_CD: action.DATA.AUTH_CD,
        GROUP_NM: action.DATA.GROUP_NM,
        GROUP_COLOR: action.DATA.GROUP_COLOR,
        GROUP_CATEGORY: action.DATA.GROUP_CATEGORY,
        GROUP_IMG: action.DATA.GROUP_IMG,
        REC_YN: action.DATA.REC_YN,
        DOWNLOAD_YN: action.DATA.DOWNLOAD_YN,
        PLAY_YN: action.DATA.PLAY_YN,
        HOST_CNT: action.DATA.HOST_CNT,
        LICENSE_CNT: action.DATA.LICENSE_CNT,
        D_DAY: action.DATA.D_DAY,
        CREATE_DT: action.DATA.CREATE_DT,
      };
    default:
      return state;
  }
};

export default groupInfo;
