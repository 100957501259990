import { useRef, useEffect, useCallback } from 'react';
/**
 * Version : 1.0
 * 파일명 : scrollCount.jsx
 * 작성일자 : 2022-02-11
 * 작성자 : 권도훈
 * 설명 : 스크롤 시 카운트 증가 hook
 * 
 */
const useScrollCount = (delay, maxCount, tagYN) => {
    const element = useRef();
    const observer = useRef(null);
    
    const scrollEventHandler = useCallback(([entry]) => {
        const { current } = element;
        
        if (entry.isIntersecting) {
            let min = 0;
            let diff = Math.floor(maxCount / 10);
            const loop = setInterval(() => {
                if (diff > 0) {
                    min += diff;
                } else {
                    min = maxCount;
                }
                
                if (tagYN) {
                    current.innerHTML = "#" + min.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                } else {
                    current.innerHTML = min.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                }
                
                diff = Math.floor((maxCount - min) / 10);
                
                if (min === maxCount) {
                    clearInterval(loop);
                }
            }, delay);
      }
    }, [delay, maxCount, tagYN]);
    
    useEffect(() => {
        if (element.current) {
            observer.current = new IntersectionObserver(scrollEventHandler, { threshold: 0.7 });
            observer.current.observe(element.current);
        }
        return () => observer;
    }, [scrollEventHandler]);
    
    return {
        ref: element,
    };
};

export default useScrollCount;