import { Box, Slider } from "@mui/material";
import StatisticsDetailDatePicker from "component/tag/datepicker/statisticsDetailDatePicker";
import MultiSlider from "component/tag/slider/data/mulitSlider";
import MultiBarSlider from "component/tag/slider/data/multiBarSlider";
import React, { useCallback, useEffect, useState } from "react";
import { openAlert, closeAlert } from "actions/index";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import WorkTimePopup from "component/tag/popup/group/dashboard/workTimePopup";
import axios from "axios";
/**
 * Version : 1.0
 * 파일명 : DataStatisticsSideBar.js
 * 작성일자 : 2022-03-04
 * 작성자 : 권도훈
 * 설명 : 통계 상세보기 사이드바
 * 수정일자 : 2022-06-08
 * 수정자 : 강연승
 * 수정내역 : 체크박스 수정
 */
const useStyles = makeStyles(() => ({
  blue: {
    color: "#2B4AC4 !important",
    "& .MuiSlider-thumb": {
      backgroundColor: "#2B4AC4",
      border: "2px solid currentColor",
      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
        boxShadow: "inherit",
      },
      "&:before": {
        display: "none",
      },
    },
  },
  orange: {
    color: "#EE6C4D !important",
    "& .MuiSlider-thumb": {
      backgroundColor: "#EE6C4D",
      border: "2px solid currentColor",
      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
        boxShadow: "inherit",
      },
      "&:before": {
        display: "none",
      },
    },
  },
  green: {
    color: "#37B392 !important",
    "& .MuiSlider-thumb": {
      backgroundColor: "#37B392",
      border: "2px solid currentColor",
      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
        boxShadow: "inherit",
      },
      "&:before": {
        display: "none",
      },
    },
  },
  purple: {
    color: "#8B5FBF !important",
    "& .MuiSlider-thumb": {
      backgroundColor: "#8B5FBF",
      border: "2px solid currentColor",
      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
        boxShadow: "inherit",
      },
      "&:before": {
        display: "none",
      },
    },
  },
  red: {
    color: "#C63353 !important",
    "& .MuiSlider-thumb": {
      backgroundColor: "#C63353",
      border: "2px solid currentColor",
      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
        boxShadow: "inherit",
      },
      "&:before": {
        display: "none",
      },
    },
  },
  lightgreen: {
    color: "#28B750 !important",
    "& .MuiSlider-thumb": {
      backgroundColor: "#28B750",
      border: "2px solid currentColor",
      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
        boxShadow: "inherit",
      },
      "&:before": {
        display: "none",
      },
    },
  },
  yellow: {
    color: "#EFAC00 !important",
    "& .MuiSlider-thumb": {
      backgroundColor: "#EFAC00",
      border: "2px solid currentColor",
      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
        boxShadow: "inherit",
      },
      "&:before": {
        display: "none",
      },
    },
  },
  deepGreen: {
    color: "#2993BC !important",
    "& .MuiSlider-thumb": {
      backgroundColor: "#2993BC",
      border: "2px solid currentColor",
      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
        boxShadow: "inherit",
      },
      "&:before": {
        display: "none",
      },
    },
  },
}));

function DataStatisticsSideBar(props) {
  /**
   * REDUCER
   */
  const STATISTICS_STATE = useSelector(state => state.statisticsInfoReducer);
  const GROUP_STATE = useSelector(state => state.groupInfoReducer);

  const dispatch = useDispatch();

  const classes = useStyles();

  const [init, setInit] = useState(false);

  const [timePopup, setTimePopup] = useState(false);
  const [isSave, setIsSave] = useState(true);
  /**
   * Attendance
   */
  const [attendanceRange, setAttendanceRange] = useState(false);
  const [attendance, setAttendance] = useState([0, 100]);

  const [worktimeRange, setWorktimeRange] = useState(false);
  const [defaultWorktime, setDefaultWorktime] = useState([]);
  const [worktime, setWorktime] = useState([0, 0]);

  const [overtimeRange, setOvertimeRange] = useState(false);
  const [defaultOvertime, setDefaultOvertime] = useState([]);
  const [overtime, setOvertime] = useState([0, 0]);

  const [weekendRange, setWeekendRange] = useState(false);
  const [defaultWeekend, setDefaultWeekend] = useState([]);
  const [weekend, setWeekend] = useState([0, 0]);

  const [attendanceCountRange, setAttendanceCountRange] = useState(false);
  const [defaultAttendanceCount, setDefaultAttendanceCount] = useState([0, 0]);
  const [attendanceCount, setAttendanceCount] = useState([0, 0]);

  const [tardyCountRange, setTardyCountRange] = useState(false);
  const [defaultTardyCount, setDefaultTardyCount] = useState([0, 0]);
  const [tardyCount, setTardyCount] = useState([0, 0]);

  const [absebceCountRange, setAbsebceCountRange] = useState(false);
  const [defaultAbsebceCount, setDefaultAbsebceCount] = useState([0, 0]);
  const [absebceCount, setAbsebceCount] = useState([0, 0]);

  const marks = [
    { value: 0, label: "0" },
    { value: 10, label: "10" },
    { value: 20, label: "20" },
    { value: 30, label: "30" },
    { value: 40, label: "40" },
    { value: 50, label: "50" },
    { value: 60, label: "60" },
    { value: 70, label: "70" },
    { value: 80, label: "80" },
    { value: 90, label: "90" },
    { value: 100, label: "100" },
  ];

  const durationSet = useCallback(() => {
    const data = props.data;
    let workArr = [0, 0];
    let overArr = [0, 0];
    let weekArr = [0, 0];

    for (let i = 0; i < data.length; i++) {
      if (data[i].WORK_TIME_TOTAL > 0) {
        if (data[i].WORK_TIME_TOTAL < workArr[0]) {
          workArr[0] = data[i].WORK_TIME_TOTAL;
        } else if (data[i].WORK_TIME_TOTAL > workArr[1]) {
          workArr[1] = data[i].WORK_TIME_TOTAL;
        }
      }

      if (data[i].WORK_OVERTIME_TOTAL > 0) {
        if (data[i].WORK_OVERTIME_TOTAL < overArr[0]) {
          overArr[0] = data[i].WORK_OVERTIME_TOTAL;
        } else if (data[i].WORK_OVERTIME_TOTAL > overArr[1]) {
          overArr[1] = data[i].WORK_OVERTIME_TOTAL;
        }
      }

      if (data[i].WEEK_WORK_TIME_TOTAL > 0) {
        if (data[i].WEEK_WORK_TIME_TOTAL < weekArr[0]) {
          weekArr[0] = data[i].WEEK_WORK_TIME_TOTAL;
        } else if (data[i].WEEK_WORK_TIME_TOTAL > weekArr[1]) {
          weekArr[1] = data[i].WEEK_WORK_TIME_TOTAL;
        }
      }
    }

    setWorktime(workArr);
    setDefaultWorktime(workArr);
    setOvertime(overArr);
    setDefaultOvertime(overArr);
    setWeekend(weekArr);
    setDefaultWeekend(weekArr);
  }, [
    defaultOvertime,
    defaultWeekend,
    defaultWorktime,
    overtime,
    props.data,
    weekend,
    worktime,
  ]);

  const countSet = useCallback(() => {
    const data = props.data;
    let attendanceArr = [0, 0];
    let tardyArr = [0, 0];
    let absebceArr = [0, 0];

    for (let i = 0; i < data.length; i++) {
      if (data[i].ATTENDANCE_COUNT > 0) {
        if (data[i].ATTENDANCE_COUNT < attendanceArr[0]) {
          attendanceArr[0] = data[i].ATTENDANCE_COUNT;
        } else if (data[i].ATTENDANCE_COUNT > attendanceArr[1]) {
          attendanceArr[1] = data[i].ATTENDANCE_COUNT;
        }
      }
      console.log("attendnaceArr" + i);
      console.log(data[i]);
      console.log(attendanceArr);

      if (data[i].TARDY_COUNT > 0) {
        if (data[i].TARDY_COUNT < tardyArr[0]) {
          tardyArr[0] = data[i].TARDY_COUNT;
        } else if (data[i].TARDY_COUNT > tardyArr[1]) {
          tardyArr[1] = data[i].TARDY_COUNT;
        }
      }

      if (data[i].ABSEBCE_COUNT > 0) {
        if (data[i].ABSEBCE_COUNT < absebceArr[0]) {
          absebceArr[0] = data[i].ABSEBCE_COUNT;
        } else if (data[i].ABSEBCE_COUNT > absebceArr[1]) {
          absebceArr[1] = data[i].ABSEBCE_COUNT;
        }
      }
    }
    setAttendanceCount(attendanceArr);
    setDefaultAttendanceCount(attendanceArr);
    setTardyCount(tardyArr);
    setDefaultTardyCount(tardyArr);
    setAbsebceCount(absebceArr);
    setDefaultAbsebceCount(absebceArr);
  }, [
    absebceCount,
    attendanceCount,
    defaultAbsebceCount,
    defaultAttendanceCount,
    defaultTardyCount,
    props.data,
    tardyCount,
  ]);

  const timeReset = useCallback(() => {
    props.setWorkTime({
      min: 0,
      max: 0,
    });
    props.setOverTime({
      min: 0,
      max: 0,
    });
    props.setWeekendTime({
      min: 0,
      max: 0,
    });

    setWorktime([0, 0]);
    setOvertime([0, 0]);
    setWeekend([0, 0]);

    props.setDetailData([]);
    setInit(false);
    props.setDetailPage({
      ROW_COUNT: 10,
      PAGE_COUNT: 0,
    });
    props.setDetailSearch(true);
  }, [props]);

  const countReset = useCallback(() => {
    props.setAttendanceCount({
      min: 0,
      max: 0,
    });
    props.setTardyCount({
      min: 0,
      max: 0,
    });
    props.setAbsebceCount({
      min: 0,
      max: 0,
    });

    setAttendanceCount([0, 0]);
    setTardyCount([0, 0]);
    setAbsebceCount([0, 0]);

    props.setDetailData([]);
    setInit(false);
    props.setDetailPage({
      ROW_COUNT: 10,
      PAGE_COUNT: 0,
    });
    props.setDetailSearch(true);
  }, [props]);

  const onAttendanceCommit = useCallback(() => {
    props.setAttendance({
      // min: Math.floor(STATISTICS_STATE.detail_data.TOTAL * attendance[0]) / 100,
      // max: Math.floor(STATISTICS_STATE.detail_data.TOTAL * attendance[1]) / 100
      min: attendance[0],
      max: attendance[1],
    });

    if (
      GROUP_STATE.GROUP_CATEGORY === "CD0403" ||
      GROUP_STATE.GROUP_CATEGORY === "CD0404" ||
      GROUP_STATE.GROUP_CATEGORY === "CD0405"
    ) {
      countReset();
    } else {
      timeReset();
    }
  }, [GROUP_STATE.GROUP_CATEGORY, attendance, countReset, props, timeReset]);

  const onWorktimeCommit = useCallback(() => {
    props.setWorkTime({
      min: worktime[0],
      max: worktime[1],
    });
    props.setDetailPage({
      ROW_COUNT: 10,
      PAGE_COUNT: 0,
    });
    props.setDetailSearch(true);
  }, [props, worktime]);

  const onOvertimeCommit = useCallback(() => {
    props.setOverTime({
      min: overtime[0],
      max: overtime[1],
    });
    props.setDetailPage({
      ROW_COUNT: 10,
      PAGE_COUNT: 0,
    });
    props.setDetailSearch(true);
  }, [props, overtime]);

  const onWeekendCommit = useCallback(() => {
    props.setWeekendTime({
      min: weekend[0],
      max: weekend[1],
    });
    props.setDetailPage({
      ROW_COUNT: 10,
      PAGE_COUNT: 0,
    });
    props.setDetailSearch(true);
  }, [props, weekend]);

  const onAttendanceCountCommit = useCallback(() => {
    props.setAttendanceCount({
      min: attendanceCount[0],
      max: attendanceCount[1],
    });
    props.setDetailPage({
      ROW_COUNT: 10,
      PAGE_COUNT: 0,
    });
    props.setDetailSearch(true);
  }, [props, attendanceCount]);

  const onTardyCountCommit = useCallback(() => {
    props.setTardyCount({
      min: tardyCount[0],
      max: tardyCount[1],
    });
    props.setDetailPage({
      ROW_COUNT: 10,
      PAGE_COUNT: 0,
    });
    props.setDetailSearch(true);
  }, [props, tardyCount]);

  const onAbsebceCountCommit = useCallback(() => {
    props.setAbsebceCount({
      min: absebceCount[0],
      max: absebceCount[1],
    });
    props.setDetailPage({
      ROW_COUNT: 10,
      PAGE_COUNT: 0,
    });
    props.setDetailSearch(true);
  }, [props, absebceCount]);
  /**
   * 참석률 변경
   */
  useEffect(() => {
    if (attendanceRange) {
      onAttendanceCommit();
      setAttendanceRange(false);
    }
  }, [attendanceRange, onAttendanceCommit]);

  useEffect(() => {
    if (worktimeRange) {
      onWorktimeCommit();
      setWorktimeRange(false);
    }
  }, [onWorktimeCommit, worktimeRange]);

  useEffect(() => {
    if (overtimeRange) {
      onOvertimeCommit();
      setOvertimeRange(false);
    }
  }, [onOvertimeCommit, overtimeRange]);

  useEffect(() => {
    if (weekendRange) {
      onWeekendCommit();
      setWeekendRange(false);
    }
  }, [onWeekendCommit, weekendRange]);

  useEffect(() => {
    if (attendanceCountRange) {
      onAttendanceCountCommit();
      setAttendanceCountRange(false);
    }
  }, [attendanceCountRange, onAttendanceCountCommit]);

  useEffect(() => {
    if (tardyCountRange) {
      onTardyCountCommit();
      setTardyCountRange(false);
    }
  }, [onTardyCountCommit, tardyCountRange]);

  useEffect(() => {
    if (absebceCountRange) {
      onAbsebceCountCommit();
      setAbsebceCountRange(false);
    }
  }, [absebceCountRange, onAbsebceCountCommit]);
  /**
   * 기본 시간 설정
   */
  useEffect(() => {
    if (STATISTICS_STATE.isDetail && !init && props.data.length > 0) {
      // timeSet();
      if (
        GROUP_STATE.GROUP_CATEGORY === "CD0403" ||
        GROUP_STATE.GROUP_CATEGORY === "CD0404" ||
        GROUP_STATE.GROUP_CATEGORY === "CD0405"
      ) {
        countSet();
      } else {
        durationSet();
      }

      setInit(true);
    }
  }, [
    GROUP_STATE.GROUP_CATEGORY,
    STATISTICS_STATE.isDetail,
    countSet,
    durationSet,
    init,
    props.data.length,
  ]);
  /**
   * 참석률 계산
   */
  const onChangeAttendance = (e, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setAttendance([Math.min(newValue[0], attendance[1] - 1), attendance[1]]);
    } else {
      setAttendance([attendance[0], Math.max(newValue[1], attendance[0] + 1)]);
    }
  };

  const onChangeWorktime = (e, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      if (worktime[1] > 0) {
        setWorktime([Math.min(newValue[0], worktime[1] - 1), worktime[1]]);
      }
    } else {
      setWorktime([worktime[0], Math.max(newValue[1], worktime[0] + 1)]);
    }
  };

  const onChangeOvertime = (e, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      if (overtime[1] > 0) {
        setOvertime([Math.min(newValue[0], overtime[1] - 1), overtime[1]]);
      }
    } else {
      setOvertime([overtime[0], Math.max(newValue[1], overtime[0] + 1)]);
    }
  };

  const onChangeWeekend = (e, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      if (weekend[1] > 0) {
        setWeekend([Math.min(newValue[0], weekend[1] - 1), weekend[1]]);
      }
    } else {
      setWeekend([weekend[0], Math.max(newValue[1], weekend[0] + 1)]);
    }
  };

  const onChangeAttendanceCount = (e, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      if (attendanceCount[1] > 0) {
        setAttendanceCount([
          Math.min(newValue[0], attendanceCount[1] - 1),
          attendanceCount[1],
        ]);
      }
    } else {
      setAttendanceCount([
        attendanceCount[0],
        Math.max(newValue[1], attendanceCount[0] + 1),
      ]);
    }
  };

  const onChangeTardyCount = (e, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      if (tardyCount[1] > 0) {
        setTardyCount([
          Math.min(newValue[0], tardyCount[1] - 1),
          tardyCount[1],
        ]);
      }
    } else {
      setTardyCount([tardyCount[0], Math.max(newValue[1], tardyCount[0] + 1)]);
    }
  };

  const onChangeAbsebceCount = (e, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      if (absebceCount[1] > 0) {
        setAbsebceCount([
          Math.min(newValue[0], absebceCount[1] - 1),
          absebceCount[1],
        ]);
      }
    } else {
      setAbsebceCount([
        absebceCount[0],
        Math.max(newValue[1], absebceCount[0] + 1),
      ]);
    }
  };
  /**
   * 시간 컨버터
   */
  const convertTime = time => {
    return time + "시간";
  };

  const convertCount = count => {
    return count + "회";
  };

  const changeTime = workTime => {
    setIsSave(false);
    setTimePopup(false);

    const start_time = workTime.startHour + ":" + workTime.startMin;
    const end_time = workTime.endHour + ":" + workTime.endMin;

    props.setTimeDetail({
      start_time_text:
        workTime.startHour < 12 ? "오전 " + start_time : "오후 " + start_time,
      start_time: start_time + ":00",
      end_time_text:
        workTime.endHour < 12 ? "오전 " + end_time : "오후 " + end_time,
      end_time: end_time + ":00",
    });
    timeReset();
    props.setDetailSearch(true);
  };

  const saveTime = () => {
    const data = {
      GROUP_NUM: GROUP_STATE.GROUP_NUM,
      WORK_START: props.timeDetail.start_time,
      WORK_END: props.timeDetail.end_time,
    };

    axios.put("/group/updateWorkTime", data).then(res => {
      if (res.status === 200) {
        const obj = {
          TEXT: "근무시간이 변경되었습니다.",
          submitEventHandler: () => {
            setIsSave(true);
            props.setDefaultTime(props.timeDetail);
            dispatch(closeAlert());
          },
        };
        dispatch(openAlert(obj));
      }
    });
  };

  return (
    <>
      <WorkTimePopup
        open={timePopup}
        close={() => setTimePopup(false)}
        save={changeTime}
      />

      <nav className="data-statistics-sidebar-nav">
        <div className="data-statistics-sidebar-title">
          <img src="/img/icon/data/setting.png" alt="setting" />
          <div>상세 설정</div>
        </div>

        <div
          className={
            STATISTICS_STATE.isDetail
              ? "data-statistics-sidebar-container show"
              : "data-statistics-sidebar-container"
          }
        >
          <div className="data-statistics-sidebar-date">
            <font>통계 기간 설정</font>

            <div className="data-statistics-sidebar-date-area">
              <div className="data-statistics-datepicker-side">
                <StatisticsDetailDatePicker
                  setDate={props.setDateDetail}
                  // defaultDate={props.date}
                  date={props.dateDetail}
                  type="START_DATE"
                  setDetailSearch={props.setDetailSearch}
                  timeReset={timeReset}
                  countReset={countReset}
                  setDetailTargetRefresh={props.setDetailTargetRefresh}
                />
              </div>

              <div className="data-statistics-datepicker-range">~</div>

              <div className="data-statistics-datepicker-side">
                <StatisticsDetailDatePicker
                  setDate={props.setDateDetail}
                  // defaultDate={props.date}
                  date={props.dateDetail}
                  type="END_DATE"
                  setDetailSearch={props.setDetailSearch}
                  timeReset={timeReset}
                  countReset={countReset}
                  setDetailTargetRefresh={props.setDetailTargetRefresh}
                />
              </div>
            </div>
          </div>

          {GROUP_STATE.GROUP_CATEGORY === "CD0401" ||
          GROUP_STATE.GROUP_CATEGORY === "CD0402" ? (
            <div className="data-statistics-sidebar-time">
              <font>근무 시간 설정</font>

              <div className="data-statistics-sidebar-time-container">
                <div className="data-statistics-sidebar-time-area">
                  <div className="data-statistics-sidebar-time-div">
                    <div
                      className="data-statistics-sidebar-time-inner"
                      onClick={() =>
                        // setTimePopup({
                        //   isShow: true,
                        //   target: "first-child",
                        // })
                        setTimePopup(true)
                      }
                    >
                      <font className="drag-none">
                        {props.timeDetail.start_time_text}
                      </font>
                      <img
                        className={
                          STATISTICS_STATE.loading ? "drag-none" : "cursor"
                        }
                        src="/img/icon/profile/drop.png"
                        alt="cal"
                      />
                    </div>
                  </div>
                  <div className="data-statistics-datepicker-range">~</div>
                  <div className="data-statistics-sidebar-time-div">
                    <div
                      className="data-statistics-sidebar-time-inner"
                      onClick={() =>
                        // setTimePopup({
                        //   isShow: true,
                        //   target: "last-child",
                        // })
                        setTimePopup(true)
                      }
                    >
                      <font className="drag-none">
                        {props.timeDetail.end_time_text}
                      </font>
                      <img
                        className={
                          STATISTICS_STATE.loading ? "drag-none" : "cursor"
                        }
                        src="/img/icon/profile/drop.png"
                        alt="cal"
                      />
                    </div>
                  </div>
                </div>

                <div className="data-statistics-sidebar-time-chk">
                  <input
                    type="checkbox"
                    checked={isSave}
                    onChange={() => {
                      if (isSave) {
                        return;
                      } else {
                        saveTime();
                      }
                    }}
                    // onClick={() => {
                    //   if (isSave) {
                    //     return;
                    //   } else {
                    //     saveTime();
                    //   }
                    // }}
                  />

                  <font>고정</font>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          <div className="data-statistics-sidebar-host">
            <font>
              {GROUP_STATE.GROUP_CATEGORY === "CD0401" ||
              GROUP_STATE.GROUP_CATEGORY === "CD0402"
                ? "미팅 참석자 검색"
                : GROUP_STATE.GROUP_CATEGORY === "CD0403"
                ? "수업 참석자 검색"
                : GROUP_STATE.GROUP_CATEGORY === "CD0404" ||
                  GROUP_STATE.GROUP_CATEGORY === "CD0405"
                ? "강의 참석자 검색"
                : "모임 참석자 검색"}
            </font>

            <div className="data-statistics-sidebar-host-area">
              <input
                type="text"
                placeholder="참석자명을 입력하세요."
                className="data-statistics-sidebar-host-area-input"
                value={props.hostNameDetail}
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    if (
                      GROUP_STATE.GROUP_CATEGORY === "CD0403" ||
                      GROUP_STATE.GROUP_CATEGORY === "CD0404" ||
                      GROUP_STATE.GROUP_CATEGORY === "CD0405"
                    ) {
                      countReset();
                    } else {
                      timeReset();
                    }
                    props.setDetailSearch(true);
                  }
                }}
                onChange={e => {
                  props.setHostNameDetail(e.target.value);
                }}
              />

              <button
                className="data-statistics-sidebar-host-area-btn cursor"
                onClick={() => {
                  if (
                    GROUP_STATE.GROUP_CATEGORY === "CD0403" ||
                    GROUP_STATE.GROUP_CATEGORY === "CD0404" ||
                    GROUP_STATE.GROUP_CATEGORY === "CD0405"
                  ) {
                    countReset();
                  } else {
                    timeReset();
                  }
                  props.setDetailSearch(true);
                }}
              >
                검 색
              </button>
            </div>
          </div>

          {GROUP_STATE.GROUP_CATEGORY === "CD0401" ||
          GROUP_STATE.GROUP_CATEGORY === "CD0402" ? (
            <>
              <div className="data-statistics-sidebar-attendance">
                <div className="data-statistics-sidebar-attendance-title">
                  <font>참석률 설정</font>

                  <img
                    className="cursor"
                    src="/img/icon/data/refresh.png"
                    alt="refresh"
                    onClick={() => {
                      setAttendance([0, 100]);
                      setAttendanceRange(true);
                    }}
                  />
                </div>

                <div className="data-statistics-sidebar-attendance-container">
                  <div className="data-statistics-sidebar-attendance-container-header">
                    <div className="data-statistics-sidebar-attendance-title-sub">
                      참석률
                    </div>

                    <div className="data-statistics-sidebar-attendance-box-area">
                      <div className="data-statistics-sidebar-attendance-box-item">
                        <font>{attendance[0]}%</font>

                        <div className="data-statistics-sidebar-attendance-box-btn">
                          <img
                            src="/img/icon/data/percent_up.png"
                            alt="up"
                            className="cursor"
                            onClick={() => {
                              if (
                                attendance[0] >= 100 ||
                                attendance[0] + 1 >= attendance[1]
                              ) {
                                return;
                              } else {
                                setAttendance([
                                  attendance[0] + 1,
                                  attendance[1],
                                ]);
                                setAttendanceRange(true);
                              }
                            }}
                          />
                          <img
                            src="/img/icon/data/percent_down.png"
                            alt="down"
                            className="cursor"
                            onClick={() => {
                              if (attendance[0] <= 0) {
                                return;
                              } else {
                                setAttendance([
                                  attendance[0] - 1,
                                  attendance[1],
                                ]);
                                setAttendanceRange(true);
                              }
                            }}
                          />
                        </div>
                      </div>

                      <font>~</font>

                      <div className="data-statistics-sidebar-attendance-box-item">
                        <font>{attendance[1]}%</font>

                        <div className="data-statistics-sidebar-attendance-box-btn">
                          <img
                            src="/img/icon/data/percent_up.png"
                            alt="up"
                            className="cursor"
                            onClick={() => {
                              if (attendance[1] >= 100) {
                                return;
                              } else {
                                setAttendance([
                                  attendance[0],
                                  attendance[1] + 1,
                                ]);
                                setAttendanceRange(true);
                              }
                            }}
                          />
                          <img
                            src="/img/icon/data/percent_down.png"
                            alt="down"
                            className="cursor"
                            onClick={() => {
                              if (
                                attendance[1] <= 0 ||
                                attendance[1] - 1 <= attendance[0]
                              ) {
                                return;
                              } else {
                                setAttendance([
                                  attendance[0],
                                  attendance[1] - 1,
                                ]);
                                setAttendanceRange(true);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="data-statistics-sidebar-slider-div">
                      <Slider
                        className={classes.blue}
                        value={attendance}
                        onChange={onChangeAttendance}
                        onChangeCommitted={onAttendanceCommit}
                        disableSwap
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="data-statistics-sidebar-duration">
                <div className="data-statistics-sidebar-duration-title">
                  <font>회의 시간</font>

                  <img
                    className="cursor"
                    src="/img/icon/data/refresh.png"
                    alt="refresh"
                    onClick={() => timeReset()}
                  />
                </div>

                <div className="data-statistics-sidebar-duration-container">
                  <div className="data-statistics-sidebar-duration-container-header">
                    <div className="data-statistics-sidebar-duration-title-sub purple">
                      근무시간
                    </div>

                    <div className="data-statistics-sidebar-attendance-box-area">
                      <div className="data-statistics-sidebar-attendance-box-item">
                        <font>{convertTime(worktime[0])}</font>

                        <div className="data-statistics-sidebar-attendance-box-btn">
                          <img
                            src="/img/icon/data/percent_up.png"
                            alt="up"
                            className="cursor"
                            onClick={() => {
                              if (
                                worktime[0] >= defaultWorktime[1] ||
                                worktime[0] + 1 >= worktime[1]
                              ) {
                                return;
                              } else {
                                setWorktime([worktime[0] + 1, worktime[1]]);
                                setWorktimeRange(true);
                              }
                            }}
                          />
                          <img
                            src="/img/icon/data/percent_down.png"
                            alt="down"
                            className="cursor"
                            onClick={() => {
                              if (
                                worktime[0] <= 0 ||
                                worktime[0] <= defaultWorktime[0]
                              ) {
                                return;
                              } else {
                                setWorktime([worktime[0] - 1, worktime[1]]);
                                setWorktimeRange(true);
                              }
                            }}
                          />
                        </div>
                      </div>

                      <font>~</font>

                      <div className="data-statistics-sidebar-attendance-box-item">
                        <font>{convertTime(worktime[1])}</font>

                        <div className="data-statistics-sidebar-attendance-box-btn">
                          <img
                            src="/img/icon/data/percent_up.png"
                            alt="up"
                            className="cursor"
                            onClick={() => {
                              if (worktime[1] >= defaultWorktime[1]) {
                                return;
                              } else {
                                setWorktime([worktime[0], worktime[1] + 1]);
                                setWorktimeRange(true);
                              }
                            }}
                          />
                          <img
                            src="/img/icon/data/percent_down.png"
                            alt="down"
                            className="cursor"
                            onClick={() => {
                              if (
                                worktime[1] <= defaultWorktime[0] ||
                                worktime[1] - 1 <= worktime[0]
                              ) {
                                return;
                              } else {
                                setWorktime([worktime[0], worktime[1] - 1]);
                                setWorktimeRange(true);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="data-statistics-sidebar-slider-div">
                      <Slider
                        className={classes.purple}
                        min={defaultWorktime[0]}
                        max={defaultWorktime[1]}
                        value={worktime}
                        onChange={onChangeWorktime}
                        onChangeCommitted={onWorktimeCommit}
                        disableSwap
                      />
                    </div>
                  </div>
                </div>

                <div className="data-statistics-sidebar-duration-container">
                  <div className="data-statistics-sidebar-duration-container-header">
                    <div className="data-statistics-sidebar-duration-title-sub green">
                      주말
                    </div>

                    <div className="data-statistics-sidebar-attendance-box-area">
                      <div className="data-statistics-sidebar-attendance-box-item">
                        <font>{convertTime(weekend[0])}</font>

                        <div className="data-statistics-sidebar-attendance-box-btn">
                          <img
                            src="/img/icon/data/percent_up.png"
                            alt="up"
                            className="cursor"
                            onClick={() => {
                              if (
                                weekend[0] >= defaultWeekend[1] ||
                                weekend[0] + 1 >= weekend[1]
                              ) {
                                return;
                              } else {
                                setWeekend([weekend[0] + 1, weekend[1]]);
                                setWeekendRange(true);
                              }
                            }}
                          />
                          <img
                            src="/img/icon/data/percent_down.png"
                            alt="down"
                            className="cursor"
                            onClick={() => {
                              if (
                                weekend[0] <= 0 ||
                                weekend[0] <= defaultWeekend[0]
                              ) {
                                return;
                              } else {
                                setWeekend([weekend[0] - 1, weekend[1]]);
                                setWeekendRange(true);
                              }
                            }}
                          />
                        </div>
                      </div>

                      <font>~</font>

                      <div className="data-statistics-sidebar-attendance-box-item">
                        <font>{convertTime(weekend[1])}</font>

                        <div className="data-statistics-sidebar-attendance-box-btn">
                          <img
                            src="/img/icon/data/percent_up.png"
                            alt="up"
                            className="cursor"
                            onClick={() => {
                              if (weekend[1] >= defaultWeekend[1]) {
                                return;
                              } else {
                                setWeekend([weekend[0], weekend[1] + 1]);
                                setWeekendRange(true);
                              }
                            }}
                          />
                          <img
                            src="/img/icon/data/percent_down.png"
                            alt="down"
                            className="cursor"
                            onClick={() => {
                              if (
                                weekend[1] <= defaultWeekend[0] ||
                                weekend[1] - 1 <= weekend[0]
                              ) {
                                return;
                              } else {
                                setWeekend([weekend[0], weekend[1] - 1]);
                                setWeekendRange(true);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="data-statistics-sidebar-slider-div">
                      <Slider
                        className={classes.green}
                        min={defaultWeekend[0]}
                        max={defaultWeekend[1]}
                        value={weekend}
                        onChange={onChangeWeekend}
                        onChangeCommitted={onWeekendCommit}
                        disableSwap
                      />
                    </div>
                  </div>
                </div>

                <div className="data-statistics-sidebar-duration-container">
                  <div className="data-statistics-sidebar-duration-container-header">
                    <div className="data-statistics-sidebar-duration-title-sub orange">
                      근무 외 시간
                    </div>

                    <div className="data-statistics-sidebar-attendance-box-area">
                      <div className="data-statistics-sidebar-attendance-box-item">
                        <font>{convertTime(overtime[0])}</font>

                        <div className="data-statistics-sidebar-attendance-box-btn">
                          <img
                            src="/img/icon/data/percent_up.png"
                            alt="up"
                            className="cursor"
                            onClick={() => {
                              if (
                                overtime[0] >= defaultOvertime[1] ||
                                overtime[0] + 1 >= overtime[1]
                              ) {
                                return;
                              } else {
                                setOvertime([overtime[0] + 1, overtime[1]]);
                                setOvertimeRange(true);
                              }
                            }}
                          />
                          <img
                            src="/img/icon/data/percent_down.png"
                            alt="down"
                            className="cursor"
                            onClick={() => {
                              if (
                                overtime[0] <= 0 ||
                                overtime[0] <= defaultOvertime[0]
                              ) {
                                return;
                              } else {
                                setOvertime([overtime[0] - 1, overtime[1]]);
                                setOvertimeRange(true);
                              }
                            }}
                          />
                        </div>
                      </div>

                      <font>~</font>

                      <div className="data-statistics-sidebar-attendance-box-item">
                        <font>{convertTime(overtime[1])}</font>

                        <div className="data-statistics-sidebar-attendance-box-btn">
                          <img
                            src="/img/icon/data/percent_up.png"
                            alt="up"
                            className="cursor"
                            onClick={() => {
                              if (overtime[1] >= defaultOvertime[1]) {
                                return;
                              } else {
                                setOvertime([overtime[0], overtime[1] + 1]);
                                setOvertimeRange(true);
                              }
                            }}
                          />
                          <img
                            src="/img/icon/data/percent_down.png"
                            alt="down"
                            className="cursor"
                            onClick={() => {
                              if (
                                overtime[1] <= defaultOvertime[0] ||
                                overtime[1] - 1 <= overtime[0]
                              ) {
                                return;
                              } else {
                                setOvertime([overtime[0], overtime[1] - 1]);
                                setOvertimeRange(true);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="data-statistics-sidebar-slider-div">
                      <Slider
                        className={classes.orange}
                        min={defaultOvertime[0]}
                        max={defaultOvertime[1]}
                        value={overtime}
                        onChange={onChangeOvertime}
                        onChangeCommitted={onOvertimeCommit}
                        disableSwap
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : GROUP_STATE.GROUP_CATEGORY === "CD0406" ? (
            <div className="data-statistics-sidebar-attendance">
              <div className="data-statistics-sidebar-attendance-title">
                <font>친밀도 설정</font>

                <img
                  className="cursor"
                  src="/img/icon/data/refresh.png"
                  alt="refresh"
                  onClick={() => {
                    setAttendance([0, 100]);
                    setAttendanceRange(true);
                  }}
                />
              </div>
              <div className="data-statistics-sidebar-attendance-container">
                <section className="data-statistics-sidebar-community">
                  <div
                    className={
                      attendance[0] < 40
                        ? "data-statistics-sidebar-community-item red cursor selected"
                        : "data-statistics-sidebar-community-item red cursor"
                    }
                    onClick={() => {
                      if (attendance[1] >= 40 && attendance[0] >= 40) {
                        setAttendance([0, attendance[1]]);
                      } else {
                        setAttendance([0, 39]);
                      }
                      setAttendanceRange(true);
                    }}
                  >
                    <div>
                      <img src="/img/icon/data/bad.png" alt="BAD" />
                    </div>

                    <font>불만족</font>
                  </div>

                  <div
                    className={
                      attendance[0] < 40 && attendance[1] <= 40
                        ? "data-statistics-sidebar-community-item blue cursor"
                        : attendance[0] >= 70 && attendance[1] > 70
                        ? "data-statistics-sidebar-community-item blue cursor"
                        : "data-statistics-sidebar-community-item blue cursor selected"
                    }
                    onClick={() => {
                      setAttendance([40, 70]);
                      setAttendanceRange(true);
                    }}
                  >
                    <div>
                      <img src="/img/icon/data/soso.png" alt="SOSO" />
                    </div>

                    <font>만족</font>
                  </div>

                  <div
                    className={
                      attendance[1] > 70
                        ? "data-statistics-sidebar-community-item green cursor selected"
                        : "data-statistics-sidebar-community-item green cursor"
                    }
                    onClick={() => {
                      if (attendance[0] < 70 && attendance[1] <= 70) {
                        setAttendance([attendance[0], 100]);
                      } else {
                        setAttendance([71, 100]);
                      }
                      setAttendanceRange(true);
                    }}
                  >
                    <div>
                      <img src="/img/icon/data/good.png" alt="GOOD" />
                    </div>

                    <font>매우 만족</font>
                  </div>
                </section>

                <div>
                  <div className="data-statistics-sidebar-slider-div">
                    <Slider
                      step={10}
                      className={
                        attendance[0] < 40 && attendance[1] <= 40
                          ? classes.red
                          : attendance[0] >= 70 && attendance[1] > 70
                          ? classes.lightgreen
                          : classes.blue
                      }
                      value={attendance}
                      onChange={onChangeAttendance}
                      onChangeCommitted={onAttendanceCommit}
                      track={true}
                      marks={marks}
                      disableSwap
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="data-statistics-sidebar-attendance">
                <div className="data-statistics-sidebar-attendance-title">
                  <font>출석률 설정</font>

                  <img
                    className="cursor"
                    src="/img/icon/data/refresh.png"
                    alt="refresh"
                    onClick={() => {
                      setAttendance([0, 100]);
                      setAttendanceRange(true);
                    }}
                  />
                </div>

                <div className="data-statistics-sidebar-attendance-container">
                  <div className="data-statistics-sidebar-attendance-container-header">
                    <div className="data-statistics-sidebar-attendance-title-sub">
                      출석률
                    </div>

                    <div className="data-statistics-sidebar-attendance-box-area">
                      <div className="data-statistics-sidebar-attendance-box-item">
                        <font>{attendance[0]}%</font>

                        <div className="data-statistics-sidebar-attendance-box-btn">
                          <img
                            src="/img/icon/data/percent_up.png"
                            alt="up"
                            className="cursor"
                            onClick={() => {
                              if (
                                attendance[0] >= 100 ||
                                attendance[0] + 1 >= attendance[1]
                              ) {
                                return;
                              } else {
                                setAttendance([
                                  attendance[0] + 1,
                                  attendance[1],
                                ]);
                                setAttendanceRange(true);
                              }
                            }}
                          />
                          <img
                            src="/img/icon/data/percent_down.png"
                            alt="down"
                            className="cursor"
                            onClick={() => {
                              if (attendance[0] <= 0) {
                                return;
                              } else {
                                setAttendance([
                                  attendance[0] - 1,
                                  attendance[1],
                                ]);
                                setAttendanceRange(true);
                              }
                            }}
                          />
                        </div>
                      </div>

                      <font>~</font>

                      <div className="data-statistics-sidebar-attendance-box-item">
                        <font>{attendance[1]}%</font>

                        <div className="data-statistics-sidebar-attendance-box-btn">
                          <img
                            src="/img/icon/data/percent_up.png"
                            alt="up"
                            className="cursor"
                            onClick={() => {
                              if (attendance[1] >= 100) {
                                return;
                              } else {
                                setAttendance([
                                  attendance[0],
                                  attendance[1] + 1,
                                ]);
                                setAttendanceRange(true);
                              }
                            }}
                          />
                          <img
                            src="/img/icon/data/percent_down.png"
                            alt="down"
                            className="cursor"
                            onClick={() => {
                              if (
                                attendance[1] <= 0 ||
                                attendance[1] - 1 <= attendance[0]
                              ) {
                                return;
                              } else {
                                setAttendance([
                                  attendance[0],
                                  attendance[1] - 1,
                                ]);
                                setAttendanceRange(true);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="data-statistics-sidebar-slider-div">
                      <Slider
                        className={classes.blue}
                        value={attendance}
                        onChange={onChangeAttendance}
                        onChangeCommitted={onAttendanceCommit}
                        disableSwap
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="data-statistics-sidebar-duration">
                <div className="data-statistics-sidebar-duration-title">
                  <font>출석부</font>

                  <img
                    className="cursor"
                    src="/img/icon/data/refresh.png"
                    alt="refresh"
                    onClick={() => countReset()}
                  />
                </div>

                <div className="data-statistics-sidebar-duration-container">
                  <div className="data-statistics-sidebar-duration-container-header">
                    <div className="data-statistics-sidebar-duration-title-sub deepGreen">
                      출석
                    </div>

                    <div className="data-statistics-sidebar-attendance-box-area">
                      <div className="data-statistics-sidebar-attendance-box-item">
                        <font>{convertCount(attendanceCount[0])}</font>

                        <div className="data-statistics-sidebar-attendance-box-btn">
                          <img
                            src="/img/icon/data/percent_up.png"
                            alt="up"
                            className="cursor"
                            onClick={() => {
                              if (
                                attendanceCount[0] >=
                                  defaultAttendanceCount[1] ||
                                attendanceCount[0] + 1 >= attendanceCount[1]
                              ) {
                                return;
                              } else {
                                setAttendanceCount([
                                  attendanceCount[0] + 1,
                                  attendanceCount[1],
                                ]);
                                setAttendanceCountRange(true);
                              }
                            }}
                          />
                          <img
                            src="/img/icon/data/percent_down.png"
                            alt="down"
                            className="cursor"
                            onClick={() => {
                              if (
                                attendanceCount[0] <= 0 ||
                                attendanceCount[0] <= defaultAttendanceCount[0]
                              ) {
                                return;
                              } else {
                                setAttendanceCount([
                                  attendanceCount[0] - 1,
                                  attendanceCount[1],
                                ]);
                                setAttendanceCountRange(true);
                              }
                            }}
                          />
                        </div>
                      </div>

                      <font>~</font>

                      <div className="data-statistics-sidebar-attendance-box-item">
                        <font>{convertCount(attendanceCount[1])}</font>

                        <div className="data-statistics-sidebar-attendance-box-btn">
                          <img
                            src="/img/icon/data/percent_up.png"
                            alt="up"
                            className="cursor"
                            onClick={() => {
                              if (
                                attendanceCount[1] >= defaultAttendanceCount[1]
                              ) {
                                return;
                              } else {
                                setAttendanceCount([
                                  attendanceCount[0],
                                  attendanceCount[1] + 1,
                                ]);
                                setAttendanceCountRange(true);
                              }
                            }}
                          />
                          <img
                            src="/img/icon/data/percent_down.png"
                            alt="down"
                            className="cursor"
                            onClick={() => {
                              if (
                                attendanceCount[1] <=
                                  defaultAttendanceCount[0] ||
                                attendanceCount[1] - 1 <= attendanceCount[0]
                              ) {
                                return;
                              } else {
                                setAttendanceCount([
                                  attendanceCount[0],
                                  attendanceCount[1] - 1,
                                ]);
                                setAttendanceCountRange(true);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="data-statistics-sidebar-slider-div">
                      <Slider
                        className={classes.deepGreen}
                        min={defaultAttendanceCount[0]}
                        max={defaultAttendanceCount[1]}
                        value={attendanceCount}
                        onChange={onChangeAttendanceCount}
                        onChangeCommitted={onAttendanceCountCommit}
                        disableSwap
                      />
                    </div>
                  </div>
                </div>

                <div className="data-statistics-sidebar-duration-container">
                  <div className="data-statistics-sidebar-duration-container-header">
                    <div className="data-statistics-sidebar-duration-title-sub yellow">
                      지각
                    </div>

                    <div className="data-statistics-sidebar-attendance-box-area">
                      <div className="data-statistics-sidebar-attendance-box-item">
                        <font>{convertCount(tardyCount[0])}</font>

                        <div className="data-statistics-sidebar-attendance-box-btn">
                          <img
                            src="/img/icon/data/percent_up.png"
                            alt="up"
                            className="cursor"
                            onClick={() => {
                              if (
                                tardyCount[0] >= defaultTardyCount[1] ||
                                tardyCount[0] + 1 >= tardyCount[1]
                              ) {
                                return;
                              } else {
                                setTardyCount([
                                  tardyCount[0] + 1,
                                  tardyCount[1],
                                ]);
                                setTardyCountRange(true);
                              }
                            }}
                          />
                          <img
                            src="/img/icon/data/percent_down.png"
                            alt="down"
                            className="cursor"
                            onClick={() => {
                              if (
                                tardyCount[0] <= 0 ||
                                tardyCount[0] <= defaultTardyCount[0]
                              ) {
                                return;
                              } else {
                                setTardyCount([
                                  tardyCount[0] - 1,
                                  tardyCount[1],
                                ]);
                                setTardyCountRange(true);
                              }
                            }}
                          />
                        </div>
                      </div>

                      <font>~</font>

                      <div className="data-statistics-sidebar-attendance-box-item">
                        <font>{convertCount(tardyCount[1])}</font>

                        <div className="data-statistics-sidebar-attendance-box-btn">
                          <img
                            src="/img/icon/data/percent_up.png"
                            alt="up"
                            className="cursor"
                            onClick={() => {
                              if (tardyCount[1] >= defaultTardyCount[1]) {
                                return;
                              } else {
                                setTardyCount([
                                  tardyCount[0],
                                  tardyCount[1] + 1,
                                ]);
                                setTardyCountRange(true);
                              }
                            }}
                          />
                          <img
                            src="/img/icon/data/percent_down.png"
                            alt="down"
                            className="cursor"
                            onClick={() => {
                              if (
                                tardyCount[1] <= defaultTardyCount[0] ||
                                tardyCount[1] - 1 <= tardyCount[0]
                              ) {
                                return;
                              } else {
                                setTardyCount([
                                  tardyCount[0],
                                  tardyCount[1] - 1,
                                ]);
                                setTardyCountRange(true);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="data-statistics-sidebar-slider-div">
                      <Slider
                        className={classes.yellow}
                        min={defaultTardyCount[0]}
                        max={defaultTardyCount[1]}
                        value={tardyCount}
                        onChange={onChangeTardyCount}
                        onChangeCommitted={onTardyCountCommit}
                        disableSwap
                      />
                    </div>
                  </div>
                </div>

                <div className="data-statistics-sidebar-duration-container">
                  <div className="data-statistics-sidebar-duration-container-header">
                    <div className="data-statistics-sidebar-duration-title-sub orange-2">
                      결석
                    </div>

                    <div className="data-statistics-sidebar-attendance-box-area">
                      <div className="data-statistics-sidebar-attendance-box-item">
                        <font>{convertCount(absebceCount[0])}</font>

                        <div className="data-statistics-sidebar-attendance-box-btn">
                          <img
                            src="/img/icon/data/percent_up.png"
                            alt="up"
                            className="cursor"
                            onClick={() => {
                              if (
                                absebceCount[0] >= defaultAbsebceCount[1] ||
                                absebceCount[0] + 1 >= absebceCount[1]
                              ) {
                                return;
                              } else {
                                setAbsebceCount([
                                  absebceCount[0] + 1,
                                  absebceCount[1],
                                ]);
                                setAbsebceCountRange(true);
                              }
                            }}
                          />
                          <img
                            src="/img/icon/data/percent_down.png"
                            alt="down"
                            className="cursor"
                            onClick={() => {
                              if (
                                absebceCount[0] <= 0 ||
                                absebceCount[0] <= defaultAbsebceCount[0]
                              ) {
                                return;
                              } else {
                                setAbsebceCount([
                                  absebceCount[0] - 1,
                                  absebceCount[1],
                                ]);
                                setAbsebceCountRange(true);
                              }
                            }}
                          />
                        </div>
                      </div>

                      <font>~</font>

                      <div className="data-statistics-sidebar-attendance-box-item">
                        <font>{convertCount(absebceCount[1])}</font>

                        <div className="data-statistics-sidebar-attendance-box-btn">
                          <img
                            src="/img/icon/data/percent_up.png"
                            alt="up"
                            className="cursor"
                            onClick={() => {
                              if (absebceCount[1] >= defaultAbsebceCount[1]) {
                                return;
                              } else {
                                setAbsebceCount([
                                  absebceCount[0],
                                  absebceCount[1] + 1,
                                ]);
                                setAbsebceCountRange(true);
                              }
                            }}
                          />
                          <img
                            src="/img/icon/data/percent_down.png"
                            alt="down"
                            className="cursor"
                            onClick={() => {
                              if (
                                absebceCount[1] <= defaultAbsebceCount[0] ||
                                absebceCount[1] - 1 <= absebceCount[0]
                              ) {
                                return;
                              } else {
                                setAbsebceCount([
                                  absebceCount[0],
                                  absebceCount[1] - 1,
                                ]);
                                setAbsebceCountRange(true);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="data-statistics-sidebar-slider-div">
                      <Slider
                        className={classes.orange}
                        min={defaultAbsebceCount[0]}
                        max={defaultAbsebceCount[1]}
                        value={absebceCount}
                        onChange={onChangeAbsebceCount}
                        onChangeCommitted={onAbsebceCountCommit}
                        disableSwap
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </nav>
    </>
  );
}

export default DataStatisticsSideBar;
