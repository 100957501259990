import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserInfo } from "actions/index";
import Alert from "component/tag/modal/alert";
import Loading from 'component/tag/loading/loading';
import axios from 'axios';
/**
 * Version : 1.0
 * 파일명 : ProfileLanguagePopup.js
 * 작성일자 : 2022-01-13
 * 작성자 : 권도훈
 * 설명 : 사용자 언어 설정 팝업
 * 수정일자 :  2022-01-24
 * 수정자 : 권도훈
 * 수정내역 : 영어 선택 불가 처리
 */
function ProfileLanguagePopup(props) {
    /**
     * REDUX
     */
    const USER_STATE = useSelector(state => state.userInfoReducer);
    /**
     * DISPATCH
     */
    const dispatch = useDispatch();
    /**
     * TRANSLATION
     */
    const { t, i18n } = useTranslation();
    /**
     * STATE
     */
    const [loading, setLoading] = useState(false);
    const [showSelectBox, setShowSelectBox] = useState(false);
    const [language, setLanguage] = useState("");
    const [languageList, setLanguageList] = useState([]);
    /**
     * ALERT
     */
    const [alertProps, setAlertProps] = useState({
        text: "",
        isShow: false,
        cancelBtnDisplay: false,
        okBtnDisplay: true,
        okBtnEventHandler: function () { },
        cancleBtnEventHandler: function () {
            closeAlertEvent();
        },
    });
    /**
     * CLOSE ALERT
     */
    const closeAlertEvent = useCallback(() => {
        setAlertProps({
            ...alertProps,
            isShow: false,
        });
    }, [alertProps]);
    /**
     * SET STATE
     */
    useEffect(() => {
        if (!props.languagePopup) {
            setLanguage(USER_STATE.LANGUAGE);
            setLanguageList([
                { VALUE: "ko", TITLE: t("my.profile.txt.korean") },
                // { VALUE: "en", TITLE: t("my.profile.txt.english") }
            ])
            setShowSelectBox(false);
        }
    }, [USER_STATE.LANGUAGE, props.languagePopup, t]);
    /**
     * USER LANGUAGE CONVERTER
     */
    const converterTitle = () => {
        if (language === "ko") {
            return t("my.profile.txt.korean");
        } else if (language === "en") {
            return t("my.profile.txt.english");
        } else {
            return ""
        }
    }
    /**
     * SAVE TIMEZONE
     */
    const saveLanguage = () => {
        setAlertProps({
            ...alertProps,
            text: t("my.profile.msg.saveLanguage"),
            isShow: true,
            cancelBtnDisplay: true,
            okBtnEventHandler: function () {
                closeAlertEvent();

                if (language === USER_STATE.LANGUAGE) {
                    return;
                }
                setLoading(true);

                const config = {
                    params: {
                        LANGUAGE: language
                    }
                }

                axios.post("/user/language", null, config)
                    .then(res => {
                        if (res.status === 200) {
                            const obj = {
                                ...USER_STATE,
                                LANGUAGE: language
                            }
                            dispatch(updateUserInfo(obj));
                            i18n.changeLanguage(language);
                            props.setLanguagePopup(false);
                            setAlertProps({
                                ...alertProps,
                                isShow: true,
                                text: t("my.profile.msg.successLanguage"),
                                cancelBtnDisplay: false,
                                okBtnEventHandler: function () {
                                    closeAlertEvent();
                                },
                            });
                        }
                        setLoading(false);
                    })
                    .catch(() => {
                        setLoading(false);
                        setAlertProps({
                            ...alertProps,
                            isShow: true,
                            text: t("my.profile.msg.failLanguage"),
                            cancelBtnDisplay: false,
                            okBtnEventHandler: function () {
                                closeAlertEvent();
                            },
                        });
                    })
            },
            cancleBtnEventHandler: function () {
                closeAlertEvent();
            }
        });
    }
    return (
        <>
            <Alert alertProps={alertProps} />
            {
                loading
                    ?
                    <div
                        style={{
                            height: "100%"
                        }}
                    >
                        <Loading />
                    </div >
                    :
                    <div
                        style={{
                            margin: "12px 16px 16px 16px",
                            // height: "118px"
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            justifyContent: "center"
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "100%"
                            }}
                        >
                            <div
                                style={{
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    color: "#3E3E4F"
                                }}
                            >
                                {t("my.profile.txt.selectLanguage")}
                            </div>
                            <img
                                className='cursor'
                                src="/img/icon/profile/close.png"
                                alt="X"
                                onClick={() => props.setLanguagePopup(false)}
                            />
                        </div>

                        <div
                            style={{
                                display: "flex",
                                width: "100%",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginTop: "8px",
                                marginBottom: "16px"
                            }}
                        >
                            <div
                                style={{
                                    width: "256px",
                                    height: "36px",
                                    background: "#FFFFFF",
                                    border: "1px solid #D1D3DA",
                                    borderRadius: "8px"
                                }}
                            >
                                <div
                                    style={{
                                        padding: "8px 16px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between"
                                    }}
                                    className='cursor'
                                    onClick={() => setShowSelectBox(!showSelectBox)}
                                >
                                    <div
                                        style={{
                                            fontSize: "14px",
                                            color: "#3E3E4F"
                                        }}
                                    >
                                        {converterTitle()}
                                    </div>
                                    <img
                                        src={
                                            showSelectBox
                                                ? "/img/icon/profile/up.png"
                                                : "/img/icon/profile/drop.png"
                                        }
                                        alt="DROP"
                                    />
                                </div>

                                <div
                                    style={{
                                        padding: "8px 16px",
                                        display: showSelectBox ? "flex" : "none",
                                        boxShadow: "0px 5px 10px #00000029",
                                        border: "1px solid #D1D3DA",
                                        borderRadius: "8px",
                                        position: "absolute",
                                        inset: "0px auto auto 0px",
                                        transform: "translate(15px, 83px)",
                                        width: "224px",
                                        alignItems: "flex-start",
                                        flexDirection: "column",
                                        background: "#FFFFFF",
                                        maxHeight: "150px",
                                        overflowY: "auto"
                                    }}
                                    className='cursor'
                                    onClick={() => setShowSelectBox(false)}
                                >
                                    {
                                        languageList.map((data, idx) => {
                                            if (data.VALUE !== language) {
                                                return (
                                                    <div
                                                        key={"LANGUAGE_LIST_" + idx}
                                                        style={{
                                                            fontSize: "14px",
                                                            color: "#3E3E4F",
                                                            padding: "5px 0",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            borderBottom: "1px solid #e7e7e7",
                                                            width: "100%"
                                                        }}
                                                        onClick={() => setLanguage(data.VALUE)}
                                                    >
                                                        {data.TITLE}
                                                    </div>
                                                )
                                            } else {
                                                return (
                                                    null
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </div>
                        </div>

                        <div>
                            <button
                                className='cursor'
                                onClick={() => props.setLanguagePopup(false)}
                                style={{
                                    width: "64px",
                                    height: "36px",
                                    background: "#3E3E4F",
                                    borderRadius: "8px",
                                    border: "none",
                                    outline: "none",
                                    color: "#FFFFFF",
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    marginRight: "8px"
                                }}
                            >
                                {t("common.btn.cancle")}
                            </button>

                            <button
                                className='cursor'
                                style={{
                                    width: "64px",
                                    height: "36px",
                                    background: "#D25EB2",
                                    borderRadius: "8px",
                                    border: "none",
                                    outline: "none",
                                    color: "#FFFFFF",
                                    fontSize: "14px",
                                    fontWeight: "bold"
                                }}
                                onClick={() => saveLanguage()}
                            >
                                {t("common.btn.save")}
                            </button>
                        </div>
                    </div>
            }
        </>
    )
}

export default ProfileLanguagePopup
