import React from "react";
import ReactModal from "react-modal";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
/**
 * Version : 1.0
 * 파일명 : groupMeetingHostDeletePopup.js
 * 작성일자 : 2021-12-02
 * 작성자 : 강연승
 * 설명 : 그룹프로필 - 그룹 미팅호스트 삭제 팝업
 * 수정일자 : 2022-02-24
 * 수정자 : 강연승
 * 수정내역 : 닫기 아이콘 제거
 */
function GroupMeetingHostDeletePopup(props) {
  /**
   * body
   */
  ReactModal.setAppElement("body");
  /**
   * 언어변환
   */
  const { t } = useTranslation();
  /* 그룹 카테고리 코드 변환 */
  const groupCategoryConvert = ctgryCD => {
    switch (ctgryCD) {
      // case "CD0401":
      //   return "default";
      case "CD0402":
        return "company";
      case "CD0403":
        return "school";
      case "CD0404":
        return "private_institute";
      case "CD0405":
        return "university";
      case "CD0406":
        return "social";
      default:
        return "company";
    }
  };
  /**
   * REDUX
   */
  const groupInfoState = useSelector(state => state.groupInfoReducer);
  const dialogClose = () => {
    document.body.style.overflow = "unset";
    props.close();
  };
  return (
    <ReactModal
      isOpen={props.open} //모달 Open(Boolean)
      className="groupMeetingHostDeletePopup" //모달 ClassName
      overlayClassName="groupMeetingHostDeletePopup_Overlay" //모달오버레이 ClassName
      onRequestClose={dialogClose}
      shouldCloseOnEsc={true}
      animationType="fade"
      transparent={true}
      closeTimeoutMS={200}
    >
      <div className="groupMeetingHostDeletePopup_header">
        <div style={{ display: "inline-block" }}>
          {t("group.profile.meetingHostSetting.deleteHostTitle", {
            MEETING_HOST: t(
              "group.thema." +
                groupCategoryConvert(groupInfoState.GROUP_CATEGORY) +
                ".meetingHost"
            ),
          })}
        </div>
      </div>
      <div
        style={{
          color: "#3E3E4F",
          marginTop: "24px",
          font: "normal normal bold 16px/24px Spoqa Han Sans Neo",
        }}
      >
        <div>
          {t("group.profile.meetingHostSetting.deleteHostText1", {
            USER_NM: props.meetingHost.USER_NM,
            GROUP_NM: groupInfoState.GROUP_NM,
          })}
        </div>
        <div>{t("group.profile.meetingHostSetting.deleteHostText2")}</div>
      </div>
      <div
        style={{
          color: "#2B4AC4",
          marginTop: "24px",
          font: "normal normal bold 14px/21px Spoqa Han Sans Neo",
        }}
      >
        {t("group.profile.meetingHostSetting.deleteHostText3")}
      </div>

      <div className="text-center" style={{ margin: "40px 0" }}>
        <button
          style={{
            width: "64px",
            height: "48px",
            backgroundColor: "white",
            border: "1px solid #2B4AC4",
            color: "#2B4AC4",
            font: "normal normal bold 14px/21px Spoqa Han Sans Neo",
            cursor: "pointer",
            borderRadius: "10px",
          }}
          onClick={() =>
            props.deleteMeetingHost(
              props.meetingHost.MEMBER_NUM,
              props.meetingHost.USER_NM
            )
          }
        >
          {t("common.btn.delete")}
        </button>
        <button
          style={{
            width: "64px",
            height: "48px",
            backgroundColor: "white",
            border: "1px solid #C63353",
            color: "#C63353",
            font: "normal normal bold 14px/21px Spoqa Han Sans Neo",
            cursor: "pointer",
            borderRadius: "10px",
            marginLeft: "16px",
          }}
          onClick={dialogClose}
        >
          {t("common.btn.cancle")}
        </button>
      </div>
    </ReactModal>
  );
}

export default GroupMeetingHostDeletePopup;
