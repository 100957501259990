/**
 * Import List
 */
import React, { useCallback, useEffect, useState } from "react";
import Calendar from "component/common/calendar_item";
import Bookmark from "./bookmark";
import ScheduleDetail from "./scheduleDetail";
import NoLicensePopup from "component/tag/popup/license/noLicensePopup";
import axios from "axios";
import FailReservationPopup from "component/tag/popup/schedule/failReservationPopup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  updateDateTimeInfo,
  updateLicenseCntInfo,
  updateGroupInfo,
  openAlert,
  closeAlert,
} from "actions/index";
import Loading from "component/tag/loading/newLoading";
/**
 * Version : 1.0
 * 파일명 : main.js
 * 작성일자 : 2021-10-05
 * 작성자 : 권도훈
 * 설명 : 사용자 - 마이 페이지 메인 화면
 * 수정일자 : 2022-10-27
 * 수정자 : 강연승
* 수정내역 : 종료시간 예외 케이스 추가
 * props :
 * 전달받은값 - 설명
 */
function Main(props) {
  /**
   * 언어변환
   */
  const { t } = useTranslation();
  /**
   * 글로벌 스테이트
   */
  const userInfoState = useSelector(state => state.userInfoReducer);
  const dateTimeState = useSelector(state => state.dateTimeInfoReducer);
  const licenseCntState = useSelector(state => state.licenseCntInfoReducer);
  const calendarRangeState = useSelector(
    state => state.calendarRangeInfoReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const config = {
      GROUP_NUM: "",
      GROUP_NM: "",
      GROUP_COLOR: "",
      AUTH_CD: "",
      GROUP_CATEGORY: "",
      GROUP_IMG: "",
      GROUP_YN: "N",
      REC_YN: "",
      DOWNLOAD_YN: "",
      PLAY_YN: "",
      HOST_CNT: "0",
      LICENSE_CNT: "0",
      D_DAY: "",
      CREATE_DT: "",
    };
    dispatch(updateGroupInfo(config));
  }, []);

  const [viewType, setViewType] = useState(true);
  const changeViewType = () => {
    if (props.isInput) {
      return;
    }
    if (viewType) {
      setViewType(false);
    } else if (!viewType) {
      setViewType(true);
    }
  };

  const [schedules, setSchedules] = useState([]); //조회된 스케줄 목록
  const [scheduleInfo, setScheduleInfo] = useState({
    id: "",
    title: "",
    displayDate: "",
    displayTime: "",
    startTime: "",
    endTime: "",
    content: "",
    apiType: "",
    recYN: "",
    fileInfoList: [],
  });

  const [scheduleParticipants, setScheduleParticipants] = useState([]);
  const [detailOpen, setDetailOpen] = useState(false);

  useEffect(() => {
    props.setMeetingInfo({
      id: scheduleInfo.id,
      title: scheduleInfo.title,
      displayDate: scheduleInfo.displayDate,
      displayTime: scheduleInfo.displayTime,
      startTime: scheduleInfo.startTime,
      endTime: scheduleInfo.endTime,
      participant: scheduleParticipants,
      content: scheduleInfo.content,
      apiType: scheduleInfo.apiType,
      recYN: scheduleInfo.recYN,
      fileInfoList: scheduleInfo.fileInfoList,
    });
  }, [scheduleInfo.displayDate, scheduleInfo.displayTime]);

  useEffect(() => {
    props.setMeetingInfo({
      id: scheduleInfo.id,
      title: scheduleInfo.title,
      displayDate: scheduleInfo.displayDate,
      displayTime: scheduleInfo.displayTime,
      startTime: scheduleInfo.startTime,
      endTime: scheduleInfo.endTime,
      participant: scheduleParticipants,
      content: scheduleInfo.content,
      apiType: scheduleInfo.apiType,
      recYN: scheduleInfo.recYN,
      fileInfoList: scheduleInfo.fileInfoList,
    });
  }, [scheduleInfo.displayDate]);

  // useEffect(() => {
  //   if (props.calendarApi !== undefined) {
  //     let activeStart = props.calendarApi.view.activeStart
  //       .toISOString()
  //       .replace(/\..*/, "");
  //     let activeEnd = props.calendarApi.view.activeEnd
  //       .toISOString()
  //       .replace(/\..*/, "");
  //     console.log(activeStart, activeEnd);
  //     getSchedules(activeStart, activeEnd);
  //   }
  // }, [props.calendarApi]);

  useEffect(() => {
    if (props.calendarApi !== undefined) {
      getSchedules(
        calendarRangeState.ACTIVE_START,
        calendarRangeState.ACTIVE_END
      );
    }
    getLicenseInfo();
    setScheduleInfo({});
    setScheduleParticipants([]);
  }, [props.dataState]);

  const [noLicensePopup, setNoLicensePopup] = useState(false);
  const openNoLicensePopup = () => {
    setNoLicensePopup(true);
  };
  const closeNoLicensePopup = () => {
    setNoLicensePopup(false);
  };
  const getLicenseInfo = () => {
    axios
      .get("/license/selectLicenseCnt", {
        params: {
          GROUP_ASSIGNED_YN: "N",
        },
      })
      .then(res => {
        if (res.status === 200) {
          const obj = {
            GROUP_YN: "N",
            FREE_CNT: res.data.FREE_CNT,
            PAY_CNT: res.data.PAY_CNT,
          };
          dispatch(updateLicenseCntInfo(obj));
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
  const licenseCheck = () => {
    if (licenseCntState.FREE_CNT > 0 || licenseCntState.PAY_CNT > 0) {
      return true;
    } else {
      return false;
    }
  };

  const getSchedules = (start, end) => {
    props.setLoading(true);
    axios
      .get("/schedule/selectMySchedule", {
        params: {
          START_TIME: start,
          END_TIME: end,
        },
      })
      .then(res => {
        if (res.status === 200) {
          props.setLoading(false);
          let schedule_list = [];
          schedule_list = res.data[0].SCHEDULE.map(data => {
            if (data.FILE_INFO !== null || data.FILE_INFO !== undefined) {
              if (data.GROUP_YN === "Y") {
                return {
                  id: data.SCHEDULE_NUM,
                  title: data.TITLE,
                  start: data.START_TIME,
                  end: data.END_TIME,
                  backgroundColor: groupColor(data.GROUP_COLOR),
                  borderColor: groupColor(data.GROUP_COLOR),
                  extendedProps: {
                    contents: data.CONTENTS,
                    inviteURL: data.INVITE_URL,
                    meetingKey: data.MEETING_KEY,
                    hostKey: data.HOST_KEY,
                    hostYN: data.HOST_YN,
                    groupYN: data.GROUP_YN,
                    groupNum: data.GROUP_NUM,
                    groupNm: data.GROUP_NM,
                    groupColor: data.GROUP_COLOR,
                    fileInfo: data.FILE_INFO,
                    apiType: data.API_TYPE,
                    recYN: data.REC_YN,
                    hostNm: data.HOST_NM,
                    hostId: data.HOST_ID,
                    meetingPW: data.MEETING_PW,
                    sipUrl: data.SIP_URL,
                    meetingLink: data.MEETING_LINK,
                  },
                };
              } else {
                return {
                  id: data.SCHEDULE_NUM,
                  title: data.TITLE,
                  start: data.START_TIME,
                  end: data.END_TIME,
                  extendedProps: {
                    contents: data.CONTENTS,
                    inviteURL: data.INVITE_URL,
                    meetingKey: data.MEETING_KEY,
                    hostKey: data.HOST_KEY,
                    hostYN: data.HOST_YN,
                    groupYN: data.GROUP_YN,
                    fileInfo: data.FILE_INFO,
                    apiType: data.API_TYPE,
                    recYN: data.REC_YN,
                    hostNm: data.HOST_NM,
                    hostId: data.HOST_ID,
                    meetingPW: data.MEETING_PW,
                    sipUrl: data.SIP_URL,
                    meetingLink: data.MEETING_LINK,
                  },
                };
              }
            } else {
              if (data.GROUP_YN === "Y") {
                return {
                  id: data.SCHEDULE_NUM,
                  title: data.TITLE,
                  start: data.START_TIME,
                  end: data.END_TIME,
                  backgroundColor: groupColor(data.GROUP_COLOR),
                  borderColor: groupColor(data.GROUP_COLOR),
                  extendedProps: {
                    contents: data.CONTENTS,
                    inviteURL: data.INVITE_URL,
                    meetingKey: data.MEETING_KEY,
                    hostKey: data.HOST_KEY,
                    hostYN: data.HOST_YN,
                    groupYN: data.GROUP_YN,
                    groupNum: data.GROUP_NUM,
                    groupNm: data.GROUP_NM,
                    groupColor: data.GROUP_COLOR,
                    apiType: data.API_TYPE,
                    recYN: data.REC_YN,
                    hostNm: data.HOST_NM,
                    hostId: data.HOST_ID,
                    meetingPW: data.MEETING_PW,
                    sipUrl: data.SIP_URL,
                    meetingLink: data.MEETING_LINK,
                  },
                };
              } else {
                return {
                  id: data.SCHEDULE_NUM,
                  title: data.TITLE,
                  start: data.START_TIME,
                  end: data.END_TIME,
                  extendedProps: {
                    contents: data.CONTENTS,
                    inviteURL: data.INVITE_URL,
                    meetingKey: data.MEETING_KEY,
                    hostKey: data.HOST_KEY,
                    hostYN: data.HOST_YN,
                    groupYN: data.GROUP_YN,
                    apiType: data.API_TYPE,
                    recYN: data.REC_YN,
                    hostNm: data.HOST_NM,
                    hostId: data.HOST_ID,
                    meetingPW: data.MEETING_PW,
                    sipUrl: data.SIP_URL,
                    meetingLink: data.MEETING_LINK,
                  },
                };
              }
            }
          });
          setSchedules(schedule_list);
        }
      })
      .catch(error => {
        props.setLoading(false);
        console.log(error);
      });
  };

  const groupColor = color => {
    switch (color) {
      case "red":
        return "#D4667E";
      case "orange":
        return "#D77953";
      case "yellow":
        return "#cdb14c";
      case "bean":
        return "#97C12A";
      case "green":
        return "#64d483";
      case "mint":
        return "#6cd6bf";
      case "pine":
        return "#75BDD9";
      case "pink":
        return "#cb86dd";
      case "purple":
        return "#a994e1";
      case "black":
        return "#3b3f4c";
      default:
        return "#6E85D7";
    }
  };

  const handleSelectTime = time => {
    if (!licenseCheck()) {
      props.calendarApi.unselect();
      openNoLicensePopup();
      return;
    } else {
      if (time.end - time.start > 86400000) {
        props.calendarApi.unselect();
        const config = {
          ...dateTimeState,
          END_DATE: dateTimeState.START_DATE,
          END_TIME: "",
        };
        dispatch(updateDateTimeInfo(config));
        const obj = {
          TEXT: t("schedule.msg.overScheduleTime"),
          submitEventHandler: () => dispatch(closeAlert()),
        };
        dispatch(openAlert(obj));
        return;
      }
      let selectTime = time.startStr;
      let selectStart = time.startStr.replace(/\+.*/, "");
      let selectEnd = time.endStr.replace(/\+.*/, "");
      if (
        selectStart <
        new Date(new Date() * 1 + 3600000 * parseFloat(userInfoState.OFFSET))
          .toISOString()
          .replace(/\..*/, "")
      ) {
        props.calendarApi.unselect();
        return;
      }
      const config = {
        INPUT_TYPE: "calendar",
        START_DATE: selectStart.replace(/\T.*/, ""),
        END_DATE: selectEnd.replace(/\T.*/, ""),
        START_TIME: selectStart,
        END_TIME: selectEnd,
      };
      dispatch(updateDateTimeInfo(config));
      if (props.resSidebarType === "res" || props.resSidebarType === "edit") {
        setScheduleInfo({
          displayDate: displayDateFormat(selectTime),
          displayTime: displayTimeFormat(selectStart, selectEnd),
          startTime: selectStart,
          endTime: selectEnd,
        });
      }
      if (props.isInput === false) {
        props.setIsInput(true);
      }
    }
  };

  const handleClickDate = date => {
    if (props.isInput === true) {
      if (date.dateStr < new Date().toISOString().replace(/\T.*/, "")) {
        const obj = {
          TEXT: t("schedule.msg.prevDateSelect"),
          submitEventHandler: () => dispatch(closeAlert()),
        };
        dispatch(openAlert(obj));
        return;
      }
    }
    let selectDate = date.dateStr.replace(/\+.*/, "");
    props.calendarApi.changeView("timeGridWeek", selectDate);
    const config = {
      ...dateTimeState,
      INPUT_TYPE: "calendar",
      START_DATE: selectDate,
      END_DATE: selectDate,
    };
    dispatch(updateDateTimeInfo(config));
  };

  function displayDateFormat(date) {
    let mon =
      new Date(date).getMonth() + 1 < 10
        ? "0" + (new Date(date).getMonth() + 1)
        : new Date(date).getMonth() + 1;
    let dt =
      new Date(date).getDate() < 10
        ? "0" + new Date(date).getDate()
        : new Date(date).getDate();
    let e = new Date(date).getDay();
    let e_ko;
    if (e === 0) {
      e_ko = t("date.sunday");
    } else if (e === 1) {
      e_ko = t("date.monday");
    } else if (e === 2) {
      e_ko = t("date.tuesday");
    } else if (e === 3) {
      e_ko = t("date.wednesday");
    } else if (e === 4) {
      e_ko = t("date.thursday");
    } else if (e === 5) {
      e_ko = t("date.friday");
    } else if (e === 6) {
      e_ko = t("date.saturday");
    }
    return mon + t("date.month") + dt + t("date.date") + e_ko;
  }

  function displayTimeFormat(start, end) {
    let s_time = new Date(start);
    let e_time = new Date(end);
    let s_hour = s_time.getHours();
    let e_hour = e_time.getHours();
    let s_min =
      s_time.getMinutes() < 10
        ? "0" + s_time.getMinutes()
        : s_time.getMinutes();
    let e_min =
      e_time.getMinutes() < 10
        ? "0" + e_time.getMinutes()
        : e_time.getMinutes();
    if (s_hour > 12) {
      s_hour = t("date.pm_kr") + (s_hour - 12);
    } else {
      if (s_hour === 12) {
        s_hour = t("date.pm_kr") + s_hour;
      } else {
        s_hour = t("date.am_kr") + s_hour;
      }
    }
    if (e_hour > 12) {
      e_hour = t("date.pm_kr") + (e_hour - 12);
    } else {
      if (e_hour === 12) {
        e_hour = t("date.pm_kr") + e_hour;
      } else {
        e_hour = t("date.am_kr") + e_hour;
      }
    }
    return (
      s_hour +
      t("date.hour") +
      s_min +
      t("date.minute") +
      "~ " +
      e_hour +
      t("date.hour") +
      e_min +
      t("date.minute")
    );
  }

  const [detailType, setDetailType] = useState();
  const handleClickSchedule = schedule => {
    if (props.isInput === true) {
      return;
    }
    if (props.calendarApi !== null && props.calendarApi !== undefined) {
      props.calendarApi.unselect();
    }
    let startTime = new Date(schedule.start).toISOString().replace(/\..*/, "");
    let endTime = schedule.start > schedule.end ? new Date(schedule.start).toISOString().replace(/\..*/, "") : new Date(schedule.end).toISOString().replace(/\..*/, "");
    if (
      startTime <
        new Date(new Date() * 1 + 3600000 * parseFloat(userInfoState.OFFSET))
          .toISOString()
          .replace(/\..*/, "") &&
      new Date(new Date() * 1 + 3600000 * parseFloat(userInfoState.OFFSET))
        .toISOString()
        .replace(/\..*/, "") < endTime
    ) {
      setDetailType("meeting");
    } else if (
      startTime <
      new Date(new Date() * 1 + 3600000 * parseFloat(userInfoState.OFFSET))
        .toISOString()
        .replace(/\..*/, "")
    ) {
      setDetailType("history");
      const config = {
        INPUT_TYPE: "calendar",
        START_DATE: "",
        END_DATE: "",
        START_TIME: "",
        END_TIME: "",
      };
      dispatch(updateDateTimeInfo(config));
    } else {
      setDetailType("timeline");
      const config = {
        ...dateTimeState,
        INPUT_TYPE: "calendar",
        START_DATE: startTime.replace(/\T.*/, ""),
        END_DATE: endTime.replace(/\T.*/, ""),
        START_TIME: startTime,
        END_TIME: endTime,
      };
      dispatch(updateDateTimeInfo(config));
    }

    let tmpScheduleInfo = {
      id: schedule.id,
      title: schedule.title,
      displaydate: displayDateFormat(startTime),
      displayTime: displayTimeFormat(startTime, endTime),
      startTime: startTime,
      endTime: endTime,
      duration: scheduleDuration(startTime, endTime),
      isJoin: meetingJoin(startTime, endTime),
      content: schedule.extendedProps.contents,
      inviteURL: schedule.extendedProps.inviteURL,
      meetingKey: schedule.extendedProps.meetingKey,
      // hostKey: schedule.extendedProps.hostKey,
      hostYN: schedule.extendedProps.hostYN,
      groupYN: schedule.extendedProps.groupYN,
      groupNum: schedule.extendedProps.groupNum,
      groupNm: schedule.extendedProps.groupNm,
      apiType: schedule.extendedProps.apiType,
      recYN: schedule.extendedProps.recYN,
      groupColor: schedule.extendedProps.groupColor,
      hostNm: schedule.extendedProps.hostNm,
      hostId: schedule.extendedProps.hostId,
      meetingPW: schedule.extendedProps.meetingPW,
      sipUrl: schedule.extendedProps.sipUrl,
      meetingLink: schedule.extendedProps.meetingLink,
    };

    if (
      schedule.extendedProps.fileInfo !== null &&
      schedule.extendedProps.fileInfo !== undefined &&
      schedule.extendedProps.fileInfo !== ""
    ) {
      const scheduleFileList = schedule.extendedProps.fileInfo.split("|");
      let rawfileInfo = [];
      let fileInfoList = [];

      for (let i = 0; i < scheduleFileList.length; i++) {
        rawfileInfo = scheduleFileList[i].split(";");
        const fileInfo = {
          fileNum: rawfileInfo[0],
          fileNm: rawfileInfo[1],
          fileURL: rawfileInfo[2],
          fileDownNm: rawfileInfo[3],
          fileSize: rawfileInfo[4],
        };
        fileInfoList.push(fileInfo);
      }
      tmpScheduleInfo = {
        ...tmpScheduleInfo,
        fileInfoList: fileInfoList,
      };
    }
    axios
      .get("/schedule/selectScheduleParticipants", {
        params: {
          schedule_num: schedule.id,
        },
      })
      .then(({ data }) => setScheduleParticipants(data))
      .catch(error => {
        console.log(error);
      });
    if (
      schedule.extendedProps.apiType === "CD0501" &&
      endTime >
        new Date(new Date() * 1 + 3600000 * parseFloat(userInfoState.OFFSET))
          .toISOString()
          .replace(/\..*/, "")
    ) {
      props.setLoading(true);
      axios
        .get("/schedule/getWebexHostKey", {
          params: {
            schedule_num: schedule.id,
          },
        })
        .then(res => {
          if (res.status === 200) {
            props.setLoading(false);
            tmpScheduleInfo = {
              ...tmpScheduleInfo,
              hostKey: res.data,
            };
            setScheduleInfo(tmpScheduleInfo);
            handleDetailOpen();
          }
        })
        .catch(error => {
          props.setLoading(false);
          console.log(error);
        });
    } else {
      tmpScheduleInfo = {
        ...tmpScheduleInfo,
        hostKey: schedule.extendedProps.hostKey,
      };
      setScheduleInfo(tmpScheduleInfo);
      handleDetailOpen();
    }
  };

  const handleEditSchedule = (
    id,
    title,
    startTime,
    endTime,
    content,
    participants,
    apiType,
    fileInfoList,
    recYN
  ) => {
    props.setMeetingInfo({
      id: id,
      title: title,
      displayDate: displayDateFormat(startTime),
      displayTime: displayTimeFormat(startTime, endTime),
      startTime: startTime,
      endTime: endTime,
      participant: participants,
      content: content,
      apiType: apiType,
      fileInfoList: fileInfoList,
      recYN: recYN,
    });
    setScheduleParticipants(participants);
    props.calendarApi.changeView("timeGridWeek", startTime);
    props.setIsInput(true);
    props.setResSidebarType("edit");
  };

  const handleResSchedule = (title, content, participants) => {
    props.setMeetingInfo({
      title: title,
      participant: participants,
      content: content,
    });
    setScheduleParticipants(participants);
    // props.calendarApi.changeView("dayGridMonth", new Date());
    props.setIsInput(true);
    props.setResSidebarType("res");
  };

  const handleDetailOpen = () => {
    setDetailOpen(true);
  };

  const handleDetailClose = () => {
    setScheduleParticipants([]);
    setDetailOpen(false);
  };

  const scheduleDuration = (start, end) => {
    var startTime = new Date(start);
    var endTime = new Date(end);
    var duration = endTime - startTime;

    if(duration < 0){
      return 0;
    }

    var hour = Math.floor(
      (duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minute = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
    var second = Math.floor((duration % (1000 * 60)) / 1000);

    if (hour === 0) {
      //1시간 미만일 때
      if (minute === 0) {
        return second + t("date.second");
      } else {
        if (second === 0) {
          return minute + t("date.minute");
        } else {
          return minute + t("date.minute") + second + t("date.second");
        }
      }
    } else {
      //1시간 이상일 때
      if (minute === 0) {
        if (second === 0) {
          return hour + t("date.durationHour");
        } else {
          return hour + t("date.durationHour") + second + t("date.second");
        }
      } else {
        if (second === 0) {
          return hour + t("date.durationHour") + minute + t("date.minute");
        } else {
          return (
            hour +
            t("date.durationHour") +
            minute +
            t("date.minute") +
            second +
            t("date.second")
          );
        }
      }
    }
  };

  const meetingJoin = (start, end) => {
    let preMeeingTime = new Date(start) - new Date() <= 300000;
    let duration = new Date() < new Date(end);
    if (preMeeingTime && duration) {
      //5분 300000 10분 600000 30분 1800000
      return true;
    } else {
      return false;
    }
  };

  const [dropLoading, setDropLoading] = useState(false);
  const saveEventDrop = useCallback(async (info, type) => {
    // props.setLoading(true);
    setDropLoading(true);
    let startTime;
    let endTime;
    if (type === "drop") {
      startTime = new Date(info.event.start).toISOString().replace(/\..*/, "");
      endTime = new Date(info.event.end).toISOString().replace(/\..*/, "");
    } else if (type === "resize") {
      startTime = new Date(info.oldEvent.start)
        .toISOString()
        .replace(/\..*/, "");
      endTime = new Date(info.event.end).toISOString().replace(/\..*/, "");
    }
    const formData = new FormData();
    let scheduleInfo = {
      SCHEDULE_NUM: info.event.id,
      TITLE: info.event.title,
      START_TIME: startTime,
      END_TIME: endTime,
      CONTENTS: info.event.extendedProps.contents,
      API_TYPE: info.event.extendedProps.apiType,
      GROUP_YN: info.event.extendedProps.groupYN,
      REC_YN: info.event.extendedProps.recYN,
    };
    if (info.event.extendedProps.groupYN === "Y") {
      scheduleInfo = {
        ...scheduleInfo,
        GROUP_NUM: info.event.extendedProps.groupNum,
      };
    }
    formData.append("scheduleInfo", JSON.stringify(scheduleInfo));
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    await axios
      .put("/schedule/updateSchedule", formData, config)
      .then(response => {
        if (response.status === 200) {
          // props.setLoading(false);
          setDropLoading(false);
          if (response.data[0].hasOwnProperty("MSG")) {
            if (
              response.data[0].MSG === "parti_add" ||
              response.data[0].MSG === "schedule_new" ||
              response.data[0].MSG === "api_call" ||
              response.data[0].MSG === "account_limit"
            ) {
              const obj = {
                TEXT: t("schedule.msg." + response.data[0].MSG),
                submitEventHandler: () => {
                  dispatch(closeAlert());
                },
              };
              dispatch(openAlert(obj));
              return;
            } else {
              openFailPopup(response.data[0].MSG);
              return;
            }
          }
          const obj = {
            TEXT: t("schedule.msg.successEditSchedule"),
            submitEventHandler: () => {
              dispatch(closeAlert());
              props.handleDataState();
              setSchedules([]);
              getSchedules(
                calendarRangeState.ACTIVE_START,
                calendarRangeState.ACTIVE_END
              );
            },
          };
          dispatch(openAlert(obj));
        }
      })
      .catch(error => {
        // props.setLoading(false);
        setDropLoading(false);
        console.log(error);
      });
  });

  useEffect(() => {
    if (dateTimeState.INPUT_TYPE === "calendar") {
      return;
    }
    if (
      dateTimeState.START_DATE !== "" &&
      dateTimeState.START_DATE !== undefined
    ) {
      props.calendarApi.changeView("timeGridWeek", dateTimeState.START_DATE);
      if (
        dateTimeState.END_TIME !== "" &&
        dateTimeState.END_TIME !== undefined
      ) {
        if (
          props.calendarApi.view.activeStart.toISOString() <
            dateTimeState.START_TIME &&
          dateTimeState.END_TIME <
            props.calendarApi.view.activeEnd.toISOString()
        ) {
          props.calendarApi.select({
            start: dateTimeState.START_TIME,
            end: dateTimeState.END_TIME,
          });
        } else {
          props.calendarApi.changeView(
            "timeGridWeek",
            dateTimeState.START_DATE
          );
          props.calendarApi.select({
            start: dateTimeState.START_TIME,
            end: dateTimeState.END_TIME,
          });
        }
      }
    }
  }, [
    dateTimeState.START_DATE,
    dateTimeState.END_DATE,
    dateTimeState.START_TIME,
    dateTimeState.END_TIME,
  ]);

  /* 회의 이용권 관련 예약 실패 팝업 */
  const [showFailPopup, setShowFailPopup] = useState(false);
  const [resultMSG, setResultMSG] = useState("");
  const openFailPopup = msg => {
    setShowFailPopup(true);
    setResultMSG(msg);
  };
  const closeFailPopup = () => {
    setShowFailPopup(false);
  };
  return (
    <div
      className="absolute inline-block zi-0"
      style={{ width: "940px", padding: "24px 35px" }}
    >
      <div className="drag-none font-bold font-28">
        <img
          src={
            viewType
              ? "/img/icon/schedule/title_calendar.png"
              : "/img/icon/bookmark/bookmark.png"
          }
          alt="calendar"
          style={{ width: "20px", marginRight: "16px" }}
        />
        <a style={{ color: "#2B4AC4" }}>
          {viewType ? t("calendar.CALENDAR") : t("calendar.BOOKMARK")}
        </a>
      </div>
      <div
        style={{
          display: dropLoading ? "block" : "none",
          zIndex: "997",
          position: "absolute",
          width: "1483px",
          height: "954px",
        }}
      >
        <Loading />
      </div>
      {viewType ? (
        <Calendar
          setCalendarApi={props.setCalendarApi}
          changeViewType={changeViewType}
          scheduleList={schedules}
          clickDate={handleClickDate}
          selectTime={handleSelectTime}
          selectSchedule={handleClickSchedule}
          saveEventDrop={saveEventDrop}
          getSchedules={getSchedules}
          setSchedules={setSchedules}
          isInput={props.isInput}
        />
      ) : (
        <Bookmark
          setBookmarkInputInfo={props.setBookmarkInputInfo}
          calendarApi={props.calendarApi}
          changeViewType={changeViewType}
          isInput={props.isInput}
          setIsInput={props.setIsInput}
          resSidebarType={props.resSidebarType}
          setResSidebarType={props.setResSidebarType}
          setMeetingInfo={props.setMeetingInfo}
          bookmarkState={props.bookmarkState}
        />
      )}
      <ScheduleDetail
        setLoading={props.setLoading}
        detailType={detailType}
        scheduleInfo={scheduleInfo}
        scheduleParticipants={scheduleParticipants}
        datastate={props.dataState}
        handleDataState={props.handleDataState}
        handleEditSchedule={handleEditSchedule}
        handleResSchedule={handleResSchedule}
        open={detailOpen}
        close={handleDetailClose}
      />
      <NoLicensePopup open={noLicensePopup} close={closeNoLicensePopup} />
      <FailReservationPopup
        open={showFailPopup}
        close={closeFailPopup}
        msg={resultMSG}
      />
    </div>
  );
}

export default Main;
