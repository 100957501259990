/**
 * Import List
 */
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Cookies } from "react-cookie";
import LoginBtn from "component/tag/btn/account/LoginBtn";
/**
 * Version : 1.0
 * 파일명 : loginpopup.js
 * 작성일자 : 2021-10-29
 * 작성자 : 권도훈
 * 설명 : 사용자 로그인 페이지
 * 수정일자 : 2022-02-03
 * 수정자 : 권도훈
 * 수정내역 : app 통합
 */
function Loginpopup() {
  /**
   * code
   */
  const [code, setCode] = useState();
  /**
   * 기존 state
   */
  const [inputs, setInputs] = useState({ ID: "", PW: "" });
  const [errorMsg, setErrorMsg] = useState(" ");
  const { ID, PW } = inputs;
  const idInput = useRef();
  const pwInput = useRef();
  const cookies = new Cookies();

  //cookie
  const domain = process.env.REACT_APP_COOKIE_DOMAIN;
  const refreshNm = process.env.REACT_APP_COOKIE_REFRESH;
  const authNm = process.env.REACT_APP_COOKIE_AUTH;
  const loginTypeNm = process.env.REACT_APP_COOKIE_TYPE;

  useEffect(() => {
    setCode(new URL(window.location.href).searchParams.get("code"));
  }, []);

  const createToken = () => {
    const config = {
      params: {
        Request_ID: ID,
      },
    };

    axios
      .post("/user/token", null, config)
      .then(res => {
        if (res.status === 200) {
          cookies.set(authNm, res.data.AUTH, {
            path: "/",
            maxAge: 60 * 60,
            domain: domain,
          });

          cookies.set(refreshNm, res.data.REFRESH, {
            path: "/",
            maxAge: 24 * 60 * 60 * 30,
            domain: domain,
          });

          axios
            .get("/user/member", {
              params: {
                request_id: ID,
              },
            })
            .then(res => {
              if (res.status === 200) {
                axios
                  .get("/group/inviteGroup", { params: { code: code } })
                  .then(res => {
                    if (res.status === 200) {
                      window.self.close();
                      window.opener.location.href = "/app/group";
                    } else {
                      alert("잘못된 요청입니다.");
                      window.opener.location.href = "/login?tag=block";
                    }
                  })
                  .catch(err => {
                    console.log(err);
                  });
              }
            })
            .catch(err => {
              console.log(err);
            });
        } else {
          console.log("토큰 저장실패");
        }
      })
      .catch(error => {
        axios.post("/user/error", null, { headers: { error_info: error } });
      });
  };

  const onChange = e => {
    const { value, name } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const goLogin = setLoading => {
    if (inputs.ID === "") {
      setErrorMsg("아이디가 비었습니다.");
      setLoading(false);
      return false;
    } else if (inputs.PW === "") {
      setErrorMsg("비밀번호가 비었습니다.");
      setLoading(false);
      return false;
    }

    const params = {
      Request_ID: ID,
      Request_PW: PW,
    };

    axios
      .post("/user/login", params)
      .then(res => {
        if (res.status === 200) {
          cookies.set(loginTypeNm, "pebble", {
            path: "/",
            maxAge: 60 * 60,
            domain: domain,
          });
          createToken();
        } else {
          setErrorMsg("존재하지 않는 계정이거나 잘못된 비밀번호입니다.");
          setLoading(false);
          return false;
        }
      })
      .catch(error => {
        axios.post("/user/error", null, { headers: { error_info: error } });
        setErrorMsg("로그인에 실패했습니다.");
        setInputs({
          ID: "",
          PW: "",
        });
        setLoading(false);
        idInput.current.focus();
      });
  };

  return (
    <div className="m-center">
      <div
        style={{
          width: "350",
          margin: "60px 100px",
        }}
      >
        <div
          style={{
            textAlign: "center",
          }}
        >
          <img src="/img/logo_login.png" alt="logo_login" />
        </div>

        <div>
          <div className="h-4 text-center font-14 font-bold font-red">
            {errorMsg}
          </div>

          <p className="font-blue font-14 font-bold m-0 mb-4">이메일</p>
          <input
            type="text"
            placeholder="메일을 입력해주세요."
            name="ID"
            value={ID}
            ref={idInput}
            onChange={onChange}
            onKeyDown={e => {
              if (e.key === "Enter") {
                pwInput.current.focus();
              }
            }}
            className="Login_Input w-full border-none"
          ></input>
        </div>

        <div>
          <p className="font-blue font-14 font-bold m-0 mt-4 mb-4">비밀번호</p>
          <input
            type="password"
            placeholder="비밀번호를 입력해주세요."
            name="PW"
            value={PW}
            ref={pwInput}
            onChange={onChange}
            onKeyDown={e => {
              if (e.key === "Enter") {
                goLogin();
              }
            }}
            className="Login_Input w-full border-none mb-6"
          ></input>
        </div>

        <p className="font-blue font-10 font-bold m-0 mb-4">
          로그인함으로써 SoBridge 개인정보 처리방침 및 이용 약관에 동의합니다.
        </p>

        <p className="font-blue font-14 font-bold m-0">
          SoBridge는 처음이신가요?
        </p>
        <a
          className="font-blue font-14 font-bold m-0 mb-4 text-decoration-underline"
          href="/authMail"
        >
          가입하기
        </a>

        <LoginBtn style={{ marginTop: "15px" }} onClick={goLogin} />
      </div>
    </div>
  );
}

export default Loginpopup;
