/**
 * Version : 1.0
 * 작성일자 : 2021-12-23
 * 작성자 : 권도훈
 * 설명 : 사용자정보
 * 수정일자 : 2022-06-08
 * 수정자 : 강연승
 * 수정내역 : USER_NUM 추가
 */
const initalState = {
  NUM: "",
  NAME: "",
  EMAIL: "",
  IMG: "",
  POINT: 0,
  PLATFORM: "",
  TIMEZONE: "",
  OFFSET: "",
  LANGUAGE: "",
  SIGNUP_DT: "",
  AUTH_YN: "",
};

const userInfo = (state = initalState, action) => {
  switch (action.type) {
    case "userInfo/UPDATE":
      return action.info;
    default:
      return state;
  }
};

export default userInfo;
