import React, { useState, useEffect } from "react";
/**
 * Version : 1.0
 * 파일명 : timeline_item.js
 * 작성일자 : 2021-10-05
 * 작성자 : 권도훈
 * 설명 :
 * 수정일자 : 2022-05-04
 * 수정자 : 강연승
 * 수정내역 : 그룹 컬러 수정
 */
function Timeline_item(props) {
  /**
   * url 설정
   */
  let server_url = "";

  if (process.env.NODE_ENV === "development") {
    server_url =
      process.env.REACT_APP_SERVER_URL + process.env.REACT_APP_SERVER_PORT;
  } else {
    server_url = process.env.REACT_APP_SERVER_URL;
  }

  const [displayTime, setDisplayTime] = useState();
  const [ampm, setAmPm] = useState();
  const [isJoin, setIsJoin] = useState(false);

  useEffect(() => {
    displaytimeFormat(props.data.START_TIME);
    meetingJoin(props.data.START_TIME);
  }, [props.data]);

  const displaytimeFormat = t => {
    const tmpArr = t.split("T");
    const timeArr = tmpArr[1].split(":");
    let hour = timeArr[0];
    let min = timeArr[1];
    if (hour > 12) {
      hour = hour - 12;
      setAmPm("PM");
    } else if (hour === "12") {
      setAmPm("PM");
    } else if (hour < 12) {
      if (hour < 10) {
        hour = hour.substr(1, 2);
      }
      setAmPm("AM");
    }
    setDisplayTime(hour + ":" + min);
  };

  const meetingJoin = t => {
    let duration = new Date(t) - new Date();
    if (duration <= 300000) {
      //5분 300000 10분 600000 30분 1800000
      setIsJoin(true);
    } else {
      setIsJoin(false);
    }
  };

  const groupColor = color => {
    switch (color) {
      case "red":
        return "#c63353";
      case "orange":
        return "#A54824";
      case "yellow":
        return "#cdb14c";
      case "bean":
        return "#97C12A";
      case "green":
        return "#64d483";
      case "mint":
        return "#6cd6bf";
      case "pine":
        return "#75BDD9";
      case "pink":
        return "#cb86dd";
      case "purple":
        return "#a994e1";
      case "black":
        return "#3b3f4c";
      default:
        return "#2B4AC4";
    }
  };

  return (
    <div
      className="border-hover-blue border-white bg-white cursor"
      style={{
        width: "344px",
        height: "112px",
        margin: "20px 7px 20px 24px",
        borderRadius: "16px",
      }}
    >
      <div
        className="mx-3 p-4"
        onClick={() => props.onClick(props.data, isJoin)}
      >
        <div>
          <div
            className="inline"
            style={{
              color:
                props.data.GROUP_YN === "Y"
                  ? groupColor(props.data.GROUP_COLOR)
                  : "#2B4AC4",
              marginRight: "8px",
              fontSize: "26px",
            }}
          >
            {displayTime}
          </div>
          <div
            className="font-14 inline"
            style={{
              color:
                props.data.GROUP_YN === "Y"
                  ? groupColor(props.data.GROUP_COLOR)
                  : "#2B4AC4",
              fontWeight: "bold",
            }}
          >
            {ampm}
          </div>
          {props.data.GROUP_YN === "Y" ? (
            <div
              className="float-right font-14 mt-1 inline"
              style={{ color: groupColor(props.data.GROUP_COLOR) }}
            >
              {props.data.GROUP_NM}
            </div>
          ) : null}
        </div>
        <div style={{ marginTop: "20px", fontSize: "16px" }}>
          <div
            className="inline-block"
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              maxWidth: "200px",
            }}
          >
            {props.data.TITLE}
          </div>
          <div
            className="inline-block float-right cursor"
            style={{
              display: isJoin ? "block" : "none",
              fontSize: "14px",
              color: "#2B4AC4",
              fontWeight: "bold",
            }}
            onClick={e => {
              e.stopPropagation();
              if (props.data.API_TYPE === "CD0501") {
                window.open(props.data.INVITE_URL, "_blank");
              } else if (props.data.API_TYPE === "CD0502") {
                if (props.data.HOST_YN === "Y") {
                  window.open(
                    server_url +
                      "/schedule/reDirectZoomHostUrl?meeting_key=" +
                      props.data.MEETING_KEY,
                    "_blank"
                  );
                } else if (props.data.HOST_YN === "N") {
                  window.open(props.data.INVITE_URL, "_blank");
                }
              }
            }}
          >
            <img
              src="/img/icon/schedule/enter.png"
              alt="enter"
              style={{ marginRight: "8px", verticalAlign: "bottom" }}
            />
            입장하기
          </div>
        </div>
      </div>
    </div>
  );
}

export default Timeline_item;
