import React, { useState, useCallback, useEffect } from 'react'
import axios from 'axios';
import Loading from "component/tag/loading/loading";
import { useDispatch } from 'react-redux';
import { openAlert, closeAlert } from "actions/index";
/**
 * Version : 1.0
 * 파일명 : PaymentTransfer.js
 * 작성일자 : 2022-04-06
 * 작성자 : 권도훈
 * 설명 : 계좌 등록
 * 수정일자 : 2022-04-19
 * 수정자 : 권도훈
 * 수정내역 : 인증 전 버튼 비활성화 처리
 */
function PaymentTransfer(props) {
    /**
     * DISPATCH
     */
    const dispatch = useDispatch();
    const [type, setType] = useState("ARS");
    const [init, setInit] = useState(true);
    const [loading, setLoading] = useState(false);
    const [identityArs, setIdentityArs] = useState({
        customerPhone: "",
        accountNumber: "",
        bank: ""
    });
    const [arsNum, setArsNum] = useState("");
    const [arsKey, setArsKey] = useState("");
    const [disable, setDisable] = useState(true);
    /**
     * 은행사 목록
     */
    const [bank, setBank] = useState();

    const closeHandler = useCallback(() => {
        if (props.initType === "PRODUCT") {
            props.setType("PAYMENT");
            props.setIsRender(true);
            dispatch(closeAlert());
        } else {
            props.popupClose();
            props.isRefresh(true);
            dispatch(closeAlert());
        }
        // props.setType("PAYMENT");
        // props.setIsRender(true);
        // dispatch(closeAlert());
    }, [dispatch, props]);
    /**
     * 은행사 목록 조회
     */
    const getBankList = useCallback(() => {
        setLoading(true);

        axios.get("/payment/list/bank")
            .then(res => {
                if (res.status === 200) {
                    setBank(res.data);
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            })
    }, []);
    /**
     * init
     */
    useEffect(() => {
        if (init) {
            getBankList();
            setInit(false);
        }
    }, [getBankList, init]);

    useEffect(() => {
        if (type === "ARS" &&
            identityArs.bank !== "" &&
            identityArs.accountNumber !== "" &&
            identityArs.customerPhone !== ""
        ) {
            setDisable(false);
        } else {
            setDisable(true);
        }
    }, [identityArs.accountNumber, identityArs.bank, identityArs.customerPhone, type]);
    /**
     * 취소
     */
    const cancleHandler = () => {
        props.setType("ADD_PAYMENT");
        props.setIsRender(true);
    }
    /**
     * 은행 선택
     */
    const selectBank = (code) => {
        setIdentityArs({
            ...identityArs,
            bank: code
        });
    }
    /**
     * 숫자 포맷
     */
    const numberFormat = (num) => {
        return num.toString().replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
    }

    const requestArsNum = () => {
        const config = {
            params: identityArs
        }

        axios.get("/payment/ars", config)
            .then(res => {
                if (res.status === 200) {
                    //res.data.isSuccess
                    setArsNum(res.data.authNumber);
                    setArsKey(res.data.arsKey);
                    setType("NUM");
                }
            })
    }
    /**
     * 계좌등록
     */
    const confirmArsNum = () => {
        axios.delete("/payment/recurring", null)
            .then(res => {
                if (res.status === 200) {
                    const config = {
                        params: {
                            arsKey: arsKey,
                            accountNumber: identityArs.accountNumber,
                            bank: identityArs.bank
                        }
                    }

                    axios.post("/payment/ars", null, config)
                        .then(res => {
                            if (res.status === 200) {
                                if (res.data.isSuccess === true) {
                                    const obj = {
                                        TEXT:
                                            <>
                                                <div className='alert-font title'>
                                                    인증 완료!
                                                </div>
                                                <div className='alert-font content'>
                                                    계좌가 등록되었습니다.
                                                </div>
                                            </>,
                                        submitEventHandler: () => closeHandler()
                                    };
                                    dispatch(openAlert(obj));
                                } else {
                                    let msg = res.data.message.split(": ")[1];
                                    let json = JSON.parse(msg);

                                    const obj = {
                                        TEXT: json[0].message,
                                        submitEventHandler: () => dispatch(closeAlert())
                                    };
                                    dispatch(openAlert(obj));
                                }
                            }
                        })
                        .catch(() => {
                            const obj = {
                                TEXT: "저장에 실패했습니다.",
                                submitEventHandler: () => dispatch(closeAlert())
                            };
                            dispatch(openAlert(obj));
                        })
                }
            })
            .catch(() => {
                // const obj = {
                //     TEXT: "기존 결제수단 삭제를 실패했습니다.",
                //     submitEventHandler: () => dispatch(closeAlert())
                // };
                // dispatch(openAlert(obj));
                const config = {
                    params: {
                        arsKey: arsKey,
                        accountNumber: identityArs.accountNumber,
                        bank: identityArs.bank
                    }
                }

                axios.post("/payment/ars", null, config)
                    .then(res => {
                        if (res.status === 200) {
                            if (res.data.isSuccess === true) {
                                const obj = {
                                    TEXT:
                                        <>
                                            <div className='alert-font title'>
                                                인증 완료!
                                            </div>
                                            <div className='alert-font content'>
                                                계좌가 등록되었습니다.
                                            </div>
                                        </>,
                                    submitEventHandler: () => closeHandler()
                                };
                                dispatch(openAlert(obj));
                            } else {
                                let msg = res.data.message.split(": ")[1];
                                let json = JSON.parse(msg);

                                const obj = {
                                    TEXT: json[0].message,
                                    submitEventHandler: () => dispatch(closeAlert())
                                };
                                dispatch(openAlert(obj));
                            }
                        }
                    })
                    .catch(() => {
                        const obj = {
                            TEXT: "저장에 실패했습니다.",
                            submitEventHandler: () => dispatch(closeAlert())
                        };
                        dispatch(openAlert(obj));
                    })
            })
    }

    return (
        <div className='py-product-add'>
            <div className='py-product-add-header'>
                <font>
                    결제 수단 등록
                </font>
            </div>

            <div className='py-product-add-container'>
                <div className='py-product-transfer'>
                    <div className='py-product-transfer-inner'>
                        <section className='py-product-transfer-section'>
                            <p className='py-product-transfer-title'>
                                계좌 등록
                            </p>

                            <p>계좌 등록을 위한 필수 정보를 입력해주세요.</p>
                        </section>

                        {
                            type === "ARS"
                                ?
                                <>
                                    <div className='py-product-transfer-bank'>
                                        <div className='py-product-transfer-bank-inner'>
                                            <div className='py-product-transfer-bank-header'>
                                                은행 선택
                                            </div>

                                            <div className='py-product-transfer-bank-list scrollbar'>
                                                {
                                                    loading
                                                        ?
                                                        <div className='py-product-transfer-bank-list-loading'>
                                                            <Loading />
                                                        </div>
                                                        :
                                                        !init
                                                            ?
                                                            bank.map((data, idx) => (
                                                                <div
                                                                    className={
                                                                        identityArs.bank === data.BANK_CODE_KR
                                                                            ? 'py-product-transfer-bank-item selected cursor'
                                                                            : 'py-product-transfer-bank-item cursor'

                                                                    }
                                                                    key={idx}
                                                                    onClick={() => selectBank(data.BANK_CODE_KR)}
                                                                >
                                                                    <div className='py-product-transfer-bank-item-inner'>
                                                                        <img
                                                                            src={"/img/icon/payment/bank/" + data.BANK_ICON + ".png"}
                                                                            alt="icon"
                                                                        />
                                                                        <div>
                                                                            {data.BANK_NM}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                            :
                                                            <></>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className='py-product-transfer-input'>
                                        <div className='py-product-transfer-input-inner'>
                                            <span>
                                                계좌 번호
                                            </span>

                                            <input
                                                type='text'
                                                placeholder="'-' 없이 입력해주세요."
                                                value={identityArs.accountNumber}
                                                onChange={e => {
                                                    setIdentityArs({
                                                        ...identityArs,
                                                        accountNumber: numberFormat(e.target.value)
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className='py-product-transfer-input'>
                                        <div className='py-product-transfer-input-inner'>
                                            <span>
                                                휴대폰 번호
                                            </span>

                                            <input
                                                type='text'
                                                placeholder="'-' 없이 입력해주세요."
                                                value={identityArs.customerPhone}
                                                onChange={e => {
                                                    setIdentityArs({
                                                        ...identityArs,
                                                        customerPhone: numberFormat(e.target.value)
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className='py-product-transfer-btn-area confirm'>
                                        <button
                                            className='py-product-transfer-btn-cancle cursor'
                                            onClick={() => cancleHandler()}
                                        >
                                            취 소
                                        </button>

                                        <button
                                            className={
                                                disable
                                                    ? 'py-product-transfer-btn-auth'
                                                    : 'py-product-transfer-btn-auth cursor'
                                            }
                                            onClick={() => requestArsNum()}
                                            disabled={disable}
                                        >
                                            인증받기
                                        </button>
                                    </div>
                                </>
                                :
                                <>
                                    <div className='py-product-transfer-ars'>
                                        <p className='py-product-transfer-ars-comment'>
                                            인증 전화가 오면 다음의 숫자를
                                        </p>
                                        <p className='py-product-transfer-ars-comment'>
                                            휴대폰 번호에 입력하여 전송해 주시기 바랍니다.
                                        </p>

                                        <p className='py-product-transfer-ars-num'>
                                            {arsNum}
                                        </p>

                                        <p className='py-product-transfer-ars-comment'>
                                            인증이 완료되면 확인 버튼을 눌러주시기 바랍니다.
                                        </p>
                                    </div>

                                    <div className='py-product-transfer-btn-area'>
                                        <button
                                            className='py-product-transfer-btn-ok cursor'
                                            onClick={() => confirmArsNum()}
                                        >
                                            확인
                                        </button>
                                    </div>
                                </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentTransfer
