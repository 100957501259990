import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Paging from "component/tag/div/common/newPaging";
import Alert from "component/tag/modal/alert";
import Loading from "component/tag/loading/loading";
import RecordEmpty from "./recordEmpty";
import axios from "axios";
import { updateRecordInfo, updatePagingInfo } from "actions/index";
import RecordHeader from "component/tag/div/my/record/recordHeader";
/**
 * Version : 1.0
 * 파일명 : recordList.js
 * 작성일자 : 2021-10-21
 * 작성자 : 권도훈
 * 설명 : 주소록
 * 수정일자 : 2022-05-23
 * 수정자 : 권도훈
 * 수정내역 : 다운로드 카운트 증가 수정
 */
function RecordList() {
  /**
   * 언어변환
   */
  const { t } = useTranslation();
  /**
   * REDUCER
   */
  const REDUCER_STATE = useSelector(state => state.groupInfoReducer);
  const RECORD_STATE = useSelector(state => state.recordInfoReducer);
  const PAGING_STATE = useSelector(state => state.pagingInfoReducer);
  /**
   * DISPATCH
   */
  const dispatch = useDispatch();
  /**
   * loading
   */
  const [loading, setLoading] = useState(false);
  /**
   * Data
   */
  const [data, setData] = useState([]);
  /**
   * 체크박스
   */
  const [checkedInputs, setCheckedInputs] = useState([]);
  /**
   * 정렬 종류, 정렬 순서
   */
  const [sortType, setSortType] = useState("name");
  const [sortOrder, setSortOrder] = useState("ASC");
  /**
   * 알림창 설정
   */
  const [alertProps, setAlertProps] = useState({
    text: "",
    isShow: false,
    cancelBtnDisplay: false,
    okBtnDisplay: true,
    okBtnEventHandler: function () { },
    cancleBtnEventHandler: function () {
      closeAlertEvent();
    },
  });
  /**
   * 알림창 닫기
   */
  const closeAlertEvent = useCallback(() => {
    setAlertProps({
      ...alertProps,
      isShow: false,
    });
  }, [alertProps]);
  /**
   * SET TOTAL
   */
  const getRecordTotal = useCallback(() => {
    setLoading(true);

    const config = {
      params: {
        Group_YN: REDUCER_STATE.GROUP_YN,
        Group_Num: REDUCER_STATE.GROUP_NUM,
        AUTH_CD: REDUCER_STATE.AUTH_CD,
        page_Row: PAGING_STATE.ROW_COUNT,
        page_Now: PAGING_STATE.PAGE_COUNT,
        Search_Text: RECORD_STATE.SEARCH_TEXT,
      },
    };

    axios
      .get("/record/RecordFileCnt", config)
      .then(res => {
        if (res.status === 200) {
          const paging_obj = {
            ROW_COUNT: PAGING_STATE.ROW_COUNT,
            PAGE_COUNT: PAGING_STATE.PAGE_COUNT,
            TOTAL: res.data[0].RECCNT,
          };
          dispatch(updatePagingInfo(paging_obj));
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [
    PAGING_STATE.PAGE_COUNT,
    PAGING_STATE.ROW_COUNT,
    RECORD_STATE.SEARCH_TEXT,
    REDUCER_STATE.AUTH_CD,
    REDUCER_STATE.GROUP_NUM,
    REDUCER_STATE.GROUP_YN,
    dispatch,
  ]);
  /**
   * SET DATA
   */
  const getRecordData = useCallback(() => {
    setLoading(true);

    const config = {
      params: {
        Group_YN: REDUCER_STATE.GROUP_YN,
        Group_Num: REDUCER_STATE.GROUP_NUM,
        AUTH_CD: REDUCER_STATE.AUTH_CD,
        page_Row: PAGING_STATE.ROW_COUNT,
        page_Now: PAGING_STATE.PAGE_COUNT,
        Search_Text: RECORD_STATE.SEARCH_TEXT,
      },
    };

    axios
      .get("/record/RecordFile", config)
      .then(res => {
        if (res.status === 200) {
          setData(res.data);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [
    PAGING_STATE.PAGE_COUNT,
    PAGING_STATE.ROW_COUNT,
    RECORD_STATE.SEARCH_TEXT,
    REDUCER_STATE.AUTH_CD,
    REDUCER_STATE.GROUP_NUM,
    REDUCER_STATE.GROUP_YN,
  ]);
  /**
   * 조회
   */
  useEffect(() => {
    if (PAGING_STATE.ROW_COUNT < 10) {
      const paging_obj = {
        ROW_COUNT: 10,
        PAGE_COUNT: 0,
        TOTAL: 0,
      };
      dispatch(updatePagingInfo(paging_obj));
    } else {
      getRecordTotal();
      getRecordData();
    }
  }, [PAGING_STATE.ROW_COUNT, dispatch, getRecordData, getRecordTotal]);
  /**
   * 체크박스 USE_EFFECT
   */
  useEffect(() => {
    if (RECORD_STATE.CHECKED_ARR.length > 0) {
      setCheckedInputs(RECORD_STATE.CHECKED_ARR);
    } else {
      setCheckedInputs([]);
    }
  }, [RECORD_STATE.CHECKED_ARR]);
  /**
   * 체크박스 전체 클릭이벤트
   */
  const clickCheckboxAll = checked => {
    if (checked) {
      let num = [];

      for (let i = 0; i < data.length; i++) {
        num.push(data[i].REC_NUM);
      }

      setCheckedInputs(num);

      const obj = {
        IS_CHECKED: true,
        CHECKED_ARR: num,
        SEARCH_TEXT: RECORD_STATE.SEARCH_TEXT,
      };
      dispatch(updateRecordInfo(obj));
    } else {
      setCheckedInputs([]);

      const obj = {
        IS_CHECKED: false,
        CHECKED_ARR: [],
        SEARCH_TEXT: RECORD_STATE.SEARCH_TEXT,
      };
      dispatch(updateRecordInfo(obj));
    }
  };
  /**
   * 체크박스 클릭이벤트
   */
  const clickCheckbox = (checked, id) => {
    if (checked) {
      setCheckedInputs([...checkedInputs, id]);
      const obj = {
        IS_CHECKED: true,
        CHECKED_ARR: [...checkedInputs, id],
        SEARCH_TEXT: RECORD_STATE.SEARCH_TEXT,
      };
      dispatch(updateRecordInfo(obj));
    } else {
      const arr = checkedInputs.filter(el => el !== id);
      setCheckedInputs(arr);

      if (arr.length < 1) {
        const obj = {
          IS_CHECKED: false,
          CHECKED_ARR: [],
          SEARCH_TEXT: RECORD_STATE.SEARCH_TEXT,
        };
        dispatch(updateRecordInfo(obj));
      } else {
        const obj = {
          IS_CHECKED: true,
          CHECKED_ARR: arr,
          SEARCH_TEXT: RECORD_STATE.SEARCH_TEXT,
        };
        dispatch(updateRecordInfo(obj));
      }
    }
  };
  /**
   * 정렬 이벤트
   */
  const clickSortBtn = type => {
    setSortType(type);

    if (sortOrder === "ASC") {
      setSortOrder("DESC");
      if (type === "date") {
        data.sort(function (a, b) {
          if (b.RECDAY > a.RECDAY) {
            return -1;
          } else {
            return 0;
          }
        });
      } else if (type === "name") {
        data.sort(function (a, b) {
          if (b.REC_TITLE > a.REC_TITLE) {
            return -1;
          } else {
            return 0;
          }
        });
      } else if (type === "group") {
        data.sort(function (a, b) {
          if (a.GROUP_NM === undefined) {
            return 1;
          } else if (b.GROUP_NM === undefined) {
            return -1;
          } else if (b.GROUP_NM > a.GROUP_NM) {
            return -1;
          } else {
            return 0;
          }
        });
      }
    } else {
      setSortOrder("ASC");
      if (type === "date") {
        data.sort(function (a, b) {
          if (a.RECDAY > b.RECDAY) {
            return -1;
          } else {
            return 0;
          }
        });
      } else if (type === "name") {
        data.sort(function (a, b) {
          if (a.REC_TITLE > b.REC_TITLE) {
            return -1;
          } else {
            return 0;
          }
        });
      } else if (type === "group") {
        data.sort(function (a, b) {
          if (a.GROUP_NM === undefined) {
            return -1;
          } else if (b.GROUP_NM === undefined) {
            return 1;
          } else if (a.GROUP_NM > b.GROUP_NM) {
            return -1;
          } else {
            return 0;
          }
        });
      }
    }
  };
  /**
   * 시간 컨버터
   */
  const timeConverter = time => {
    const splitTime = time.split(":");
    let target = parseInt(splitTime[0]);

    if (target === 24) {
      return t("my.record.time.AM") + "0 : " + splitTime[1];
    } else if (target < 12) {
      return t("my.record.time.AM") + target + " : " + splitTime[1];
    } else {
      target = target - 12;
      return t("my.record.time.PM") + target + " : " + splitTime[1];
    }
  };
  /**
   * 색상 컨버터
   */
  const colorConverter = color => {
    switch (color) {
      case "red":
        return "#C63353";
      case "orange":
        return "#B24E27";
      case "yellow":
        return "#806A1C";
      case "bean":
        return "#5B751A";
      case "green":
        return "#239D45";
      case "mint":
        return "#229A80";
      case "pine":
        return "#2993BC";
      // case "blue": return "#2B4AC4";
      case "pink":
        return "#A836C7";
      case "purple":
        return "#7855D0";
      case "black":
        return "#3B3F4C";
      default:
        return "#2B4AC4";
    }
  };
  /**
   * 녹화파일 다운로드
   */
  const downloadRecordFile = (url, num) => {
    const config = {
      params: {
        Rec_Num: num
      }
    }

    axios.put("/record/RecordFileCnt", null, config)
      .then(res => {
        if (res.status === 200) {
          window.open(
            url,
            "_blank",
            "width=600,height=250,left=0,top=0,visible=no,scrollbars=no,status=no,titlebar=no,toolbar=no,menubar=no,resizeable=no,location=no"
          );
        }
      })
      .catch(() => {

      });
  };
  /**
   * 데이터 삭제
   */
  const deleteRecordData = num => {
    setAlertProps({
      ...alertProps,
      text: t("my.record.msg.delete.confirm"),
      isShow: true,
      cancelBtnDisplay: true,
      okBtnEventHandler: function () {
        setLoading(true);
        closeAlertEvent();

        const config = {
          params: {
            Rec_Num: num,
          },
        };

        axios
          .delete("/record/RecordFile", config)
          .then(res => {
            if (res.status === 200) {
              getRecordTotal();
              getRecordData();
              setAlertProps({
                ...alertProps,
                isShow: true,
                text: t("my.record.msg.delete.success"),
                cancelBtnDisplay: false,
                okBtnEventHandler: function () {
                  closeAlertEvent();
                },
              });
            }
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      },
      cancleBtnEventHandler: function () {
        closeAlertEvent();
      },
    });
  };
  /**
   * 체크된 DATA 삭제
   */
  const deleteCheckedRecordData = () => {
    setAlertProps({
      ...alertProps,
      text: t("my.record.msg.delete.confirm"),
      isShow: true,
      cancelBtnDisplay: true,
      okBtnEventHandler: function () {
        closeAlertEvent();
        setLoading(true);

        let targetStr = "";

        for (let i = 0; i < checkedInputs.length; i++) {
          targetStr = targetStr + checkedInputs[i] + ",";
        }

        const config = {
          params: {
            Rec_Num: targetStr,
          },
        };

        axios
          .delete("/record/RecordFile", config)
          .then(res => {
            if (res.status === 200) {
              getRecordTotal();
              getRecordData();

              setCheckedInputs([]);
              const obj = {
                IS_CHECKED: false,
                CHECKED_ARR: [],
                SEARCH_TEXT: RECORD_STATE.SEARCH_TEXT,
              };
              dispatch(updateRecordInfo(obj));

              setAlertProps({
                ...alertProps,
                isShow: true,
                text: t("my.record.msg.delete.success"),
                cancelBtnDisplay: false,
                okBtnEventHandler: function () {
                  closeAlertEvent();
                },
              });
            }
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      },
      cancleBtnEventHandler: function () {
        closeAlertEvent();
      },
    });
  };
  /**
   * TAG
   */
  return (
    <div
      style={{
        margin: "26px 40px",
        height: "845px",
        width: "1404px",
      }}
    >
      <RecordHeader deleteCheckedRecordData={deleteCheckedRecordData} />

      <div
        style={{
          backgroundColor: "rgba(255,255,255,0.4)",
          height: "845px",
          marginTop: "23px",
          borderRadius: "12px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Alert alertProps={alertProps} />
          {loading ? (
            <div
              style={{
                height: "100%",
              }}
            >
              <Loading />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                alignItems: "center",
                margin: "0 37px 13px 45px",
                paddingTop: "8px",
                width: "1330px",
                height: "770px",
              }}
            >
              <table
                id="record_table"
                className="record_table table-border-separate-10"
              >
                <colgroup>
                  <col width="8px" />
                  <col width="48px" />
                  <col width="32px" />
                  <col width="112px" />
                  <col width="32px" />
                  <col width="216px" />
                  <col width="32px" />
                  <col width="104px" />
                  <col width="24px" />
                  <col width="104px" />
                  <col width="40px" />
                  <col width="144px" />
                  <col width="48px" />
                  <col width="184px" />
                  <col width="16px" />
                  <col width="186px" />
                </colgroup>

                <thead>
                  <tr
                    className="record_thead_tr"
                    id="record_thead_tr"
                    style={{
                      color:
                        REDUCER_STATE.GROUP_YN === "N" ||
                          REDUCER_STATE.GROUP_YN === ""
                          ? "#2B4AC4"
                          : "#3B3F4C",
                    }}
                  >
                    <th className="record_table_leftItem"></th>
                    <th
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <input
                        className="cursor"
                        type="checkbox"
                        name="Rec_Chk"
                        id="Rec_Chk_ALL"
                        onChange={e => {
                          clickCheckboxAll(e.currentTarget.checked);
                        }}
                        checked={checkedInputs.length === 0 ? false : true}
                        style={{
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    </th>
                    <th></th>
                    <th>
                      {t("my.record.txt.table.date")}
                      {sortType === "date" && sortOrder === "DESC" ? (
                        <img
                          className="cursor address_header_icon"
                          onClick={() => clickSortBtn("date")}
                          src={
                            REDUCER_STATE.GROUP_YN === "N" ||
                              REDUCER_STATE.GROUP_YN === ""
                              ? "/img/icon/address/sort_up.png"
                              : "/img/icon/common/sort_up.png"
                          }
                          alt="name_sort_up"
                        />
                      ) : (
                        <img
                          className="cursor address_header_icon"
                          onClick={() => clickSortBtn("date")}
                          src={
                            REDUCER_STATE.GROUP_YN === "N" ||
                              REDUCER_STATE.GROUP_YN === ""
                              ? "/img/icon/address/sort_down.png"
                              : "/img/icon/common/sort_down.png"
                          }
                          alt="name_sort_down"
                        />
                      )}
                    </th>
                    <th></th>
                    <th>
                      {t("my.record.txt.table.filename")}
                      {sortType === "name" && sortOrder === "DESC" ? (
                        <img
                          className="cursor address_header_icon"
                          onClick={() => clickSortBtn("name")}
                          src={
                            REDUCER_STATE.GROUP_YN === "N" ||
                              REDUCER_STATE.GROUP_YN === ""
                              ? "/img/icon/address/sort_up.png"
                              : "/img/icon/common/sort_up.png"
                          }
                          alt="name_sort_up"
                        />
                      ) : (
                        <img
                          className="cursor address_header_icon"
                          onClick={() => clickSortBtn("name")}
                          src={
                            REDUCER_STATE.GROUP_YN === "N" ||
                              REDUCER_STATE.GROUP_YN === ""
                              ? "/img/icon/address/sort_down.png"
                              : "/img/icon/common/sort_down.png"
                          }
                          alt="name_sort_down"
                        />
                      )}
                    </th>
                    <th></th>
                    <th>{t("my.record.txt.table.starttime")}</th>
                    <th></th>
                    <th>{t("my.record.txt.table.endtime")}</th>
                    <th></th>
                    <th>{t("my.record.txt.table.recordtime")}</th>
                    <th></th>
                    <th>
                      {t("my.record.txt.table.group")}
                      {sortType === "group" && sortOrder === "DESC" ? (
                        <img
                          className="cursor address_header_icon"
                          onClick={() => clickSortBtn("group")}
                          src={
                            REDUCER_STATE.GROUP_YN === "N" ||
                              REDUCER_STATE.GROUP_YN === ""
                              ? "/img/icon/address/sort_up.png"
                              : "/img/icon/common/sort_up.png"
                          }
                          alt="name_sort_up"
                        />
                      ) : (
                        <img
                          className="cursor address_header_icon"
                          onClick={() => clickSortBtn("group")}
                          src={
                            REDUCER_STATE.GROUP_YN === "N" ||
                              REDUCER_STATE.GROUP_YN === ""
                              ? "/img/icon/address/sort_down.png"
                              : "/img/icon/common/sort_down.png"
                          }
                          alt="name_sort_down"
                        />
                      )}
                    </th>
                    <th></th>
                    <th className="record_table_rightItem"></th>
                  </tr>
                </thead>

                <tbody
                  style={{
                    maxHeight: "650px",
                  }}
                >
                  {PAGING_STATE.TOTAL === 0 ? (
                    <RecordEmpty />
                  ) : (
                    data.map((data, index) => (
                      <tr
                        className="record_tbody_tr"
                        key={"data_" + index}
                        style={{
                          height: "56px",
                        }}
                      >
                        <td className="record_table_leftItem"></td>
                        <td
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <input
                            className="cursor"
                            type="checkbox"
                            name="Rec_Chk"
                            onChange={e => {
                              clickCheckbox(
                                e.currentTarget.checked,
                                data.REC_NUM
                              );
                            }}
                            checked={
                              checkedInputs.includes(data.REC_NUM)
                                ? true
                                : false
                            }
                            style={{
                              width: "20px",
                              height: "20px",
                            }}
                          />
                        </td>
                        <td></td>
                        <td>{data.RECDAY}</td>
                        <td></td>
                        <td
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            maxWidth: "216px",
                          }}
                          title={data.REC_TITLE}
                        >
                          {data.REC_TITLE}
                        </td>
                        <td></td>
                        <td>{timeConverter(data.STHM)}</td>
                        <td></td>
                        <td>{timeConverter(data.EDHM)}</td>
                        <td></td>
                        <td>{data.DURTM}</td>
                        <td></td>
                        <td>
                          {data.GROUP_NM !== undefined ? (
                            <div
                              className="record_groupName"
                              style={{
                                background: colorConverter(data.GROUP_COLOR),
                              }}
                            >
                              <p className="inline">{data.GROUP_NM}</p>
                            </div>
                          ) : (
                            <></>
                          )}
                        </td>
                        <td></td>
                        <td className="record_table_rightItem">
                          {REDUCER_STATE.GROUP_YN === "N" ? (
                            <>
                              <img
                                className="cursor"
                                src="/img/icon/record/list_play.png"
                                alt="play"
                                style={{
                                  marginRight: "8px",
                                }}
                                onClick={() => window.open(data.STREAM_URL)}
                              />
                              <img
                                className="cursor"
                                src="/img/icon/record/list_download.png"
                                alt="download"
                                style={{
                                  marginRight: "8px",
                                }}
                                onClick={() =>
                                  downloadRecordFile(data.FILE_URL, data.REC_NUM)
                                }
                              />
                              <img
                                className="cursor"
                                src="/img/icon/record/list_delete.png"
                                alt="delete"
                                onClick={() => deleteRecordData(data.REC_NUM)}
                              />
                            </>
                          ) : (
                            <></>
                          )}
                          {REDUCER_STATE.PLAY_YN === "Y" ? (
                            <img
                              className="cursor"
                              src={
                                REDUCER_STATE.GROUP_YN === "N" ||
                                  REDUCER_STATE.GROUP_YN === ""
                                  ? "/img/icon/record/list_play.png"
                                  : "/img/icon/record/play_" +
                                  REDUCER_STATE.GROUP_COLOR +
                                  ".png"
                              }
                              alt="play"
                              style={{
                                margin:
                                  REDUCER_STATE.GROUP_YN === "N" ||
                                    REDUCER_STATE.GROUP_YN === ""
                                    ? "0px 8px 0px 0px"
                                    : "6px 14px 6px 6px",
                              }}
                              onClick={() => window.open(data.STREAM_URL)}
                            />
                          ) : (
                            <></>
                          )}
                          {REDUCER_STATE.DOWNLOAD_YN === "Y" ? (
                            <img
                              className="cursor"
                              src={
                                REDUCER_STATE.GROUP_YN === "N" ||
                                  REDUCER_STATE.GROUP_YN === ""
                                  ? "/img/icon/record/list_download.png"
                                  : "/img/icon/record/down_" +
                                  REDUCER_STATE.GROUP_COLOR +
                                  ".png"
                              }
                              alt="download"
                              style={{
                                margin:
                                  REDUCER_STATE.GROUP_YN === "N" ||
                                    REDUCER_STATE.GROUP_YN === ""
                                    ? "0px 8px 0px 0px"
                                    : "6px 14px 6px 6px",
                              }}
                              onClick={() => {
                                downloadRecordFile(data.FILE_URL, data.REC_NUM)
                              }}
                            />
                          ) : (
                            <></>
                          )}
                          {REDUCER_STATE.AUTH_CD === "CD0301" ? (
                            <img
                              className="cursor"
                              src={
                                REDUCER_STATE.GROUP_YN === "N" ||
                                  REDUCER_STATE.GROUP_YN === ""
                                  ? "/img/icon/record/list_delete.png"
                                  : "/img/icon/record/delete_" +
                                  REDUCER_STATE.GROUP_COLOR +
                                  ".png"
                              }
                              style={{
                                margin:
                                  REDUCER_STATE.GROUP_YN === "N" ||
                                    REDUCER_STATE.GROUP_YN === ""
                                    ? "0px"
                                    : "6px",
                              }}
                              alt="delete"
                              onClick={() => deleteRecordData(data.REC_NUM)}
                            />
                          ) : (
                            <></>
                          )}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>

              <Paging />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default RecordList;
