import axios from "axios";
import Paging from "component/tag/div/common/paging";
import Paging8 from "component/tag/div/common/paging_8";
import Loading from "component/tag/loading/loading";
import React, { useCallback, useEffect, useState } from "react";
import ReactModal from "react-modal";
import { useSelector } from "react-redux";
/**
 * Version : 1.0
 * 파일명 : participantsHistoryPopup.js
 * 작성일자 : 2021-12-29
 * 작성자 : 권도훈
 * 설명 : 스케줄 참석 내역 팝업
 * 수정일자 : 2022-04-27
 * 수정자 : 권도훈
 * 수정내역 : ui 수정
 */
function ParticipantsHistoryPopup(props) {
  /**
   * REDUCER
   */
  const GROUP_STATE = useSelector(state => state.groupInfoReducer);
  /**
   * 상세보기 팝업
   */
  const [showDetail, setShowDetail] = useState(false);
  const [detailInfo, setDetailInfo] = useState();
  const [row, setRow] = useState([]);
  /**
   * 로딩
   */
  const [loading, setLoading] = useState(false);
  /**
   * 데이터
   */
  const [history, setHistory] = useState([]);
  /**
   * 페이지 정보
   */
  const [page, setPage] = useState({
    ROW_COUNT: 10,
    PAGE_COUNT: 0,
  });
  const [detailPage, setDetailPage] = useState({
    ROW_COUNT: 8,
    PAGE_COUNT: 0,
  });
  const [total, setTotal] = useState(0);
  /**
   * 히스토리 조회
   */
  const getParticipantsHistory = useCallback(() => {
    setLoading(true);

    const config = {
      params: {
        MEETING_KEY: props.meetingKey,
        ROW_COUNT: page.ROW_COUNT,
        PAGE_COUNT: page.PAGE_COUNT,
        STUDY_THEMA:
          GROUP_STATE.GROUP_YN === "Y"
            ? GROUP_STATE.GROUP_CATEGORY === "CD0403" ||
              GROUP_STATE.GROUP_CATEGORY === "CD0404" ||
              GROUP_STATE.GROUP_CATEGORY === "CD0405"
              ? "Y"
              : "N"
            : "N",
      },
    };

    axios
      .get("/schedule/participantsHistory", config)
      .then(res => {
        if (res.status === 200) {
          setHistory(res.data);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [
    GROUP_STATE.GROUP_CATEGORY,
    GROUP_STATE.GROUP_YN,
    page.PAGE_COUNT,
    page.ROW_COUNT,
    props.meetingKey,
  ]);
  /**
   * 히스토리 토탈 값
   */
  const getHistoryTotal = useCallback(() => {
    setLoading(true);

    const config = {
      params: {
        MEETING_KEY: props.meetingKey,
        STUDY_THEMA:
          GROUP_STATE.GROUP_YN === "Y"
            ? GROUP_STATE.GROUP_CATEGORY === "CD0403" ||
              GROUP_STATE.GROUP_CATEGORY === "CD0404" ||
              GROUP_STATE.GROUP_CATEGORY === "CD0405"
              ? "Y"
              : "N"
            : "N",
      },
    };

    axios
      .get("/schedule/total/history", config)
      .then(res => {
        if (res.status === 200) {
          setTotal(res.data.TOTAL);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [GROUP_STATE.GROUP_CATEGORY, GROUP_STATE.GROUP_YN, props.meetingKey]);

  useEffect(() => {
    if (props.showHistory) {
      setShowDetail(false);
      getParticipantsHistory();
      getHistoryTotal();
      setHistory([]);
      setTotal(0);
      setPage({
        ROW_COUNT: 10,
        PAGE_COUNT: 0,
      });
      setDetailPage({
        ROW_COUNT: 8,
        PAGE_COUNT: 0,
      });
    }
  }, [getHistoryTotal, getParticipantsHistory, props.showHistory]);
  /**
   * 참석현황 시간 포맷
   */
  // const timeFormat = (start_time, end_time) => {
  //     let result = "";
  //     const start_arr = start_time.split("/");
  //     const end_arr = end_time.split("/");

  //     for (let i = 0; i < start_arr.length; i++) {
  //         const start = start_arr[i].split(":");
  //         const end = end_arr[i].split(":");

  //         result = result + start[0] + ":" + start[1] + " ~ " + end[0] + ":" + end[1];

  //         if (i !== start_arr.length - 1) {
  //             result = result + " / ";
  //         }
  //     }

  //     return result;
  // }
  /**
   * 날짜 포맷
   */
  const timeFormat = date => {
    if (date === "") {
      return <div style={{ width: "100%" }}>-</div>;
    } else {
      const dateArr = date.split(" ");

      return (
        <div>
          <div>{dateArr[0]}</div>
          <div>{dateArr[1]}</div>
        </div>
      );
    }
  };
  /**
   * 총 참여 시간 포맷
   */
  const durationFormat = duration => {
    let result = 0;
    const duration_arr = duration.split("/");

    for (let i = 0; i < duration_arr.length; i++) {
      result = result + Number(duration_arr[i]);
    }

    return result;
  };
  /**
   * 입장 횟수 포맷
   */
  const joinCountFormat = data => {
    if (data === "") {
      return 0;
    } else {
      const count = data.split("/");
      return count.length;
    }
  };
  /**
   * 상세 정보 테이블 포맷
   */
  const detailArrFormat = data => {
    if (data.DURATION === "") {
      setRow([]);
    } else {
      let result = [];
      const duration = data.DURATION.split("/");
      const start_time = data.START_TIME.split("/");
      const end_time = data.END_TIME.split("/");

      for (let i = 0; i < duration.length; i++) {
        result.push({
          JOIN_COUNT: i + 1,
          START_TIME: start_time[i],
          END_TIME: end_time[i],
          DURATION: duration[i],
        });
      }
      setRow(result);
    }
  };
  /**
   * 엑셀 다운로드
   */
  const downloadExcel = () => {
    let arr = [];

    if (showDetail) {
      arr.push({
        MEETING_TITLE: props.meetingInfo.TITLE,
        GROUP_NAME:
          GROUP_STATE.GROUP_YN === "Y"
            ? GROUP_STATE.GROUP_NM
            : props.meetingInfo.GROUP_NAME === undefined
            ? ""
            : props.meetingInfo.GROUP_NAME,
        HOST_NAME: props.meetingInfo.HOST_NAME,
        MEETING_START_TIME: detailInfo.MIN_START_TIME,
        MEETING_END_TIME: detailInfo.MAX_END_TIME,
        MEETING_DURATION: props.duration + "분",
        MEETING_PARTICIPANT_COUNT: history.length + "명",
      });

      for (let i = 0; i < row.length; i++) {
        arr.push({
          NAME: detailInfo.NAME,
          EMAIL: detailInfo.EMAIL,
          START_TIME: row[i].START_TIME,
          END_TIME: row[i].END_TIME,
          DURATION: durationFormat(row[i].DURATION) + "/" + props.duration,
          COUNT: row[i].JOIN_COUNT,
        });
      }

      const data = arr;
      const config = {
        responseType: "blob",
      };

      axios.post("/schedule/excel/history/detail", data, config).then(res => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: res.headers["content-type"] })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "SoBridge_회의참석세부내역.xlsx");
        document.body.appendChild(link);
        link.click();
      });
    } else {
      arr.push({
        MEETING_TITLE: props.meetingInfo.TITLE,
        GROUP_NAME:
          GROUP_STATE.GROUP_YN === "Y"
            ? GROUP_STATE.GROUP_NM
            : props.meetingInfo.GROUP_NAME === undefined
            ? ""
            : props.meetingInfo.GROUP_NAME,
        HOST_NAME: props.meetingInfo.HOST_NAME,
        MEETING_START_TIME: props.meetingInfo.START_TIME,
        MEETING_END_TIME: props.meetingInfo.END_TIME,
        MEETING_DURATION: props.duration + "분",
        MEETING_PARTICIPANT_COUNT: history.length + "명",
      });

      for (let i = 0; i < history.length; i++) {
        arr.push({
          NAME: history[i].NAME,
          EMAIL: history[i].EMAIL,
          MIN_START_TIME: history[i].MIN_START_TIME,
          MAX_END_TIME: history[i].MAX_END_TIME,
          DURATION: durationFormat(history[i].DURATION) + "/" + props.duration,
          COUNT: joinCountFormat(history[i].DURATION),
          ATTENDANCE:
            GROUP_STATE.GROUP_YN === "Y"
              ? GROUP_STATE.GROUP_CATEGORY === "CD0403" ||
                GROUP_STATE.GROUP_CATEGORY === "CD0404" ||
                GROUP_STATE.GROUP_CATEGORY === "CD0405"
                ? history[i].ATTENDANCE
                : ""
              : "",
        });
      }

      const data = arr;
      const config = {
        params: {
          STUDY_THEMA:
            GROUP_STATE.GROUP_YN === "Y"
              ? GROUP_STATE.GROUP_CATEGORY === "CD0403" ||
                GROUP_STATE.GROUP_CATEGORY === "CD0404" ||
                GROUP_STATE.GROUP_CATEGORY === "CD0405"
                ? "Y"
                : "N"
              : "N",
        },
        responseType: "blob",
      };

      axios.post("/schedule/excel/history", data, config).then(res => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: res.headers["content-type"] })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "SoBridge_회의참석내역.xlsx");
        document.body.appendChild(link);
        link.click();
      });
    }
  };
  /**
   * 출결 업데이트
   */
  const updateAttendance = (email, value) => {
    const config = {
      params: {
        MEETING_KEY: props.meetingKey,
        ATTENDANCE: value,
        EMAIL: email,
      },
    };

    axios
      .post("/schedule/history/attendance", null, config)
      .then(res => {
        if (res.status === 200) {
          getParticipantsHistory();
          getHistoryTotal();
        }
      })
      .catch(() => {
        alert("에러!");
      });
  };
  /**
   * body
   */
  ReactModal.setAppElement("body");
  /**
   * 팝업 닫기
   */
  const closePopup = () => {
    props.setShowHistory(false);
  };
  return (
    <ReactModal
      isOpen={props.showHistory} //모달 Open(Boolean)
      className="data_popup_history" //모달 ClassName
      overlayClassName="NewAlertOverlay" //모달오버레이 ClassName
      animationType="fade"
      transparent={true}
      closeTimeoutMS={200}
      style={{
        content: {
          transition: "none",
          width: showDetail
            ? "768px"
            : GROUP_STATE.GROUP_CATEGORY === "CD0403" ||
              GROUP_STATE.GROUP_CATEGORY === "CD0404" ||
              GROUP_STATE.GROUP_CATEGORY === "CD0405"
            ? "914px"
            : "768px",
        },
      }}
    >
      {loading ? (
        <Loading />
      ) : (
        <div
          style={{
            margin: "0 16px 0px 24px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "0 0 14px 0",
              paddingTop: "24px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                className="drag-none"
                style={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  color: showDetail ? "#000000" : "#2B4AC4",
                }}
              >
                {showDetail ? "참석자 회의 세부 내역" : "회의 참석 내역"}
              </div>
              <div
                className="cursor"
                style={{
                  marginLeft: "16px",
                  width: "141px",
                  height: "36px",
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: showDetail ? "#000000" : "#2B4AC4",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: showDetail
                    ? "1px solid #000000"
                    : "1px solid #2B4AC4",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "8px",
                }}
                onClick={() => downloadExcel()}
              >
                <img
                  src={
                    showDetail
                      ? "/img/icon/log/download_black.png"
                      : "/img/icon/log/download.png"
                  }
                  alt="DOWN"
                />
                <div
                  style={{
                    marginLeft: "8px",
                  }}
                >
                  엑셀 내려받기
                </div>
              </div>
            </div>

            <img
              className="cursor"
              onClick={() => closePopup()}
              src="/img/icon/common/close.png"
              alt="X"
            />
          </div>

          <div
            style={{
              height: "632px",
              width: showDetail
                ? "720px"
                : GROUP_STATE.GROUP_CATEGORY === "CD0403" ||
                  GROUP_STATE.GROUP_CATEGORY === "CD0404" ||
                  GROUP_STATE.GROUP_CATEGORY === "CD0405"
                ? "866px"
                : "720px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {showDetail ? (
              <div
                style={{
                  width: "720px",
                  height: "632px",
                  borderRadius: "12px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    background: "#F6F7FC",
                    height: "632px",
                    borderRadius: "12px",
                  }}
                >
                  <div
                    style={{
                      width: "688px",
                      marginTop: "16px",
                      marginBottom: "4px",
                    }}
                  >
                    <table
                      style={{
                        borderRadius: "10px",
                        backgroundColor: "#EDEDF0",
                      }}
                    >
                      <colgroup>
                        <col width="16px" />
                        <col width="176px" />
                        <col width="40px" />
                        <col width="112px" />
                        <col width="40px" />
                        <col width="128px" />
                        <col width="40px" />
                        <col width="96px" />
                        <col width="40px" />
                      </colgroup>
                      <thead>
                        <tr
                          style={{
                            color: "#2B4AC4",
                            fontSize: "14px",
                            fontWeight: "bold",
                            height: "40px",
                          }}
                        >
                          <th />
                          <th>이름</th>
                          <th />
                          <th>최초 입장 시간</th>
                          <th />
                          <th>마지막 퇴장 시간</th>
                          <th />
                          <th>총 참여 시간</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          style={{
                            color: "#2B4AC4",
                            fontSize: "14px",
                            height: "48px",
                          }}
                        >
                          <th />
                          <th>
                            <div>
                              <div>{detailInfo.NAME}</div>
                              <div>{detailInfo.EMAIL}</div>
                            </div>
                          </th>
                          <th />
                          <th
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                width: "76px",
                                height: "46px",
                                display: "flex",
                                alignItems: "center",
                                fontSize: "13px",
                              }}
                            >
                              {timeFormat(detailInfo.MIN_START_TIME)}
                            </div>
                          </th>
                          <th />
                          <th
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                width: "76px",
                                height: "46px",
                                display: "flex",
                                alignItems: "center",
                                fontSize: "13px",
                              }}
                            >
                              {timeFormat(detailInfo.MAX_END_TIME)}
                            </div>
                          </th>
                          <th />
                          <th>{durationFormat(detailInfo.DURATION)}분</th>
                          <th />
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div
                    style={{
                      height: "488px",
                    }}
                  >
                    <table
                      style={{
                        maxHeight: "480px",
                      }}
                    >
                      <colgroup>
                        <col width="16px" />
                        <col width="176px" />
                        <col width="40px" />
                        <col width="112px" />
                        <col width="40px" />
                        <col width="128px" />
                        <col width="40px" />
                        <col width="96px" />
                        <col width="40px" />
                      </colgroup>
                      <thead>
                        <tr
                          style={{
                            color: "#3E3E4F",
                            fontSize: "14px",
                            fontWeight: "bold",
                            height: "40px",
                          }}
                        >
                          <th />
                          <th>입장 횟수</th>
                          <th />
                          <th>입장 시간</th>
                          <th />
                          <th>퇴장 시간</th>
                          <th />
                          <th>참여 시간</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {row.length > 0 ? (
                          row.map((detail_item, idx) =>
                            idx >= detailPage.PAGE_COUNT ? (
                              detailPage.PAGE_COUNT + detailPage.ROW_COUNT >=
                              row.length ? (
                                idx < row.length ? (
                                  <tr
                                    key={"historyDetail_" + idx}
                                    style={{
                                      color: "#3E3E4F",
                                      fontSize: "14px",
                                      height: "56px",
                                      textAlign: "center",
                                    }}
                                  >
                                    <td />
                                    <td>{detail_item.JOIN_COUNT}</td>
                                    <td />
                                    <td
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "76px",
                                          height: "46px",
                                          display: "flex",
                                          alignItems: "center",
                                          fontSize: "13px",
                                        }}
                                      >
                                        {timeFormat(detail_item.START_TIME)}
                                      </div>
                                    </td>
                                    <td />
                                    <td
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "76px",
                                          height: "46px",
                                          display: "flex",
                                          alignItems: "center",
                                          fontSize: "13px",
                                        }}
                                      >
                                        {timeFormat(detail_item.END_TIME)}
                                      </div>
                                    </td>
                                    <td />
                                    <td>{detail_item.DURATION}분</td>
                                    <td />
                                  </tr>
                                ) : null
                              ) : idx <
                                detailPage.PAGE_COUNT + detailPage.ROW_COUNT ? (
                                <tr
                                  key={"historyDetail_" + idx}
                                  style={{
                                    color: "#3E3E4F",
                                    fontSize: "14px",
                                    height: "56px",
                                    textAlign: "center",
                                  }}
                                >
                                  <td />
                                  <td>{detail_item.JOIN_COUNT}</td>
                                  <td />
                                  <td
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "76px",
                                        height: "46px",
                                        display: "flex",
                                        alignItems: "center",
                                        fontSize: "13px",
                                      }}
                                    >
                                      {timeFormat(detail_item.START_TIME)}
                                    </div>
                                  </td>
                                  <td />
                                  <td
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "76px",
                                        height: "46px",
                                        display: "flex",
                                        alignItems: "center",
                                        fontSize: "13px",
                                      }}
                                    >
                                      {timeFormat(detail_item.END_TIME)}
                                    </div>
                                  </td>
                                  <td />
                                  <td>{detail_item.DURATION}분</td>
                                  <td />
                                </tr>
                              ) : null
                            ) : null
                          )
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    marginTop: "8px",
                  }}
                >
                  <div
                    className="cursor"
                    style={{
                      width: "141px",
                      height: "36px",
                      background: "#3E3E4F",
                      borderRadius: "8px",
                      color: "#FFFFFF",
                      fontSize: "12px",
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => setShowDetail(false)}
                  >
                    목록으로 돌아가기
                  </div>

                  <Paging8
                    page={detailPage}
                    setPage={setDetailPage}
                    total={row.length}
                  />
                </div>
              </div>
            ) : (
              <div>
                <div
                  style={{
                    width:
                      GROUP_STATE.GROUP_CATEGORY === "CD0403" ||
                      GROUP_STATE.GROUP_CATEGORY === "CD0404" ||
                      GROUP_STATE.GROUP_CATEGORY === "CD0405"
                        ? "866px"
                        : "720px",
                    // width: "720px",
                    height: "632px",
                    background: "#F6F7FC",
                    borderRadius: "12px",
                    marginBottom: "8px",
                  }}
                >
                  <table
                    style={{
                      width:
                        GROUP_STATE.GROUP_CATEGORY === "CD0403" ||
                        GROUP_STATE.GROUP_CATEGORY === "CD0404" ||
                        GROUP_STATE.GROUP_CATEGORY === "CD0405"
                          ? "820px"
                          : "688px",
                      // width: "688px",
                      maxHeight: "520px",
                      borderCollapse: "separate",
                      borderSpacing: "0px 10px",
                      margin: "6px 16px 6px 16px",
                    }}
                  >
                    <colgroup>
                      <col width="16px" />
                      <col width="176px" />
                      <col width="16px" />
                      <col width="112px" />
                      <col width="16px" />
                      <col width="112px" />
                      <col width="16px" />
                      <col width="96px" />
                      <col width="16px" />
                      <col width="90px" />
                      {GROUP_STATE.GROUP_CATEGORY === "CD0403" ||
                      GROUP_STATE.GROUP_CATEGORY === "CD0404" ||
                      GROUP_STATE.GROUP_CATEGORY === "CD0405" ? (
                        <>
                          <col width="16px" />
                          <col width="136px" />
                          <col width="16px" />
                        </>
                      ) : (
                        <col width="22px" />
                      )}
                    </colgroup>

                    <thead>
                      <tr
                        style={{
                          backgroundColor: "#EAEDF9",
                          color: "#2B4AC4",
                          fontSize: "14px",
                          fontWeight: "bold",
                          height: "40px",
                          marginBottom: "4px",
                        }}
                      >
                        <th
                          style={{
                            borderTopLeftRadius: "12px",
                            borderBottomLeftRadius: "12px",
                          }}
                        />
                        <th>참석자</th>
                        <th />
                        <th>최초 입장 시간</th>
                        <th />
                        <th>마지막 퇴장 시간</th>
                        <th />
                        <th>총 참여 시간</th>
                        <th />
                        <th>재입장 횟수</th>
                        {GROUP_STATE.GROUP_CATEGORY === "CD0403" ||
                        GROUP_STATE.GROUP_CATEGORY === "CD0404" ||
                        GROUP_STATE.GROUP_CATEGORY === "CD0405" ? (
                          <>
                            <th />
                            <th>출석부</th>
                          </>
                        ) : (
                          <></>
                        )}
                        <th
                          style={{
                            borderTopRightRadius: "12px",
                            borderBottomRightRadius: "12px",
                          }}
                        />
                      </tr>
                    </thead>
                    <tbody>
                      {history.length > 0 ? (
                        history.map((item, idx) => (
                          <tr
                            className="cursor"
                            key={item.SCHEDULE_NUM + "_" + idx}
                            style={{
                              fontSize: "14px",
                              color: "#3E3E4F",
                              height: "48px",
                              textAlign: "center",
                              marginTop: "4px",
                              marginBottom: "4px",
                              background: "none",
                            }}
                            onClick={() => {
                              detailArrFormat(item);
                              setDetailInfo(item);
                              setShowDetail(true);
                              setDetailPage({
                                ROW_COUNT: 8,
                                PAGE_COUNT: 0,
                              });
                            }}
                          >
                            <td />
                            <td>
                              <div>
                                <div>{item.NAME}</div>
                                <div>{item.EMAIL}</div>
                              </div>
                            </td>
                            <td />
                            <td
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <div
                                style={{
                                  width: "76px",
                                  height: "46px",
                                  display: "flex",
                                  alignItems: "center",
                                  fontSize: "13px",
                                }}
                              >
                                {timeFormat(item.MIN_START_TIME)}
                              </div>
                            </td>
                            <td />
                            <td
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <div
                                style={{
                                  width: "76px",
                                  height: "38px",
                                  display: "flex",
                                  alignItems: "center",
                                  fontSize: "13px",
                                }}
                              >
                                {timeFormat(item.MAX_END_TIME)}
                              </div>
                            </td>
                            <td />
                            <td>
                              {durationFormat(item.DURATION)}/{props.duration}
                            </td>
                            <td />
                            <td>{joinCountFormat(item.DURATION)}</td>
                            {GROUP_STATE.GROUP_CATEGORY === "CD0403" ||
                            GROUP_STATE.GROUP_CATEGORY === "CD0404" ||
                            GROUP_STATE.GROUP_CATEGORY === "CD0405" ? (
                              <>
                                <td />
                                <td onClick={e => e.stopPropagation()}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <button
                                      className="cursor"
                                      style={{
                                        color:
                                          item.ATTENDANCE === "attendance"
                                            ? "#FFFFFF"
                                            : "#AFB3BF",
                                        background:
                                          item.ATTENDANCE === "attendance"
                                            ? "#2993BC"
                                            : "#DFE1E5",
                                        width: "40px",
                                        height: "24px",
                                        borderRadius: "6px",
                                        border: "none",
                                        outline: "none",
                                      }}
                                      onClick={() => {
                                        if (item.ATTENDANCE === "attendance") {
                                          return;
                                        } else {
                                          updateAttendance(
                                            item.EMAIL,
                                            "attendance"
                                          );
                                        }
                                      }}
                                    >
                                      출석
                                    </button>
                                    <button
                                      className="cursor"
                                      style={{
                                        color:
                                          item.ATTENDANCE === "tardy"
                                            ? "#FFFFFF"
                                            : "#AFB3BF",
                                        background:
                                          item.ATTENDANCE === "tardy"
                                            ? "#EFAC00"
                                            : "#DFE1E5",
                                        width: "40px",
                                        height: "24px",
                                        borderRadius: "6px",
                                        border: "none",
                                        outline: "none",
                                      }}
                                      onClick={() => {
                                        if (item.ATTENDANCE === "tardy") {
                                          return;
                                        } else {
                                          updateAttendance(item.EMAIL, "tardy");
                                        }
                                      }}
                                    >
                                      지각
                                    </button>
                                    <button
                                      className="cursor"
                                      style={{
                                        color:
                                          item.ATTENDANCE === "absebce"
                                            ? "#FFFFFF"
                                            : "#AFB3BF",
                                        background:
                                          item.ATTENDANCE === "absebce"
                                            ? "#EE6C4D"
                                            : "#DFE1E5",
                                        width: "40px",
                                        height: "24px",
                                        borderRadius: "6px",
                                        border: "none",
                                        outline: "none",
                                      }}
                                      onClick={() => {
                                        if (item.ATTENDANCE === "absebce") {
                                          return;
                                        } else {
                                          updateAttendance(
                                            item.EMAIL,
                                            "absebce"
                                          );
                                        }
                                      }}
                                    >
                                      결석
                                    </button>
                                  </div>
                                </td>
                              </>
                            ) : (
                              <></>
                            )}
                            <td />
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            style={{
                              position: "absolute",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "437px",
                              height: "380px",
                            }}
                          >
                            참석 내역이 없습니다.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <Paging page={page} setPage={setPage} total={total} />
              </div>
            )}
          </div>
        </div>
      )}
    </ReactModal>
  );
}

export default ParticipantsHistoryPopup;
