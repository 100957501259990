import DeleteAgree from "component/tag/div/account/deleteAgree";
import DeleteResult from "component/tag/div/account/deleteResult";
import DeleteSurvey from "component/tag/div/account/deleteSurvey";
import Loading from "component/tag/loading/loading";
import React, { useState } from "react";
import ReactModal from "react-modal";
/**
 * Version : 1.0
 * 파일명 : deleteAccountPopup.js
 * 작성일자 : 2021-11-29
 * 작성자 : 권도훈
 * 설명 : 사용자 탈퇴 팝업
 * 수정일자 : 2022-01-20
 * 수정자 : 권도훈
 * 수정내역 : UI 수정
*/
function DeleteAccountPopup(props) {
    /**
     * body
     */
    ReactModal.setAppElement("body");
    /**
     * 설문조사 결과
     */
    const [surveyResult, setSurveyResult] = useState("");
    /**
     * 렌더링 페이지 구분
     */
    const [page, setPage] = useState("SURVEY");
    /**
     * 로딩
     */
    const [loading, setLoading] = useState(false);
    /**
     * 취소버튼 이벤트
     */
    const cancleBtnEvent = () => {
        window.location.href = "/";
    }
    /**
     * 렌더링
     */
    const renderEvent = () => {
        switch (page) {
            case "SURVEY":
                return <DeleteSurvey cancleBtnEvent={cancleBtnEvent} setPage={setPage} setSurveyResult={setSurveyResult} EMAIL={props.userInfo.EMAIL} setLoading={setLoading} />
            case "AGREE":
                return <DeleteAgree cancleBtnEvent={cancleBtnEvent} setPage={setPage} INFO={props.userInfo} surveyResult={surveyResult} />
            case "RESULT":
                return <DeleteResult cancleBtnEvent={cancleBtnEvent} setPage={setPage} />
            default:
                return <div>잘못된 요청입니다.</div>
        }
    }
    /**
     * 태그
     */
    return (
        <ReactModal
            isOpen={props.isPopup} //모달 Open(Boolean)
            className="deleteAccount_Alert" //모달 ClassName
            overlayClassName="AlertOverlay" //모달오버레이 ClassName
            animationType="fade"
            transparent={true}
            style={{
                content: {
                    margin: page === "SURVEY"
                        ? "13% auto"
                        : page === "AGREE"
                            ? "17% auto"
                            : "19% auto",
                    height: page === "SURVEY"
                        ? "480px"
                        : page === "AGREE"
                            ? "269px"
                            : "196px"
                }
            }}
        >
            <div
                style={{
                    margin: "0 32px",
                    height: "100%"
                }}
            >
                <div
                    style={{
                        padding: "32px 0 29px 0"
                    }}
                >
                    {
                        loading
                            ?
                            <div
                                style={{
                                    height: page === "SURVEY"
                                        ? "539px"
                                        : page === "AGREE"
                                            ? "208px"
                                            : "135px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}
                            >
                                <Loading />
                            </div>
                            :
                            renderEvent()
                    }
                </div>

            </div>
        </ReactModal>
    )
}

export default DeleteAccountPopup