import 'css/recipt.css'
import React, { useCallback, useEffect, useState } from 'react'
import { openAlert, closeAlert } from "actions/index";
import { useDispatch } from 'react-redux';
import axios from 'axios';
/**
 * Version : 1.0
 * 파일명 : Recipt.js
 * 작성일자 : 2022-04-14
 * 작성자 : 권도훈
 * 설명 : 결제 전표
 * 수정일자 : 2022-04-21
 * 수정자 : 권도훈
 * 수정내역 : 버튼 UI 수정
 */
function Recipt() {
    /**
     * dispatch
     */
    const dispatch = useDispatch();
    /**
     * state
     */
    const [init, setInit] = useState(true);
    const [info, setInfo] = useState({
        CANCLE_YN: "",
        CURRENCY: "",
        DISCOUNT: "",
        METHOD_NUM: "",
        METHOD_TYPE: "",
        ORDER_NAME: "",
        ORDER_NUM: "",
        SUPPLIED_AMOUNT: "",
        TOTAL_AMOUNT: "",
        VAT: ""
    });
    /**
     * 전표 데이터 조회
     */
    const getReciptData = useCallback(() => {
        if (new URL(window.location.href).searchParams.get("ordernum") === null) {
            const obj = {
                TEXT: "정보를 불러올 수 없습니다.",
                submitEventHandler: () => {
                    dispatch(closeAlert());
                }
            };
            dispatch(openAlert(obj));
        } else {
            const orderNum = new URL(window.location.href).searchParams.get("ordernum").replaceAll(" ", "");

            if (orderNum !== null && orderNum !== undefined && orderNum !== "") {
                const config = {
                    params: {
                        ORDER_NUM: orderNum
                    }
                }

                axios.get("/payment/recipt", config)
                    .then(res => {
                        if (res.status === 200) {
                            setInfo(res.data);
                        }

                    })
                    .catch(() => {
                        const obj = {
                            TEXT: "정보를 불러올 수 없습니다.",
                            submitEventHandler: () => {
                                dispatch(closeAlert());
                            }
                        };
                        dispatch(openAlert(obj));
                    })
            } else {
                const obj = {
                    TEXT: "정보를 불러올 수 없습니다.",
                    submitEventHandler: () => {
                        dispatch(closeAlert());
                    }
                };
                dispatch(openAlert(obj));
            }
        }


    }, [dispatch]);

    useEffect(() => {
        if (init) {
            getReciptData();
            setInit(false);
        }
    }, [getReciptData, init]);

    /**
     * 가격 포맷
     */
    const amountFormat = (amount) => {
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + "원";
    }
    /**
     * URL 복사
     */
    const copyURL = () => {
        const orderNum = new URL(window.location.href).searchParams.get("ordernum");
        const url = new URL(window.location.href).origin + "/recipt?ordernum=" + orderNum;

        if (navigator.clipboard) {
            navigator.clipboard
                .writeText(url)
                .then(() => {
                    const obj = {
                        TEXT: "클립보드에 복사됐어요!",
                        submitEventHandler: () => {
                            dispatch(closeAlert());
                        }
                    };
                    dispatch(openAlert(obj));
                })
                .catch(() => {
                    const obj = {
                        TEXT: "복사를 다시 시도해주세요.",
                        submitEventHandler: () => {
                            dispatch(closeAlert());
                        }
                    };
                    dispatch(openAlert(obj));
                });
        } else {
            if (!document.queryCommandSupported("copy")) {
                const obj = {
                    TEXT: "복사하기가 지원되지 않는 브라우저입니다.",
                    submitEventHandler: () => {
                        dispatch(closeAlert());
                    }
                };
                dispatch(openAlert(obj));
                return alert("");
            } else {
                const textarea = document.createElement("textarea");
                textarea.value = url;
                textarea.style.top = 0;
                textarea.style.left = 0;
                textarea.style.position = "fixed";

                document.body.appendChild(textarea);
                textarea.focus();
                textarea.select();
                document.execCommand("copy");
                document.body.removeChild(textarea);

                const obj = {
                    TEXT: "클립보드에 복사되었습니다.",
                    submitEventHandler: () => {
                        dispatch(closeAlert());
                    }
                };
                dispatch(openAlert(obj));
            }
        }
    }

    return (
        <div className='rp-container'>
            <div className='rp-content'>
                <table className='rp-table'>
                    <tbody>
                        <tr className='rp-table-tr empty' />

                        <tr>
                            <td>
                                <div className='rp-table-div'>
                                    <span className='rp-table-title'>
                                        {
                                            info.METHOD_TYPE === "카드"
                                                ? "카드전표"
                                                : "계좌전표"
                                        }
                                    </span>

                                    <div className='rp-table-div'>
                                        <div
                                            className='rp-table-btn cursor'
                                            onClick={() => window.print()}
                                        >
                                            <img
                                                src="/img/icon/payment/print.png"
                                                alt=''
                                            />
                                        </div>

                                        <div
                                            className='rp-table-btn cursor'
                                            onClick={() => copyURL()}
                                        >
                                            <img
                                                src="/img/icon/payment/copy.png"
                                                alt=''
                                            />
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr className='rp-table-tr empty' />

                        <tr>
                            <td>
                                <div className='rp-table-div'>
                                    <span className='rp-table-text key'>
                                        주문번호
                                    </span>

                                    <span className='rp-table-text value'>
                                        {info.ORDER_NUM}
                                    </span>
                                </div>

                                <div className='rp-table-div margin'>
                                    <span className='rp-table-text key'>
                                        구매자
                                    </span>

                                    <span className='rp-table-text value'>
                                        {info.USER_NM}
                                    </span>
                                </div>

                                <div className='rp-table-div margin'>
                                    <span className='rp-table-text key'>
                                        구매상품
                                    </span>

                                    <span className='rp-table-text value'>
                                        {info.ORDER_NAME}
                                    </span>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div className='rp-table-div line' />
                            </td>
                        </tr>

                        <tr>
                            <td>
                                {
                                    info.METHOD_TYPE === "카드"
                                        ?
                                        <>
                                            <div className='rp-table-div'>
                                                <span className='rp-table-text key'>
                                                    카드사
                                                </span>

                                                <span className='rp-table-text value'>
                                                    {info.COMPANY}
                                                </span>
                                            </div>

                                            <div className='rp-table-div margin'>
                                                <span className='rp-table-text key'>
                                                    카드번호
                                                </span>

                                                <span className='rp-table-text value'>
                                                    {info.CARD_NUMBER}
                                                </span>
                                            </div>

                                            <div className='rp-table-div margin'>
                                                <span className='rp-table-text key'>
                                                    카드타입
                                                </span>

                                                <span className='rp-table-text value'>
                                                    {
                                                        info.CARD_TYPE !== "" && info.OWNER_TYPE !== ""
                                                            ?
                                                            info.CARD_TYPE + "/" + info.OWNER_TYPE
                                                            :
                                                            ""
                                                    }
                                                </span>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className='rp-table-div'>
                                                <span className='rp-table-text key'>
                                                    은행
                                                </span>

                                                <span className='rp-table-text value'>
                                                    {info.BANK}
                                                </span>
                                            </div>
                                        </>
                                }
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div className='rp-table-div line' />
                            </td>
                        </tr>

                        <tr>
                            <td>
                                {/* <div className='rp-table-div'>
                                    <span className='rp-table-text key'>
                                        공급가액
                                    </span>

                                    <span className='rp-table-text value'>
                                        {amountFormat(info.SUPPLIED_AMOUNT)}
                                    </span>
                                </div>

                                <div className='rp-table-div margin'>
                                    <span className='rp-table-text key'>
                                        부가세
                                    </span>

                                    <span className='rp-table-text value'>
                                        {amountFormat(info.VAT)}
                                    </span>
                                </div> */}

                                {/* <div className='rp-table-div margin'> */}
                                <div className='rp-table-div'>
                                    <span className='rp-table-text key blue'>
                                        금액
                                    </span>

                                    <span className='rp-table-text value blue'>
                                        {amountFormat(info.TOTAL_AMOUNT)}
                                    </span>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div className='rp-table-div line' />
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div className='rp-table-footer'>
                                    <span className='rp-table-footer title'>
                                        이용상점
                                    </span>

                                    <span>
                                        (주)리브테크놀로지
                                    </span>

                                    <span>
                                        대표자명: 김우성
                                    </span>

                                    <span>
                                        사업자등록번호: 220-88-68179
                                    </span>

                                    <span>
                                        전화: 070-7798-1311
                                    </span>

                                    <span>
                                        주소: 서울특별시 중구 남대문로7길 16(소공동) 4층 408호
                                    </span>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div className='rp-table-div line' />
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <img
                                    src="/img/logo.png"
                                    alt=''
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Recipt
