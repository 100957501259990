import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router";
import { useInView } from "react-intersection-observer";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";
import TimelineItem from "../timeline_item";
import LogChangeBtn from "component/tag/btn/common/logChangeBtn";
import ScheduleDetail from "component/page/my/schedule/scheduleDetail";
import Loading from "component/tag/loading/loading";

/**
 * Version : 1.0
 * 파일명 : timeline.js
 * 작성일자 : 2021-10-05
 * 작성자 : 권도훈
 * 설명 :
 * 수정일자 : 2022-06-09
 * 수정자 : 강연승
 * 수정내역 : offset 적용
 * props :
 * 전달받은값 - 설명
 */
function Timeline(props) {
  /**
   * 언어변환
   */
  const { t } = useTranslation();
  /**
   * 글로벌 스테이트
   */
  const userInfo = useSelector(state => state.userInfoReducer);
  const groupInfoState = useSelector(state => state.groupInfoReducer);
  const location = useLocation();
  const [timelineCnt, setTimelineCnt] = useState(0);
  const [timelineList, setTimelineList] = useState([]);
  const [timelineDates, setTimelineDates] = useState([]);
  const [scheduleInfo, setScheduleInfo] = useState({});

  useEffect(() => {
    setTimelineCnt(0);
    setTimelineList([]);
    setTimelineDates([]);
    getTimeline();
  }, []);

  useEffect(() => {
    setTimelineCnt(0);
    setTimelineList([]);
    setTimelineDates([]);
    getTimeline();
  }, [props.dataState, location.pathname]);

  const [ref, inView] = useInView();
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);

  const getTimelineDates = timeline => {
    let tmp = [];
    for (let i = 0; i < timeline.length; i++) {
      const dateStr = timeline[i].START_TIME.replace(/\T.*/, "");
      tmp.push(dateStr);
    }
    const set = new Set(tmp);
    setTimelineDates([...set]);
  };

  const sliceTimeline = (date, timeline) => {
    let tmpArr = [];
    for (let i = 0; i < timeline.length; i++) {
      if (date === timeline[i].START_TIME.replace(/\T.*/, "")) {
        tmpArr.push(timeline[i]);
      }
    }
    return tmpArr;
  };

  const getTimeline = useCallback(async () => {
    setLoading(true);
    let url = "";
    let params = {
      HISTORY: "N",
      CURRENTPAGE: page,
      RECORDPERPAGE: 10,
    };
    if (groupInfoState.GROUP_YN === "" && groupInfoState.GROUP_NUM === "") {
      if (props.groupYN === "N") {
        url = "/schedule/selectMySchedule";
      } else if (
        props.groupYN === "Y" &&
        (location.state === "" ||
          location.state === null ||
          location.state === undefined)
      ) {
        url = "/schedule/selectGroupSchedule";
      } else if (
        props.groupYN === "Y" &&
        location.state !== "" &&
        location.state !== null &&
        location.state !== undefined
      ) {
        url = "/schedule/selectGroupSchedule";
        params = {
          ...params,
          GROUP_NUM: location.state.groupNum,
        };
      }
    } else {
      if (groupInfoState.GROUP_YN === "N" && groupInfoState.GROUP_NUM === "") {
        url = "/schedule/selectMySchedule";
      } else if (
        groupInfoState.GROUP_YN === "Y" &&
        groupInfoState.GROUP_NUM === ""
      ) {
        url = "/schedule/selectGroupSchedule";
      } else if (
        groupInfoState.GROUP_YN === "Y" &&
        groupInfoState.GROUP_NUM !== ""
      ) {
        url = "/schedule/selectGroupSchedule";
        params = {
          ...params,
          GROUP_NUM: groupInfoState.GROUP_NUM,
        };
      }
    }

    await axios
      .get(url, {
        params: params,
      })
      .then(res => {
        if (res.status === 200) {
          setLoading(false);
          const totalCnt = res.data[0].TOTAL;
          const resultData = res.data[0].SCHEDULE;
          setTimelineCnt(totalCnt);
          if (page === 0) {
            setTimelineList(resultData);
            getTimelineDates(resultData);
          } else if (page > 0 && page <= totalCnt) {
            let mergeData = timelineList.concat(...resultData);
            setTimelineList(mergeData);
            getTimelineDates(mergeData);
          }
        } else {
          setLoading(false);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  }, [page, groupInfoState.GROUP_YN, groupInfoState.GROUP_NUM]);

  useEffect(() => {
    getTimeline();
  }, [getTimeline]);

  useEffect(() => {
    if (timelineList.length !== timelineCnt) {
      // 사용자가 마지막 요소를 보고 있고, 로딩 중이 아니라면
      if (inView && !loading) {
        setPage(prevState => prevState + 10);
      } else {
        return;
      }
    }
  }, [inView, loading]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [scheduleParticipants, setScheduleParticipants] = useState([]);

  const [detailType, setDetailType] = useState("timeline");
  const handleDialogOpen = (data, isJoin) => {
    setDetailType("timeline");
    let timeNow = new Date(
      new Date() * 1 + 3600000 * parseFloat(userInfo.OFFSET)
    )
      .toISOString()
      .replace(/\..*/, "");
    if (data.START_TIME < timeNow && timeNow < data.END_TIME) {
      setDetailType("meeting");
    }

    let tmpScheduleInfo = {
      id: data.SCHEDULE_NUM,
      title: data.TITLE,
      startTime: data.START_TIME,
      endTime: data.END_TIME,
      isJoin: isJoin,
      content: data.CONTENTS,
      apiType: data.API_TYPE,
      inviteURL: data.INVITE_URL,
      meetingKey: data.MEETING_KEY,
      hostYN: data.HOST_YN,
      groupYN: data.GROUP_YN,
      groupNum: data.GROUP_NUM,
      groupNm: data.GROUP_NM,
      groupColor: data.GROUP_COLOR,
      recYN: data.REC_YN,
      hostNm: data.HOST_NM,
      hostId: data.HOST_ID,
      meetingPW: data.MEETING_PW,
      sipUrl: data.SIP_URL,
      meetingLink: data.MEETING_LINK,
    };
    if (
      data.FILE_INFO !== null &&
      data.FILE_INFO !== undefined &&
      data.FILE_INFO !== ""
    ) {
      const scheduleFileList = data.FILE_INFO.split("|");
      let rawfileInfo = [];
      let fileInfoList = [];
      for (let i = 0; i < scheduleFileList.length; i++) {
        rawfileInfo = scheduleFileList[i].split(";");
        const fileInfo = {
          fileNum: rawfileInfo[0],
          fileNm: rawfileInfo[1],
          fileURL: rawfileInfo[2],
          fileDownNm: rawfileInfo[3],
          fileSize: rawfileInfo[4],
        };
        fileInfoList.push(fileInfo);
      }
      tmpScheduleInfo = {
        ...tmpScheduleInfo,
        fileInfoList: fileInfoList,
      };
    }
    axios
      .get("/schedule/selectScheduleParticipants", {
        params: {
          schedule_num: data.SCHEDULE_NUM,
        },
      })
      .then(({ data }) => setScheduleParticipants(data))
      .catch(error => {
        console.log(error);
      });
    if (data.API_TYPE === "CD0501") {
      props.setLoading(true);
      axios
        .get("/schedule/getWebexHostKey", {
          params: {
            schedule_num: data.SCHEDULE_NUM,
          },
        })
        .then(res => {
          if (res.status === 200) {
            props.setLoading(false);
            tmpScheduleInfo = {
              ...tmpScheduleInfo,
              hostKey: res.data,
            };
            setScheduleInfo(tmpScheduleInfo);
            setDialogOpen(true);
          }
        })
        .catch(error => {
          props.setLoading(false);
          console.log(error);
        });
    } else if (data.API_TYPE === "CD0502") {
      tmpScheduleInfo = {
        ...tmpScheduleInfo,
        hostKey: data.HOST_KEY,
      };
      setScheduleInfo(tmpScheduleInfo);
      setDialogOpen(true);
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const dateFormat = date => {
    let mon =
      new Date(date).getMonth() + 1 < 10
        ? "0" + (new Date(date).getMonth() + 1)
        : new Date(date).getMonth() + 1;
    let dt = new Date(date).getDate();
    let e = new Date(date).getDay();
    let e_ko;
    if (e === 0) {
      e_ko = t("date.sunday");
    } else if (e === 1) {
      e_ko = t("date.monday");
    } else if (e === 2) {
      e_ko = t("date.tuesday");
    } else if (e === 3) {
      e_ko = t("date.wednesday");
    } else if (e === 4) {
      e_ko = t("date.thursday");
    } else if (e === 5) {
      e_ko = t("date.friday");
    } else if (e === 6) {
      e_ko = t("date.saturday");
    }
    return mon + "월 " + dt + "일 " + e_ko;
  };

  function displayTimeFormat(start, end) {
    let s_time = new Date(start);
    let e_time = new Date(end);
    let s_hour = s_time.getHours();
    let e_hour = e_time.getHours();
    let s_min =
      s_time.getMinutes() < 10
        ? "0" + s_time.getMinutes()
        : s_time.getMinutes();
    let e_min =
      e_time.getMinutes() < 10
        ? "0" + e_time.getMinutes()
        : e_time.getMinutes();
    if (s_hour > 12) {
      s_hour = "오후 " + (s_hour - 12);
    } else {
      if (s_hour === 12) {
        s_hour = "오후 " + s_hour;
      } else {
        s_hour = "오전 " + s_hour;
      }
    }
    if (e_hour > 12) {
      e_hour = "오후 " + (e_hour - 12);
    } else {
      if (e_hour === 12) {
        e_hour = "오후 " + e_hour;
      } else {
        e_hour = "오전 " + e_hour;
      }
    }
    // setDisplayTime(
    //   s_hour + "시 " + s_min + "분 ~ " + e_hour + "시 " + e_min + "분"
    // );
    // return displayTime;
    return s_hour + "시 " + s_min + "분 ~ " + e_hour + "시 " + e_min + "분";
  }

  const handleEditSchedule = (
    id,
    title,
    startTime,
    endTime,
    content,
    participants,
    apiType,
    fileInfoList,
    recYN
  ) => {
    props.setMeetingInfo({
      id: id,
      title: title,
      displayDate: dateFormat(startTime),
      displayTime: displayTimeFormat(startTime, endTime),
      startTime: startTime,
      endTime: endTime,
      participant: participants,
      content: content,
      apiType: apiType,
      fileInfoList: fileInfoList,
      recYN: recYN,
    });
    setScheduleParticipants(participants);
    props.calendarApi.changeView("timeGridWeek", startTime);
    props.setIsInput(true);
    props.setResSidebarType("edit");
  };

  const groupColor = color => {
    switch (color) {
      case "red":
        return "#C63353";
      case "orange":
        return "#B24E27";
      case "yellow":
        return "#9A6F14";
      case "bean":
        return "#749420";
      case "green":
        return "#1B7C37";
      case "mint":
        return "#229A80";
      case "pine":
        return "#2688AE";
      case "pink":
        return "#9931B5";
      case "purple":
        return "#7855D0";
      case "black":
        return "#3B3F4C";
      default:
        return "#2B4AC4";
    }
  };
  return (
    <>
      <div className="sidebar_schedule">
        <div className="sidebar_header">
          <div
            className={
              groupInfoState.GROUP_YN === "Y"
                ? "sidebar_title groupSidebar_title_timeline"
                : "sidebar_title sidebar_title_timeline"
            }
          >
            {groupInfoState.GROUP_YN === "Y"
              ? t("schedule.sidebar.timeline.title.group")
              : t("schedule.sidebar.timeline.title.my")}
          </div>
          <LogChangeBtn
            onClick={() => props.setIsToggle(props.isToggle ? false : true)}
          />
        </div>

        <div
          className="sidebar_scheduleList"
          style={{
            height: props.height,
          }}
        >
          {timelineDates.length === 0 ||
          new Date().toISOString().replace(/\T.*/, "") < timelineDates[0] ? (
            <div className="timelineGroup">
              <div
                className={
                  groupInfoState.GROUP_YN === "Y"
                    ? "groupSidebar_title_timeline"
                    : "sidebar_title_timeline"
                }
                style={{
                  margin: "16px 32px",
                  font: "normal normal bold 14px/18px Spoqa Han Sans Neo",
                }}
              >
                <div style={{ display: "inline" }}>| {t("date.today")} </div>
                <div
                  style={{
                    display: "inline",
                    width: "18px",
                    height: "18px",
                    borderRadius: "4px",
                    backgroundColor: groupColor(groupInfoState.GROUP_COLOR),
                    color: "#FFFFFF",
                    marginLeft: "8px",
                    padding: "0 5px",
                  }}
                >
                  0
                </div>
              </div>
              <div className="timelineNoSchedule">
                {t("schedule.sidebar.timeline.noSchedule")}
              </div>
            </div>
          ) : null}
          {timelineDates.map((data, index) => (
            <div className="timelineGroup" key={data}>
              {timelineDates.length - 1 === index ? (
                <div ref={ref}>
                  <TimelineDate
                    color={groupColor(groupInfoState.GROUP_COLOR)}
                    dateStr={data}
                    dateForm={dateFormat(data)}
                    timeline={sliceTimeline(data, timelineList)}
                    handleDialogOpen={handleDialogOpen}
                  />
                </div>
              ) : (
                <div>
                  <TimelineDate
                    color={groupColor(groupInfoState.GROUP_COLOR)}
                    dateStr={data}
                    dateForm={dateFormat(data)}
                    timeline={sliceTimeline(data, timelineList)}
                    handleDialogOpen={handleDialogOpen}
                  />
                </div>
              )}
            </div>
          ))}
          {loading ? (
            <div>
              <Loading />
            </div>
          ) : null}
        </div>
      </div>
      <ScheduleDetail
        detailType={detailType}
        setLoading={props.setLoading}
        scheduleInfo={scheduleInfo}
        scheduleParticipants={scheduleParticipants}
        datastate={props.dataState}
        handleDataState={props.handleDataState}
        handleEditSchedule={handleEditSchedule}
        open={dialogOpen}
        close={handleDialogClose}
      />
    </>
  );
}

function TimelineDate(props) {
  const todayStr = new Date().toISOString().replace(/\T.*/, "");
  const { t } = useTranslation();
  const groupInfoState = useSelector(state => state.groupInfoReducer);
  return (
    <>
      <div
        className={
          groupInfoState.GROUP_YN === "Y"
            ? "groupSidebar_title_timeline"
            : "sidebar_title_timeline"
        }
        style={{
          margin: "16px 32px",
          font: "normal normal bold 14px/18px Spoqa Han Sans Neo",
        }}
      >
        {props.dateStr === todayStr ? (
          <div style={{ display: "inline" }}>| {t("date.today")} </div>
        ) : (
          <div
            style={{
              display: "inline",
            }}
          >
            | {props.dateForm}
          </div>
        )}
        <div
          style={{
            display: "inline",
            width: "18px",
            height: "18px",
            borderRadius: "4px",
            backgroundColor: props.color,
            color: "#FFFFFF",
            marginLeft: "8px",
            padding: "0 5px",
          }}
        >
          {props.timeline.length}
        </div>
      </div>

      {props.timeline.map(data => (
        <TimelineItem
          key={data.SCHEDULE_NUM}
          data={data}
          onClick={props.handleDialogOpen}
        />
      ))}
    </>
  );
}

export default Timeline;
