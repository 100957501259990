import React from "react";
import ReactModal from "react-modal";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
/**
 * Version : 1.0
 * 파일명 : FailReservationPopup.js
 * 작성일자 : 2022-02-16
 * 작성자 : 강연승
 * 설명 : 예약 실패 팝업
 * 수정일자 : 2022-02-16
 * 수정자 : 강연승
 * 수정내역 :
 */
function FailReservationPopup(props) {
  /**
   * 언어변환
   */
  const { t } = useTranslation();
  /**
   * body
   */
  ReactModal.setAppElement("body");
  /**
   * 글로벌 스테이트
   */
  const groupInfoState = useSelector(state => state.groupInfoReducer);
  const history = useHistory();
  const goLicense = () => {
    if (groupInfoState.GROUP_YN === "Y") {
      history.push({
        pathname: "/app/group/groupProfile/" + groupInfoState.GROUP_NUM,
        state: {
          viewType: "LICENSE",
        },
      });
    } else {
      history.push({ pathname: "/app/home/profile" });
    }
  };
  return (
    <>
      {props.open && props.msg !== "" ? (
        <ReactModal
          isOpen={props.open} //모달 Open(Boolean)
          className="noLicensePopup" //모달 ClassName
          overlayClassName="noLicensePopup_Overlay" //모달오버레이 ClassName
          onRequestClose={props.close}
          shouldCloseOnEsc={true}
          animationType="fade"
          transparent={true}
          closeTimeoutMS={200}
        >
          <div>
            <img
              src="/img/icon/landing/close.png"
              alt="close"
              onClick={props.close}
              style={{
                cursor: "pointer",
                float: "right",
              }}
            />
            {/* </div> */}
            <div className="text-center" style={{ clear: "both" }}>
              <img
                src="/img/non_pass.png"
                alt="noLicense"
                style={{ marginBottom: "24px" }}
              />
              <div className="popupTitle">
                {t("schedule.msg." + props.msg + ".title")}
              </div>
              <div className="popupContents">
                {t("schedule.msg." + props.msg + ".text1")}
                <br />
                {t("schedule.msg." + props.msg + ".text2")}
              </div>
              <div className="btnArea">
                <button className="btnOK" onClick={props.close}>
                  알겠습니다!
                </button>
                <button className="btnGoLicense" onClick={goLicense}>
                  회의 이용권 보러 가기
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      ) : null}
    </>
  );
}

export default FailReservationPopup;
