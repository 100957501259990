/**
 * Import List
 */
import React, { useState, useEffect, useRef } from "react";
import ReactModal from "react-modal";
import axios from "axios";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  updateDateTimeInfo,
  openAlert,
  openConfirm,
  closeAlert,
} from "actions/index";
import { useTranslation } from "react-i18next";
import { CopyToClipboard } from "react-copy-to-clipboard";
/**
 * Version : 1.0
 * 파일명 : scheduleDetail.js
 * 작성일자 : 2021-10-05
 * 작성자 : 강연승
 * 설명 : 스케줄 상세내역 팝업
 * 수정일자 : 2022-07-13
 * 수정자 : 강연승
 * 수정내역 : 진행중인 회의 녹화 상태 출력
 */
function ScheduleDetail(props) {
  /**
   * 언어변환
   */
  const { t } = useTranslation();
  /**
   * url 설정
   */
  let server_url = "";

  if (process.env.NODE_ENV === "development") {
    server_url =
      process.env.REACT_APP_SERVER_URL + process.env.REACT_APP_SERVER_PORT;
  } else {
    server_url = process.env.REACT_APP_SERVER_URL;
  }
  const location = useLocation();
  const dispatch = useDispatch();
  const [deleteType, setDeleteType] = useState(true);

  const dateFormat = dateTime => {
    let DT = new Date(dateTime);
    let year = DT.getFullYear();
    let month =
      DT.getMonth() + 1 < 10 ? "0" + (DT.getMonth() + 1) : DT.getMonth() + 1;
    let date = DT.getDate() < 10 ? "0" + DT.getDate() : DT.getDate();
    let day = DT.getDay();
    let dayStr;
    switch (day) {
      case 0:
        dayStr = t("date.sunday");
        break;
      case 1:
        dayStr = t("date.monday");
        break;
      case 2:
        dayStr = t("date.tuesday");
        break;
      case 3:
        dayStr = t("date.wednesday");
        break;
      case 4:
        dayStr = t("date.thursday");
        break;
      case 5:
        dayStr = t("date.friday");
        break;
      case 6:
        dayStr = t("date.saturday");
        break;
    }
    let hour = DT.getHours();
    let minute = DT.getMinutes() < 10 ? "0" + DT.getMinutes() : DT.getMinutes();
    if (hour > 12) {
      hour = t("date.pm_kr") + (hour - 12);
    } else {
      if (hour === 12) {
        hour = t("date.pm_kr") + hour;
      } else {
        hour = t("date.am_kr") + hour;
      }
    }
    let result =
      t("date.fullFormat", {
        YEAR: year,
        MONTH: month,
        DATE: date,
        DAY: dayStr,
      }) +
      hour +
      ":" +
      minute;
    return result;
  };

  const deleteSchedule = () => {
    props.setLoading(true);
    axios
      .put("/schedule/deleteSchedule", {
        SCHEDULE_NUM: props.scheduleInfo.id,
        TITLE: props.scheduleInfo.title,
        START_TIME: props.scheduleInfo.startTime,
        END_TIME: props.scheduleInfo.endTime,
        CONTENTS: props.scheduleInfo.content,
        API_TYPE: props.scheduleInfo.apiType,
      })
      .then(response => {
        if (response.status === 200) {
          props.setLoading(false);
          const obj = {
            TEXT: t("schedule.msg.successDeleteSchedule"),
            submitEventHandler: () => {
              dispatch(closeAlert());
              props.handleDataState();
              changeBtnType(true);
            },
          };
          dispatch(openAlert(obj));
        } else {
          props.setLoading(false);
          const obj = {
            TEXT: t("schedule.msg.failDeleteSchedule"),
            submitEventHandler: () => dispatch(closeAlert()),
          };
          dispatch(openAlert(obj));
        }
      })
      .catch(error => {
        props.setLoading(false);
        const obj = {
          TEXT: t("schedule.msg.errorDeleteSchedule"),
          submitEventHandler: () => dispatch(closeAlert()),
        };
        dispatch(openAlert(obj));
        console.log(error);
      });
  };

  const endSchedule = () => {
    props.close();
    props.setLoading(true);
    axios
      .put("/schedule/endSchedule", {
        SCHEDULE_NUM: props.scheduleInfo.id,
      })
      .then(response => {
        if (response.status === 200) {
          props.setLoading(false);
          const obj = {
            TEXT: t("schedule.msg.successEndSchedule"),
            submitEventHandler: () => {
              dispatch(closeAlert());
              props.handleDataState();
            },
          };
          dispatch(openAlert(obj));
        } else {
          props.setLoading(false);
          const obj = {
            TEXT: t("schedule.msg.failEndSchedule"),
            submitEventHandler: () => dispatch(closeAlert()),
          };
          dispatch(openAlert(obj));
        }
      })
      .catch(error => {
        props.setLoading(false);
        const obj = {
          TEXT: t("schedule.msg.failEndSchedule"),
          submitEventHandler: () => dispatch(closeAlert()),
        };
        dispatch(openAlert(obj));
        console.log(error);
      });
  };

  const changeBtnType = type => {
    if (type === true) {
      setDeleteType(true);
    } else if (type === false) {
      setDeleteType(false);
    }
  };

  const platformImg = type => {
    switch (type) {
      case "CD0501":
        return (
          <img
            src="/img/platform/webex3.png"
            alt="webex"
            style={{ verticalAlign: "text-bottom" }}
          />
        );
      case "CD0502":
        return (
          <img
            src="/img/platform/zoom3.png"
            alt="zoom"
            style={{ verticalAlign: "text-bottom" }}
          />
        );
      case "CD0503":
        return (
          <img
            src="/img/platform/chime3.png"
            alt="chime"
            style={{ verticalAlign: "text-bottom" }}
          />
        );
      case "CD0504":
        return (
          <img
            src="/img/platform/pexip3.png"
            alt="pexip"
            style={{ verticalAlign: "text-bottom" }}
          />
        );
    }
  };

  const [joinYN, setJoinYN] = useState(props.scheduleInfo.isJoin);
  /**
   * 인터벌 설정
   */
  function useInterval(callback, delay) {
    const savedCallback = useRef();

    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }
  /**
   * 인터벌 사용 (입장하기 버튼 활성화 체크)
   */
  useInterval(() => {
    if (props.open) {
      if (
        new Date(props.scheduleInfo.startTime) - new Date() <= 300000 &&
        new Date() < new Date(props.scheduleInfo.endTime)
      ) {
        // console.log("회의 참석 가능");
        setJoinYN(true);
      } else {
        // console.log("회의 참석 불가");
        setJoinYN(false);
      }
    }
  }, 1000);

  /**
   * 그룹 색상
   */
  const groupColor = color => {
    switch (color) {
      case "red":
        return "#de8799";
      case "orange":
        return "#d78e71";
      case "yellow":
        return "#cdb14c";
      case "bean":
        return "#97C12A";
      case "green":
        return "#64d483";
      case "mint":
        return "#6cd6bf";
      case "pine":
        return "#75BDD9";
      case "pink":
        return "#cb86dd";
      case "purple":
        return "#a994e1";
      case "black":
        return "#3b3f4c";
      default:
        return "#2B4AC4";
    }
  };
  return (
    <div>
      <ReactModal
        isOpen={props.open} //모달 Open(Boolean)
        className="scheduleDetail" //모달 ClassName
        overlayClassName="scheduleDetail_Overlay" //모달오버레이 ClassName
        onRequestClose={() => {
          props.close();
          changeBtnType(true);
        }}
        shouldCloseOnEsc={true}
        animationType="fade"
        transparent={true}
        closeTimeoutMS={200}
      >
        <div>
          <img
            className="closeBtn"
            src="/img/icon/schedule/successReservation/close.png"
            alt="close"
            onClick={() => {
              props.close();
              changeBtnType(true);
            }}
          />
        </div>
        <div className="popup_header">
          <div
            className="scheduleTitle"
            style={{
              color: props.detailType === "history" ? "#3E3E4F" : "#2B4AC4",
            }}
            title={props.scheduleInfo.title}
          >
            {props.scheduleInfo.title}
          </div>
        </div>

        <div className="popup_contents">
          <div className="scheduleDetail_PlatFormDiv">
            {platformImg(props.scheduleInfo.apiType)}
          </div>

          {props.scheduleInfo.hostYN === "Y" &&
          props.detailType !== "history" ? (
            props.scheduleInfo.apiType === "CD0501" ? (
              <>
                <CopyToClipboard
                  text={props.scheduleInfo.hostKey}
                  onCopy={() => alert("호스트 키가 클립보드에 복사되었습니다.")}
                >
                  <div className="scheduleDetail_HostKeyDiv cursor">
                    Webex 호스트 키 : {props.scheduleInfo.hostKey}
                  </div>
                </CopyToClipboard>
                <div className="scheduleDetail_HostKeyExpln">
                  <div>* 호스트 설정 방법 : </div>
                  <div>
                    [상단 메뉴 표시줄 → 참가자 → 호스트 역할 받기 → 호스트키
                    입력]
                  </div>
                </div>
              </>
            ) : null
          ) : null}

          {props.scheduleInfo.recYN === "Y" &&
          props.detailType === "history" ? (
            <div className="scheduleDetail_recYN history">
              <div className="">회의 녹화 진행</div>
            </div>
          ) : null}

          {props.scheduleInfo.groupYN === "Y" ? (
            props.detailType === "history" ? (
              <div className="scheduleDetail_div">
                <div className="scheduleDetail_label history">그룹 미팅</div>
                <div
                  style={{
                    display: "inline-block",
                    font: "normal normal normal 14px/18px Spoqa Han Sans Neo",
                    color: groupColor(props.scheduleInfo.groupColor),
                  }}
                >
                  {props.scheduleInfo.groupNm}
                </div>
              </div>
            ) : (
              <div
                className="scheduleDetail_GroupNm"
                style={{ color: groupColor(props.scheduleInfo.groupColor) }}
              >
                [{props.scheduleInfo.groupNm}]에서 진행하는 회의입니다.
              </div>
            )
          ) : null}

          {props.scheduleInfo.recYN === "Y" &&
          (props.detailType === "timeline" || joinYN) ? (
            <div className="scheduleDetail_div">
              <div className="scheduleDetail_label timeline">회의 녹화</div>
              <div className="scheduleDetail_recYN timeline">ON</div>
            </div>
          ) : null}

          <div className="scheduleDetail_div">
            <div
              className={
                props.detailType === "history"
                  ? "scheduleDetail_label history"
                  : "scheduleDetail_label timeline"
              }
            >
              시작 시간
            </div>
            <div className="scheduleDetail_Contents">
              {dateFormat(props.scheduleInfo.startTime)}
            </div>
          </div>

          <div className="scheduleDetail_div">
            <div
              className={
                props.detailType === "history"
                  ? "scheduleDetail_label history"
                  : "scheduleDetail_label timeline"
              }
            >
              종료 시간
            </div>
            <div className="scheduleDetail_Contents">
              {dateFormat(props.scheduleInfo.endTime)}
            </div>
          </div>

          <div className="scheduleDetail_div">
            <div
              className={
                props.detailType === "history"
                  ? "scheduleDetail_label history"
                  : "scheduleDetail_label timeline"
              }
            >
              주최자
            </div>
            <div className="scheduleDetail_Contents">
              <>
                <div className="inline-block">{props.scheduleInfo.hostNm}</div>
                <div className="inline-block" style={{ marginLeft: "10px" }}>
                  {props.scheduleInfo.hostId}
                </div>
              </>
            </div>
          </div>

          {Array.isArray(props.scheduleParticipants) &&
          props.scheduleParticipants.length > 0 ? (
            <div className="scheduleDetail_div">
              <div
                className={
                  props.detailType === "history"
                    ? "scheduleDetail_label history align-top"
                    : "scheduleDetail_label timeline align-top"
                }
              >
                참석자
              </div>
              <div className="scheduleDetail_Contents participantsList scrollbar">
                {props.scheduleParticipants.map(data => (
                  <div key={data.PRTCP_NUM}>
                    {data.PRTCP_NM === "" ? (
                      <div className="inline-block">{data.PRTCP_EMAIL}</div>
                    ) : (
                      <>
                        <div className="inline-block">{data.PRTCP_NM}</div>
                        <div
                          className="inline-block"
                          style={{ marginLeft: "10px" }}
                        >
                          {data.PRTCP_EMAIL}
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ) : null}

          {props.scheduleInfo.content !== "" &&
          props.scheduleInfo.content !== undefined ? (
            <div className="scheduleDetail_div">
              <div
                className={
                  props.detailType === "history"
                    ? "scheduleDetail_label history align-top"
                    : "scheduleDetail_label timeline align-top"
                }
              >
                회의 내용
              </div>
              <div className="scheduleDetail_Contents scheduleContents scrollbar">
                {props.scheduleInfo.content.replaceAll("<br />", "\n")}
              </div>
            </div>
          ) : null}

          {Array.isArray(props.scheduleInfo.fileInfoList) &&
          props.scheduleInfo.fileInfoList.length > 0 ? (
            <div className="scheduleDetail_div">
              <div
                className={
                  props.detailType === "history"
                    ? "scheduleDetail_label history align-top"
                    : "scheduleDetail_label timeline align-top"
                }
              >
                첨부파일
              </div>
              <div className="scheduleDetail_Contents scheduleFileList scrollbar">
                {props.scheduleInfo.fileInfoList.map(data => (
                  <div
                    key={data.fileNum}
                    className="scheduleFileInfo cursor"
                    onClick={() =>
                      window.open(
                        server_url +
                          "/file/downloadFile?filename=" +
                          data.fileDownNm +
                          "&downfilename=" +
                          data.fileNm,
                        "_blank"
                      )
                    }
                  >
                    {data.fileNm}
                  </div>
                ))}
              </div>
            </div>
          ) : null}

          {props.scheduleInfo.apiType === "CD0501" &&
          props.scheduleInfo.meetingPW !== "" &&
          props.scheduleInfo.meetingPW !== undefined ? (
            <div className="scheduleDetail_div">
              <div
                className={
                  props.detailType === "history"
                    ? "scheduleDetail_label history"
                    : "scheduleDetail_label timeline"
                }
              >
                비밀번호
              </div>
              <div className="scheduleDetail_Contents">
                <>
                  <div className="inline-block">
                    {props.scheduleInfo.meetingPW}
                  </div>
                </>
              </div>
            </div>
          ) : null}

          {props.scheduleInfo.apiType === "CD0501" &&
          props.scheduleInfo.sipUrl !== "" &&
          props.scheduleInfo.sipUrl !== undefined ? (
            <div className="scheduleDetail_div">
              <div
                className={
                  props.detailType === "history"
                    ? "scheduleDetail_label history"
                    : "scheduleDetail_label timeline"
                }
              >
                SIP URL
              </div>
              <div className="scheduleDetail_Contents">
                <div className="inline-block">{props.scheduleInfo.sipUrl}</div>
              </div>
            </div>
          ) : null}

          {props.scheduleInfo.apiType === "CD0501" &&
          props.scheduleInfo.meetingLink !== "" &&
          props.scheduleInfo.meetingLink !== undefined ? (
            <div className="scheduleDetail_div">
              <div
                className={
                  props.detailType === "history"
                    ? "scheduleDetail_label history"
                    : "scheduleDetail_label timeline"
                }
              >
                회의 링크
              </div>
              <div className="scheduleDetail_Contents">
                <CopyToClipboard
                  text={props.scheduleInfo.meetingLink}
                  onCopy={() => alert("클립보드에 복사되었습니다.")}
                >
                  <div className="meetingLink">
                    {props.scheduleInfo.meetingLink}
                  </div>
                </CopyToClipboard>
              </div>
            </div>
          ) : null}
        </div>

        {props.scheduleInfo.hostYN === "Y" ? (
          <>
            <div className="div_scheduleURL">
              <div className="scheduleURL">{props.scheduleInfo.inviteURL}</div>
              <CopyToClipboard
                text={props.scheduleInfo.inviteURL}
                onCopy={() => alert("클립보드에 복사되었습니다.")}
              >
                <img
                  className="copyIcon"
                  src="/img/icon/schedule/successReservation/copy_link.png"
                  alt="meetingLink"
                />
              </CopyToClipboard>
            </div>
            <div className="scheduleURLText">
              링크를 복사하여 회의실을 공유해보세요.
            </div>
          </>
        ) : null}

        <div className="footer">
          {deleteType ? (
            <>
              {props.detailType === "history" &&
              props.scheduleInfo.hostYN === "Y" ? (
                location.pathname === "/app/group" ? (
                  <Link
                    exact
                    to={{
                      pathname:
                        "/app/group/groupMain/" + props.scheduleInfo.groupNum,
                      state: {
                        detailType: "history",
                        title: props.scheduleInfo.title,
                        content: props.scheduleInfo.content.replaceAll(
                          "<br />",
                          "\n"
                        ),
                        participants: props.scheduleParticipants,
                      },
                    }}
                    style={{ textDecoration: "none", color: "white" }}
                    onClick={() => {
                      props.close();
                      changeBtnType(true);
                    }}
                  >
                    <button className="editBtn">다시 예약하기</button>
                  </Link>
                ) : props.scheduleInfo.groupYN === "Y" &&
                  props.scheduleInfo.groupNum !== undefined ? (
                  <Link
                    exact
                    to={{
                      pathname:
                        "/app/group/groupMain/" + props.scheduleInfo.groupNum,
                      state: {
                        detailType: "history",
                        title: props.scheduleInfo.title,
                        content: props.scheduleInfo.content.replaceAll(
                          "<br />",
                          "\n"
                        ),
                        participants: props.scheduleParticipants,
                      },
                    }}
                    style={{ textDecoration: "none", color: "white" }}
                    onClick={() => {
                      props.close();
                      changeBtnType(true);
                    }}
                  >
                    <button className="editBtn">다시 예약하기</button>
                  </Link>
                ) : (
                  <button
                    className="editBtn"
                    onClick={() => {
                      props.handleResSchedule(
                        props.scheduleInfo.title,
                        props.scheduleInfo.content.replaceAll("<br />", "\n"),
                        props.scheduleParticipants,
                        props.scheduleInfo.groupNum !== "" &&
                          props.scheduleInfo.groupNum !== undefined
                          ? props.scheduleInfo.groupNum
                          : ""
                      );
                      props.close();
                      changeBtnType(true);
                    }}
                  >
                    다시 예약하기
                  </button>
                )
              ) : (
                <>
                  <button
                    style={{
                      display:
                        joinYN || props.detailType === "meeting"
                          ? "inline-block"
                          : "none",
                    }}
                    className="enterBtn"
                    onClick={() => {
                      if (props.scheduleInfo.apiType === "CD0501") {
                        window.open(props.scheduleInfo.inviteURL, "_blank");
                      } else if (props.scheduleInfo.apiType === "CD0502") {
                        if (props.scheduleInfo.hostYN === "Y") {
                          window.open(
                            server_url +
                              "/schedule/reDirectZoomHostUrl?meeting_key=" +
                              props.scheduleInfo.meetingKey,
                            "_blank"
                          );
                        } else if (props.scheduleInfo.hostYN === "N") {
                          window.open(props.scheduleInfo.inviteURL, "_blank");
                        }
                      }
                    }}
                  >
                    입장하기
                  </button>
                  <button
                    style={{
                      display:
                        props.detailType === "meeting" &&
                        props.scheduleInfo.hostYN === "Y"
                          ? "inline-block"
                          : "none",
                    }}
                    className="delBtn"
                    onClick={() => {
                      const config = {
                        TEXT: t("schedule.msg.confirmEndSchedule"),
                        submitEventHandler: () => {
                          dispatch(closeAlert());
                          endSchedule();
                        },
                      };
                      dispatch(openConfirm(config));
                    }}
                  >
                    회의 종료
                  </button>
                </>
              )}
              {props.scheduleInfo.hostYN === "Y" ? (
                location.pathname === "/app/group" ? (
                  <Link
                    to={{
                      pathname:
                        "/app/group/groupMain/" + props.scheduleInfo.groupNum,
                      state: {
                        detailType: "timeline",
                        id: props.scheduleInfo.id,
                        title: props.scheduleInfo.title,
                        startTime: props.scheduleInfo.startTime,
                        endTime: props.scheduleInfo.endTime,
                        content: props.scheduleInfo.content.replaceAll(
                          "<br />",
                          "\n"
                        ),
                        participants: props.scheduleParticipants,
                        apiType: props.scheduleInfo.apiType,
                        fileInfoList: props.scheduleInfo.fileInfoList,
                        recYN: props.scheduleInfo.recYN,
                      },
                    }}
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    <button
                      style={{
                        display:
                          props.detailType !== "timeline"
                            ? "none"
                            : "inline-block",
                      }}
                      className="editBtn"
                      onClick={() => {
                        props.handleEditSchedule(
                          props.scheduleInfo.id,
                          props.scheduleInfo.title,
                          props.scheduleInfo.startTime,
                          props.scheduleInfo.endTime,
                          props.scheduleInfo.content.replaceAll("<br />", "\n"),
                          props.scheduleParticipants,
                          props.scheduleInfo.apiType,
                          props.scheduleInfo.fileInfoList,
                          props.scheduleInfo.recYN
                        );
                        props.close();
                        changeBtnType(true);
                        const config = {
                          INPUT_TYPE: "sidebar",
                          START_DATE: props.scheduleInfo.startTime.replace(
                            /\T.*/,
                            ""
                          ),
                          END_DATE: props.scheduleInfo.endTime.replace(
                            /\T.*/,
                            ""
                          ),
                          START_TIME: props.scheduleInfo.startTime,
                          END_TIME: props.scheduleInfo.endTime,
                        };
                        dispatch(updateDateTimeInfo(config));
                      }}
                    >
                      수 정
                    </button>
                  </Link>
                ) : props.scheduleInfo.groupYN === "Y" &&
                  props.scheduleInfo.groupNum !== undefined ? (
                  <Link
                    to={{
                      pathname:
                        "/app/group/groupMain/" + props.scheduleInfo.groupNum,
                      state: {
                        detailType: "timeline",
                        id: props.scheduleInfo.id,
                        title: props.scheduleInfo.title,
                        startTime: props.scheduleInfo.startTime,
                        endTime: props.scheduleInfo.endTime,
                        content: props.scheduleInfo.content.replaceAll(
                          "<br />",
                          "\n"
                        ),
                        participants: props.scheduleParticipants,
                        apiType: props.scheduleInfo.apiType,
                        fileInfoList: props.scheduleInfo.fileInfoList,
                        recYN: props.scheduleInfo.recYN,
                      },
                    }}
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    <button
                      style={{
                        display:
                          props.detailType !== "timeline"
                            ? "none"
                            : "inline-block",
                      }}
                      className="editBtn"
                      onClick={() => {
                        props.handleEditSchedule(
                          props.scheduleInfo.id,
                          props.scheduleInfo.title,
                          props.scheduleInfo.startTime,
                          props.scheduleInfo.endTime,
                          props.scheduleInfo.content.replaceAll("<br />", "\n"),
                          props.scheduleParticipants,
                          props.scheduleInfo.apiType,
                          props.scheduleInfo.fileInfoList,
                          props.scheduleInfo.recYN
                        );
                        props.close();
                        changeBtnType(true);
                        const config = {
                          INPUT_TYPE: "sidebar",
                          START_DATE: props.scheduleInfo.startTime.replace(
                            /\T.*/,
                            ""
                          ),
                          END_DATE: props.scheduleInfo.endTime.replace(
                            /\T.*/,
                            ""
                          ),
                          START_TIME: props.scheduleInfo.startTime,
                          END_TIME: props.scheduleInfo.endTime,
                        };
                        dispatch(updateDateTimeInfo(config));
                      }}
                    >
                      수 정
                    </button>
                  </Link>
                ) : (
                  <button
                    style={{
                      display:
                        props.detailType !== "timeline"
                          ? "none"
                          : "inline-block",
                    }}
                    className="editBtn"
                    onClick={() => {
                      props.handleEditSchedule(
                        props.scheduleInfo.id,
                        props.scheduleInfo.title,
                        props.scheduleInfo.startTime,
                        props.scheduleInfo.endTime,
                        props.scheduleInfo.content.replaceAll("<br />", "\n"),
                        props.scheduleParticipants,
                        props.scheduleInfo.apiType,
                        props.scheduleInfo.fileInfoList,
                        props.scheduleInfo.recYN
                      );
                      props.close();
                      changeBtnType(true);
                      const config = {
                        INPUT_TYPE: "sidebar",
                        START_DATE: props.scheduleInfo.startTime.replace(
                          /\T.*/,
                          ""
                        ),
                        END_DATE: props.scheduleInfo.endTime.replace(
                          /\T.*/,
                          ""
                        ),
                        START_TIME: props.scheduleInfo.startTime,
                        END_TIME: props.scheduleInfo.endTime,
                      };
                      dispatch(updateDateTimeInfo(config));
                    }}
                  >
                    수 정
                  </button>
                )
              ) : null}
              {props.scheduleInfo.hostYN === "Y" ? (
                props.detailType !== "timeline" ? null : (
                  <button
                    className="delBtn"
                    onClick={() => changeBtnType(false)}
                  >
                    삭 제
                  </button>
                )
              ) : null}
            </>
          ) : (
            <>
              <img
                src="/img/icon/schedule/delete.png"
                alt="delete"
                style={{ display: "inline-block" }}
              />
              <div
                style={{
                  display: "inline-block",
                  color: "red",
                  marginLeft: "8px",
                }}
              >
                이 일정을 삭제할까요?
              </div>
              <button
                style={{
                  display: "inline-block",
                  height: "36px",
                  backgroundColor: "white",
                  border: "none",
                  color: "#C63353",
                  fontSize: "18px",
                  fontWeight: "bold",
                  padding: "0px 10px 0px 10px",
                  marginLeft: "16px",
                  marginRight: "8px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  deleteSchedule();
                  props.close();
                }}
              >
                예
              </button>
              <button
                style={{
                  display: "inline-block",
                  height: "36px",
                  backgroundColor: "white",
                  border: "none",
                  color: "#2B4AC4",
                  padding: "0px 10px 0px 10px",
                  fontSize: "18px",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
                onClick={() => changeBtnType(true)}
              >
                아니오
              </button>
            </>
          )}
        </div>
      </ReactModal>
    </div>
  );
}

export default ScheduleDetail;
