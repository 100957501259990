import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { blue, green } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
/**
 * Version : 1.0
 * 파일명 : 
 * 작성일자 : 2021-10-05
 * 작성자 : 권도훈
 * 설명 : 
 * 수정일자 :  2021-10-05
 * 수정자 : 권도훈
 * 수정내역 : 
 * props : 
 * 전달받은값 - 설명
*/
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  wrapper: {
    // margin: theme.spacing(1),
    position: 'relative',
  },
  button: {
    width: '100%',
    fontWeight: 'bold',
    boxShadow: 'none',
    backgroundColor: blue[500],
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: blue[700],
    },
    '&:active': {
      boxShadow: 'none',
    },
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

// value         |0px     600px    960px    1280px   1920px
// key           |xs      sm       md       lg       xl
// screen width  |--------|--------|--------|--------|-------->
// range         |   xs   |   sm   |   md   |   lg   |   xl

function LoginBtn(props) {
  const classes = useStyles();
  // const [loading, setLoading] = useState(false);

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          disabled={props.loading}
          onClick={() => {
            // setLoading(true);
            props.onClick();
          }}
        >
          로그인
        </Button>
        {props.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    </div>
  )
}

export default LoginBtn
