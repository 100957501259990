import axios from "axios";
import Loading from "component/tag/loading/loading";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
/**
 * Version : 1.0
 * 파일명 :
 * 작성일자 : 2021-10-15
 * 작성자 : 권도훈
 * 설명 : 구분 리스트
 * 수정일자 : 2022-02-09
 * 수정자 : 강연승
 * 수정내역 : 연락처 그룹 테마별 텍스트 적용
 */
function Addresscategorypopup(props) {
  /**
   * REDUCER
   */
  const REDUCER_STATE = useSelector(state => state.groupInfoReducer);
  /**
   * 언어변환
   */
  const { t } = useTranslation();
  /**
   * 로딩
   */
  const [loading, setLoading] = useState(false);
  /**
   * 분류
   */
  const [category, setCategory] = useState([]);
  /**
   * 사용자 구분 설정 조회
   */
  const getcategoryData = useCallback(() => {
    setLoading(true);
    const config = {
      params: {
        Group_YN: REDUCER_STATE.GROUP_YN,
        Group_Num: REDUCER_STATE.GROUP_NUM,
      },
    };

    axios
      .get("/address/AdrType", config)
      .then(res => {
        if (res.status === 200) {
          setCategory(res.data);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [REDUCER_STATE.GROUP_NUM, REDUCER_STATE.GROUP_YN]);

  useEffect(() => {
    if (props.isCategory) {
      getcategoryData();
    } else {
      setCategory([]);
    }
  }, [getcategoryData, props.isCategory]);
  /**
   * 태그
   */
  return (
    <div
      style={{
        width: "200px",
        height: "378px",
        background: "#FFFFFF",
        boxShadow: "0px 5px 16px #00000029",
        borderRadius: "8px",
        // top: topValue,
        zIndex: "80",
      }}
    >
      <div
        style={{
          fontSize: "14px",
          fontWeight: "bold",
          color: "#3E3E4F",
          left: "16px",
          top: "8px",
          right: "104px",
          position: "relative",
        }}
      >
        {t("my.address.txt.popup.category.title")}
      </div>
      <div
        className="cursor"
        style={{
          fontSize: "14px",
          fontWeight: "bold",
          color: "#2B4AC4",
          left: "16px",
          top: "25px",
          right: "76px",
          position: "relative",
        }}
        onClick={() => props.filterAll()}
      >
        {REDUCER_STATE.GROUP_YN === "N"
          ? t("my.address.btn.all.CD0401")
          : t("my.address.btn.all." + REDUCER_STATE.GROUP_CATEGORY)}
      </div>
      <div
        className="scrollbar"
        style={{
          marginTop: "30px",
          marginLeft: "16px",
          position: "relative",
          overflowY: "auto",
          overflowX: "hidden",
          maxHeight: "296px",
        }}
      >
        {loading ? (
          <div
            style={{
              height: "295px",
            }}
          >
            <Loading />
          </div>
        ) : (
          category.map((data, index) => (
            <div
              className="cursor"
              key={index}
              style={{
                background: props.colorConverter(data.ADR_TYPE_BGC),
                fontSize: "14px",
                fontWeight: "bold",
                borderRadius: "6px",
                textAlign: "center",
                color: "white",
                padding: "3px 8px",
                marginTop: "8px",
                marginBottom: "8px",
                width: "fit-content",
              }}
              data-num={data.ADR_TYPE_NUM}
              onClick={() => props.filterCategory(data.ADR_TYPE_NUM)}
            >
              {data.ADR_TYPE_NM}
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default Addresscategorypopup;
